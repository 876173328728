import * as go from 'gojs';
import { updateNodeLinksValidation } from '../utils/updateLinksValidation';

const handleDropElement = (diagram: go.Diagram, part: go.Part) => {
  if (part.containingGroup) {
    diagram.commandHandler.addTopLevelParts(new go.Set([part]), true);

    if (part instanceof go.Node) {
      updateNodeLinksValidation(part);
    }
  }
};

export const handleDiagramMouseDrop = (event: go.InputEvent) => {
  const { diagram } = event;
  const { selection } = diagram;
  selection.each((part: go.Part) => handleDropElement(diagram, part));
};
