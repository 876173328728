import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import _ from 'lodash';

import { DATASOURCE, DATASOURCE_TYPE_MAP } from 'src/app/shared/const/datasource.const';
import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { CodeEditorPanelService } from 'src/app/workflow-editor/components/code-editor-panel/code-editor-panel.service';
import { UDPDatasetOperatorService } from './udp-data-operator.service';
import { UPDMilOperatorService } from '../upd-mil-operator/udp-mil-operator.service';
import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { ConfirmDialogService } from '../shared/component/confirm-dialog/confirm-dialog.service';

import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';


/**
 * @description Accepts string param and convert given string to the object
 */
export function convertStringToObject(stringObject: any): any {
  const removeNONE = stringObject.replaceAll('None', '""');
  let regexObjFromString = '';
  if (stringObject.includes('\\')) {
    regexObjFromString = removeNONE.replaceAll('\\', '');
  } else {
    regexObjFromString = removeNONE.replace(/'/g, '"');
  }
  return JSON.parse(regexObjFromString);
}

@Component({
  selector: 'lib-udp-dataset-operator',
  templateUrl: './udp-dataset-operator.component.html',
  styleUrls: ['./udp-dataset-operator.component.scss']
})
export class UdpDatasetOperatorComponent implements OnInit, OnDestroy {
  @Input() customerId: string;
  @Input() userId: string;
  @Input() dataAssetCongurationCtx: string;
  @Input() previousOperatorIDs: string = null;
  @Input() currentTaskID: string;
  @Input() currentFileConfig: any;

  @Output() valueChanges: any = new EventEmitter();

  public datasets: any[] = [];
  public datasetsBackUp: any[] = [];

  private dataAssetTypeIconMap = {
    DF: 'DMG-File Imort.svg',
    DP: 'DP-Data Pipeline.svg',
    QRY: 'DMG-Query.svg',
    VIZ: 'DMG-Visualization.svg',
    DS: 'DMG-Dataset.svg',
    NB: 'DP-Data Pipeline Composer.svg'
  };

  selectedDataset: any;
  datasetDetails: any = {};
  fileForm: FormGroup;

  public DATASOURCE = DATASOURCE;

  units: any = [];
  hasDateformat = false;
  datasetOperatorConfig: any = {};
  dataAssetCategoryName: string;

  queryDatasetSchema: any;
  querySelectedata: any;

  public warningMessageWithoutDataset = '';

  private subscriptions: Subscription[] = [];
  private dataAssetTypeMap = DATASOURCE_TYPE_MAP;
  private fileName: string;
  private dataAssetDetails;

  constructor(
    private datasetOperatorService: UDPDatasetOperatorService,
    private udpMilOperatorService: UPDMilOperatorService,
    private udpTaskConfigService: UdpTaskConfigService,
    private codeEditorPanelService: CodeEditorPanelService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dataCatalogService: DataCatalogService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.initForm();
    this.subscribeToDatasetSchemaUpdates();
    this.subsribeToOperatorSave();
  }

  /**
   *
   */
  ngOnInit() {
    this.fileName = `${
      this.currentTaskID
    }.config.${DATASOURCE.DATASET_CODE.toLowerCase()}.json`;
    this.checkIfConfigIsAlreadyAvailable(this.currentFileConfig);
    this.getDataAssestCategories();
  }

  private checkIfConfigIsAlreadyAvailable(config) {
    if (config && Object.keys(config.contents).length > 0) {
      this.datasetOperatorConfig = config.contents;
      this.selectedDataset = {};
      if (
        config.contents.datasetDataSource &&
        config.contents.datasetDataSource.database
      ) {
        const database = config.contents.datasetDataSource.database;
        this.selectedDataset.dataSourceID = database.customerDataSourceID;
        this.selectedDataset.dataSourceName = database.dataSourceName;
        this.selectedDataset.dataSourceType = database.dataSourceType;
        this.selectedDataset.dataAssetCategoryName =
          database.dataSourceCategory;
        if (this.selectedDataset.dataSourceType == DATASOURCE.FILE_ASSET_CODE) {
          this.getDataSetAssetDetails(
            this.selectedDataset.dataSourceID,
            this.selectedDataset.dataSourceType
          );
        }
      }
      if (config.contents.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
        this.selectedDataset.dataSourceType =
          config.contents.datasetDataSourceType;
      }
      if (
        config.contents.datasetDataSource &&
        config.contents.datasetDataSource.dataAsset &&
        config.contents.datasetDataSource.dataAsset.dataFileAsset
      ) {
        const dataFileAsset =
          config.contents.datasetDataSource.dataAsset.dataFileAsset;
        this.selectedDataset.dataSourceType =
          config.contents.datasetDataSource.dataAsset.sourceDataAssetType;
        if (dataFileAsset.fileName) {
          this.selectedDataset.fileName = dataFileAsset.fileName;
          this.selectedDataset.dataFileAsset = 'processFile';
          this.selectedDataset.dataSourceName = dataFileAsset.fileName;
          this.selectedDataset.dataAssetCategoryName =
            dataFileAsset.dataAssetCategoryName
              ? dataFileAsset.dataAssetCategoryName
              : '';
        } else if (dataFileAsset.dataFileSelection === 'Y') {
          this.selectedDataset.dataFileAsset = 'dataFileSelection';
          this.selectedDataset.dataFileNamePattern =
            dataFileAsset.dataFileNamePattern;
          this.selectedDataset.includeFiles = dataFileAsset.includeFiles;
          this.fileNamePatternChange(this.selectedDataset.dataFileNamePattern);
          if (dataFileAsset.includeFiles) {
            this.selectedDataset.dataFileSelection = dataFileAsset.includeFiles;
          }
          if (dataFileAsset.pastFileSet === 'Y') {
            if (dataFileAsset.quantity) {
              this.selectedDataset.quantity = dataFileAsset.quantity;
            }
            if (dataFileAsset.unit) {
              this.selectedDataset.unit = dataFileAsset.unit;
            }
          }
          if (dataFileAsset.fileNameContains) {
            this.selectedDataset.fileNameContains =
              dataFileAsset.fileNameContains;
          }
          if (dataFileAsset.customDateRange === 'Y') {
            if (dataFileAsset.dateFrom) {
              this.selectedDataset.from = new Date(
                dataFileAsset.dateFrom
              ).toLocaleDateString('en-US');
            }
            if (dataFileAsset.dateTo) {
              this.selectedDataset.to = new Date(
                dataFileAsset.dateTo
              ).toLocaleDateString('en-US');
            }
          }
        } else if (dataFileAsset.processAllFilesInFolder === 'Y') {
          this.selectedDataset.dataFileAsset = 'processAllFilesInFolder';
        }

        if (dataFileAsset.includeSubFolders === 'Y') {
          this.selectedDataset.includeSubFolders =
            dataFileAsset.includeSubFolders;
          this.selectedDataset.subFolderPathPattern =
            dataFileAsset.subFolderPathPattern;
        }
        this.fileForm.patchValue({ ...this.selectedDataset });
        this.cdr.detectChanges();
      }
      if (config.contents && config.contents.queryID) {
        this.selectedDataset.queryID = config.contents.queryID;
      }
      this.checkExistingConfigForQryAndDSRC(config);
    }
  }

  private getDataSetAssetDetails(dataSourceId, type) {
    this.subscriptions[this.subscriptions.length - 1] = this.dataCatalogService
      .getDataAssetDetails(dataSourceId, type)
      .subscribe((res) => {
        this.dataAssetDetails = res;
        if (this.dataAssetDetails && this.dataAssetDetails.fileSchema) {
          this.datasetOperatorConfig.datasetSchema = convertStringToObject(
            this.dataAssetDetails.fileSchema
          );
          this.selectedDataset.datasetSchema =
            this.datasetOperatorConfig.datasetSchema;
          this.queryDatasetSchema = this.selectedDataset.datasetSchema;
        }
      });
  }

  private checkExistingConfigForQryAndDSRC(config) {
    if (
      config.contents.datasetDataSourceType === DATASOURCE.QUERY_CODE ||
      config.contents.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE
    ) {
      this.queryDatasetSchema = _.cloneDeep(config.contents.datasetSchema);
      this.querySelectedata = _.cloneDeep(config.contents);
      this.cdr.detectChanges();
    }
  }

  private subscribeToDatasetSchemaUpdates() {
    this.subscriptions[this.subscriptions.length] =
      this.udpTaskConfigService.updateEntitySchema$.subscribe(
        (entitySchema) => {
          if (entitySchema !== undefined && entitySchema !== null) {
            const generatedSchema = entitySchema.currentGeneratedSchema;

            const queryID = entitySchema.queryID ? entitySchema.queryID : '';
            if (typeof generatedSchema !== 'boolean' && this.selectedDataset) {
              this.selectedDataset.datasetSchema = generatedSchema;
              if (this.selectedDataset.datasetSchema) {
                if (queryID) {
                  this.selectedDataset.queryID = queryID;
                }
                this.datasetOperatorConfig.datasetSchema = generatedSchema
                  ? generatedSchema
                  : this.datasetOperatorConfig.datasetSchema;
                this.datasetOperatorConfig.queryID = queryID;
                this.fileForm.patchValue({ ...this.selectedDataset });
                this.cdr.detectChanges();
              } else {
                if (this.selectedDataset.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
                  this.onDataSetSavedWarning();
                }
              }
            } else if (
              this.selectedDataset &&
              this.selectedDataset.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE
            ) {
              this.onDataSetSavedWarning();
            }
            this.udpTaskConfigService.updateEntitySchema.next(null);

            // This case is specifically for DSRC to check generatedSchema empty
            if (
              generatedSchema !== null &&
              this.selectedDataset.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE &&
              (generatedSchema === false || generatedSchema === undefined)
            ) {
              return;
            }
            this.udpTaskConfigService.currentOperatorSave.next('SAVE_DATASET');
            this.udpTaskConfigService.currentOperatorSave.next('NOT_SAVE');
          }
        }
      );
  }

  private subsribeToOperatorSave() {
    this.subscriptions[this.subscriptions.length] =
      this.udpTaskConfigService.currentOperatorSave$.subscribe((mode) => {
        if (
          mode &&
          mode !== '' &&
          mode === 'DATASET' &&
          (this.selectedDataset === null || this.selectedDataset === undefined)
        ) {
          this.warningMessageWithoutDataset =
            'Please select a dataset first before Save!';
        }
      });
  }

  /**
   *
   */
  private initForm(): void {

    this.fileForm = this.fb.group({
      dataFileAsset: ['processFile'],
      fileName: [''],
      dataFileSelection: [''],
      includeSubFolders: [false],
      subFolderPathPattern: [''],
      includeFiles: [''],
      dataFileNamePattern: [''],
      quantity: [''],
      unit: [''],
      fileNameContains: [''],
      from: [''],
      to: ['']
    });

    this.subscribeToFileFormGroup();
  }

  private subscribeToFileFormGroup() {
    this.subscriptions[this.subscriptions.length] = this.fileForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data: any) => {
        if (
          (this.selectedDataset &&
            this.selectedDataset.dataSourceType === DATASOURCE.FILE_ASSET_CODE) ||
          (this.selectedDataset &&
            this.selectedDataset.dataSourceType === DATASOURCE.DATASET_CODE) ||
          this.selectedDataset.dataSourceType === DATASOURCE.QUERY_CODE
        ) {
          this.processDataFileAsset(data);
        }
        this.processQueryvariables();
        this.subscriptions[this.subscriptions.length] =
          this.dataCatalogService.lastQueryScript$.subscribe((item: any) => {
            if (item) {
              this.datasetOperatorConfig.datasetDataSource.database.queryScript =
                item;
              this.codeEditorPanelService.datasetOperatorConfig = {
                fileName: `config/${this.fileName}`,
                contents: this.datasetOperatorConfig,
                TS: Math.floor(new Date().getTime() / 1000)
              };
            }
          });
        this.codeEditorPanelService.datasetOperatorConfig = {
          fileName: `config/${this.fileName}`,
          contents: this.datasetOperatorConfig,
          TS: Math.floor(new Date().getTime() / 1000)
        };
      });
  }

  processQueryvariables() {
    this.queryDatasetSchema = _.cloneDeep(
      this.datasetOperatorConfig.datasetSchema
    );
    this.querySelectedata = _.cloneDeep(this.selectedDataset);
    this.cdr.detectChanges();
  }

  /**
   *
   * @param pattern
   */
  fileNamePatternChange(pattern: string) {
    this.units = [];
    for (const k in miscellaneousConst.unitsMap) {
      if (pattern.includes(k)) {
        this.units.push(miscellaneousConst.unitsMap[k]);
      }
    }
    this.hasDateformat =
      this.units.includes('YEARS') &&
      this.units.includes('MONTHS') &&
      this.units.includes('DAYS');
  }

  getDataAssestCategories() {
    const dataAssetTypes = Object.keys(this.dataAssetTypeMap);
    const temp = 'DSRC,' + dataAssetTypes.join(',');
    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService
      .getDataAssestCategories(temp, this.customerId, this.userId)
      .subscribe((res: any) => {
        if (res.dataAssetCategoryList) {
          this.datasets = dataAssetTypes.map((type, i) => {
            return {
              id: i + 2,
              dataAssetType: this.dataAssetTypeMap[type],
              dataAssetCode: type,
              icon:
                'assets/Complete-Icon-Set/' + this.dataAssetTypeIconMap[type],
              dataAssets: res.dataAssetCategoryList[type]
                ? res.dataAssetCategoryList[type].map((cat: any) => {
                    return {
                      dataSetDataSource: [],
                      dataSetID: cat.dataAssetCategory.dataAssetCategoryID,
                      dataSetName: cat.dataAssetCategory.dataAssetCategoryName,
                      datasetSchema: {}
                    };
                  })
                : []
            };
          });
        }
        if (res.customerDataSourcesMap) {
          const t: any = {
            id: 1,
            dataAssetType: 'Data Sources',
            dataAssetCode: DATASOURCE.INTERNAL_DATA_SOURCE_CODE,
            icon: 'assets/Complete-Icon-Set/DMG-Internal-Data-Source.png',
            dataAssets: []
          };
          const dsMap = {
            EXTD: 'External Data',
            INTD: 'Internal Data',
            SHRD: 'Shared Data',
            DFR: 'Data Frame'
          };
          ['EXTD', 'INTD', 'SHRD', 'DFR'].forEach((type, i) => {
            if (res.customerDataSourcesMap[type]) {
              const datasources: any = [];
              res.customerDataSourcesMap[type].reverse().forEach((cat: any) => {
                if (cat.customerDataSourceID && cat.customerDataSourceName) {
                  datasources.push({
                    dataSourceID: cat.customerDataSourceID,
                    dataSourceName: cat.customerDataSourceName,
                    dataSourceType: DATASOURCE.INTERNAL_DATA_SOURCE_CODE,
                    dataSetDesc: '',
                    dataCatType: type,
                    dataSourceCategoryID: cat.refDataSourceCategoryID
                  });
                }
              });
              t.dataAssets.push({
                dataSetDataSource: datasources,
                dataSetName: dsMap[type],
                dataAssetType: type,
                datasetSchema: {}
              });
            }
          });
          this.datasets.unshift(t);
        }
        this.cdr.detectChanges();
        this.datasetsBackUp = [...this.datasets];
      });
  }

  onExpandCat({ asset, dataAssetCode }) {
    const typeMap: any = { DF: 'file', DS: 'dataset', QRY: 'query' };
    this.dataAssetCategoryName = asset.dataSetName;
    const data = {
      assetCategory: [
        {
          dataAssetCategoryID: asset.dataSetID,
          dataAssetType: dataAssetCode
        }
      ]
    };

    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService
      .getAssetLists(asset.dataSetID, typeMap[dataAssetCode])
      .subscribe((res: any) => {
        if (res && res.length > 0) {
          if (dataAssetCode === DATASOURCE.QUERY_CODE) {
            asset.dataSetDataSource = res.map((ds) => {
              return {
                ...ds,
                dataSourceID: ds.queryID,
                dataSourceName: ds.queryName,
                dataSourceType: dataAssetCode,
                dataSetDesc: ds.queryDescription
              };
            });
          } else if (dataAssetCode === DATASOURCE.FILE_ASSET_CODE) {
            asset.dataSetDataSource = res.map((ds) => {
              return {
                ...ds,
                dataSourceID: ds.fileID,
                dataSourceName: ds.fileName,
                dataSourceType: dataAssetCode
              };
            });
          } else if (dataAssetCode === DATASOURCE.DATASET_CODE) {
            asset.dataSetDataSource = res.map((ds) => {
              return {
                ...ds,
                dataSourceID: ds.datasetID,
                dataSourceName: ds.datasetName,
                dataSourceType: dataAssetCode,
                dataSetDesc: ds.datasetDescription
              };
            });
          }
          this.cdr.detectChanges();
        }
      });
  }

  public onFilterUpdate(list: any[]): void {
    if (Array.isArray(list)) {
      const data: any = [];
      if (list.length > 0) {
        for (const item of this.datasetsBackUp) {
          if (list.includes(item.dataAssetType)) {
            data.push(item);
          }
        }
        this.datasets = data;
      } else {
        this.datasets = [...this.datasetsBackUp];
      }
    }
  }

  public onDestDropped(e: CdkDragDrop<string[]>, id: number): void {
    const dataSourceCategoriesNumbers = ['3', '8'];
    if (
      [dataSourceCategoriesNumbers].includes(e.item.data.dataSourceCategoryID)
    ) {
      return;
    }
    this.selectedDataset = e.item.data;
    this.warningMessageWithoutDataset = '';

    this.processDataSetConfig();
  }

  private processDataSetConfig() {
    this.datasetOperatorConfig = {}; // Reset the main object

    // Set Customer ID and User ID
    this.datasetOperatorConfig.customerId = this.customerId;
    this.datasetOperatorConfig.userId = this.userId;

    this.processDatasetDataSourceType();
  }

  private processDatasetDataSourceType() {
    this.datasetOperatorConfig.datasetDataSourceType =
      this.selectedDataset.dataSourceType;

    this.datasetOperatorConfig.datasetDataSource = {};
    this.datasetOperatorConfig.datasetDataSource.database = undefined;
    if (this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
      // this.datasetOperatorConfig.datasetSchema = {};
      this.processDatasetSchema();
      this.processDatasetDataSourceDatabase();
      this.querySelectedata = this.datasetOperatorConfig;
    } else if (
      this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.FILE_ASSET_CODE ||
      this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.DATASET_CODE ||
      this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.QUERY_CODE
    ) {
      this.selectedDataset.fileName = this.selectedDataset.fileName
        ? this.selectedDataset.fileName
        : '';

      this.processDatasetDataSourceDatabase();
      this.processDatasetSchema();

      this.fileForm.patchValue({
        fileName: this.selectedDataset.fileName,
        dataFileAsset: 'processFile'
      });
    } else if (this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.QUERY_CODE) {
      this.datasetOperatorConfig.datasetSchema = {};
      this.processDataSourseQuery();
      this.processDatasetDataSourceDatabase();
    }
  }

  processDataSourseQuery() {
    this.datasetOperatorConfig.datasetDataSource = {};
    this.datasetOperatorConfig.datasetDataSource.dataAsset = {};
    this.datasetOperatorConfig.datasetDataSource.dataAsset.sourceDataAssetID =
      '';
    this.datasetOperatorConfig.datasetDataSource.dataAsset.sourceDataAssetType =
      this.datasetOperatorConfig.datasetDataSourceType;

    const dataFileAsset: any = {
      // Defult Object
      fileName: '',
      processAllFilesInFolder: 'N',
      includeSubFolders: '',
      subFolderPathPattern: 'N',
      dataFileSelection: 'N',
      dataFileNamePattern: 'N',
      includeFiles: 'N',
      pastFileSet: 'N',
      quantity: '',
      fileNameContains: '',
      customDateRange: 'N',
      unit: '',
      dateFrom: '',
      dateTo: '',
      dataAssetCategoryName: ''
    };
    this.datasetOperatorConfig.datasetDataSource.dataAsset.dataFileAsset =
      dataFileAsset;
  }

  private processDatasetDataSourceDatabase() {
    const dataSourceCategoryId = this.selectedDataset.dataSourceCategoryID
      ? this.selectedDataset.dataSourceCategoryID
      : '';

    this.datasetOperatorConfig.datasetDataSource.database = {
      customerDataSourceID: this.selectedDataset.dataSourceID
        ? this.selectedDataset.dataSourceID
        : '',
      dataSourceName: this.selectedDataset.dataSourceName
        ? this.selectedDataset.dataSourceName
        : '',

      dataSourceType: dataSourceCategoryId
        ? this.udpMilOperatorService.getDataSourceType(dataSourceCategoryId)
        : this.selectedDataset.dataSourceType
        ? this.selectedDataset.dataSourceType
        : '',
      dataSourceCategory: this.selectedDataset.dataCatType
        ? this.selectedDataset.dataCatType
        : '',
      queryScript: this.selectedDataset.queryScript
        ? this.selectedDataset.queryScript
        : ''
    };

    if (
      this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE ||
      this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.QUERY_CODE ||
      this.datasetOperatorConfig.datasetDataSourceType === DATASOURCE.DATASET_CODE
    ) {
      this.codeEditorPanelService.datasetOperatorConfig = {
        fileName: `config/${this.fileName}`,
        contents: this.datasetOperatorConfig,
        TS: Math.floor(new Date().getTime() / 1000)
      };
    }
  }

  setUnits(event) {
    this.fileForm.get('unit').patchValue(event);
  }

  private processDatasetSchema() {
    this.datasetDetails = this.selectedDataset;

    // Get datasetSchema from the dropped data
    this.datasetOperatorConfig.datasetSchema = {};
    if (
      this.selectedDataset.fileSchema &&
      this.selectedDataset.fileSchema !== ''
    ) {
      this.datasetOperatorConfig.datasetSchema = convertStringToObject(
        this.selectedDataset.fileSchema
      );
    } else if (
      this.selectedDataset.datasetSchema &&
      this.selectedDataset.datasetSchema !== null &&
      this.selectedDataset.datasetSchema !== undefined &&
      this.selectedDataset.datasetSchema !== ''
    ) {
      this.datasetOperatorConfig.datasetSchema = convertStringToObject(
        this.selectedDataset.datasetSchema
      );
    } else if (
      this.selectedDataset.querySchema &&
      this.selectedDataset.querySchema !== null &&
      this.selectedDataset.querySchema !== undefined &&
      this.selectedDataset.querySchema !== ''
    ) {
      this.datasetOperatorConfig.datasetSchema = convertStringToObject(
        this.selectedDataset.querySchema
      );
    }
  }

  private processDataFileAsset(data) {
    const dataFileAsset: any = {
      // Defult Object
      fileName: '',
      processAllFilesInFolder: '',
      includeSubFolders: '',
      subFolderPathPattern: '',
      dataFileSelection: '',
      dataFileNamePattern: '',
      includeFiles: '',
      pastFileSet: '',
      quantity: '',
      fileNameContains: '',
      customDateRange: '',
      unit: '',
      dateFrom: '',
      dateTo: '',
      dataAssetCategoryName: ''
    };
    if (data.dataFileAsset === 'processFile') {
      dataFileAsset.fileName = this.selectedDataset.fileName;
      dataFileAsset.processAllFilesInFolder = 'N';
    } else if (data.dataFileAsset === 'processAllFilesInFolder') {
      dataFileAsset.processAllFilesInFolder = 'Y';
    } else {
      dataFileAsset.dataFileSelection = 'Y';
      dataFileAsset.dataFileNamePattern = data.dataFileNamePattern;

      if (data.dataFileSelection === 'ALL_FILES') {
        dataFileAsset.includeFiles = 'ALL_FILES';
      } else if (data.dataFileSelection === 'TODAYS_FILE') {
        dataFileAsset.includeFiles = 'TODAYS_FILE';
      } else if (data.dataFileSelection === 'PAST_FILE_SET') {
        dataFileAsset.includeFiles = 'PAST_FILE_SET';
        dataFileAsset.pastFileSet = 'Y';
        dataFileAsset.quantity = data.quantity ? data.quantity : '';
        dataFileAsset.unit = data.unit ? data.unit : '';
      } else if (data.dataFileSelection === 'FILE_NAME_CONTAINS') {
        dataFileAsset.includeFiles = 'FILE_NAME_CONTAINS';
        dataFileAsset.fileNameContains = data.fileNameContains;
      } else if (data.dataFileSelection === 'CUSTOM_DATE_RANGE') {
        dataFileAsset.includeFiles = 'CUSTOM_DATE_RANGE';
        dataFileAsset.customDateRange = 'Y';
        if (data.from) {
          if (typeof data.from === 'string' && data.from.includes('/')) {
            dataFileAsset.dateFrom = data.from;
          } else {
            dataFileAsset.dateFrom = data.from.toISOString();
          }
        }
        if (data.to) {
          if (typeof data.to === 'string' && data.to.includes('/')) {
            dataFileAsset.dateTo = data.to;
          } else {
            dataFileAsset.dateTo = data.to.toISOString();
          }
        }
      }
    }
    if (data.includeSubFolders) {
      dataFileAsset.includeSubFolders = 'Y';
      dataFileAsset.subFolderPathPattern = data.subFolderPathPattern;
    }

    dataFileAsset.dataAssetCategoryName = this.selectedDataset.bucketName
      ? this.selectedDataset.bucketName
      : '';

    if (
      this.datasetOperatorConfig &&
      this.datasetOperatorConfig.datasetDataSource
    ) {
      this.datasetOperatorConfig.datasetDataSource.dataAsset = {};
      this.datasetOperatorConfig.datasetDataSource.dataAsset.sourceDataAssetID =
        this.selectedDataset.dataSourceID;
      this.datasetOperatorConfig.datasetDataSource.dataAsset.sourceDataAssetType =
        this.selectedDataset.dataSourceType;
      this.datasetOperatorConfig.datasetDataSource.dataAsset.dataFileAsset =
        dataFileAsset;
    }
  }

  private onDataSetSavedWarning() {
    this.confirmDialogService.show({
      message: `Please provide a saved query before saving the data asset!`,
      buttonOK: `Ok`,
      callback: () => {}
    });
  }

  onRemoveDataAsset() {
    const dialogRef = this.confirmDialogService.show({
      message: `Are you sure you want to remove selected data asset?`,
      buttonCancel: 'Cancel',
      buttonOK: `Ok`,
      callback: () => {}
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.selectedDataset = null;
        this.querySelectedata = null;
        this.queryDatasetSchema = null;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
}
