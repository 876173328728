import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material';

import { LoaderComponent } from './loader.component';

const imports = [
    MatProgressSpinnerModule
];

const components = [
    LoaderComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class LoaderModule { }
