import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

import { QueryVariable } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-query-variables-dialog-grid',
    templateUrl: './dialog-grid.component.html',
    styleUrls: ['./dialog-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryVariablesDialogGridComponent {
    @Input()
    variables: QueryVariable[];

    @Output()
    variableDelete = new EventEmitter<QueryVariable>();

    @Output()
    variableEdit = new EventEmitter<QueryVariable>();
}
