import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuItemModel } from '@xfusiontech/shared';
import { Observable } from 'rxjs';
import { User } from './../../components/header/user.model';
import { MENU_ITEMS } from './../../consts/menu';
import { DASHBOARD_PATH } from './../../consts/paths';
import { HomePageService } from './home-page.service';

@Component({
    selector: 'igv-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {
    user$: Observable<User>;

    menuItems: MenuItemModel[];
    homeItemPath: string;
    databaseName: string;

    constructor(private service: HomePageService) {}

    ngOnInit() {
        this.service.queryUserInfo();
        this.user$ = this.service.getUserInfo();

        this.menuItems = MENU_ITEMS;
        this.homeItemPath = DASHBOARD_PATH;
        this.databaseName = 'Database name';
    }

    onLogoutClick() {
        this.service.logout();
    }
}
