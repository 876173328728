/**
 * @author: Naga
 * @constant
 */
const __DEFAULTS__: any = {
  CO: {
    ZIP: {
      id: '1',
      label: 'Zip',
      hint: '.zip'
    },
    FILE: {
      id: '2',
      label: 'File(s)',
      hint: '.py,.json,.csv,.txt,.scala,.class & .sql'
    },
    FOLDER: {
      id: '3',
      label: 'Folder(s)',
      hint: '.py,.json,.csv,.txt,.scala,.class & .sql'
    }
  },
  DAG: {
    ZIP: {
      id: '1',
      label: 'Zip',
      hint: '.zip'
    },
    FILE: {
      id: '2',
      label: 'File',
      hint: '.py,.json,.csv,.txt,.scala,.class & .sql'
    }
  }
};

/**
 * @constant
 */
const getUploadOpts: any = (isDag?: boolean): any[] => {
  let cfg: any = __DEFAULTS__.CO;

  if (isDag) {
    cfg = __DEFAULTS__.DAG;
  }
  return Object.values(cfg);
};

/**
 * @enum
 * @exports
 */
export enum UploadType {
  Zip = '1',

  File = '2',

  Folder = '3'
}

/**
 * @constant
 */
const ALLOWED_EXTNS: any = {
  1: '.zip',
  2: '.py,.json,.csv,.txt,.scala,.class,.sql',
  3: '.py,.json,.csv,.txt,.scala,.class,.sql'
};

/**
 * @constant
 */
export const OS_FILES: string[] = ['__MACOSX'];

/**
 * @constant
 * @exports
 */
export const getUploadConfig: any = (isDag?: boolean): any => {
  return {
    get: getUploadOpts,
    uploadType: UploadType,
    allowedExtns: ALLOWED_EXTNS,
    options: getUploadOpts(isDag)
  };
};
