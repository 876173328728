import { NgModule } from '@angular/core';
import { DiagramComponent } from './diagram.component';

@NgModule({
    declarations: [
        DiagramComponent
    ],
    exports: [
        DiagramComponent
    ]
})
export class DiagramModule { }
