import * as _ from 'lodash/fp';

import { mapParameterTypeToName } from '../../properties-editor/utils/mapParameterTypeToName]';
import { AvailableType, ParameterType, UnionInputPayload } from '../../../types/form';
import { normalizeAvailableType } from './normalizeAvailableType';
import { TaskCatalogItemParameter } from '../../../types/taskCatalog';
import { mapParameterTypeToInputType } from '../../properties-editor/utils/mapParameterTypeToInputType';
import { InputType } from '../../shared/form-field/form-field.types';
import { InputValue } from '../../../types/form';

const parseAvailableType = (
    availableType: string,
    typeSubCategories: string[],
    index: number
) => {
    if (!typeSubCategories || !typeSubCategories[index]) {
        return availableType;
    }

    switch (typeSubCategories[index]) {
        case 'elem':
        case 'complex': {
            return availableType;
        }
        default: {
            return typeSubCategories[index];
        }
    }
};

const getAvailableTypes = (parameterType: ParameterType) => {
    const regex = /Union *\[(?<types>(?:.+,? *)+)\]/;
    const match = parameterType.match(regex);
    if (!match) {
        return [];
    }
    return match.groups.types
        .split(',')
        .map((type) => type.trim());
};

const mapAvailableTypesToInputModel = (typeSubCategories: string[]) => (
    availableTypes: ParameterType[]
) =>
    availableTypes.map((type: ParameterType, i: number) => ({
        label: _.flowRight(
            mapParameterTypeToName,
            normalizeAvailableType
        )(type),
        type: parseAvailableType(type, typeSubCategories, i)
    }));

const buildAvailableTypesParameter = (
    parameterType: ParameterType,
    typeSubCategories: string[]
): AvailableType[] =>
    _.flowRight(
        mapAvailableTypesToInputModel(typeSubCategories),
        getAvailableTypes
    )(parameterType);

export const mapUnionToInputValue = (
    parameterValue: any,
    param: TaskCatalogItemParameter,
    mapToInputValue: (
        value: string,
        formInputType: InputType,
        parameter: TaskCatalogItemParameter
    ) => InputValue
): UnionInputPayload => {
    const inputType = mapParameterTypeToInputType(
        param.userValueType as ParameterType,
    );

    const value = inputType === 'union'
        ? parameterValue
        : mapToInputValue(parameterValue, inputType, param);

    return {
        value,
        selectedParameterType: param.userValueType as ParameterType,
        availableParameterTypes: buildAvailableTypesParameter(
            param.type as ParameterType,
            param.typeSubCategories
        )
    };
};
