export class ScoringConfiguration {
    /*Scoring Configuration Add Popup data*/
    public subjectRelatedSet = [
        { description: "Child Person", id: "Child Person" },
        { description: "Facility", id: "Facility" },
        { description: "Licensee", id: "Licensee" }
    ];
    public scroingRuleSet = [
        { description: "Child Person RuleSet", id: "Child Person RuleSet", parentID: "Child Person" },
        { description: "Facility Ruleset", id: "Facility Ruleset", parentID: "Facility" },
        { description: "Licensee Ruleset", id: "Licensee Ruleset", parentID: "Licensee" }
    ];
    public ruleParameterSet = [
        { description: "FirstName", id: "FirstName", parentID: "Child Person RuleSet" },
        { description: "LastName", id: "LastName", parentID: "Child Person RuleSet" },
        { description: "MiddleName", id: "MiddleName", parentID: "Child Person RuleSet" },
        { description: "DateOfBirth", id: "DateOfBirth", parentID: "Child Person RuleSet" },
        { description: "IdentificationNumber", id: "IdentificationNumber", parentID: "Child Person RuleSet" },
        { description: "SexatBirthCode", id: "SexatBirthCode", parentID: "Child Person RuleSet" },
        { description: "ZipCode", id: "ZipCode", parentID: "Child Person RuleSet" },
        { description: "StreetName", id: "StreetName", parentID: "Child Person RuleSet" },
        { description: "RaceCode", id: "RaceCode", parentID: "Child Person RuleSet" },
        { description: "EthnicityCode", id: "EthnicityCode", parentID: "Child Person RuleSet" }
    ];
    public targetValueSet = [
        { description: "PersonName:FirstName", id: "PersonName:FirstName", parentID: "Child Person RuleSet" },
        { description: "PersonName:LastName", id: "PersonName:LastName", parentID: "Child Person RuleSet" },
        { description: "PersonName:MiddleName", id: "PersonName:MiddleName", parentID: "Child Person RuleSet" },
        { description: "Person:DateOfBirth", id: "Person:DateOfBirth", parentID: "Child Person RuleSet" },
        { description: "Identification:SSN", id: "Identification:SSN", parentID: "Child Person RuleSet" },
        { description: "Person:SexatBirthCode", id: "Person:SexatBirthCode", parentID: "Child Person RuleSet" },
        { description: "StructuredAddress:ZipCode", id: "StructuredAddress:ZipCode", parentID: "Child Person RuleSet" },
        { description: "StructuredAddress:StreetName", id: "StructuredAddress:StreetName", parentID: "Child Person RuleSet" },
        { description: "Race:RaceCode", id: "Race:RaceCode", parentID: "Child Person RuleSet" },
        { description: "Ethnicity:EthinicityCode", id: "Ethnicity:EthinicityCode", parentID: "Child Person RuleSet" }
    ];
    /*Scoring Configuration Add Popup data*/
}
