import { FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SlideToggleSize, SlideToggleDirection } from './slide-toggle.types';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'udp-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlideToggleComponent implements AfterViewInit {
  @Input()
  size: SlideToggleSize = 'default';

  @Input()
  direction: SlideToggleDirection = 'default';

  @Input()
  label: string;

  @Input()
  value: boolean;

  @Input()
  disabled: boolean;

  /**
   * @public
   * @type: {string}
   * @input
   */
  @Input()
  public control: string;

  /**
   * @public
   * @type: {group<FormGroup>}
   * @input
   */
  @Input()
  public group: FormGroup;

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange(e: MatSlideToggleChange) {
    this.valueChange.emit(e.checked);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngAfterViewInit(): void {
    if (this.group && this.control && this.disabled) {
      this.group.get(this.control).disable();
    }
  }
}
