import * as _ from 'lodash/fp';

export const mapDateToInputValue = (dateValue) => {
    if (dateValue != null) {
        if (typeof dateValue === 'object') {
            return dateValue.value || dateValue;
        } else if (typeof dateValue === 'string') {
            return dateValue;
        }
    }
};
