export class SearchConfiguration {
  constructor() {}

  searchConfigurationResponce: any[] = [
    {
      configurationId: "1",
      integration_pid: "CWDS-CMS",
      title: "Person1",
      subjectInfo: {
        relatedSubjectId: "Person",
        relatedSubject: "Person",
        classNameId: "1",
        className: "PatientAdapter",
        queryConfigurationId: "1",
        queryConfiguration: "Query 1"
      },
      customQuery: [
        {
          queryNameId: "1",
          queryName: "findByFirstNameLastNameDOB",
          parameterEntity: [
            {
              parameterId: "1",
              parameter: "String:FirstName",
              targetDbEntityNameId: 1,
              targetDbEntityName: "PersonName:FirstName"
            },
            {
              parameterId: "2",
              parameter: "String:LastName",
              targetDbEntityNameId: 4,
              targetDbEntityName: "PersonName:LastName"
            }
          ]
        },
        {
          queryNameId: "3",
          queryName: "findByStreetAddressZipCode",
          parameterEntity: [
            {
              parameterId: "4",
              parameter: "Varchar:SSN",
              targetDbEntityNameId: "5",
              targetDbEntityName: "PersonSSN:SSN"
            }
          ]
        }
      ]
    }
  ];
}
