import * as _ from 'lodash';
import { UdpGrid } from './../shared/classes/udp-grid';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, SimpleChanges } from '@angular/core';

/**
 * @author: Naga
 */
@Component({
  selector: 'view-imported-data',
  templateUrl: './view-data.component.html',
  styleUrls: [
    './view-data.component.scss',
    './../shared/styles/udp-table.scss'
  ]
})
export class ViewDataComponent extends UdpGrid implements OnInit {

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.init();
    super.ngOnInit();
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the imported data view.
   */
  private init(): void {
    // capture the data here
    const { fileRecords }: any = this.entity;
    const { attributes }: any = this.entity.fileSchema;

    // transform data to a simplified format
    const data: any = this.transformData(fileRecords, attributes);

    // set the data and displayed columns here
    this.dataSource = new MatTableDataSource<any>(data);
    this.displayedColumns = _.map(attributes, 'attributeName');
  }

  /**
   * @private
   * @param: {records<any[]>}
   * @param: {attrs<any[]>}
   * @return: void
   * @description: a helper method that transforms
   * the data to simplified format.
   */
  private transformData(records: any[], attrs: any[]): any[] {
    const arr: any[] = [];

    for (const rec of records) {
      const obj: any = {};

      for (let i = 0; i < rec.length; i++) {
        const item: any = attrs[i];
        const name: string = item.attributeName;

        obj[name] = rec[i];
      }
      arr.push(obj);
    }
    return arr;
  }
}
