export const DATASETS = [
    {
        "id": 3,
        "dataAssetType": "Query",
        "dataAssetCode": "QRY",
        "icon": "assets/Complete-Icon-Set/DMG-Query.svg",
        "dataAssets": [
            {
                "dataSetDataSource": [],
                "dataSetID": "786aab84-c83d-4bce-9bab-50f14779fbc2",
                "dataSetName": "Query 1",
                "datasetSchema": {}
            },
            {
                "dataSetDataSource": [],
                "dataSetID": "1e894e84-54e7-46c2-a72d-e3a20d8bd4d1",
                "dataSetName": "Query 2",
                "datasetSchema": {}
            },
            {
                "dataSetDataSource": [],
                "dataSetID": "033dd27e-c5b7-4d72-b38a-776003512bb7",
                "dataSetName": "Query 3",
                "datasetSchema": {}
            },
            {
                "dataSetDataSource": [],
                "dataSetID": "982f1ee6-9d6f-46a1-be9f-3b885247fe2d",
                "dataSetName": "Query 4",
                "datasetSchema": {}
            },
            {
                "dataSetDataSource": [],
                "dataSetID": "d063f24a-8230-49e3-bb09-cbdc5be0e740",
                "dataSetName": "Query 5",
                "datasetSchema": {}
            }
        ]
    }
]

export const DATA_TABS = [
    {
        id:0,
        name:'Data'
    },
    {
        id:1,
        name:'Meta Data'
    },
]

export const GET_QUERY = [
    {
        "dataSetDataSource": [
            // {
            //     "dataSetDataSourceID": "1",
            //     "dataSetDataSourceName": "Test Query"
            // }
        ],
        "dataSetID": "786aab84-c83d-4bce-9bab-50f14779fbc2",
        "dataSetName": "Query 1",
        "datasetSchema": {}
    },
    {
        "dataSetDataSource": [],
        "dataSetID": "1e894e84-54e7-46c2-a72d-e3a20d8bd4d1",
        "dataSetName": "Query 2",
        "datasetSchema": {}
    },
    {
        "dataSetDataSource": [],
        "dataSetID": "033dd27e-c5b7-4d72-b38a-776003512bb7",
        "dataSetName": "Query 3",
        "datasetSchema": {}
    },
    {
        "dataSetDataSource": [],
        "dataSetID": "982f1ee6-9d6f-46a1-be9f-3b885247fe2d",
        "dataSetName": "Query 4",
        "datasetSchema": {}
    },
    {
        "dataSetDataSource": [],
        "dataSetID": "d063f24a-8230-49e3-bb09-cbdc5be0e740",
        "dataSetName": "Query 5",
        "datasetSchema": {}
    }
]