import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

/**
 * @author: Naga
 */
export class UdpGrid implements OnInit, AfterViewInit {

  /**
   * @public
   * @type: {any}
   * @input
   */
  @Input()
  public entity: any;

  /**
   * @public
   * @type: {string}
   * @input
   */
  @Input()
  public headerText: string = '';

  /**
   * @public
   * @type: {string}
   * @input
   */
  @Input()
  public customerImportFileId: string;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public elevatedStyle: boolean = true;

  /**
   * @public
   * @type: {MatSort}
   * @viewchild
   */
  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  /**
   * @public
   * @type: {string[]}
   * @input
   */
  @Input()
  public displayedColumns: string[] = [];

  /**
   * @public
   * @type: {MatPaginator}
   * @viewchild
   */
  @ViewChild(MatPaginator, { static: false })
  public paginator: MatPaginator;

  /**
   * @public
   * @type: {MatTableDataSource<any>}
   */
  public dataSource: MatTableDataSource<any>;

  /**
   * @constructor
   */
  constructor() {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.dataSource.filterPredicate = this.predicate.bind(this);
  }

  /**
   * @public
   * @param: {data<any>}
   * @param: {filter<string>}
   * @return: void
   * @description: a helper method that transforms
   * the filters out the data based on requested
   * look up value.
   */
  public predicate(data: any, filter: string): boolean {
    let obj: any = data;

    // in case of editable mat table
    // data would be returned in the
    // form of a form group
    if (data instanceof FormGroup) {
      obj = data.value;
    }

    const str: string = _.toLower(_.join(_.values(obj), ','));
    return _.includes(str, filter);
  }

  /**
   * @private
   * @param: {value<string>}
   * @return: void
   * @description: a helper method that sets
   * the mat filter value.
   */
  public applyFilter(value: string): void {
    value = value.toLowerCase().trim();
    this.dataSource.filter = value;
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
