export const DISP_COLUMNS_CONFIG: any = {
  attributeSequenceNumber: {
  name: 'Code',
  field: 'code',
  controlName: 'code'
},
attributeName: {
  name: 'Value',
  field: 'val',
  controlName: 'val'
},
attributeAliasName: {
  name: 'Mapped Source Value',
  field: 'svarr',
  controlName: 'svarr',
  fieldType: 'MSV'
}
};

export interface ElementData {
  code: string;
  val: string;
  svarr: Array<any>;
}
