import { ReplaySubject } from 'rxjs';
import { MESSAGES } from '../shared/constants/i18n';
import { UPLOAD_CONFIG } from './import-dataset.constants';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

// Library Imports
import { SnackbarService } from './../../../../shared';
import { UploadConfig } from './../../../../data-import';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-import-dataset',
  templateUrl: './import-dataset.component.html',
  styleUrls: ['./import-dataset.component.scss']
})
export class ImportDatasetComponent implements OnInit, OnDestroy {

  /**
   * @public
   * @type: {any}
   */
  public i18n: any;

  /**
   * @public
   * @type: {string}
   */
  public actionType: string;

  /**
   * @public
   * @type: {UploadConfig}
   */
  public uploadConf: UploadConfig;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public fullView: boolean = true;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {snackbarService<SnackbarService>}
   */
  constructor(public snackbarService: SnackbarService) {
    this.i18n = MESSAGES;
    this.uploadConf = UPLOAD_CONFIG;
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {}

  /**
   * @public
   * @param: {action<string>}
   * @return: void
   * @description: a helper method that
   * that sets the action type.
   */
  public onActionSelect(action: string): void {
    this.actionType = action;
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
