import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
//import scoringConfigurationData from "scoring_configuration_data.json";
import { ScoringConfiguration } from "./scoring-configuration-model";
import { IntegrationWorkflowService } from 'src/app/workflow-editor/integration-workflow.service';

@Component({
  selector: "app-scoring-configuration",
  templateUrl: "./scoring-configuration.component.html",
  styleUrls: ["./scoring-configuration.component.scss"]
})
export class ScoringConfigurationComponent implements OnInit {
  public scoringConfigurationDataObj = [];
  scoringConfigurationForm: FormGroup;
  isEdit = false;
  @Input() parentData: any;
  @Input() scoringConfigurationData = [];
  @Input() dropDownData: any[];

  dataModel1: ScoringConfiguration;
  relatedSubjectDropdownOptions: any[];
  relatedSubjectRawDropdownOptions: any[];

  sRuleSetDropdownOptions: any[];
  ruleParameterDropdownOptions: any[];
  ruleParameterNameDropdownOptions: any[];
  entityNameDropdownOptions: any[];
  targetDropdownOptions: any[];
  ruleParameterData = [];
  targetParameterData = [];
  popuptitle = "";
  popupbutton = "";
  editArray = [];
  isEditPopUp = false;
  config = {
    displayKey: "description", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    // customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 500, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    // searchPlaceholder:'Search' // label thats displayed in search input,
    // searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
  };

  config1 = {
    displayKey: "description", //if objects array passed which key to be displayed defaults to description
    search: false, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    // customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 500, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "", // text to be displayed when no items are found while searching
    // searchPlaceholder:'Search' // label thats displayed in search input,
    // searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
  };

  constructor(
    private _fb: FormBuilder,
    private _workFlowService: IntegrationWorkflowService
  ) {
    this.dataModel1 = new ScoringConfiguration();
  }

  @ViewChild("CloseWorkFlow1", { static: false }) CloseWorkFlow1;
  ngOnInit() {
    // this.scoringConfigurationDataObj = scoringConfigurationData.filter(
    //   i => i.integration_pid == this.parentData.TargetDataTypeCode
    // );
    // this.relatedSubjectDropdownOptions = this.dataModel1.subjectRelatedSet;
    this.scoringConfigurationForm = this._fb.group({
      subjectRelatedType: ["", Validators.required],
      scroingRuleSetType: ["", Validators.required],
      details: this._fb.array([this.getScoringDetails()])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.scoringConfigurationDataObj = [];
    if (
      this.scoringConfigurationData &&
      this.scoringConfigurationData.length > 0
    ) {
      this.scoringConfigurationData.map(e1 => {
        if (e1 && this.parentData.WorkflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "score") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              // let jsonObj = JSON.parse(e1.IntegrationWorkflowComponentVersion);
              // if (jsonObj) {
              //   let data = [];
              //   data = jsonObj;
              //   data["id"] = e1.WorkflowComponentTypes.id;
              //   data["parentid"] = e1.IntegrationWorkflowID;
              //   this.scoringConfigurationDataObj.push(data);
              // }
              JSON.parse(e1.IntegrationWorkflowComponentVersion).map(e2 => {
                if (e2 && e2 !== null) {
                  let data = [];
                  data = e2;
                  data["id"] = e1.WorkflowComponentTypes.id;
                  data["parentid"] = e1.IntegrationWorkflowID;
                  this.scoringConfigurationDataObj.push(data);
                }
              });
            }
          }
        }
      });

      this.getSubjectDropDownData();
    }
  }

  setchild() {
    const control = <FormArray>(
      this.scoringConfigurationForm.controls["details"]
    );
    //control.removeAt(i);
    while (control.length > 1) {
      control.removeAt(0);
    }
  }
  getPopupTitle() {
    this.popuptitle = "Add";
    this.popupbutton = "Save";
  }

  extractChildren(obj) {
    let keysArray = Object.keys(obj);
    keysArray = keysArray.filter(e => e !== "integration");
    return keysArray;
  }

  getScoringDetails(): FormGroup {
    return this._fb.group({
      ruleParameterElement: [""],
      targetValueElemet: [""]
    });
  }
  addRuleParameter() {
    (<FormArray>this.scoringConfigurationForm.get("details")).push(
      this.getScoringDetails()
    );
  }
  deleteRuleParameter(i) {
    if (i > 0) {
      const control = <FormArray>(
        this.scoringConfigurationForm.controls["details"]
      );
      control.removeAt(i);
    }
  }
  get f1() {
    return this.scoringConfigurationForm.controls;
  }

  //Submitting the Scoring Configuration form
  onSubmitScoringConfiguration() {
    let identificationsArray = [];
    this.scoringConfigurationForm.value.details.map(e => {
      identificationsArray.push({
        ruleParameter: e.ruleParameterElement.description,
        targetValue: e.targetValueElemet.description
      });
    });

    let formdata: any;
    let scoringRuleSetName = "";
    if (this.scoringConfigurationForm.value.scroingRuleSetType.description) {
      scoringRuleSetName = this.scoringConfigurationForm.value
        .scroingRuleSetType.description;
    } else {
      scoringRuleSetName = this.scoringConfigurationForm.value
        .scroingRuleSetType;
    }
    //converyting Submitted form data into API format
    formdata = {
      integration_pid: this.parentData.sourceDataTypeCode,
      matchScores: [
        {
          subject: {
            subjectName: this.scoringConfigurationForm.value.subjectRelatedType
              .description,
            subjectPhysicalName: this.scoringConfigurationForm.value
              .subjectRelatedType.description,
            subjectType: this.scoringConfigurationForm.value.subjectRelatedType
              .description,
            subjectClassType: "PRIMARY"
          },
          scoringRule: {
            subjectScoringRuleSetName: scoringRuleSetName,
            rulesParameters: identificationsArray
          }
        }
      ]
    };

    if (this.isEditPopUp === true) {
      this.scoringConfigurationDataObj.filter((e, index) => {
        if (JSON.stringify(e) === JSON.stringify(this.editArray)) {
          this.scoringConfigurationDataObj.splice(index, 1);
        }
      });
    }
    this.scoringConfigurationDataObj.push(formdata);

    let saveObj = {
      Source: 2,
      SourceDataTypeCode: this.parentData.sourceDataTypeCode,
      Status: this.parentData.status,
      TargetDataTypeCode: this.parentData.targetDataTypeCode,
      WorkflowID: this.parentData.workflowID,
      WorkflowComponentTypeCode: "score",
      IntegrationWorkflowComponentName: "Scoring Data",
      IntegrationWorkflowComponentStatus: "Active",
      WorkflowComponent: JSON.stringify(this.scoringConfigurationForm.value)
    };
    this._workFlowService
      .updateIntegrationWorkflowInnerModule(saveObj)
      .subscribe(result => {
        this.scoringConfigurationForm.reset();
        this.CloseWorkFlow1.nativeElement.click();
        this.ruleParameterDropdownOptions = [];
        this.targetDropdownOptions = [];
      });

    this.isEditPopUp = false;
  }

  resetAttributes() {
    this.ruleParameterDropdownOptions = [];
    this.targetDropdownOptions = [];
  }

  relatedSubjectSelectionChanged(event) {
    // this.sRuleSetDropdownOptions = [];
    // this.dataModel1.scroingRuleSet.map(e => {
    //   if (e.parentID === event.value.description) {
    //     this.scoringConfigurationForm.patchValue({
    //       scroingRuleSetType: e.description
    //     });
    //     this.scoringConfigurationForm.updateValueAndValidity();
    //     this.classSelectionChanged(e.description);
    //   }
    // });
    this.getScorentRuleSet(event.value);
  }
  getScorentRuleSet(selectedSubject) {
    if (
      this.dropDownData.filter(i => i.SubjectEntityName == selectedSubject)
        .length > 0
    ) {
      this.sRuleSetDropdownOptions = this.dropDownData
        .find(i => i.SubjectEntityName == selectedSubject)
      ["ScoringConfigurationMap"].map(i => i.ScoringConfigurationRuleSetName);
      // this.sRuleSetDropdownOptions = this.dropDownData
      //   .filter(i => i.SubjectEntityName == selectedSubject)
      //   ["ScoringConfigurationMap"].map(i => i.ScoringConfigurationRuleSetName);
    }
  }
  getRuleParameter() { }
  relatedSubjectFilterData(event) {
    this.relatedSubjectDropdownOptions = this.dataModel1.subjectRelatedSet.filter(
      i => i.description.toLowerCase().includes(event.toLowerCase())
    );
  }

  classSelectionChanged(event) {
    // this.setchild();
    // this.scoringConfigurationForm.patchValue({
    //   ruleParameterElement: [""],
    //   targetValueElemet: [""]
    // });
    // this.scoringConfigurationForm.updateValueAndValidity();
    // this.ruleParameterData = [];
    // this.targetParameterData = [];
    // this.ruleParameterDropdownOptions = this.ruleParameterData;
    // this.targetDropdownOptions = this.targetParameterData;
    // this.dataModel1.ruleParameterSet.map(e => {
    //   if (e.parentID === event) {
    //     this.ruleParameterData.push({ id: e.id, description: e.description });
    //   }
    // });
    // this.ruleParameterDropdownOptions = this.ruleParameterData;
    // this.dataModel1.targetValueSet.map(e => {
    //   if (e.parentID === event) {
    //     this.targetParameterData.push({ id: e.id, description: e.description });
    //   }
    // });
    // this.targetDropdownOptions = this.targetParameterData;
    let selectedSubject = this.scoringConfigurationForm.get(
      "subjectRelatedType"
    ).value;
    if (
      this.dropDownData.filter(i => i.SubjectEntityName == selectedSubject)
        .length > 0
    ) {
      this.ruleParameterDropdownOptions = this.dropDownData
        .find(i => i.SubjectEntityName == selectedSubject)
      ["ScoringConfigurationMap"].find(
        i => i.ScoringConfigurationRuleSetName == event.value
      )
        .RulesetParameters.map(i => i.RulesetParameterDataName);
    }
  }

  sRuleSetFilterData() {
    // this.sRuleSetDropdownOptions = this.dataModel1.scroingRuleSet.filter(i =>
    //   i.description.toLowerCase().includes(event.toLowerCase())
    // );
  }
  editScoringConfiguration(queryData) {
    //Calling setChild to remove extra text fields
    this.setchild();
    this.editArray = [];
    this.popuptitle = "Edit";
    this.popupbutton = "Update";
    if (
      this.scoringConfigurationData &&
      this.scoringConfigurationData.length > 0
    ) {
      this.scoringConfigurationData.map(e1 => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "score") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              JSON.parse(e1.IntegrationWorkflowComponentVersion).map(
                (e2, index) => {
                  if (e2 && e2 !== null) {
                    if (index === queryData) {
                      let data = [];
                      data = e2;
                      data["id"] = e1.WorkflowComponentTypes.id;
                      data["parentid"] = e1.IntegrationWorkflowID;
                      this.editArray = data;
                    }
                  }
                }
              );
            }
          }
        }
      });
    }

    //Retriving the Rule parameter and target value based on the scoring rule set name
    this.classSelectionChanged(
      this.editArray["matchScores"][0]["scoringRule"][
      "subjectScoringRuleSetName"
      ]
    );

    this.scoringConfigurationForm.setValue({
      subjectRelatedType: {
        id: this.editArray["matchScores"][0]["subject"]["subjectName"],
        description: this.editArray["matchScores"][0]["subject"]["subjectName"]
      },
      scroingRuleSetType: {
        id: this.editArray["matchScores"][0]["scoringRule"][
          "subjectScoringRuleSetName"
        ],
        description: this.editArray["matchScores"][0]["scoringRule"][
          "subjectScoringRuleSetName"
        ]
      },
      details: this.editScoringParametrs(
        this.editArray["matchScores"][0]["scoringRule"]["rulesParameters"]
      )
    });

    this.isEditPopUp = true;
  }

  editScoringParametrs(cq) {
    let grpRows = [];
    let r = 0;
    cq.forEach(element => {
      if (r > 0) {
        this.addRuleParameter();
      }
      grpRows.push(
        // this._fb.group(
        {
          ruleParameterElement: {
            id: element.ruleParameter,
            description: element.ruleParameter
          },
          targetValueElemet: {
            id: element.targetValue,
            description: element.targetValue
          }
        }
        // )
      );
      r = r + 1;
    });
    return grpRows;
  }

  deleteScoringConfiguration(deleteid) {
    this.editArray = [];
    if (
      this.scoringConfigurationData &&
      this.scoringConfigurationData.length > 0
    ) {
      this.scoringConfigurationData.map(e1 => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "score") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              JSON.parse(e1.IntegrationWorkflowComponentVersion).map(
                (e2, index) => {
                  if (e2 && e2 !== null) {
                    if (index === deleteid) {
                      let data = [];
                      data = e2;
                      data["id"] = e1.WorkflowComponentTypes.id;
                      data["parentid"] = e1.IntegrationWorkflowID;
                      this.editArray = data;
                    }
                  }
                }
              );
            }
          }
        }
      });
    }

    this.scoringConfigurationDataObj.filter((e, index) => {
      if (JSON.stringify(e) === JSON.stringify(this.editArray)) {
        this.scoringConfigurationDataObj.splice(index, 1);
      }
    });

    let saveObj = {
      Source: 2,
      SourceDataTypeCode: this.parentData.sourceDataTypeCode,
      Status: this.parentData.status,
      TargetDataTypeCode: this.parentData.targetDataTypeCode,
      WorkflowID: this.parentData.workflowID,
      WorkflowComponentTypeCode: "score",
      IntegrationWorkflowComponentName: "Scoring Data",
      IntegrationWorkflowComponentStatus: "Active",
      WorkflowComponent: JSON.stringify(this.scoringConfigurationDataObj)
    };
    this._workFlowService
      .updateIntegrationWorkflowInnerModule(saveObj)
      .subscribe(result => {
        console.info("response", result.data);
      });
  }
  getSubjectDropDownData() {
    if (this.dropDownData && this.dropDownData.length > 0) {
      this.relatedSubjectRawDropdownOptions = this.dropDownData.map(
        i => i.SubjectEntityName
      );
      this.relatedSubjectDropdownOptions = this.relatedSubjectRawDropdownOptions;
    }
  }
}
