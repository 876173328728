import { trigger, transition, style, animate } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate('200ms', style({
            opacity: 1
        })),
    ]),
    transition(':leave', [
        animate('200ms', style({
            opacity: 0
        }))
    ])
]);

export const slideInOut = trigger('slideInOut', [
    transition(':enter', [
        style({
            transform: 'translateX(100%)'
        }),
        animate('200ms ease-out', style({
            transform: 'translateX(0)'
        })),
    ]),
    transition(':leave', [
        animate('200ms ease-in', style({
            transform: 'translateX(100%)'
        }))
    ])
]);
