import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {
    dataAssetType,
    Query,
    Visualization,
} from '@xfusiontech/data-visualizer';
import { ShareByEmailDialogComponent } from './share-by-email-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ShareByEmailDialogService {
    constructor(private dialog: MatDialog) {}

    show(data: {
        type: dataAssetType;
        entity: Visualization | Query;
    }): MatDialogRef<ShareByEmailDialogComponent, any> {
        return this.dialog.open(ShareByEmailDialogComponent, { data });
    }
}
