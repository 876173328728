import { takeUntil } from 'rxjs/operators';
import { Point } from '../../types/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { PaletteService } from './palette.service';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { OPERATORS_UPLOAD_API } from '../../consts/api';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TaskCatalogService } from '../../services/task-catalog.service';
import { UploadOperatorsComponent } from '@xfusiontech/upload-operators';
import { PaletteGroup, PaletteItem, PaletteSubgroup } from '../../types/palette';
import { DiagramPaletteService } from '../../gojs/services/diagram-palette.service';
import { CodeEditorPanelService } from '../code-editor-panel/code-editor-panel.service';

@Component({
  selector: 'xft-palette',
  templateUrl: './palette.component.html',
  styleUrls: ['./palette.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaletteComponent implements OnDestroy {
  /**
   * @private
   * @type: {Point}
   */
  private pointerPosition: Point;

  /**
   * @public
   * @type: {Observable<PaletteGroup[]>}
   */
  public groups$: Observable<PaletteGroup[]>;

  /**
   * @private
   * @type: {MatDialogRef<UploadOperatorsComponent>}
   */
  private dialogRef: MatDialogRef<UploadOperatorsComponent>;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private dialog: MatDialog,
    public paletteService: PaletteService,
    private taskCatalogService: TaskCatalogService,
    private diagramPaletteService: DiagramPaletteService,
    private service: CodeEditorPanelService,
  ) {
    this.groups$ = paletteService
      .getPaletteGroups()
      .pipe(takeUntil(this.destroy$));
  }

  trackByItems = (idx: number, { label }: PaletteItem) => label;
  trackByGroups = (idx: number, { name }: PaletteGroup) => name;
  trackBySubgroups = ({ name }: PaletteSubgroup) => name;

  onDragReleased(item: PaletteItem): void {
    this.paletteService.blendDataSet.next({blendDataSet: item})
    if (item.label.includes('UDP')) {
      const newstr: string = item.label.replace('UDP', 'UDP');
      // item.imagePath = '../assets/icons/udp-icon.png';
      item.label = newstr;
    }
    if (item.subcategory === 'amazon' || item.subcategory === 'Amazon') {
      item.imagePath = '../assets/icons/aws-icon.png';
      if (item.label.includes('Aws')) {
        const newstr: string = item.label.replace('AWS', 'AWS');
        item.label = newstr;
      }
    } else if (item.category === 'airflow') {
      item.imagePath = '../assets/icons/airflow-logo.png';
    } else {
      item.label = item.label;
    }
    this.diagramPaletteService.paletteItemDropped(item, this.pointerPosition);
    // setTimeout(() => {
    //   // this.service.fetchPythonCode();
    //   this.service.openJsonEditor();
    //   this.service.saveChanges();
    //   // this.service.openPythonEditor();
    //   this.service.setPythonContent('')
      // this.service.pythonCode();

    // }, 2000);
  }

  onDragMoved(event: CdkDragMove) {
    this.pointerPosition = event.pointerPosition;
  }

  onRefresh() {
    this.taskCatalogService.fetchTaskCatalog();
  }

  /**
   * @public
   * @returns: void
   * @description: a helper method
   * that opens up upload operator
   * modal.
   */
  public openUploadModal(): void {
    this.dialogRef = this.dialog.open(UploadOperatorsComponent, {
      width: '575px',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      panelClass: 'xft-dialog',
      data: {
        domain: OPERATORS_UPLOAD_API,
        env: environment
      }
    });

    this.dialogRef
    .afterClosed()
    .pipe(takeUntil(this.destroy$))
    .subscribe((v) => {
      if (v) {
        this.onRefresh();
      }
    });
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    if (!!this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
