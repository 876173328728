import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { WorkflowDefinition, WorkflowDefinitionNode, WorkflowDefinitionLink } from '../../types/workflowDefinition';
import { WorkflowService } from '../../services/workflow.service';
import { updateDataObject } from '../utils/updateDataObject';
import { ModelSaveService } from './model-save.service';
import { SelectionService } from './selection.service';
import { ValueObject } from '../../types/shared';
import { DiagramService } from './base.service';
import { UndoService } from './undo.service';
import { Diagram } from '../Diagram';
import { Category } from '../types';
import { updateDiagramPortsVisibility } from '../utils/updateDiagramPortsVisibility/updateDiagramPortsVisibility';
import { parseNodeDataArray } from '../utils/parseNodeDataArray';

@Injectable()
export class ModelService extends DiagramService {

    constructor(
        private workflowService: WorkflowService,
        private modelSaveService: ModelSaveService,
        private undoService: UndoService,
        private selectionService: SelectionService
    ) {
        super();
    }

    bindDiagram(diagram: Diagram) {
        super.bindDiagram(diagram);
        this.workflowService.getWorkflowDefinition()
            .pipe(takeUntil(this.onDiagramUnbind$))
            .subscribe((workflowDefinition) =>
                this.setModel(workflowDefinition)
            );
    }

    getModelData() {
        return this.diagram.model.modelData;
    }
    getNodes() {
        return this.diagram.model.nodeDataArray;
    }
    getLinks(){
        return this.diagram.model.linkDataArray;
    }


    updateModelData(nextModelData: ValueObject) {
        this.diagram.commit(() => updateDataObject(
            this.diagram,
            this.diagram.model.modelData,
            nextModelData
        ));
    }

    updateNodeData(id: go.Key, nextNodeData: ValueObject) {
        const part = this.diagram.findPartForKey(id);
        this.diagram.commit(() => updateDataObject(
            this.diagram,
            part.data,
            nextNodeData
        ));
    }

    setWorkflowDefinition(next: WorkflowDefinition) {
        const prev = this.modelSaveService.getWorkflowDefinition();
        this.undoService.remember(
            () => this.setModel(next),
            () => this.setModel(prev)
        );
        this.selectionService.refreshSelection();
    }

    private setModel(workflowDefinition: WorkflowDefinition) {
        this.diagram.commit(() => {
            const { model } = this.diagram;
            model.modelData = this.parseModelData(workflowDefinition);
            model.nodeDataArray = parseNodeDataArray(workflowDefinition);
            model.linkDataArray = this.parseLinkDataArray(workflowDefinition);
        });

        this.diagram.updateAllTargetBindings();
        updateDiagramPortsVisibility(this.diagram);
    }

    private parseModelData(workflowDefinition: WorkflowDefinition) {
        const { filename, dag } = workflowDefinition;
        return {
            filename,
            dag
        };
    }

    private parseLinkDataArray(workflowDefinition: WorkflowDefinition) {
        const { links } = workflowDefinition;
        return _.flowRight(
            _.map((link: WorkflowDefinitionLink) => ({
                ...link,
                category: Category.Link
            })),
            _.values
        )(links);
    }

}
