import * as go from 'gojs';

import { generateGeometryFromSvgPath } from '../../../utils/generateGeometryFromSvgPath';
import { CHEVRON_UP } from '../../../../consts/icons';
import { updatePortsVisibility } from '../../../utils/updateDiagramPortsVisibility/updatePortsVisibility';

const $ = go.GraphObject.make;

const toggleSubGraphExpanded = (obj: go.GraphObject) => {
  const { diagram, part: { data } } = obj;
  const group = obj.part as go.Group;

  diagram.commit(() => {
    const nextCollapsed = !data.collapsed;
    diagram.model.setDataProperty(data, 'collapsed', nextCollapsed);
    group.isSubGraphExpanded = !nextCollapsed;
    updatePortsVisibility(group, 1);
  });
};

const background = () => $(
  go.Shape,
  'Circle',
  {
    alignmentFocus: go.Spot.Center,
    fill: 'transparent',
    stroke: null,
    desiredSize: new go.Size(30, 30),
  }
);

const expandIcon = () => $(
  go.Shape,
  {
    alignmentFocus: go.Spot.Center,
    geometry: generateGeometryFromSvgPath(CHEVRON_UP.path),
    desiredSize: new go.Size(10, 5),
    angle: 0
  },
  new go.Binding(
    'angle',
    'collapsed',
    (collapsed) => collapsed ? 180 : 0
  )
);

export const expandButton = () => $(
  go.Panel,
  go.Panel.Spot,
  {
    alignmentFocus: go.Spot.TopRight,
    alignment: new go.Spot(1, 0, -16, 16),
    cursor: 'pointer',
    click: (__, obj: go.GraphObject) => toggleSubGraphExpanded(obj),
  },
  background(),
  expandIcon()
);
