import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { getIconRegistry, IconModule } from '@xfusiontech/shared';
import { LoginFormModule } from './../../components/login-form/login-form.module';
import { LoginPageComponent } from './login-page.component';

const customIcons = ['linkedin_logo', 'facebook_logo', 'twitter_logo'];

@NgModule({
    declarations: [LoginPageComponent],
    imports: [CommonModule, LoginFormModule, IconModule],
    exports: [LoginPageComponent],
})
export class LoginPageModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace('custom', iconsPath)(customIcons);
    }
}
