import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { DiffEditorModel } from 'ngx-monaco-editor';
import _ from 'lodash';

import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';

export interface DataBlob {
  name: string;
  description?: string;
  local_path?: string;
  tags?: Map<string, string>;
  entity_id?: string;
  tenant_id?: string;
  created_by?: string;
  created_on?: string;
  changed_by?: string;
  changed_on?: string;
  version?: number;
  is_latest?: true;
  blob?: string;
  blob_type?: string;

  // Used by UI to be able to revert back without having to call back the api.
  initial_blob?: string;
}

@Component({
  selector: 'xfusiontech-monaco-editor',
  templateUrl: './monaco-editor.component.html',
  styleUrls: ['./monaco-editor.component.scss'],
})
export class MonacoEditorComponent implements OnInit, OnDestroy, OnChanges {

  // Actual value we're editing - Can be used as [(value)]
  @Input() value: string;
  @Input() previewData: string;
  @Input() enablePreview = false;
  @Input() showPreview = true;
  @Output() valueChange = new EventEmitter<string>();
  @Output() getPreviewData = new EventEmitter<string>();

  /// Should we display the format editor. (currently we only support raw and base64)
  @Input() showFormatSelector = true;
  @Input() selectedFormat: 'raw' | 'base64' = 'raw';
  @Input() selectedLanguage = 'python';

  // Array of supported languages for syntax highlighting
  @Input() languages: string[] = [
    'python',
    'plaintext',
    'sql',
    'json',
    'shell',
    'xml',
    'yaml',
  ];

  @Input() editorConfig: object = {
    theme: 'vs-dark',
    language: 'plaintext',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    contextmenu: false,
  };

  // Update size when the parent resizes. Triggered when this value changes.
  @Input() parentResize: string;

  // Height margin when maximizing
  @Input() maxPanelHeightMargin = 125;

  // Get the editorContainer so we can center scroll on resize.
  @ViewChild('editorContainer', { static: false }) editorContainer: ElementRef;

  public editorOptions: {};

  // MonacoEditor for the 'Write' tab. Used to ensure it's sized properly
  writeEditor: any;
  // MonacoEditor for the 'Preview Change' tab. Used to ensure it's sized properly
  previewChangeEditor: any;

  public blob = '';
  public differenceEditor: {
    changes?: boolean;
    initModel?: DataBlob['blob'];
    originalModel?: DiffEditorModel;
    modifiedModel?: DiffEditorModel;
  } = {};
  displaySaveButton = false;

  // Used during resizing operations
  private oldBodyHeight = null;
  private defaultEditorHeight = 200;
  public editorResizeStyle: object = {};
  public isMaximized = false;

  private bouncedValueChangeHandler: (value: string) => void;

  constructor(
  ) { }

  ngOnInit() {
    this.selectedLanguage = this.languages[0].toLocaleLowerCase();
    this.editorOptions = {
      ...(this.editorConfig || {}),
      language: this.selectedLanguage,
    };

    this.differenceEditor = {
      changes: false,
      originalModel: {
        code: this.value,
        language: this.selectedLanguage,
      },
      modifiedModel: {
        code: this.value,
        language: this.selectedLanguage,
      },
    };
    this.bouncedValueChangeHandler = _.throttle(this.valueChangeHandler);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Uncomment below code when we support base64 option

    // if (changes.value?.currentValue && this.selectedFormat === 'base64') {
    //   // After emitting, this will fire.
    //   // while emitting btoa, then recieving the emitted value and having to atob again isn't ideal.
    //   // I did not find a good way to prevent this and still handle legitimate changes.
    //   this.value = atob(changes.value.currentValue); // decode base64
    // }

    // if (!changes.parentResize?.firstChange) {
    // }
    if (changes && changes.parentResize && !changes.parentResize.firstChange) {
      this.resizeWithParent();
    }

    // if (changes.previewData?.currentValue) {
    if (changes && changes.previewData && changes.previewData.currentValue) {
      this.previewData = changes.previewData.currentValue;
    }
  }

  public editorTextChange(editorValue: string): void {
    if (this.differenceEditor.originalModel.code === editorValue) {
      this.differenceEditor.changes = false;
    } else {
      this.differenceEditor.changes = true;
    }
    this.bouncedValueChangeHandler(editorValue);
  }

  private valueChangeHandler(editorValue: string): void {
    // Update difference Editor
    this.differenceEditor.modifiedModel = {
      code: this.value,
      language: this.selectedLanguage,
    };

    // Emit change. If base64, emit base64 encoded value
    this.valueChange.emit(this.selectedFormat === 'base64' ? btoa(this.value) : this.value);
  }

  public saveCodeChanges() {
    this.valueChange.emit(this.value);
  }

  public changeLanguage(matSelectChange: MatSelectChange): void {
    this.selectedLanguage = matSelectChange.value.toLocaleLowerCase();

    this.editorOptions = {
      ...this.editorOptions,
      language: this.selectedLanguage,
    };
    this.differenceEditor.originalModel.language = this.selectedLanguage;
    this.differenceEditor.modifiedModel.language = this.selectedLanguage;
  }

  public changeFormat($event: MatSelectChange): void {

    // Need to check this code properly whether we need Raw and Base64 or not

    // const previousValue = this.selectedFormat;
    // this.selectedFormat = $event.value;
    // const converters = {
    //   raw: btoa,
    //   base64: atob,
    // };
    // try {
    //   const converter = converters[this.selectedFormat];
    //   this.value = converter(this.value);
    //   this.differenceEditor.originalModel.code = converter(this.differenceEditor.originalModel.code);
    //   this.differenceEditor.modifiedModel.code = converter(this.differenceEditor.modifiedModel.code);
    // } catch (e) {
    //   this.transLocoService.selectTranslateObject(
    //     'dataBlob.conversionDialog', {}, this.scope
    //   ).pipe(first()).subscribe((data) => {
    //     const conversionDialog = data;

    //     const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
    //       width: '350px',
    //       data: {
    //         message: conversionDialog.message,
    //         cancel: conversionDialog.ok,
    //       },
    //     });
    //     dialogRef.afterClosed().subscribe((result) => {
    //       if (result === 'cancel') {
    //         this.selectedFormat = previousValue;
    //       }
    //     });
    //   });
    // }
  }

  onWriteEditorInit($event: any): void {
    this.writeEditor = $event;
  }

  onPreviewChangeEditor($event: any): void {
    this.previewChangeEditor = $event;
  }

  onTabChange($event: MatTabChangeEvent) {
    if ($event.index === 0 && !_.isEmpty(this.writeEditor)) {
      this.writeEditor.layout();
    } else if ($event.index === 1 && !_.isEmpty(this.previewChangeEditor)) {
      this.previewChangeEditor.layout();
    } else if ($event.index === 2) {
      this.getPreviewData.emit(this.selectedFormat === 'base64' ? btoa(this.value) : this.value);
    }
  }

  ngOnDestroy(): void { }

  /* ----- RESIZE HANDLERS ----- */
  public onMaximize(): void {
    this.isMaximized = true;
    this.editorResizeStyle = {
      height: `${this.getMaxPanelHeight()}px`,
    };
    // Scroll so this the panel is vertically centered.
    // Delaying to wait until the editor has resized. Otherwise we center based on the old height.
    setTimeout(() => {
      const el = this.editorContainer.nativeElement;
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (!_.isEmpty(this.writeEditor)) {
        this.writeEditor.layout();
      }
      if (!_.isEmpty(this.previewChangeEditor)) {
        this.previewChangeEditor.layout();
      }
    });
  }

  private getMaxPanelHeight(): number {
    return window.innerHeight - this.maxPanelHeightMargin;
  }

  private resizeWithParent() {
    const height = this.editorResizeStyle['height'] ?
    this.editorResizeStyle['height'] : `${this.defaultEditorHeight}px`;

    this.editorResizeStyle = {
      width: `100%`,
      height: `${height}`,
    };

    setTimeout(() => {
      if (!_.isEmpty(this.writeEditor)) {
        this.writeEditor.layout();
      }
      if (!_.isEmpty(this.previewChangeEditor)) {
        this.previewChangeEditor.layout();
      }
    });
  }

  public onRestore(): void {
    this.isMaximized = false;
    this.editorResizeStyle = {
      height: `${this.defaultEditorHeight}px`,
    };
    setTimeout(() => {
      if (!_.isEmpty(this.writeEditor)) {
        this.writeEditor.layout();
      }
      if (!_.isEmpty(this.previewChangeEditor)) {
        this.previewChangeEditor.layout();
      }
    });
  }

  public onResizeStart(event: ResizeEvent): void {
    // Override document height, otherwise we get unexpected behaviour.
    // Namely everything but the resizable div will move up.
    this.oldBodyHeight = document.body.style.height;
    document.body.style.setProperty('height', `${document.body.scrollHeight}px`, 'important');
  }

  public onResizeEnd(event: ResizeEvent): void {
    // Reset body height
    document.body.style.height = this.oldBodyHeight;

    const newHeight = event.rectangle.height;
    if (newHeight > this.getMaxPanelHeight()) {
      this.isMaximized = true;
    } else {
      this.isMaximized = false;
      this.defaultEditorHeight = newHeight;
    }
    setTimeout(() => {
      if (!_.isEmpty(this.writeEditor)) {
        this.writeEditor.layout();
      }
      if (!_.isEmpty(this.previewChangeEditor)) {
        this.previewChangeEditor.layout();
      }
    });
  }

  public onResize(event: ResizeEvent): void {
    if (!_.isEmpty(this.writeEditor)) {
      this.writeEditor.layout();
    }
    if (!_.isEmpty(this.previewChangeEditor)) {
      this.previewChangeEditor.layout();
    }

    const newHeight = event.rectangle.height;
    this.editorResizeStyle = {
      height: `${newHeight}px`,
    };
  }

  public validateResize(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX = 50;
    return !(event.rectangle && (event.rectangle.height < MIN_DIMENSIONS_PX));
  }
}
