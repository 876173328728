import { TaskCatalogItemParameter, TaskCatalogItemProperties } from '../types/taskCatalog';

export enum Category {
    Node = 'Node',
    Link = 'Link',
    TaskGroup = 'TaskGroup'
}

export type TemplateCreator<TTemplate> = {
    category: Category,
    template: TTemplate
};

export type EventHandler<TEventName> = {
    event: TEventName;
    callback: (...args: any[]) => void;
};

export type DiagramTools = {
    commandHandler: go.CommandHandler,
    linkingTool: go.LinkingTool
};

export type DiagramValidationTool = {
  nodeProperties: (properties: TaskCatalogItemProperties) => boolean;
  workflowProperties: (parameters: TaskCatalogItemParameter[]) => boolean;
  link: (link: go.Link) => boolean;
  revalidateLinks: () => boolean;
};
