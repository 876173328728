import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    readonly ACCESS_TOKEN = 'accessToken';
    readonly USER_ID = 'userID';

    login(userID: string, token: string) {
        localStorage.setItem(this.ACCESS_TOKEN, token);
        localStorage.setItem(this.USER_ID, userID);
    }

    getAccessToken(): string {
        return localStorage.getItem(this.ACCESS_TOKEN);
    }

    getUserID(): string {
        return localStorage.getItem(this.USER_ID);
    }

    logout() {
        localStorage.removeItem(this.ACCESS_TOKEN);
        localStorage.removeItem(this.USER_ID);
    }
}
