import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import { ButtonComponent } from './button.component';

const imports = [
    CommonModule,
    MatRippleModule
];

const components = [
    ButtonComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class ButtonModule {}
