import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Visualization } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-visualization-tile',
    templateUrl: './visualization-tile.component.html',
    styleUrls: ['./visualization-tile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualizationTileComponent {
    @Input()
    visualization: Visualization;

    @Output()
    visualizationClick = new EventEmitter<Visualization>();

    @Output()
    shareVisualizationInDashboard = new EventEmitter<Visualization>();

    @Output()
    shareVisualizationByEmail = new EventEmitter<Visualization>();

    @Output()
    editVisualization = new EventEmitter<Visualization>();

    @Output()
    deleteVisualization = new EventEmitter<Visualization>();
}
