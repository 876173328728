
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * @author: Naga
 */
@Injectable({
  providedIn: 'root'
})
export class DataImportService {

  entityDelete: any  = new BehaviorSubject<any>(null);
  public entityDelete$ = this.entityDelete.asObservable();
  entityData: any  = new BehaviorSubject<any>([]);
  entityData$: any  = this.entityData.asObservable();
  private saveColumnData: any;
  /**
   * @constructor
   * @param: {http<HttpClient>}
   */
  constructor(private http: HttpClient) { }

  /**
   * @public
   * @param: {url<string>}
   * @param: {payload<FormData>}
   * @returns: Observable<any>
   * @description: a helper method that imports
   * meta data.
   */
  public importMetadata(url: string, payload: FormData): Observable<any> {
    return this.http.post(url, payload);
  }

  public setColumnData(data) {
    this.saveColumnData = data;
  }

  public getColumnData() {
    return this.saveColumnData;
  }
  /**
   * @public
   * @param: {url<string>}
   * @param: {payload<any>}
   * @returns: Observable<any>
   * @description: a helper method that updates
   * the meta data.
   */
  public updateMetadata(url: string, payload: any): Observable<any> {
    return this.http.put(url, payload);
  }
}
