import { ComplexInputPayload } from '../../../types/form';
import { TaskCatalogItemParameter } from '../../../types/taskCatalog';

export const mapComplexToInputValue = (
    parameterValue: string,
    { imports }: TaskCatalogItemParameter
): ComplexInputPayload => ({
    value: parameterValue,
    imports
});
