import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowDropdownComponent } from './component/workflow-dropdown/workflow-dropdown.component';
import { WorkflowDropdownItemComponent } from './component/workflow-dropdown-item/workflow-dropdown-item.component';
import { FormsModule } from '@angular/forms';
import {ConfirmDialogModule} from './component/confirm-dialog/confirm-dialog.module'
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
@NgModule({
  declarations: [WorkflowDropdownComponent, WorkflowDropdownItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    ConfirmDialogModule
  ],
  exports: [WorkflowDropdownComponent, WorkflowDropdownItemComponent,ConfirmDialogModule]
})
export class SharedModule {}
