import * as go from 'gojs';
import { isGroupInternalPort } from '../portTypes/isGroupInternalPort';
import { isRegularPort } from '../portTypes/isRegularPort';

const updateRegularPort = (
  part: go.Node,
  port: go.GraphObject,
  opacity: number
) => port.opacity = part.findLinksConnected(port.portId).count
  ? 1
  : opacity;

const updateGroupInternalPort = (
  part: go.Node,
  port: go.GraphObject,
  opacity: number
) => {
  const group = part as go.Group;
  if (!group.isSubGraphExpanded) {
    port.opacity = 0;
    return;
  }

  port.opacity = part.findLinksConnected(port.portId).count
    ? 1
    : opacity;
};

export const updatePortsVisibility = (
  obj: go.GraphObject,
  opacity: number = 0
) => {
  const part = obj.part as go.Node;
  part.ports.each((port) => {
    if (isRegularPort(port)) {
      return updateRegularPort(part, port, opacity);
    } else if (isGroupInternalPort(port)) {
      return updateGroupInternalPort(part, port, opacity);
    }
  });
};
