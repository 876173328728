import * as _ from 'lodash/fp';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { MenuItemModel, MenuItemPosition, MenuType } from '../menu.types';

@Component({
    selector: 'udp-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {

    @Input()
    type: MenuType = 'primary';

    @Input()
    items: MenuItemModel[] = [];

    trackByIndex(index: number) {
        return index;
    }

    getItemsByPosition(position: MenuItemPosition) {
        return _.flowRight(
            _.filter(
                ({ menuPosition }: MenuItemModel) => menuPosition === position
            ),
            _.map(
                _.defaults({
                    menuPosition: 'left',
                    iconVariant: 'material'
                })
            )
        )(this.items);
    }

}
