/**
 * @author: Naga
 * @constant
 */
export const DISP_COLUMNS_CFG: any = {
  attributeChecked: {
    name: 'Select',
    field: 'attributeChecked',
    controlName: 'attributeChecked',
    fieldType: 'CHECKBOX'
  },
  // attributeSequenceNumber: {
  //   name: 'Seq No.',
  //   field: 'attributeSequenceNumber'
  // },
  attributeName: {
    name: 'Attribute Name',
    field: 'attributeName'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  autoIncrementEnabled: {
    name: 'Auto Increment Enabled',
    field: 'autoIncrementEnabled'
  },
  // dataFormat is missing right now in the API
  dataType: {
    name: 'Data Type',
    field: 'dataType',
    controlName: 'dataType',
    editable: true,
    fieldType: 'SELECT'
  },
  dataSize: {
    name: 'Data Size',
    field: 'dataSize',
    controlName: 'dataSize',
    editable: true,
    fieldType: 'INPUT'
  },
  dataStandard: {
    name: 'Data Standard',
    field: 'dataStandard',
    controlName: 'dataStandard',
    editable: true,
    fieldType: 'INPUT'
  },

  // isActive is right now active in API
  active: {
    name: 'Active',
    field: 'active',
    controlName: 'active',
    editable: true,
    fieldType: 'RADIO'
  },

  // isPrimarykey is right now primaryKey in API
  primaryKey: {
    name: 'Primary Key',
    field: 'primaryKey',
    controlName: 'primaryKey',
    editable: true,
    fieldType: 'RADIO'
  },

  // isForeignKey is right now foreignKey in API
  foreignKey: {
    name: 'Foreign Key',
    field: 'foreignKey',
    controlName: 'foreignKey',
    editable: true,
    fieldType: 'RADIO'
  },
  listofValues: {
    name: 'List of Values',
    field: 'listofValues',
    controlName: 'listofValues',
    editable: true,
    fieldType: 'INPUT'
  },
  comment: {
    name: 'Comment',
    field: 'comment',
    controlName: 'comment',
    editable: true,
    fieldType: 'INPUT'
  },
  // attributeAliasName: {
  //   name: 'Alias',
  //   field: 'attributeAliasName',
  //   controlName: 'attributeAliasName',
  //   editable: true,
  //   fieldType: 'INPUT'
  // },
  // attributeDescription: {
  //   name: 'Description',
  //   field: 'attributeDescription',
  //   editable: true,
  //   fieldType: 'POPUP'
  // },


  // attributeLength: {
  //   name: 'Length',
  //   field: 'attributeLength',
  //   controlName: 'attributeLength',
  //   editable: true,
  //   fieldType: 'INPUT'
  // },
  // attributeFormat: {
  //   name: 'Format',
  //   field: 'attributeFormat',
  //   controlName: 'attributeFormat',
  //   editable: true,
  //   fieldType: 'INPUT'
  // },
  // glossaryTerm: {
  //   name: 'Glossary Term',
  //   field: 'glossaryTerm',
  //   editable: true,
  //   fieldType: 'TERM'
  // },
  // isRequiredAttribute: {
  //   name: 'Required',
  //   field: 'requiredAttribute',
  //   controlName: 'requiredAttribute',
  //   editable: true,
  //   fieldType: 'RADIO'
  // },
  // identifyingAttribute: {
  //   name: 'Id Attribute',
  //   field: 'identifyingAttribute',
  //   controlName: 'identifyingAttribute',
  //   editable: true,
  //   fieldType: 'RADIO'
  // },
  dataSensitivity: {
    name: 'Data Sensitivity',
    field: 'dataSensitivity',
    controlName: 'dataSensitivity',
    editable: true,
    fieldType: 'RADIO'
  },
  // attributeComment: {
  //   name: 'Comment',
  //   field: 'attributeComment',
  //   controlName: 'attributeComment',
  //   editable: true,
  //   fieldType: 'POPUP'
  // }
};

export const DISP_COLUMNS_CFG_READONLY: any = {
  // attributeChecked: {
  //   name: 'Select',
  //   field: 'attributeChecked',
  //   controlName: 'attributeChecked',
  //   fieldType: 'CHECKBOX'
  // },
  // attributeSequenceNumber: {
  //   name: 'Seq No.',
  //   field: 'attributeSequenceNumber'
  // },
  attributeName: {
    name: 'Target Attribute Name',
    field: 'attributeName'
  },
  sourceAttribute: {
    name: 'Source Attribute Name',
    field: 'sourceAttribute',
    fieldType: 'sourceMapping'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  autoIncrementEnabled: {
    name: 'Auto Increment Enabled',
    field: 'autoIncrementEnabled',
    toggle: true,
    controlName: 'autoIncrementEnabled'
  },
  // dataFormat is missing right now in the API
  dataType: {
    name: 'Data Type',
    field: 'dataType',
  },
  dataSize: {
    name: 'Data Size',
    field: 'dataSize',
  },
  dataStandard: {
    name: 'Data Standard',
    field: 'dataStandard',
  },
  // isActive is right now active in API
  active: {
    name: 'Active',
    field: 'active',
    toggle: true,
    controlName: 'active'
  },
  // isPrimarykey is right now primaryKey in API
  primaryKey: {
    name: 'Primary Key',
    field: 'primaryKey',
    toggle: true,
    controlName: 'primaryKey'
  },
  // isForeignKey is right now foreignKey in API
  foreignKey: {
    name: 'Foreign Key',
    field: 'foreignKey',
    toggle: true,
    controlName: 'foreignKey'
  },
  listofValues: {
    name: 'List of Values',
    field: 'listofValues',
  },
  comment: {
    name: 'Comment',
    field: 'comment',
  },
  dataSensitivity: {
    name: 'Data Sensitivity',
    field: 'dataSensitivity',
  },
  // actions: {
  //   name: 'Actions',
  //   field: 'actions',
  //   controlName: 'actions',
  //   fieldType: 'action'
  // },
};

export const DISP_COLUMNS_CFG_READONLY_FILE_ASSET: any = {
  attributeChecked: {
    name: 'Select',
    field: 'attributeChecked',
    controlName: 'attributeChecked',
    fieldType: 'CHECKBOX'
  },
  attributeName: {
    name: 'Attribute Name',
    field: 'attributeName'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  attributeDescription: {
    name: 'Attribute Description',
    field: 'attributeDescription'
  },
  dataType: {
    name: 'Data Type',
    field: 'type',
  },
  dataFormat: {
    name: 'Data Format',
    field: 'dataFormat',
  },
  foreignKey: {
    name: 'Foreign Key',
    field: 'isForeignKey ',
    toggle: true,
    controlName: 'isForeignKey '
  },
  isIdentifyingAttribute: {
    name: 'Identifying Attribute',
    field: 'isIdentifyingAttribute',
    toggle: true,
    controlName: 'isIdentifyingAttribute'
  },
  isNullable: {
    name: 'Nullable',
    field: 'isNullable ',
    toggle: true,
    controlName: 'isNullable '
  },
  isRequired: {
    name: 'Required',
    field: 'isRequired',
    toggle: true,
    controlName: 'isRequired'
  },
  dataSensitivity: {
    name: 'Data Sensitivity',
    field: 'isSensitiveData',
  }
};

export const DISP_COLUMNS_CFG_READONLY_SOURCE: any = {
  attributeChecked: {
    name: 'Select',
    field: 'attributeChecked',
    controlName: 'attributeChecked',
    fieldType: 'CHECKBOX'
  },
  // attributeSequenceNumber: {
  //   name: 'Seq No.',
  //   field: 'attributeSequenceNumber'
  // },
  attributeName: {
    name: 'Attribute Name',
    field: 'attributeName'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  attributeDescription: {
    name: 'Attribute Description',
    field: 'attributeDescription'
  },
  // dataFormat is missing right now in the API
  dataType: {
    name: 'Data Type',
    field: 'type',
  },
  dataFormate: {
    name: 'Data Format',
    field: 'dataFormat',
  },
  foreignKey: {
    name: 'Foreign Key',
    field: 'isForeignKey ',
    toggle: true,
    controlName: 'isForeignKey '
  },
  isIdentifyingAttribute: {
    name: 'Identifying Attribute',
    field: 'isIdentifyingAttribute',
    toggle: true,
    controlName: 'isIdentifyingAttribute'
  },
  isNullable: {
    name: 'Nullable',
    field: 'isNullable ',
    toggle: true,
    controlName: 'isNullable '
  },
  isRequired: {
    name: 'Required',
    field: 'isRequired',
    toggle: true,
    controlName: 'isRequired'
  },
  dataSensitivity: {
    name: 'Sensitive Data',
    field: 'isSensitiveData',
    toggle: true,
    controlName: 'isSensitiveData'
  },
};

export const DISP_COLUMNS_CFG_UPSERT: any = {
  attributeName: {
    name: 'Target Attribute Name',
    field: 'attributeName'
  },
  sourceAttribute: {
    name: 'Source Attribute Name',
    field: 'sourceAttribute',
    fieldType: 'sourceMapping'
  },
  duplicateIdentifyingColumn: {
    name: 'Duplicate Identifying Column',
    field: 'duplicateColumnChecked',
    controlName: 'duplicateIdentifyingColumn',
    fieldType: 'CHECKBOX',
  },
  updateColumn: {
    name: 'Update Column',
    field: 'updateColumnChecked',
    controlName: 'updateColumn',
    fieldType: 'CHECKBOX'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  autoIncrementEnabled: {
    name: 'Auto Increment Enabled',
    field: 'autoIncrementEnabled',
    toggle: true,
    controlName: 'autoIncrementEnabled'
  },
  // dataFormat is missing right now in the API
  dataType: {
    name: 'Data Type',
    field: 'dataType',
  },
  dataSize: {
    name: 'Data Size',
    field: 'dataSize',
  },
  dataStandard: {
    name: 'Data Standard',
    field: 'dataStandard',
  },
  // isActive is right now active in API
  active: {
    name: 'Active',
    field: 'active',
    toggle: true,
    controlName: 'active'
  },
  // isPrimarykey is right now primaryKey in API
  primaryKey: {
    name: 'Primary Key',
    field: 'primaryKey',
    toggle: true,
    controlName: 'primaryKey'
  },
  // isForeignKey is right now foreignKey in API
  foreignKey: {
    name: 'Foreign Key',
    field: 'foreignKey',
    toggle: true,
    controlName: 'foreignKey'
  },
  listofValues: {
    name: 'List of Values',
    field: 'listofValues',
  },
  comment: {
    name: 'Comment',
    field: 'comment',
  },
  dataSensitivity: {
    name: 'Data Sensitivity',
    field: 'dataSensitivity',
  }
};

export const DISP_COLUMNS_CFG_UPSERT_SCD0: any = {
  attributeName: {
    name: 'Target Attribute Name',
    field: 'attributeName'
  },
  sourceAttribute: {
    name: 'Source Attribute Name',
    field: 'sourceAttribute',
    fieldType: 'sourceMapping'
  },
  duplicateIdentifyingColumn: {
    name: 'Duplicate Identifying Column',
    field: 'duplicateColumnChecked',
    controlName: 'duplicateIdentifyingColumn',
    fieldType: 'CHECKBOX'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  autoIncrementEnabled: {
    name: 'Auto Increment Enabled',
    field: 'autoIncrementEnabled',
    toggle: true,
    controlName: 'autoIncrementEnabled'
  },
  // dataFormat is missing right now in the API
  dataType: {
    name: 'Data Type',
    field: 'dataType',
  },
  dataSize: {
    name: 'Data Size',
    field: 'dataSize',
  },
  dataStandard: {
    name: 'Data Standard',
    field: 'dataStandard',
  },
  // isActive is right now active in API
  active: {
    name: 'Active',
    field: 'active',
    toggle: true,
    controlName: 'active'
  },
  // isPrimarykey is right now primaryKey in API
  primaryKey: {
    name: 'Primary Key',
    field: 'primaryKey',
    toggle: true,
    controlName: 'primaryKey'
  },
  // isForeignKey is right now foreignKey in API
  foreignKey: {
    name: 'Foreign Key',
    field: 'foreignKey',
    toggle: true,
    controlName: 'foreignKey'
  },
  listofValues: {
    name: 'List of Values',
    field: 'listofValues',
  },
  comment: {
    name: 'Comment',
    field: 'comment',
  },
  dataSensitivity: {
    name: 'Data Sensitivity',
    field: 'dataSensitivity',
  }
};

export const DISP_COLUMNS_CFG_SUBJECT_MAPPING: any = {
  targetAttributeName: {
    name: 'Subect Attribute Name',
    field: 'targetAttributeName'
  },
  dataMappingEntity: {
    name: 'Target Entity',
    field: 'dataMappingEntity',
    controlName: 'dataMappingEntity',
    editable: true,
    fieldType: 'SUBJECT-SELECT'
  },
  dataMappingEntityAttributes: {
    name: 'Target Entity Attributes',
    field: 'dataMappingEntityAttributes',
    controlName: 'dataMappingEntityAttributes',
    editable: true,
    fieldType: 'SUBJECT-SELECT-ATTR'
  },
  dataMappingEntityAttributesType: {
    name: 'Target Entity Attributes Type',
    field: 'dataMappingEntityAttributesType'
  },
  targetAttributeDataType: {
    name: 'Subject Attribute Type',
    field: 'targetAttributeDataType'
  },
  isRequired: {
    name: 'Is Required',
    field: 'isRequired',
  },
};

/**
 * @constant
 */
export const DATA_TYPES: string[] = [
  'STRING',
  'INTEGER',
  'NUMBER',
  'DECIMAL',
  'BOOLEAN',
  'DATE',
  'TIME',
  'NULL',
  'DATETIME',
  'LONG',
  'VARCHAR',
  'VARCHAR2',
  'TEXT',
  'Boolean',
  'BIT',
  'CHAR',
  'BIGINT',
  'TIMESTAMP',
  'REAL',
  'DOUBLE',
  'OTHER'
];
