// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Library Imports
import { ButtonModule} from './../../../../shared';
import { QueryEditorPageModule } from './../../../../query-editor';

// Components
import { QueryDatasetComponent } from './query-dataset.component';

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [
    QueryDatasetComponent
  ],
  imports: [
    CommonModule
    , ButtonModule
    , QueryEditorPageModule
  ],
  exports: [
    QueryDatasetComponent
  ]
})
export class QueryDatasetModule { }
