import { ComplexOutputPayload } from '../../../types/form';
import { TaskCatalogItemParameter } from '../../../types/taskCatalog';

export const mapComplexToCustomFields = (
    value: ComplexOutputPayload,
    param: TaskCatalogItemParameter
): TaskCatalogItemParameter => ({
    ...param,
    imports: value.imports
});
