import * as _ from 'lodash/fp';

import { mapParameterTypeToInputType } from '../../properties-editor/utils/mapParameterTypeToInputType';
import { normalizeAvailableType } from './normalizeAvailableType';

export const mapAvailableTypeToInputType = (type: string) =>
    _.flowRight(
        mapParameterTypeToInputType,
        normalizeAvailableType
    )(type);
