import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash/fp';

@Injectable({
    providedIn: 'root',
})
export class HasPermissionService {

    checkPermission(currentUserPermissions: string[], permissions: string[], logicalOp = 'AND') {
        let hasPermission = false;
        const currentPermissions = _.keyBy(x => x)(
            (currentUserPermissions || []).map(x => x.toUpperCase())
        );

        if (currentUserPermissions != null) {
            for (const checkPermission of (permissions || [])) {
                const permissionFound = checkPermission in currentPermissions;

                if (permissionFound) {
                    hasPermission = true;

                    if (logicalOp === 'OR') {
                        break;
                    }
                } else {
                    hasPermission = false;
                    if (logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }

        return hasPermission;
    }
}
