import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// import { TOKEN_API } from '../consts/api';
import { ResponseToken } from '../types/response';

@Injectable()
export class TokenService implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject();
    private token = new BehaviorSubject<string>('');
    constructor(
        private httpClient: HttpClient,
    ) { }

    get getToken(): Observable<string> {
        return this.token.pipe(filter(token => !!token));
    }

    private body = { grant_type: 'client_credentials' };
    // TODO: find secure way to store user credentials
    private headers = new HttpHeaders({
        Authorization:
            'Basic dWhYQ2dmV1lycTNNalZzdENxc2tmdEptN2VBYTo3OU04dDdUSE5OOHFSVGJRdGJNdTBhS2Y5akFh'
    });

    // fetchToken() {
    //     this.httpClient.post(
    //         TOKEN_API, this.body,
    //         { headers: this.headers })
    //         .pipe(takeUntil(this.onDestroy$))
    //         .subscribe((result: ResponseToken) => {
    //             this.setToken(result.access_token);
    //         });
    // }

    setToken(token: string) {
        this.token.next(token);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
