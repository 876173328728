import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, FormFieldModule } from '@xfusiontech/shared';
import { LoginFormComponent } from './login-form.component';

@NgModule({
    declarations: [LoginFormComponent],
    imports: [CommonModule, FormFieldModule, ButtonModule, ReactiveFormsModule],
    exports: [LoginFormComponent],
})
export class LoginFormModule {}
