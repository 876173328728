import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    TemplateRef,
} from '@angular/core';

@Component({
    selector: 'igv-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewComponent {
    @HostBinding('class') classes = 'igv-view';

    @Input()
    titleBar?: TemplateRef<any> | boolean;

    @Input()
    actionsBar?: TemplateRef<any> | boolean = false;

    @Input()
    title?: string;

    @Input()
    titleActions?: TemplateRef<any> | boolean = false;
}
