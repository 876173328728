// Modules
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WorkflowEditorModule } from './workflow-editor/workflow-editor.module';
import { LayoutsModule } from './workflow-editor/components/shared/modules/layouts/layouts.module';
import { InterceptorModule } from './workflow-editor/components/shared/modules/interceptor/interceptor.module';

// Components
import { AppComponent } from './app.component';

/**
 * @author: XFT, Naga
 * @module
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule
    , LayoutsModule
    , WorkflowEditorModule
    , AppRoutingModule
    , BrowserAnimationsModule
    , InterceptorModule.forRoot()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
