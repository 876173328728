import * as _ from 'lodash/fp';
import { Category } from '../gojs/types';

import { TaskCatalogItem } from '../types/taskCatalog';
import { camelCaseToLabel } from './camelCaseToLabel';
import { snakeCaseToLabel } from './snakeCaseToLabel';

const mapTaskCatalogItemToValues = _.flowRight(
    _.map(_.toLower),
    ({
        type,
        properties: { category, subcategory, description }}: TaskCatalogItem
    ) => [
        camelCaseToLabel(type),
        snakeCaseToLabel(category),
        snakeCaseToLabel(subcategory),
        description,
    ]
);

const getNodeDataValues = _.flowRight(
    _.map(_.toLower),
    _.values,
    _.get('properties'),
);

const mapNodeDataToValues = (data: TaskCatalogItem) => [
    ...mapTaskCatalogItemToValues(data),
    ...getNodeDataValues(data),
];

const valuesMatchSearch = _.flowRight(
    _.some,
    _.includes,
    _.toLower,
) as (search: string) => (item: TaskCatalogItem) => boolean;

const isMatchingItem = (search: string) => _.flowRight(
    valuesMatchSearch(search),
    mapTaskCatalogItemToValues,
);

const isMatchingNode = (search: string) => _.flowRight(
    valuesMatchSearch(search),
    mapNodeDataToValues,
);

const isNode = ({ category }) => category === Category.Node;

export const filterTaskCatalogItems = _.flowRight(
    _.filter,
    isMatchingItem,
);

export const filterNodes = (searchText: string) => _.flowRight(
    _.filter(isMatchingNode(searchText)),
    _.filter(isNode),
);
