import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EntityTextIndicatorComponent } from './entity-text-indicator.component';

const imports = [
    CommonModule
];

const components = [
    EntityTextIndicatorComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class EntityTextIndicatorModule {}
