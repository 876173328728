import { takeUntil } from 'rxjs/operators';
import { Component, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';

import { ComplexInputPayload, ComplexOutputPayload } from '../../../../types/form';

@Component({
    selector: 'xft-form-complex',
    templateUrl: './complex.component.html',
    styleUrls: ['./complex.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ComplexComponent implements OnDestroy {
    @Input()
    set control(control: FormControl) {
        this._control = control;
        this.createComplexFormControl(control.value);
    }
    get control(): FormControl {
        return this._control;
    }
    private _control: FormControl;

    private onDestroy$ = new Subject();

    @Input()
    tooltipMessage: string;

    @Input()
    label = '';

    @Input()
    paramType = '';

    complexFormControl: FormGroup;

    constructor(
        private formBuilder: FormBuilder
    ) {}

    private createComplexFormControl(value: ComplexInputPayload) {
        this.complexFormControl = this.formBuilder.group({
            constructor: [value ? value.value : ''],
            imports: [value ? value.imports : '']
        });

        this.complexFormControl.valueChanges.
            pipe(takeUntil(this.onDestroy$))
            .subscribe((val) => {
                const nextValue: ComplexOutputPayload = {
                    value: val.constructor,
                    imports: val.imports
                };

                this.control.markAsDirty();
                this.control.setValue(nextValue);
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
