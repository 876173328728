import { Injectable } from '@angular/core';
import { DiagramService } from './base.service';

import * as go from 'gojs';
import { theme } from '../../consts/theme';

const $ = go.GraphObject.make;

@Injectable()
export class OverviewService extends DiagramService {
    attachOverview(element: HTMLDivElement) {
        const overview = $(
            go.Overview,
            element,
            {
                observed: this.diagram,
                contentAlignment: go.Spot.Center,
            },
        );

        (overview.box.elt(0) as go.Shape)
            .stroke = theme.colors.selectedNodeAccent;
    }
}
