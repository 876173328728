import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Visualization } from '@xfusiontech/data-visualizer';
import { VISUALIZATION_PATH } from './../../../../consts/paths';

@Component({
    selector: 'igv-dashboard-navigation-visualizations',
    templateUrl: './navigation-visualizations.component.html',
    styleUrls: ['../navigation/navigation.component.scss'],
})
export class DashboardNavigationVisualizationComponent {
    VISUALIZATION_PATH = VISUALIZATION_PATH;

    @Input()
    config: any;

    @Input()
    customSubCtxMenuTpl: any;

    @Input()
    suppressNavigation: boolean = false;

    @Input() visualizations: Visualization[];

    @Output()
    itemClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    shareVisualizationInDashboard = new EventEmitter<Visualization>();

    @Output()
    shareVisualizationByEmail = new EventEmitter<Visualization>();

    @Output()
    editVisualization = new EventEmitter<Visualization>();

    @Output()
    deleteVisualization = new EventEmitter<Visualization>();

    menuTopLeftPosition = { x: '0', y: '0' };
    menuData: Visualization;

    @ViewChild(MatMenuTrigger, { static: true })
    matMenuTrigger: MatMenuTrigger;

    visualizationTrackBy = (_, v: any) => {
        return v[this.config.subCatIdKey] || v.visualizationID;
    }

    onRightClick(event: MouseEvent, data: Visualization) {
        event.preventDefault();
        this.menuTopLeftPosition.x = event.clientX + 'px';
        this.menuTopLeftPosition.y = event.clientY + 'px';
        this.menuData = data;
        this.matMenuTrigger.openMenu();
    }
}
