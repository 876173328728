import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TabsComponent } from './tab-group.component';
import { TabComponent } from './tab.component';

const imports = [CommonModule, RouterModule];

const components = [TabsComponent, TabComponent];

@NgModule({
    imports,
    declarations: [...components],
    exports: components,
})
export class TabsModule {}
