import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { MatProgressSpinnerModule } from '@angular/material';
import {
  BlendDatasetsModule,
  CreateDatasetModule,
  QueryModule
} from '@xfusiontech/data-catalog';

import { CodeEditorPanelComponent } from './panel/code-editor-panel.component';
import { CodeEditorHeaderComponent } from './header/code-editor-header.component';
import { CodeEditorComponent } from './editor/code-editor.component';
import { CodeEditorPanelService } from './code-editor-panel.service';
import { ButtonModule } from '../shared/button/button.module';
import { DataMappingComponent } from './data-mapping/data-mapping.component';
import { KendoModule } from 'src/app/third-party-libs/kendo.module';
import { AngularMaterialModule } from 'src/app/third-party-libs/material.module';
import { MatMenuModule } from '@angular/material/menu';
import { NgxBootstrapModule } from 'src/app/third-party-libs/ngx-bootstrap.module';
import { GraphQLModule } from '../../../shared-modules/graphql.module';
import { DataToolsComponent } from './data-tools/data-tools.component';
import { UdpDatasetOperatorModule } from 'libs/data-catalog/src/lib/udp-dataset-operator/udp-dataset-operator.module';
import { UpdMilOperatorModule } from 'libs/data-catalog/src/lib/upd-mil-operator/upd-mil-operator.module';
import { UdpDataTransformationOperatorModule } from 'libs/data-catalog/src/lib/udp-data-transformation-operator/udp-data-transformation-operator.module';
@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    MonacoEditorModule,
    MatProgressSpinnerModule,
    KendoModule,
    AngularMaterialModule,
    MatMenuModule,
    NgxBootstrapModule,
    GraphQLModule,
    ReactiveFormsModule,
    CreateDatasetModule,
    BlendDatasetsModule,
    UdpDatasetOperatorModule,
    UpdMilOperatorModule,
    UdpDataTransformationOperatorModule,
    QueryModule
  ],
  declarations: [
    CodeEditorPanelComponent,
    CodeEditorHeaderComponent,
    CodeEditorComponent,
    DataMappingComponent,
    DataToolsComponent
  ],
  providers: [CodeEditorPanelService],
  exports: [CodeEditorPanelComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CodeEditorPanelModule {}
