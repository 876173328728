import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxBootstrapModule } from 'src/app/third-party-libs/ngx-bootstrap.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { MatButtonModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatInputModule, MatMenuModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatToolbarModule, MatTooltipModule } from '@angular/material';

import {
  ButtonModule,
  ExpansionPanelModule,
  IconModule
} from '@xfusiontech/shared';
import { FormFieldModule, StringArrayToSelectOptionsModule } from '@xfusiontech/shared';

import { UpdMilOperatorComponent } from './upd-mil-operator.component';
import { SearchConfigurationComponent } from './search-configuration/search-configuration.component';
import { AssociationMappingComponent } from './association-mapping/association-mapping.component';
import { IdentifyingAttributesComponent } from './identifying-attributes/identifying-attributes.component';
import { DecideConfigurationComponent } from './decide-configuration/decide-configuration.component';
import { LinkNLoadDataIntegrationComponent } from './link-n-load-data-integration/link-n-load-data-integration.component';
import { ScoringConfigurationComponent} from './scoring-configuration/scoring-configuration.component';
import { MapSrcAttributesComponent } from './map-src-attributes/map-src-attributes.component';

import { LayoutModule } from '../../../../interactive-graph-view';
import { SidebarWithFilterModule } from '../../../../sidebar';
import { KendoModule } from 'src/app/third-party-libs/kendo.module';
import { IconButtonModule } from 'src/app/workflow-editor/components/shared/icon-button/icon-button.module';
import { DrawerExpansionButtonModule } from 'src/app/workflow-editor/components/shared/drawer-expansion-button/drawer-expansion-button.module';

import { SchemaExplorerModule } from '@xfusiontech/schema-explorer';
import { SharedModule } from '../shared/shared.module';
import { BucketExplorerComponent } from './bucket-explorer/bucket-explorer.component';

@NgModule({
  declarations: [
    UpdMilOperatorComponent,
    SearchConfigurationComponent,
    AssociationMappingComponent,
    IdentifyingAttributesComponent,
    DecideConfigurationComponent,
    LinkNLoadDataIntegrationComponent,
    ScoringConfigurationComponent,
    MapSrcAttributesComponent,
    BucketExplorerComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SidebarWithFilterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    LayoutModule,
    DragDropModule,
    KendoModule,
    MatRadioModule,
    MatIconModule,
    StringArrayToSelectOptionsModule,
    FormFieldModule,
    NgxBootstrapModule,
    MatExpansionModule,
    ButtonModule,
    ExpansionPanelModule,
    IconButtonModule,
    IconModule,
    SchemaExplorerModule,
    MatDialogModule,
    MatSidenavModule,
    SharedModule,
    DrawerExpansionButtonModule,
    MatToolbarModule,
    MatMenuModule,
    TooltipModule
  ],
  exports: [UpdMilOperatorComponent],
  entryComponents: [BucketExplorerComponent]
})
export class UpdMilOperatorModule { }
