import * as _ from 'lodash/fp';
import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormArray, FormBuilder } from '@angular/forms';

import { ValueObject } from '../../../../types/shared';

type DictionaryEntry = {
    key: string,
    value: string,
};

@Component({
    selector: 'xft-form-dictionary',
    templateUrl: './dictionary.component.html',
    styleUrls: ['./dictionary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DictionaryComponent {

    @Input()
    set control(control: FormControl) {
        this._control = control;
        this.createEntriesFormControl(control.value);
    }
    get control(): FormControl {
        return this._control;
    }
    private _control: FormControl;

    @Input()
    tooltipMessage: string;

    @Input()
    label: string;

    entriesFormControl: FormArray;

    constructor(
        private formBuilder: FormBuilder
    ) {
        this.createEntriesFormControl({ });
    }

    onKeyDown({ key }: KeyboardEvent, last: boolean) {
        if (last && key === 'Enter') {
            this.addNewItem();
        }
    }

    addNewItem() {
        this.entriesFormControl.push(
            this.formBuilder.group({
                key: '',
                value: ''
            })
        );
    }

    removeItem(idx: number) {
        this.entriesFormControl.removeAt(idx);
    }

    private updateValue() {
        const nextValue = _.flowRight(
            _.reduce((acc: ValueObject, next: ValueObject) => ({
                ...acc,
                ...next
            }), {}),
            _.map(({ key, value }: DictionaryEntry) => ({ [key]: value })),
            _.filter(({ key }: DictionaryEntry) => !!key)
        )(this.entriesFormControl.getRawValue());

        this.control.markAsDirty();
        this.control.setValue(nextValue);
    }

    private createEntriesFormControl(values: ValueObject) {
        this.entriesFormControl = this.formBuilder.array(
            _.flowRight(
                _.map((key: string) => this.formBuilder.group({
                    key, value: values[key]
                })),
                _.keys
            )(values)
        );
        this.entriesFormControl.valueChanges
            .subscribe(() => {
                this.updateValue();
            });
    }

}
