import { Component, Input } from '@angular/core';

import { WidgetWrapperComponent } from '../widget-wrapper.component';
import { LogsListWrapperData } from './logs-list-wrapper.types';

@Component({
    selector: 'udp-logs-list-wrapper',
    templateUrl: './logs-list-wrapper.component.html'
})
export class LogsListWrapperComponent
    extends WidgetWrapperComponent<LogsListWrapperData> {

    @Input()
    data: LogsListWrapperData;

}
