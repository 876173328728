import { ParameterType } from '../../../types/form';
import { mapParameterTypeToInputType } from '../../properties-editor/utils/mapParameterTypeToInputType';
import { TaskCatalogItemParameter } from '../../../types/taskCatalog';

export const mapUnionToCustomField = (
    param: TaskCatalogItemParameter
) => {
    const inputType = mapParameterTypeToInputType(
        param.userValueType as ParameterType
    );
    if (inputType === 'complex' && typeof(param.value) === 'object') {
        return {
            ...param,
            ...param.value
        };
    }
    return param;
};
