import { QueryVariable } from '@xfusiontech/data-visualizer';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface RawData {
    [key: string]: string | number;
}

export class SyntaxError extends Error {
    constructor(message) {
        super(message);
    }
}

export class Variable {
    constructor(
        public name: string,
        public type: string,
        public parameters: { [key: string]: any }
    ) {}

    parseValue(data: RawData): string {
        return data[this.name].toString();
    }
}

export interface VariableMap {
    [key: string]: Variable;
}

@Injectable()
export class TemplateEngineService {
    protected template = new BehaviorSubject<Array<string | Variable>>([]);
    public template$ = this.template.asObservable();

    protected variables = new BehaviorSubject<Variable[]>([]);
    public variables$ = this.variables.asObservable();

    parse(template: string, queryVariables: QueryVariable[] = []): void {
        if (template == null) return;
        const variables: VariableMap = {};

        const nextTemplate = template.split(/{{(.*?)}}/).map((literal, idx) => {
            if (idx % 2 === 1) {
                const interpolation = this.parseVariable(
                    literal,
                    queryVariables
                );

                if (
                    variables[interpolation.name] &&
                    variables[interpolation.name].type !== interpolation.type
                ) {
                    throw new SyntaxError('Query Template Syntax Error');
                } else {
                    variables[interpolation.name] = interpolation;
                }

                return interpolation;
            } else {
                if (literal.indexOf('{{') > -1 || literal.indexOf('}}') > -1) {
                    throw new SyntaxError('Query Template Syntax Error');
                }

                return literal;
            }
        });

        this.variables.next(Object.values(variables));
        this.template.next(nextTemplate);
    }

    compile(data: RawData): string {
        return this.template
            .getValue()
            .map(literal => {
                if (literal instanceof Variable) {
                    return literal.parseValue(data);
                }

                return literal;
            })
            .join('');
    }

    private parseVariable(literal: string, queryVariables: QueryVariable[]) {
        const matches = queryVariables.find(
            x =>
                x.queryVariableName.trim().toLocaleLowerCase() ===
                literal.trim().toLocaleLowerCase()
        );

        if (matches == null)
            throw new SyntaxError(
                `Query Template Syntax Error: unknown variable ${literal.trim()}`
            );

        return new Variable(
            matches.queryVariableName,
            matches.queryVariableDataType,
            matches.queryVariableParameters
        );
    }
}
