import * as go from 'gojs';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { validateLink } from './links/validateLink';

@Injectable()
export class LinkValidationService {

    private invalidLinkIds = new BehaviorSubject<Set<go.Key>>(new Set());

    get linksValid$() {
        return this.invalidLinkIds.pipe(
            map((keys) => !keys.size)
        );
    }

    setLinkKeyInvalid(key: go.Key) {
        const value = this.invalidLinkIds.getValue();
        value.add(key);
        this.invalidLinkIds.next(value);
    }

    setLinkKeyValid(key: go.Key) {
        const value = this.invalidLinkIds.getValue();
        value.delete(key);
        this.invalidLinkIds.next(value);
    }

    revalidateLinks(diagram: go.Diagram) {
        const invalidIds: go.Key[] = [];
        diagram.links.map((link) => {
            const { key, fromNode, fromPort, toNode, toPort } = link;
            const result = validateLink(fromNode, fromPort, toNode, toPort);
            if (!result) {
                invalidIds.push(key);
            }
            this.updateLinkData(link, result);
        });
        this.invalidLinkIds.next(new Set(invalidIds));

        return !invalidIds.length;
    }

    validateLink(link: go.Link) {
        const { key, fromNode, fromPort, toNode, toPort } = link;
        const result = validateLink(fromNode, fromPort, toNode, toPort);
        if (!result) {
            this.setLinkKeyInvalid(key);
        } else {
            this.setLinkKeyValid(key);
        }
        this.updateLinkData(link, result);
        return result;
    }

    private updateLinkData(link: go.Link, valid: boolean) {
        const { diagram, data } = link;
        const prevSkipUndo = diagram.skipsUndoManager;
        diagram.skipsUndoManager = true;
        diagram.commit(() => {
            diagram.model.setDataProperty(data, 'valid', valid);
        });
        diagram.skipsUndoManager = prevSkipUndo;
    }

}
