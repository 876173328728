import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataAssetCategory, Query } from '@xfusiontech/data-visualizer';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { QueryInCategory } from '../../models/query.model';
import { TemplateEngineService } from './template-engine.service';

@Component({
    selector: 'igv-query-editor',
    templateUrl: './query-editor.component.html',
    styleUrls: ['./query-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TemplateEngineService],
})
export class QueryEditorComponent implements OnChanges, OnDestroy {
    private onDestroy$ = new Subject();

    @HostBinding('class')
    classes = 'igv-query-editor';

    @ViewChild('preview', { static: false })
    previewTemplate: TemplateRef<any>;

    form: FormGroup;
    queryFormHidden = false;

    @Input()
    query: QueryInCategory;

    @Input()
    dataAssetCategories: DataAssetCategory[];

    @Output()
    addOrUpdateQuery = new EventEmitter<{
        query: Query | null;
        categoryId: string;
    }>();

    @Output()
    previewQuery = new EventEmitter<Query>();

    @Output()
    runQuery = new EventEmitter<Query>();

    @Output() queryVariablesPopupClick = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        public templateEngine: TemplateEngineService,
        private cd: ChangeDetectorRef
    ) {
        this.initializeFormGroup();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.query && this.query != null) {
            const { query, categoryId } = this.query;
            this.form.patchValue({
                queryName: query.queryName,
                queryDescription: query.queryDescription,
                query: query.query,
                queryCategoryId: categoryId,
                isQueryTemplate: query.isQueryTemplate,
            });

            this.parseQueryTemplate(query.query);
            this.cd.markForCheck();
        }
    }

    private initializeFormGroup() {
        this.form = this.fb.group({
            queryName: ['', Validators.required],
            queryDescription: [''],
            query: [''],
            queryCategoryId: [null, Validators.required],
            isQueryTemplate: [false],
        });

        this.form
            .get('query')
            .valueChanges.pipe(takeUntil(this.onDestroy$))
            .pipe(debounceTime(500))
            .subscribe(value => {
                this.query.query.query = value;
                this.parseQueryTemplate(value);
                this.cd.markForCheck();
            });

        this.form.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .pipe(debounceTime(500))
            .subscribe(value => {
                this.query.query = {
                    ...this.query.query,
                    ...value,
                };
            });
    }

    private parseQueryTemplate(value: string): void {
        if (this.form.value.isQueryTemplate) {
            try {
                this.templateEngine.parse(value, this.query.query.variables);
            } catch (err) {
                this.form.get('query').setErrors({
                    syntax: err.message,
                });
            }
        }
    }

    onPreview() {
        const query = {
            ...this.query.query,
            ...this.form.getRawValue(),
        };

        this.previewQuery.emit(query);
    }

    onRun() {
        const query = {
            ...this.query.query,
            ...this.form.getRawValue(),
        };

        this.runQuery.emit(query);
    }

    onSubmit() {
        const { queryCategoryId, ...queryData } = this.form.getRawValue();
        const query = {
            ...this.query.query,
            ...queryData,
        };

        this.addOrUpdateQuery.emit({
            query,
            categoryId: queryCategoryId,
        });
    }

    onQueryTabChange(selection: string) {
        this.form.patchValue({
            isQueryTemplate: selection === 'Query template',
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
