import { FormItemModel, IdentifyingAttributesType } from '../../global-search.types';

export const mapAttributesToFields = (
    identifyingAttributes: IdentifyingAttributesType[]
): FormItemModel[] => {
    const arr: FormItemModel[] = [];
    identifyingAttributes.forEach((identAttr) => {
        identAttr.attributes.forEach(({attributeLabel, attributeName}) => {
            arr.push({
                label: attributeLabel,
                value: `${identAttr.entityClassType}/${attributeName}`
            });
        });
    });

    return arr;
};
