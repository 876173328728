import * as _ from 'lodash/fp';
import * as go from 'gojs';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Category } from '../types';
import { DiagramService } from './base.service';
import { SelectionService } from './selection.service';
import { TASK_GROUP_BASE_DATA, TASK_GROUP_MIN_HEIGHT, TASK_GROUP_MIN_WIDTH, TASK_GROUP_PADDING } from '../consts/task-groups';

@Injectable()
export class TaskGroupInsertService extends DiagramService {

  constructor(
    private selectionService: SelectionService
  ) {
    super();
  }

  groupSelectedTasks() {
    const currentSelection = this.selectionService.getCurrentSelection();

    this.diagram.commit(() => {
      const groupId = this.insertTaskGroup(currentSelection);
      this.insertTasksToGroup(groupId, currentSelection);
    });
  }

  private insertTaskGroup(nodes: go.Set<go.Part>) {
    const groupId = uuidv4();
    const groupData = {
      id: groupId,
      category: Category.TaskGroup,
      isGroup: true,
      size: go.Size.stringify(this.getGroupSize(nodes)),
      loc: go.Point.stringify(this.getGroupLocation(nodes)),
      ...TASK_GROUP_BASE_DATA
    };
    this.diagram.model.addNodeData(groupData);

    return groupId;
  }

  private insertTasksToGroup(groupId: go.Key, nodes: go.Set<go.Part>) {
    const group = this.diagram.findNodeForKey(groupId) as go.Group;
    group.addMembers(nodes);
  }

  private getGroupSize(nodes: go.Set<go.Part>): go.Size {
    if (!nodes.count) {
      return new go.Size(TASK_GROUP_MIN_WIDTH, TASK_GROUP_MIN_HEIGHT);
    }

    const minLeft = _.min(nodes.map(node => node.actualBounds.left).toArray());
    const minTop = _.min(nodes.map(node => node.actualBounds.top).toArray());

    const maxRight = _.max(
      nodes.map(node => node.actualBounds.right).toArray()
    );
    const maxBottom = _.max(
      nodes.map(node => node.actualBounds.bottom).toArray()
    );

    const width = Math.max(Math.abs(maxRight - minLeft), TASK_GROUP_MIN_WIDTH)
      + 2 * TASK_GROUP_PADDING;
    const height = Math.abs(maxBottom - minTop)
      + TASK_GROUP_MIN_HEIGHT
      + TASK_GROUP_PADDING;

    return new go.Size(width, height);
  }

  private getGroupLocation(nodes: go.Set<go.Part>): go.Point {
    if (!nodes.count) {
      const { x, y } = this.diagram.viewportBounds.center;
      return new go.Point(
        x - 0.5 * TASK_GROUP_MIN_WIDTH,
        y - 0.5 * TASK_GROUP_MIN_HEIGHT
      );
    }

    const xLocs = nodes.map(node => node.location.x).toArray();
    const yLocs = nodes.map(node => node.location.y).toArray();

    const minX = _.min(xLocs);
    const minY = _.min(yLocs);

    return new go.Point(
      minX - TASK_GROUP_PADDING,
      minY - TASK_GROUP_MIN_HEIGHT
    );
  }
}
