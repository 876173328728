import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'udp-inner-page-tab',
    templateUrl: './inner-page-tab.component.html',
    styleUrls: ['./inner-page-tab.component.scss']
})
export class InnerPageTabComponent {

    @Input() label: string;
    @Input() isActive: boolean;
    @Output() tabSelected: EventEmitter<string> = new EventEmitter();
}
