import gql from 'graphql-tag';

export const READ_All_INTEGRATION_WORKFLOW_QUERY = gql`
query IntegrationFlowAll {
  integrationWorkflowAll {
    sourceDataTypeCode
    status
    targetDataTypeCode
    workflowName
    workflowDescription
    workflowVersion
    lastUpdateDate
    owner
    id
    workflowID
    pipelineCategory
    pipelineId
    workflowDocumentId

  }
}
    `;

export const CLONE_WORKFLOW_QUERY = gql`
    mutation cloneWorkflow($WorkflowID:String!,$WorkflowName:String!)
    {
    cloneIntegrationworkflow(WorkflowID:$WorkflowID,WorkflowName:$WorkflowName)
    {
    SourceDataTypeCode
        Status
        TargetDataTypeCode
        WorkflowName
        WorkflowDescription
        id
        WorkflowID
        IntegrationWorkflowComponents{
            IntegrationWorkflowID
            IntegrationWorkflowComponentID
            IntegrationWorkflowComponentName
            IntegrationWorkflowComponentStatus
            WorkflowComponentTypeCode
            WorkflowComponent
            WorkflowComponentTypes{
                WorkflowComponentDescription
                WorkflowComponentName
                WorkflowComponentStatus
                WorkflowComponentTypeCode
            }
        }
    }
    }`;

export const WORKFLOW_MAPPING_DETAILS_QUERY = gql`
    query IntegrationFlow($WorkflowID: String!) {
      integrationWorkflow(WorkflowID: $WorkflowID) {
        workflowName
        workflowDescription
        id
        integrationWorkflowComponents {
          IntegrationWorkflowID
          IntegrationWorkflowComponentID
          IntegrationWorkflowComponentName
          IntegrationWorkflowComponentStatus
          WorkflowComponentTypeCode
          WorkflowComponentTypes {
            id
            WorkflowComponentTypeCode
            WorkflowComponentStatus
            WorkflowComponentName
            WorkflowComponentDescription
          }
          WorkflowComponent
        }
      }
    }
  `;

export const STATUS_VERSION_UPDATE_QUERY = gql`
    mutation updateIntegrationWorkflowVersion($Status: String!,$WorkflowID:String!,$Version:String!)
    {
      updateIntegrationWorkflowVersion(Status: $Status,WorkflowID:$WorkflowID,Version:$Version){
        WorkflowID
        WorkflowVersion
        Status
      }
}`;

export const DELETE_WORKFLOW_QUERY = gql`
mutation deleteIntegrationWorkflow($WorkflowID: ID!) {
  deleteIntegrationWorkflow(WorkflowID: $WorkflowID)
}`;

export const FETCH_ASSOCIATION_MAPPING_QUERY = gql`
query AssociationMapping($uid: ID!) {
  associationMapping(SourceDataTypeCode: $uid) {
    SourceDataTypeCode
    TargetDataTypeCode
    AssociationMappingObject
  }
}`;

export const FETCH_ALL_ASSOCIATION_MAPPING_QUERY = gql`
query getAssociationMapping{
  associationMapAll
  {
    associationSourceEntityName
    associationSourceEntityType
    entityAssociationName
    associationTargetEntityName
    associationTargetEntityType
  }
}`;

export const FETCH_ASSOCIATION_MAP_QUERY = gql`
query getAssociationMap($associationTargetEntityType:String!)
{
  associationMap(associationTargetEntityType:$associationTargetEntityType)
  {
    associationSourceEntityName
    associationSourceEntityType
    associationSourceEntityClass
    associationTargetEntityName
    associationTargetEntityType
    associationTargetEntityClass
    entityAssociationName
  }
}`;

export const FETCH_ALL_SUBJECT_IDENTIFIACTION_MAP = gql`
query getSubjectIdentificationMapAll {
  subjectIdentificationMapAll {
    SubjectEntityName
    SubjectEntityType
    SubjectEntityClassType

    SearchConfigurationMap {
      SearchConfigurationMapID
      SearchAdapterClassName
      SearchQuery {
        SearchQueryName
        SearchQueryParameter {
          QueryParameterName
          QueryParameterDataType
        }
      }
    }
    ScoringConfigurationMap {
      ScoringConfigurationRuleSetName
      RulesetParameters {
        RulesetParameterDataName
        TargetEntityName
        TargetentityAttributeName
      }
    }
    DecisionConfigurationMap {
      DecisionConfigurationRulesetName
      RulesetParameters {
        RulesetParameterDataName
      }
    }
  }
}`;

export const UPDATE_IW_INNER_MODULE = gql`
mutation updateIntegrationWorkflow(
  $Source: Int!
  $SourceDataTypeCode: String!
  $Status: String!
  $TargetDataTypeCode: String!
  $WorkflowID: String!
  $WorkflowComponentTypeCode: String!
  $IntegrationWorkflowComponentName: String!
  $IntegrationWorkflowComponentStatus: String!
  $WorkflowComponent: String!
  $Version: String!
) {
  updateIntegrationWorkflow(
    source: $Source
    sourceDataTypeCode: $SourceDataTypeCode
    status: $Status
    targetDataTypeCode: $TargetDataTypeCode
    workflowID: $WorkflowID
    workflowComponentTypeCode: $WorkflowComponentTypeCode
    IntegrationWorkflowComponentName: $IntegrationWorkflowComponentName
    IntegrationWorkflowComponentStatus: $IntegrationWorkflowComponentStatus
    WorkflowComponent: $WorkflowComponent
    Version: $Version
  ) {
    workflowID
    id
  }
}`;

export const ADD_NEW_INTEGRATION_WORKFLOW = gql`
mutation newIntegrationWorkflow(
  $source: Int!,
  $sourceDataTypeCode: String!,
  $status: String!,
  $targetDataTypeCode: String!,
  $workflowDescription: String!,
  $workflowName: String!,
  $workflowVersion: String!,
  $pipelineCategory:String!,
  $owner:String!
) {
  newIntegrationWorkflow(
    source:$source,
    sourceDataTypeCode:$sourceDataTypeCode,
    status: $status,
    targetDataTypeCode: $targetDataTypeCode,
    workflowDescription: $workflowDescription,
    workflowName: $workflowName,
    workflowVersion:$workflowVersion,
    pipelineCategory:$pipelineCategory,
    owner:$owner
  ) {

    id

  }
}`;

export const SCHEDULE_INTEGRATION_WORKFLOW = gql`
mutation scheduleIntegrationWorkflow($workflowID:String!,
  $JobName: String!,
  $JobDescription: String!,
  $CronExpression: String!,
        $SourceDataOrganization:String!,
        $SourceDataType:String!,
        $CutoffDate:String!
  )
{
  scheduleIntegrationWorkflow(workflowID: $workflowID,
    JobName:$JobName,
    JobDescription:$JobDescription,
    CronExpression: $CronExpression,
      SourceDataOrganization:$SourceDataOrganization,
      SourceDataType:$SourceDataType,
      CutoffDate:$CutoffDate){
    WorkflowID
    WorkflowName
    ScheduledJobs
    {
      CronExpression
      JobName
    }
  }
}`;

export const UPDATE_SCHEDULE_INTEGRATION_WORKFLOW = gql`
mutation updateScheduleIntegrationWorkflow($workflowID:String!,
  $JobName: String!,
  $JobDescription: String!,
  $CronExpression: String!,
  $IsScheduled: String!,
  $ActiveFlag: String!,
        $SourceDataOrganization:String!,
        $SourceDataType:String!,
        $CutoffDate:String!
  )
{
  updateScheduleIntegrationWorkflow(workflowID: $workflowID,
    JobName:$JobName,
    JobDescription:$JobDescription,
    CronExpression: $CronExpression,
      IsScheduled:$IsScheduled,
      ActiveFlag:$ActiveFlag,
      SourceDataOrganization:$SourceDataOrganization,
      SourceDataType:$SourceDataType,
      CutoffDate:$CutoffDate){
    WorkflowID
    WorkflowName
    ScheduledJobs
    {
      CronExpression
      JobName
    }
  }
}`;

export const FETCH_SOURCE_DATABASES_QUERY = gql`
query getSourceDatabases {
  externalDataAll {
    ExternalDataID
    AverageDataSize
    ExternalDataDesc
    ExternalDataName
    ExternalDataTypeCode
    Status
  }
}`;

export const FETCH_TARGET_DATABASES_QUERY = gql`
query getTargetDatabases {
  internalDatabasesAll {
    InternalDatabaseID
    DatabaseName
    DatabasePhysicalName
    DatabaseProductName
  }
}`;

export const FETCH_ALL_TARGET_DATA_ENTITIES_QUERY = gql`
query getInternalDatabaseEntities {
  internalDatabasesAll {
    InternalDatabaseID
    DatabaseName
    DatabasePhysicalName
    DatabaseProductName
    InternalDataOwnerID
    EntityGroup {
      EntityGroupName
      EntityGroupType
      Entity {
        EntityID
        EntityDesc
        EntityName
        EntityPhysicalName
      }
    }
  }
}`;

export const FETCH_TARGET_DATA_ENTITIES_QUERY = gql`
query getInternalDatabaseEntities {
  internalDatabasesAll {
    InternalDatabaseID
    DatabaseName
    DatabasePhysicalName
    DatabaseProductName
    InternalDataOwnerID
    EntityGroup {
      EntityGroupName
      EntityGroupType
      Entity {
        EntityID
        EntityDesc
        EntityName
        EntityPhysicalName
        EntityAttributes {
          EntityAttributeID
          EntityAttributeName
          Sensitivity
          PhysicalName
          Attribute{
            DataType
          }
        }
      }
    }
  }
}`;

export const FETCH_TARGET_DATABASE_MAPPINGS_QUERY = gql`
    query getInternalDatabaseAllMappings{
    internalDatabasesAll
    {
      DatabaseName
      DatabasePhysicalName
      EntityGroup{
        EntityGroupName
        EntityGroupType
        Entity{
          EntityName
          EntityClassType
        }
      }
    }
  }`;

export const FETCH_SUBJECT_QUERY = gql`
  query SubjectQuery($entityName:String!)
    {
      subjectQuery(entityName:$entityName)
      {
        queryName
        queryParameters
        entityName
      }
    }
`;





