export const CORNER_SIZE = 16;
export const ICON_SIZE = 32;

export const TOP_ROUNDED_RECTANGLE_HEIGHT = 20;
export const EXPAND_BUTTON_HEIGHT = 24;
export const SCROLLBAR_WIDTH = 6;
export const PROPERTIES_TABLE_HEIGHT = 150;

export const NODE_WIDTH = 250;
export const NODE_PADDING = 12;
export const NODE_CONTENT_WIDTH = NODE_WIDTH - 2 * NODE_PADDING;
export const NODE_COLLAPSED_HEIGHT = 116;
export const NODE_EXPANDED_HEIGHT = (
    TOP_ROUNDED_RECTANGLE_HEIGHT
    + ICON_SIZE
    + EXPAND_BUTTON_HEIGHT
    + PROPERTIES_TABLE_HEIGHT
    + 4 * NODE_PADDING
);

export const PROPERTIES_TABLE_WIDTH = NODE_CONTENT_WIDTH - SCROLLBAR_WIDTH;

export const MAIN_SHAPE_NAME = 'MAIN_SHAPE';
export const PROPERTIES_TABLE_NAME = 'PROPERTIES_TABLE';

export const DIAGRAM_SCALE_MIN = 0.1;
export const DIAGRAM_SCALE_MAX = 5;
export const DIAGRAM_SCALE_INITIAL = 1;
