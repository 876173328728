import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownButtonModule } from '@progress/kendo-angular-buttons';

import { DropdownButtonComponent } from './dropdown-button.component';
import { IconModule } from '../icon/icon.module';

const imports = [
    CommonModule,
    DropDownButtonModule,
    IconModule
];

const components = [
    DropdownButtonComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class DropdownButtonModule {}
