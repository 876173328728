import { Component, Input } from '@angular/core';
import { DonutChartSeries, DonutChartSeriesData } from './donut-chart.types';

@Component({
    selector: 'udp-donut-chart',
    templateUrl: './donut-chart.component.html'
})
export class DonutChartComponent {
    public chartData;
    @Input() public  centerLabel;
    @Input() set dataSeries(dataSeries: DonutChartSeries) {
        this.chartData = dataSeries.data.map((data: DonutChartSeriesData) => ({
            kind: data.label,
            share: data.value,
            color: data.color
        }));
    }
}
