import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from './../../user.model';

@Component({
    selector: 'igv-header-user',
    templateUrl: './header-user.component.html',
    styleUrls: ['./header-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserComponent {
    @Input()
    user: User;
}
