import { NgModule } from '@angular/core';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { WorkflowEditorRoutingModule } from './workflow-editor.routing';
import { WorkflowEditorComponent } from './workflow-editor.component';
import { EditorModule } from './components/editor/editor.module';
import { GoJSModule } from './gojs/gojs.module';

import { WorkflowService } from './services/workflow.service';
import { TaskCatalogService } from './services/task-catalog.service';
import { ApiService } from './services/api.service';
import { MessagesService } from './services/messages.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TokenService } from './services/token.service';
import { MatExpansionModule, MatButtonModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { CreateSaveBodyService } from './services/create-save-body.service';
import { NodeValidationService } from './services/node-validation.service';

import { WorkflowValidationService } from './services/workflow-validation.service';
import { TaskCatalogPropertyService } from './services/task-catalog-property.service';
import { UploadOperatorsComponent, UploadOperatorsModule } from '@xfusiontech/upload-operators';
import { SnackbarModule } from './components/shared/snackbar';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropUploadDirective } from './directives/drag-drop-upload/drag-drop-upload.directive';
import { ButtonModule } from './components/shared/button/button.module';

const imports = [
    MonacoEditorModule.forRoot(),
    EditorModule,
    WorkflowEditorRoutingModule,
    GoJSModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    MatExpansionModule,
    UploadOperatorsModule,
    SnackbarModule,
    MatProgressSpinnerModule
];

const providers = [
    WorkflowService,
    TaskCatalogService,
    ApiService,
    MessagesService,
    NodeValidationService,
    TaskCatalogPropertyService,
    TokenService,
    CreateSaveBodyService,
    WorkflowValidationService
];

@NgModule({
    declarations: [WorkflowEditorComponent, DragDropUploadDirective],
    imports,
    providers,
    entryComponents: [UploadOperatorsComponent],
    exports: [WorkflowEditorComponent]
})
export class WorkflowEditorModule {}
