import * as go from 'gojs';

import { createCorner } from './corner';
import { CORNER_SIZE, NODE_WIDTH, TOP_ROUNDED_RECTANGLE_HEIGHT } from '../../../consts/shared';
import { theme } from '../../../../consts/theme';
import { Diagram } from '../../../Diagram';

const $ = go.GraphObject.make;

export const createTopBarShape = (
    width: number,
    height: number,
    corner: number = CORNER_SIZE
) => `
    f m 0 ${height}
    v ${- (height - corner)}
    ${createCorner(1, -1, corner)}
    h ${width - 2 * corner}
    ${createCorner(1, 1, corner)}
    v ${height - corner} z
`;

const fillBinding = () => new go.Binding('fill', '', (obj) => {
    const { data, isSelected } = obj;
    const diagram = obj.diagram as Diagram;
    const invalid = diagram.validation.nodeProperties(data.properties);

    if (invalid) {
        return isSelected
            ? theme.colors.selectedInvalidNodeAccent
            : theme.colors.invalidNodeAccent;
    }

    return isSelected
        ? theme.colors.selectedNodeAccent
        : theme.colors.nodeAccent;
}).ofObject();

export const topRoundedRectangle = () => $(
    go.Shape,
    {
        desiredSize: new go.Size(NODE_WIDTH, TOP_ROUNDED_RECTANGLE_HEIGHT),
        fill: theme.colors.nodeAccent,
        stroke: null,
        geometryString: createTopBarShape(
            NODE_WIDTH,
            TOP_ROUNDED_RECTANGLE_HEIGHT
        )
    },
    fillBinding()
);
