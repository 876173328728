import { Component, Input } from '@angular/core';
import { Option } from '../form-field.types';

@Component({
    selector: 'udp-autocomplete-option',
    templateUrl: './autocomplete-option.component.html',
    styleUrls: ['./autocomplete-option.component.scss']
})
export class AutocompleteOptionComponent {
    @Input()
    option: Option;
}
