// Modules
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatCheckboxModule, MatDialogModule, MatExpansionModule, MatIconModule, MatInputModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule , MatSidenavModule, MatSortModule, MatTableModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { ImportDatasetModule } from '../import-dataset/import-dataset.module';
import { SharedDataModule } from '../shared/modules/shared-data/shared-data.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

// Library Imports
import { DataImportModule } from './../../../../data-import';
import { QueryEditorPageModule } from './../../../../query-editor';
import { ButtonModule, ExpansionPanelModule, FormFieldModule, IconButtonModule, IconModule, TabsModule } from './../../../../shared';
// import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';

// Components
import { ViewDataComponent } from '../shared/modules/shared-data/view-data/view-data.component';
import { KendoModule } from 'src/app/third-party-libs/kendo.module';
// import { CreateDataAssetModule } from '../create-data-asset/create-data-asset.module';
import { MonacoEditorModule } from 'ngx-monaco-editor';
// import { DataFrameModule, DataStudioModule } from '@xfusiontech/data-studio';
import { QueryComponent } from './query.component';
import { QueryControllerComponent } from './query-controller/query-controller.component';
import { DashboardModule, LayoutModule, SidebarModule } from '../../../../interactive-graph-view';
import { SidebarWithFilterModule } from '../../../../sidebar';
import { QuerySchemaComponent } from './query-schema/query-schema.component';
import { DrawerExpansionButtonModule } from '@workflow-editor/components/shared/drawer-expansion-button/drawer-expansion-button.module';
import { DataFrameModule, DataStudioModule } from '@xfusiontech/data-studio';
import { SchemaExplorerModule } from '@xfusiontech/schema-explorer';
import { CreateDataAssetModule } from '../create-data-asset';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    QueryComponent,
    QueryControllerComponent,
    QuerySchemaComponent,
  ],
  imports: [
    CommonModule
    , MatExpansionModule
    , MatButtonModule
    , MonacoEditorModule
    , DashboardModule
    , SidebarWithFilterModule
    , SidebarModule
    , MatSidenavModule
    , KendoModule
    , ButtonModule
    , QueryEditorPageModule
    , LayoutModule
    , RouterModule
    , ReactiveFormsModule
    , FormsModule
    , ButtonModule
    , MatRadioModule
    , FormFieldModule
    , DataImportModule
    , SharedDataModule
    , CreateDataAssetModule
    , ImportDatasetModule
    , QueryEditorPageModule
    , MatFormFieldModule
    , MatSelectModule
    , IconButtonModule
    , MatTooltipModule
    , MatIconModule
    , IconModule
    , TabsModule,
    DrawerExpansionButtonModule,
    MatNativeDateModule,
    ExpansionPanelModule,
    MatMenuModule
    , DataStudioModule
    , DataFrameModule,
    DragDropModule,
  ],
  exports: [
    QueryComponent,
    QueryControllerComponent,
    QuerySchemaComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class QueryModule { }
