import gql from 'graphql-tag';

export const CREATE_NEW_ROLE_QUERY = gql`
  mutation addDataManagementRole($UserId:String!,$DataManagementRoleDesc:String,$DataManagementRoleName:String,$DataManagementRoleCode:String,$DataManagementRoleIsActive:Boolean){
  addDataManagementRole(UserId:$UserId,DataManagementRoleDesc:$DataManagementRoleDesc,DataManagementRoleName:$DataManagementRoleName,DataManagementRoleCode:$DataManagementRoleCode,DataManagementRoleIsActive:$DataManagementRoleIsActive){
      id
      DataManagementRoleCode
      DataManagementRoleDesc
      DataManagementRoleIsActive
      DataManagementRoleName
      UserId
      RefAllDataManagementRoleID
        }
      }`;

export const DELETE_ROLE_QUERY = gql`
mutation deleteDataManagementRole($DataManagementRoleCode:String!)
{
  deleteDataManagementRole(DataManagementRoleCode:$DataManagementRoleCode)
}`;

export const DELETE_PERSON_QUERY = gql`mutation deleteDataManagementPerson($id:Long!)
{  deleteDataManagementPerson(id:$id)
}`;

export const ADD_PERSON = gql`
  mutation newDataManagementPerson($OrganizationID:String!,$ContactEmail:String,$ContactHomePhoneNumber:String,$ContactMobilePhoneNumber:String,$DataManagementPersonIsActive:String,$DataManagementPersonPicture:String,$FirstName:String,$LastName:String,$MiddleName:String,$Title:String)
  { newDataManagementPerson(OrganizationID:$OrganizationID,ContactEmail:$ContactEmail,ContactHomePhoneNumber:$ContactHomePhoneNumber,ContactMobilePhoneNumber:$ContactMobilePhoneNumber,DataManagementPersonIsActive:$DataManagementPersonIsActive,DataManagementPersonPicture:$DataManagementPersonPicture,FirstName:$FirstName,LastName:$LastName,MiddleName:$MiddleName,Title:$Title)
  {
  OrganizationID
  ContactEmail
  }
  }
  `;

export const ADD_ROLE = gql`
mutation addDataManagementRole($DataManagementRoleDesc:String!,$DataManagementRoleName:String!,$DataManagementRoleCode:String!)
{
addDataManagementRole(DataManagementRoleDesc:$DataManagementRoleDesc,DataManagementRoleName:$DataManagementRoleName,DataManagementRoleCode:$DataManagementRoleCode)
{
  id
  DataManagementRoleDesc
  DataManagementRoleCode
  DataManagementRoleName

}
}`;

export const EDIT_PEOPLE = gql`
mutation updateDataManagementPerson($OrganizationID:String!,$id:Long!,$ContactEmail:String,$ContactHomePhoneNumber:String,$ContactMobilePhoneNumber:String,$DataManagementPersonIsActive:String,$DataManagementPersonPicture:String,$FirstName:String,$LastName:String,$MiddleName:String,$Title:String)
{
  updateDataManagementPerson(OrganizationID:$OrganizationID,id:$id,ContactEmail:$ContactEmail,ContactHomePhoneNumber:$ContactHomePhoneNumber,ContactMobilePhoneNumber:$ContactMobilePhoneNumber,DataManagementPersonIsActive:$DataManagementPersonIsActive,DataManagementPersonPicture:$DataManagementPersonPicture,FirstName:$FirstName,LastName:$LastName,MiddleName:$MiddleName,Title:$Title)
{
      ContactEmail
      ContactHomePhoneNumber
      ContactMobilePhoneNumber
      DataManagementPersonPicture
      FirstName
      LastName
      MiddleName
      OrganizationID
      Title
      id
 }
}`;

export const UPDATE_ROLE = gql`mutation updateDataManagementRole($DataManagementRoleDesc:String!,$DataManagementRoleName:String!,$DataManagementRoleCode:String!,$DataManagementRoleIsActive:Boolean!)

{  updateDataManagementRole(DataManagementRoleDesc:$DataManagementRoleDesc,DataManagementRoleName:$DataManagementRoleName,DataManagementRoleCode:$DataManagementRoleCode,DataManagementRoleIsActive:$DataManagementRoleIsActive)
{
  DataManagementRoleCode
  DataManagementRoleName
  DataManagementRoleDesc
  DataManagementRoleIsActive
}}`;

export const ADD_ROLE_FOR_PERSON_EXTERNAL = gql`mutation addDataManagementRoleForUserExternal($id:Long!,$DataManagementRoleCode:String!,$ExternalDataOrgID:String!,$IsRoleActive:String!)
  { addDataManagementRoleForUserExternal(id:$id,DataManagementRoleCode:$DataManagementRoleCode,ExternalDataOrgID:$ExternalDataOrgID,IsRoleActive:$IsRoleActive)
    {
      DataManagementRoleCode
    DataManagementRoleName
    }
  }`;

export const ADD_ROLE_FOR_PERSON_INTERNAL = gql`mutation addDataManagementRoleForUserInternal($id:Long!,$DataManagementRoleCode:String!,$InternalDataOrgID:String!,$IsRoleActive:String!)
{ addDataManagementRoleForUserInternal(id:$id,DataManagementRoleCode:$DataManagementRoleCode,InternalDataOrgID:$InternalDataOrgID,IsRoleActive:$IsRoleActive)
{
  DataManagementRoleCode
DataManagementRoleName
}
}
`;

export const GET_ALL_PERSON_BY_ORGANIZATIONID = gql`
query DataManagementPerson($OrganizationID: String!)
{
  dataManagementPerson(OrganizationID:$OrganizationID)
  {
    id
    ContactEmail
    Title
    FirstName
    MiddleName
    LastName
    ContactMobilePhoneNumber
    ContactHomePhoneNumber
    DataManagementPersonPicture
    DataManagementPersonIsActive
    DataManagementPersonRoleDataLink{
      id
      RefDataManagementRole{
        DataManagementRoleCode
        DataManagementRoleName
        DataManagementRoleDesc
        DataManagementRoleIsActive
      }

      ExternalData{
        ExternalDataID
        ExternalDataName
      }

      InternalDatabases{
        InternalDatabaseID
        InternalDataOrgID
        DatabasePhysicalName
      }

      SharedDataset{
        SharedDatasetName
      }
    }
  }
}`;

export const GET_PERSON_ROLE_BY_ID = gql`
query dataManagementPersonRole($id:Long!)
{
dataManagementPersonRole(id:$id)
{
  UserId
  ContactEmail
  Title
  FirstName
  LastName
  DataManagementPersonRoleDataLink{
    id
    RefDataManagementRole
    {
      DataManagementRoleCode
      DataManagementRoleName
    }
  }
}
}`;

export const GET_ALL_ROLES = gql`
query refDataManagementRoleAll
{
  refDataManagementRoleAll
  {
    RefAllDataManagementRoleID
    DataManagementRoleCode
    DataManagementRoleName
    DataManagementRoleDesc
    DataManagementRoleIsActive
}
}`;

export const GET_ALL_PERSONS = gql`
query dataManagementPersonAll
{
    dataManagementPersonAll
    {
      FirstName
      LastName
      id
      MiddleName
      ContactEmail
      ContactHomePhoneNumber
      ContactMobilePhoneNumber
      OrganizationID
      Title
      DataManagementPersonPicture
      DataManagementPersonRoleDataLink{
        id
        RefDataManagementRole
        {
            DataManagementRoleCode
            DataManagementRoleName
            DataManagementRoleIsActive
        }
        ExternalData{
          ExternalDataID
          ExternalDataName
        }

        InternalDatabases{
          InternalDatabaseID
          InternalDataOrgID
          DatabasePhysicalName
        }

        SharedDataset{
          SharedDatasetName
        }
        }
  }
}`;

export const ADD_PERSON_AND_ROLE_BY_EXT_ID = gql`
mutation addDataManagementRoleForUserExternal($id:Long!,$DataManagementRoleCode:String!,$ExternalDataOrgID:String!,$IsRoleActive:String!)
{ addDataManagementRoleForUserExternal(id:$id,DataManagementRoleCode:$DataManagementRoleCode,ExternalDataOrgID:$ExternalDataOrgID,IsRoleActive:$IsRoleActive)
{
   DataManagementRoleCode
 DataManagementRoleName
}
}

`;

export const ADD_PERSON_AND_ROLE_BY_INT_ID = gql`
mutation addDataManagementRoleForUserInternal($id:Long!,$DataManagementRoleCode:String!,$InternalDataOrgID:String!,$IsRoleActive:String!)
{ addDataManagementRoleForUserInternal(id:$id,DataManagementRoleCode:$DataManagementRoleCode,InternalDataOrgID:$InternalDataOrgID,IsRoleActive:$IsRoleActive)
{
   DataManagementRoleCode
 DataManagementRoleName
}
}

`;

export const ADD_ROLE_FOR_USER_BY_EXT_ID = gql`
  mutation addDataManagementRoleForUserExternal($id:Long!,$DataManagementRoleCode:String!,$ExternalDataOrgID:String!)
  { addDataManagementRoleForUserExternal(id:$id,DataManagementRoleCode:$DataManagementRoleCode,ExternalDataOrgID:$ExternalDataOrgID)
  {
    DataManagementRoleCode
  DataManagementRoleName
  }
  }
`;

export const DELETE_ROLE_FOR_USER_BY_EXT_ID = gql`
  mutation removeDataManagementRoleForUser($id:Long!)
  { removeDataManagementRoleForUser(id:$id)
  {
    DataManagementRoleCode
    DataManagementRoleName
  }
  }
`;
