import * as go from 'gojs';
import { Injectable } from '@angular/core';

import { UndoService } from '../services/undo.service';
import { updateDiagramPortsVisibility } from '../utils/updateDiagramPortsVisibility/updateDiagramPortsVisibility';

@Injectable()
export class CommandHandler extends go.CommandHandler {

  constructor(
    private undoService: UndoService
  ) {
    super();
  }

  canUndo() {
    return this.undoService.checkIsUndoPossible();
  }

  async undo() {
    await this.undoService.undo();
    updateDiagramPortsVisibility(this.diagram);
  }

  canRedo() {
    return this.undoService.checkIsRedoPossible();
  }

  async redo() {
    await this.undoService.redo();
    updateDiagramPortsVisibility(this.diagram);
  }

  pasteSelection(pos?: go.Point) {
    super.pasteSelection(pos || this.diagram.lastInput.documentPoint);
  }
}
