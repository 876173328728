import { Component, Input } from '@angular/core';

import { BarGraphImgWrapperData } from './bar-graph-img-wrapper.types';
import { WidgetWrapperComponent } from '../widget-wrapper.component';

@Component({
    selector: 'udp-bar-graph-img-wrapper',
    templateUrl: './bar-graph-img-wrapper.component.html',
    styleUrls: ['./bar-graph-img-wrapper.component.scss']
})
export class BarGraphImgWrapperComponent
    extends WidgetWrapperComponent<BarGraphImgWrapperData> {

    @Input()
    data: BarGraphImgWrapperData; 

}
