import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GraphSearchboxItemModel } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent {
    onSelectionChange(event: GraphSearchboxItemModel) {
        console.info(event);
    }

    form: FormGroup;

    constructor(formBuilder: FormBuilder) {
        this.form = formBuilder.group({
            test: [],
        });
    }
}
