import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { lessThanValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
// import { DataCatalogService } from 'src/app/service/global-services/data-catalog.service';
// import { ErrorHandlingService } from 'src/app/service/global-services/error-handling.service';
// import { UtilService } from 'src/app/service/util-service/util-service';
import { DATA_ASSET_TYP } from './create-data-asset.const';
// import { unsubscribeCollection } from '@shared/utils/common-utilities';
import { Subscription } from 'rxjs';
import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';
import { UtilService } from 'src/app/service/util-service';
import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';
@Component({
  selector: 'lib-create-data-asset',
  templateUrl: './create-data-asset.component.html',
  styleUrls: ['./create-data-asset.component.scss']
})
export class CreateDataAssetComponent implements OnInit, OnDestroy {
  saveDataAssetForm: FormGroup;
  public datasets: any[] = [];
  dataAssetTyp = DATA_ASSET_TYP;
  selectedCategory: string;
  selectedCategoryId: string;
  selectedTypeName: string;
  selectedTypeCode: string;
  fileInfo: any;
  userId: any;
  customerId: any;
  uploadS3File = false;
  uploadS3Info: any;
  queryEditor: any;
  queryScript: any;
  queryDataSourceID: any;
  queryDataSourceType: any;
  querySchema: any;
  isQueryTab: boolean;
  showUploadSection = false;
  uploadS3: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private dialogRef: MatDialogRef<CreateDataAssetComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dataCatalogService: DataCatalogService,
    private fb: FormBuilder,
    private util: UtilService,
    private errorHandlingService: ErrorHandlingService,
    private udpTaskConfigService: UdpTaskConfigService
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.userId = this.udpTaskConfigService.getUserId();
    this.customerId = this.udpTaskConfigService.getCustomerId();
    // create/save data asset form
    this.saveDataAssetForm = this.fb.group({
      dataAssetType: ['', Validators.required],
      dataAssetCategory: ['', Validators.required],
      dataAssetName: ['', Validators.required],
      dataAssetDescription: ['']
    });
    // Retrieve Data Assets
    this.subscriptions[this.subscriptions.length] =
      this.dataCatalogService.newDataAssetInfo$.subscribe((data: any) => {
        if (data) {
          if (data.dataAssetType) {
            this.selectedTypeCode = data.dataAssetType;
          } else if (data.dataSourceType) {
            this.selectedTypeCode = data.dataSourceType;
          }
          this.getDataAssetCategory(true);
          this.fileInfo = data.fileInfo;
          this.selectedTypeName = this.dataAssetTyp.find(
            (item: any) => item.code === this.selectedTypeCode
          ).name;
          // this.uploadS3File = data.uploadS3File;
          this.showUploadSection = false; // data.showUploadSection;
          this.uploadS3File = true;
          // this.uploadS3 = this.fileInfo.uploadS3; // notifier -  uploading file and create file as asset
          if (data.category) {
            this.selectedCategory = data.category.dataSetName;
            this.selectedCategoryId = data.category.dataSetID;
          } else {
            this.selectedCategoryId = '';
            this.selectedCategory = '';
            this.saveDataAssetForm.get('dataAssetCategory').reset();
          }
          this.saveDataAssetForm.patchValue({
            dataAssetType: this.selectedTypeCode,
            dataAssetCategory: this.selectedCategoryId
          });
          // this.dataCatalogService.newDataAssetInfo.next(null);
          if (data.fileInfo.uploadSaveAsset) {
            // check - to call saveDataAsset() while uploading file, after clicking import
            this.saveDataAsset();
          }
        } else {
          this.selectedCategoryId = '';
          this.selectedCategory = '';
          this.selectedTypeCode = '';
          this.selectedTypeName = '';
          this.saveDataAssetForm.get('dataAssetCategory').reset();
        }
      });
    // upload s3 file -  called on click of upload in explorer tab
    this.subscriptions[this.subscriptions.length] =
      this.dataCatalogService.uploadS3FileInfo$.subscribe((data: any) => {
        if (data) {
          this.selectedTypeCode = data.dataAssetType;
          this.selectedTypeName = this.dataAssetTyp.find(
            (item: any) => item.code === this.selectedTypeCode
          ).name;
          this.getDataAssetCategory(true);
          this.uploadS3Info = data;
          this.uploadS3File = data.uploadS3File;
          this.showUploadSection = true; // data.showUploadSection;
        }
      });
    // creating new asset while uploading - on click of import button in data.import
    this.subscriptions[this.subscriptions.length] =
      this.dataCatalogService.uploadSaveAsset$.subscribe((data: any) => {
        if (data) {
          this.fileInfo = data.fileInfo;
          this.saveDataAsset();
        }
      });

    // Query info to save
    if (this.data) {
      this.queryEditor = this.data.saveQueryData.queryEditor;
      this.isQueryTab = this.data.saveQueryData.isQueryTab;
      this.queryScript = this.data.saveQueryData.queryScript;
      this.queryDataSourceID = this.data.saveQueryData.queryDataSourceID;
      this.queryDataSourceType = this.data.saveQueryData.queryDataSourceType;
      this.querySchema = this.data.saveQueryData.querySchema;
      this.selectedTypeName = DATASOURCE.QUERY;
      this.selectedTypeCode = DATASOURCE.QUERY_CODE;
      this.getDataAssetCategory(true);
      this.saveDataAssetForm.patchValue({
        dataAssetType: 'QRY'
      });
    }
    // this.subscriptions[this.subscriptions.length] = this.dataCatalogService.saveQueryInfo$.subscribe((data:any) => {
    //   if(data) {
    //     this.queryEditor = data.queryEditor;
    //     this.isQueryTab = data.isQueryTab;
    //     this.queryScript = data.queryScript;
    //     this.queryDataSourceID = data.queryDataSourceID;
    //     this.queryDataSourceType = data.queryDataSourceType;
    //     this.querySchema = data.querySchema;
    //     this.selectedTypeName = 'Query';
    //     this.selectedTypeCode = 'QRY';
    //     this.getDataAssetCategory(true);

    //     this.saveDataAssetForm.patchValue({
    //       dataAssetType: 'QRY'
    //     });
    //   }
    // })
  }

  /**
   *
   */
  // backButton() {
  //   if(this.uploadS3File) {
  //     this.router.navigateByUrl(`dashboard/dmg/data-catalog/datasets`);
  //     this.dataCatalogService.fileUpload.next({fileUploaded: true, bucketName: this.fileInfo.basketName});
  //   } else {
  //     this.router.navigateByUrl(`dashboard/dmg/data-catalog/datasets`);
  //   }
  // }

  /**
   *
   * @param data
   */
  onChangeDataAsset(data: any) {
    this.selectedTypeCode = data.value;
    this.getDataAssetCategory();
  }

  /**
   *
   * @param data
   */
  onSelectCategory(data: any) {
    this.dataCatalogService.uploadS3FileSelectedCategory.next({
      dataAssetCategoryID: this.saveDataAssetForm.value.dataAssetCategory
    });
  }

  /**
   *
   * @param e
   */
  dataAssetNameChange(e) {
    this.dataCatalogService.uploadS3FileDataAssetName.next({
      fileName: this.saveDataAssetForm.value.dataAssetName
    });
  }

  /**
   *
   */
  getDataAssetCategory(isLoad: boolean = false) {
    this.subscriptions[this.subscriptions.length] = this.dataCatalogService
      .getDataAssestCategories(
        this.selectedTypeCode,
        this.customerId,
        this.userId
      )
      .subscribe((res: any) => {
        if (
          res.dataAssetCategoryList &&
          res.dataAssetCategoryList[this.selectedTypeCode]
        ) {
          this.datasets = res.dataAssetCategoryList[this.selectedTypeCode].map(
            (item) => {
              return item.dataAssetCategory;
            }
          );
          // if(isLoad) {
          //   setTimeout(() => {
          //     this.saveDataAssetForm.patchValue({
          //       dataAssetCategory: this.selectedCategoryId,
          //     });
          //   }, 500);
          // }
        }
      });
  }

  /**
   * saveDataAssetFormControl
   */
  get saveDataAssetFormControl() {
    return this.saveDataAssetForm.controls;
  }

  /**
   *
   */
  backButton() {
    if (this.uploadS3Info) {
      this.dataCatalogService.backToExplorer.next({
        backToExplorer: true,
        bucketName: this.uploadS3Info.bucketName
      });
      // this.router.navigateByUrl(
      //   `dashboard/dmg/data-catalog/datasets/view-data-source/${this.uploadS3Info.dataSource}/${this.uploadS3Info.dataSourceID}`
      // );
    } else if (this.uploadS3File) {
      this.dataCatalogService.backToExplorer.next({
        backToExplorer: true,
        bucketName: this.fileInfo.basketName
      });
      // this.router.navigateByUrl(
      //   `dashboard/dmg/data-catalog/datasets/view-data-source/${this.fileInfo.dataSource}/${this.fileInfo.dataSourceID}`
      // );
    } else {
      // this.router.navigateByUrl(`/dashboard/dmg/data-catalog/datasets`);
    }
  }

  /**
   *
   */
  onResetForm(aid?: string) {
    this.dialogRef.close(aid);

    // this.saveDataAssetForm.reset();
    // this.dataCatalogService.newDataAssetClosed.next(true);
    if (this.queryEditor) {
      this.dataCatalogService.newDataAssetClosed.next({
        cancel: true,
        save: null
      });
      // this.router.navigateByUrl(
      //   `/dashboard/dmg/data-catalog/datasets/query-controller`
      // );
    } else if (this.uploadS3File) {
      this.dataCatalogService.backToExplorer.next({
        backToExplorer: true,
        bucketName: this.fileInfo.basketName
      });
      // this.router.navigateByUrl(
      //   `dashboard/dmg/data-catalog/datasets/view-data-source/${this.fileInfo.dataSource}/${this.fileInfo.dataSourceID}`
      // );
    } else {
      this.saveDataAssetForm.reset();
      this.dataCatalogService.newDataAssetClosed.next(true);
    }
  }

  /**
   *
   */
  saveDataAsset() {
    const data = {
      customerFile: null,
      dataSet: null,
      query: null
    };
    let type = '';
    if (this.selectedTypeCode === DATASOURCE.FILE_ASSET_CODE) {
      const DACId = this.saveDataAssetForm.value.dataAssetCategory
        ? this.saveDataAssetForm.value.dataAssetCategory
        : this.fileInfo.dataAssetCategoryID;
      const fName = this.saveDataAssetForm.value.dataAssetName
        ? this.saveDataAssetForm.value.dataAssetName
        : this.fileInfo.fileName;
      const fileType = this.fileInfo.format
        ? this.fileInfo.format
        : this.fileInfo.Key.split('.')[1];

      data.customerFile = {
        isActive: true,
        dataAssetCategoryID: DACId, // this.selectedCategoryId, //this.saveDataAssetForm.value.dataAssetCategory,
        dataSourceID: this.fileInfo.dataSourceID,
        fileName: fName, // this.saveDataAssetForm.value.dataAssetName,
        fileLocation: this.fileInfo.Key, // this.fileInfo.fileLocation || '',
        basketName: this.fileInfo.basketName,
        fileType, // this.fileInfo.format,
        fileOwnerUserID: this.userId,
        fileSize: this.fileInfo.Size,
        fileSchema: '',
        updateUserID: this.userId,
        createDate: new Date(),
        updateDate: new Date(),
        fileCreateUserID: this.userId,
        filePhysicalName: this.fileInfo.Key,
        parentFileID: 'test',
        importedFileFlag: true
      };
      type = 'file';
    } else if (this.selectedTypeCode === DATASOURCE.DATASET_CODE) {
      data.dataSet = {
        datasetID: '',
        datasetCreateUser: this.userId,
        datasetCreateDate: '',
        datasetLastUpdateDate: '',
        datasetLastUpdateUser: this.userId,
        datasetLastRunDate: 'None',
        dataAssetCategoryID: this.saveDataAssetForm.value.dataAssetCategory,
        isCompositeDataSet: false,
        datasetName: this.saveDataAssetForm.value.dataAssetName,
        datasetDescription: this.saveDataAssetForm.value.dataAssetDescription,
        datasetSchema: '',
        compositeDatasetBlendMetadata: '',
        datasetType: 'DS'
      };
      type = 'dataset';
    } else if (this.selectedTypeCode === DATASOURCE.QUERY_CODE) {
      data.query = {
        isQueryTemplate: true,
        queryDescription: this.saveDataAssetForm.value.dataAssetDescription,
        queryID: '',
        queryLanguage: 'sql',
        queryName: this.saveDataAssetForm.value.dataAssetName,
        sharedFromUserID: this.userId,
        sharedToUserID: this.userId,
        dataAssetCategoryID: this.saveDataAssetForm.value.dataAssetCategory,
        querySchema: this.querySchema ? this.querySchema : '',
        lastExecutedOn: '2022-05-24 22:53:30.585335+05:30',
        lastExecutedbyUserID: this.userId,
        queryCreateUserID: this.userId,
        queryDataSourceID: this.queryDataSourceID,
        queryDataSourceType: this.queryDataSourceType
          ? this.queryDataSourceType
          : '',
        queryScript: this.queryScript,
        queryDashboardConfig: []
      };
      this.dataCatalogService.lastQueryScript.next(data.query.queryScript);
      type = 'query';
    } else {
      return;
    }
    this.subscriptions[this.subscriptions.length] = this.dataCatalogService
      .addDataAsset(data, type, true, this.userId, this.customerId)
      .subscribe(
        (resp: any) => {
          if (resp.data.sc !== 'Run Query failed') {
            this.dataCatalogService.reloadCategories.next(true);
            this.errorHandlingService.errorSuccessSnack('134', true);
            const aid = resp && resp.data && resp.data.aid;
            this.onResetForm(aid);
            if (this.selectedTypeCode === 'DS') {
              this.dataCatalogService.newDataAssetInfo.next({
                dataAssetType: 'DS',
                dataAssetID: resp
              });
            }
            if (this.selectedTypeCode === DATASOURCE.FILE_ASSET_CODE) {
              this.saveDataAssetForm.reset();
            }
            if (this.queryEditor) {
              if (this.selectedTypeCode === DATASOURCE.QUERY_CODE) {
                this.saveDataAssetForm.reset();
                this.onResetForm(aid);
                this.dataCatalogService.newDataAssetClosed.next({
                  cancel: false,
                  save: resp
                });
              }
            }
          } else {
            this.errorHandlingService.errorSuccessSnackMessage(
              resp.data.sc,
              false
            );
          }
        },
        (err) => {
          this.errorHandlingService.errorSuccessSnackMessage(
            err.message,
            false
          );
        }
      );
  }

  /**
   *
   */
  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
}
