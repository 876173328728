import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationPageComponent } from './administration-page.component';

@NgModule({
    declarations: [AdministrationPageComponent],
    imports: [CommonModule],
    exports: [AdministrationPageComponent],
})
export class AdministrationPageModule {}
