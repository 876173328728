// tslint:disable: max-line-length
export const WORKFLOW_ID_PARAM_NAME = 'nodeId';
export const WORKFLOW_NAME_PARAM_NAME = 'nodeName';
export const LOGIN = 'login';
export const TOKEN = 'token';
export const BRANCHNAME = 'branchName';
export const VERSION = 'version';
export const GITHUBURL = 'gitHubUrl';
export const WORKFLOWDEFFILENAME = 'workflowDefFileName';
export const DAGFILENAME = 'dagFileName';
export const DAGPATHNAME = 'dagPathName';
export const DAGFILESHA = 'dagFileSha';
export const PYTHONFILESHA = 'pythonFileSha';
export const CUSTOMERID = 'customerId';
export const USERID = 'userId';
export const WORKFLOW_EDITOR_PATH = 'workflow-editor';
export const ACCESS_TOKEN = 'access_token';
export const WORKFLOW_EDITOR_ID_PATHS = `${WORKFLOW_EDITOR_PATH}/:${WORKFLOW_ID_PARAM_NAME}/:${WORKFLOW_NAME_PARAM_NAME}/:${LOGIN}/:${TOKEN}/:${ACCESS_TOKEN}/:${BRANCHNAME}/:${VERSION}/:${GITHUBURL}/:${WORKFLOWDEFFILENAME}/:${DAGPATHNAME}/:${DAGFILENAME}/:${DAGFILESHA}/:${PYTHONFILESHA}/:${CUSTOMERID}/:${USERID}`;
// tslint:enable
