import * as _ from 'lodash/fp';
import * as go from 'gojs';

import { updateNodeLinksValidation } from '../utils/updateLinksValidation';

const isInsideOwnGroup = (group: go.Group, part: go.Part) =>
  part.containingGroup && (part.containingGroup.key === group.key);

export const handleAddNodesToGroup = (event: go.InputEvent) => {
  const diagram = event.diagram;
  const group = (event.targetObject && event.targetObject.part) as go.Group;
  const nodes = diagram.selection;
  const nodesToAdd = nodes.filter(
    (node) => !isInsideOwnGroup(group, node)
  );

  if (!nodesToAdd.count || !group.data.isGroup) {
    return;
  }

  group.addMembers(nodesToAdd);
  nodesToAdd.each((node) => {
    if (node instanceof go.Node) {
      updateNodeLinksValidation(node);
    }
  });
};
