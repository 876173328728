import * as _ from 'lodash/fp';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const getIconRegistry = (
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
) => {
    const registerIconsInNamespace = (namespace: string, url: string) =>
        _.forEach((icon: string) => {
            const path = domSanitizer.bypassSecurityTrustResourceUrl(
                `${url}/${icon}.svg`
            );

            matIconRegistry.addSvgIconInNamespace(
                namespace,
                icon,
                path,
            );
        });

    return {
        registerIconsInNamespace
    };
};
