import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataAssetCategory, Query } from '@xfusiontech/data-visualizer';
import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { Router } from '@angular/router';

import { filter, map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterItem } from 'libs/sidebar/src/lib/sidebar-with-filter/sidebar-with-filter.interface';
import { FILTERS, getFiltersForm } from 'libs/sidebar/src/lib/sidebar-with-filter/sidebar-with-filter.constants';
import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';


@Component({
  selector: 'lib-view-data-catalog',
  templateUrl: './view-data-catalog.component.html',
  styleUrls: ['./view-data-catalog.component.scss']
})
export class ViewDataCatalogComponent implements OnInit {

  @Input() selMapping: any;
  public menuItems: any[] = [];
  public menuCodes: string[] = [];
    public datasets: any[] = [];
    public selectedItem: any;
    public datasetsBackUp: any[] = [];
    activeRoute = 'CAT';
    searchTerm = '';
    assetCategoriesData: any;
    refDatasetData: any;
    private _updateNotifierSub: Subscription = null;
    public filterForm: FormGroup;
    public filters: FilterItem[];
    private searchStr = '';
    private selFilters = '';
    isAddRefData = false;
    selectedSubCat: any;
    userId: string;
    customerId: string;
    @Output() onCheckCodeMap =  new EventEmitter();

    private subscriptions: Subscription[] = [];

  /**
   * @constructor
   */
  constructor(
    private router: Router,
   // private sharedDataService: SharedDataService,
    private dataCatalogService: DataCatalogService,
    public udpTaskConfigService: UdpTaskConfigService
    ) {
      // this.router.routeReuseStrategy.shouldReuseRoute = function() {
      //   return false;
      // };
    }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
   public ngOnInit(): void {
    this.filters = FILTERS;
    this.filterForm = getFiltersForm(
      this.filters
    );
    this.filterForm.addControl('all', new FormControl(''));


    if (this.udpTaskConfigService.getCustomerId() !== '' && this.udpTaskConfigService.getUserId() !== '') {
      this.userId = this.udpTaskConfigService.getUserId();
      this.customerId = this.udpTaskConfigService.getCustomerId();
    }

    if (this.customerId) {
      this.sidebarCategoryDisplay();
    }

    if (!this._updateNotifierSub) {
      this._updateNotifierSub = this.dataCatalogService.updateNotifier.asObservable()
      .subscribe(obj => {
        if (obj && obj.updateDAC) {
          this.ngOnInit();
        }
      });
    }
    this.filterForm.valueChanges.subscribe((data: any) => {
      if (data) {
        const map = {all: 'ALL', dataSources: 'DSRC', datasets: 'DS', query: 'QRY', visualization: 'VIZ', dataFile: 'DF', notebook: 'NB' };
        if (data.all) {
          this.selFilters = 'ALL';
        } else {
          const temp = [];
          for (let key in data) {
            if (data[key]) {
              temp.push(map[key]);
            }
          }
          this.selFilters = temp.join(';');
        }
        this.doSearch();
      }
    });
    this.subscriptions[this.subscriptions.length] = this.dataCatalogService.reloadCategories$.subscribe((reload: any) => {
      if (reload) {
        this.sidebarCategoryDisplay();
        this.dataCatalogService.reloadCategories.next(false);
      }
    });
  }
  toggleSelectAll(data: any) {
    this.filters.forEach((item: any) => {
      this.filterForm.get(item.control).setValue(data.checked);
    });
  }

  onSearch($event: any) {
    this.searchStr = $event.target.value;
    this.doSearch();
  }
  doSearch() {
    if (!this.searchStr) return;
    const data: any = {
      searchInput: this.searchStr,
      filterList: this.selFilters || 'ALL'
    };

  }

  async sidebarCategoryDisplay() {
    this.refDatasetData = [];
    this.subscriptions[this.subscriptions.length] = this.dataCatalogService.getReferenceDatasets(this.customerId).subscribe((data: any) => {
      if (data.length > 0) {
        this.refDatasetData = data;
        let setVal = {
            id: 5,
            dataAssetType: 'Reference Datasets',
            dataAssetCode: 'RDS',
            dataAssets: this.refDatasetData ? this.refDatasetData.map((val: any) => {
              return {
                ...val,
                dataSetDataSource: [],
                dataSetID: val.refDataSetID,
                dataSetName: val.refDataSetName,
                datasetSchema: {}
              };
            }) : []
          };
          this.datasets.push(setVal);
          this.datasetsBackUp = [...this.datasets];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataCatalogService.selectedCategory.next(null);
    this.dataCatalogService.selectedDataAsset.next(null);
  }

  /**
   * @public
   * @param: {e<any>}
   * @return: void
   * @description: a helper method that
   * that brings data from the sidebar
   * component upon user's selection.
   */
  public onDatasetSelect(e: any): void {
      this.dataCatalogService.selectedDataAsset.next(e);
  }

  public newAsset(data: any) {
    if (!location.href.includes('datasets/data-asset') || location.href.includes('datasets/data-asset/view')) {
      this.router.navigate([`/dashboard/dmg/data-catalog/datasets/data-asset`]);
    }
    this.dataCatalogService.newDataAssetInfo.next(data);
  }

  public newCategory(data: any) {
    this.dataCatalogService.newCategoryInfo.next(data);
  }

  /**
   *
   * @param e
   */
  public onDataSetCategory(e: any): void {
      this.dataCatalogService.selectedCategory.next(e);
  }

  onTypeSelect(dataAssetCode: string) {
    if (dataAssetCode === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
      this.goTODataSources('INTD');
    }
  }
  goTODataSources(dataAssetType: string) {
    this.dataCatalogService.selectedDSCategory.next(dataAssetType);
    if (!location.href.includes('datasets/datasources')) {
      this.router.navigate([`/dashboard/dmg/data-catalog/datasets/datasources`]);
    }
  }

  async onExpandCat({asset, dataAssetCode}) {
    if (dataAssetCode === 'RDS') {
      this.subscriptions[this.subscriptions.length] = await this.dataCatalogService.getRefDatasetVersions(asset.dataSetID, this.customerId).subscribe((value: any) => {
        if (value) {
          asset.dataSetDataSource = value.map((ds) => {
            return {
              ...ds,
              dataSourceID: ds.refDataSetVersionID,
              dataSourceName: ds.refDataSetVersion,
            };
          });
        }
      });
    }
  }

  /**
   * @public
   * @param: {list<any[]>}
   * @return: void
   * @description: a helper method that
   * that listens to the filters.
   */
  public onFilterUpdate(list: any[]): void {
    if (Array.isArray(list)) {
      const data: any = [];
      if (list.length > 0) {
        for (const item of this.datasetsBackUp) {
          if (list.includes(item.dataAssetType)) {
            data.push(item);
          }
        }
        this.datasets = data;
      } else {
        this.datasets = [...this.datasetsBackUp];
      }
    }
  }

  onCheckCode(e) {
    if (e) {
      this.onCheckCodeMap.emit(e);
    }
  }

  /**
   *
   */
   ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
    this._updateNotifierSub && this._updateNotifierSub.unsubscribe();
  }
}
