import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, ViewChild, ViewRef, ChangeDetectorRef, TemplateRef, ElementRef } from '@angular/core';

import { ValueObject } from '../../../types/shared';
import { CodeEditorLanguage } from '../types';
import { EntityDataMapping, IntegrationWorkflowDataModel, WorkFlow } from '../integration-workflow-data-model';
import * as _ from 'lodash';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IntegrationWorkflowService } from 'src/app/workflow-editor/integration-workflow.service';
import { Configurations } from '../configuration';
import { ExternalDataService } from 'src/app/external-data.service';
import { InternalDataService } from 'src/app/internal-data.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'xft-data-mapping',
    templateUrl: './data-mapping.component.html',
    styleUrls: ['./data-mapping.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataMappingComponent {

    public workFlowData: WorkFlow;
    customTransformationRuleScript = '';
    workflowNameList: Array<String> = [];
    isSelectedForSI: Boolean = false;
    selectedListForSI: Array<any> = [];
    selectedParamsForSI: Array<any> = [];
    entityClassTypeList = [];
    primaryRelatedViewList: Array<any> = [];
    primaryRelatedViewConfiguration: any;
    showSubjectIdentification: Boolean = false;
    _DATA_MAPPING_COMPONENT_CODE = 'dataMapping';
    dataModel: IntegrationWorkflowDataModel;
    entityDataMappingForm: FormGroup;
    entityMappingModalRef: BsModalRef | null;
    modalRefManageWorkFlow: BsModalRef | null;
    showDuplicateAlert: Boolean = false;
    mapValues: Array<any> = [];
    currentMapIndex = 0;
    targetEntityTypeDropdownOptions = [];
    popuptitle: any;
    popupbutton: any;
    workFlowId: any;
    mappingEdit: boolean;
    addChildButtonsEnabling: boolean;
    public addEntityDatamappingObj = [];
    public setSourceDataElementsList = [];
    public setTargetDataElementsList = [];
    public setSelectedTypeId: string;
    public setSelectedClassId: string;
    public setSelectedNameId: string;
    targetEntityClassMapping: any;
    targetEntityName = [];
    isSourceMappingDataElementDropdownMultiSelect: boolean;

    dropDownConfig = Configurations.ngxSelectdropDownConfig;
    tdndDropDownConfig = Configurations.ngxSelectdropDownConfig;
    customDropDownConfig = Configurations.ngxSelectdropDownConfig;

    availability: boolean;
    targetEntityNameErrorMessage: string;
    overrideDuplicateMapping: boolean;
    disableMappingDropdown: boolean;

    public singleDataMappingobj: any[] = [];
    parentDataForNested: any;
    modalRef2: BsModalRef;

    workFlowDataInput: any;
    workFlowDataSample: any;
    externalData: any = [];
    internalData: any = [];

    externalDataSize: any;
    internalDataSize: any;
    // aceEditorConfig: AceConfigInterface = {
    //     mode: "javascript",
    //     // theme: 'github',
    //     readOnly: false,
    //   };
    // editorOptions = {theme: 'vs-light', language: 'javascript'};
    @ViewChild('templateNested', { static: false }) templateNested;
    @ViewChild('template', { static: false }) template;
    @ViewChild('conformDelete', null) conformDelete: ElementRef;

    @Input()
    set language(language: CodeEditorLanguage) {
        this.editorOptions.language = language;
    }

    // @Input()
    // value: string;

    editorOptions: ValueObject = {
        theme: 'vs-light'
    };

    @ViewChild('editor', { static: false })
    editor: ValueObject;

    @Output()
    valueChange = new EventEmitter<string>();

    constructor(private _cd: ChangeDetectorRef,
                private _fb: FormBuilder,
                private _workFlowService: IntegrationWorkflowService,
                public modalService: BsModalService,
                public dialog: MatDialog,
                private _externalDataService: ExternalDataService,
                private _internalDataService: InternalDataService) {
            this.dataModel = new IntegrationWorkflowDataModel();

    }

    ngOnInit() {



          this.getEntityDataMapping('WF-3-PCAP-64');

          this.workFlowDataInput = {
            ReviseSourceDataType: '3:Personal Capital External Data(PCAP)',
            ReviseTargetDataType: 'Hope2 Transaction Database(HOPE2-TSDB)',
            id: 97,
            lastUpdateDate: '2021-03-03 01:02:40',
            owner: 'Austin Robert Bryant',
            pipelineCategory: 'ETL',
            pipelineId: 'DAG-ETL-20210122161635',
            sourceDataTypeCode: 'Personal Capital External Data',
            status: 'Draft',
            targetDataTypeCode: 'Hope2 Transaction Database',
            workflowDescription: 'ETL Customer Data from CSV File to Neo4j',
            workflowDocumentId: '60f47312ec05c34c4305ae40',
            workflowID: 'WF-3-PCAP-64',
            workflowName: 'ETL Customer Data from CSV File to Neo4j',
            workflowVersion: ''
          };


          this.workFlowDataSample = {
            lastUpdateDate: '2021-03-03 01:02:40',
            owner: 'Austin Robert Bryant',
            pipelineId: 'DAG-ETL-20210122161635',
            schedule: 'Yes',
            sourceDataTypeCode: 'Personal Capital External Data',
            status: 'Draft',
            targetDataTypeCode: 'Hope2 Transaction Database',
            version: '',
            workflowDescription: 'ETL Customer Data from CSV File to Neo4j',
            workflowDocumentId: '60f70932ec05c34c4305ae41',
            workflowID: 'WF-3-PCAP-64',
            workflowName: 'ETL Customer Data from CSV File to Neo4j',
            workflowType: 'ETL Pipeline (ETL)'
          };
          this.getAllExternalDataSource();
          this.getTargetEntityMapping(this.workFlowDataSample);

          this.entityDataMappingForm = this._fb.group({
            targetEntityType: ['', Validators.required],
            targetEntityName: ['', Validators.required],
            targetEntityClass: ['', Validators.required],
            isSelectedForSI: false,
            details: this._fb.array([]),
          });
    }

    get edp() {
        return this.entityDataMappingForm.controls;
    }

    updateFormControlRowJavascripRule(parentRef) {
        this.parentDataForNested = {};
        if (parentRef) {
          parentRef.controls.customRuleJavascript.setValue(
            this.customTransformationRuleScript
          );

          this.modalRef2.hide();
          this.modalRef2 = null;
          this.parentDataForNested = parentRef;

        }
      }

      isArray(value) {
        return _.isArray(value);
      }

    getAllExternalDataSource() {
        this._externalDataService
          .fetchAllExternalData()
          .subscribe((res: any) => {
            const { data }: any = res;
            this.externalData = data.externalDataAll;
            this.externalDataSize = this.externalData.length;
            this.getAllInternalDataSource();
          });
      }

      getAllInternalDataSource() {
        this._internalDataService
          .fetchAllInternalData()
          .subscribe((res: any) => {
            const { data }: any = res;
            this.internalData = data.internalDatabasesAll;
            this.internalDataSize = this.internalData.length;
            // this.getDataIntegrationList();
          });
      }


    overrideDuplicate(response) {
        if (response == 'Yes') {
          this.overrideDuplicateMapping = true;
        } else {
          this.overrideDuplicateMapping = false;
          this.deleteMappingRow(this.currentMapIndex);
        }

        this.showDuplicateAlert = false;
        this.disableMappingDropdown = false;
        this.availability = false;
      }


      formValid() {
        return this.entityDataMappingForm.valid;
      }


      async addMappingData(workflow) {
        if (this.entityDataMappingForm.valid) {
          const formValues = this.entityDataMappingForm.value;
          this.entityDataMappingForm.patchValue({
            isSelectedForSI: formValues.isSelectedForSI,
          });
          if (!this.mappingEdit) {
            this.workFlowData.rawEntityDataMapping.push({
              targetEntity: {
                entityId: formValues.isSelectedForSI,
                entityName: formValues.targetEntityName,
                entityPhysicalName: formValues.targetEntityName,
                entityType: formValues.targetEntityType.value,
                entityClassType: formValues.targetEntityClass,
                entityLinkAndLoadPolicy: '',
              },
              dataMappings: this.entityDataMappingForm.value.details,
            });
          } else if (this.mappingEdit) {
            const editEntityIndex = this.workFlowData.rawEntityDataMapping.findIndex(
              (i) =>
                i.entityPhysicalName == formValues.dataMappingName &&
                i.entityName == formValues.targetEntityName
            );
            if (editEntityIndex > -1) {
              this.workFlowData.rawEntityDataMapping.splice(
                editEntityIndex,
                1,
                formValues
              );
            }
          }


          this.mappingEdit = false;
        } else {
          alert('notvalid');
        }
        this.workFlowId = -1;
      }

      displayCodeEditorFromIcon(templateNested, formControlRow) {
        this.customTransformationRuleScript =
          formControlRow.controls.customRuleJavascript.value;



        this.openModal2(templateNested, formControlRow);
        this.setCodeInEditorSingle(formControlRow.controls);
        if (formControlRow.controls.targetDataElement.value.value === 'City') {
            this.customTransformationRuleScript = 'function customRuleFunction(params)\n{ \n    var obj = JSON.parse(params.Address); \n    return obj.City;\n}';
          }

        if (formControlRow.controls.targetDataElement.value.value === 'StateCode') {
            this.customTransformationRuleScript = 'function customRuleFunction(params)\n{ \n    var obj = JSON.parse(params.Address); \n    return obj.StateCode;\n}';
          }

        if (formControlRow.controls.targetDataElement.value.value === 'StreetName') {
            this.customTransformationRuleScript = 'function customRuleFunction(params)\n{ \n    var obj = JSON.parse(params.Address); \n    return obj.StreetName;\n}';
          }

        if (formControlRow.controls.targetDataElement.value.value === 'LastName') {
            this.customTransformationRuleScript = 'function customRuleFunction(params)\n{ \n    var names = params.Name; \n    names = names.split(\' \'); \n    return names[1];\n}';
          }

        if (formControlRow.controls.targetDataElement.value.value === 'FirstName') {
            this.customTransformationRuleScript = 'function customRuleFunction(params)\n{ \n   var names = params.Name; \n     names = names.split(\' \'); \n    return names[1];\n}';
          }
        formControlRow.controls.customRuleJavascript.setValue(
            this.customTransformationRuleScript
          );
      }

      setDefaultTranformationRule(formControl) {
        formControl.controls.transformationRuleName.setValue('SELECT');
      }


      isMultiselect(i, other) {
        let multiSelect = false;
        if (other.controls.displayOne2oneTransformationRule.value) {
          this.isSourceMappingDataElementDropdownMultiSelect = false;
        } else if (other.controls.displayN2oneTransformationRule.value) {
          this.isSourceMappingDataElementDropdownMultiSelect = true;
        }

        if (this.isSourceMappingDataElementDropdownMultiSelect) {
          multiSelect = true;
        } else if (
          this.singleDataMappingobj &&
          this.singleDataMappingobj[this.workFlowId] &&
          this.singleDataMappingobj[this.workFlowId].entityDataMapping[i]
        ) {
          const childDataMapping = this.singleDataMappingobj[this.workFlowId]
            .entityDataMapping;
          if (childDataMapping.length > 0 && childDataMapping[i]) {
            if (
              this.singleDataMappingobj[this.workFlowId].entityDataMapping[i]
                .oneToOneMap
            ) {
              multiSelect = !this.singleDataMappingobj[this.workFlowId]
                .entityDataMapping[i].oneToOneMap;
            } else {
              if (
                this.singleDataMappingobj[this.workFlowId].entityDataMapping[i]
                  .dataMappings[i]
              ) {
                multiSelect =
                  this.singleDataMappingobj[this.workFlowId].entityDataMapping[i]
                    .dataMappings[i].transformation.mappingType == '1->1' ||
                    '1 -> 1'
                    ? false
                    : true;
              } else {
                multiSelect = false;
              }
            }
          }
        }
        return multiSelect;
      }


      displayCodeEditor(event, templateNested, formControlRow) {
        if (formControlRow.controls.displayOne2oneTransformationRule.value) {
          if (event.value == 'CUSTOM') {
            this.openModal2(templateNested, formControlRow);
            this.setCodeInEditorSingle(formControlRow.controls);

            formControlRow.controls.customRuleJavascript.setValue(
              this.customTransformationRuleScript
            );
          }
        } else if (formControlRow.controls.displayN2oneTransformationRule.value) {
          const isAllTypeSame = this.checkIfAllSourcesHaveSameTypes(
            formControlRow.controls
          );
          if (isAllTypeSame) {
            if (event.value == 'SUM') {
              this.setCodeInEditorMulti('SUM', formControlRow.controls);
            } else if (event.value == 'AVERAGE') {
              this.setCodeInEditorMulti('AVG', formControlRow.controls);
            } else {
              this.setCodeInEditorMulti('CUSTOM', formControlRow.controls);
            }
            formControlRow.controls.customRuleJavascript.setValue(
              this.customTransformationRuleScript
            );
          }
          this.openModal2(templateNested, formControlRow);
        }
      }


      setCodeInEditorSingle(formControl) {
        this.customTransformationRuleScript = '';
        this.customTransformationRuleScript = 'function customRuleFunction(';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript + 'sourceRecord) {\n\t';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript +
          'var i_' +
          formControl.sourceDataElement.value.id +
          ' = ' +
          'sourceRecord.' +
          formControl.sourceDataElement.value.id +
          ';\n\t';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript +
          '//\t' +
          'Calculation for ' +
          'CUSTOM' +
          '\n\t';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript + 'return ';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript +
          'i_' +
          formControl.sourceDataElement.value.id +
          ';';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript + '\n}';
      }

      setCodeInEditorMulti(value, formControl) {
        this.customTransformationRuleScript = '';
        this.customTransformationRuleScript = 'function customRuleFunction(';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript + 'sourceRecord) {\n\t';
        formControl.sourceDataElement.value.forEach((item) => {
          this.customTransformationRuleScript =
            this.customTransformationRuleScript +
            'var i_' +
            item.id +
            ' = ' +
            'sourceRecord.' +
            item.id +
            ';\n\t';
        });

        this.customTransformationRuleScript =
          this.customTransformationRuleScript +
          '//\t' +
          'Calculation for ' +
          value +
          '\n\t';
        this.customTransformationRuleScript =
          this.customTransformationRuleScript + 'return (';
        if (value == 'AVG' || value == 'SUM') {
          formControl.sourceDataElement.value.forEach((item, index) => {
            if (index != formControl.sourceDataElement.value.length - 1) {
              this.customTransformationRuleScript =
                this.customTransformationRuleScript + 'i_' + item.id + ' + ';
            }

            if (index == formControl.sourceDataElement.value.length - 1) {
              this.customTransformationRuleScript =
                this.customTransformationRuleScript + 'i_' + item.id;
            }
          });
        }

        if (value == 'AVG') {
          this.customTransformationRuleScript =
            this.customTransformationRuleScript + ')';
          this.customTransformationRuleScript =
            this.customTransformationRuleScript +
            '/' +
            formControl.sourceDataElement.value.length +
            ';';
        } else if (value == 'SUM' || value == 'CUSTOM') {
          this.customTransformationRuleScript =
            this.customTransformationRuleScript + ');';
        }

        this.customTransformationRuleScript =
          this.customTransformationRuleScript + '\n}';
      }

      checkIfAllSourcesHaveSameTypes(formControl) {
        const arr = [];
        formControl.sourceDataElement.value.forEach((item) => {
          arr.push(
            _.filter(this.setSourceDataElementsList, ['id', item.id])[0].type
          );
        });

        if (arr.every((val, i, arr) => val === arr[0])) {
          return true;
        } else {
          return false;
        }
      }

      displaySeelctedSourceDataElements(selectedValues) {
        let values = '';
        if (Array.isArray(selectedValues)) {
          selectedValues
            .map((sv) => sv.id)
            .forEach((i) => {
              values = values + '<br/>' + i;
            });
        } else {
          values = selectedValues.trim();
        }

        return values;
      }


      openModal(template: TemplateRef<any>, workFlow) {
        const initialState = workFlow;
        const dialogRef = this.dialog.open(template, {
            data : initialState,
            panelClass: 'custom-modalbox'
          });
        // this.entityMappingModalRef.content = {};
        // this.entityMappingModalRef.content.workFlow = workFlow;
        this.availability = false;
        this.targetEntityNameErrorMessage = '';
      }

      openModal2(template: TemplateRef<any>, formControlRowNested) {
        this.parentDataForNested = {};
        const dialogRef = this.dialog.open(template, {
            panelClass: 'custom-modalbox-rule',
             // backdrop: "static",
           });
        this.parentDataForNested = formControlRowNested;

      }

      sourceDataElementChange(event, index, other) {
        this.overrideDuplicateMapping = false;
        if (other.controls.displayOne2oneTransformationRule.value) {
          if (this.mapValues.length == 0 || this.overrideDuplicateMapping) {
            this.mapValues[index] = [event.value];
          } else {
            for (let i = 0; i < this.mapValues.length; i++) {
              if (_.some(this.mapValues[i], ['id', event.value.id])) {
                this.showDuplicateAlert = true;
                this.disableMappingDropdown = true;
                this.availability = true;
                break;
              }
            }
            if (typeof this.mapValues[index] === 'undefined') {
              this.mapValues[index] = [];
            }
            this.mapValues[index].push(event.value);
          }
        } else {
          if (this.mapValues.length == 0 || this.overrideDuplicateMapping) {
            this.mapValues[index] = event.value;
          } else {
            if (typeof this.mapValues[index] !== 'undefined') {
              this.mapValues[index].splice(this.mapValues[index].length - 1, 1);
            }
            for (let i = 0; i < event.value.length; i++) {
              for (let j = 0; j < this.mapValues.length; j++) {
                if (
                  _.some(this.mapValues[j], [
                    'id',
                    event.value[event.value.length - 1].id,
                  ])
                ) {
                  this.showDuplicateAlert = true;
                  this.disableMappingDropdown = true;
                  this.availability = true;
                  break;
                }
              }
              if (!this.showDuplicateAlert) {
                if (typeof this.mapValues[index] === 'undefined') {
                  this.mapValues[index] = [];
                }
                this.mapValues[index].push(event.value[i]);
              }
            }
          }
        }


        this.currentMapIndex = index;
      }

      deleteMappingRow(i) {
        const control = this.entityDataMappingForm.controls.details as FormArray;
        control.removeAt(i);
        this.mapValues.splice(i, 1);
      }

    onValueChange(event) {
        this.valueChange.emit(event);
    }

    getClassType(entityClassType) {
        // Abbreviate String to its initials
        let str = '';
        entityClassType.split('_').forEach((item) => {
          str = str.concat(item.charAt(0));
        });
        return str;
      }

      closeEditMapModal() {
        this.showDuplicateAlert = false;
        this.mapValues = [];
      }

      changeSubjectIdentification(formValue) {
        this.workFlowData.rawEntityDataMapping.forEach((item) => {
          if (item.targetEntity.entityName == formValue.targetEntityName) {
            item.targetEntity.entityId = formValue.isSelectedForSI;
          }
        });
      }

      getEntityDataMapping(workflowID: any) {
        this._workFlowService
          .getWorkFlowMappingDetails(workflowID)
          .valueChanges.subscribe(async ({ data }) => {
            this.updateMappingData(data);
            const waitcomplete = await this.getDataElements('Personal Capital External Data');
          });

        // this.getEntitymappingDropDownData(workFlow.TargetDataTypeCode);
      }

    updateMappingData(data) {
        this.selectedListForSI = [];
        this.selectedParamsForSI = [];
        this.primaryRelatedViewList = [];
        this.showSubjectIdentification = false;
        this.workFlowData = new WorkFlow();
        if (data.integrationWorkflow) {
          const entityDataMapping = data.integrationWorkflow.integrationWorkflowComponents.find(
            (i) => i.WorkflowComponentTypeCode == this._DATA_MAPPING_COMPONENT_CODE
            // &&
            // i.IntegrationWorkflowComponentStatus == this._ACTIVE_STATUS
          );
          if (entityDataMapping && entityDataMapping.WorkflowComponent) {
            const wFComponentJSON = JSON.parse(entityDataMapping.WorkflowComponent);
            this.workFlowData.workFlowId = entityDataMapping.IntegrationWorkflowID;
            // entityDataMapping.IntegrationWorkflowComponentVersion.forEach(mapObj => {
            // if (entityDataMapping.WorkflowComponentTypes.WorkflowComponentStatus == this._ACTIVE_STATUS) {
            this.workFlowData.workFlowId = entityDataMapping.IntegrationWorkflowID;
            if (!_.isEmpty(wFComponentJSON)) {
              if (wFComponentJSON.entityGroup.entityDataMappings.length > 0) {
                wFComponentJSON.entityGroup.entityDataMappings.forEach((mapObj) => {
                  // if (entityDataMapping.IntegrationWorkflowComponentStatus == this._ACTIVE_STATUS) {
                  if (mapObj) {
                    if (mapObj.targetEntity.entityId == true) {
                      const classType = _.includes(
                        this.entityClassTypeList,
                        mapObj.targetEntity.entityClassType
                      )
                        ? this.getClassType(mapObj.targetEntity.entityClassType)
                        : '';
                      this.selectedListForSI.push({
                        entityName: mapObj.targetEntity.entityName,
                        entityType: mapObj.targetEntity.entityType,
                        entityClass: mapObj.targetEntity.entityClassType,
                        value:
                          mapObj.targetEntity.entityName +
                          ' : ' +
                          mapObj.targetEntity.entityType +
                          '[' +
                          // mapObj.targetEntity.entityClassType.split("_")[0].substring(1, 0)+
                          // mapObj.targetEntity.entityClassType.split("_")[1].substring(1, 0)+
                          mapObj.targetEntity.entityClassType +
                          ']',
                      });
                    }

                    if (
                      wFComponentJSON.entityGroup.entityDataMappings.length == 1 &&
                      _.isEmpty(
                        wFComponentJSON.entityGroup.entityDataMappings[0]
                          .targetEntity.entityPhysicalName
                      )
                    ) {
                      this.workFlowData.rawEntityDataMapping = [];
                    }
                    else { this.workFlowData.rawEntityDataMapping.push(mapObj); }

                    const mappingObj: EntityDataMapping = new EntityDataMapping();
                    const dataMappingDetails = [];
                    mappingObj.targetEntity = {
                      displayMappingName: mapObj.targetEntity.entityPhysicalName,
                      targetEntityName: mapObj.targetEntity.entityName,
                      targetEntityType: {
                        id: mapObj.targetEntity.entityType,
                        value: mapObj.targetEntity.entityType,
                      },
                      targetEntityClass: {
                        id: mapObj.targetEntity.entityClassType,
                        value: mapObj.targetEntity.entityClassType,
                      },
                      displayParameters: false,
                      isSelectedForSI: mapObj.targetEntity.entityId,
                    };
                    mapObj.dataMappings.forEach((d) => {
                      dataMappingDetails.push({
                        sourceDataElement: d.sourceDataElementNames,
                        transformation: {
                          dataMappingType: d.transformation.dataMappingType,
                          dataTransformationType:
                            d.transformation.dataTransformationType,
                          transformationRuleName:
                            d.transformation.transformationRuleName,
                          customCalculationRule:
                            d.transformation.customCalculationRule,
                        },
                        targetDataElement: d.targetDataElementPhysicalName,
                      });
                    });

                    mappingObj.targetEntity.displayParameters = mappingObj.targetEntity.targetEntityClass.value.includes(
                      'SUBJECT'
                    )
                      ? true
                      : false;
                    mappingObj.dataMapping = dataMappingDetails;
                    this.workFlowData.entityDataMapping.push(mappingObj);
                    this.selectedListForSI = _.uniq(this.selectedListForSI);
                  //  this._broadcaster.storeDataIntegrationConfig(wFComponentJSON);
                    if (wFComponentJSON.associationDataMappings.length > 0) {
                      // this.associateDataMappingFlag = true;
                      this.dataModel;
                    }
                    // this._snackbar.open('Update Mapping Data','Success!', {
                    //   duration:3000,
                    //   panelClass: ['success-snackbar'],
                    //   horizontalPosition: this.horizontalPosition,
                    //   verticalPosition: this.verticalPosition
                    // });
                    if (!(this._cd as ViewRef).destroyed) {
                      this._cd.detectChanges();
                      // do other tasks
                    }
                  }
                  // }
                });
              } else {
                // this.nullifyAssociateMappings();
              }
            }
            // }
          } else {
           // this.nullifyAssociateMappings();
          }
        //   this.getIdentifyAttributes(
        //     data.integrationWorkflow.integrationWorkflowComponents
        //   );
        }
      }


      editEntityMappingData(data, singleMappingObjIndex, parentId) {
        this.primaryRelatedViewList = [];
        this.entityDataMappingForm.get('isSelectedForSI').enable();
        this.getTargetEntityTypeList(
          parentId.targetDataTypeCode,
          data.targetEntity.targetEntityType.id
        );

        // this.getSourceEntityTypeList(
        //   parentId.sourceDataType,
        //   data.targetEntity.targetEntityType.id
        // );

        this.dataModel.oneToOneRules = [];
        this.popuptitle = 'Edit';
        this.popupbutton = 'Update';
        this.workFlowId = singleMappingObjIndex;
        this.entityDataMappingFOrmReset();
        this.mappingEdit = true;
        this.addChildButtonsEnabling = true;
        this.targetEntityTypeDropdownOptions = [];

        data.dataMapping.forEach((item, parentIndex) => {
          this.mapValues[parentIndex] = [];
          item.transformation.dataTransformationType = item.transformation.dataTransformationType.toUpperCase();
          item.sourceDataElement.forEach((element) => {
            this.mapValues[parentIndex].push({
              id: element,
              value: element,
            });
          });
        });

        // this.dataModel.entityType.map(e => {
        //   if (e.sourceDataTypeCode === parentId.SourceDataTypeCode) {
        //     this.targetEntityTypeDropdownOptions.push({ id: e.id, value: e.value });
        //   }
        // });

        this.targetEntitySelectedData(data.targetEntity.targetEntityType);
        this.entityClassValueChange(data.targetEntity.targetEntityClass);

        this.entityDataMappingForm.setValue({
          targetEntityType: data.targetEntity.targetEntityType,
          targetEntityName: data.targetEntity.targetEntityName,
          // dataMappingName: data.targetEntity.displayMappingName,
          targetEntityClass: data.targetEntity.targetEntityClass,
          isSelectedForSI: data.targetEntity.isSelectedForSI
            ? data.targetEntity.isSelectedForSI
            : false,
          details: this.editMappingDetails(
            data.dataMapping,
            this.includesSubject(data.targetEntity.targetEntityClass.value)
          ),
        });
      }

      getTargetEntityTypeList(targetDataTypeCode, selectedEntityTypeInEdit?) {
        const entityDropdownData = this.getEntityMappingTargetDropDownData(
          targetDataTypeCode
        );

        this.targetEntityTypeDropdownOptions = [];
        this.targetEntityName = [];
        if (entityDropdownData) {
          entityDropdownData.forEach((i) => {
            this.targetEntityName.push({ id: i.EntityName, value: i.EntityName });
            if (
              selectedEntityTypeInEdit &&
              i.EntityName == selectedEntityTypeInEdit &&
              i.EntityAttributes
            ) {
              i.EntityAttributes.forEach((ea) => {
                this.setTargetDataElementsList.push({
                  id: ea.EntityAttributeName,
                  value: ea.EntityAttributeName,
                  custom: ea.EntityAttributeName + ' : ' + ea.Attribute[0].DataType,
                  type: ea.Attribute[0].DataType,
                });
              });
            }
          });
        }

        this.targetEntityTypeDropdownOptions = this.targetEntityName;
      }

      getEntityMappingTargetDropDownData(targetDataTypeCode) {
        let entityMappingTargetDropDownData;
        entityMappingTargetDropDownData = this.internalData.find(
      (i) => i.DatabaseName == targetDataTypeCode
    ).EntityGroup.Entity;

        return entityMappingTargetDropDownData;
      }

      entityDataMappingFOrmReset() {
        const detailsRows = this.entityDataMappingForm.get('details') as FormArray;
        detailsRows.controls = [];
        this.entityDataMappingForm.reset({
          isSelectedForSI: false,
        });
      }


       // Add Entity Data Mapping
  targetEntitySelectedData(event) {
    if (event.value) {
      this.setSelectedTypeId = event.value.id ? event.value.id : event.value;
      this.targetEntityClassMapping.EntityGroup.Entity.forEach((item) => {
        if (item.EntityName == this.setSelectedTypeId) {
          const entityClassData = {
            value: {
              id: item.EntityClassType,
              value: item.EntityClassType,
            },
          };
          this.entityClassValueChange(entityClassData);
        }
      });
    }
  }

  entityClassValueChange(changedValue) {
    this.dataModel.displayParametersColumn = false;
    if (
      (changedValue &&
        changedValue.value.value &&
        changedValue.value.value == 'PRIMARY_SUBJECT') ||
      changedValue.value.value == 'RELATED_SUBJECT'
    ) {
      this.dataModel.displayParametersColumn = true;
      this.entityDataMappingForm.get('isSelectedForSI').enable();
    } else if (
      changedValue.value == 'PRIMARY_SUBJECT' ||
      changedValue.value == 'RELATED_SUBJECT'
    ) {
      this.entityDataMappingForm.get('isSelectedForSI').enable();
    } else if (
      (changedValue &&
        changedValue.value.value &&
        changedValue.value.value != 'PRIMARY_SUBJECT') ||
      changedValue.value.value != 'RELATED_SUBJECT'
    ) {
      this.entityDataMappingForm.get('isSelectedForSI').disable();
    }
    this.setSelectedClassId = changedValue.value
      ? changedValue.value.id
        ? changedValue.value.id
        : changedValue.id
      : '';
    // this.addSourceandTargetElements();
    this.entityDataMappingForm.patchValue({
      targetEntityClass: this.setSelectedClassId,
    });
  }

  getTargetEntityMapping(wFlowData) {
    this._workFlowService.getTargetDatabaseMappings().subscribe((mapData: any) => {
      mapData.data.internalDatabasesAll.forEach((item: any) => {
        if (item.DatabaseName == wFlowData.targetDataTypeCode) {
          this.targetEntityClassMapping = item;
        }
      });
    });
  }


  editMappingDetails(queryMappingData, displayColumnParams) {
    const queryRows = [];
    if (displayColumnParams) {
      this.dataModel.displayParametersColumn = true;
    }
    if (queryMappingData) {
      queryMappingData.forEach((rowData) => {
        const isOneToOneMapping = this.isOneToOneMappingType(
          rowData.transformation.dataMappingType
        );
        if (isOneToOneMapping) {
          this.addOneToOneMapping(1);
        } else if (!isOneToOneMapping) {
          this.addManyToOneMapping('n');
          // rowData.sourceDataElement.spli
        }

        queryRows.push({
          sourceDataElement: this.mapSourceDataElements(
            rowData.sourceDataElement,
            isOneToOneMapping
          ),
          transformation:
            rowData.transformation.dataMappingType == 'ONE_TO_ONE'
              ? '1 > 1'
              : rowData.transformation.dataMappingType == 'MANY_TO_ONE'
                ? 'n > 1'
                : '',
          transformationType: rowData.transformation.dataTransformationType,
          displayOne2oneTransformationRule: isOneToOneMapping,
          ntoOneRuleName: rowData.ntoOneRuleName ? rowData.ntoOneRuleName : '',
          ntoOneRuleDefination: rowData.ntoOneRuleDefination
            ? rowData.ntoOneRuleDefination
            : '',
          transformationRuleName: rowData.transformation.transformationRuleName,
          customRuleJavascript: rowData.dataTransformationType
            ? rowData.dataTransformationType
            : '',
          displayN2oneTransformationRule: !isOneToOneMapping,
          targetDataElement: this.mapTargetElements(rowData.targetDataElement),
        });
      });
    }

    return queryRows;
  }

  isOneToOneMappingType(mappingType) {
    if (mappingType == 'ONE_TO_ONE') {
      return true;
    } else {
      return false;
    }
  }

  mapTargetElements(targetDataElement) {
    let value: any;
    value = {
      id: targetDataElement,
      value: targetDataElement,
      custom: _.filter(this.setTargetDataElementsList, [
        'id',
        targetDataElement,
      ])[0].custom,
    };

    return value;
  }

  includesSubject(val) {
    if (!val) {
      return false;
    } else {
      return val.toLowerCase().includes('subject');
    }
  }

  addOneToOneMapping(mappingType) {
    this.isSourceMappingDataElementDropdownMultiSelect = false;
    (this.entityDataMappingForm.get('details') as FormArray).push(
        this.getMappingDetails(mappingType)
      );
    }

    addManyToOneMapping(mappingType) {
        this.isSourceMappingDataElementDropdownMultiSelect = true;
        (this.entityDataMappingForm.get('details') as FormArray).push(
          this.getMappingDetails(mappingType)
        );
      }

    getMappingDetails(mappingType): FormGroup {
        return this._fb.group({
          sourceDataElement: ['', Validators.required],
          transformation: [mappingType + ' -> 1'],
          transformationType: [''],
          transformationRuleName: [''],
          displayOne2oneTransformationRule: mappingType == 1 ? true : false,
          displayN2oneTransformationRule: mappingType == 'n' ? true : false,
          ntoOneRuleName: '',
          ntoOneRuleDefination: '',
          customRuleJavascript: '',
          targetDataElement: ['', Validators.required],
        });
      }

      mapSourceDataElements(sourceData, isOneToOneMapping) {
        let value: any;
        if (isOneToOneMapping) {
          value = {};
          value = {
            id: sourceData[0],
            value: sourceData[0],
            custom: _.filter(this.setSourceDataElementsList, [
              'id',
              sourceData[0],
            ])[0].custom,
          };
        } else if (!isOneToOneMapping) {
          value = [];
          sourceData.forEach((item) => {
            value.push({
              id: item,
              value: item,
              custom: _.filter(this.setSourceDataElementsList, ['id', item])[0]
                .custom,
            });
          });
        }
        return value;
      }


      targetEntityNameChecks(data) {
        let exists: Boolean;
        const entityName = data.target.value.toLocaleLowerCase();
        if (
          this.workFlowData.rawEntityDataMapping.length == 1 &&
          _.isEmpty(
            this.workFlowData.rawEntityDataMapping[0].targetEntity
              .entityPhysicalName
          )
        ) {
          exists = false;
        } else {
          this.workFlowData.rawEntityDataMapping.forEach((item) => {
            if (
              item.targetEntity.entityPhysicalName.toLocaleLowerCase() ===
              entityName
            ) {
              exists = true;
            }
          });
        }

        if (exists) {
          this.availability = true;
          this.targetEntityNameErrorMessage =
            'Name already exists, Please enter other name';
        } else {
          this.availability = false;
          this.targetEntityNameErrorMessage = '';
        }
      }

      async getDataElements(sourceDataTypeCode) {
        let tempData: any;
        const myMap = new Map();
        const completeService = await this._externalDataService.fetchSourceDataElements(sourceDataTypeCode).subscribe(({ data, loading }) => {

          tempData = data;
          Array.from(tempData.externalDataElements).forEach((e: any) => {

            this.setSourceDataElementsList.push({
              id: e.DataElementName,
              value: e.DataElementName,
              custom: e.DataElementName + ' : ' + e.DataType,
              type: e.DataType,
            });
          });

        });
      }

}
