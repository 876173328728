/**
 * @author: Naga
 * @constant
 */
enum NodeType {
  Blend = 'UDP Data Blend Operator',
  Dataset = 'UDP Dataset Operator',
  MIL = 'UDP MIL Operator',
  DT = 'UDP Transformation Operator'
}

/**
 * @author: Naga
 *
 * @export
 * @param: {node<any>}
 * @returns: string
 * @description: a helper method that
 * returns the node type based on the
 * node string.
 */
export const getNodeType: Function = (node: any): string => {
  let mode: string = 'OTHER';

  if (!node || (!!node && !node.type)) {
    return mode;
  }

  if (node.type === NodeType.Blend) {
    mode = 'BLEND';
  }

  if (node.type === NodeType.Dataset) {
    mode = 'DATASET';
  }

  if (node.type === NodeType.MIL) {
    mode = 'MIL';
  }

  if (node.type === NodeType.DT) {
    mode = 'DT';
  }
  return mode;
};
