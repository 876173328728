import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionInfoComponent } from './connection-info.component';

@NgModule({
    declarations: [ConnectionInfoComponent],
    imports: [CommonModule],
    exports: [ConnectionInfoComponent],
})
export class ConnectionInfoModule {}
