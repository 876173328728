import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule,
  MatIconModule, MatInputModule, MatMenuModule, MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResizableModule } from 'angular-resizable-element';
import { MonacoEditorModule } from 'ngx-monaco-editor';

import { ButtonModule, ExpansionPanelModule, FormFieldModule, IconButtonModule, IconModule,
  SlideToggleModule, SnackbarModule, StringArrayToSelectOptionsModule } from '../../../shared';
import { DataImportService } from 'libs/data-import';

import { SchemaExplorerComponent } from './schema-explorer/schema-explorer.component';
import { EntitySearchComponent } from './schema-explorer/entity-search/entity-search.component';
import { EntitiesComponent } from './schema-explorer/entity-search/entities/entities.component';
import { EntityContextMenuComponent } from './schema-explorer/entity-search/entities/entity-context-menu/entity-context-menu.component';
import { EntityAttributesComponent } from './entity-attributes/entity-attributes.component';

import { SourceAttributesComponent } from './entity-attributes/source-attributes/source-attributes.component';
import { ConfigureMappingsComponent } from './entity-attributes/configure-mappings/configure-mappings.component';

import { MonacoEditorComponent } from './shared/monaco-editor/monaco-editor.component';

import { SchemaExplorerService } from './schema-explorer/schema-explorer.service';
import { ViewReferenceDatasetModule } from 'libs/data-catalog/src/lib/view-reference-dataset/view-reference-dataset.module';
import { ViewDataCatalogComponent } from './view-data-catalog/view-data-catalog.component';
import { SidebarWithFilterModule } from 'libs/sidebar/src/lib/sidebar-with-filter/sidebar-with-filter.module';
import { LayoutModule } from 'libs/interactive-graph-view/src/components/layout/layout.module';
import { UtilService } from 'src/app/service/util-service';
import { SharedModule } from 'libs/data-catalog/src/lib/shared/shared.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

const components = [
  SchemaExplorerComponent,
  EntitySearchComponent,
  EntitiesComponent,
  EntityContextMenuComponent,
  EntityAttributesComponent,
  SourceAttributesComponent,
  ConfigureMappingsComponent,
  MonacoEditorComponent,
  ViewDataCatalogComponent,
  ConfirmationDialogComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    ResizableModule, // Only use in the modules wherever necessary
    MonacoEditorModule.forRoot(), // Only use in the modules wherever necessary,
    IconButtonModule,
    FormFieldModule,
    IconModule,
    ExpansionPanelModule,
    SlideToggleModule,
    ButtonModule,
    StringArrayToSelectOptionsModule,
    SnackbarModule,
    ViewReferenceDatasetModule,
    SidebarWithFilterModule,
    LayoutModule,

    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule
  ],
  exports: components,
  entryComponents: [
    SourceAttributesComponent,
    ConfigureMappingsComponent,
    ConfirmationDialogComponent
  ],
  providers: [
    SchemaExplorerService,
    DataImportService,
    UtilService,
  ]
})
export class SchemaExplorerModule { }
