import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';

@Component({
  selector: 'lib-data-asset-item-menu',
  templateUrl: './data-asset-item-menu.component.html',
  styleUrls: ['./data-asset-item-menu.component.scss']
})
export class DataAssetItemMenuComponent implements OnInit {

  @Input()
  asset: any;

  @Input()
  item: any;

  @Output()
  public onAssetClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public newQueryAsset: EventEmitter<any> = new EventEmitter<any>();

  contextMenuType: any;
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
  typeMap: any = {DF: 'File', DS: 'Dataset', QRY: 'Query'};

  menuData: { category: any; subCategory: any; };

  constructor() { }

  ngOnInit() {
  }

  onRightClick(event: MouseEvent, cat: any, subCat: any) {
    event.preventDefault();
    if(cat.dataAssetType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE || cat.dataAssetType === DATASOURCE.RDS_CODE) return;
    this.contextMenuType = this.typeMap[cat.dataAssetType];
    this.menuData = {category: cat, subCategory: subCat};
    this.matMenuTrigger.openMenu();
  }

  newQueryAssetClick({category, subCategory}) {
    if(this.newQueryAsset)
    this.newQueryAsset.emit({ category, subCategory});
  }

}
