import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';
import { SpinnerConfig } from './spinner.interface';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

/**
 * @constant
 * @default
 */
const spinnerDefaults: SpinnerConfig = {
  backdrop: 'black',
  spinnerText: 'Loading...',
  imageSource: '/assets/sm-bars.gif'
};

/**
 * @author: Naga
 */
@Component({
  selector: 'app-loading-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  /**
   * @public
   * @input
   * @type: SpinnerConfig
   */
  @Input()
  public config: SpinnerConfig;

  /**
   * @public
   * @type: boolean
   */
  public display: boolean = false;

  /**
   * @private
   * @type: ReplaySubject<boolean>
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {spinner<SpinnerService>}
   */
  constructor(private spinner: SpinnerService) {
    this.initSubscription();
  }

  /**
   * @public
   * @type: method<life cycle hook>
   * @returns: void
   * @description: N/A
   */
  public ngOnInit() {
    setTimeout(() => {
      this.config = Object.assign({},
        spinnerDefaults, this.config || {}
      );
    }, 50);
  }

  /**
   * @private
   * @returns: void
   * @description: subscribes to the spinner
   * display event.
   */
  private initSubscription(): void {
    this.spinner.load()
    .pipe(takeUntil(this.destroy$))
    .subscribe(value => {
      this.display = value;
    });
  }

  /**
   * @public
   * @type: method<life cycle hook>
   * @returns: void
   * @description: N/A
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
