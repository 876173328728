import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef
} from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { SearchConfiguration } from "./search-configuration-model";
import { Configurations } from "./../configuration";
import { IntegrationWorkflowService } from 'src/app/workflow-editor/integration-workflow.service';
import { SubjectIdentificationConfigService } from './../subject-identification-config.service';
import * as _ from 'lodash';
import { BroadcastService} from './../broadcast.service'
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ItemTemplateDirective } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: "app-search-configuration",
  templateUrl: "./search-configuration.component.html",
  styleUrls: ["./search-configuration.component.css"]
})
export class SearchConfigurationComponent implements OnInit {
  @Input() parentData: any;
  @Input() searchConfigurationData: any;
  @Input() selectedEntity: any;

  searchConfigurationForm: FormGroup;
  isEdit = false;
  searchConfiguration: SearchConfiguration;
  dropDownConfig = Configurations.ngxSelectdropDownConfig;
  popuptitle = "";
  popupbutton = "";

  searchParticipationList: Array<any> = [];
  targetEntityNameOptions: Array<any> = [];
  entityAttributeDropdownOptions: Array<any> = [];
  identificationConfig: any;
  subjectQueryList: Array<any> = [];
  queryParamInfo: String;

  config = {
    displayKey: "value", // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: "auto", // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 100, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
  };

  queryConfig = {
    displayKey: "queryName", // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: "auto", // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 100, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
  };


  constructor(
    private _fb: FormBuilder,
    private _cd: ChangeDetectorRef,
    private _workFlowService: IntegrationWorkflowService,
    private _identificationConfigService: SubjectIdentificationConfigService,
    private _bs: BroadcastService
  ) { }

  setchild() {
    const control = <FormArray>(
      this.searchConfigurationForm.controls["queryDetails"]
    );
    //control.removeAt(i);
    while (control.length > 1) {
      control.removeAt(0);
    }
  }

  ngOnInit() {
    this.searchConfiguration = new SearchConfiguration();
    this.searchConfigurationForm = this._fb.group({
      queryDetails: this._fb.array([this.addQueryParametrs()])
    });

    this.clearFormArray(
      <FormArray>this.searchConfigurationForm.get("queryDetails")
    );
    this.searchConfigurationForm.reset();

  }

  getIdentifiableData() {
    this.clearFormArray(
      <FormArray>this.searchConfigurationForm.get("queryDetails")
    );
    this.searchConfigurationForm.reset();

    let obj = JSON.parse(this.searchConfigurationData[0].IntegrationWorkflowComponentVersion);
    if (!_.isEmpty(obj)) {
      obj['identifiableEntities'].forEach(entity => {
        if (entity.subjectSearchConfiguration.searchQueries[0].queryName != "") {
          entity.subjectSearchConfiguration.searchQueries.forEach(attr => {
            this.setValueForForm(entity, attr);
          })
        }
      })
    }
  }

  setValueForForm(entity, attr) {
    let queryNameObj = {
      entityName: entity.entityName,
      queryName: attr.queryName,
      queryParameters: [attr.queryName]
    };

    attr.searchQueryParameters = _.uniqBy(attr.searchQueryParameters, 'targetAttributeName');

    attr.searchQueryParameters.forEach(item => {
      let paramterEntityArr = [
        {
          targetEntityAttribute: {
            type: item.targetAttributeDataType,
            value: item.targetAttributeName
          },
          targetEntityName: item.targetEntityName
        }
      ];

      (<FormArray>this.searchConfigurationForm.get("queryDetails")).push(
        this.initQueryParametrs(queryNameObj, paramterEntityArr)
      );
    })
  }

  saveSearchConfig(workFlowData) {
    if (this.searchConfigurationForm.valid) {
      const formValues = this.searchConfigurationForm.value;
      this._identificationConfigService.mapSearchParamToJSON(formValues, this.selectedEntity);
      this.identificationConfig = this._identificationConfigService.getIdentificationConfig();
      // this.searchConfigurationData[0]['WorkflowComponent'] = JSON.stringify(this.identificationConfig);
      this._bs.storeIdentificationCofigDict(this.identificationConfig);
    }
  }

  initializeSearchConfig() {
    //this.popuptitle = "Add";
    //this.popupbutton = "Save";
    // this.addNewQueryRow();
    this.searchParticipationList = this._identificationConfigService.getIdentificationAttributes();
    this._workFlowService.getSubjectQuery(this.selectedEntity.entityType).subscribe(queryData => {
      this.subjectQueryList = queryData['data']['subjectQuery'];
    })
    this.getTargetEntityNameOptions();
    this.getIdentifiableData();
  }

  changeQueryParamInfo(details) {
    this.queryParamInfo = "";
    if (details.value.queryName) {
      details.value.queryName.queryParameters.forEach((item, index) => {
        if (index != details.value.queryName.queryParameters.length - 1) {
          this.queryParamInfo = this.queryParamInfo + item + ', ';
        } else {
          this.queryParamInfo = this.queryParamInfo + item;
        }
      })
    }
  }

  getTargetEntityNameOptions() {
    if (this.searchParticipationList.hasOwnProperty('attributeDetails')) {
      this.searchParticipationList['attributeDetails'].forEach(item => {
        this.targetEntityNameOptions.push(item.entityName.item.entityType)
      })

      this.targetEntityNameOptions = _.uniq(this.targetEntityNameOptions);
    }
  }

  tagetEntityNameChange(event) {
    this.entityAttributeDropdownOptions = [];
    this.searchParticipationList['attributeDetails'].forEach(item => {
      if (item.entityName.item.entityType == event.value) {
        item.parameterEntity.forEach(param => {
          this.entityAttributeDropdownOptions.push({
            type: param.targetAttributeName.type,
            value: param.targetAttributeName.value
          });
        });
      }
    });

    this.entityAttributeDropdownOptions = _.uniqWith(this.entityAttributeDropdownOptions, _.isEqual);
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  addQueryParametrs() {
    return this._fb.group({
      queryName: [""], 
      parameterEntity: this._fb.array([this.addParameterEntity()])
    });
  }

  addParameterEntity() {
    return this._fb.group({
      targetEntityName: [""],
      targetEntityAttribute: [""]
    });
  }

  initQueryParametrs(queryName, parameter) {
    return this._fb.group({
      queryName: [queryName],
      parameterEntity: this._fb.array([this.initParameterEntity(parameter)])
    });
  }

  initParameterEntity(parameter) {
    return this._fb.group({
      targetEntityName: [parameter[0].targetEntityName],
      targetEntityAttribute: [parameter[0].targetEntityAttribute]
    });
  }

  queryNameFilterData(event) { }

  addNewQueryRow() {
    (<FormArray>this.searchConfigurationForm.get("queryDetails")).push(
      this.addQueryParametrs()
    );
    this.initializeSearchConfig();
  }

  addParameterEntityOnClick(i) {
    (<FormArray>(
      this.searchConfigurationForm
        .get("queryDetails")
      ["controls"][i].get("parameterEntity")
    )).push(this.addParameterEntity());
  }

  deleteQueryRow(i) {
    if (i) {
      const control = <FormArray>(
        this.searchConfigurationForm.controls["queryDetails"]
      );
      control.removeAt(i);
    }
  }

  deleteParameterEntityRow(control, index) {
    control.removeAt(index);
  }

}
