import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@xfusiontech/shared';
import { MESSAGES } from './upload-operators.messages';
import { UploadConfig } from './upload-operators.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { UploadOperatorsService } from './upload-operators.service';
import { getUploadConfig, OS_FILES } from './upload-operators.constants';

/**
 * @author: Naga
 */
@Component({
  selector: 'xft-upload-operators',
  templateUrl: './upload-operators.component.html',
  styleUrls: ['./upload-operators.component.scss']
})
export class UploadOperatorsComponent implements OnDestroy {

  /**
   * @public
   * @type: {any}
   * @input
   */
  @Input()
  public env: any;

  /**
   * @public
   * @type: {any}
   */
  public errorInfo: any;

  /**
   * @public
   * @type: {File[]}
   */
  public files: File[] = [];

  /**
   * @public
   * @type: {any}
   */
  public uploadType: any = '';

  /**
   * @public
   * @type: {string}
   * @input
   */
  @Input()
  public endpoint: string = '';

  /**
   * @public
   * @type: {UploadConfig}
   */
  public uploadConf: UploadConfig;

  /**
   * @public
   * @type: {File[]}
   */
  public removedFiles: File[] = [];

  /**
   * @public
   * @type: {boolean}
   */
  public hasErrors: boolean = false;

   /**
    * @public
    * @type: {boolean}
    */
  public shouldDisable: boolean = false;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {snackbarService<SnackbarService>}
   * @param: {data<any>}
   * @param: {uploadService<UploadOperatorsService>}
   * @param: {dialogRef<MatDialogRef<UploadOperatorsComponent>>}
   */
  constructor(
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: UploadOperatorsService,
    private dialogRef: MatDialogRef<UploadOperatorsComponent>) {
    this.uploadConf = getUploadConfig(data.isDag);
    this.setData();
  }

  /**
   * @public
   * @param: {download<boolean>}
   * @return: void
   * @description: a helper method that
   * submits the upload form.
   */
  public async onFormUpload(download?: boolean): Promise<any> {
    this.shouldDisable = true;

    const id: string = this.uploadType.id;
    const formData: FormData = new FormData();
    const ut: any = this.uploadConf.uploadType;

    // in case of zip file or folder upload
    // process it differently.
    if (id === ut.Folder || id === ut.Zip) {
      const isZip: boolean = id === ut.Zip;

      await this.processZip(formData, isZip, download);
    } else {
      this.processFiles(formData);
    }

    // upload the file
    if (!download) {
      this.uploadZip(formData);
    }
  }

  /**
   * @private
   * @param: {formData<FormData>}
   * @param: {isZipFile<boolean>}
   * @param: {download<boolean>}
   * @return: Promise<any>
   * @description: a helper method that
   * adds zip file to be uploaded to the
   * form data object. It's used for zip
   * file & folder upload.
   */
  private async processZip(formData: FormData,
    isZipFile: boolean, download?: boolean): Promise<any> {

    return new Promise(async (resolve) => {
      const prefix: string = this.data.isDag
      ? `${this.env.dagPathName}`
      : '';

      const blob: any = await this.createZip();
      const hash: number = new Date().getTime();

      const name: string = isZipFile
        ? this.files[0].name
        : `co_${hash}.zip`
      ;

      // in case if the download
      // is requested, just download
      // the zip file.
      if (download) {
        this.downloadZip(blob, name);

      // in case of a dag operator file
      // handle it separately
      } else if (this.data.isDag) {
        formData.append('filename', prefix + name);
        formData.append('filecontent', name);
      } else {
        formData.append('filename', blob, name);
      }

      resolve(null);
    });
  }

  /**
   * @private
   * @param: {blob<Blob>}
   * @param: {name<string>}
   * @return: void
   * @description: a helper method that
   * downloads the blob into a zip file.
   */
  private downloadZip(
    blob: Blob, name: string): void {

    try {
      saveAs(blob, name);
    } catch (e) {
      console.warn(`Couldn't download the blob ${name}`);
    }
  }

  /**
   * @private
   * @param: {formData<FormData>}
   * @return: void
   * @description: a helper method that
   * adds all files to be uploaded to the
   * form data object.
   */
  private processFiles(formData: FormData): void {
    const env: any = this.env;

    for (const file of this.files) {
      if (this.data.isDag) {
        formData.append(
          'filename', `${env.dagPathName}/${file.name}`
        );
        formData.append('filecontent', file);
      } else {
        formData.append(
          'files', file, file.name
        );
      }
    }
  }

  /**
   * @private
   * @param: {formData<FormData>}
   * @return: void
   * @description: a helper method that
   * uploads zip file to git repo.
   */
  private uploadZip(formData: FormData): void {
    const url: string = this.endpoint;

    // in case of a DAG operator
    // handle it separately
    if (this.data.isDag) {
      this.uploadDagOperators(
        url, formData
      );
    } else {
      this.uploadCustomOperators(
        url, formData
      );
    }
  }

  /**
   * @private
   * @param: {url<string>}
   * @param: {formData<FormData>}
   * @return: void
   * @description: a helper method that
   * uploads DAG operators.
   */
  private uploadDagOperators(
    url: string, formData: FormData): void {
    const env: any = this.env;
  
    if (!!formData) {
      formData.append('token', env.token);
      formData.append('repository', env.repository);
      formData.append('branch', env.branch);
      formData.append('current_version', env.current_version);
    }

    this.uploadService
    .uploadDagFiles(url, formData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (!!res && !!res.detail) {
        this.handleUploadSuccess(res, true);
      }
    }, this.handleUploadErr.bind(this));
  }

  /**
   * @private
   * @param: {url<string>}
   * @param: {formData<FormData>}
   * @return: void
   * @description: a helper method that
   * uploads custom operators.
   */
  private uploadCustomOperators(
    url: string, formData: FormData): void {
    const env: any = this.env;

    if (!!formData) {
      formData.append('token', env.token);
      formData.append('customer_id', env.customer_id);
    }

    this.uploadService
    .uploadOperators(url, formData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (!!res && !!res.detail) {
        this.handleUploadSuccess(res);
      }
    }, this.handleUploadErr.bind(this));

  }

  /**
   * @private
   * @param: {res<any>}
   * @param: {isDag<boolean>}
   * @return: void
   * @description: a helper method that
   * handles upload success response.
   */
   private handleUploadSuccess(res: any, isDag?: boolean): void {
    const re: any[] = res.detail.errors_found_at_repo
    || res.detail.errors_found;

    // this means there are errors found
    // at the repository level
    if (Array.isArray(re) && re.length > 0) {
      this.setErrState();
      res.detail.partialSuccess = true;
      this.errorInfo = res.detail;
    } else {
      this.notify(
        this.data.isDag ? res.detail : 'UPLOAD_SUCCESS',
        'success'
      );
      setTimeout(this.close.bind(this, true));
    }
  }

  /**
   * @private
   * @param: {err<any>}
   * @return: void
   * @description: a helper method that
   * handles upload errors.
   */
  private handleUploadErr(err: any): void {
    if (!!err && (err.detail || err.error.detail)) {
      this.setErrState();
      const detail: any = err.detail || err.error.detail;
      const list: any[] = detail.errors_found || detail.errors_found_at_repo;

      if (!list) {
        this.errorInfo = { errors_found: detail };
      } else {
        this.errorInfo = detail;
      }
    } else {
      this.shouldDisable = false;
      this.notify('UPLOAD_FAILED', 'error');
    }
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * sets the error state.
   */
  private setErrState(): void {
    this.hasErrors = true;
    this.dialogRef.updateSize('1000px');
  }

  /**
   * @private
   * @param: {msg<string>}
   * @param: {msgType<any>}
   * @return: void
   * @description: a helper method that
   * notifies with a message on the screen.
   */
  private notify(msg: string, msgType: any): void {
    this.snackbarService.notify(
      MESSAGES[msg] || msg, msgType
    );
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * sets the scope variables for file/
   * folder upload API calls.
   */
  private setData(): void {
    // if the env was passed through
    // input decorator, do not set it
    // from the data variable.
    if (!this.env) {
      this.env = this.data.env;
    }

    // if the endpoing was passed through
    // input decorator, do not set it
    // from the data variable.
    if (!this.endpoint) {
      this.endpoint = this.data.domain;
    }
  }

  /**
   * @public
   * @param: {force<boolean>}
   * @return: void
   * @description: a helper method that
   * resets the scope variables for file/
   * folder upload.
   */
  public onReset(force?: boolean): void {
    this.files = [];
    this.removedFiles = [];
    this.shouldDisable = false;

    if (!force) {
      this.uploadType = '';
    }
  }

  /**
   * @public
   * @param: {files<FileList>}
   * @return: void
   * @description: a helper method that
   * listens to file drop event & prepares
   * a list of files for file upload.
   */
  public onFileDropped(files: FileList): void {
    this.createFilesList(Array.from(files));
  }

  /**
   * @public
   * @param: {files<FileList>}
   * @param: {e<MouseEvent>}
   * @return: void
   * @description: a helper method that
   * gets triggered on file browse & prepares
   * a list of files for file upload.
   */
  public async onFileBrowse(
    files: FileList, e: MouseEvent): Promise<any> {

    const cfg: any = this.uploadConf;
    const uType: any = cfg.uploadType;
    let arr: any[] = Array.from(files);
    const id: string = this.uploadType.id;

    // reset the input field
    (e.target as any).value = '';

    // in case of folder selection
    // filter out the allowed file types
    if (id === uType.Folder || id === uType.File) {
      arr = this.getFilteredFiles(arr, id, cfg);
    }
    this.createFilesList(arr);
  }

  /**
   * @private
   * @param: {list<any[]>}
   * @param: {id<string>}
   * @param: {cfg<any>}
   * @param: {skip<boolean>}
   * @return: any[]
   * @description: a helper method that
   * filters out the list based on the
   * supported file extensions.
   */
  private getFilteredFiles(list: any[],
    id: string, cfg: any, skip?: boolean): any[] {

    return list.filter((file) => {
      // get allowed file extenions for folder type
      const _id: string = skip ? '2' : id;
      const extns: any[] = (cfg.allowedExtns[_id] || '').split(',');

      // get the file extension
      const extn: string =
        file.name && file.name.replace(
          /^.*?(\.[a-zA-Z0-9]+)$/, '$1'
        );

      // add file to the truncated list
      if (!extns.includes(extn)) {
        this.truncatedFiles([file]);
      }
      return extns.includes(extn);
    });
  }

  /**
   * @public
   * @param: {files<FileList | any[]>}
   * @return: void
   * @description: a helper method that
   * listens to file removed event & prepares
   * a list of files that were removed.
   */
   public truncatedFiles(files: FileList | any[]): void {
    const ut: any = this.uploadConf.uploadType;

    if (this.uploadType.id === ut.Folder) {
      this.removedFiles = [
        ...this.removedFiles,
        ...Array.from(files)
      ];
    }
  }

  /**
   * @private
   * @param: {zip<JSZip>}
   * @return: any
   * @description: a helper method that
   * removes system files from a zip file.
   */
  private truncateOsFiles(zip: JSZip): any {
    const jszip: any = JSZip;
    const keys: string[] = Object.keys(zip.files);

    // remove system files
    if (zip instanceof jszip) {
      const ignores: string[] = OS_FILES;

      for (const item of ignores) {
        for(const k of keys) {
          if (k.includes(item)) {
            delete zip.files[k];
          }
        }
      }
    }

    return this.filterZipFiles(zip);
  }

  /**
   * @private
   * @param: {zip<JSZip>}
   * @return: any
   * @description: a helper method that
   * removes unsupported files from a zip file.
   */
  private filterZipFiles(zip: JSZip): any {
    const cfg: any = this.uploadConf;
    const extns: any = cfg.allowedExtns['2'];
    const keys: string[] = Object.keys(zip.files);

    for (const k of keys) {
      const extn: string = k.split('.')[1] || '';

      if (!extns.includes(extn)) {
        delete zip.files[k];
      }
    }
    return zip.files;
  }

  /**
   * @public
   * @param: {idx<number>}
   * @return: void
   * @description: a helper method that
   * deletes a file from the file list.
   */
  public deleteFile(idx: number): void {
    this.files.splice(idx, 1);
  }

  /**
   * @private
   * @param: {files<any[]>}
   * @return: void
   * @description: a helper method that
   * converts files list to an array list.
   */
  private createFilesList(files: any[]): void {
    if (files.length > 0) {
      for (const file of files) {
        this.addFile(file);
      }
    }
  }

  /**
   * @private
   * @param: {file<File>}
   * @return: void
   * @description: a helper method that
   * a file to the list.
   */
  private addFile(file: File): void {
    const item: any = this.files.find(
      (o) => o.name === file.name
    );

    if (!item) {
      this.files.push(file);
    }
  }

  /**
   * @public
   * @param: {v<boolean>}
   * @return: void
   * @description: a helper method that
   * closes the modal.
   */
  public close(v: boolean): void {
    this.dialogRef.close(v);
  }

  /**
   * @private
   * @return: Promise<any>
   * @description: a helper method that
   * creates a zip blob.
   */
  private createZip(): Promise<any> {
    const zip: any = new (<any>JSZip)();
    const id: string = this.uploadType.id;
    const ut: any = this.uploadConf.uploadType;

    // loop through the filelist to get each
    // filename and pass each file to zip object
    if (id !== ut.Zip) {
      for (const file of this.files) {
        const f: any = file;
        const name: string = f.webkitRelativePath || f.name;
        zip.file(name, f);
      }
    }

    return new Promise((resolve) => {
      // in case of files other than zip
      // resolve them straight away
      if (id !== ut.Zip) {
        this.generate(zip, resolve);
      } else {

        // for zip files, do not zip it twice
        const file: File = this.files[0];
        const config: any = { createFolders: true };

        zip.loadAsync(file, config).then(z => {
          if (!!z) {
            const files: any = this.truncateOsFiles(z);
            z.files = files;

            this.generate(z, resolve);
          }
        });
      }
    });
  }

  /**
   * @private
   * @param: {zip<JSZip>}
   * @param: {resolve<Function>}
   * @return: Promise<any>
   * @description: a helper method that
   * creates a zip blob.
   */
  private generate(zip: JSZip, resolve: Function): void {
    // generate the complete zip file
    zip.generateAsync({ type: 'blob' }).then((cont) => {
      // create zip blob file
      const blob: any = new Blob([cont], {
        type: 'application/zip'
      });
      resolve(blob);
    });
  }

  /**
   * @public
   * @param: {size<number>}
   * @param: {decimals<number>}
   * @return: string
   * @description: a helper method that
   * formats the file size and returns
   * a lucid formatted file size.
   */
  public formatBytes(size: number, decimals?: number): any {
    if (size === 0) {
      return '0 Bytes';
    }

    const k: number = 1024;
    const sizes: string[] = [
      'Bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB'
    ];
    const dm: number = decimals <= 0 ? 0 : decimals || 2;
    const i: number = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
