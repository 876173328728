import { Component, Input } from '@angular/core';

@Component({
    selector: 'udp-tab',
    templateUrl: './tab.component.html',
})
export class TabComponent {
    @Input()
    label!: string;

    @Input()
    selected = false;
}
