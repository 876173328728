import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UndoStorage } from '../undoManager/UndoStorage';
import { UndoAction } from '../undoManager/UndoAction';
import { SelectionService } from './selection.service';
import { DiagramService } from './base.service';
import { Diagram } from '../Diagram';

type UndoRedoEvent = 'undo' | 'redo';

@Injectable()
export class UndoService extends DiagramService {

    protected undoStorage: UndoStorage;
    isUndoDisabled$ = new BehaviorSubject(true);
    isRedoDisabled$ = new BehaviorSubject(true);
    undoRedoEvent$ = new EventEmitter<UndoRedoEvent>();

    constructor(
        private selectionService: SelectionService
    ) {
        super();
    }

    handleModelChange = () => {
        this.isRedoDisabled$.next(
            !this.checkIsRedoPossible()
        );

        this.isUndoDisabled$.next(
            !this.checkIsUndoPossible()
        );
    }

    bindDiagram(diagram: Diagram) {
        super.bindDiagram(diagram);
        this.undoStorage = new UndoStorage();
        this.undoStorage.bindDiagram(this.diagram);

        this.diagram.addModelChangedListener(
            this.handleModelChange
        );
    }

    unbindDiagram() {
        this.undoStorage.unbindDiagram();
        this.undoStorage = null;

        this.diagram.removeModelChangedListener(
            this.handleModelChange
        );

        super.unbindDiagram();
    }

    changeProperty(obj: object, propertyName: string, value: any) {
        const oldValue = obj[propertyName];

        this.remember(
            () => {
                obj[propertyName] = value;
            },
            () => {
                if (oldValue === undefined) {
                    delete obj[propertyName];
                } else {
                    obj[propertyName] = oldValue;
                }
            },
            'ChangeProperty'
        );
    }

    changePropertyWithCommit(
        obj: object,
        propertyName: string,
        value: any,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.changeProperty(obj, propertyName, value);
        this.commitTransaction(transactionName);
    }

    removeProperty(obj: object, propertyName: string) {
        const oldValue = obj[propertyName];

        this.remember(
            () => { delete obj[propertyName]; },
            () => { obj[propertyName] = oldValue; },
            'DeleteProperty'
        );
    }

    removePropertyWithCommit(
        obj: object,
        propertyName: string,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.removeProperty(obj, propertyName);
        this.commitTransaction(transactionName);
    }

    addToArray(array: Array<any>, element: any, index?: number) {
        let previousElement: any;
        if (index !== undefined) {
            previousElement = array[index];
        }

        this.remember(
            () => {
                if (index === undefined) {
                    array.push(element);
                } else {
                    array[index] = element;
                }
            },
            () => {
                if (previousElement !== undefined) {
                    array[index] = previousElement;
                } else {
                    array.splice(array.length - 1, 1);
                }
            },
            'AddToArray'
        );
    }

    addToArrayWithCommit(
        array: Array<any>,
        element: any,
        index: number,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.addToArray(array, element, index);
        this.commitTransaction(transactionName);
    }

    removeFromArray(array: Array<any>, index: number) {
        const previousElement = array[index];

        this.remember(
            () => { array.splice(index, 1); },
            () => { array.splice(index, 0, previousElement); },
            'RemoveFromArray'
        );
    }

    removeFromArrayWithCommit(
        array: Array<any>,
        index: number,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.removeFromArray(array, index);
        this.commitTransaction(transactionName);
    }

    addToMap(map: Map<any, any>, key: any, value: any) {
        const previousElement = map.get(key);

        this.remember(
            () => { map.set(key, value); },
            () => {
                if (previousElement !== undefined) {
                    map.set(key, previousElement);
                } else {
                    map.delete(key);
                }
            },
            'AddToMap'
        );
    }

    addToMapWithCommit(
        map: Map<any, any>,
        key: any,
        value: any,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.addToMap(map, key, value);
        this.commitTransaction(transactionName);
    }

    removeFromMap(map: Map<any, any>, key: any) {
        const previousElement = map.get(key);

        this.remember(
            () => { map.delete(key); },
            () => { map.set(key, previousElement); },
            'RemoveFromMap'
        );
    }

    removeFromMapWithCommit(
        map: Map<any, any>,
        key: any,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.removeFromMap(map, key);
        this.commitTransaction(transactionName);
    }

    remember(
        change: () => void,
        undo: () => void,
        name?: string
    ) {
        change();
        this.undoStorage.registerAction(
            new UndoAction(name || 'Custom', undo, change)
        );
    }

    rememberWithCommit(
        change: () => void,
        undo: () => void,
        transactionName: string
    ) {
        this.startTransaction(transactionName);
        this.remember(change, undo, name);
        this.commitTransaction(transactionName);
    }

    rememberTransparent(change: () => void, undo: () => void) {
        this.rememberWithCommit(
            change,
            undo,
            this.undoStorage.transparentTransactionName
        );
    }

    async undo() {
        await this.undoStorage.undo();
        this.handleModelChange();
        this.undoRedoEvent$.next('undo');
        this.selectionService.refreshSelection();
    }

    async redo() {
        await this.undoStorage.redo();
        this.handleModelChange();
        this.undoRedoEvent$.next('redo');
        this.selectionService.refreshSelection();
    }

    checkIsUndoPossible(): boolean {
        return this.undoStorage.transactionToUndo !== null;
    }

    checkIsRedoPossible(): boolean {
        return this.undoStorage.transactionToRedo !== null;
    }

    startTransaction(name: string) {
        this.undoStorage.startTransaction(name);
    }

    commitTransaction(name: string) {
        this.undoStorage.commitTransaction(name);
    }

    reset() {
        this.undoStorage.reset();
    }

}
