import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';

@Component({
  selector: 'lib-data-asset-type-menu',
  templateUrl: './data-asset-type-menu.component.html',
  styleUrls: ['./data-asset-type-menu.component.scss']
})
export class DataAssetTypeMenuComponent implements OnInit {
  @Input() item: any;
  @Input() searchItem: string;
  @Input()
  public isDatasetOperator: boolean = false;

  menuTopLeftPosition = { x: '0', y: '0' };
  contextMenuType: any;
  menuData: any;
  @ViewChild(MatMenuTrigger, { static: true})
  matMenuTrigger: MatMenuTrigger;
  typeMap: any = {DF: 'File', DS: 'Dataset', QRY: 'Query', RDS: 'Reference Dataset'};

  @Output()
  public toggleExpansion: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onTypeClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public newAsset: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public newCategory: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onCatClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onAssetClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onToggleExpansion(asset: any) {
    this.toggleExpansion.emit({asset, item: this.item});
  }
  // public toggleSidebar(): void {
  //   this.sidebarExpanded = !this.sidebarExpanded;
  // }


  onRightClick(event: MouseEvent, dataAssetType: string) {
    event.preventDefault();
    if(dataAssetType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) return;
    this.contextMenuType = this.typeMap[dataAssetType];
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.menuData = {dataAssetType};
    this.matMenuTrigger.openMenu();
  }
  catClick(asset: any ) {
    this.onCatClick && this.onCatClick.emit({ category: this.item, subCategory: asset });
    if(this.item.dataAssetCode === DATASOURCE.INTERNAL_DATA_SOURCE_CODE && !asset.expanded) {
      asset.expanded = true;
    }
  }

  assetClick(o: any, asset: any) {
    if(this.onAssetClick)
    this.onAssetClick.emit({ category: {...this.item, name: asset.dataSetName}, subCategory: o });
  }

}
