import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {
    dataAssetType,
    Query,
    Visualization,
} from '@xfusiontech/data-visualizer';
import { Observable } from 'rxjs';
import { User } from './../../models/user.model';
import { ShareInDashboardDialogComponent } from './share-in-dashboard-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ShareInDashboardDialogService {
    constructor(private dialog: MatDialog) {}

    show({
        type,
        entity,
        userQuery,
    }: {
        type: dataAssetType;
        entity: Visualization | Query;
        userQuery: Observable<User[]>;
    }): MatDialogRef<ShareInDashboardDialogComponent, any> {
        return this.dialog.open(ShareInDashboardDialogComponent, {
            data: { type, entity, userQuery },
        });
    }
}
