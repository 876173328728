import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { IconButtonComponent } from './icon-button.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    declarations: [
        IconButtonComponent
    ],
    imports: [
        MatIconModule,
        MatButtonModule,
        IconModule,
        CommonModule
    ],
    exports: [
        IconButtonComponent
    ]
})
export class IconButtonModule { }
