import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import { IconButtonComponent } from './icon-button.component';
import { IconModule } from '../icon/icon.module';

const imports = [
    MatRippleModule,
    IconModule,
    CommonModule
];

const components = [
    IconButtonComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class IconButtonModule {}
