import { Injectable, Injector } from '@angular/core';

import { DiagramPaletteService } from './services/diagram-palette.service';
import { NodeSearchingService } from './services/node-searching.service';
import { ModelSaveService } from './services/model-save.service';
import { SelectionService } from './services/selection.service';
import { AlignmentService } from './services/alignment.service';
import { OverviewService } from './services/overview.service';
import { EventsService } from './services/events.service';
import { DiagramService } from './services/base.service';
import { ModelService } from './services/model.service';
import { ZoomService } from './services/zoom.service';
import { UndoService } from './services/undo.service';
import { DiagramTools } from './types';
import { CommandHandler } from './tools/CommandHandler';
import { Diagram } from './Diagram';
import { RedrawService } from './services/redraw.service';
import { FocusService } from './services/focus.service';
import { ModelChangesService } from './services/model-changes.service';
import { TaskGroupInsertService } from './services/task-group-insert.service';
import { LinkingTool } from './tools/LinkingTool';
import { TaskGroupLocationService } from './services/task-group-location.service';
import { ValidationService } from './services/validation/validation.service';
import go from 'gojs';

@Injectable()
export class DiagramFactoryService {

    constructor(
        private injector: Injector
    ) {
      go.Diagram.licenseKey = '2bf840e7b56658c511d35a25403e7efb0ea02d3bcf824ef7595316f6ed5f601123cce17b55d299d0d5f01ef41a7493d189956b2c93480c6be232dbdf47b680f0b03275b2165b17d9a15071909af82df3f52b24f3c7e727aada7b8df1eefb93954ef8f58118cc0ee979791421557fac4aa8fbc678f8';

    }

    createDiagram(div: HTMLDivElement): Diagram {

        const services = this.getDiagramServices();
        const tools = this.getDiagramTools();


        return new Diagram(div, services, tools);
    }

    private getDiagramServices(): DiagramService[] {
        return [
            this.injector.get(ModelService),
            this.injector.get(DiagramPaletteService),
            this.injector.get(SelectionService),
            this.injector.get(ModelSaveService),
            this.injector.get(EventsService),
            this.injector.get(AlignmentService),
            this.injector.get(ZoomService),
            this.injector.get(OverviewService),
            this.injector.get(NodeSearchingService),
            this.injector.get(UndoService),
            this.injector.get(RedrawService),
            this.injector.get(FocusService),
            this.injector.get(ModelChangesService),
            this.injector.get(TaskGroupInsertService),
            this.injector.get(TaskGroupLocationService),
            this.injector.get(ValidationService)
        ];
    }

    private getDiagramTools(): DiagramTools {
        return {
            commandHandler: this.injector.get(CommandHandler),
            linkingTool: this.injector.get(LinkingTool)
        };
    }

}
