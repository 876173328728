/**
 * @author: Naga
 * @constant
 */
export const UPLOAD_OPTS: any = [
  {
    id: '1',
    label: 'UDP Schema File',
    fileType: 'Schema',
    type: 'file',
    isActive: false
  },
  {
    id: '2',
    label: 'Delimited',
    fileType: 'Delimited',
    type: 'file',
    isActive: false
  },
  {
    id: '3',
    label: 'CSV',
    fileType: 'CSV',
    hint: '.csv',
    type: 'file',
    isActive: true
  },
  {
    id: '4',
    label: 'EXCEL(Spreadsheet)',
    fileType: 'EXCEL',
    hint: '.xls & .xlsx',
    type: 'file',
    isActive: true
  },
  {
    id: '5',
    label: 'XML',
    fileType: 'XML',
    hint: '.xml',
    type: 'file',
    isActive: false
  },
  {
    id: '6',
    label: 'JSON',
    fileType: 'JSON',
    hint: '.json',
    type: 'file',
    isActive: false
  }
];

/**
 * @enum
 * @exports
 */
export enum UploadType {
  SCHEMA = '1',

  DEL = '2',

  CSV = '3',

  EXCEL = '4',

  XML = '5',

  JSON = '6'
}

/**
 * @constant
 */
const ALLOWED_EXTNS: any = {
  3: '.csv',
  4: '.xls,.xlsx',
  5: '.xml',
  6: '.json'
};

/**
 * @constant
 * @exports
 */
export const UPLOAD_CONFIG: any = {
  options: UPLOAD_OPTS,
  uploadType: UploadType,
  allowedExtns: ALLOWED_EXTNS
};
