import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ValidationService } from '../gojs/services/validation/validation.service';
import { ValueObject } from '../types/shared';

@Injectable()
export class WorkflowValidationService {
    private saveButtonDisabled$ = new BehaviorSubject<boolean>(false);

    constructor(private validationService: ValidationService) { }

    get saveButtonDisabledObservable(): Observable<boolean> {
        return this.saveButtonDisabled$.asObservable();
    }

    checkWorkflowValidation(modelData: ValueObject) {
        const { dag } = modelData;
        const invalid = this.validationService
            .validateWorkflowProperties(dag.parameters);

        this.setSaveButtonDisabled(invalid);
    }

    setSaveButtonDisabled(visibility: boolean) {
        this.saveButtonDisabled$.next(visibility);
    }
}
