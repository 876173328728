import { RecommendationsPageComponent } from './recommendations-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [RecommendationsPageComponent],
    imports: [CommonModule],
    exports: [RecommendationsPageComponent],
})
export class RecommendationsPageModule {}
