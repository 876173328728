import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

import { AdvancedSearchNavigationComponent } from './advanced-search/nav/navigation.component';
import { AdvancedSearchFormComponent } from './advanced-search/form/form.component';
import { AdvancedSearchComponent } from './advanced-search/advanced.component';
import { AdvancedSearchService } from './advanced-search/advanced.service';
import { GlobalSearchComponent } from './search-box/search-box.component';
import { FormFieldModule } from '../form-field/form-field.module';
import { SearchService } from './global-search.service';
import { ButtonModule } from '../button/button.module';
import { IconButtonModule } from '../icon-button/icon-button.module';

const imports = [
    CommonModule,
    FormFieldModule,
    MatDialogModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonModule,
    IconButtonModule
];

const components = [
    GlobalSearchComponent
];

const entryComponents = [
    AdvancedSearchComponent
];

const innerComponents = [
    AdvancedSearchFormComponent,
    AdvancedSearchNavigationComponent
];

const providers = [
    SearchService,
    AdvancedSearchService
];

@NgModule({
    imports,
    declarations: [
        ...components,
        ...entryComponents,
        ...innerComponents
    ],
    entryComponents,
    providers,
    exports: components
})
export class GlobalSearchModule { }
