export type CodeEditorLanguage = 'json' | 'python' | 'javascript';

export type CodeEditorPanelMode = {
    data?: any;
    mode?: string;
    open: boolean;
    title?: string;
    value?: string;
    language?: CodeEditorLanguage;
};

export class NotValidOperator {
    operatorCode: string;
    operatorType: string;
    errorMessage: string;
}

export class NotValidTimeStampOperator {
    currentTaskId: string;
    previousTaskIds: any[];
    warningMessage: string;
}


