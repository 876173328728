import { Injectable } from '@angular/core';
import { DiagramService } from './base.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as go from 'gojs';
import { Diagram } from '../Diagram';
import { filterNodes } from '../../utils/itemsFiltering';

@Injectable()
export class NodeSearchingService extends DiagramService {
  nodes$ = new BehaviorSubject<go.ObjectData[]>([]);
  search$ = new BehaviorSubject('');

  filteredNodes$ = new BehaviorSubject<go.ObjectData[]>([]);

  updateNodes = () => {
    this.nodes$.next(
      this.diagram.model.nodeDataArray
    );
  }

  bindDiagram(diagram: Diagram) {
    super.bindDiagram(diagram);

    this.updateNodes();
    diagram.addModelChangedListener(this.updateNodes);

    combineLatest([this.nodes$, this.search$])
      .subscribe(([nodes, search]) => {
        this.filteredNodes$.next(
          filterNodes(search)(nodes)
        );
      });
  }

  unbindDiagram() {
    this.diagram.removeModelChangedListener(this.updateNodes);
    super.unbindDiagram();
  }

  public setSearch(search: string) {
    this.search$.next(search);
  }

  public focusNodeById(id: string) {
    const node = this.diagram.findNodeForKey(id);

    node.diagram.commandHandler.scrollToPart(node);
  }
}
