import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { DataAssetCategory, Visualization } from '@xfusiontech/data-visualizer';
import { PERMISSIONS } from '@xfusiontech/shared';

@Component({
    selector: 'igv-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
    PERMISSIONS = PERMISSIONS;
    @Input()
    dataAssetCategory: DataAssetCategory;

    @Output()
    visualizationClick = new EventEmitter<Visualization>();

    @Output()
    editCategory = new EventEmitter<DataAssetCategory | null>();

    @Output()
    deleteCategory = new EventEmitter<DataAssetCategory>();

    @Output()
    shareVisualizationInDashboard = new EventEmitter<Visualization>();

    @Output()
    shareVisualizationByEmail = new EventEmitter<Visualization>();

    @Output()
    editVisualization = new EventEmitter<Visualization>();

    @Output()
    deleteVisualization = new EventEmitter<Visualization>();

    @Output()
    newVisualization = new EventEmitter();

    visualizationTrackBy = (_, visualization: Visualization) =>
        visualization.visualizationID;
}
