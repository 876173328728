import { Component } from '@angular/core';
import { PaletteService } from '../palette.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'xft-palette-search-bar',
    templateUrl: './search-bar.component.html',
})
export class SearchBarComponent {
    control = new FormControl('');

    constructor(private paletteService: PaletteService) {
        this.control.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
            this.paletteService.setSearchValue(
                value
            );
        });
    }
}
