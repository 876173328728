export const miscellaneousConst = {
    operators: {
        DS: {
            errors: {
                noPreviousTasks: `A Dataset Operator cannot have any previous task operator(s).
                Please remove the previous task operator(s) connected to this Dataset Operator.`
            },
            FILENAME: 'config.ds.json'
        },
        DB: {
            errors: {
                must2PreviousTasks: `A Datablend Operator should always have 2 operators connected
                to it. It can be either Dataset or Datablend operators or combination of both.
                Please modify the source operator(s) accordingly.`,
                previousConfigError: `The previous tasks either Dataset operators or Datablend operators need to be
                configured before the current task Datablend operator can be configured.!`,
                previousTasksModifiedAfterCurrentTask: `The previous task(s) was modified before the current task.
                Please check the configuration of this DataBlend Operator for potential change impacts.`,
                // tslint:disable-next-line: max-line-length
                leftDatasetSchemaChanged: `Schema is changed for Left Dataset Operator from Previous task that is not matching with the current Blend operator left dataset!`,
                // tslint:disable-next-line: max-line-length
                rightDatasetSchemaChanged: `Schema is changed for Right Dataset Operator from Previous task that is not matching with the current Blend operator right dataset!`,
                errorsInPreviousTask: `There are errors in the previous task(s). Please resolve errors before configuring
                this Datablend Operator!`
            },
            FILENAME: 'config.db.json'
        },
        MIL: {
            errors: {
                must1PreviousTask: `A MIL Operator should always have exact 1 operator connected
                to it. Please keep the source operator count as 1.`,
                previousConfigError: `The previous task Operator ( any type ) needs to be configured
                before the current task MIL Operator can be configured!`
            }
        },
        DT: {
            errors: {
                must1PreviousTask: `A Transformation Operator should always have exact 1 operator connected
                to it. Please keep the source operator count as 1.`,
                previousConfigError: `The previous task Operator ( any type ) needs to be configured
                before the current task Transformation Operator can be configured!`
            },
            FILENAME: 'config.dt.json'
        },
        TIMESTAMP: {
            FILENAME: 'config.TS.json'
        }
    },
    DAG: {
        FILENAME: 'dagworkflow.json',
        DAGWORKFLOW: 'dagworkflow'
    },
    common: {
        noOperators: `Please configure some operators first before saving!`
    },
    unitsMap:  {
        YYYY: 'YEARS',
        MM: 'MONTHS',
        MON: 'MONTHS',
        DD: 'DAYS',
        hh: 'HOURS',
        mm: 'MINUTES',
        ss: 'SECONDS'
    },
    ACCESS_TOKEN: 'token'
};
