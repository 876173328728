import { TaskCatalogItemParameter, TaskCatalogItemProperties } from './taskCatalog';

export type WorkflowDefinition = {
  filename: string;
  dag: {
    description: string;
    name: string;
    parameters: TaskCatalogItemParameter[];
  },
  nodes: {
    [key: string]: WorkflowDefinitionNode;
  },
  links: {
    [key: string]: WorkflowDefinitionLink;
  }
};

export type WorkflowDefinitionNode = {
  id: string;
  loc: string;
  type: string;
  properties: TaskCatalogItemProperties;
};

export type ModelNode = {
  [key: string]: WorkflowDefinitionNode;
};

export enum Port {
  Left = 'Left',
  Right = 'Right',
  GroupInternalLeft = 'GroupInternalLeft',
  GroupInternalRight = 'GroupInternalRight',
}

export type WorkflowDefinitionLink = {
  id: string;
  from: string;
  fromPort: string;
  to: string;
  toPort: string;
};
