import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
    transform(value: string): string {
        return value
            .match(/(\b\w)?/g)
            .join('')
            .match(/(^\w|\w$)?/g)
            .join('')
            .toUpperCase();
    }
}
