import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import {
    FloatingLabelModule,
    LabelModule,
} from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IntlModule } from '@progress/kendo-angular-intl';

@NgModule({
    imports: [
        CommonModule,
        TreeViewModule,
        DropDownsModule,
        GridModule,
        FloatingLabelModule,
        ButtonsModule,
        DateInputsModule,
        LabelModule,
        InputsModule,
        LayoutModule,
        IntlModule,
        DatePickerModule,
    ],
    exports: [
        CommonModule,
        TreeViewModule,
        DropDownsModule,
        GridModule,
        FloatingLabelModule,
        ButtonsModule,
        DateInputsModule,
        LabelModule,
        InputsModule,
        LayoutModule,
        IntlModule,
    ],
})
export class KendoModule { }
