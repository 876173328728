import * as _ from 'lodash/fp';
import { ValueObject } from '../../../form-field/form-field.types';
import { isPrimaryEntity } from './isPrimaryEntity';

export const mapFieldsToSearchQuery = (formValue: ValueObject) => {
    const groups = _.flowRight(
        _.groupBy(
            _.first
        ),
        _.map(
            _.split('/')
        ),
        _.filter((key: string) => formValue[key]),
        _.keys
    )(formValue);

    return _.flowRight(
        _.map((groupKey: string) => {
            const entries = groups[groupKey];

            return {
                entityName: groupKey,
                entityPhysicalName: groupKey,
                entityType: groupKey,
                entityAttributes: _.flowRight(
                    _.filter((obj: any) => obj.attributeValue),
                    _.map(
                        ([__, entryKey]: string[]) => ({
                            attributeName: entryKey,
                            attributeValue: formValue[`${groupKey}/${entryKey}`],
                        })
                    )
                )(entries as any),
                entityClassType: isPrimaryEntity(groupKey)
                    ? 'PRIMARY_SUBJECT'
                    : 'SUBJECT_ATTRIBUTE'
            };
        }),
        _.keys
    )(groups);
};
