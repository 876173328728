import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  state,
  style,
  transition,
  trigger,
  animate
} from '@angular/animations';

import _, { filter } from 'lodash';

import { MatDialog, MatDialogRef, MatSort, MatTableDataSource } from '@angular/material';

import { DATA_TYPES, DISP_COLUMNS_CFG, DISP_COLUMNS_CFG_READONLY, DISP_COLUMNS_CFG_READONLY_FILE_ASSET, DISP_COLUMNS_CFG_READONLY_SOURCE, DISP_COLUMNS_CFG_SUBJECT_MAPPING, DISP_COLUMNS_CFG_UPSERT, DISP_COLUMNS_CFG_UPSERT_SCD0 } from './entity.const';

import { ConfigModel } from '../schema-explorer/models/config.model';

import { UdpGrid } from './udp-grid';

import { SourceAttributesComponent } from './source-attributes/source-attributes.component';

import { DataImportService } from 'libs/data-import';
import { SchemaExplorerService } from '../schema-explorer/schema-explorer.service';
import { ConfigureMappingsComponent } from './configure-mappings/configure-mappings.component';
import { SnackbarService } from '@xfusiontech/shared';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UPDMilOperatorService } from 'libs/data-catalog/src/lib/upd-mil-operator/udp-mil-operator.service';
import { getTypeMapping } from 'src/app/shared/utils/common-utilities';


@Component({
  selector: 'xfusiontech-entity-attributes',
  templateUrl: './entity-attributes.component.html',
  styleUrls: ['./entity-attributes.component.scss', './udp-table.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class EntityAttributesComponent extends UdpGrid implements OnInit, OnDestroy {

  @Input() updateMetadataUrl: string;
  @Input() genericConfig: ConfigModel;
  @Input() sourceMappingFormControlData = [];
  @Input() subjectDataMappingData: any;
  @Input() newSelectedEntities = [];
  @Input() udpMILOperatorConfiguration;
  @Input() selectedDataset: any;
  @Input() dataSetOperaterConfig: any;
  @Input() sourceTitle: any;
  @Input() priviousConfigData: any;
  @Input() public entity: any;
  @Input() configType: any;

  typeMap: any = getTypeMapping();

  /**
   * @type: {EventEmitter<boolean>}
   */
  @Output() public wheneError: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @type: {EventEmitter<boolean>}
   */
  @Output() public whenSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public whenCrudCall: EventEmitter<any> = new EventEmitter<any>();

  @Output() public whenSourceMapped: EventEmitter<any> = new EventEmitter<any>();

  @Output() public whenEntity: EventEmitter<any> = new EventEmitter<any>();

  @Output() public whenFinalEntityConfigPassed: EventEmitter<any> = new EventEmitter<any>();

  @Output() public whenSelectedMapping: EventEmitter<any> = new EventEmitter<any>();

  public config: any;

  /**
   * @type: {FormGroup}
   */
  public metaDataForm: FormGroup;

  /**
   * @type: {string[]}
   */
  public dataTypes: any[] = [];

  /**
   * @type: {any}
   */
  public sorter: (...args) => any[];

  public subjectDataMappingEntities = [];
  public subEntities = [];
  public subjectDataMappingEntityGroupBy: any;

  formArr: FormArray;
  moreOption: any;
  public atleastOneConfigAvailable = false;
  removeSortFilter = false;

  /**
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  checkedAttributeRow: any = [];
  enableEdit: boolean;
  colNames: any;
  isReadOnly = true;
  showDescription: boolean;
  showComment: boolean;
  showDetails: boolean;
  sourceDataMapping = [];

  subjectDataMapping = [];

  private dataMappingUploadMode: any;
  private dataMappingUpsertConfig = {
    upsertType: '',
    duplicateColumns: [],
    updateColumns: []
  };
  private checkedDuplicateCols = [];
  private checkedUpdateCols = [];
  duplicateSelectedAll: boolean;

  duplicateAll = new FormControl();
  updateAll = new FormControl();

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.moreOption = null;
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackBar: SnackbarService,
    private schemaExplorerService: SchemaExplorerService,
    private datasetOperatorService: UPDMilOperatorService,
    private dataImportService: DataImportService) {
    super();
    this.dataTypes = DATA_TYPES;
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {

    this.assignEntityConstConfig();

    this.init();
    if (this.udpMILOperatorConfiguration && this.udpMILOperatorConfiguration.dataMappingConfig) {
      this.priviousConfigData = this.udpMILOperatorConfiguration;
    }
    this.processIfPreviousSavedConfigIsAvailable();

    this.onInitProcessForConfigTypes();

    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig) {
      this.udpMILOperatorConfiguration = this.priviousConfigData;
      this.whenEntity.emit(this.udpMILOperatorConfiguration);
    }
    this.checkPrimaryKey();

    if (this.genericConfig.context !== 'source-mapping') {
      this.getEntityDataMappingUploadMode();
    }
  }

  private getEntityDataMappingUploadMode() {
    const key = this.configType === 'subject' ? 'subjectToSourceDataMapping' : 'targetToSourceDataMapping';
    this.datasetOperatorService.entityDataMappingUploadMode$.subscribe((data) => {
      if (data && data.uploadMode && data.upsertConfig && data.entity) {
        if (data.entity.entityID === this.entity.entityID) {
          this.dataMappingUploadMode = data.uploadMode;
          this.dataMappingUpsertConfig.upsertType = data.upsertConfig.upsertType ? data.upsertConfig.upsertType : '';
          this.entity.upsertConfig.upsertType = this.dataMappingUpsertConfig.upsertType;
          // UPSERT
          if (data.uploadMode === 'UPSERT' && data.upsertConfig.upsertType === 'SCD0') {
            this.config = DISP_COLUMNS_CFG_UPSERT_SCD0;
            this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_UPSERT_SCD0);
          } else if (data.uploadMode === 'UPSERT' && (data.upsertConfig.upsertType === 'SCD1'
              || data.upsertConfig.upsertType === 'SCD2' || data.upsertConfig.upsertType === 'SCD3')) {
                this.config = DISP_COLUMNS_CFG_UPSERT;
                this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_UPSERT);
          } else {
            this.assignEntityConstConfig();
          }

          if (data.upsertConfig.duplicateColumns) {
            this.dataMappingUpsertConfig.duplicateColumns = data.upsertConfig.duplicateColumns;
          }

          if (data.upsertConfig.updateColumns) {
            this.dataMappingUpsertConfig.updateColumns = data.upsertConfig.updateColumns;
          }

          if (this.dataMappingUpsertConfig.duplicateColumns.length === this.entity.attributes.length) {
            this.duplicateAll.patchValue(true);
          }

          if (this.dataMappingUpsertConfig.updateColumns.length === this.entity.attributes.length) {
            this.updateAll.patchValue(true);
          }

          // Data Mapping UPSERT config
          this.dataMappingUpsertConfig.duplicateColumns.forEach((duplicateCol) => {
            const attributes = this.metaDataForm.get('attributes') as FormArray;
            const allAttributesValues = attributes.value;
            const index = _.findIndex(allAttributesValues, (e: any) => {
              return e.attributeName === duplicateCol;
            });
            if (index !== -1) {
              ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('duplicateIdentifyingColumn').patchValue(true);
            }
          });

          if (this.dataMappingUpsertConfig.upsertType === 'SCD1' || this.dataMappingUpsertConfig.upsertType === 'SCD3') {
            this.dataMappingUpsertConfig.updateColumns.forEach((updCol) => {
              const attributes = this.metaDataForm.get('attributes') as FormArray;
              const allAttributesValues = attributes.value;
              const index = _.findIndex(allAttributesValues, (e: any) => {
                return e.attributeName === updCol;
              });
              if (index !== -1) {
                ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('updateColumn').patchValue(true);
              }
            });
          }

          if (this.dataMappingUpsertConfig.upsertType === 'SCD2') {
            this.dataMappingUpsertConfig.updateColumns.forEach((updCol) => {
              const updColKeys = Object.keys(updCol);
              updColKeys.forEach((updColkey) => {
                const attributes = this.metaDataForm.get('attributes') as FormArray;
                const allAttributesValues = attributes.value;
                const index = _.findIndex(allAttributesValues, (e: any) => {
                return e.attributeName === updColkey;
              });
                if (index !== -1) {
                ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('updateColumn').patchValue(true);
              }
              });
            });
          }
        }
      }
    });

    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig  && this.priviousConfigData.dataMappingConfig[key]) {
      const index = _.findIndex(this.priviousConfigData.dataMappingConfig[key], (e: any) => {
        return e.entityDataMapping.targetEntityName === this.entity.entityName;
      });

      if (index !== -1) {

        this.dataMappingUploadMode = this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.uploadMode;
        this.dataMappingUpsertConfig.upsertType =
          this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType ?
            this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType : '';

        // UPSERT
        if (this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.uploadMode === 'UPSERT'
          && this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType === 'SCD0') {
            this.config = DISP_COLUMNS_CFG_UPSERT_SCD0;
            this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_UPSERT_SCD0);
        } else if (this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.uploadMode === 'UPSERT'
          && (this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType === 'SCD1'
            || this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType === 'SCD2'
            || this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType === 'SCD3')) {
              this.config = DISP_COLUMNS_CFG_UPSERT;
              this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_UPSERT);
        } else {
          this.assignEntityConstConfig();
        }


        if (this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.duplicateColumns) {
          this.dataMappingUpsertConfig.duplicateColumns =
            this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.duplicateColumns;
        }

        if (this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.updateColumns) {
          this.dataMappingUpsertConfig.updateColumns =
            this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.updateColumns;
        }

        if (this.dataMappingUpsertConfig.duplicateColumns.length === this.entity.attributes.length) {
          this.duplicateAll.patchValue(true);
        }

        if (this.dataMappingUpsertConfig.updateColumns.length === this.entity.attributes.length) {
          this.updateAll.patchValue(true);
        }

        // Data Mapping UPSERT config
        this.dataMappingUpsertConfig.duplicateColumns.forEach((duplicateCol) => {
          const attributes = this.metaDataForm.get('attributes') as FormArray;
          const allAttributesValues = attributes.value;
          const index = _.findIndex(allAttributesValues, (e: any) => {
            return e.attributeName === duplicateCol;
          });
          if (index !== -1) {
            ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('duplicateIdentifyingColumn').patchValue(true);
          }
        });

        if (this.dataMappingUpsertConfig.upsertType === 'SCD1' || this.dataMappingUpsertConfig.upsertType === 'SCD3') {
          this.dataMappingUpsertConfig.updateColumns.forEach((updCol) => {
            const attributes = this.metaDataForm.get('attributes') as FormArray;
            const allAttributesValues = attributes.value;
            const index = _.findIndex(allAttributesValues, (e: any) => {
              return e.attributeName === updCol;
            });
            if (index !== -1) {
              ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('updateColumn').patchValue(true);
            }
          });
        }

        if (this.dataMappingUpsertConfig.upsertType === 'SCD2') {
          this.dataMappingUpsertConfig.updateColumns.forEach((updCol) => {
            const updColKeys = Object.keys(updCol);
            updColKeys.forEach((updColkey) => {
              const attributes = this.metaDataForm.get('attributes') as FormArray;
              const allAttributesValues = attributes.value;
              const index = _.findIndex(allAttributesValues, (e: any) => {
              return e.attributeName === updColkey;
            });
              if (index !== -1) {
              ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('updateColumn').patchValue(true);
            }
            });
          });
        }

      }

    }
  }

  checkPrimaryKey() {
    const key = this.configType === 'subject' ? 'subjectToSourceDataMapping' : 'targetToSourceDataMapping';
    if (this.udpMILOperatorConfiguration && this.udpMILOperatorConfiguration.dataMappingConfig &&
      this.udpMILOperatorConfiguration.dataMappingConfig[key]) {
        const data = this.priviousConfigData.dataMappingConfig[key].filter(item =>
          item.entityDataMapping.targetEntityName === this.entity.entityName);
        if (data.length === 0) {
          this.processDefaultConfigForOnlyPrimaryKeys();
        }
      } else {
        this.processDefaultConfigForOnlyPrimaryKeys();
      }
  }

  private processDefaultConfigForOnlyPrimaryKeys() {
    let entityAtrributes: any[];

    if (this.entity && this.entity.attributes) {
      entityAtrributes = this.entity.attributes;
    } else if (this.entity && this.entity.attribute) {
      entityAtrributes = this.entity.attribute;
    } else {
      entityAtrributes = [];
      return;
    }

    const primaryKeyColumn = entityAtrributes.filter(attribute => (attribute && attribute.primaryKey));

    if (primaryKeyColumn.length > 0) {
      const findPrimaryKeyElement = ((this.metaDataForm.get('attributes') as FormArray).controls.filter(
        element => element.value.attributeID === primaryKeyColumn[0].attributeID
      ));

      if (findPrimaryKeyElement.length > 0) {
        const attributes = this.dataSetOperaterConfig && this.dataSetOperaterConfig.attribute ?
          this.dataSetOperaterConfig.attribute : this.dataSetOperaterConfig.attributes;

        const sourceEmptyData = _.cloneDeep(attributes[0]);

        // tslint:disable-next-line: forin
        for (const key in sourceEmptyData) {
          sourceEmptyData[key] = '';
        }

        this.addEditSrcAttriutes(findPrimaryKeyElement[0], true, [sourceEmptyData]);

        const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
          return e.attributeID === findPrimaryKeyElement[0].value.attributeID;
        });
        if (sourceIndex === -1) {
          return;
        }
        this.sourceDataMapping[sourceIndex].transformation = {
          dataMappingType: '1:1',
          actionType: 'Copy',
          customCalculationFunction: '',
          dataTranslationAssetID: ''
        };
        this.atleastOneConfigAvailable = true;
        this.cdr.detectChanges();
      }
    }

  }

  private assignEntityConstConfig() {
    if (this.genericConfig.context === 'data-catalog') {
      this.config = DISP_COLUMNS_CFG;
      this.displayedColumns = Object.keys(DISP_COLUMNS_CFG);
    } else if (this.genericConfig.context === 'source-mapping') {
      this.removeSortFilter = true;
      this.config = DISP_COLUMNS_CFG_READONLY_SOURCE;
      this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_READONLY_SOURCE);
    } else if (this.genericConfig.context === 'subject-mapping') {
      this.removeSortFilter = true;
      this.processSubjetDataMapping();
      this.config = DISP_COLUMNS_CFG_SUBJECT_MAPPING;
      this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_SUBJECT_MAPPING);
    } else {
      this.removeSortFilter = true;
      if (this.genericConfig.fileAsset) {
        this.config = DISP_COLUMNS_CFG_READONLY_SOURCE;
        this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_READONLY_SOURCE);
      } else {
        this.config = DISP_COLUMNS_CFG_READONLY;
        this.displayedColumns = Object.keys(DISP_COLUMNS_CFG_READONLY);
      }
    }
  }

  private processIfPreviousSavedConfigIsAvailable() {
    const key = this.configType === 'subject' ? 'subjectToSourceDataMapping' : 'targetToSourceDataMapping';
    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig && this.priviousConfigData.dataMappingConfig[key]
      ) {
      this.whenSelectedMapping.emit(_.cloneDeep(this.priviousConfigData));
      if (this.priviousConfigData.dataMappingConfig[key] && this.genericConfig.context !== 'subject-mapping') {
        let element: any;
        this.priviousConfigData.dataMappingConfig[key].forEach(data => {
          data.dataMapping.forEach(dataMap => {
            const sourceData = [];
            this.entity.attributes.filter(i => {
              if (i.attributeName === dataMap.targetAttributeName) {
                element = {
                  attributeID: i.attributeID,
                  targetAttributeName: i.attributeName,
                  elementValue: i
                };
                if (dataMap.sourceValue) {
                  element.transformation = dataMap.transformation;
                  this.setSourceValue(i, dataMap.sourceValue, element);
                }
                if (dataMap.sourceDataAttributes && dataMap.sourceDataAttributes.length > 0) {
                  dataMap.sourceDataAttributes.forEach(sData => {
                    this.dataSetOperaterConfig.attribute.filter(x => {
                      if (x.name === sData) {
                        sourceData.push(x);
                      }
                    });
                  });
                  if (element) {
                    element = { ...element, transformation: dataMap.transformation };
                    this.setFormValue(element, sourceData);
                  }
                } else if (dataMap.parentKeyAttribute && dataMap.parentKeyAttribute.length > 0) {
                  this.setParentAttribute(element, dataMap.parentKeyAttribute);
                } else if (dataMap.transformation && dataMap.transformation.actionType === "Value") {
                  this.setUpdateColumn(element, '', dataMap.transformation);
                }
              }
            });
          });
          if (data.entityDataMapping.upsertConfig) {
            // duplicate columns
            if (data.entityDataMapping.upsertConfig.duplicateColumns) {
              const attributes = this.metaDataForm.get('attributes') as FormArray;
              const allAttributesValues = attributes.value;
              const index = _.findIndex(allAttributesValues, (e: any) => {
                return e.attributeName === data.entityDataMapping.upsertConfig.duplicateColumns;
              });
              if (index !== -1) {
                ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup)
                  .get('duplicateIdentifyingColumn').patchValue(true);
              }
            }
            // update columns
            if (data.entityDataMapping.upsertConfig.updateColumns) {
              const attributes = this.metaDataForm.get('attributes') as FormArray;
              const allAttributesValues = attributes.value;
              const index = _.findIndex(allAttributesValues, (e: any) => {
                return e.attributeName === data.entityDataMapping.upsertConfig.updateColumns;
              });
              if (index !== -1) {
                ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('updateColumn').patchValue(true);
              }
            }
          }
          if (data.entityDataMapping.upsertConfig && data.entityDataMapping.upsertConfig.updateColumns) {
            data.entityDataMapping.upsertConfig.updateColumns.forEach(col => {
              const keyAttribute = Object.keys(col);
              this.entity.attributes.filter(i => {
                if (i.attributeName === keyAttribute[0]) {
                  this.dataMappingUpsertConfig.updateColumns.push({[keyAttribute[0]]: col[keyAttribute[0]]});
                  this.setUpdateColumn(i, col[keyAttribute[0]].updateValue, '');
                }
              });
            });
          }
        });
        this.atleastOneConfigAvailable = true;
      }
    } else {
      if (this.genericConfig.context === 'data-mapping') {
        this.processDefaultConfigForOnlyPrimaryKeys();
      }
    }
  }

  private onInitProcessForConfigTypes() {
    if (this.genericConfig.context === 'source-mapping') {
      if (this.sourceMappingFormControlData) {
        this.sourceMappingFormControlData.forEach(data => {
          const attributes = this.metaDataForm.get('attributes') as FormArray;
          const allAttributesValues = attributes.value;
          const index = _.findIndex(allAttributesValues, (e: any) => {
            return e.attributeName === data.attributeName;
          });
          if (index !== -1) {
            ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('attributeChecked').patchValue(true);
          }
        });
      }
    }

    if (this.genericConfig.context === 'subject-mapping') {
      this.subControls();
    }

    if (this.genericConfig.context === 'data-mapping') {
      if (this.udpMILOperatorConfiguration &&
          this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.identifyDuplicateRecordsByFields) {
            this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.identifyDuplicateRecordsByFields.forEach(data => {
              const attributes = this.metaDataForm.get('attributes') as FormArray;
              const allAttributesValues = attributes.value;
              const index = _.findIndex(allAttributesValues, (e: any) => {
                return e.attributeName === data;
              });
              if (index !== -1) {
                ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('attributeChecked').patchValue(true);
              }
            });
          }
    }

  }

  private subControls() {
    if (this.priviousConfigData && this.priviousConfigData.subjectIdentificationConfig) {
      this.priviousConfigData.subjectIdentificationConfig.forEach(data => {
        data.mdmSubject.subjectToTargetattributeMap.forEach(sub => {
          const attributes = this.metaDataForm.get('attributes') as FormArray;
          const allAttributesValues = attributes.value;
          const index = _.findIndex(allAttributesValues, (e: any) => {
            return e.targetEntityName === sub.subjectEntityName && e.targetAttributeName === sub.subjectAttributeName;
          });
          if (index !== -1) {
            this.atleastOneConfigAvailable = true;
            ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup)
              .get('dataMappingEntity').patchValue(sub.targetEntityName);
            ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup)
              .get('dataMappingEntityAttributes').patchValue(sub.targetAttributeName);
            ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup)
              .get('dataMappingEntityAttributesType').patchValue(sub.targetAttributeDatatType);
          }
        });
      });
    }
  }

  private processSubjetDataMapping() {
    this.subjectDataMappingData.forEach((dataMap) => {
      const entity = {
        entityName: dataMap.entityDataMapping.targetEntityName,
        attributes: []
      };
      dataMap.dataMapping.forEach((attribute) => {
        entity.attributes.push(attribute.targetAttributeName);
      });
      this.subEntities.push(entity.entityName);
      this.subjectDataMappingEntities.push(entity);
      this.subjectDataMappingEntityGroupBy = _.groupBy(this.subjectDataMappingEntities, 'entityName');
    });
  }

  public getValue(val, el) {
    if (val && el) {
      return el.value[val];
    } else {
      return '';
    }
  }

  public onSubjectTargetEntityChange(event, element) {
    element.patchValue({ dataMappingEntity: event });
    if (!element.value.dataMappingEntityAttributes) {
      element.patchValue({ dataMappingEntityAttributes: '' });
      element.patchValue({ dataMappingEntityAttributesType: '' });
    }
    // This code is not required now, form control should work automatically from HTML
    const attributes = this.metaDataForm.get('attributes') as FormArray;
    const allAttributesValues = attributes.value;

    const index = _.findIndex(allAttributesValues, (e: any) => {
      return e.targetAttributeName === element.value.targetAttributeName;
    });

    if (index !== -1) {
      const entityIndex = _.findIndex(this.subjectDataMappingEntities, (e) => {
        return e.entityName === element.value.dataMappingEntity;
      });
      this.cdr.detectChanges();
      this.atleastOneConfigAvailable = true;
    }
    this.getValue('', '');
    this.cdr.detectChanges();
  }

  public saveCurrentEntityConfig() {
    console.log(this.configType);
    const key = this.configType === 'subject' ? 'subjectToSourceDataMapping' : 'targetToSourceDataMapping';
    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig) {
      this.udpMILOperatorConfiguration = this.priviousConfigData;
    }
    const projectName = [];
    projectName.push(this.sourceDataMapping.map(item => item.entity.entityID));
    const uniqueProject = [...new Set(projectName[0])];

    const result = uniqueProject.map(id => {
      return this.sourceDataMapping.filter(item => item.entity.entityID === id);
    });

    const final = [];
    let mapping: any;
    mapping = {
      targetEntityGroupName: this.selectedDataset.dataSourceName,
      targetEntityGroupType: this.selectedDataset.dataSourceType,
    };
    for (const item of result) {
      const entityData = item[0].entity;
      if (this.dataMappingUpsertConfig.upsertType === 'SCD0') {
        this.dataMappingUpsertConfig.updateColumns = [];
      }
      const entityGroup = {
        targetEntityName: entityData.entityName,
        targetEntityType: entityData.entityName,
        entityClassType: entityData.entityClassType,
        subjectIdentification: entityData.subjectIdentification,
        uploadMode: entityData.uploadMode,
        upsertConfig: entityData.upsertConfig
      };
      let attributeData = [];
      for (const data of item) {
        const sourceData = [];
        let attribute: any;
        attribute = [(data.elementValue.primaryKey ? 'PK' : ''), (data.elementValue.foreignKey ? 'FK' : '')];
        attribute = attribute.filter(n => n);
        // tslint:disable-next-line: no-unused-expression
        attribute.length === 0 ? attribute.push('NA') : '';
        const parentAttribute = [{
          parentEntityName: data.elementValue.parentAttribute ? data.elementValue.parentAttribute.parentEntityName : '',
          parentEntityAttributeName: data.elementValue.parentAttribute ? data.elementValue.parentAttribute.parentEntityAttributeName : ''
        }];
        data.sourceData ? data.sourceData.filter((source => sourceData.push(source.name))) : '';
        if (attributeData.length > 0) {
            attributeData = [...attributeData, this.setDataMapping(data, attribute, sourceData, parentAttribute)];
        } else {
            attributeData = [this.setDataMapping(data, attribute, sourceData, parentAttribute)];
        }

      }
      final.push({
        dataMapping: attributeData, targetToSourceDataMapping: mapping,
        entityDataMapping: entityGroup,
      });
      if (this.udpMILOperatorConfiguration.dataMappingConfig) {
        if (this.udpMILOperatorConfiguration.dataMappingConfig[key]) {
          this.replaceDataMapping(final, key);
        } else {
          const oldKey = Object.keys(this.udpMILOperatorConfiguration.dataMappingConfig);
          const data = this.udpMILOperatorConfiguration.dataMappingConfig[oldKey[0]];
          this.udpMILOperatorConfiguration = { ...this.udpMILOperatorConfiguration, dataMappingConfig: {[key]: final, [oldKey[0]]: data}  };
        }
      } else {
        this.udpMILOperatorConfiguration = { ...this.udpMILOperatorConfiguration, dataMappingConfig: {[key]: final} };
      }
    }
    this.finalEntitySave(key);
  }

  private replaceDataMapping(final, key) {
    const sourceIndex = _.findIndex(this.udpMILOperatorConfiguration.dataMappingConfig[key], (e: any) => {
      return e.entityDataMapping.targetEntityName === final[0].entityDataMapping.targetEntityName;
    });
    if (sourceIndex === -1) {
      if (this.udpMILOperatorConfiguration.dataMappingConfig) {
        this.udpMILOperatorConfiguration.dataMappingConfig[key].push(final[0]);
      } else {
        this.udpMILOperatorConfiguration = { ...this.udpMILOperatorConfiguration, dataMappingConfig: final };
      }
    } else {
      this.udpMILOperatorConfiguration.dataMappingConfig[key][sourceIndex] = final[0];
    }
  }

  private setDataMapping(data, attribute, sourceData, parentAttribute) {
    if (parentAttribute) {
      if (sourceData.length > 0) {
        return {
          targetAttributeName: data.elementValue.attributeName,
          targetAttrbuteKeyType: attribute,
          targetAttrbuteType: data.elementValue.dataType,
          sourceDataAttributes: sourceData,
          parentKeyAttribute: [
            {
              parentEntityName: '',
              parentEntityAttributeName: ''
            }
          ],
          transformation: data.transformation,
        };
      } else if(data.sourceValue) {
        return {
          targetAttributeName: data.elementValue.attributeName,
          targetAttrbuteKeyType: attribute,
          targetAttrbuteType: data.elementValue.dataType,
          transformation: data.transformation,
          sourceValue: data.sourceValue
        };
      } else if(data.transformation) {
        return {
          targetAttributeName: data.elementValue.attributeName,
          targetAttrbuteKeyType: attribute,
          targetAttrbuteType: data.elementValue.dataType,
          transformation: data.transformation,
        };
      } else {
        return {
          targetAttributeName: data.elementValue.attributeName,
          targetAttrbuteKeyType: attribute,
          targetAttrbuteType: data.elementValue.dataType,
          parentKeyAttribute: parentAttribute,
        };
      }
    } else {
      return {
        targetAttributeName: data.elementValue.attributeName,
        targetAttrbuteKeyType: attribute,
        targetAttrbuteType: data.elementValue.dataType,
        transformation: data.transformation,
        sourceDataAttributes: sourceData
      };
    }
  }

  private finalEntitySave(key) {
    this.dataImportService.entityData.next(this.sourceDataMapping);
    const index = _.findIndex(
      this.udpMILOperatorConfiguration.dataMappingConfig[key],
      (e: any) => {
        return (
          e.entityDataMapping.targetEntityName === this.entity.entityName &&
          (e.entityDataMapping.entityClassType !==
            this.entity.entityClassType ||
            e.entityDataMapping.subjectIdentification !==
            this.entity.subjectIdentification ||
            e.entityDataMapping.uploadMode !==
            this.entity.uploadMode ||
            e.entityDataMapping.upsertConfig.upsertType !==
            this.entity.upsertConfig.upsertType ||
            e.entityDataMapping.upsertConfig.duplicateColumns !==
            this.entity.upsertConfig.duplicateColumns ||
            e.entityDataMapping.upsertConfig.updateColumns !==
            this.entity.upsertConfig.updateColumns)
        );
      }
    );
    if (index !== -1) {
      this.udpMILOperatorConfiguration.dataMappingConfig[key][
        index
      ].entityDataMapping.entityClassType = this.entity.entityClassType;
      this.udpMILOperatorConfiguration.dataMappingConfig[key][
        index
      ].entityDataMapping.subjectIdentification =
        this.entity.subjectIdentification;
      this.udpMILOperatorConfiguration.dataMappingConfig[key][
          index
      ].entityDataMapping.uploadMode = this.entity.uploadMode;
      this.udpMILOperatorConfiguration.dataMappingConfig[key][
        index
      ].entityDataMapping.upsertConfig.upsertType = this.entity.upsertConfig ? this.entity.upsertConfig.upsertType : '';

      this.udpMILOperatorConfiguration.dataMappingConfig[key][
        index
      ].entityDataMapping.upsertConfig.duplicateColumns = this.dataMappingUpsertConfig.duplicateColumns;

      this.udpMILOperatorConfiguration.dataMappingConfig[key][
        index
      ].entityDataMapping.upsertConfig.updateColumns = this.dataMappingUpsertConfig.updateColumns;
    }
    this.whenFinalEntityConfigPassed.emit(this.udpMILOperatorConfiguration);
    this.snackBar.notify(
      this.entity.entityName + ' entity ' + 'config saved successfully!',
      'success'
    );
    this.whenSelectedMapping.emit(_.cloneDeep(this.udpMILOperatorConfiguration));
  }

  openConfigureMappingDialog(element) {
    if (!this.dataMappingUploadMode && this.entity.uploadMode) {
      this.dataMappingUploadMode = this.entity.uploadMode;
    }
    let isUpsertData = false;
    let sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });
    let currentMapping: any;
    if (!element.value.sourceAttribute && ((this.dataMappingUploadMode === 'APPEND' ||
    this.dataMappingUploadMode === 'OVERRIDE') ||
    (this.dataMappingUploadMode === 'UPSERT' &&
      (
        this.dataMappingUpsertConfig.upsertType === 'SCD0' ||
        this.dataMappingUpsertConfig.upsertType === 'SCD1' ||
        this.dataMappingUpsertConfig.upsertType === 'SCD2' ||
        this.dataMappingUpsertConfig.upsertType === 'SCD3')))) {
          isUpsertData = true;
        }
    if (isUpsertData && sourceIndex === -1) {
      const newEntity = _.cloneDeep(this.entity);
      this.sourceDataMapping.push({
        attributeID: element.value.attributeID,
        targetAttributeName: element.value.attributeName,
        elementValue: element.value,
        sourceValue: element.value.sourceValue ? element.value.sourceValue : '',
        entity: newEntity,
        transformation: {}
      });
      sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
        return e.attributeID === element.value.attributeID;
      });
      currentMapping = this.sourceDataMapping[sourceIndex];
    } else {
      if (isUpsertData && this.sourceDataMapping[sourceIndex].elementValue &&
        this.sourceDataMapping[sourceIndex].elementValue.primaryKey === true
        && this.sourceDataMapping[sourceIndex].transformation.actionType === 'Copy') {
          this.sourceDataMapping[sourceIndex].transformation.actionType = 'Value';
      }
      currentMapping = this.sourceDataMapping[sourceIndex];
    }
    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      ConfigureMappingsComponent, this.dialog,
      {
        width: '1280px',
        height: '425px',
        data: {
          currentMapping,
          isUpsertData,
          uploadMode: this.dataMappingUploadMode,
          upsertConfig: this.dataMappingUpsertConfig,
          element: element.value
        }
      }
    );

    ref.afterClosed()
      .subscribe((data) => {
        if (data) {
          this.cdr.detectChanges();
          if (isUpsertData) {
            if (data.transformation.actionType === 'Custom') {
              this.sourceDataMapping[sourceIndex].sourceValue = '';
              delete this.dataMappingUpsertConfig.updateColumns[element.value.attributeName];
            }
            this.sourceDataMapping[sourceIndex].transformation = data.transformation;
            if (data.sourceValue) {
              this.sourceDataMapping[sourceIndex].sourceValue = data.sourceValue;
              this.setSourceValue(element.value, data.sourceValue, '');
              this.setUpdateColumn(element, '', '');
              delete this.dataMappingUpsertConfig.updateColumns[element.value.attributeName];
            } else if (data.updateColumn) {
              if (this.sourceDataMapping[sourceIndex].sourceValue) {
                this.sourceDataMapping[sourceIndex].sourceValue = '';
              }
              const mapIndex = _.findIndex(this.dataMappingUpsertConfig.updateColumns, (e: any) => {
                const keyAttribute = Object.keys(e)[0];
                return element.value.attributeName === keyAttribute;
              });
              if (mapIndex !== -1) {
                this.dataMappingUpsertConfig.updateColumns[mapIndex] = {[element.value.attributeName]: data.updateColumn};
              } else {
                this.dataMappingUpsertConfig.updateColumns.push({[element.value.attributeName]: data.updateColumn});
              }
              this.setUpdateColumn(element.value, data.updateColumn.updateValue, '');
              // }
              this.setSourceValue(element.value, '', '');
            }
            if (data.transformation.actionType !== 'Value') {
              this.setUpdateColumn(element, '', '');
              this.setSourceValue(element.value, '', '');
            }
          } else {
            this.sourceDataMapping[sourceIndex].transformation = data.transformation;
          }
          this.atleastOneConfigAvailable = true;
        }
      });
  }

  setUpdateColumn(element, data, previousData) {
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.attributeID;
    });
    if (sourceIndex === -1 && previousData) {
      const newEntity = _.cloneDeep(this.entity);
      delete newEntity.attributes;
      this.sourceDataMapping.push({
        attributeID: element.attributeID,
        targetAttributeName: element.targetAttributeName,
        elementValue: element.elementValue,
        entity: newEntity,
        transformation: previousData
      });
    }
    if (data) {
      const attributes = this.metaDataForm.get('attributes') as FormArray;
      const allAttributesValues = attributes.value;

      const index = _.findIndex(allAttributesValues, (e: any) => {
        return e.attributeID === element.attributeID;
      });

      if (index !== -1) {
        (
          (this.metaDataForm.get('attributes') as FormArray).at(
            index
          ) as FormGroup
        ).get('updateValue').patchValue(data);
        this.cdr.detectChanges();
      }
    }
  }

  setSourceValue(element, data, previousData) {
    if (previousData) {
      const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
        return e.attributeID === element.attributeID;
      });
      if (sourceIndex === -1) {
        const newEntity = _.cloneDeep(this.entity);
        delete newEntity.attributes;
        this.sourceDataMapping.push({
          attributeID: element.attributeID,
          sourceValue: data,
          targetAttributeName: previousData.targetAttributeName,
          elementValue: previousData.elementValue,
          entity: newEntity,
          transformation: previousData.transformation
        });
      }
    }
    const attributes = this.metaDataForm.get('attributes') as FormArray;
    const allAttributesValues = attributes.value;

    const index = _.findIndex(allAttributesValues, (e: any) => {
      return e.attributeID === element.attributeID;
    });

    if (index !== -1) {
      (
        (this.metaDataForm.get('attributes') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('sourceValue')
        .patchValue(data);
      this.cdr.detectChanges();
    }
  }

  addEditSrcAttriutes(element, notLoadSourcePopup?: boolean, data?: any) {
    let sourceAttr = [];
    if (this.sourceDataMapping.length > 0) {
      const index = _.findIndex(this.sourceDataMapping, (e: any) => {
        return e.attributeID === element.value.attributeID;
      });
      if (index !== -1) {
        sourceAttr = this.sourceDataMapping[index].sourceData;
      }
    }
    if (notLoadSourcePopup && data) {
      this.findSourceAttributeForElementAndUpdate(element, data, 'addUpdate');
    } else {
      this.openSourceMappingPopup(element, sourceAttr);
    }

  }

  private openSourceMappingPopup(element, sourceAttr) {
    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      SourceAttributesComponent, this.dialog,
      {
        width: '900px',
        height: '600px',
        data: {
          currentEntityGroupId: this.genericConfig.currentEntityGroupId,
          attributeData: this.dataSetOperaterConfig,
          sourceMappingFormControlData: sourceAttr,
          element: element.value
        }
      }
    );
    ref.afterClosed()
      .subscribe((data) => {
        if (data) {
          if (data && data.length === 0) { // return if user doesnt do any config and clicks config button
            this.removeSources(element);
            return;
          }
          data = data.filter((sourceData) => {
            return sourceData.attributeName && sourceData.attributeName !== '';
          });
          if (data && data.length === 0) { // check twice intentionally
            return;
          }
          this.findSourceAttributeForElementAndUpdate(element, data, 'addUpdate');
          element.patchValue({ parentAttribute: '' });
          this.cdr.detectChanges();
          this.setDefaultConfig(element);
        }
      });
  }

  private removeSources(element) {
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });
    if (sourceIndex !== -1) {
      this.sourceDataMapping.splice(sourceIndex, 1);
    }
    const attributes = this.metaDataForm.get('attributes') as FormArray;
    const allAttributesValues = attributes.value;

    const index = _.findIndex(allAttributesValues, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });

    if (index !== -1) {
      ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('sourceAttribute').patchValue('');
      this.cdr.detectChanges();
    }
    if (this.sourceDataMapping.length === 0) {
      this.atleastOneConfigAvailable = false;
    }
  }

  private setDefaultConfig(element) {
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });
    const transformation = {
      dataMappingType: '',
      actionType: 'Copy',
      customCalculationFunction: '',
      dataTranslationAssetID: ''
    };
    if (this.sourceDataMapping[sourceIndex].sourceData.length > 1) {
      transformation.dataMappingType = '1:N';
    } else {
      transformation.dataMappingType = '1:N';
    }
    this.sourceDataMapping[sourceIndex].transformation = transformation;
  }

  private findSourceAttributeForElementAndUpdate(element, data, mode: string) {
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });
    if (sourceIndex !== -1) {
      this.sourceDataMapping[sourceIndex].sourceData = data;
    } else {
      if (mode !== 'delete') {

        // Add a logic on top also to check if entity param is missing in case user does mapping first and then
        // select top level dropdown for Subject Identification
        const newEntity = _.cloneDeep(this.entity);
        delete newEntity.attributes;
        this.sourceDataMapping.push({
          attributeID: element.value.attributeID,
          sourceData: data,
          targetAttributeName: element.value.attributeName,
          elementValue: element.value,
          entity: newEntity
        });
      }
    }
    const sourceAttributes = mode !== 'delete' ? _.map(data, 'attributeName').join(',') : '';

    if (sourceAttributes === '') {
      return;
    }

    const attributes = this.metaDataForm.get('attributes') as FormArray;
    const allAttributesValues = attributes.value;

    const index = _.findIndex(allAttributesValues, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });

    if (index !== -1) {
      ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('sourceAttribute').patchValue(sourceAttributes);
      this.cdr.detectChanges();
    }
  }

  public deleteSrcAttributes(element) {
    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      ConfirmationDialogComponent, this.dialog,
      {
        width: '600px',
        height: '250px',
        data: {
           title: 'Delete Mapping',
           message: `Deleting the mapping will automatically delete all configurations associated to
            it. Do you want to proceed with deleting the mapping?`,
           buttonCancel: 'No',
           buttonOK: 'Yes'
        }
      }
    );
    ref.afterClosed()
      .subscribe((flag) => {
        if (flag) {
          if (element.value.sourceAttribute) {
            element.patchValue({ sourceAttribute: '' });
          } else if (element.value.sourceValue) {
            element.patchValue({ sourceValue: '' });
          } else if (element.value.updateValue) {
            element.patchValue({ updateValue: '' });
            let columnIndex: any;
            this.dataMappingUpsertConfig.updateColumns.forEach(( data, i) => {
              const key = Object.keys(data)[0];
              if (key === element.value.attributeName ) {
                columnIndex = i;
              }
            });
            this.dataMappingUpsertConfig.updateColumns.splice(columnIndex, 1);
          }
          const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
            return e.attributeID === element.value.attributeID;
          });
          this.sourceDataMapping.splice(sourceIndex, 1);
          this.cdr.detectChanges();
        }
      });
  }

  public saveCurrentSubjectEntityConfig() {
    const attributes = this.metaDataForm.get('attributes') as FormArray;
    this.whenFinalEntityConfigPassed.emit(attributes.value);
    this.snackBar.notify(this.entity.entityName + ' entity ' + 'config saved successfully!', 'success');
  }


  public openMore(event, i) {
    this.moreOption === i ? this.moreOption = null : this.moreOption = i;
    event.stopPropagation();
  }

  private setFormValue(element, data) {
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.attributeID;
    });
    if (sourceIndex !== -1) {
      this.sourceDataMapping[sourceIndex].sourceData = data;
    } else {
      const newEntity = _.cloneDeep(this.entity);
      delete newEntity.attributes;
      this.sourceDataMapping.push({
        attributeID: element.attributeID,
        sourceData: data,
        targetAttributeName: element.targetAttributeName,
        elementValue: element.elementValue,
        entity: newEntity,
        transformation: element.transformation
      });
    }
    const sourceAttributes = _.map(data, 'attributeName').join(',');

    const attributes = this.metaDataForm.get('attributes') as FormArray;
    const allAttributesValues = attributes.value;

    const index = _.findIndex(allAttributesValues, (e: any) => {
      return e.attributeID === element.attributeID;
    });

    if (index !== -1) {
      ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('sourceAttribute').patchValue(sourceAttributes);
      this.cdr.detectChanges();
    }
  }


  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the meta data form.
   */
  private init(): void {

    this.metaDataForm = this.fb.group({
      searchValue: new FormControl(''),
      attributes: this.formArr ? this.formArr : this.fb.array([])
    });

    // initialize the form attributes
    this.initFormArray();

    this.metaDataForm.setControl('attributes', this.formArr ? this.formArr : this.fb.array([]));
    this.dataImportService.entityData$.subscribe(data => {

      // if (data.length > 0) {
      //   this.sourceDataMapping = data;
      //   for (let item of data) {
      //     this.setFormValue(item, item.sourceData)
      //   }
      //   // this.sourceDataMapping = data;
      // }
    });
    // set table data source
    this.dataSource = new MatTableDataSource<any>(
      this.attributes.controls.sort((a, b) => a.value.attributeSequenceNumber - b.value.attributeSequenceNumber)
    );

    // selection = new SelectionModel<PeriodicElement>(true, []);

    // customize the sorting mechanism
    setTimeout(() => {
      super.ngOnInit();
      this.sorter = this.dataSource.sortData;
      this.dataSource.sortData = this.sortData.bind(this);
    });
  }

  private setParentAttribute(element, parentAttribute) {
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.attributeID;
    });
    if (sourceIndex !== -1) {
      this.sourceDataMapping[sourceIndex].parentAttribute = parentAttribute;
    } else {
      const newEntity = _.cloneDeep(this.entity);
      delete newEntity.attributes;
      element.elementValue.parentAttribute = parentAttribute[0];
      this.sourceDataMapping.push({
        attributeID: element.attributeID,
        sourceData: [],
        targetAttributeName: element.targetAttributeName,
        elementValue: element.elementValue,
        entity: newEntity,
      });
    }
    const attributes = this.metaDataForm.get('attributes') as FormArray;
    const allAttributesValues = attributes.value;

    const index = _.findIndex(allAttributesValues, (e: any) => {
      return e.attributeID === element.attributeID;
    });

    if (index !== -1) {
      ((this.metaDataForm.get('attributes') as FormArray).at(index) as FormGroup).get('parentAttribute').patchValue(parentAttribute[0]);
      this.cdr.detectChanges();
    }
    this.cdr.detectChanges();
  }

  async addParentAttriutes(element) {
    if (element.value.foreignKey === false) {
      const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
        ConfirmationDialogComponent, this.dialog,
        {
          width: '600px',
          height: '250px',
          data: {
             title: 'Non Foreign key mapping warning',
             message: 'The Primary Key is being mapped to a column that is not a Foreign Key. Do you like to continue with the mapping?',
             buttonCancel: 'No',
             buttonOK: 'Yes'
          }
        }
      );
      ref.afterClosed()
        .subscribe((data) => {
          console.log('callledddd', data);
          if (data) {
            this.setParentAttriutes(element);
          }
        });
    } else {
      this.setParentAttriutes(element);
    }

  }

  setParentAttriutes(element) {
    const columnData = this.dataImportService.getColumnData();
    try {
      if (columnData && columnData.parentEntityAttributeName && columnData.parentEntityName) {
        element.patchValue({ parentAttribute: columnData });
        this.atleastOneConfigAvailable = true;
        const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
          return e.attributeID === element.value.attributeID;
        });
        if (sourceIndex !== -1) {
          this.sourceDataMapping.splice(sourceIndex, 1);
        }
        element.patchValue({ sourceAttribute: '' });
        const newEntity = _.cloneDeep(this.entity);
        delete newEntity.attributes;
        this.sourceDataMapping.push({
          attributeID: element.value.attributeID,
          targetAttributeName: element.value.attributeName,
          sourceData: [],
          elementValue: element.value,
          entity: newEntity
        });
      }
      this.cdr.detectChanges();
    } catch (err) {

    }
  }

  removeParentAttribute(element) {
    element.patchValue({ parentAttribute: '' });
    const sourceIndex = _.findIndex(this.sourceDataMapping, (e: any) => {
      return e.attributeID === element.value.attributeID;
    });
    if (sourceIndex !== -1) {
      this.sourceDataMapping.splice(sourceIndex, 1);
    }
    if (this.sourceDataMapping.length === 0) {
      this.atleastOneConfigAvailable = false;
    }
  }

  /**
   * @private
   * @returns: FormArray
   * @description: a helper method that
   * returns the attributes.
   */
  private get attributes(): FormArray {
    return this.metaDataForm.get('attributes') as FormArray;
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the meta data list.
   */
  private initFormArray(attributes?: any[], list?: any[]): any[] {
    const entity: any = this.entity.attributes;
    const formArr: FormArray = this.attributes;

    if (entity.fileSchema) {
      attributes = entity.fileSchema.attributes;
    } else if (entity.entities) {
      attributes = entity.entities[0].attributes;
    } else {
      attributes = this.entity.attributes; // attributes
    }

    // get list of all the attributes
    // convert them into a group
    for (const attr of attributes) {
      const group: any = {};
      const keys: string[] = _.keys(attr);

      // convert each field into a form control
      _.each(keys, (key) => {
        // in case if the data type is missing
        // preset it to string data type
        if (key === 'dataType' && !attr[key]) {
          attr[key] = 'STRING';
        }

        group[key] = new FormControl(attr[key]);
      });

      // in case if the attribute alias
      // name wasn't returned from the
      // API do add one.
      if (this.genericConfig.context !== 'subject-mapping') {
        this.setAttributes(group);
      } else {
        this.setAttributesForSubjectMapping(group);
      }

      if (!list) {
        formArr.push(new FormGroup(group));
      } else {
        list.push(new FormGroup(group));
      }

    }

    this.formArr = formArr;

    return list || [];
  }

  /**
   * @private
   * @param: {group<any>}
   * @returns: void
   * @description: a helper method that
   * sets the attributes.
   */
  private setAttributes(group: any): void {
    group.attributeLength = this.setControl(
      'attributeLength', group
    );

    group.attributeFormat = this.setControl(
      'attributeFormat', group
    );

    group.attributeComment = this.setControl(
      'attributeComment', group
    );

    group.attributeChecked = this.setControl(
      'attributeChecked', group
    );

    group.duplicateIdentifyingColumn = this.setControl(
      'duplicateIdentifyingColumn', group
    );

    group.updateColumn = this.setControl(
      'updateColumn', group
    );

    group.sourceAttribute = this.setControl(
      'sourceAttribute', group
    );

    group.sourceValue = this.setControl(
      'sourceValue', group
    );

    group.updateValue = this.setControl(
      'updateValue', group
    );

    group.parentAttribute = this.setControl(
      'parentAttribute', group
    );
  }

  private setAttributesForSubjectMapping(group: any) {
    group.dataMappingEntity = this.setControl(
      'dataMappingEntity', group
    );

    group.dataMappingEntityAttributes = this.setControl(
      'dataMappingEntityAttributes', group
    );

    group.dataMappingEntityAttributesType = this.setControl(
      'dataMappingEntityAttributesType', group
    );

  }

  /**
   * @private
   * @param: {attr<any>}
   * @param: {group<any>}
   * @returns: any
   * @description: a helper method that
   * sets the control.
   */
  private setControl(attr: any, group: any): any {
    if (!group.hasOwnProperty(attr)) {
      return new FormControl('');
    }
    return group[attr];
  }

  /**
   * @private
   * @returns: any[]
   * @description: a helper method that
   * normalizes the data for sorting.
   */
  private sortData(
    data: FormGroup[], sort: MatSort): any[] {
    const list: any[] = [];

    // normalize the data
    // for sorting to happen
    for (const item of data) {
      list.push(item.value);
    }

    // get the sorted data
    const sorted: any[] = this.sorter.call(
      this, list, sort
    );

    return this.initFormArray(sorted, []);
  }

  onSubjectTargetEntityAttrChange(event, el) {
    el.patchValue({ dataMappingEntityAttributes: event });
    const data = this.subjectDataMappingData;
    const entity = _.filter(data, (item) => {
      return item.entityDataMapping.targetEntityName === el.value.dataMappingEntity;
    });
    const type = _.filter(entity[0].dataMapping, (item) => {
      return item.targetAttributeName === el.value.dataMappingEntityAttributes;
    });
    if (type && type.length > 0) {
      el.patchValue({ dataMappingEntityAttributesType: type[0].targetAttrbuteType });
    }
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * saves the meta data.
   */
  public saveMetaData(): void {
    if (this.entity.attributes) {
      const crud = {
        type: 'saveAttr',
        checkedAttributeRow: this.checkedAttributeRow
      };
      this.whenCrudCall.emit(crud);
    } else {
      this.dataImportService
        .updateMetadata(
          this.updateMetadataUrl + this.customerImportFileId,
          this.metaDataForm.value.attributes
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!!res) {
            this.whenSuccess.emit(true);
          } else {
            this.handleErrors();
          }
        }, err => {
          this.handleErrors();
        });
    }
  }

  public checkedAttribute(e, ele) {
    this.checkedAttributeRow.push(ele.value);
    this.datasetOperatorService.selectedFields.next(this.checkedAttributeRow);
    if (this.genericConfig.context === 'source-mapping') {
      this.whenSourceMapped.emit({
        checked: e.checked,
        value: ele.value
      });
    }
    if (this.checkedAttributeRow && e.checked) {
      this.enableEdit = true;
    } else {
      this.enableEdit = false;
    }
  }

  public selectAllDuplicateIdentifyingColumn() {
    let e;
    const element = this.metaDataForm.get('attributes') as FormArray;
    if (this.metaDataForm.value.attributes.every(val => val.duplicateIdentifyingColumn === true)) {
      element.controls.forEach( data => {
        data.get('duplicateIdentifyingColumn').patchValue(false);
        e = {checked : false};
        this.checkDuplicateColumns(e, data);
      });
    } else {
      element.controls.forEach(data => {
        data.get('duplicateIdentifyingColumn').patchValue(true);
        e = {checked : true};
        this.checkDuplicateColumns(e, data);
      });
    }
  }

  /**
   *
   * @param e
   * @param ele
   */
  public checkDuplicateColumns(e, ele) {
    if (!e.checked) {
      this.checkedDuplicateCols = this.checkedDuplicateCols.filter((colName) => {
        return colName !== ele.value.attributeName;
      });
    } else {
      this.checkedDuplicateCols.push(ele.value.attributeName);
    }
    this.dataMappingUpsertConfig.duplicateColumns = this.checkedDuplicateCols;
  }

  /**
   *
   * @param e
   */
  public selectAllUpdateColumn() {
    let e;
    const element = this.metaDataForm.get('attributes') as FormArray;
    if (this.metaDataForm.value.attributes.every(val => val.updateColumn === true)) {
      element.controls.forEach( data => {
        data.get('updateColumn').patchValue(false);
        e = {checked : false};
        this.checkUpdateColumns(e, data);
      });
    } else {
      element.controls.forEach(data => {
        data.get('updateColumn').patchValue(true);
        e = {checked : true};
        this.checkUpdateColumns(e, data);
      });
    }
  }

  /**
   *
   * @param e
   * @param ele
   */
  public checkUpdateColumns(e, ele) {
    if (!e.checked) {
      this.checkedUpdateCols = this.checkedUpdateCols.filter((colName) => {
        return colName !== ele.value.attributeName;
      });
    } else {
      this.checkedUpdateCols.push(ele.value.attributeName);
    }
    if (this.dataMappingUpsertConfig.upsertType === 'SCD1' || this.dataMappingUpsertConfig.upsertType === 'SCD3') {
      this.dataMappingUpsertConfig.updateColumns = this.checkedUpdateCols;
    }
  }

  /**
   *
   */
  addAttribute() {
    this.whenCrudCall.emit('addAttribute');
  }

  /**
   *
   */
  editAttribute() {
    this.isReadOnly = false;
  }

  viewDescription() {
    this.showDetails = true;
    this.showDescription = true;
    this.showComment = false;
  }

  viewComment() {
    this.showDetails = true;
    this.showComment = true;
    this.showDescription = false;
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * handles import metadata API errors.
   */
  private handleErrors(): void {
    this.wheneError.emit(true);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

