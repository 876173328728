import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ButtonSize, ButtonStyle } from './button.types';

@Component({
    selector: 'udp-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {

    @Input()
    style: ButtonStyle = 'primary';

    @Input()
    size: ButtonSize = 'default';

    @Input()
    disabled: boolean;

    @Input()
    selected: boolean;

    @Input()
    type: string;

}
