import {
    ActivatedRouteSnapshot,
    CanDeactivate,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { UnsavedChangesService } from './services/unsaved-changes.service';
import { filter, map } from 'rxjs/operators';
import { EditorComponent } from './components/editor/editor.component';

@Injectable()
export class CanDeactivateOnChangesGuard
    implements CanDeactivate<EditorComponent> {

    constructor(
        private unsavedChangesService: UnsavedChangesService
    ) {
    }

    canDeactivate(
        component: EditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {

        return merge(
            this.unsavedChangesService.stateChanged$
                .pipe(
                    filter((changed) => changed),
                    // tslint:disable-next-line:max-line-length
                    map(() => window.confirm('Unsaved changes. Do you want to navigate?')),
                    map((confirmation) => confirmation)
                ),
            this.unsavedChangesService.stateChanged$
                .pipe(
                    filter((changed) => !changed),
                    map(() => true)
                ),
        );
    }
}
