// Modules
import { NgModule } from '@angular/core';
import { ViewDatasetModule } from './view-dataset/view-dataset.module';
import { QueryDatasetModule } from './query-dataset/query-dataset.module';
import { BlendDatasetsModule } from './blend-datasets/blend-datasets.module';
import { CreateDatasetModule } from './create-dataset/create-dataset.module';
import { ImportDatasetModule } from './import-dataset/import-dataset.module';
import { UdpDatasetOperatorModule } from './udp-dataset-operator/udp-dataset-operator.module';
import { UpdMilOperatorModule } from './upd-mil-operator/upd-mil-operator.module';
import { CodeMaptableModule } from './codemaptable/codemaptable.module';
import { ViewReferenceDatasetModule } from './view-reference-dataset/view-reference-dataset.module';
import { SharedModule } from './shared/shared.module';
import { CreateDataAssetModule } from './create-data-asset';
import { UdpDataTransformationOperatorModule } from './udp-data-transformation-operator/udp-data-transformation-operator.module';
/**
 * @constant
 */
const MODULES: any[] = [
  ImportDatasetModule,
  ViewDatasetModule,
  QueryDatasetModule,
  BlendDatasetsModule,
  CreateDatasetModule,
  UdpDatasetOperatorModule,
  UpdMilOperatorModule,
  CodeMaptableModule,
  ViewReferenceDatasetModule,
  UpdMilOperatorModule,
  SharedModule,
  CreateDataAssetModule,
  UdpDataTransformationOperatorModule
];

/**
 * @author: Naga
 * @module
 */
@NgModule({
  imports: [...MODULES],
  exports: [...MODULES]
})
export class DataCatalogModule {}
