import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserIdentity } from './../../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private currentUser$ = new BehaviorSubject<UserIdentity>(null);
    private currentDataSourceId$ = new BehaviorSubject<string>(null);

    /**
     * Set user upon login
     * @param user
     */
    setUser(user: UserIdentity) {
        this.currentUser$.next(user);
        this.currentDataSourceId$.next(user.dafaultDataSource.dataSourceId);
        // this.currentDataSourceId$.next('fcf2bd65-3f30-4b98-b81b-dc47d696443d'); //for testing permissions
    }

    get user(): Observable<UserIdentity> {
        return this.currentUser$.asObservable();
    }

    get userIDSnapshot(): string {
        // const user = this.currentUser$.getValue();
        // return user ? user.userInfo.userID : '';
        return '3cb0c733-21ab-47ca-a057-e7179849053c'; // temporary for other parts of the APP to work right
    }

    get userSnapshot(): UserIdentity {
        return this.currentUser$.getValue();
    }

    get userPermissions(): Observable<string[]> {
        return combineLatest([
            this.currentDataSourceId$,
            this.currentUser$,
        ]).pipe(
            filter(
                ([dataSourceId, user]) =>
                    dataSourceId != null &&
                    user != null &&
                    user.datasouceAccessRights != null &&
                    user.datasouceAccessRights[dataSourceId] != null
            ),
            map(([dataSourceId, user]) =>
                user.datasouceAccessRights[dataSourceId].reduce(
                    (acc, dataSourceAccessRights) => {
                        const functions = dataSourceAccessRights.refUDPSubModules
                            .map(m => m.functions)
                            .reduce((a, val) => a.concat(val), []);
                        acc.push(...functions);
                        return acc;
                    },
                    []
                )
            )
        );
    }

    get currentDataSourceIdSnapshot(): string {
        return this.currentDataSourceId$.getValue();
    }
}
