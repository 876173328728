import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDrawerContent, MatMenuTrigger } from '@angular/material';
import { maximizeDrawer } from '@workflow-editor/components/editor/editor.animation';
import { RedrawService } from '@workflow-editor/gojs/services/redraw.service';
import { SharedDataService } from '../../shared/modules/shared-data/shared-data.service';
import { UPDMilOperatorService } from '../../upd-mil-operator/udp-mil-operator.service';
import { environment } from 'src/environments/environment';
import { SchemaExplorerService } from 'libs/schema-explorer/src/lib/schema-explorer/schema-explorer.service';
import {
  getTypeMapping,
  unsubscribeCollection
} from 'src/app/shared/utils/common-utilities';
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';
@Component({
  selector: 'lib-query-schema',
  templateUrl: './query-schema.component.html',
  styleUrls: ['./query-schema.component.scss'],
  animations: [maximizeDrawer]
})
export class QuerySchemaComponent implements OnInit {
  @Input() selectedDataset: any;
  @Input() datasetSchema: any;

  typeMap: any = getTypeMapping();

  private subscriptions: Subscription[] = [];
  @ViewChild('content', { static: true })
  private content: MatDrawerContent;
  public entities: any;
  mappingConfig: any;
  public drawerOpen: any = {
    start: true,
    end: false
  };
  // menuTopLeftPosition =  {x: 0, y: 0}
  menuData: any;
  // @ViewChild(MatMenuTrigger, {static: true}) contextMenu: MatMenuTrigger;
  public propertiesEditorMaximized = false;

  items = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' }
  ];

  @ViewChild(MatMenuTrigger, { static: true })
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };
  constructor(
    private redrawService: RedrawService,
    private sharedService: SharedDataService,
    private udpMilOperatorService: UPDMilOperatorService,
    private cdr: ChangeDetectorRef,
    private schemaExplorerService: SchemaExplorerService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit() {
    if (
      (this.selectedDataset &&
        this.selectedDataset.dataSourceType ===
          DATASOURCE.INTERNAL_DATA_SOURCE_CODE) ||
      this.selectedDataset.datasetDataSourceType ===
        DATASOURCE.INTERNAL_DATA_SOURCE_CODE ||
      this.selectedDataset.datasetDataSourceType === DATASOURCE.QUERY_CODE
    ) {
      this.getEntityGroups();
    } else {
      this.entities = [];
      if (this.datasetSchema && this.datasetSchema.attribute) {
        this.datasetSchema.attribute.forEach((attr) => {
          attr.foreignKey = attr.isForeignKey;
          delete attr.isForeignKey;
          attr.dataType = attr.type;
          delete attr.type;
        });
        this.datasetSchema.attributes = this.datasetSchema.attribute;
        delete this.datasetSchema.attribute;
      }
      if (this.datasetSchema) {
        this.entities.push(this.datasetSchema);
      }
    }
  }

  /**
   *
   */
  private getEntityGroups() {
    this.subscriptions[this.subscriptions.length] = this.udpMilOperatorService
      .getEntityGroupList(
        this.selectedDataset.datasetDataSource.database.customerDataSourceID,
        this.selectedDataset.datasetDataSource.database.dataSourceCategory
      )
      .subscribe((entityGroupList: any) => {
        if (entityGroupList && entityGroupList.length > 0) {
          this.entities = entityGroupList;
          this.mappingConfig = {
            baseApiUrl: environment.baseApiUrl,
            mode: 'view',
            context: 'data-mapping',
            currentEntityGroupId:
              this.udpMilOperatorService.getCurrentEntityGroupId(),
            type: DATASOURCE.QUERY
          };
        }
        this.cdr.detectChanges();
      });
  }

  callOnCardExpand(item) {
    if (!item.attributes) {
      if (
        (this.selectedDataset &&
          this.selectedDataset.dataSourceType ===
            DATASOURCE.INTERNAL_DATA_SOURCE_CODE) ||
        this.selectedDataset.datasetDataSourceType ===
          DATASOURCE.INTERNAL_DATA_SOURCE_CODE
      ) {
        const currentEntityGroupID =
          this.udpMilOperatorService.getCurrentEntityGroupId();
        this.subscriptions[this.subscriptions.length] =
          this.schemaExplorerService
            .getEntity(
              environment.baseApiUrl,
              item.entityID,
              currentEntityGroupID
            )
            .subscribe((entity) => {
              if (entity && entity.attributes && entity.attributes.length > 0) {
                item.attributes = entity.attributes;
                item.expanded = !item.expanded;
                this.cdr.detectChanges();
              }
            });
      } else {
        item.expanded = !item.expanded;
        this.cdr.detectChanges();
      }
    } else {
      item.expanded = !item.expanded;
      this.cdr.detectChanges();
    }
  }
  setDrawerOpen(position: string, next: boolean) {
    this.drawerOpen[position] = next;
    this.redrawService.redraw();
  }
  onMaximizeDone() {
    this.content ? this.content._container.updateContentMargins() : '';
    this.redrawService.redraw();
  }

  onPropertiesEditorMaximized() {
    this.propertiesEditorMaximized = !this.propertiesEditorMaximized;
  }

  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  selectQuery(item: any) {
    const attribute = [];
    if (item.attributes && item.attributes.length > 0) {
      item.attributes.forEach((data) => {
        attribute.push(data.attributeName);
      });
    } else {
      this.errorHandlingService.errorSuccessSnack('151', false);
      return;
    }
    const str = '"' + attribute.join('", "') + '"';
    if (
      this.selectedDataset.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE ||
      this.selectedDataset.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE
    ) {
      let tableAlias =
        item.entityName.substring(0, 2).toLowerCase() +
        item.entityName.slice(-3).toLowerCase();
      const qryScript = attribute.map((attr) => `"${tableAlias}"."${attr}"`);
      this.sharedService.querySchema.next(
        `Select ${qryScript} from "${this.udpMilOperatorService.getCurrentEntityGroupName()}"."${
          item.entityName
        }" as "${tableAlias}"`
      );
    } else {
      this.sharedService.querySchema.next(
        `Select ${str} from "${item.entityName}"`
      );
    }
  }

  copyAllCloumnName(item: any) {
    const attribute = [];
    if (item.attributes.length > 0) {
      item.attributes.forEach((data) => {
        attribute.push(data.attributeName);
      });
    }
    const str = '"' + attribute.join('", "') + '"';
    this.sharedService.querySchema.next(str);
  }

  copyTableName(item: any) {
    this.sharedService.querySchema.next(`"${item.entityName}"`);
  }

  copyColumnName(item: any) {
    this.sharedService.querySchema.next(`"${item.attributeName}"`);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
}
