import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'udp-radio-input',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadioGroupComponent implements OnInit {

  constructor() { }

    @Input()
    control: FormControl = new FormControl();

    @Input()
    tooltipMessage: string;

    @Input()
    checkedRadio1 = false;

    @Input()
    checkedRadio2 = false;

    @Input()
    labelRadio1: string;

    @Input()
    labelRadio2: string;

  ngOnInit() {

  }

}
