import { CommonModule } from '@angular/common';
import { InterceptorService } from './interceptor.service';
import { LayoutsModule } from '../layouts/layouts.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule
    , LayoutsModule
    , HttpClientModule
  ]
})
export class InterceptorModule {
  /**
   * @static
   */
  static forRoot(): ModuleWithProviders<NgModule> {
    return {
      ngModule: InterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptorService,
          multi: true
        }
      ]
    };
  }

  /**
   * @constructor
   */
  constructor(@Optional() @SkipSelf() parentModule: InterceptorModule) {}
}
