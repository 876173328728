import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@xfusiontech/shared';
import { HeaderComponent } from './header.component';
import { HeaderUserComponent } from './components/header-user/header-user.component';
import { UserInitialsPipe } from './components/header-user/user-initials.pipe';

@NgModule({
    declarations: [HeaderComponent, HeaderUserComponent, UserInitialsPipe],
    imports: [CommonModule, ButtonModule],
    exports: [HeaderComponent],
})
export class HeaderModule {}
