import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'xft-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
    @Input()
    disabled: boolean;

    @Input()
    secondary: boolean;

    @Input()
    isLoading: boolean;

    @Output()
    buttonClick = new EventEmitter();
}
