import { NgModule } from '@angular/core';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { DonutChartComponent } from './donut-chart.component';
import { CommonModule } from '@angular/common';

const imports = [
    CommonModule,
    ChartsModule
];

const components = [
    DonutChartComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class DonutChartModule { }
