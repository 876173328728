import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarMessageComponent, SnackbarMessageType } from './component/snackbar-message.component';

export type MessageType = 'error' | 'warning' | 'info' | 'success';

@Injectable()
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openMessage(
    message: string,
    messageType: SnackbarMessageType = 'info',
    options?: Partial<MatSnackBarConfig>
  ) {
    const { instance } = this.snackBar.openFromComponent(
      SnackbarMessageComponent,
      {
        duration: 3000,
        ...options,
        data: {
          iconType: messageType === 'success' ? 'material' : 'feather'
        },
        panelClass: `xft-snackbar-${messageType}`
      }
    );

    instance.message = message;
    instance.type = messageType;
  }

  /**
   * @public
   * @param: {msg<string>}
   * @param: {msgType<any>}
   * @return: void
   * @description: a helper method that
   * notifies with a message on the screen.
   */
  public notify(msg: string, msgType: any): void {
    this.openMessage(msg, msgType, {
      verticalPosition: 'top'
    });
  }
}
