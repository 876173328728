import * as go from 'gojs';
import * as _ from 'lodash/fp';

export const opacityBinding = (scrollingPanelName: string) =>
    new go.Binding('opacity')
        .ofObject(scrollingPanelName);

export const heightBinding = (
    maxHeight: number,
    scrollingPanelName: string
) => new go.Binding(
    'height',
    '',
    ({ visible, actualBounds: { height } }) => {
        if (!visible) {
            return 0;
        }

        return _.clamp(0, maxHeight)(height);
    },
).ofObject(scrollingPanelName);

export const scrollbarVisibilityBinding = (
    maxHeight: number,
    scrollingPanelName: string
) => new go.Binding(
    'visible',
    '',
    ({ visible, actualBounds: { height } }) =>
        !visible || height > maxHeight,
).ofObject(scrollingPanelName);

export const scrollbarThumbHeightBinding = (
    maxHeight: number,
    scrollingPanelName: string
) => new go.Binding(
    'height',
    '',
    ({ actualBounds: { height } }) => (maxHeight / height) * maxHeight
).ofObject(scrollingPanelName);

export const scrollbarThumbAlignmentBinding = (
    maxHeight: number,
    scrollingPanelName: string,
) => new go.Binding(
    'alignment',
    '',
    ({ alignment, actualBounds: { height } }) => {
        if (height === 0 || _.isNaN(height)) {
            return go.Spot.TopLeft;
        }

        return new go.Spot(
            0,
            0,
            0,
            (-alignment.offsetY) * (maxHeight / height),
        );
    },
).ofObject(scrollingPanelName);
