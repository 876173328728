import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  DataProcessingWorkflow,
  DataStudioEventsService,
  DiagramModel
} from '@xfusiontech/data-studio';
import * as _ from 'lodash';

import { DATASOURCE } from 'src/app/shared/const/datasource.const';
import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { DataCatalogService } from '../data-catalog.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { TransformationOperatorEventHelperService } from './transformation-operator-event-helper.service';

import {
  guidGenerator,
  unsubscribeCollection
} from 'src/app/shared/utils/common-utilities';


@Component({
  selector: 'lib-transformation-operator-event',
  templateUrl: './transformation-operator-event.component.html',
  styleUrls: ['./transformation-operator-event.component.css']
})
export class TransformationOperatorEventComponent implements OnInit, OnDestroy {
  @Input() currentTaskID: string;
  @Input() previousTaskIDs: any; // If Transformation Operator is connected to an operator
  @Input() currentFileConfig: any;
  @Input() currentOperatorCode: any;
  @Input() customerId: string;
  @Input() userId: string;
  @Input() workflowDef: string;
  @Input() nodes: string;

  dataSetOperatorConfig;
  dataBlendOperatorConfig;
  dataSetOperatorConfigColumnList;

  @Output() hideDataStudio: EventEmitter<any> = new EventEmitter();

  @Input()
  public dataAssetType: string;

  @Input()
  public dataAssetDetails: any = {};

  private destroy: Subject<any> = new Subject();

  dataSourceID: string;
  private subscriptions: Subscription[] = [];

  workflows: any[] = [];
  selectedWorkflow: DataProcessingWorkflow;
  workFlowDetails: any = undefined;
  selectedProcessingDiagramModel: DiagramModel = {
    nodes: [],
    links: []
  };
  dataAssetID: any;
  selectedWorkFlowID: any = '';
  selectedWorkFlow: any = undefined;

  constructor(
    private dataCatalogService: DataCatalogService,
    private dataStudioEventsService: DataStudioEventsService,
    private cdr: ChangeDetectorRef,
    private _udpTaskConfigService: UdpTaskConfigService,
    private transformationOperatorEventHelperService: TransformationOperatorEventHelperService
  ) {}

  ngOnInit() {
    this.initializeDataStudio();
    this.getPreviousTaskData();
    this.executeAllWorkflowAndTaskEvents();
  }

  private executeAllWorkflowAndTaskEvents() {
    this.getWorkflowSelected();
    this.getWorkflowCreated();
  }

  private getPreviousTaskData() {
    if (this.previousTaskIDs[0].includes(miscellaneousConst.operators.DS.FILENAME)) {
      this.getDataSetPreviousTask();
    } else if (this.previousTaskIDs[0].includes(miscellaneousConst.operators.DB.FILENAME)) {
      this.getDataBlendPreviousTaskData();
    }
  }

  private getDataSetPreviousTask() {
    this.dataSetOperatorConfig = this._udpTaskConfigService.getConfig(
      this.previousTaskIDs[0]
    ).contents;
    if (
      (this.dataSetOperatorConfig &&
        this.dataSetOperatorConfig.datasetDataSourceType === DATASOURCE.QUERY_CODE) ||
      this.dataSetOperatorConfig.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE
    ) {
      if (this.dataSetOperatorConfig.datasetSchema) {
        this.dataSetOperatorConfigColumnList =
          this.dataSetOperatorConfig.datasetSchema.attribute;
        this.transformationOperatorEventHelperService.setPreviousTaskSchemaForDTOperator(
          this.dataSetOperatorConfig.datasetSchema
        );
        this.getExistingDataAndSetWorkflows();
        return;
      }
    }
    if (
      this.dataSetOperatorConfig &&
      this.dataSetOperatorConfig.datasetDataSourceType &&
      this.dataSetOperatorConfig.datasetDataSource.dataAsset &&
      this.dataSetOperatorConfig.datasetDataSource.dataAsset.sourceDataAssetID
    ) {
      this.dataSourceID =
        this.dataSetOperatorConfig.datasetDataSource.dataAsset.sourceDataAssetID.trim();
      this.dataAssetType = this.dataSetOperatorConfig.datasetDataSourceType;
      this.dataAssetID = this.dataSourceID;

      this.dataSetOperatorConfigColumnList =
        this.dataSetOperatorConfig.datasetSchema.attribute;
      this.transformationOperatorEventHelperService.setPreviousTaskSchemaForDTOperator(
        this.dataSetOperatorConfig.datasetSchema
      );
      this.getAllWorkFlow();
    }
  }

  private getDataBlendPreviousTaskData():any {
    this.dataBlendOperatorConfig = this._udpTaskConfigService.getConfig(
      this.previousTaskIDs[0]
    ).contents;

    if (this.dataBlendOperatorConfig) {
      this.dataSetOperatorConfigColumnList =
        this.transformationOperatorEventHelperService.getDataBlendOperatorAttributes(
          this.dataBlendOperatorConfig
        );
      if (
        this.dataBlendOperatorConfig.metadata &&
        this.dataBlendOperatorConfig.metadata.dataSetSchema
      ) {
        this.transformationOperatorEventHelperService.setPreviousTaskSchemaForDTOperator(
          this.dataSetOperatorConfigColumnList
        );
      }
      this.dataSetOperatorConfigColumnList = _.cloneDeep(this.dataSetOperatorConfigColumnList.attribute);
      this.getExistingDataAndSetWorkflows();
    }
  }

  private initializeDataStudio() {
    this.selectedWorkFlowID = '';
    this.selectedProcessingDiagramModel = _.cloneDeep({
      nodes: [],
      links: []
    });
  }

  onCloseClick() {}

  private resetVariables() {
    this.selectedWorkFlowID = '';
    this.selectedWorkFlow = undefined;
    this.workFlowDetails = undefined;
    this.workflows = [];
    this.selectedWorkFlowID = '';
    this.selectedProcessingDiagramModel = {
      nodes: [],
      links: []
    };
  }

  private getWorkflowSelected() {
    this.subscriptions[this.subscriptions.length] =
      this.dataStudioEventsService.workflowSelected$.subscribe((event) => {
        if (!event) {
          return;
        }
        if (
          this.selectedWorkFlowID !== '' &&
          this.selectedWorkFlowID === event.workflowId
        ) {
          return;
        }
        this.updateProcessingDiagramModel(event);
      });
  }

  private updateProcessingDiagramModel(event) {
    this.workFlowDetails = this.workflows.find((x) => x.id == event.workflowId);

    this.selectedWorkFlowID = event.workflowId;
    if (this.workFlowDetails) {
      if (
        this.workFlowDetails &&
        this.workFlowDetails.dataAssetWorkflowDefinition
      ) {
        const savedModel = this.workFlowDetails.dataAssetWorkflowDefinition;
        const next = savedModel || {
          nodes: []
        };
        this.selectedProcessingDiagramModel = _.cloneDeep(next);
        this.cdr.detectChanges();
      }
    }
    const { workflowId } = event;
    this.selectedWorkflow = this.workflows.find(({ id }) => id === workflowId);
  }

  /**
   *
   */
  private getWorkflowCreated() {
    this.subscriptions[this.subscriptions.length] =
      this.dataStudioEventsService.workflowCreated$.subscribe((event) => {
        this.createWorkFlow(event);
      });
  }

  private createWorkFlow(data) {
    const guid = guidGenerator();
    if (data) {
      this.workflows.push({
        name: data.name.workflowName,
        id: guid,
        dataPreparationWorkflowName: data.name.workflowName,
        dataAssetID: this.dataAssetID,
        dataAssetType: this.dataAssetType,
        description: data.name.workflowDescription,
        dataAssetWorkflowDefinition:
          this.transformationOperatorEventHelperService.blankWorkflowDefination
      });
      this.selectedWorkFlowID = guid;
      this.selectedProcessingDiagramModel =
        this.transformationOperatorEventHelperService.blankWorkflowDefination;
      this.cdr.detectChanges();
    }
  }

  private getAllWorkFlow() {
    this.subscriptions[this.subscriptions.length] = this.dataCatalogService
      .getAllWorkflowsList(
        this.customerId,
        this.dataAssetID.trim(),
        this.dataAssetType,
        'ACTIVE'
      )
      .subscribe((res: any) => {
        if (res) {
          this.workflows =
            this.transformationOperatorEventHelperService.getWorkflowsListUsingRes(
              res
            );
          this.getExistingDataAndSetWorkflows();
          this.cdr.detectChanges();
        }
      });
  }

  private getExistingDataAndSetWorkflows() {
    const existingData =
      this.transformationOperatorEventHelperService.checkIfConfigIsAlreadyAvailable(
        this.currentFileConfig
      );
    if (existingData) {
      if (existingData.dataPreparationWorkflowID) {
        const isExistInWorkflow = this.workflows.find(
          (x) => x.id === existingData.dataPreparationWorkflowID
        );
        if (!isExistInWorkflow) {
          this.workflows.push(
            this.transformationOperatorEventHelperService.addWorkflow(
              existingData
            )
          );
        }
        this.selectedWorkFlowID = existingData.dataPreparationWorkflowID
          ? existingData.dataPreparationWorkflowID
          : null;
        this.cdr.detectChanges();
      }
      if (existingData.dataTransformationRecipe) {
        this.selectedProcessingDiagramModel =
          existingData.dataTransformationRecipe;
      }
    } else {
      this.selectedWorkFlowID = '';
      this.selectedProcessingDiagramModel =
        this.transformationOperatorEventHelperService.blankWorkflowDefination;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
    this.resetVariables();
    this.destroy.next();
  }
}
