import { Injectable } from '@angular/core';
import * as _ from 'lodash/fp';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { Workflow } from '../types/workflow';

import { WorkflowDefinition } from '../types/workflowDefinition';
import { mapWorkflowDbEntityToWorkflow } from '../utils/workflowEntityMappers';
import { createEmptyWorkflow } from './mocks/createWorkflowMock';

import { ApiService } from './api.service';
import { ModelSaveService } from '../gojs/services/model-save.service';
import { MessagesService } from './messages.service';
import { ModelChangesService } from '../gojs/services/model-changes.service';
import { SelectionService } from '../gojs/services/selection.service';
import { ZoomService } from '../gojs/services/zoom.service';
import { UdpTaskConfigService } from './udp-task-config.service';

import { UrlParameters } from '../types/url';

const parseWorkflow =
    _.flowRight(
        mapWorkflowDbEntityToWorkflow,
        _.head
    );

@Injectable()
export class WorkflowService {
    public workflowId: any;

    constructor(
        private apiService: ApiService,
        private modelSaveService: ModelSaveService,
        private modelChangesService: ModelChangesService,
        private messagesService: MessagesService,
        private selectionService: SelectionService,
        private zoomService: ZoomService,
        private _udpTaskConfigService: UdpTaskConfigService
    ) { }

    private workflow: BehaviorSubject<Workflow>
        = new BehaviorSubject(createEmptyWorkflow());

    getFilesContent(branchFiles) {
        const payload = {
            items: branchFiles
        };
        return this.apiService.getMultiFilesContent(payload);
    }

    getWorkflow(): Observable<Workflow> {
        return this.workflow
            .pipe(
                filter(_.identity)
            );
    }

    getWorkflowDefinition(): Observable<WorkflowDefinition> {
        return this.getWorkflow()
            .pipe(
                map((workflow) => workflow.WorkflowDefination)
            );
    }

    getCurrentNodeID() {
        const workflow = this.workflow.getValue();
        return workflow.NodeID;
    }

    getCurrentWorkflowScript() {
        const worklfow = this.workflow.getValue();
        return worklfow.WorkflowScript;
    }

    setWorkflowScript(nextScript: string) {
        const nextWorklfow = {
            ...this.workflow.getValue(),
            WorkflowScript: nextScript
        };
        this.workflow.next(nextWorklfow);
    }

    /** method added to pass workflowname to the json -- Piyush     */
    setWorkFlowName(WorkflowName) {
        const workflow = this.workflow.getValue();
        workflow.WorkflowDefination.dag.name = WorkflowName || '';

        const nextWorklfow = {
            ...workflow,
            WorkflowName,
        };
        this.workflow.next(nextWorklfow);
    }

    /**
     * Set parameters array value in Properties textbox.
     * @param keys Default value keys array.
     */
    setDagParametersValue(keys) {
        const workflow = this.workflow.getValue();
        if (workflow && workflow.WorkflowDefination) {
            const parms = workflow.WorkflowDefination.dag.parameters || [];

            (keys || []).forEach(item => {
                const match = parms.find(p => p.id === item.key);
                if (match) {
                    match.value = item.value;
                }
            });
            const nextWorklfow = {
                ...workflow,
            };
            this.workflow.next(nextWorklfow);
        }
    }

    fetchWorkflow(): void {
        const workflowDefinition = this._udpTaskConfigService.getConfig(miscellaneousConst.DAG.FILENAME);
        const nextWorklfow = {
            ...this.workflow.getValue(),
            WorkflowDefination: workflowDefinition
        };
        this.workflow.next(nextWorklfow);
        this.selectionService.refreshSelection();
        this.zoomService.zoomToFit();
        if (workflowDefinition) {
            this._udpTaskConfigService.saveConfig.items.jsonEditor = workflowDefinition;
        }
    }

    updateTimeStampConfigJson(parameters) {
        const timeStampConfig: any[] = this._udpTaskConfigService.getConfig(miscellaneousConst.operators.TIMESTAMP.FILENAME);
        if (timeStampConfig && timeStampConfig.length > 0) {
            this._udpTaskConfigService.saveConfig.items.timeStampConfig = {
                filename: miscellaneousConst.operators.TIMESTAMP.FILENAME, // timestamp file
                contents: timeStampConfig,
                current_version: parameters.version
            };
        }
    }

    fetchWorkflowPython(routeParams: UrlParameters): void {
        const workflowScript = this._udpTaskConfigService.getConfig(`generatedDAG/${routeParams.nodeId}.py`);
        const nextWorklfow = {
            ...this.workflow.getValue(),
            WorkflowScript: workflowScript
        };
        this.workflow.next(nextWorklfow);
        this.setNodeID(
            routeParams.nodeId,
            nextWorklfow.WorkflowDefination);
    }

    async saveWorkflow() {
        const workflowDefinition =
            this.modelSaveService.getWorkflowDefinition();

        return (await this.apiService.upsertWorkflowToGithub(workflowDefinition)).pipe(
            tap(
                (result: string) => {
                    this.setNodeID(result, workflowDefinition);
                    this.messagesService.openMessage('Workflow saved');
                    this.modelChangesService.setUnmodified();
                },
                (error) => {
                    console.error(error);
                    this.messagesService.openMessage(
                        'Failed to save the workflow',
                        'error'
                    );
                }
            )
        );
    }

    private async setNodeID(
        nodeId: string,
        workflowDefinition: WorkflowDefinition
    ) {
        const current = this.workflow.getValue();
        if (!current.NodeID) {
            this.workflow.next({
                ...current,
                WorkflowDefination: workflowDefinition,
                NodeID: nodeId
            });
            // await this.router.navigate(
            //     [WORKFLOW_EDITOR_PATH, nodeId],
            //     {
            //         relativeTo: this.route,
            //         replaceUrl: true
            //     }
            // );
            this.modelChangesService.setUnmodified();
        }
    }
}
