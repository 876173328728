import { Component, Input } from '@angular/core';
import { IconVariant } from '../../../types/icons';
import { MenuType } from '../menu.types';

@Component({
    selector: 'udp-menu-icon-item',
    templateUrl: './menu-icon-item.component.html',
    styleUrls: ['./menu-icon-item.component.scss']
})
export class MenuIconItemComponent {

    @Input()
    path: string;

    @Input()
    variant: IconVariant = 'material';

    @Input()
    label: string;

    @Input()
    type: MenuType = 'primary';

}
