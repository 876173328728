import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ConfirmDialogParams {
    title: string;
    message: string;
    buttonCancel?: string;
    buttonOK: string;
}

@Component({
    selector: 'igv-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public params: ConfirmDialogParams,
        private dialogRef: MatDialogRef<ConfirmDialogComponent>
    ) {}

    onSubmitClick() {
        this.dialogRef.close(true);
    }
}
