import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Query } from '@xfusiontech/data-visualizer';
import { Observable } from 'rxjs';
import { QueryVariableType } from './../../models/query-variable.model';
import { QueryVariablesDialogComponent } from './dialog/query-variables-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class QueryVariablesDialogService {
    constructor(private dialog: MatDialog) {}

    show(data: {
        query: Query;
        variableTypes$: Observable<QueryVariableType[]>;
    }): MatDialogRef<QueryVariablesDialogComponent, any> {
        return this.dialog.open(QueryVariablesDialogComponent, { data });
    }
}
