import { Component, Input, HostBinding } from '@angular/core';
import { GridLayoutCellSize } from '../grid-layout.types';

@Component({
    selector: 'udp-grid-layout-cell',
    templateUrl: './grid-layout-cell.component.html',
    styleUrls: ['./grid-layout-cell.component.scss']
})
export class GridLayoutCell {

    @Input()
    set size(size: GridLayoutCellSize) {
        this._size = size;
        this.flexBasis = `calc(${this._size * 100 / 12}% - 24px)`;
    }
    get size(): GridLayoutCellSize {
        return this._size;
    }
    private _size: GridLayoutCellSize = 12;

    @HostBinding('style.flexBasis')
    @HostBinding('style.maxWidth')
    flexBasis = 'calc(100% - 24px)';

}
