import { Injectable } from '@angular/core';
import * as _ from 'lodash';

interface Attribute {
  attribute: any;
}

@Injectable()
export class TransformationOperatorEventHelperService {
  blankWorkflowDefination = {
    nodes: [],
    links: []
  };
  private previousTaskSchemaForDTOperator;

  constructor() {}

  checkIfConfigIsAlreadyAvailable(config) {
    if (config && Object.keys(config.contents).length > 0) {
      return config.contents;
    }
    return null;
  }

  public setPreviousTaskSchemaForDTOperator(schema) {
    this.previousTaskSchemaForDTOperator = schema;
  }

  public getPreviousTaskSchemaForDTOperator() {
    return this.previousTaskSchemaForDTOperator;
  }

  addWorkflow(existingData: any) {
    return {
      id: existingData.dataPreparationWorkflowID || null,
      name: existingData.dataPreparationWorkflowName || null,
      dataPreparationWorkflowName:
        existingData.dataPreparationWorkflowName || null,
      dataAssetID: existingData.dataAssetID || null,
      dataAssetType: existingData.dataAssetType || null,
      description: existingData.description || null,
      dataAssetWorkflowDefinition: existingData.dataTransformationRecipe || {
        nodes: [],
        links: []
      }
    };
  }

  getWorkflowsListUsingRes(res) {
    if (res && res.data) {
      const allWorkflows = res.data.map((v) => ({
        ...v,
        id: v.dataAssetWorkflowID,
        name: v.dataPreparationWorkflowName,
        createdBy: v.createUser,
        createdAt: v.createDate,
        meta: v.dataPreparationWorkflowDescription
      }));

      return allWorkflows.sort((a: any, b: any) => {
        const c: any = new Date(a.createDate);
        const d: any = new Date(b.createDate);
        return d - c;
      });
    } else {
      return [];
    }
  }

  getDataBlendOperatorAttributes(dataBlendOperatorConfig) {
    if (
      dataBlendOperatorConfig &&
      dataBlendOperatorConfig.metadata &&
      dataBlendOperatorConfig.metadata.dataSetSchema
    ) {
      return this.removeDataSetSchemaFormatForDataBlend(dataBlendOperatorConfig.metadata.dataSetSchema)
    } else {
      return [];
    }
  }

  private removeDataSetSchemaFormatForDataBlend(dataset) {
    const keys = Object.keys(dataset);
    let finalDataSet = {};
    keys.forEach(key => {
      dataset[key].entityPhysicalName = dataset[key].entityName = (dataset[key].entityName).slice(2);
      dataset[key].attribute.forEach(item => {
        item.attributeName = item.attributeName.slice(2);
      });
      finalDataSet = finalDataSet ? { ...finalDataSet, ...{ [dataset[key].entityName]: dataset[key] } }
        : { [dataset[key].entityName]: dataset[key] };
    });

    return this.flattenDatablendConfigForBlendByColumn(finalDataSet);
  }

  private flattenDatablendConfigForBlendByColumn(dataset) {
    const keys = Object.keys(dataset);

    const attrArray = [];
    const entities = [];

    for (const key of keys) {
      for (const attr of dataset[key].attribute) {
        attrArray.push(attr);
      }
      entities.push(key);
    }

    const entityName = entities.join(' + ');

    const unique = [...new Map(attrArray.map((m) => [m.attributeName, m])).values()];

    return {
      entityName,
      entityPhysicalName: entityName,
      entityDescription: 'This is where the description lives',
      entityType: entityName,
      entityClassType: '',
      entityLinkandLoadPolicy: '',
      entityObjectUniqueIDFormat: '',
      attribute: unique
    };
  }
}
