import {
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { QueryVariable } from '@xfusiontech/data-visualizer';
import { QueryTextEditorVariableComponent } from './query-text-editor-variable/query-text-editor-variable.component';

@Directive({
    selector: '[igvQueryTextEditorTooltip]',
})
export class QueryTextEditorTooltipDirective implements OnChanges {
    @Input('igvQueryTextEditorTooltip')
    text: string;

    @Input('igvQueryTextEditorTooltipVariables')
    variables: QueryVariable[];

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private vc: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnChanges() {
        this.renderer.setProperty(this.element.nativeElement, 'innerHTML', '');
        this.vc.clear();

        if (!this.text) {
            return '';
        }

        this.insertComponents(this.text);
    }

    insertComponents(text) {
        text.split(/\n/g).forEach((line, i, array) => {
            (line.split(/{{(.*?)}}/g) || []).forEach((literal, idx) =>
                idx % 2 === 1
                    ? this.loadComponent(literal)
                    : this.appendText(literal)
            );

            if (array.length - 1 !== i) {
                this.appendNewLine();
            }
        });
    }

    loadComponent(variable: string) {
        const host = this.element.nativeElement;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            QueryTextEditorVariableComponent
        );

        const viewContainerRef = this.vc;

        const componentRef = viewContainerRef.createComponent<
            QueryTextEditorVariableComponent
        >(componentFactory);
        componentRef.instance.variable = `{{${variable}}}`;
        componentRef.instance.variableDefinition = (this.variables || []).find(
            x =>
                x.queryVariableName.trim().toLocaleLowerCase() ===
                variable.trim().toLocaleLowerCase()
        );
        host.appendChild(componentRef.location.nativeElement);
    }

    appendText(text: string) {
        const plainText = this.renderer.createText(text);
        this.element.nativeElement.appendChild(plainText);
    }

    appendNewLine() {
        const br = this.renderer.createElement('br');
        this.element.nativeElement.appendChild(br);
    }
}
