import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'udp-diff-indicator',
    templateUrl: './diff-indicator.component.html',
    styleUrls: ['./diff-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DiffIndicatorComponent {

    @Input()
    value: number;

}
