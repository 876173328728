export const DATA_ASSET_TYP = [
    {
      "id": 1,
      "code": "VIZ",
      "name": "Visualization"
    },
    {
      "id": 2,
      "code": "DSRC",
      "name": "Data Source"
    },
    {
      "id": 3,
      "code": "QRY",
      "name": "Query"
    },
    {
      "id": 4,
      "code": "DS",
      "name": "Dataset"
    },
    {
      "id": 5,
      "code": "DP",
      "name": "Data Pipeline"
    },
    {
      "id": 6,
      "code": "DF",
      "name": "Data File"
    },
    {
      "id": 7,
      "code": "NB",
      "name": "Notebook"
    }
  ];