import * as _ from 'lodash/fp';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { WidgetLibrary } from '../select-cell-type-dialog.types';
import { SelectCellTypeDialogService } from '../select-cell-type-dialog.service';

@Component({
    selector: 'udp-select-type',
    templateUrl: './select-type.component.html',
    styleUrls: ['./select-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTypeComponent {

    @Output()
    widgetCategorySelect: EventEmitter<string> = new EventEmitter();

    @Output()
    nextClick: EventEmitter<void> = new EventEmitter();

    @Output()
    saveClick: EventEmitter<void> = new EventEmitter();

    @Output()
    modelChange: EventEmitter<any> = new EventEmitter();

    widgetGroups: WidgetLibrary;
    person360Widgets: WidgetLibrary;

    @Input()
    type: string;

    homedashboard: boolean = false;
    person360: boolean = false;
    glossaryDashboard: Boolean = false;

    selectedWidgetGroupIdx: number;
    selectedWidgetIdx: number;

    get isAnyWidgetSelected(): boolean {
        return !_.isNil(this.selectedWidgetIdx);
    }

    constructor(
        dialogService: SelectCellTypeDialogService,
        private changeDetector: ChangeDetectorRef
    ) {
      console.log('selected widget',this.type);
        // this.widgetGroups = dialogService.getWidgetLibrary();
        // this.person360Widgets = dialogService.getPerson360WidgetLibrary();
        this.type = dialogService.getType();
        if(this.type === "homeDashboard") {
            this.homedashboard = true;
            this.person360 = false;
            this.glossaryDashboard = false;
            //this.widgetGroups = dialogService.getPerson360WidgetLibrary();
            this.widgetGroups = dialogService.getWidgetLibrary();
        }
        if(this.type === "person360Dashboard"){
            this.homedashboard = false;
            this.person360 = true;
            this.glossaryDashboard = false;
            //this.widgetGroups = dialogService.getWidgetLibrary();
            this.widgetGroups = dialogService.getPerson360WidgetLibrary();
        }
        if(this.type === "glossaryDashboard"){
            this.homedashboard = false;
            this.person360 = false;
            this.glossaryDashboard = true;
            //this.widgetGroups = dialogService.getWidgetLibrary();
            this.widgetGroups = dialogService.getBusinessGlossaryLibrary();
        }
    }

    selectWidget(groupIdx: number, widgetIdx: number) {
        this.selectedWidgetGroupIdx = groupIdx;
        this.selectedWidgetIdx = widgetIdx;

        this.emitSelectEvent();
        this.changeDetector.markForCheck();
    }

    isWidgetSelected(groupIdx: number, widgetIdx: number) {
        return groupIdx === this.selectedWidgetGroupIdx
            && widgetIdx === this.selectedWidgetIdx;
    }

    private emitSelectEvent() {
        const selectedGroup = this.widgetGroups[this.selectedWidgetGroupIdx];
        const selectedWidget = selectedGroup.elements[this.selectedWidgetIdx];
        this.widgetCategorySelect.emit(selectedWidget.category);
    }

}
