import { Pipe, PipeTransform } from '@angular/core';
import { DataAssetCategory } from '@xfusiontech/data-visualizer';
import { Option } from '@xfusiontech/shared';

@Pipe({ name: 'categoriesToSelectOptions' })
export class CategoriesToSelectOptionsPipe implements PipeTransform {
    transform(value: DataAssetCategory[]): Option[] {
        return value.map(category => ({
            name: category.dataAssetCategoryName,
            value: category.dataAssetCategoryID,
        }));
    }
}
