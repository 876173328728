import { MenuItemModel, PERMISSIONS } from '@xfusiontech/shared';
import {
    ADMINISTRATION_PATH,
    ALERTS_PATH,
    DASHBOARD_PATH,
    DATA_IMPORT_PATH,
    QUERY_EDITOR_PATH,
    RECOMMENDATIONS_PATH,
} from './paths';

export const MENU_ITEMS: MenuItemModel[] = [
    {
        label: 'dashboard',
        path: DASHBOARD_PATH,
    },
    {
        label: 'data import',
        path: DATA_IMPORT_PATH,
    },
    {
        label: 'query editor',
        path: QUERY_EDITOR_PATH,
    },
    {
        label: 'administration',
        path: ADMINISTRATION_PATH,
        permissions: Object.values(PERMISSIONS.ADM),
    },
    {
        label: 'recommendations',
        path: RECOMMENDATIONS_PATH,
    },
    {
        label: 'alerts',
        path: ALERTS_PATH,
        permissions: Object.values(PERMISSIONS.ALRT),
    },
];
