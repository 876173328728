

export class IntegrationWorkflowDataModel {
  public displayParametersColumn = false;
  public associateMappingData: any;
  public searchConfig: any[];
  public linkLoadData: any;
  public entityDataMappingModalId = 0;
  public stepBarStatus = 'step-bar incompleted';
  public stepCompletedStatus = 'step-completed incompleted';

  public entityName = [
    { id: 'Case', value: 'Case' },
    { id: 'ChildClient', value: 'ChildClient' },
    { id: 'AdoptionHomeStudy', value: 'AdoptionHomeStudy' },
    { id: 'AdoptivePlacement', value: 'AdoptivePlacement' },
    { id: 'Allegation', value: 'Allegation' },
    { id: 'AllegationDisposition', value: 'AllegationDisposition' },
    { id: 'AdoptionAgency', value: 'AdoptionAgency' }
  ];
  // public entityType = [
  //   { id: "Transaction", value: "Transaction" },
  //   { id: "PersonRole", value: "PersonRole" },
  //   { id: "Event", value: "Event" },
  //   { id: "Organization", value: "Organization" },
  //   { id: "Core Entity", value: "Core Entity" }
  // ];

  public entityType = [
    { id: 'Case', value: 'Case', sourceDataTypeCode: 'CMS' },
    { id: 'ChildClient', value: 'ChildClient', sourceDataTypeCode: 'CMS' },
    {
      id: 'AdoptionHomeStudy',
      value: 'AdoptionHomeStudy',
      sourceDataTypeCode: 'CMS'
    },
    {
      id: 'AdoptivePlacement',
      value: 'AdoptivePlacement',
      sourceDataTypeCode: 'CMS'
    },
    { id: 'Allegation', value: 'Allegation', sourceDataTypeCode: 'CMS' },
    {
      id: 'AllegationDisposition',
      value: 'AllegationDisposition',
      sourceDataTypeCode: 'CMS'
    },
    {
      id: 'AdoptionAgency',
      value: 'AdoptionAgency',
      sourceDataTypeCode: 'CMS'
    },
    { id: 'Person', value: 'Person', sourceDataTypeCode: 'CMS' },
    { id: 'PersonName', value: 'PersonName', sourceDataTypeCode: 'CMS' },
    { id: 'Gender', value: 'Gender', sourceDataTypeCode: 'CMS' },
    { id: 'Race', value: 'Race', sourceDataTypeCode: 'CMS' },
    { id: 'Ethnicity', value: 'Ethnicity', sourceDataTypeCode: 'CMS' },
    { id: 'Identity', value: 'Identity', sourceDataTypeCode: 'CMS' },
    {
      id: 'PhysicalDetails',
      value: 'PhysicalDetails',
      sourceDataTypeCode: 'CMS'
    },
    { id: 'Facility', value: 'Facility', sourceDataTypeCode: 'FASD' },
    {
      id: 'FacilityAddress',
      value: 'FacilityAddress',
      sourceDataTypeCode: 'FASD'
    },
    { id: 'Visit', value: 'Visit', sourceDataTypeCode: 'FASD' },
    { id: 'Licensee', value: 'Licensee', sourceDataTypeCode: 'FASD' }
  ];
  public entityClass = [
    { id: 'PRIMARY SUBJECT', value: 'PRIMARY SUBJECT' },
    { id: 'PRIMARY SUBJECT ROLE', value: 'PRIMARY SUBJECT ROLE' },
    { id: 'RELATED SUBJECT', value: 'RELATED SUBJECT' },
    { id: 'SUBJECT ATTRIBUTE', value: 'SUBJECT ATTRIBUTE' },
    { id: 'TRANSACTION', value: 'TRANSACTION' },
    { id: 'EVENT', value: 'EVENT' }
  ];
  public sourceDataType = [
    // {
    //   id: "CMS",
    //   value: "CWS/CMS : CWS Case Management System",
    //   description: "CWS Case Management System"
    // },
    // {
    //   id: "LIS-FAS",
    //   value: "LIS/FAS : Licensee Information System / Field Automation System",
    //   description: "Licensee Information System / Field Automation System"
    // }
  ];

  public targetDataType = [
    // {
    //   id: "CWS-CDI",
    //   value: "CWS/CDI : CDI: CWS CARES Data Infrastructure Database",
    //   description: "CWS CARES Data Infrastructure Database"
    // },
    // {
    //   id: "Neo4j",
    //   value: "CWS/SF : CWS Salesforce Database",
    //   description: "CWS Salesforce Database"
    // }
  ];

  public nToOneTransformationMapping = ['SUM', 'AVERAGE', 'CUSTOM', 'SELECT'];

  public oneToOneRules = [];
  public transformationTypes = ['COPY', 'TRANSLATION', 'CUSTOM'];

  public workFlowAssociateMappings: any;
}
export class WorkFlow {
  workFlowId: string;
  entityDataMapping: EntityDataMapping[] = [];
  rawEntityDataMapping: any[] = [];
  linkAndLoad: LinkAndLoad[] = [];
  identifyAttributeConfiguration: IdentifyAttributes[] = [];
  scoringConfiguration: ScoringConfigurationData[] = [];
  decisionConfiguration: DecisionConfigurationData[] = [];
  searchConfiguration: SearchConfigurationData[] = [];
}

export class LinkAndLoad {
  IntegrationWorkflowID: string;
  IntegrationWorkflowComponentStatus: string;
  WorkflowComponentTypeCode: string;
  WorkflowComponentTypes: {
    id: string;
    WorkflowComponentTypeCode: string;
    WorkflowComponentStatus: string;
    WorkflowComponentName: string;
    WorkflowComponentDescription: string;
  };
  IntegrationWorkflowComponentVersion: workFlowComponentVersions[] = [];
}

export class workFlowComponentVersions {
  transformation: {
    ComponentVersion: string;
    id: string;
    WorkflowComponent: string;
    ComponentVersionStatus: string;
    ComponentVersionDate: string;
  };
}

export class ScoringConfigurationData {
  IntegrationWorkflowID: string;
  IntegrationWorkflowComponentStatus: string;
  WorkflowComponentTypeCode: string;
  WorkflowComponentTypes: {
    id: string;
    WorkflowComponentTypeCode: string;
    WorkflowComponentStatus: string;
    WorkflowComponentName: string;
    WorkflowComponentDescription: string;
  };
  IntegrationWorkflowComponentVersion: workFlowComponentVersions[] = [];
}

export class SearchConfigurationData {
  IntegrationWorkflowID: string;
  IntegrationWorkflowComponentStatus: string;
  WorkflowComponentTypeCode: string;
  WorkflowComponentTypes: {
    id: string;
    WorkflowComponentTypeCode: string;
    WorkflowComponentStatus: string;
    WorkflowComponentName: string;
    WorkflowComponentDescription: string;
    WorkflowComponent: string;
  };
  IntegrationWorkflowComponentVersion: workFlowComponentVersions[] = [];
}

export class IdentifyAttributes {
  IntegrationWorkflowID: string;
  IntegrationWorkflowComponentStatus: string;
  WorkflowComponentTypeCode: string;
  WorkflowComponentTypes: {
    id: string;
    WorkflowComponentTypeCode: string;
    WorkflowComponentStatus: string;
    WorkflowComponentName: string;
    WorkflowComponentDescription: string;
    WorkflowComponent: String;
  };
  IntegrationWorkflowComponentVersion: workFlowComponentVersions[] = [];
}

export class DecisionConfigurationData {
  IntegrationWorkflowID: string;
  IntegrationWorkflowComponentStatus: string;
  WorkflowComponentTypeCode: string;
  WorkflowComponentTypes: {
    id: string;
    WorkflowComponentTypeCode: string;
    WorkflowComponentStatus: string;
    WorkflowComponentName: string;
    WorkflowComponentDescription: string;
  };
  IntegrationWorkflowComponentVersion: workFlowComponentVersions[] = [];
}

export class EntityDataMapping {
  targetEntity: {
    targetEntityName: string;
    displayMappingName: string;
    targetEntityType: { id: string; value: string };
    targetEntityClass: { id: string; value: string };
    displayParameters: boolean;
    isSelectedForSI: boolean;
  };
  dataMapping: SourceTargetMapping[] = [];
}
export class SourceTargetMapping {
  sourceDataElement: string;
  transformation: {
    mappingType: string;
    ruleName: string;
    ntoOneRuleName: string;
    ntoOneRuleDefination: string;
    description: string;
  };
  subjectSearchParameter: string;
  subjectIdentifyingParameter: string;
  targetDataElement: string;
}
