import { WIDGET_LIBRARY } from './select-cell-type-dialog.consts';

export * from './dialog/select-cell-type-dialog.component';
export * from './select-cell-type-dialog.module';
export * from './select-cell-type-dialog.types';
export * from './select-cell-type-dialog.service';

export const DEFUALT_CELL_TYPE_DIALOG_OPTIONS = {
    WIDGET_LIBRARY
};
