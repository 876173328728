import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { MatDialogRef } from '@angular/material';

import { DATASETS } from './blend-datasets.mock';
import { DATA_ASSET_TYP, JOIN_TYPES } from './blend-datasets.constants';

import { SelectMetadataComponent } from './select-metadata/select-metadata.component';

import { SnackbarService } from './../../../../shared';
import { SharedDataService } from '../shared/modules/shared-data/shared-data.service';
import { PaletteService } from '@workflow-editor/components/palette/palette.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { CodeEditorPanelService } from '@workflow-editor/components/code-editor-panel/code-editor-panel.service';
import { NotValidTimeStampOperator } from '@workflow-editor/components/code-editor-panel/types';
import { ConfigErrorsComponent } from './config-errors/config-errors.component';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-blend-datasets',
  templateUrl: './blend-datasets.component.html',
  styleUrls: ['./blend-datasets.component.scss']
})
export class BlendDatasetsComponent implements OnInit, OnDestroy {
  /**
   * @public
   * @type: {any}
   */
  public config: any = {};

  /**
   * @public
   * @type: {any}
   */
  public dataset: any = {
    list1: [],
    list2: [],
    entities1: [],
    entities2: []
  };

  /**
   * @public
   * @type: {any[]}
   */
  public joins: any[] = [];

  /**
   * @public
   * @type: {any}
   */
  public selectedItem: any;

  /**
   * @public
   * @type: {any[]}
   */
  public datasets: any[] = [];

  /**
   * @public
   * @type: {string}
   */
  public selection = '';

  /**
   * @public
   * @type: {any[]}
   */
  public attributes: any[] = [];

  /**
   * @public
   * @type: {any[]}
   */
  public dataSources: any[] = [];

  /**
   * @public
   * @type: {boolean}
   */
  @Input()
  public backBtn = true;

  /**
   * @public
   * @type: {any[]}
   */
  public dataBlendForm: FormGroup;

  /**
   * @private
   * @type: {any}
   */
  private selectedFields: any = {};

  /**
   * @public
   * @type: {any[]}
   */
  private avlblAttributes: any[] = [];

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input()
  dataAssetCategoryName = '';

  @Input()
  datasetCategory: any;

  @Input()
  showBlendData = false;

  @Input()
  selectedType: any;

  @Input()
  selectedCategoryId: any;

  public selected: any;

  dacLength: any;
  datasetType: any;
  dataAssetTyp = DATA_ASSET_TYP;
  DACData: any = {};
  newDAC: boolean;
  addDataSet = true;
  typeMap: any = { DF: 'file', DS: 'dataset', QRY: 'query' };
  NewDataSet: boolean;
  categoryLists: any;
  catList: any;
  createdDataAsset: any = {};
  assetList: any;
  datasetsTemp: any;
  fullAssetList: any;
  selectedAttributes = {
    LEFT_DATA_ASSET: [],
    RIGHT_DATA_ASSET: []
  };
  leftDataAsset = true;
  dfDataBlend: any = {};
  dataFrameSchema: any = [];
  includeAllColumns = true;
  bbcIncludeAllLeftColumns = true;
  bbcIncludeAllRightColumns = true;
  leftSelectedAsset: any;
  rightSelectedAsset: any;
  bbrIncludedColumns: any;
  dataAssetCode: string;
  fileSchema: any;
  datasetID: any;
  bbcLeftDataAssetIncludedColumns: any;
  bbcRightDataAssetIncludedColumns: any;
  dataBlendBy = 'ROW';
  bbrInheritTargetDataFrameFrom: any;
  bbcDataBlendJoinType: string;
  bbcLeftDataAssetIncludeAllColumnsValue = 'Yes';
  bbcRightDataAssetIncludedColumnsValue = [];
  bbcRightDataAssetIncludeAllColumnsValue = 'Yes';
  bbcLeftDataAssetIncludedColumnsValue = [];
  bbrIncludeAllColumnsValue: any;
  bbrIncludedColumnsValue: any;
  leftFileSchema: any;
  rightFileSchema: any;
  blendRulePayload: string;
  fileSchemaPayload: string;
  showBlendRule: boolean;
  // workflowDef: any;
  leftDataSetID: string;
  rightDataSetID: string;
  datasetsIDs: any;
  leftDataSet: any;
  rightDataSet: any;
  leftDataSetProperties: any;
  rightDataSetProperties: any;
  leftDatasetTaskIdProp: any;
  rightDatasetTaskIdProp: any;
  leftDatasetTaskId: any;
  rightDatasetTaskId: any;
  lData: any;
  rData: any;
  addBlendRule: boolean;
  editBlendRule: boolean;
  viewBlendRule: boolean;
  leftAttributes: any;
  allAttributes: any;
  showLeftTargetDataFrameFrom: boolean;
  leftData: any;
  rightData: any;
  rightAttributes: any;
  leftEntityName: any;
  rightAttrName: any;
  leftAttrName: any;
  rightEntityName: any;
  currBlendRuleMetaData: any;
  currBlenRuleSchema: any;
  fileName: string;
  previousOperatorID1: any;
  previousOperatorID2: any;
  @Input() currentTaskID: string;
  @Input() nodes: any;
  @Input() workflowDef: any;
  @Input() currentFileConfig: any;
  @Input() operatorsConfigs: any;
  @Input() previousTaskIDs: any;
  @Input() currentOperatorCode: any;
  previousOperatorCode = 'DS';
  params: any;
  branchFiles: any;
  branchFileSha: any;
  branchFile: any = {};
  currentFileName: string;
  leftConfig: any;
  rightConfig: void;
  dataSetConfigs: any;
  previousTaskConfigs: any = [];
  righttAllAttributes: any;
  leftAllAttributes: any;
  leftAllAttributesName = [];
  rightAllAttributesName = [];
  onSelectJoinAttibute: any;
  checkJoinAttibute = false;
  rowFileSchemaJSON: any;
  colFileSchemaJSON: any = [];
  fileSchemaJSON: any;
  selectedLeftFileSchema: any;
  selectedRightFileSchema: any;
  dataBlendConfig: any;
  selectedConfgiData: any;
  leftSelectedAttribute = [];
  rightSelectedAttribute = [];
  leftDatasetSchema: any;
  rightDatasetSchema: any;
  selectedDataSetSchema;
  formData: any;
  leftSchema: any;
  rightSchema: any;
  finalSchemaData: any;
  private leftDataSetKey: any;
  private rightDataSetKey: any;

  public notValidTSOperator: NotValidTimeStampOperator;
  public configError = '';


  /**
   * @constructor
   * @param: {fb<FormBuilder>}
   * @param: {snackbarService<SnackbarService>}
   * @param: {sharedDataService<SharedDataService>}
   */
  constructor(
    private fb: FormBuilder,
    private snackbarService: SnackbarService,
    public paletteService: PaletteService,
    private _udpTaskConfigService: UdpTaskConfigService,
    private codeEditorPanelService: CodeEditorPanelService,
    private sharedDataService: SharedDataService,
    private cdr: ChangeDetectorRef) {
    this.joins = JOIN_TYPES;
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.initBlendForm();
    this.init();

    this.fileName = `${this.currentTaskID}.config.${this.currentOperatorCode.toLowerCase()}.json`;
    if (this.currentFileConfig) {
      this.checkIfConfigIsAlreadyAvailable(this.currentFileConfig);
    }

    if (this.notValidTSOperator) {
      const configError = this._udpTaskConfigService.checkForTCVValidationForOperators([this.previousTaskIDs[0], this.previousTaskIDs[1]],
         this.currentTaskID);
      if (configError && configError.length > 0) {
        this.configError = _.cloneDeep(configError[0]);
      }
    }

    const temp = _.cloneDeep(this._udpTaskConfigService.getOperatorConfigFromCache(this.previousTaskIDs[0]));
    this.previousTaskConfigs.push(temp);

    const temp1 = _.cloneDeep(this._udpTaskConfigService.getOperatorConfigFromCache(this.previousTaskIDs[1]));
    this.previousTaskConfigs.push(temp1);

    if (this.previousTaskConfigs) {
      const temp01 = _.cloneDeep(this._udpTaskConfigService.getConfig(this.previousTaskIDs[0]));
      this.previousTaskConfigs.push(temp01);
      const temp02 = _.cloneDeep(this._udpTaskConfigService.getConfig(this.previousTaskIDs[1]));
      this.previousTaskConfigs.push(temp02);
    }
    let filteredPreviousTaskConfigs = this.previousTaskConfigs.filter(x => x !== undefined);
    filteredPreviousTaskConfigs = _.uniqBy(filteredPreviousTaskConfigs, 'filename');
    // Fetchinh data sets to display in cards
    this.getDataSets(filteredPreviousTaskConfigs);

    this.config = {
      catKey: 'dataSetName',
      catIdKey: 'dataSetID',
      subCatIdKey: 'dataSourceID',
      subCatKey: 'dataSourceName',
      listKey: 'dataSetDataSource'
    };

    // Retriving selected attributes
    this.paletteService.blendDataSet$.subscribe((data: any) => {
    });

    this.sharedDataService.blendAttributesSelectcted$.asObservable().subscribe(data => {
      this.selectedAttributes = data;
      if (this.selectedAttributes.LEFT_DATA_ASSET && this.selectedAttributes.LEFT_DATA_ASSET.length > 0 &&
        this.selectedAttributes.LEFT_DATA_ASSET[0].blendSelectedAttributes) {
        this.selectedAttributes.LEFT_DATA_ASSET.map((item) => {
          item.attribute = item.blendSelectedAttributes;
          delete item.blendSelectedAttributes;
          delete item.entityAttributes;
        });
        this.selectedLeftFileSchema = this.selectedAttributes;
      }
      if (this.selectedAttributes.RIGHT_DATA_ASSET && this.selectedAttributes.RIGHT_DATA_ASSET.length > 0 &&
        this.selectedAttributes.RIGHT_DATA_ASSET[0].blendSelectedAttributes) {
        this.selectedAttributes.RIGHT_DATA_ASSET.map((item) => {
          item.attribute = item.blendSelectedAttributes;
          delete item.blendSelectedAttributes;
          delete item.entityAttributes;
        });
        this.selectedRightFileSchema = this.selectedAttributes;
      }
    });
    this.sharedDataService.entity = {
      LEFT_DATA_ASSET: [],
      RIGHT_DATA_ASSET: []
    };
    this.dataBlendForm.valueChanges.subscribe((data) => {
      if (data) {
        this.formData = data;
        this.onSave();
      }
    });
    this.currentFileName = `${this.currentTaskID}.config.${this.currentOperatorCode.toLowerCase()}.json`;
    this.onSave();
  }

  public showErrorPopup() {

    const ref: MatDialogRef<any> = this.sharedDataService.openDialog(
      ConfigErrorsComponent,
      {
        height: '600px',
        width: '800px',
        data: this.configError
      }
    );

  }

  private checkIfConfigIsAlreadyAvailable(config) {
    if (config && Object.keys(config.contents).length > 0) {
      this.dataBlendConfig = _.cloneDeep(config.contents.metadata);
      this.selectedConfgiData = {};
      this.selectedConfgiData.dataBlendBy = this.dataBlendConfig.dataBlendBy ? this.dataBlendConfig.dataBlendBy : '';
      this.dataBlendBy = this.selectedConfgiData.dataBlendBy ? this.selectedConfgiData.dataBlendBy : '';
      this.selectedConfgiData.bbcDataBlendJoinType = this.dataBlendConfig.bbcDataBlendJoinType ?
        this.dataBlendConfig.bbcDataBlendJoinType : '';
      this.selectedConfgiData.bbrIncludeAllColumns = this.dataBlendConfig.bbrIncludeAllColumns ?
        this.dataBlendConfig.bbrIncludeAllColumns : '';
      if (this.dataBlendConfig.bbrIncludeAllColumns) {
        this.includeAllColumns = this.dataBlendConfig.bbrIncludeAllColumns === 'Yes' ? true : false;
      }
      this.selectedConfgiData.bbcLeftDataAssetIncludeAllColumns = this.dataBlendConfig.bbcLeftDataAssetIncludeAllColumns ?
        this.dataBlendConfig.bbcLeftDataAssetIncludeAllColumns : '';
      this.bbcIncludeAllLeftColumns = this.selectedConfgiData.bbcLeftDataAssetIncludeAllColumns === 'Yes' ? true : false;
      this.selectedConfgiData.bbcRightDataAssetIncludeAllColumns = this.dataBlendConfig.bbcRightDataAssetIncludeAllColumns ?
        this.dataBlendConfig.bbcRightDataAssetIncludeAllColumns : '';
      this.bbcIncludeAllRightColumns = this.selectedConfgiData.bbcRightDataAssetIncludeAllColumns === 'Yes' ? true : false;
      this.selectedConfgiData.bbrInheritTargetDataFrameFrom = this.dataBlendConfig.bbrInheritTargetDataFrameFrom ?
        this.dataBlendConfig.bbrInheritTargetDataFrameFrom : '';
      if (this.dataBlendConfig.dataBlendBy === 'COL') {
        // Remove DataSet Schema Format (l_, r_) for blend by column
        this.removeDataSetSchemaFormate(this.dataBlendConfig.dataSetSchema);
        for (const key in this.dataBlendConfig.dataSetSchema) {
          if (key.startsWith('l_')) {
            this.selectedAttributes.LEFT_DATA_ASSET = this.dataBlendConfig.dataSetSchema[key];
          } else if (key.startsWith('r_')) {
            this.selectedAttributes.RIGHT_DATA_ASSET = this.dataBlendConfig.dataSetSchema[key];
          }
        }
      }
      // Setting selected attributes to be displayed in view edit schema component
      if (this.dataBlendConfig.bbrInheritTargetDataFrameFrom === 'LEFT_DATA_ASSET') {
        this.selectedAttributes.LEFT_DATA_ASSET = Object.values(this.dataBlendConfig.dataSetSchema);
      } else {
        this.selectedAttributes.RIGHT_DATA_ASSET = Object.values(this.dataBlendConfig.dataSetSchema);
      }
      if (this.dataBlendConfig.bbcJoinColumn) {
        const field: FormArray = (this.dataBlendForm.get('mapping') as FormArray);
        this.dataBlendConfig.bbcJoinColumn.forEach(item => {
          field.push(
            new FormGroup({
              leftDataAssetColumn: new FormControl('', [
                Validators.required
              ]),
              rightDataAssetColumn: new FormControl('', [
                Validators.required
              ])
            })
          );
        });
        this.selectedConfgiData.mapping = this.dataBlendConfig.bbcJoinColumn;
      }
      this.dataBlendForm.patchValue({...this.selectedConfgiData});

      // We need to check for timestamp validation if current task is available
      this.notValidTSOperator = this._udpTaskConfigService.checkTimeStampValidationForOperators(
        [this.previousTaskIDs[0], this.previousTaskIDs[1]], this.currentTaskID);

      this.cdr.detectChanges();
    }
  }

  removeDataSetSchemaFormate(dataset) {
    const keys = Object.keys(dataset);
    let finalDataSet = {};
    keys.forEach( key => {
      dataset[key].entityPhysicalName = dataset[key].entityName = (dataset[key].entityName).slice(2);
      dataset[key].attribute.forEach(item => {
        item.attributeName = item.attributeName.slice(2);
      });
      if (key.startsWith('l_')) {
        this.sharedDataService.selectedEntity.LEFT_DATA_ASSET = dataset[key].attribute;
      } else if (key.startsWith('r_')) {
        this.sharedDataService.selectedEntity.RIGHT_DATA_ASSET = dataset[key].attribute;
      }
      finalDataSet = finalDataSet ? {...finalDataSet, ...{[dataset[key].entityName] : dataset[key] }}
        : {[dataset[key].entityName]: dataset[key] };
    });
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * initializes the blend configurations.
   */
  private init(): void {
    this.datasets = DATASETS;
    this.dataSources = _.flatten(_.map(this.datasets, 'dataSetDataSource'));

    // get flat list of all the attributes
    const attributes: any[] = _.flattenDeep(
      _.map(this.datasets, (o) => {
        return _.map(o.datasetSchema.entity, 'attributes');
      })
    );

    this.attributes = attributes;
    this.avlblAttributes = attributes;
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * initializes the blend form.
   */
  private initBlendForm(): void {
    this.dataBlendForm = this.fb.group({
      mapping: new FormArray([]),
      bbcDataBlendJoinType: new FormControl(''),
      dataBlendBy: new FormControl('ROW', [Validators.required]),
      bbrIncludeAllColumns: new FormControl('Yes', [Validators.required]),
      bbcLeftDataAssetIncludeAllColumns: new FormControl('Yes', [Validators.required]),
      bbcRightDataAssetIncludeAllColumns: new FormControl('Yes', [Validators.required]),
      bbrInheritTargetDataFrameFrom: new FormControl('LEFT_DATA_ASSET', [Validators.required]),
      leftDataAsset: new FormControl('', [Validators.required]),
      rightDataAsset: new FormControl('', [Validators.required])
    });
  }

  /**
   *
   * @param previousTaskConfigs
   */
  getDataSets(previousTaskConfigs) {

    // const datasetSources = previousTaskConfigs.map((prvConfig) => {
    //   if (typeof prvConfig.contents.datasetDataSource.dataAsset.fileSchema === 'string') {
    //     const t1 = (prvConfig.contents.datasetDataSource.dataAsset.fileSchema).replaceAll('None', '""');
    //     const t2 = t1.replace(/'/g, '"');
    //     const t3 = JSON.parse(t2);
    //     prvConfig.contents.datasetDataSource.dataAsset.fileSchema = t3;
    //   }
    //   return prvConfig.contents.datasetDataSource.dataAsset;
    // });
    this.lData = {};
    this.rData = {};

    this.lData.leftSourceDataSetTaskID = (((previousTaskConfigs[0].filename.split('/'))[1]).split('.'))[0];
    this.lData.leftSourceDataSetOperatorCode = (((previousTaskConfigs[0].filename.split('/'))[1]).split('.'))[2];
    this.lData.leftDataSetColumnsIncluded = []; // Should have values based on columns selection processing
    this.lData.leftDataSetIncludeNumRows = []; // Should have values based on row selection processing

    this.rData.rightSourceDataSetTaskID = (((previousTaskConfigs[1].filename.split('/'))[1]).split('.'))[0];
    this.rData.rightSourceDataSetOperatorCode = (((previousTaskConfigs[1].filename.split('/'))[1]).split('.'))[2];
    this.rData.rightDataSetColumnsIncluded = [];
    this.rData.rightDataSetIncludeNumRows = [];

    // It would always be undefined - Aakash
    // this.leftFileSchema = this.lData.fileSchema;
    // this.rightFileSchema = this.rData.fileSchema;

    this.leftAllAttributes = previousTaskConfigs[0].contents.datasetSchema.attribute;
    this.righttAllAttributes = previousTaskConfigs[1].contents.datasetSchema.attribute;
    previousTaskConfigs[0].contents.datasetSchema.attribute.filter(item => this.leftAllAttributesName.push(item.name));
    previousTaskConfigs[1].contents.datasetSchema.attribute.filter(item => this.rightAllAttributesName.push(item.name));
    this.leftDatasetSchema = _.cloneDeep(previousTaskConfigs[0].contents.datasetSchema);
    this.rightDatasetSchema = _.cloneDeep(previousTaskConfigs[1].contents.datasetSchema);
  }

  /**
   * @private
   * @param: {name<string>}
   * @param: {idx<number>}
   * @param: {fid<number>}
   * @return: void
   * @description: a helper method that
   * sets attributes selected state in
   * the local variable.
   */
  public onFieldSelect(
    name: string, idx: number, fid: number, col: string): void {
    let value: any;
    if (col === 'left' && this.leftAllAttributes) {
      value = this.leftAllAttributes.filter(item => item.name === name);
      this.dataBlendForm.get(`mapping.${idx}`).patchValue({leftDataAssetColumn: value[0] });
    } else if (this.righttAllAttributes) {
      value = this.righttAllAttributes.filter(item => item.name === name);
      this.dataBlendForm.get(`mapping.${idx}`).patchValue({ rightDataAssetColumn: value[0] });
    }
    if (!!name) {
      this.selectedFields[`${fid}${idx}`] = name;
      setTimeout(() => this.setSelectedFields());
    }
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * sets attributes selected state &
   * constructs the available attributes
   * list.
   */
  private setSelectedFields(): void {
    this.avlblAttributes = [];

    const fields: string[] = Object.values(this.selectedFields);
    const attrs: string[] = _.map(this.attributes, (attr) => {
      attr.selected = _.includes(fields, _.trim(attr.attributeName));

      // capture all avaiable attributes
      // based on their selected status
      if (!attr.selected) {
        this.avlblAttributes.push(attr);
      }
      return attr;
    });
    this.attributes = attrs;
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * resets the dataset view.
   */
  public cancel(e: boolean): void {
    if (e) {
      this.selectedItem = null;
    }
  }

  /**
   * @public
   * @param: {check<boolean>}
   * @return: void
   * @description: a helper method that
   * adds a new mapping field to the form
   * array.
   */
  public addField(check?: boolean): void {
    const field: FormArray = (this.dataBlendForm.get('mapping') as FormArray);

    if (check && field.invalid) {
      this.snackbarService.openMessage(
        'Please ensure all mapping fields are selected before adding a new mapping set.',
        'warning', {
        verticalPosition: 'top'
      });
      return;
    }
    field.push(
      new FormGroup({
        leftDataAssetColumn: new FormControl('', [
          Validators.required
        ]),
        rightDataAssetColumn: new FormControl('', [
          Validators.required
        ])
      })
    );
  }

  /**
   * @public
   * @param: {idx<number>}
   * @return: void
   * @description: a helper method that
   * removes requested mapping field from
   * the form array.
   */
  public removeField(idx: number): void {
    (this.dataBlendForm.get('mapping') as FormArray).removeAt(idx);

    _.each([1, 2], i => {
      const field: string = this.selectedFields[
        `${i}${idx}`
      ];

      if (!!field) {
        delete this.selectedFields[
          `${i}${idx}`
        ];
      }
    });
    this.setSelectedFields();
  }

  /**
   * @public
   * @param: {preserve<boolean>}
   * @return: void
   * @description: a helper method that
   * removes all field mappings from
   * the form array.
   */
  public removeAllFields(preserve?: boolean): void {
    const list: FormArray = this.dataBlendForm.get('mapping') as FormArray;

    if (preserve) {
      const group: FormGroup = list.get('0') as FormGroup;

      list.clear();
      list.push(group);
    } else {
      list.clear();
    }
  }

  /**
   * @public
   * @param: {e<any>}
   * @return: void
   * @description: a helper method that
   * brings data from the sidebar
   * component upon user's selection.
   */
  public onDatasetSelect(e: any): void {
    this.selectedItem = e;
  }

  /**
   * @public
   * @param: {id<number>}
   * @return: void
   * @description: a helper method that
   * clears out the selected item for a
   * given list.
   */
  public reset(id: number): void {
    this.dataset[`list${id}`] = [];

    const field: string = id === 1 ? 'leftDataAsset' : 'rightDataAsset';
    this.dataBlendForm.get(field).reset('');
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * that opens up data source view based
   * on selected data source type.
   */
  public mapDataSource(asset: any, card?: string): void {
    const sourceLEFTRIGHTDataset = _.cloneDeep(asset);
    this.sharedDataService.activeCard = card;
    this.sharedDataService.fileSchema.next({ fileSchema: sourceLEFTRIGHTDataset, selectedAttributes: this.selectedAttributes, card });
    const ref: MatDialogRef<any> = this.sharedDataService.openDialog(
      SelectMetadataComponent,
      {
        width: '800px'
      }
    );

    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => {
        if (v) {
          if (card === 'LEFT_DATA_ASSET' && v) {
            this.leftSchema = v[0];
            if (v[0].blendSelectedAttributes) {
              this.sharedDataService.selectedEntity.LEFT_DATA_ASSET = v[0].blendSelectedAttributes;
            } else {
              this.sharedDataService.selectedEntity.LEFT_DATA_ASSET = v[0].attribute;
            }
          } else {
            this.rightSchema = v[0];
            if (v[0].blendSelectedAttributes) {
              this.sharedDataService.selectedEntity.RIGHT_DATA_ASSET = v[0].blendSelectedAttributes;
            } else {
              this.sharedDataService.selectedEntity.RIGHT_DATA_ASSET = v[0].attribute;
            }
          }
          this.dataset[`entities${asset.id}`] = [];
          this.dataset[`entities${asset.id}`].push(v);
          this.onSave();
        }
      });

    ref.componentInstance.id = asset.id;
  }

  /**
   *
   * @param e
   * @param field
   * @param listType
   */
  onSelectLeftAsset(e, field: string, listType: number) {
    const data: any = this.dataBlendForm.get(field).value;
    this.dataset[`list${listType}`] = [];
    this.dataset[`list${listType}`].push(data);
  }

  /**
   *
   * @param e
   * @param field
   * @param listType
   */
  onSelectRightAsset(e, field: string, listType: number) {
    const data: any = this.dataBlendForm.get(field).value;
    this.dataset[`list${listType}`] = [];
    this.dataset[`list${listType}`].push(data);
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * marks join type field mandatory or
   * optional based on the blend type.
   */
  public onBlendTypeChange(): void {
    const values: any = this.dataBlendForm.value;
    const field: AbstractControl = this.dataBlendForm.get('bbcDataBlendJoinType');
    this.dataBlendBy = this.dataBlendForm.get('dataBlendBy').value;

    if (values.dataBlendBy === 'COL') {
      this.init();

      field.setValidators([Validators.required]);
      this.setSelectedFields();
      this.addField();
    } else {
      field.clearValidators();
      this.removeAllFields();
      field.reset();
    }
    this.sharedDataService.entity = {
      LEFT_DATA_ASSET: [],
      RIGHT_DATA_ASSET: []
    };
    field.updateValueAndValidity();
    this.removeSelectedAttribute();
  }

  bbrTargetDataFrame() {
    const value: any = this.dataBlendForm.get('bbrInheritTargetDataFrameFrom').value;
    this.leftDataAsset = value === 'RIGHT_DATA_ASSET' ? false : true;
    this.removeSelectedAttribute();
  }

  /**
   *
   */
  public onBbrIncludeAllColumns(): void {
    const value: any = this.dataBlendForm.get('bbrIncludeAllColumns').value;
    this.includeAllColumns = value === 'Yes' ? true : false;
    this.removeSelectedAttribute();
  }

  /**
   *
   */
  public bbcLeftDataAssetIncludeAllColumns(): void {
    const value: any = this.dataBlendForm.get('bbcLeftDataAssetIncludeAllColumns').value;
    this.bbcIncludeAllLeftColumns = value === 'Yes' ? true : false;
    this.sharedDataService.selectedEntity.LEFT_DATA_ASSET = [];
    this.leftSchema = null;
  }

  /**
   *
   */
  public bbcRightDataAssetIncludeAllColumns(): void {
    const value: any = this.dataBlendForm.get('bbcRightDataAssetIncludeAllColumns').value;
    this.bbcIncludeAllRightColumns = value === 'Yes' ? true : false;
    this.sharedDataService.selectedEntity.RIGHT_DATA_ASSET = [];
    this.rightSchema = null;
  }

  removeSelectedAttribute() {
    this.sharedDataService.selectedEntity.LEFT_DATA_ASSET = [];
    this.sharedDataService.selectedEntity.RIGHT_DATA_ASSET = [];
    this.leftSchema = null;
    this.rightSchema = null;
  }

  removeDuplicateAttribute(data) {
    if (data.blendSelectedAttributes) {
      data.attribute = data.blendSelectedAttributes;
      delete data.blendSelectedAttributes;
      data.attribute = _.uniqBy(data.attribute, 'attributeName');
    }
    return data;
  }

  setRowData() {
    // set empty to col
    this.bbcDataBlendJoinType = '';
    this.bbcLeftDataAssetIncludeAllColumnsValue = '';
    this.bbcRightDataAssetIncludeAllColumnsValue = '';
    this.bbcLeftDataAssetIncludedColumnsValue = [];
    this.bbcRightDataAssetIncludedColumnsValue = [];
    this.rowFileSchemaJSON = {};
    // row
    this.bbrIncludeAllColumnsValue = this.dataBlendForm.get(
      'bbrIncludeAllColumns'
    ).value;
    if (this.bbrIncludeAllColumnsValue) {
      if (this.bbrIncludeAllColumnsValue === 'Yes') {
        const leftRowData = _.cloneDeep(this.leftDatasetSchema);
        const rightRowData = _.cloneDeep(this.rightDatasetSchema);
        this.bbrInheritTargetDataFrameFrom = this.dataBlendForm.get(
          'bbrInheritTargetDataFrameFrom'
        ).value;
        if (this.bbrInheritTargetDataFrameFrom === 'LEFT_DATA_ASSET') {
          this.rowFileSchemaJSON = { [leftRowData.entityName]: leftRowData };
        } else {
          this.rowFileSchemaJSON = { [rightRowData.entityName]: rightRowData }; // this.leftFileSchema
        }
      } else {
        this.bbrInheritTargetDataFrameFrom = this.dataBlendForm.get(
          'bbrInheritTargetDataFrameFrom'
        ).value;
        this.rowFileSchemaJSON = '';
        if (
          this.leftSchema &&
          this.bbrInheritTargetDataFrameFrom === 'LEFT_DATA_ASSET'
        ) {
          this.leftSchema = this.removeDuplicateAttribute(this.leftSchema);
          this.rowFileSchemaJSON = {
            [this.leftSchema.entityName]: this.leftSchema
          };
          this.lData.leftDataSetColumnsIncluded = this.getAttributeNames(this.leftSchema);
        } else if (
          this.rightSchema &&
          this.bbrInheritTargetDataFrameFrom === 'RIGHT_DATA_ASSET'
        ) {
          this.rightSchema = this.removeDuplicateAttribute(this.rightSchema);
          this.rowFileSchemaJSON = {
            [this.rightSchema.entityName]: this.rightSchema
          }; // this.rightFileSchema
          this.rData.rightDataSetColumnsIncluded = this.getAttributeNames(this.rightSchema);
        }
      }
      this.dfDataBlend = {
        dataBlendBy: this.dataBlendBy,
        bbrIncludeAllColumns: this.bbrIncludeAllColumnsValue, // this.dataBlendForm.get('bbrIncludeAllColumns').value,
        bbrInheritTargetDataFrameFrom: this.bbrInheritTargetDataFrameFrom,
        leftSourceDataSet: this.lData, // this.dataBlendForm.get('leftDataAsset').value,
        rightSourceDataSet: this.rData, // this.dataBlendForm.get('rightDataAsset').value,
        dataSetSchema: this.rowFileSchemaJSON
      };
    }
  }

  filterLeftDataSet() {
    const leftData = _.cloneDeep(this.leftDatasetSchema);
    if (this.bbcLeftDataAssetIncludeAllColumnsValue === 'Yes') {
      this.bbcLeftDataAssetIncludedColumnsValue = this.leftFileSchema; // this.leftFileSchema; - for mock
      this.colFileSchemaJSON.push(this.leftFileSchema);
      this.leftDataSetKey = leftData.entityName;
      this.finalSchemaData = this.finalSchemaData
        ? { ...this.finalSchemaData, ...{ [leftData.entityName]: leftData } }
        : { [leftData.entityName]: leftData };
    } else {
      if (this.finalSchemaData) {
        delete this.finalSchemaData[leftData.entityName];
      }
      this.leftDataSetKey = '';
      let leftSelectedSchema = _.cloneDeep(this.leftSchema);
      if (this.selectedAttributes && this.selectedAttributes.LEFT_DATA_ASSET) {
        if (leftSelectedSchema) {
          leftSelectedSchema =
            this.removeDuplicateAttribute(leftSelectedSchema);
          this.leftDataSetKey = leftSelectedSchema.entityName;
          this.finalSchemaData = this.finalSchemaData
            ? {
                ...this.finalSchemaData,
                ...{ [leftSelectedSchema.entityName]: leftSelectedSchema }
              }
            : { [leftSelectedSchema.entityName]: leftSelectedSchema };
        }
        this.bbcLeftDataAssetIncludedColumnsValue = this.selectedAttributes.LEFT_DATA_ASSET;
        this.colFileSchemaJSON.push(this.selectedAttributes.LEFT_DATA_ASSET[0]);
      }
    }
  }

  filterRightDataSet() {
    const rightData = _.cloneDeep(this.rightDatasetSchema);
    if (this.bbcRightDataAssetIncludeAllColumnsValue === 'Yes') {
      this.bbcRightDataAssetIncludedColumnsValue = this.rightFileSchema; // this.rightFileSchema; - for mock
      this.colFileSchemaJSON.push(this.rightFileSchema);
      this.rightDataSetKey = rightData.entityName;
      this.finalSchemaData = {
        ...this.finalSchemaData,
        ...{ [rightData.entityName]: rightData }
      };
    } else {
      this.rightDataSetKey = '';
      if (this.finalSchemaData) {
        delete this.finalSchemaData[rightData.entityName];
      }
      if (this.selectedAttributes && this.selectedAttributes.RIGHT_DATA_ASSET) {
        if (this.rightSchema) {
          this.rightSchema = this.removeDuplicateAttribute(this.rightSchema);
          this.rightDataSetKey = this.rightSchema.entityName;
          this.finalSchemaData = this.finalSchemaData
            ? {
                ...this.finalSchemaData,
                ...{ [this.rightSchema.entityName]: this.rightSchema }
              }
            : { [this.rightSchema.entityName]: this.rightSchema };
        }
        this.bbcRightDataAssetIncludedColumnsValue =
          this.selectedAttributes.RIGHT_DATA_ASSET;
        this.colFileSchemaJSON.push(this.selectedAttributes.RIGHT_DATA_ASSET[0]);
      }
    }
  }

  setColumnData() {
    // row
    this.bbrIncludeAllColumnsValue = '';
    this.bbrIncludedColumnsValue = [];
    this.bbrInheritTargetDataFrameFrom = '';

    this.bbcDataBlendJoinType = this.dataBlendForm.get(
      'bbcDataBlendJoinType'
    ).value;
    this.bbcLeftDataAssetIncludeAllColumnsValue = this.dataBlendForm.get(
      'bbcLeftDataAssetIncludeAllColumns'
    ).value;
    this.bbcRightDataAssetIncludeAllColumnsValue = this.dataBlendForm.get(
      'bbcRightDataAssetIncludeAllColumns'
    ).value;

    // Col - bbc left include/included columns
    this.filterLeftDataSet();

    // Col - bbc right include/included columns
    this.filterRightDataSet();

    this.lData.leftDataSetColumnsIncluded = this.bbcLeftDataAssetIncludedColumnsValue ?
      this.getAttributeNames(this.bbcLeftDataAssetIncludedColumnsValue[0]) : [];

    this.rData.rightDataSetColumnsIncluded = this.bbcRightDataAssetIncludedColumnsValue ?
      this.getAttributeNames(this.bbcRightDataAssetIncludedColumnsValue[0]) : [];
    this.fileSchemaJSON = this.finalSchemaData;
    this.dfDataBlend = {
      dataBlendBy: this.dataBlendBy,
      leftSourceDataSet: this.lData, // this.dataBlendForm.get('leftDataAsset').value,
      rightSourceDataSet: this.rData, // this.dataBlendForm.get('rightDataAsset').value,
      bbcDataBlendJoinType: this.bbcDataBlendJoinType, // this.dataBlendForm.get('bbcDataBlendJoinType').value,
      bbcLeftDataAssetIncludeAllColumns: this.bbcLeftDataAssetIncludeAllColumnsValue,
      bbcRightDataAssetIncludeAllColumns: this.bbcRightDataAssetIncludeAllColumnsValue,
      bbcLeftDataAssetIncludedColumns: this.bbcLeftDataAssetIncludedColumnsValue
        ? this.getAttributeNames(this.bbcLeftDataAssetIncludedColumnsValue[0]) : [],
      bbcRightDataAssetIncludedColumns: this.bbcRightDataAssetIncludedColumnsValue
        ? this.getAttributeNames(this.bbcRightDataAssetIncludedColumnsValue[0]) : [],
      bbcJoinColumn: this.dataBlendForm.get('mapping').value,
      dataSetSchema: this.fileSchemaJSON
    };
  }
  /**
   *
   */
  onSave() {
    // Blend By Row - bbr include/included columns
    if (this.dataBlendForm.get('dataBlendBy').value === 'ROW') {
      this.setRowData();
    } else if (this.dataBlendForm.get('dataBlendBy').value === 'COL') {
      this.setColumnData();
    }
    const dataBlend = _.cloneDeep(this.dfDataBlend);
    const payload = {
      metadata: this.dataBlendBy === 'COL' ? this.filterFinalJson(dataBlend) : dataBlend
    };
    // setting on click of save
    this.codeEditorPanelService.datablendOperatorConfig = { fileName: `config/${this.fileName}`, contents: payload,
    TS: Math.floor(new Date().getTime() / 1000) };
  }

  filterFinalJson(dataSet) {
    if (this.dataBlendBy === 'COL') {
      const dataSchema = _.cloneDeep(dataSet.dataSetSchema);
      let finalJson = {};
      if (this.leftDataSetKey) {
        dataSchema[this.leftDataSetKey].entityName = this.changeNameFormat(dataSchema[this.leftDataSetKey].entityName, 'l_');
        dataSchema[this.leftDataSetKey].entityPhysicalName = this.changeNameFormat(dataSchema[this.leftDataSetKey].entityPhysicalName,
          'l_');
        dataSchema[this.leftDataSetKey].attribute.forEach(attribute => {
          attribute.attributeName = this.changeNameFormat(attribute.attributeName, 'l_');
        });
        finalJson = {[dataSchema[this.leftDataSetKey].entityName] : dataSchema[this.leftDataSetKey]};
      }
      if (this.rightDataSetKey) {
        dataSchema[this.rightDataSetKey].entityName = this.changeNameFormat(dataSchema[this.rightDataSetKey].entityName, 'r_');
        dataSchema[this.rightDataSetKey].entityPhysicalName = this.changeNameFormat(
          dataSchema[this.rightDataSetKey].entityPhysicalName, 'r_');
        dataSchema[this.rightDataSetKey].attribute.forEach(attribute => {
          attribute.attributeName = this.changeNameFormat(attribute.attributeName, 'r_');
        });
        finalJson = finalJson ? {...finalJson , ...{[dataSchema[this.rightDataSetKey].entityName] : dataSchema[this.rightDataSetKey]}}
          : {[dataSchema[this.rightDataSetKey].entityName] : dataSchema[this.rightDataSetKey]};
      }
      dataSet.dataSetSchema = finalJson;
      return dataSet;
    } else {
      return dataSet;
    }
  }

  changeNameFormat(name, asset) {
    if (!name.startsWith('l_') && !name.startsWith('r_')) {
      return asset + name;
      // return asset + name.replace(' ', '_');
    } else {
      return name;
    }
  }

  private getAttributeNames(datasetEntity, keyName?: string): string[] {
    if (datasetEntity) {
      const attributes = [];
      if (keyName) {
        for (const attr of datasetEntity[keyName].attribute) {
          attributes.push(attr.name);
        }
      } else {
        for (const attr of datasetEntity.attribute) {
          attributes.push(attr.name);
        }
      }
      return attributes;
    } else {
      return;
    }
  }

  /**
   *
   * @param selectedCategoryId
   * @param selectedType
   * @param createdAsset
   */
  getDataAssetById(createdDataAssetId, selectedType) {
    // this._dataCatalogService.getDataAssetById(createdDataAssetId, selectedType).subscribe((data:any) => {
    //     this._errorHandlingService.errorSuccessSnack("134", true);
    //     this.createdDataAsset = data;
    // })
  }

  swapDataSets() {
    const lData = this.lData;
    const rData = this.rData;

    this.lData = {};
    this.lData.leftSourceDataSetTaskID = rData.rightSourceDataSetTaskID;
    this.lData.leftSourceDataSetOperatorCode = rData.rightSourceDataSetOperatorCode;
    this.lData.leftDataSetColumnsIncluded = rData.rightDataSetColumnsIncluded;
    this.lData.leftDataSetIncludeNumRows = rData.rightDataSetIncludeNumRows;

    this.rData = {};
    this.rData.rightSourceDataSetTaskID = lData.leftSourceDataSetTaskID;
    this.rData.rightSourceDataSetOperatorCode = lData.leftSourceDataSetOperatorCode;
    this.rData.rightDataSetColumnsIncluded = lData.leftDataSetColumnsIncluded;
    this.rData.rightDataSetIncludeNumRows = lData.leftDataSetIncludeNumRows;

    // [this.lData, this.rData] = [this.rData, this.lData];
  }

  /**
   * TODO:
   */
  // previeBlendData() {
  //   const payload = {
  //     userID: this.userId,
  //     customerID: this.customerID,
  //     actionType: 'UDBS',
  //     actionCode: 'UDBS_BLEND_DF',
  //     workflowDefination: null,
  //     dataAssetID: this.datasetID,
  //     dataAssetType: 'DS',
  //     sessionKey: this.userSessionKey,
  //     taskContentRequest: null,
  //     queryScript: null,
  //     dataBlendRule: currBlendRule
  //   };
  //   this.dataCatalogService
  //   .userSession(payload)
  //   .subscribe((runResponse: any) => {
  //     console.log('Preview Response', runResponse);
  //     if (runResponse.data) {
  //       this.dfModel = runResponse.data;
  //     }
  //     if (runResponse.code === -1) {
  //       console.log(runResponse.message);
  //       this.errorHandlingService.errorSuccessSnack('151', false);
  //     }
  //   });
  // }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.selectedItem = null;

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

