import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationItemModel } from '../../global-search.types';
import { AdvancedSearchService } from '../advanced.service';

@Component({
    selector: 'udp-advanced-search-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdvancedSearchNavigationComponent implements OnDestroy {
    types: NavigationItemModel[];

    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private service: AdvancedSearchService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.service.getEntityTypes()
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((types) => {
                this.types = types;
                this.changeDetector.markForCheck();
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onClick(type: string) {
        this.service.setType(type);
    }
}
