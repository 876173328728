import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'udp-select-content',
    templateUrl: './select-content.component.html',
    styleUrls: ['./select-content.component.scss'],
})
export class SelectContentComponent implements OnDestroy {

    @Output()
    backClick: EventEmitter<void> = new EventEmitter();

    @Output()
    saveClick: EventEmitter<void> = new EventEmitter();

    @Output()
    modelChange: EventEmitter<any> = new EventEmitter();

    form: FormGroup;

    private onDestroy$ = new Subject();

    constructor(formBuilder: FormBuilder) {
        this.form = formBuilder.group({
            title: formBuilder.control('', Validators.required),
            source: formBuilder.control(''),
        });
        this.form.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value) => {
                this.modelChange.emit(value);
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
