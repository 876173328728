import * as _ from 'lodash/fp';
import { TaskCatalogItemParameter } from '../../../types/taskCatalog';

export const validateRequiredParameters = (
  parameters: TaskCatalogItemParameter[]
) => {
    const invalidParameters = _.flowRight(
        _.map(
            (parameter: TaskCatalogItemParameter) => {
                return !parameter.value;
            }
        ),
        _.filter((parameter: TaskCatalogItemParameter) => parameter.required)
    )(parameters);

    return _.any(_.identity, invalidParameters);
};
