import { List } from "lodash";


export class ClassDtl {
  activeFlag: string;
  createDate: string;
  createUser: string;
  effectiveDate: string;
  refCodeValMap: Object;
  refCodesetClassCode: string;
  refCodesetClassDesc: string;
  refCodesetClassID: string;
  refCodesetClassName: string;
  refCodesetVerionID: string;
  refDataSetVersionID: string;
  isEditClassType: boolean;
  isViewUpload: boolean;
  isViewCodeRef: boolean;
  isAddCodeRef: boolean;
  subClassLength: string;
  subClasses: Array<SubModule>
}

export class SubModule  {
  activeFlag: boolean;
  createDate: string;
  createUser: string;
  effectiveDate: string;
  refCodeValMap: Array<any>;
  refCodesetClassID: string;
  refCodesetSubClassCode: string;
  refCodesetSubClassID: string;
  refCodesetSubClassName: string;
  isEditSubClassType: boolean;
  isViewUploadSubClass: boolean;
  isViewSubCodeRef: boolean;
  isAddSubCodeRef: boolean;
}

