import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { UdpGrid } from './udp-grid/udp-grid';
import { DATA_TYPES, DISP_COLUMNS_CFG } from './view-edit-schema.constants';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { state, style, transition, trigger, animate } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SharedDataService } from '../shared-data.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'view-edit-schema',
  templateUrl: './view-edit-schema.component.html',
  styleUrls: [
    './view-edit-schema.component.scss',
    './udp-grid/udp-grid.scss'
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ViewEditSchemaComponent extends UdpGrid implements OnInit, OnChanges {

  /**
   * @public
   * @type: {any}
   */
  public config: any;

  /**
   * @public
   * @type: {FormGroup}
   */
  public schemaForm: FormGroup;

  /**
   * @public
   * @type: {string[]}
   */
  public dataTypes: string[] = [];

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public disable: boolean = false;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public enableSelect: boolean = false;

  @Input()
  public entity: any;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  selectedAttributes = [];
  currAttributes: any;

  /**
   * @constructor
   * @param: {fb<FormBuilder>}
   */
  constructor(private fb: FormBuilder,
    private sharedDataService: SharedDataService) {
    super();

    this.dataTypes = DATA_TYPES;
    this.config = DISP_COLUMNS_CFG;
    this.displayedColumns = Object.keys(DISP_COLUMNS_CFG);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.sharedDataService.fileSchema$.subscribe((data: any) => {
      if (data.card === 'LEFT_DATA_ASSET') {
        this.attributesList = data.fileSchema.attribute;
        if (data.selectedAttributes && data.selectedAttributes.LEFT_DATA_ASSET[0]) {
          this.currAttributes = data.selectedAttributes.LEFT_DATA_ASSET[0].attribute;
        } else {
          this.currAttributes = [];
        }
      } else if (data.card === 'RIGHT_DATA_ASSET') {
        this.attributesList = data.fileSchema.attribute;
        if (data.selectedAttributes && data.selectedAttributes.RIGHT_DATA_ASSET[0]) {
          this.currAttributes = data.selectedAttributes.RIGHT_DATA_ASSET[0].attribute;
        } else {
          this.currAttributes = [];
        }
      } else {
        this.attributesList = data.fileSchema.attribute;
      }
      this.selectedAttributes = this.currAttributes;
    });

    this.init();
    this.initDataSourceCfg();
  }

  /**
   * @public
   * @param: {changes<SimpleChanges>}
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && !!changes.attributesList.currentValue) {
      this.init();
    }
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the meta data form.
   */
  private init(): void {
    this.schemaForm = this.fb.group({
      searchValue: new FormControl(''),
      attributes: this.fb.array([])
    });

    // initialize the form attributes
    this.initFormArray();

    // set table data source
    this.dataSource.data = this.attributes.controls as any[];
    const attribute = _.cloneDeep(this.attributes.value);
    if (attribute) {
      attribute.forEach(item => {
        if (item.selected === true) {
          this.selectedAttributes.push(item);
        }
      });
    }
  }

  /**
   * @private
   * @returns: FormArray
   * @description: a helper method that
   * returns the attributes.
   */
  private get attributes(): FormArray {
    return this.schemaForm.get('attributes') as FormArray;
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the meta data list.
   */
  private initFormArray(attributes?: any[], list?: any[]): any[] {
    const formArr: FormArray = this.attributes;
    attributes = attributes || this.attributesList;

    // get list of all the attributes
    // convert them into a group
    for (const attr of attributes) {
      const group: any = {};
      const keys: string[] = _.keys(attr);

      // convert each field into a form control
      _.each(keys, (key) => {
        group[key] = new FormControl(attr[key]);
      });

      // in case if the attribute alias
      // name wasn't returned from the
      // API do add one.
      this.setAttributes(group);

      if (!list) {
        formArr.push(new FormGroup(group));
        for (const item of this.currAttributes) {
          if (attr.name === item.name) {
            group.selected.patchValue('true');
            break;
          }
        }
      } else {
        list.push(new FormGroup(group));
      }
    }
    if (this.sharedDataService.selectedEntity[this.sharedDataService.activeCard].length > 0) {
      this.setSelectedValues();
    }
    return list || [];
  }

  /**
   * @private
   * @param: {group<any>}
   * @returns: void
   * @description: a helper method that
   * sets the attributes.
   */
  private setAttributes(group: any): void {
    if (this.enableSelect) {
      group.selected = this.setControl(
        'selected', group
      );
    }

    group.isNullable = this.setControl(
      'isNullable', group
    );

    group.dataFormat = this.setControl(
      'dataFormat', group
    );

    group.isRequired = this.setControl(
      'isRequired', group
    );

    group.isForeignKey = this.setControl(
      'isForeignKey', group
    );

    group.isSensitiveData = this.setControl(
      'isSensitiveData', group
    );

    group.attributeDataType = this.setControl(
      'attributeDataType', group
    );

    group.attributeDescription = this.setControl(
      'attributeDescription', group
    );

    group.attributePhysicalName = this.setControl(
      'attributePhysicalName', group
    );

    group.isIdentifyingAttribute = this.setControl(
      'isIdentifyingAttribute', group
    );
  }

  setSelectedValues(){
    const attrinutes = this.schemaForm.get('attributes') as FormArray;
    let prviousData: any;
    if (this.sharedDataService.activeCard === 'LEFT_DATA_ASSET') {
      prviousData = this.sharedDataService.selectedEntity.LEFT_DATA_ASSET;
    } else if (this.sharedDataService.activeCard === 'RIGHT_DATA_ASSET') {
      prviousData = this.sharedDataService.selectedEntity.RIGHT_DATA_ASSET;
    }
    prviousData.forEach(item => {
      const index = _.findIndex(attrinutes.value, (e: any) => {
        return e.attributeName === item.attributeName;
      });
      if (index !== -1) {
        const element  = ((this.schemaForm.get('attributes') as FormArray).at(index) as FormGroup);
        ((this.schemaForm.get('attributes') as FormArray).at(index) as FormGroup).get('selected').patchValue(true);
        this.checkedAttribute(true, element);
      }
    });
  }

  /**
   * @private
   * @param: {attr<any>}
   * @param: {group<any>}
   * @returns: any
   * @description: a helper method that
   * sets the control.
   */
  private setControl(attr: any, group: any): any {
    if (!group.hasOwnProperty(attr)) {

      // in case if the data type is missing
      // preset it to string data type
      if (attr === 'attributeDataType') {
        return new FormControl('STRING');
      }

      return new FormControl('');
    }
    return group[attr];
  }

  /**
   *
   * @param e
   * @param element
   */
    checkedAttribute(e, element) {
      if (element.value.selected) {
        this.selectedAttributes.push(element.value);
      } else {
        this.selectedAttributes = this.selectedAttributes.filter(att => att.attributeName !== element.value.attributeName)
      }
      this.selectedAttributes = _.uniqBy(this.selectedAttributes, 'attributeName');

      if (this.selectedAttributes.length === 0) {
        this.sharedDataService.entity[this.sharedDataService.activeCard] =
          this.sharedDataService.entity[this.sharedDataService.activeCard].filter(e => this.entity.entityName !== e.entityName);
      } else {
        this.entity.blendSelectedAttributes = this.selectedAttributes;
        if (this.sharedDataService.entity[this.sharedDataService.activeCard].findIndex
          (e => e.entityName === this.entity.entityName) === -1) {
          this.sharedDataService.entity[this.sharedDataService.activeCard].push(this.entity);
        }
      }
    }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
