import { Component, Input } from '@angular/core';
import { SearchItemModel } from '../fuzzy-search.types';

@Component({
    selector: 'udp-fuzzy-search-option',
    templateUrl: './fuzzy-search-option.component.html',
    styleUrls: ['./fuzzy-search-option.component.scss']
})
export class FuzzySearchOptionComponent {

    @Input()
    item: SearchItemModel;

    @Input()
    last: boolean;

}
