import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { HttpClient} from '@angular/common/http';
import * as IntegrationWorkflowQueries from '../queries/integration-workflow-query';

@Injectable({
  providedIn: 'root'
})
export class IntegrationWorkflowService {
  integrationWorkFlowServiceStore: any;
  constructor(private apollo: Apollo, private httpLink: HttpLink, private http: HttpClient) {}

  public getDataIntegrationList() {
    return this.apollo.use('endpoint9096')
      .watchQuery({
        query: IntegrationWorkflowQueries.READ_All_INTEGRATION_WORKFLOW_QUERY
      });
  }

  public deleteWorkFlow(workFlowId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: IntegrationWorkflowQueries.DELETE_WORKFLOW_QUERY,
      variables: {
        WorkflowID: workFlowId
      },
      refetchQueries: [{
        query: IntegrationWorkflowQueries.READ_All_INTEGRATION_WORKFLOW_QUERY
      }]
    });
  }
  public getWorkFlowMappingDetails(workFlowId) {
    return this.apollo.use('endpoint9096') // .mutate({
      .watchQuery({
        query: IntegrationWorkflowQueries.WORKFLOW_MAPPING_DETAILS_QUERY,
        variables: {
          WorkflowID: workFlowId
        }
      });
  }

  getAssociationMappings(workFlow) {
    return this.apollo.use('endpoint9096')
      .query({
        query: IntegrationWorkflowQueries.FETCH_ASSOCIATION_MAPPING_QUERY,
        variables: {
          uid: workFlow.SourceDataTypeCode
        },
      });
  }

  getAllAssociationMappings() {
    return this.apollo.use('endpoint9096').query({
      query: IntegrationWorkflowQueries.FETCH_ALL_ASSOCIATION_MAPPING_QUERY
    });
  }

  public getAssociationMap(targetEntityType) {
    return this.apollo.use('endpoint9096').query({
      query: IntegrationWorkflowQueries.FETCH_ASSOCIATION_MAP_QUERY,
      variables: {
        associationTargetEntityType : targetEntityType
      }
    });
  }

  public getSubjectIdentificationMapAll() {
    return this.apollo.use('endpoint9096') // .mutate({
      .query({
        query: IntegrationWorkflowQueries.FETCH_ALL_SUBJECT_IDENTIFIACTION_MAP
      });
  }

  public updateIntegrationWorkflowInnerModule(saveObj) {
    return this.apollo.use('endpoint9096').mutate({
      // .query({
      mutation: IntegrationWorkflowQueries.UPDATE_IW_INNER_MODULE,
      variables: {
        Source: saveObj.Source,
        SourceDataTypeCode: saveObj.SourceDataTypeCode,
        Status: saveObj.Status,
        TargetDataTypeCode: saveObj.TargetDataTypeCode,
        WorkflowID: saveObj.WorkflowID,
        WorkflowComponentTypeCode: saveObj.WorkflowComponentTypeCode,
        IntegrationWorkflowComponentName:
          saveObj.IntegrationWorkflowComponentName,
        IntegrationWorkflowComponentStatus:
          saveObj.IntegrationWorkflowComponentStatus,
        WorkflowComponent: saveObj.WorkflowComponent,
        Version: ''
      },
      refetchQueries: [{
        query: IntegrationWorkflowQueries.WORKFLOW_MAPPING_DETAILS_QUERY,
        variables: { WorkflowID : saveObj.WorkflowID }
      }]
    });
  }

  public cloneWorkFlow(workFlowId, workFlowName) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: IntegrationWorkflowQueries.CLONE_WORKFLOW_QUERY,
      variables: {
        WorkflowID: workFlowId,
        WorkflowName: workFlowName
      },
      refetchQueries: [{
        query: IntegrationWorkflowQueries.READ_All_INTEGRATION_WORKFLOW_QUERY
      }]
    });
  }

  public updateStatusAndVersionForWorkflow(status, workflowId, version) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: IntegrationWorkflowQueries.STATUS_VERSION_UPDATE_QUERY,
      variables: {
        Status: status,
        WorkflowID: workflowId,
        Version: version
      },
      refetchQueries: [{
        query: IntegrationWorkflowQueries.READ_All_INTEGRATION_WORKFLOW_QUERY
      }]
    });
  }

  public addNewIntegrationWorkflow(saveObj) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: IntegrationWorkflowQueries.ADD_NEW_INTEGRATION_WORKFLOW,
      variables: {
        source: 2,
        sourceDataTypeCode: saveObj.SourceDataTypeCode,
        status: saveObj.status,
        targetDataTypeCode: saveObj.TargetDataTypeCode,
        workflowDescription: saveObj.WorkflowDescription,
        workflowName: saveObj.WorkflowName,
        workflowVersion: '1.0',
        pipelineCategory: saveObj.pipelineCategory,
        owner: saveObj.Owner
      },
      refetchQueries: [{
        query: IntegrationWorkflowQueries.READ_All_INTEGRATION_WORKFLOW_QUERY
      }]
    });
  }

  public scheduleIntegrationWork(saveObj) {
    console.log('Scheduling job');
    return this.apollo.use('endpoint9096').mutate({
      // .query({
      mutation: IntegrationWorkflowQueries.SCHEDULE_INTEGRATION_WORKFLOW,
      variables: {
        workflowID: saveObj.workflowID,
        JobName: saveObj.workFlowName,
        JobDescription: saveObj.jobDescription,
        CronExpression: saveObj.cronExpression,
        SourceDataOrganization: saveObj.sourcedataOrganization,
        SourceDataType: saveObj.sourceDataType,
        CutoffDate: saveObj.cutoffDate
      }
    });
  }

  public updateScheduleIntegrationWork(saveObj) {
    console.log('Scheduling job');
    return this.apollo.use('endpoint9096').mutate({
      // .query({
      mutation: IntegrationWorkflowQueries.UPDATE_SCHEDULE_INTEGRATION_WORKFLOW,
      variables: {
        workflowID: saveObj.workflowID,
        JobName: saveObj.workFlowName,
        JobDescription: saveObj.jobDescription,
        CronExpression: saveObj.cronExpression,
        IsScheduled: saveObj.IsScheduled,
        ActiveFlag: saveObj.ActiveFlag,
        SourceDataOrganization: saveObj.sourcedataOrganization,
        SourceDataType: saveObj.sourceDataType,
        CutoffDate: saveObj.cutoffDate
      }
    });
  }

  public getSourceDatabases() {
    return this.apollo.use('endpoint9096') // .mutate({
      .query({
        query: IntegrationWorkflowQueries.FETCH_SOURCE_DATABASES_QUERY
      });
  }

  public getTargetDatabases() {
    return this.apollo.use('endpoint9096') // .mutate({
      .query({
        query: IntegrationWorkflowQueries.FETCH_TARGET_DATABASES_QUERY
      });
  }

  public getAllTargetDataEntities() {
    return this.apollo.use('endpoint9096') // .mutate({
      .query({
        query: IntegrationWorkflowQueries.FETCH_ALL_TARGET_DATA_ENTITIES_QUERY
      });
  }

  public getTargetDataEntities() {
    return this.apollo.use('endpoint9096') // .mutate({
      .query({
        query: IntegrationWorkflowQueries.FETCH_TARGET_DATA_ENTITIES_QUERY
      });
  }

  public getSepcificTargetDataEntities(databaseName) {
    this.getTargetDataEntities().subscribe((res: any) => {
      return res.data.internalDatabasesAll.find(
        i => i.DatabaseName == databaseName
      ).EntityGroup.Entity;
    });
  }

  public getTargetDatabaseMappings() {
    return this.apollo.use('endpoint9096').query({
      query: IntegrationWorkflowQueries.FETCH_TARGET_DATABASE_MAPPINGS_QUERY
    });
  }

  public getSubjectQuery(targetEntityName) {
    return this.apollo.use('endpoint9096').query({
      query: IntegrationWorkflowQueries.FETCH_SUBJECT_QUERY,
      variables: {
        entityName : targetEntityName
      }
    });
  }

}
