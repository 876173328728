import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';

import { DrawerExpansionButtonComponent } from './drawer-expansion-button.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatRippleModule
    ],
    declarations: [
        DrawerExpansionButtonComponent
    ],
    exports: [
        DrawerExpansionButtonComponent
    ]
})
export class DrawerExpansionButtonModule { }
