import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * @author: Naga
 */
@Injectable({
  providedIn: 'root'
})
export class UploadOperatorsService {

  /**
   * @constructor
   * @param: {http<HttpClient>} 
   */
  constructor(private http: HttpClient) { }

  /**
   * @public
   * @param: {url<string>}
   * @param: {payload<any>}
   * @returns: Observable<any>
   * @description: a helper method that uploads
   * custom opererators.
   */
  public uploadOperators(url: string, payload: FormData): Observable<any> {
    return this.http.post(`${url}v1.0/upload/operator/v2`, payload);
  }

  /**
   * @public
   * @param: {url<string>}
   * @param: {payload<any>}
   * @returns: Observable<any>
   * @description: a helper method that uploads
   * DAG operators.
   */
  public uploadDagFiles(url: string, payload: FormData): Observable<any> {
    return this.http.post(`${url}`, payload);
  }
}
