import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { FormFieldModule, IconModule } from '@xfusiontech/shared';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
    declarations: [SidebarComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormFieldModule,
        IconModule,
        PerfectScrollbarModule,
    ],
    exports: [SidebarComponent],
})
export class SidebarModule {}
