import * as go from 'gojs';
import { isGroupInternalPort } from '../../../../utils/portTypes/isGroupInternalPort';

const isPolicyApplicable = isGroupInternalPort;

// Only node within the group can connect to group internal IN port
export const groupInternalInPortPolicy = (
  fromNode: go.Node,
  fromPort: go.GraphObject,
  toNode: go.Node,
  toPort: go.GraphObject
) => {
  if (!isPolicyApplicable(fromPort)) {
    return undefined;
  }

  const group = toNode.containingGroup as go.Group;
  if (!group || group.key !== fromNode.key) {
    return false;
  }

  return true;
};
