import * as _ from 'lodash/fp';
import * as go from 'gojs';

import { WorkflowDefinition, WorkflowDefinitionNode } from '../../types/workflowDefinition';
import { Category } from '../types';
import { TASK_GROUP_CATEGORY_PROPERTY } from '../consts/task-groups';

const isTaskGroup = (
  node: WorkflowDefinitionNode
) => node.properties.category === TASK_GROUP_CATEGORY_PROPERTY;

const parseTask = (node: WorkflowDefinitionNode) => ({
  ...node,
  category: Category.Node
});

const parseTaskGroup = (node: WorkflowDefinitionNode) => ({
  ...node,
  category: Category.TaskGroup,
  isGroup: true
});

export const parseNodeDataArray = (
  { nodes }: WorkflowDefinition
): go.ObjectData[] => _.flowRight(
  _.map((node: WorkflowDefinitionNode) => isTaskGroup(node)
    ? parseTaskGroup(node)
    : parseTask(node)
  ),
  _.values
)(nodes);

