import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValueObject } from '../form-field/form-field.types';

@Injectable()
export class SearchService {

    private searchQuery: BehaviorSubject<ValueObject[]>
        = new BehaviorSubject([]);

    getSearchQuery(): Observable<ValueObject[]> {
        return this.searchQuery;
    }

    setSearchQuery(query: ValueObject[]) {
        this.searchQuery.next(query);
    }

}
