import { Injectable } from '@angular/core';
import { DiagramService } from './base.service';

@Injectable()
export class RedrawService extends DiagramService {
    public redraw() {
        if (this.diagram) {
            this.diagram.redraw();
        }
    }
}
