import { HasPermissionDirective } from './has-permission.directive';
import { NgModule } from '@angular/core';

const directives = [HasPermissionDirective];

@NgModule({
    declarations: directives,
    exports: directives,
})
export class HasPermissionModule {}
