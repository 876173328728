import { trigger, transition, style, animate, group } from '@angular/animations';

export const showHideClearButton = trigger('showHideClearButton', [
    transition(':enter', [
        style({
            transform: 'scale(0)',
            transformOrigin: 'right',
            width: 0,
            paddingLeft: 0,
            borderColor: 'transparent'
        }),
        group([
            animate('200ms', style({
                transform: 'scale(1)',
                width: '*',
                paddingLeft: '*'
            })),
            animate('200ms 100ms', style({
                borderColor: '*'
            })),
        ])
    ]),
    transition(':leave', [
        style({
            transform: 'scale(1)',
            transformOrigin: 'right'
        }),
        group([
            animate('100ms', style({
                borderColor: 'transparent'
            })),
            animate('200ms', style({
                transform: 'scale(0)',
                width: 0,
                paddingLeft: 0
            }))
        ])
    ]),
]);
