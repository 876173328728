import { ELEMENT_DATA, PeriodicElement } from './view-data.mock';
import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-view-data',
  templateUrl: './view-data.component.html',
  styleUrls: ['./view-data.component.scss']
})
export class ViewDataComponent implements OnInit, AfterViewInit {

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public hideActionBtns: boolean;

  /**
   * @public
   * @type: {MatPaginator}
   * @viewchild
   */
  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  /**
   * @public
   * @type: {MatTableDataSource<PeriodicElement>}
   */
  public dataSource: MatTableDataSource<PeriodicElement> =
    new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  /**
   * @public
   * @type: {string[]}
   */
  public displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  /**
   * @constructor
   * @param: {data<boolean>}
   * @param: {dialogRef<MatDialogRef<ViewDataComponent>>}
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    private dialogRef: MatDialogRef<ViewDataComponent>) {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  /**
   * @public
   * @param: {v<boolean>}
   * @return: void
   * @description: a helper method that
   * closes the modal.
   */
  public close(v: boolean): void {
    this.dialogRef.close(v);
  }
}
