export const theme = {
    colors: {
        nodeAccent: '#BDCCDC',
        selectedNodeAccent: '#172D43',
        invalidNodeAccent: '#F9ADAD',
        selectedInvalidNodeAccent: '#ED5F5F',
        text: '#535353',
        textLight: '#838383',
        link: '#706A7C',
        scrollbarContainerStroke: '#C5C5C5',
        tooltip: 'rgba(97,97,97,.9)',
        tooltipText: '#fff'
    },
    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
    fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700
    },
    fontSize: {
        tooltip: '10px'
    },
    sizes: {
        scrollbarContainerStroke: 0.25
    }
};
