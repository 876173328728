import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
  Directive
} from '@angular/core';

@Directive({
  selector: '[udpExpansionHeader]'
})
export class ExpansionHeaderDirective {}

@Directive({
  selector: '[udpExpandedContent]'
})
export class ExpandedContentDirective {}

@Component({
  selector: 'udp-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelComponent {

  @Input()
  expanded: boolean;

  @Input()
  margin = 17;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public displayIcon: boolean = true;

  @Output()
  toggleExpansion = new EventEmitter();
}
