export const PERMISSIONS = {
    ADM: {
        VIEW_USER_ROL_PERM: 'UDP.PSO.ADM.VIEW_USER_ROL_PERM', // View Users, Roles and Permissions
        EDIT_USER_ROL_PERM: 'UDP.PSO.ADM.EDIT_USER_ROL_PERM', // Edit Users, Roles and Permissions
        ADD_USER_ROL_PERM: 'UDP.PSO.ADM.ADD_USER_ROL_PERM', // Add Users, Roles and Permissions
        DEL_USER_ROL_PERM: 'UDP.PSO.ADM.DEL_USER_ROL_PERM', // Delete Users, Roles and Permissions
        VIEW_CONFIG: 'UDP.PSO.ADM.VIEW_CONFIG', // View Configuration
        EDIT_CONFIG: 'UDP.PSO.ADM.EDIT_CONFIG', // Edit Configuration
        ADD_CONFIG: 'UDP.PSO.ADM.ADD_CONFIG', // Add Configuration
        DEL_CONFIG: 'UDP.PSO.ADM.DEL_CONFIG', // Delete Configuration
        VIEW_DS: 'UDP.PSO.ADM.VIEW_DS', // View Data Source
        EDIT_DS: 'UDP.PSO.ADM.EDIT_DS', // Edit Data Source
        ADD_DS: 'UDP.PSO.ADM.ADD_DS', // Add Data Source
        DEL_DS: 'UDP.PSO.ADM.DEL_DS', // Delete Data Source
    },
    VIZ: {
        VIZ_DATA: 'UDP.PSO.VIZ.VIZ_DATA', // Visualize Data
        EXP_VIZ: 'UDP.PSO.VIZ.EXP_VIZ', // Export Visualization
        EXP_VIZ_DATA: 'UDP.PSO.VIZ.EXP_VIZ_DATA', // Export Visualization Data
        EMAIL_VIZ: 'UDP.PSO.VIZ.EMAIL_VIZ', // Email Visualization
        SHARE_DATA_ASSET: 'UDP.PSO.VIZ.SHARE_DATA_ASSET', // Share Data Asset
        MANAGE_DATA_ASSET_CATG: 'UDP.PSO.VIZ.MANAGE_DATA_ASSET_CATG', // Manage Data Asset Category
        ADD_ENTITY: 'UDP.PSO.VIZ.ADD_ENTITY', // Add Entity
        EDIT_ENTITY: 'UDP.PSO.VIZ.EDIT_ENTITY', // Edit Entity
    },
    FRUD: {
        VIEW_FRUD: 'UDP.PSO.VIZ.VIEW_FRUD', // View Fraud Analysis
        AED_FRUD: 'UDP.PSO.VIZ.AED_FRUD', // Add, edit, delete Fraud Analysis
    },
    ALRT: {
        VIEW_ALRT: 'UDP.PSO.VIZ.VIEW_ALRT', // View Alerts
        AED_ALRT: 'UDP.PSO.VIZ.AED_ALRT', // Add, Edit, Delete Alerts
    },
};
