import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { map } from 'rxjs/operators';

import { WorkflowService } from '../../services/workflow.service';

@Injectable()
export class ToolbarService {

    constructor(
        private workflowService: WorkflowService
    ) { }

    getProjectName() {
        return this.workflowService.getWorkflow()
            .pipe(
                map((workflow) => workflow.WorkflowName.length ? workflow.WorkflowName
                    : 'Untitled project test'
                )
            );
    }

    setWorkFlowName(WorkflowName) {
        this.workflowService.setWorkFlowName(WorkflowName);
    }

    public openDialog(component: any, dialog: any,
        opts: any = {}): MatDialogRef<typeof component> {

        const obj: any = {
          width: '1200px',
          autoFocus: true,
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'xft-dialog',
          data: null
        };

        return dialog.open(
          component, Object.assign({}, obj, opts)
        );
      }

}
