import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridLayoutComponent } from './grid-layout/grid-layout.component';
import { GridLayoutRow } from './grid-layout-row/grid-layout-row.component';
import { GridLayoutCell } from './grid-layout-cell/grid-layout-cell.component';

const components = [
    GridLayoutComponent,
    GridLayoutRow,
    GridLayoutCell
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: components,
    exports: components
})
export class GridLayoutModule { }
