import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sanitizeFormFieldName' })
export class SanitizeFormFieldNamePipe implements PipeTransform {
    transform(value: string): string {
        return value
            ? value.replace(/[.|&;$%@%"<>+-]/g, '').toLocaleLowerCase()
            : value;
    }
}
