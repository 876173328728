import * as go from 'gojs';

import { regularPortPolicy } from './policies/regularPortPolicy';
import { groupInternalInPortPolicy } from './policies/groupInternalInPortPolicy';
import { groupInternalOutPortPolicy } from './policies/groupInternalOutPortPolicy';
import { LinkValidationPolicy } from './types';

const policies: LinkValidationPolicy[] = [
  groupInternalOutPortPolicy,
  groupInternalInPortPolicy,
  regularPortPolicy
];

export const validateLink = (
  fromNode: go.Node,
  fromPort: go.GraphObject,
  toNode: go.Node,
  toPort: go.GraphObject
): boolean => {
  for (const policy of policies) {
    const policyResult = policy(fromNode, fromPort, toNode, toPort);
    if (policyResult !== undefined) {
      return policyResult;
    }
  }

  return true;
};
