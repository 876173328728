import * as _ from 'lodash';
import { Subscription } from 'rxjs';

/**
 * To unsubscribe from all the subscriptions
 * Generally gets called when component gets destroyed
 */
export function unsubscribeCollection(subscriptions: Subscription[]) {
  if (subscriptions) {
    _.each(subscriptions, (sub) => {
      if (!_.isUndefined(sub)) {
        sub.unsubscribe();
      }
    });
  }
}

export function uniqueArrayBasedOnKey(
  sourceArray: any[],
  key: string | number
): any[] {
  return sourceArray.filter(
    (item, index) =>
      index === sourceArray.findIndex((other) => item[key] === other[key])
  );
}

export function getCustomPythonCodeForDataMapping(
  targetAttributeName: string,
  sourceColumns: any[]
) {
  const targetVarName = targetAttributeName.includes(' ')
    ? targetAttributeName.concat(' ', '_')
    : targetAttributeName;

  const sourceAttrs = [];

  for (const attr of sourceColumns) {
    sourceAttrs.push(attr.attributeName);
  }

  const sourceParams = sourceAttrs.toString();

  return `def tf_${targetVarName} (${sourceParams}):

        ret_var = None

        #please write your code here using Python

        return ret_var`;
}

export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toString();
}

/**
 * @description This method returns all data types for attributes and their unique ids and info
 */
export function getTypeMapping(): any {
  return {
    integer: {
      id: 'I',
      info: 'Integer'
    },
    INTEGER: {
      id: 'I',
      info: 'Integer'
    },
    number: {
      id: 'N',
      info: 'Number'
    },
    NUMBER: {
      id: 'N',
      info: 'Number'
    },
    bigint: {
      id: 'BI',
      info: 'Big Integer'
    },
    BIGINT: {
      id: 'BI',
      info: 'Big Integer'
    },
    boolean: {
      id: 'B',
      info: 'Boolean'
    },
    Boolean: {
      id: 'B',
      info: 'Boolean'
    },
    varchar: {
      id: 'S',
      info: 'String'
    },
    VARCHAR: {
      id: 'S',
      info: 'String'
    },
    varchar2: {
      id: 'S',
      info: 'String'
    },
    VARCHAR2: {
      id: 'S',
      info: 'String'
    },
    text: {
      id: 'S',
      info: 'String'
    },
    TEXT: {
      id: 'S',
      info: 'String'
    },
    string: {
      id: 'S',
      info: 'String'
    },
    STRING: {
      id: 'S',
      info: 'String'
    },
    char: {
      id: 'C',
      info: 'Character'
    },
    CHAR: {
      id: 'C',
      info: 'Character'
    },
    timestamp: {
      id: 'T',
      info: 'Timestamp'
    },
    TIMESTAMP: {
      id: 'T',
      info: 'Timestamp'
    },
    bit: {
      id: '#',
      info: 'Bit'
    },
    BIT: {
      id: '#',
      info: 'Bit'
    },
    other: {
      id: 'O',
      info: 'Other'
    },
    OTHER: {
      id: 'O',
      info: 'Other'
    },
    date: {
      id: 'D',
      info: 'Date'
    },
    DATE: {
      id: 'D',
      info: 'Date'
    },
    real: {
      id: 'R',
      info: 'Real'
    },
    REAL: {
      id: 'R',
      info: 'Real'
    },
    double: {
      id: 'DO',
      info: 'Double'
    },
    DOUBLE: {
      id: 'DO',
      info: 'Double'
    },
    decimal: {
      id: 'DC',
      info: 'Decimal'
    },
    DECIMAL: {
      id: 'DC',
      info: 'Decimal'
    }
  };
}

