import * as go from 'gojs';

import { generateGeometryFromSvgPath } from '../../../utils/generateGeometryFromSvgPath';
import { CPU_ICON, UDP_ICON } from '../../../../consts/icons';
import { theme } from '../../../../consts/theme';
import { ICON_SIZE } from '../../../consts/shared';

const $ = go.GraphObject.make;

const picture = () => $(
    go.Picture,
    {
        alignment: go.Spot.Left,
        alignmentFocus: go.Spot.Left,
        desiredSize: new go.Size(ICON_SIZE, ICON_SIZE),
    },

    new go.Binding('source', 'imagePath'),
    new go.Binding('visible', 'imagePath', (imagePath) => !!imagePath)
);

const defaultIcon = () => $(
    go.Shape,
    {
        stroke: null,
        desiredSize: new go.Size(ICON_SIZE, ICON_SIZE),
        fill: theme.colors.selectedNodeAccent,
        alignment: go.Spot.Left,
        alignmentFocus: go.Spot.Left,
        geometry: generateGeometryFromSvgPath(CPU_ICON.path),
    },
    new go.Binding('visible', 'imagePath', (imagePath) => !imagePath)
);

export const nodeIcons = () => [
    picture(),
    defaultIcon()
];
