import { ParameterType } from '../../../types/form';

export const parameterTypeNames = {
    [ParameterType.String]: 'String',
    [ParameterType.Dictionary]: 'Dictionary',
    [ParameterType.Boolean]: 'Boolean',
    [ParameterType.Integer]: 'Integer',
    [ParameterType.DeltaTime]: 'Delta Time',
    [ParameterType.Datetime]: 'Datetime',
    [ParameterType.Callable]: 'Callable',
    [ParameterType.List]: 'List',
    [ParameterType.Union]: 'Union',
    [ParameterType.Complex]: 'Complex'
};

export const mapParameterTypeToName = (
    parameterType: ParameterType,
) => parameterTypeNames[parameterType]
    ? parameterTypeNames[parameterType]
    : parameterType;
