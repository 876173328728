// tslint:disable: max-line-length
// Outdated
import { environment } from "src/environments/environment";
// No need
export const API_URL = 'https://stageworkflowapi.xaquaudp.io/';

// Actual
export const PYPIPELINE_URL = `${environment.dataPipelinePythonBaseURL}`;
// export const TOKEN_API = `${environment.wso2URL}/oauth2/token`;
export const GATEWAY_API = PYPIPELINE_URL + 'operators/v2';
export const GITHUB_API = PYPIPELINE_URL + 'github/files';
export const GITHUB_MULTIFILES_API = PYPIPELINE_URL + 'github/';

// TODO: change when api will be deployed
export const PYTHON_CODE_URL = `${environment.workflowBaseAPI}dags`;

export const OPERATORS_OLD_API = `${environment.workflowBaseAPI}operators`;


export const OPERATORS_UPLOAD_API = `${environment.operatorsUrl}`;

// No need
export const OPERATORS_URL = API_URL + 'operators';
export const WORKFLOW_URL = API_URL + 'workflow';

export const GO_BACK_URL = 'http://localhost:4300/integration-workflow';
// tslint:enable
