import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ConfigModel } from './models/config.model';

@Component({
  selector: 'xfusiontech-schema-explorer',
  templateUrl: './schema-explorer.component.html',
  styleUrls: ['./schema-explorer.component.scss']
})
export class SchemaExplorerComponent implements OnInit {

  @Input() config: ConfigModel;
  @Input() entities = [];
  @Input() priviousConfigData: any;
  @Input() mappedIds = [];

  @Output() public whenSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() public whenDeleted: EventEmitter<any> = new EventEmitter<any>();

  disableAddEntityGroup = true;

  constructor() {
  }

  ngOnInit() {

  }

  addEntityGroup() {

  }

  onSelectEntity(item: any) {
    this.whenSelected.emit(item);
  }

  onDeleteEntity(id: any) {
    this.whenDeleted.emit(id);
  }

}
