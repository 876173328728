// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatRadioModule, MatTooltipModule } from '@angular/material';
import { ImportDatasetModule } from '../import-dataset/import-dataset.module';
import { SharedDataModule } from '../shared/modules/shared-data/shared-data.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

// Library Imports
import { DataImportModule } from './../../../../data-import';
import { QueryEditorPageModule } from './../../../../query-editor';
import { ButtonModule, FormFieldModule, IconButtonModule } from './../../../../shared';

// Components
import { CreateDataAssetComponent } from './create-data-asset.component';
import { ViewDataComponent } from '../shared/modules/shared-data/view-data/view-data.component';
import { LayoutModule } from '@xfusiontech/interactive-graph-view';

const EXPORTS: any[] = [
  CreateDataAssetComponent
];


@NgModule({
  declarations: [
    ...EXPORTS
  ],
  imports: [
    CommonModule
    , LayoutModule
    , RouterModule
    , ReactiveFormsModule  
    , FormsModule
    , ButtonModule
    , MatRadioModule
    , FormFieldModule
    , DataImportModule
    , SharedDataModule
    , ImportDatasetModule
    , QueryEditorPageModule
    , MatFormFieldModule
    , MatSelectModule
    , IconButtonModule
    , MatTooltipModule
    , MatIconModule
  ],
  exports: [...EXPORTS],
  entryComponents: [ViewDataComponent,CreateDataAssetComponent]
  
})
export class CreateDataAssetModule { }
