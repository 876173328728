import { Pipe, PipeTransform } from '@angular/core';

/**
 * @author: Naga
 */
@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  /**
   * @public
   * @param: {data<any[]>}
   * @param: {value<string>}
   * @returns: any[]
   * @description: a helper method that transforms the
   * search results.
   */
  public transform(data: any[], value: string): any[] {
    return value
      ? (data || []).filter((category: any) => {
          const val: string = (value || '').toLowerCase();
          const dataStr: string = JSON.stringify(category || '').toLowerCase();

          return dataStr.indexOf(val) > -1;
        })
      : data;
  }
}
