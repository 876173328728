import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { WidgetWrapperComponent } from '../wrappers/widget-wrapper.component';
import { WidgetWrapperService } from '../wrappers/widget-wrapper.service';
import { WidgetData, WidgetModel } from '../widget.types';

@Component({
    selector: 'udp-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetComponent implements OnInit {

    @Input()
    solid = true;

    @Input()
    model: WidgetModel;

    @Input()
    removable = true;

    @Input()
    type: string;

    @Output()
    removeClick: EventEmitter<void> = new EventEmitter();

    public isInfoCard: boolean;

    @ViewChild('widgetHost', { static: true, read: ViewContainerRef })
    widgetHost: ViewContainerRef;

    constructor(
        private widgetWrapperService: WidgetWrapperService
    ) { 
    }

    ngOnInit() {
        this.loadComponent();
    }


    loadComponent() {
        if(this.model.category === 'facilities-info-card' || this.model.category === 'licenses-info-card' || this.model.category === 'potential-dup-child-client-info-card'
        || this.model.category === 'potential-dup-licenses-info-card'|| this.model.category === 'potential-dup-facilities-info-card'|| this.model.category === 'information-block') {
            this.isInfoCard = true;
        } else {
            this.isInfoCard = false;
        }
        const componentFactory = this.widgetWrapperService
            .getComponentFactory(this.model.category);
        this.widgetHost.clear();

        const componentRef = this.widgetHost.createComponent
            <WidgetWrapperComponent<WidgetData>>(componentFactory);
        componentRef.instance.data = this.model.data;
      }

}
