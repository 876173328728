import { FormGroup } from '@angular/forms';

export type AttributeType = {
    attributeName: string,
    attributeLabel: string,
    attributeDataType: string,
    displayType: string,
    required?: boolean,
    attributeFormat?: string
    listValues?: string,
    comparator?: object,
    values?: string
};

export type IdentifyingAttributesType = {
    entityClassType: string,
    entityName: string,
    entityLabel: string,
    attributes: AttributeType[]
};

export type DataType = {
    entityClassType: string,
    entityName: string,
    entityLabel: string,
    identifyingAttributes: IdentifyingAttributesType[]
};

export const IconMap: Record<string, string> = {
    address: 'location_on',
    facility: 'corporate_fare',
    organization: 'apartment',
    person: 'person',
    product: 'biotech'
};

export type AdvancedSearchFormModel = {
    fields: FormItemModel[];
    group?: FormGroup
};

export type NavigationItemModel = {
    value: string,
    label: string,
    icon: string,
    selected?: boolean
};

export type FormItemModel = {
    value: string,
    label: string
};
