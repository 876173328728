import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';

import { getNodeType } from './properties-editor.constants';

import { PropertiesEditorService } from '../properties-editor.service';
import { FormField } from '../../../types/form';
import { NodeViewModel } from '../properties-editor.types';
import { CodeEditorPanelService } from '../../code-editor-panel/code-editor-panel.service';
import { ValidationService } from 'src/app/workflow-editor/gojs/services/validation/validation.service';

@Component({
  selector: 'xft-properties-editor',
  templateUrl: './properties-editor.component.html',
  styleUrls: ['./properties-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesEditorComponent implements OnDestroy {
  @Input()
  maximized = false;

  @Output()
  maximizeClick: EventEmitter<void> = new EventEmitter();

  formFields: FormField[] = [];
  form: FormGroup = new FormGroup({});
  nodeViewModel: NodeViewModel;

  /**
   * @public
   * @type: {string}
   */
  public nodeType = 'other';

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private codeEditorPanelService: CodeEditorPanelService,
    private validationService: ValidationService,
    propertiesEditorService: PropertiesEditorService
  ) {
    propertiesEditorService
      .getModel()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((model) => {
        this.formFields = model.formFields;
        this.form = model.form;
        this.changeDetector.markForCheck();
      });

    propertiesEditorService
      .getNodeViewModel()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((nodeViewModel) => {
        if (nodeViewModel) {
          if (nodeViewModel.type.includes('Udp')) {
            const newstr = nodeViewModel.type.replace('Udp', 'UDP');
            nodeViewModel.type = newstr;
          }
          this.nodeViewModel = nodeViewModel;
          this.nodeType = getNodeType(nodeViewModel);
        }
      });

    // Commenting this code because it is processing nothing
    // this.validationService.nodesValid.subscribe(isValid => {
    //   if (isValid) {
    //   }
    // });
  }

  trackByFormFieldId = (idx: number, { id }: FormField) => id;

  onClickDataMapping() {
    this.codeEditorPanelService.openDataMapping();
  }

  /**
   * @public
   * @returns: void
   * @description: a helper method that
   * opens up the data catalog tools.
   */
  public openDataCatalogItem(): void {
    if (!this.form.valid) { return; }
    this.codeEditorPanelService.openDataTools(
      {
        form: this.form,
        fields: this.formFields
      },
      this.nodeType
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
