import { ValueObject } from '../../../types/shared';
import { updateParameters } from './updateParameters';

export const updateModelData = (
    prevModelData: ValueObject,
    values: ValueObject
) => ({
    ...prevModelData,
    dag: {
        ...prevModelData.dag,
        name: values.name,
        parameters: updateParameters(
            prevModelData.dag.parameters,
            values
        )
    }
});
