import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatRadioModule, MatRippleModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GoBackButtonComponent } from './go-back-button/go-back-button.component';
import { IconButtonModule } from '../shared/icon-button/icon-button.module';
import { FormFieldModule } from '../shared/form-field/form-field.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { ButtonModule } from '../shared/button/button.module';
import { ButtonModule as ButtonModule1 } from 'libs/shared/src/components/button/button.module';
import { IconModule } from '../shared/icon/icon.module';
import { ToolbarComponent } from './toolbar.component';
import { ToolbarService } from './toolbar.service';

import { SelectVersionDialogComponent } from '../shared/select-version-dialog/select-version-dialog.component';


const imports = [
    CommonModule,
    ReactiveFormsModule,
    FormFieldModule,
    IconButtonModule,
    IconModule,
    ButtonModule,
    ButtonModule1,
    MatRippleModule,
    MatDialogModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule
];

const innerComponent = [
    SearchBarComponent,
    ButtonsComponent,
    GoBackButtonComponent,
    SelectVersionDialogComponent
];

const exports = [
    ToolbarComponent
];

@NgModule({
    imports,
    declarations: [
        ...innerComponent,
        ...exports
    ],
    providers: [
        ToolbarService
    ],
    exports,
    entryComponents: [SelectVersionDialogComponent]
})
export class ToolbarModule {}
