import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataAssetCategory, Query } from '@xfusiontech/data-visualizer';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfirmDialogService } from '../../components/confirm-dialog/confirm-dialog.service';
import { QueryVariablesDialogService } from '../../components/query-variables-dialog/query-variables-dialog.service';
import { VisualizationDialogRef } from '../../components/visualization-dialog/visualization-dialog.ref';
import { QUERY_EDITOR_PATH, QUERY_ID_PARAM } from '../../consts/paths';
import { QueryInCategory } from '../../models/query.model';
import { CategoryDialogService } from './../../components/category-dialog/category-dialog.service';
import { ShareByEmailDialogService } from './../../components/share-by-email-dialog/share-by-email-dialog.service';
import { ShareInDashboardDialogService } from './../../components/share-in-dashboard-dialog/share-in-dashboard-dialog.service';
import { ShareService } from '../../services/share.service';
import { QueryEditorService } from './query-editor-page.service';

@Component({
    selector: 'igv-query-editor-page',
    templateUrl: './query-editor-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryEditorPageComponent implements OnInit, OnDestroy {
    activeQuery: QueryInCategory;
    QUERY_EDITOR_PATH = QUERY_EDITOR_PATH;
    private onDestroy$ = new Subject();

    constructor(
        public service: QueryEditorService,
        private shareService: ShareService,
        private categoryDialogService: CategoryDialogService,
        private confirmDialogService: ConfirmDialogService,
        private shareByEmailDialogService: ShareByEmailDialogService,
        private shareInDashboardDialogService: ShareInDashboardDialogService,
        private queryVariablesDialogService: QueryVariablesDialogService,
        private route: ActivatedRoute,
        private visualizationDialog: VisualizationDialogRef,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        combineLatest([
            this.service.dataAssetCategories$,
            this.route.paramMap.pipe(
                map(paramMap => paramMap.get(QUERY_ID_PARAM))
            ),
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([categories, activeQueryId]) =>
                    activeQueryId
                        ? (categories.reduce((acc, next) => {
                              if (next.queries.length > 0) {
                                  const query = next.queries.find(
                                      q => q.queryID === activeQueryId
                                  );

                                  if (query) {
                                      return {
                                          categoryId: next.dataAssetCategoryID,
                                          query,
                                      };
                                  }
                              }

                              return acc;
                          }, undefined) as QueryInCategory)
                        : this.createEmptyQuery()
                ),
                filter(query => query != null)
            )
            .subscribe(activeQuery => (this.activeQuery = activeQuery));
    }

    onQueryAddOrUpdate({
        query,
        categoryId,
    }: {
        query: Query | null;
        categoryId: string;
    }) {
        this.service.createOrUpdateQuery(query, categoryId);
    }

    onQueryPreview(query: Query) {
        this.service.getQueryRunResult(query).subscribe(data => {
            data
                ? this.visualizationDialog.show(data)
                : this.showQueryRunErrorDialog();
        });
    }

    onQueryRun(query: Query) {
        this.service.getQueryRunResult(query).subscribe(data => {
            data
                ? this.router.navigate(['/visualization'], {
                      state: {
                          model: data,
                      },
                  })
                : this.showQueryRunErrorDialog();
        });
    }

    showQueryRunErrorDialog() {
        this.confirmDialogService.show({
            title: 'Error',
            message: 'An error occurred when parsing query',
            buttonOK: 'Close',
        });
    }

    onCategoryAddClick() {
        const dialogRef = this.categoryDialogService.show({
            dataAssetType: 'QUERY',
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(result => this.service.createOrUpdateCategory(result));
    }

    onShareQueryInDashboardClick(query: Query) {
        const dialogRef = this.shareInDashboardDialogService.show({
            type: 'QUERY',
            entity: query,
            userQuery: this.shareService.getShareToUsers(),
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(
                ({ sharedTo, users }: { sharedTo: string; users: string[] }) =>
                    this.service.shareQueryInDashboard(sharedTo, users, query)
            );
    }

    onShareQueryByEmailClick(query: Query) {
        const dialogRef = this.shareByEmailDialogService.show({
            type: 'QUERY',
            entity: query,
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(email => !!email),
                take(1)
            )
            .subscribe(email => this.service.sendQueryByEmail(email, query));
    }

    onCategoryEditClick(category?: DataAssetCategory) {
        const dialogRef = this.categoryDialogService.show(
            category || {
                dataAssetType: 'QUERY',
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(result => this.service.createOrUpdateCategory(result));
    }

    onCategoryDeleteClick(category: DataAssetCategory) {
        const dialogRef = this.confirmDialogService.show({
            title: 'Confirm',
            message: 'Are you sure to delete the category? All associated assets will be deleted as well.',
            buttonCancel: 'Cancel',
            buttonOK: 'Delete',
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(() => {
                this.service.deleteCategory(category);
                this.router.navigate([QUERY_EDITOR_PATH]);
            });
    }

    onQueryDeleteClick(query: Query) {
        const dialogRef = this.confirmDialogService.show({
            title: 'Confirm',
            message: 'Are you sure to delete the query?',
            buttonCancel: 'Cancel',
            buttonOK: 'Delete',
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(() => {
                this.service.deleteQuery(query);
                this.router.navigate([QUERY_EDITOR_PATH]);
            });
    }

    onQueryVariablesPopupClick() {
        this.service.getQueryVariablesTypes();

        const dialogRef = this.queryVariablesDialogService.show({
            query: this.activeQuery.query,
            variableTypes$: this.service.getQueryVariablesTypes(),
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.activeQuery = {
                    ...this.activeQuery,
                };
                this.cd.markForCheck();
            });
    }

    private createEmptyQuery(): QueryInCategory {
        return {
            categoryId: null,
            query: {
                dataSourceID: null,
                isQueryTemplate: false,
                queryDescription: null,
                documentId: null,
                queryID: null,
                queryLanguage: null,
                queryName: null,
                sharedFromUserID: null,
                sharedToUserID: null,
                userId: null,
                variables: [],
                query: '',
                isActive: true,
            },
        };
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
