import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { GridModule } from '@progress/kendo-angular-grid';
import { GraphSearchboxModule } from '@xfusiontech/data-visualizer';
import {
    ButtonModule,
    FormFieldModule,
    IconButtonModule,
    IconModule,
    StringArrayToSelectOptionsModule,
} from '@xfusiontech/shared';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { QueryVariablesDialogComponent } from './dialog/query-variables-dialog.component';
import { QueryVariablesDialogFormComponent } from './form/dialog-form.component';
import { SanitizeFormFieldNamePipe } from './form/sanitize-field-name.pipe';
import { QueryVariablesDialogGridParametersCellComponent } from './grid-parameters-cell/grid-parameters-cell.component';
import { QueryVariablesDialogGridComponent } from './grid/dialog-grid.component';
import { QueryVariablesDialogService } from './query-variables-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        ButtonModule,
        FormFieldModule,
        IconModule,
        FormsModule,
        GridModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        IconButtonModule,
        PerfectScrollbarModule,
        GraphSearchboxModule,
        StringArrayToSelectOptionsModule,
    ],
    declarations: [
        QueryVariablesDialogComponent,
        QueryVariablesDialogFormComponent,
        QueryVariablesDialogGridComponent,
        QueryVariablesDialogGridParametersCellComponent,
        SanitizeFormFieldNamePipe,
    ],
    entryComponents: [QueryVariablesDialogComponent],
    exports: [QueryVariablesDialogComponent],
    providers: [QueryVariablesDialogService],
})
export class QueryVariablesDialogModule {}
