import { Component, Inject, Input } from '@angular/core';
import { MatIconRegistry, MAT_SNACK_BAR_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { getIconRegistry } from '../../../utils/iconRegistry';

const featherIcons = [
    'info',
    'alert-triangle',
    'alert-octagon'
];

export type SnackbarMessageType =
    | 'error'
    | 'warning'
    | 'info'
    | 'success';

@Component({
    selector: 'udp-snackbar-message',
    templateUrl: './snackbar-message.component.html',
    styleUrls: ['./snackbar-message.component.scss']
})
export class SnackbarMessageComponent {
    typeToIconMap: Record<SnackbarMessageType, string> = {
        info: 'info',
        error: 'alert-octagon',
        success: 'check_circle',
        warning: 'alert-triangle'
    };
    
    @Input()
    iconType: 'feather' | 'material' = 'feather';

    @Input()
    type: SnackbarMessageType = 'info';

    @Input()
    message: string;

    /**
     * @constructor
     * @param: {data<any>}
     */
    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        @Inject(MAT_SNACK_BAR_DATA) public data: any) {

        if (!!data.iconType) {
          this.iconType = data.iconType;
        }

        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace(
            'feather',
            iconsPath
        )(featherIcons);
    }
}
