import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-select-version-dialog',
  templateUrl: './select-version-dialog.component.html',
  styleUrls: ['./select-version-dialog.component.scss']
})
export class SelectVersionDialogComponent implements OnInit {
  constructor(public fb: FormBuilder, private dialogRef: MatDialogRef<SelectVersionDialogComponent>) { }

  versionForm = this.fb.group({
    version: ['sameVersion']
  });

  ngOnInit() {
  }

  onSubmit(value: boolean) {
    this.dialogRef.close(
      {
        isClose: value,
        value: this.versionForm.value}
      );
  }

}
