import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'udp-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoIconComponent {
  @Input() tooltip: string;
}
