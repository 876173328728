import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

export type SnackbarMessageType =
    | 'error'
    | 'warning'
    | 'info'
    | 'success';

@Component({
  selector: 'xft-snackbar-message',
  templateUrl: './snackbar-message.component.html',
  styleUrls: ['./snackbar-message.component.scss']
})
export class SnackbarMessageComponent {
  typeToIconMap: Record<SnackbarMessageType, string> = {
    info: 'info',
    error: 'alert-octagon',
    success: 'check_circle',
    warning: 'alert-triangle'
  };

  @Input()
  iconType: 'feather' | 'material' = 'feather';

  @Input()
  type: SnackbarMessageType = 'info';

  @Input()
  message: string;

  /**
   * @constructor
   * @param: {data<any>}
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    if (!!data.iconType) {
      this.iconType = data.iconType;
    }
  }
}
