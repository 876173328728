import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Query, QueryVariable } from '@xfusiontech/data-visualizer';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { QueryVariableType } from '../../../models/query-variable.model';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';

@Component({
    selector: 'igv-query-variables-dialog',
    templateUrl: './query-variables-dialog.component.html',
    styleUrls: ['./query-variables-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class QueryVariablesDialogComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    showForm: boolean;
    editedVariable: QueryVariable;
    variableTypes: QueryVariableType[];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public params: {
            query: Query;
            variableTypes$: Observable<QueryVariableType[]>;
        },
        private confirmDialogService: ConfirmDialogService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.params.variableTypes$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(variableTypes => (this.variableTypes = variableTypes));
    }

    onAddQueryClick() {
        this.showForm = true;
    }

    onVariableDeleteClick(variable: QueryVariable) {
        const dialogRef = this.confirmDialogService.show({
            title: 'Confirm',
            message: 'Are you sure to delete variable?',
            buttonCancel: 'Cancel',
            buttonOK: 'Delete',
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(() => {
                this.params.query.variables = this.params.query.variables.filter(
                    v => v !== variable
                );
                this.cd.markForCheck();
            });
    }

    onVariableEditClick(variable: QueryVariable) {
        this.editedVariable = variable;
        this.showForm = true;
    }

    onBackClick() {
        this.showForm = false;
        this.editedVariable = null;
    }

    onVariableAdded(variable: QueryVariable) {
        this.params.query.variables = this.params.query.variables
            ? [...this.params.query.variables, variable]
            : [variable];
        this.showForm = false;
    }

    onVariableEdited(variable: QueryVariable) {
        const index = this.params.query.variables.indexOf(this.editedVariable);
        const tmp = [...this.params.query.variables];
        tmp[index] = variable;
        this.params.query.variables = [...tmp];
        this.showForm = false;
        this.editedVariable = null;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
