/**
 * @author: Naga
 * @interface
 */
export interface EnvParams {
  token: string;
  branch: string;
  repository: string;
  customer_id: string;
}

/**
 * @exports
 */
export default class EnvConfig implements EnvParams {

  /**
   * @public
   * @type: {string}
   */
  public token: string;

  /**
   * @public
   * @type: {string}
   */
  public branch: string;

  /**
   * @public
   * @type: {string}
   */
  public repository: string;

  /**
   * @public
   * @type: {string}
   */
  public customer_id: string;

  /**
   * @constructor
   * @param: {cfg<any>}
   */
  constructor(cfg: Partial<EnvParams> = {}) {
    Object.assign(this,
      {
        branch: 'main',
        repository: 'upload-op-test',
        customer_id: 'xfusiontest01',
        token: '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'
      },
      cfg
    );
  }
}
