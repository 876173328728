import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AuthService } from '@xfusiontech/shared';
import { Observable } from 'rxjs';
import { LOGIN_PATH } from './../consts/paths';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const token = this.authService.getAccessToken();
        return (
            token != null ||
            this.router.navigate([LOGIN_PATH], {
                queryParams: { returnUrl: state.url },
            })
        );
    }
}
