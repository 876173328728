import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItemModel } from '@xfusiontech/shared';
@Component({
    selector: 'igv-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    @Input()
    menuItems: MenuItemModel[];

    @Input()
    homeItemPath: string;

    @Input()
    databaseName: string;
}
