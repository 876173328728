import { Injectable } from '@angular/core';
import { WIDGET_LIBRARY, Person360_WIDGET_LIBRARY,BUSINESS_GLOSSARY_WIDGET_LIBRARY } from './select-cell-type-dialog.consts';
import { WidgetLibrary } from './select-cell-type-dialog.types';

@Injectable()
export class SelectCellTypeDialogService {

    type

    getWidgetLibrary(): WidgetLibrary {
        return [...WIDGET_LIBRARY];
    }

    getPerson360WidgetLibrary(): WidgetLibrary {
        return [...Person360_WIDGET_LIBRARY];
    }

    getBusinessGlossaryLibrary(): WidgetLibrary{
        return  [...BUSINESS_GLOSSARY_WIDGET_LIBRARY];
    }

    setType(type:String) {
        this.type = type;
    }

    getType(): string {
        return this.type;
    }

}
