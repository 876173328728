import * as go from 'gojs';

import { linkEnding } from './parts/link/linkEnding';
import { theme } from '../../consts/theme';
import { validationStrokeBinding } from './parts/link/bindings';

const $ = go.GraphObject.make;

const arrow = () => $(
    go.Shape,
    {
        toArrow: 'Feather',
        stroke: theme.colors.link,
        strokeWidth: 1,
        scale: 1.5
    },
    validationStrokeBinding(),
);

const selectionAdornmentTemplate = () => $(
    go.Adornment,
    $(
        go.Shape,
        {
            isPanelMain: true,
            stroke: 'dodgerblue',
            strokeWidth: 2.5,
        },
        validationStrokeBinding(),
    ),
    $(
        go.Shape,
        {
            toArrow: 'Feather',
            stroke: 'dodgerblue',
            scale: 2.5,
        },
        validationStrokeBinding(),
    )
);

export const createLinkTemplate = () =>
    $(
        go.Link,
        {
            routing: go.Link.Orthogonal,
            fromEndSegmentLength: 24,
            toEndSegmentLength: 24,
            corner: 16,
            toShortLength: 2
        },
        linkEnding(),
        arrow(),
        {
            selectionAdornmentTemplate: selectionAdornmentTemplate()
        }
    );
