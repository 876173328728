import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
    MatAutocompleteSelectedEvent,
    MatFormFieldControl,
} from '@angular/material';
import * as _ from 'lodash/fp';
import { BehaviorSubject, Subject } from 'rxjs';
import { Option } from './../form-field.types';

@Component({
    selector: 'udp-multi-autocomplete',
    templateUrl: './multi-autocomplete.component.html',
    styleUrls: ['./multi-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MultiAutocompleteComponent,
        },
    ],
})
export class MultiAutocompleteComponent
    implements OnDestroy, MatFormFieldControl<Option[]>, ControlValueAccessor {
    @Input()
    options: Option[] = [];

    @Input()
    placeholder: string;

    @Input()
    required: boolean;

    @ViewChild('multiAutocomplete', { static: false })
    elementRef: ElementRef;

    displayAutocompleteValue = _.get('name');

    autocompleteSelectedItems: Option[] = [];
    autocompleteFilteredItems$ = new BehaviorSubject<Option[]>([]);

    value: Option[];
    stateChanges = new Subject<void>();
    id: string;
    focused: boolean;
    empty: boolean;
    shouldLabelFloat: boolean;
    errorState: boolean;
    autofilled?: boolean;
    disabled: boolean;
    controlType = 'udp-multi-autocomplete';

    onChange: (value: any) => void = () => {};
    onTouched: () => void = () => {};

    private optionHeight = 48;
    private defaultVisibleOptions = 5;
    height = this.optionHeight * this.defaultVisibleOptions;

    private _inputValue: string;
    public get inputValue(): string {
        return this._inputValue;
    }
    public set inputValue(value: string) {
        if (typeof value !== 'string') {
            return;
        }

        this.filterAutocompleteItems(value);
        this._inputValue = value;
    }

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    filterAutocompleteItems(value: string) {
        const autocompleteSelectedItemsValues = this.autocompleteSelectedItems.map(
            x => x.value
        );
        const unusedElements = this.options.filter(
            option => !autocompleteSelectedItemsValues.includes(option.value)
        );

        if (value == null || value === '') {
            this.autocompleteFilteredItems$.next(unusedElements);
        } else {
            const filterValue = value.toLowerCase().replace(/\s/g, '');
            const result = unusedElements.filter(option =>
                option.name
                    .toLowerCase()
                    .replace(/\s/g, '')
                    .includes(filterValue)
            );

            this.height =
                Math.min(result.length, this.defaultVisibleOptions) *
                this.optionHeight;
            this.autocompleteFilteredItems$.next(result);
        }
    }

    writeValue(autocompleteSelectedItems: Option[]): void {
        this.autocompleteSelectedItems = autocompleteSelectedItems || [];
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    setDescribedByIds(ids: string[]): void {
        const controlElement = this.elementRef.nativeElement;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    }

    onMultiAutocompleteSelected(event: MatAutocompleteSelectedEvent) {
        this.autocompleteSelectedItems.push(event.option.value);
        this.onTouched();
        this.onChange(this.autocompleteSelectedItems);
        this.inputValue = '';
    }

    onMultiAutocompleteRemove(item: Option) {
        this.autocompleteSelectedItems = this.autocompleteSelectedItems.filter(
            i => i !== item
        );
        this.inputValue = '';
        this.onTouched();
        this.onChange(this.autocompleteSelectedItems);
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }
}
