import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';

import { TaskCatalogItemParameter, TaskCatalogItemProperties } from '../../../types/taskCatalog';
import { Diagram } from '../../Diagram';
import { DiagramService } from '../base.service';
import { validateRequiredParameters } from './validateProperties';
import { BehaviorSubject } from 'rxjs';
import { LinkValidationService } from './link-validation.service';

@Injectable()
export class ValidationService extends DiagramService {

    private nodesValid$ = new BehaviorSubject<boolean>(true);
    private workflowValid$ = new BehaviorSubject<boolean>(true);

    get nodesValid() {
        return this.nodesValid$.asObservable();
    }

    get linksValid() {
        return this.linkValidationService.linksValid$;
    }

    get workflowValid() {
        return this.workflowValid$.asObservable();
    }

    validateNodeProperties = _.memoize((
        properties: TaskCatalogItemProperties
    ) => validateRequiredParameters(properties.parameters));

    validateWorkflowProperties = _.memoize((
        parameters: TaskCatalogItemParameter[]
    ) => validateRequiredParameters(parameters));

    bindDiagram(diagram: Diagram): void {
        super.bindDiagram(diagram);
        this.diagram.validation = {
            nodeProperties: this.validateNodeProperties,
            workflowProperties: this.validateWorkflowProperties,
            link: (link: go.Link) =>
                this.linkValidationService.validateLink(link),
            revalidateLinks: () =>
              this.linkValidationService.revalidateLinks(this.diagram),
        };
    }

    constructor(
      public linkValidationService: LinkValidationService
    ) {
      super();
    }

    setNodesValid(valid: boolean) {
        this.nodesValid$.next(valid);
    }

    setWorkflowValid(valid: boolean) {
        this.workflowValid$.next(valid);
    }

}
