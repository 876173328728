import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatCheckboxModule, MatMenuModule } from '@angular/material';

// Library Imports
import { ExpansionPanelModule, FormFieldModule, IconButtonModule, IconModule } from '../../../../shared';

// Components
import { SidebarWithFilterComponent } from './sidebar-with-filter.component';

// Filters
import { SearchFilterPipe } from './../shared/filters/search/search.filter';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DataCategoryMenuComponent } from './data-category-menu/data-category-menu.component';
import { DataAssetTypeMenuComponent } from './data-asset-type-menu/data-asset-type-menu.component';
import { DataAssetItemMenuComponent } from './data-asset-item-menu/data-asset-item-menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

/**
 * @author: Naga
 */
@NgModule({
  declarations: [
    SearchFilterPipe
    , SidebarWithFilterComponent, ContextMenuComponent, DataCategoryMenuComponent, DataAssetTypeMenuComponent,DataAssetItemMenuComponent
  ],
  imports: [
    CommonModule
    , IconModule
    , MatMenuModule
    , FormFieldModule
    , IconButtonModule
    , MatCheckboxModule
    , ReactiveFormsModule
    , ExpansionPanelModule
    , PerfectScrollbarModule
    , DragDropModule
  ],
  exports: [
    SidebarWithFilterComponent
  ]
})
export class SidebarWithFilterModule {}
