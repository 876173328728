import { NgModule } from '@angular/core';

import { MouseHoldDirective } from './mouse-hold.directive';

const directives = [
    MouseHoldDirective
];

@NgModule({
    declarations: directives,
    exports: directives
})
export class MouseHoldModule { }
