import { Component, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Option } from '../../shared/form-field/form-field.types';
import { NodeSearchingService } from '../../../gojs/services/node-searching.service';
import * as go from 'gojs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from 'lodash/fp';
import { camelCaseToLabel } from '../../../utils/camelCaseToLabel';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';

const nodeDataToOption = (data: go.ObjectData): Option<go.ObjectData> => ({
    name: camelCaseToLabel(data.type),
    value: data,
    icon: 'cpu',
    imagePath: data.imagePath,
    description: data.properties.description,
});

@Component({
    selector: 'xft-toolbar-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
    options$ = new BehaviorSubject<Option<go.ObjectData>[]>([]);
    mongoExpressURL: any;
    constructor(private nodeSearchingService: NodeSearchingService,
                private sanitizer: DomSanitizer,
                public dialog: MatDialog) {
        this.nodeSearchingService.filteredNodes$.subscribe(
            _.flowRight(
                this.options$.next.bind(this.options$),
                _.map(nodeDataToOption),
            )
        );
    }

    onSearchChange($event: Event) {
        const search = ($event.target as HTMLInputElement).value;
        this.nodeSearchingService.setSearch(
            search
        );
    }

    onOptionSelected($event: MatAutocompleteSelectedEvent) {
        const { value: { id } } = $event.option.value;
        this.nodeSearchingService.focusNodeById(id);
    }

    onClickJsonEditor(templateNested: any) {
        const baseWFUrl = 'http://54.218.128.93:32760/db/catalog/operators';
        console.log(baseWFUrl);
        this.mongoExpressURL = this.sanitizer.bypassSecurityTrustResourceUrl(baseWFUrl);
        this.openModal2(templateNested);
    }

    openModal2(template: TemplateRef<any>) {
        const dialogRef = this.dialog.open(template, {
            panelClass: 'custom-modalbox-rule',
             // backdrop: "static",
           });

      }
}
