import { ParameterType } from '../../../types/form';

const complexTypes = [ParameterType.Union, ParameterType.Complex];

export const getFullParameterType = (
    type: ParameterType,
    typeCategory?: ParameterType
) => typeCategory && complexTypes.includes(typeCategory)
    ? typeCategory
    : type;
