import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { TaskCatalogItem, TaskCategoryItem, TaskOperatorItem, TaskSubcategoryItem } from '../types/taskCatalog';
import { ApiService } from './api.service';
import { TaskCatalogItemParameter } from './../types/taskCatalog';
import { ParameterType } from '../types/form';
import { mockParameter } from './mocks/operatorsMockNew';

const parseParameters = (val: TaskOperatorItem) => {
    if (!val || !val.parameters) {
        return val;
    }

    val.parameters.forEach((param: TaskCatalogItemParameter) => {
        const { type, userValueType, typeCategory } = param;
        if (!type || userValueType) {
            return;
        }

        param.userValueType = typeCategory === ParameterType.Union
            ? undefined
            : type;
    });
    return val;
};

const mapOperatorToTaskCatalogItem = _.curry((
    category: string,
    subcategory: string,
    {
        name,
        description,
        parameters
    }: TaskOperatorItem
) => ({
    type: name,
    imagePath: null,
    properties: {
        description,
        name,
        category,
        subcategory,
        parameters: parameters || [mockParameter(subcategory || category)]
    }
}));

const parseInputData = (item: TaskCategoryItem) => {
    let operators: TaskCatalogItem[] = [];
    if (item.operators) {
        operators = item.operators.map(
            mapOperatorToTaskCatalogItem(item.name, null)
        );
    }

    if (item.subcategory) {
        const subcategoryOperators = _.flatMap((sub: TaskSubcategoryItem) =>
            sub.operators.map(
                mapOperatorToTaskCatalogItem(item.name, sub.name)
            ))(item.subcategory);
        return operators.concat(subcategoryOperators);
    }
    return operators;
};

// TODO: for mocked data JSON parse is not needed, but when the functionality
// is connected to API, input need to be parsed at some point, if not here
// then in parseInputData function
const parseTaskCatalog =
    _.flowRight(
        parseParameters,
        parseInputData,
        // JSON.parse
    );

@Injectable()
export class TaskCatalogService {

    constructor(
        private apiService: ApiService) { }

    private taskCatalog: BehaviorSubject<TaskCatalogItem[]>
        = new BehaviorSubject([]);

    getTaskCatalog(): Observable<TaskCatalogItem[]> {
        return this.taskCatalog;
    }

    getTaskByType(type: string): TaskCatalogItem {
        return _.find(
            (task: TaskCatalogItem) => task.type === type
        )(this.taskCatalog.getValue());
    }

    fetchTaskCatalog() {
        this.apiService.fetchOperators().subscribe(
            _.flowRight(this.taskCatalog.next.bind(this.taskCatalog),
                _.flatMap(parseTaskCatalog))
        );
    }
}
