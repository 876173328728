import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-udp-data-transformation-operator',
  templateUrl: './udp-data-transformation-operator.component.html',
  styleUrls: ['./udp-data-transformation-operator.component.css']
})
export class UdpDataTransformationOperatorComponent implements OnInit {
  // Very important data coming from parent components
  @Input() currentTaskID: string;
  @Input() previousTaskIDs: any; // If Transformation Operator is connected to an operator
  @Input() currentFileConfig: any;
  @Input() currentOperatorCode: any;
  @Input() customerId: string;
  @Input() userId: string;
  @Input() workflowDef: string;
  @Input() nodes: string;

  isDataStudioOpen = false;
  constructor() {}

  ngOnInit() {
  }


  openCloseDataStudio() {
    this.isDataStudioOpen = !this.isDataStudioOpen;
  }
}
