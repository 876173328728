import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { QueryVariable } from '@xfusiontech/data-visualizer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QueryVariableType } from './../../../models/query-variable.model';
import { SanitizeFormFieldNamePipe } from './sanitize-field-name.pipe';

@Component({
    selector: 'igv-query-variables-dialog-form',
    templateUrl: './dialog-form.component.html',
    styleUrls: ['./dialog-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SanitizeFormFieldNamePipe],
})
export class QueryVariablesDialogFormComponent implements OnInit, OnDestroy {
    form: FormGroup;
    submitButtonText = 'Add';

    @Input()
    editVariable: QueryVariable;

    @Input()
    parameters: QueryVariableType[];

    @Output()
    submitVariable = new EventEmitter();

    @Output()
    backClick = new EventEmitter();

    selectedQueryVariableType: QueryVariableType | undefined;
    private onDestroy$ = new Subject();

    get queryVariableParametersForm(): FormGroup {
        return this.form.controls['queryVariableParameters'] as FormGroup;
    }

    set queryVariableParametersForm(group: FormGroup) {
        this.form.controls['queryVariableParameters'] = group;
    }

    get queryVariableParametersFormFields() {
        return this.queryVariableParametersForm &&
            this.queryVariableParametersForm.controls
            ? Object.keys(this.queryVariableParametersForm.controls)
            : [];
    }

    get variableTypes() {
        return this.parameters ? this.parameters.map(x => x.datatype) : [];
    }

    constructor(
        private formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private sanitizeFormFieldNamePipe: SanitizeFormFieldNamePipe
    ) {}

    onSubmitClick() {
        this.submitVariable.emit({
            ...this.form.getRawValue(),
            queryVariableValue: null,
        });
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.formBuilder.group({
            queryVariableName: ['', Validators.required],
            queryVariableDataType: ['', Validators.required],
            queryVariableParameters: this.formBuilder.group({}),
        });

        this.form
            .get('queryVariableDataType')
            .valueChanges.pipe(takeUntil(this.onDestroy$))
            .subscribe(variableType => {
                const selectedQueryVariableType = this.matchTypeToQueryVariableType(
                    variableType
                );
                this.createParametersForm(selectedQueryVariableType);
                this.selectedQueryVariableType = selectedQueryVariableType;
                this.cd.markForCheck();
            });

        if (this.editVariable) {
            this.submitButtonText = 'Edit';

            this.form.patchValue({
                queryVariableName: this.editVariable.queryVariableName,
                queryVariableDataType: this.editVariable.queryVariableDataType,
                queryVariableParameters: this.editVariable
                    .queryVariableParameters,
            });
        }
    }

    createParametersForm(
        selectedQueryVariableType: QueryVariableType | undefined
    ) {
        if (
            selectedQueryVariableType == null ||
            selectedQueryVariableType.queryVariableParameter == null
        ) {
            this.form.controls['queryVariableParameters'] = new FormGroup({});
            return;
        }

        const formGroup = selectedQueryVariableType.queryVariableParameter.reduce(
            (acc, val) => {
                const validators = val.isRequired ? [Validators.required] : [];
                acc[
                    this.sanitizeFormFieldNamePipe.transform(val.parameterName)
                ] = new FormControl(null, validators);
                return acc;
            },
            {}
        );

        this.queryVariableParametersForm = this.formBuilder.group(formGroup);
        this.queryVariableParametersForm.statusChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => this.form.updateValueAndValidity());
    }

    matchTypeToQueryVariableType(type: string) {
        return this.parameters.find(parameter => parameter.datatype === type);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
