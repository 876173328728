import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconComponent } from './icon.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        IconComponent
    ],
    imports: [
        MatIconModule,
        CommonModule,
        MatTooltipModule
    ],
    exports: [
        IconComponent
    ]
})
export class IconModule {}
