import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DropdownButtonOption } from './dropdown-button.types';

@Component({
    selector: 'udp-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropdownButtonComponent {
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() options: DropdownButtonOption[];
    @Output() itemSelected: EventEmitter<string> = new EventEmitter();

    public popupConfig = { align: 'center' };
}
