import { QueryVariableType } from '../../models/query-variable.model';

export const queryVariables: QueryVariableType[] = [
    {
        datatype: 'Node',
        queryVariableParameter: [
            {
                parameterName: 'Node-Categories',
                isRequired: false,
                parameterDataTypeValue: 'array[string]',
            },
        ],
    },
    {
        datatype: 'Nodeset',
        queryVariableParameter: [
            {
                parameterName: 'Node-Categories',
                isRequired: false,
                parameterDataTypeValue: 'array[string]',
            },
        ],
    },
    {
        datatype: 'Edge',
        queryVariableParameter: [
            {
                parameterName: 'Edge-Types',
                isRequired: false,
                parameterDataTypeValue: 'array[string]',
            },
        ],
    },
    {
        datatype: 'Edgeset',
        queryVariableParameter: [
            {
                parameterName: 'Edge-Types',
                isRequired: false,
                parameterDataTypeValue: 'array[string]',
            },
        ],
    },
    {
        datatype: 'Enum',
        queryVariableParameter: [
            {
                parameterName: 'Values',
                isRequired: true,
                parameterDataTypeValue: 'array[]',
            },
            {
                parameterName: 'Default',
                isRequired: false,
                parameterDataTypeValue: 'String',
            },
        ],
    },
    {
        datatype: 'Boolean',
        queryVariableParameter: [
            {
                parameterName: 'Default',
                isRequired: false,
                parameterDataTypeValue: 'boolean',
            },
        ],
    },
    {
        datatype: 'Date',
        queryVariableParameter: [
            {
                parameterName: 'Format',
                isRequired: true,
                parameterDataTypeValue: 'String',
                parameterLOV: ['native', 'timestamp', 'timestamp-ms', 'iso'],
                parameterDataTypeValueDesc:
                    'The serialization format of the date-time in the query, must be one of the value from the list',
            },
            {
                parameterName: 'timezone',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'A string in +HH:MM or -HH:MM format. If omitted and the format is native, a LocalDateTime object is created.',
            },
            {
                parameterName: 'default',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'A default value (expressed in yyyy-mm-ddThh:mm:ss format.',
            },
            {
                parameterName: 'placeholder',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The placeholder text to use in the form.',
            },
            {
                parameterName: 'min',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The minimum accepted value (expressed in yyyy-mm-ddThh:mm:ss format).',
            },
            {
                parameterName: 'max',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The minimum accepted value (expressed in yyyy-mm-ddThh:mm:ss format).',
            },
        ],
    },
    {
        datatype: 'Datetime',
        queryVariableParameter: [
            {
                parameterName: 'Format',
                isRequired: true,
                parameterDataTypeValue: 'String',
                parameterLOV: ['native', 'timestamp', 'timestamp-ms', 'iso'],
                parameterDataTypeValueDesc:
                    'The serialization format of the date-time in the query, must be one of the value from the list.',
            },
            {
                parameterName: 'timezone',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'A string in +HH:MM or -HH:MM format. If omitted and the format is native, a LocalDateTime object is created.',
            },
            {
                parameterName: 'default',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'A default value (expressed in yyyy-mm-ddThh:mm:ss format',
            },
            {
                parameterName: 'placeholder',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The placeholder text to use in the form.',
            },
            {
                parameterName: 'min',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The minimum accepted value (expressed in yyyy-mm-ddThh:mm:ss format).',
            },
            {
                parameterName: 'max',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The maximum accepted value (expressed in yyyy-mm-ddThh:mm:ss format).',
            },
        ],
    },
    {
        datatype: 'Number',
        queryVariableParameter: [
            {
                parameterName: 'default',
                isRequired: false,
                parameterDataTypeValue: 'Number',
                parameterDataTypeValueDesc: 'A default value.',
            },
            {
                parameterName: 'placeholder',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc:
                    'The placeholder text to use in the form.',
            },
            {
                parameterName: 'min',
                isRequired: false,
                parameterDataTypeValue: 'Number',
                parameterDataTypeValueDesc: 'The minimum accepted value.',
            },
            {
                parameterName: 'max',
                isRequired: false,
                parameterDataTypeValue: 'Number',
                parameterDataTypeValueDesc: 'The maximum accepted value.',
            },
        ],
    },
    {
        datatype: 'String',
        queryVariableParameter: [
            {
                parameterName: 'default',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc: 'A default value.',
            },
        ],
    },
    {
        datatype: 'List',
        queryVariableParameter: [
            {
                parameterName: 'values',
                isRequired: true,
                parameterDataTypeValue: 'array[]',
                parameterDataTypeValueDesc:
                    'An array of values to choose from or an array of value + label.',
            },
            {
                parameterName: 'default',
                isRequired: false,
                parameterDataTypeValue: 'String',
                parameterDataTypeValueDesc: 'A default value.',
            },
        ],
    },
];
