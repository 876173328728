import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AlignmentService } from '../../../gojs/services/alignment.service';
import { AlignmentType } from '../../../gojs/utils/align';

@Component({
    selector: 'xft-alignment',
    templateUrl: './alignment.component.html',
    styleUrls: ['./alignment.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AlignmentComponent implements OnDestroy {

    isDisabled = false;
    Alignment = AlignmentType;

    private onDestroy$ = new Subject<void>();

    constructor(
        private alignmentService: AlignmentService
    ) {
        this.isDisabled = true;

        this.alignmentService.getCanAlignSelection()
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((canAlign) => this.isDisabled = !canAlign);
    }

    alignSelection(type: AlignmentType) {
        this.alignmentService.alignSelection(type);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
