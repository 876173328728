import { Component, Input } from '@angular/core';

import { EntityTextIndicatorWrapperData } from './entity-text-indicator-wrapper.types';
import { WidgetWrapperComponent } from '../widget-wrapper.component';

@Component({
    selector: 'udp-entity-text-indicator-wrapper',
    templateUrl: './entity-text-indicator-wrapper.component.html'
})
export class EntityTextIndicatorWrapperComponent
    extends WidgetWrapperComponent<EntityTextIndicatorWrapperData> {

    @Input()
    data: EntityTextIndicatorWrapperData;

}
