import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MatMenuTrigger,
  throwMatDialogContentAlreadyAttachedError
} from '@angular/material';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';

@Component({
  selector: 'lib-data-category-menu',
  templateUrl: './data-category-menu.component.html',
  styleUrls: ['./data-category-menu.component.scss']
})
export class DataCategoryMenuComponent implements OnInit {
  @Input() item: any;
  @Input() asset: any;
  @Input() searchItem: string;
  @Output()
  public onCatClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onAssetClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public toggleExpansion: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onNewAsset: EventEmitter<any> = new EventEmitter<any>();

  contextMenuType: any;
  menuData: any;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  typeMap: any = { DF: 'File', DS: 'Dataset', QRY: 'Query' };

  @Input()
  public isDatasetOperator: boolean = false;

  dropIDList: any = [];
  constructor() {}

  ngOnInit() {
    this.dropIDList = this.isDatasetOperator
      ? ['udp-dataset-operator']
      : ['udp-dd-list-2', 'udp-dd-list-3'];
  }

  onToggleExpansion() {
    this.asset.expanded = !this.asset.expanded;
    if (this.asset.expanded) {
      this.toggleExpansion.emit();
    }
  }

  onRightClick(event: MouseEvent, dataAssetType: string, category: any) {
    event.preventDefault();
    if (
      this.isDatasetOperator ||
      dataAssetType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE ||
      dataAssetType === DATASOURCE.FILE_ASSET_CODE ||
      dataAssetType === DATASOURCE.QUERY_CODE ||
      dataAssetType === DATASOURCE.RDS_CODE
    )
      return;
    this.contextMenuType = this.typeMap[dataAssetType];
    this.menuData = { category: category, dataAssetType };
    this.matMenuTrigger.openMenu();
  }
}
