import * as go from 'gojs';

import { ICON_SIZE, NODE_PADDING, NODE_WIDTH, NODE_CONTENT_WIDTH } from '../../../consts/shared';
import { camelCaseToLabel } from '../../../../utils/camelCaseToLabel';
import { theme } from '../../../../consts/theme';
import { nodeIcons } from './nodeIcons';
import { tooltip } from '../shared/tooltip';

const $ = go.GraphObject.make;

const label = () => $(
    go.TextBlock,
    {
        font: `${theme.fontWeight.light} 14px ${theme.fontFamily}`,
        stroke: theme.colors.text,
        alignment: new go.Spot(0, 0, ICON_SIZE + NODE_PADDING, 0),
        alignmentFocus: go.Spot.TopLeft
    },
    new go.Binding('text', '', (data) => {
        const { properties: { name }, type } = data;
        return name || camelCaseToLabel(type);
    })
);

const descriptionBinding = () => new go.Binding(
    'text',
    '',
    (data) => {
        const { properties: { name, description }, type } = data;
        return name
            ? camelCaseToLabel(type)
            : description;
    }
);

const description = () => $(
    go.TextBlock,
    {
        font: `${theme.fontWeight.light} 12px ${theme.fontFamily}`,
        stroke: theme.colors.textLight,
        alignment: new go.Spot(0, 1, ICON_SIZE + NODE_PADDING, 0),
        alignmentFocus: go.Spot.BottomLeft,
        maxLines: 1,
        overflow: go.TextBlock.OverflowEllipsis,
        width: NODE_WIDTH - (ICON_SIZE + 3 * NODE_PADDING),
        toolTip: tooltip(descriptionBinding())
    },
    descriptionBinding()
);

export const labelPanel = () => $(
    go.Panel,
    go.Panel.Spot,
    {
        margin: new go.Margin(NODE_PADDING, 0, 0, 0),
        width: NODE_CONTENT_WIDTH
    },
    ...nodeIcons(),
    label(),
    description()
);
