import { Injectable } from '@angular/core';
import * as go from 'gojs';

import { DiagramService } from './base.service';
import { Diagram } from '../Diagram';

@Injectable()
export class FocusService extends DiagramService {
    mouseOnDiagram: boolean;

    bindDiagram(diagram: Diagram) {
        super.bindDiagram(diagram);

        diagram.doFocus = this.doFocus;
        diagram.toolManager.doMouseDown = () => {
            go.ToolManager.prototype.doMouseDown.call(this.diagram.toolManager);
            this.onMouseDown();
        };
        diagram.toolManager.doMouseUp = () => {
            go.ToolManager.prototype.doMouseUp.call(this.diagram.toolManager);
            this.onMouseUp();
        };
    }

    onMouseDown() {
        this.mouseOnDiagram = true;
    }

    onMouseUp() {
        this.mouseOnDiagram = false;
    }

    doFocus = () => {
        if (this.mouseOnDiagram) {
            this.diagram.focus();
        }
    }
}
