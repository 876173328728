import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-config-errors',
  templateUrl: './config-errors.component.html',
  styleUrls: ['./config-errors.component.scss']
})
export class ConfigErrorsComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public configErrors: any,
              private dialogRef: MatDialogRef<ConfigErrorsComponent>) { }

  onCancel() {
    this.dialogRef.close();
  }

}
