import * as go from 'gojs';
import { v4 as uuidv4 } from 'uuid';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { mapDocumentToDiagramPoint } from '../utils/mapDocumentToDiagramPoint';
import { PaletteItem } from '../../types/palette';
import { Point } from '../../types/shared';
import { Category } from '../types';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { TaskCatalogService } from '../../services/task-catalog.service';
import { DiagramService } from './base.service';
import { TaskCatalogPropertyService } from '../../services/task-catalog-property.service';
import { TaskCatalogItem, TaskCatalogItemResponse } from '../../types/taskCatalog';
import { TaskGroupLocationService } from './task-group-location.service';
import { ModelSaveService } from './model-save.service';
import { UdpTaskConfigService } from '../../services/udp-task-config.service';

@Injectable()
export class DiagramPaletteService extends DiagramService implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private taskCatalogService: TaskCatalogService,
        private taskCatalogPropertyService: TaskCatalogPropertyService,
        private taskGroupLocationService: TaskGroupLocationService,
        private modelSaveService: ModelSaveService,
        private _udpTaskConfigService: UdpTaskConfigService
    ) {
        super();
    }

    paletteItemDropped(item: PaletteItem, point: Point) {
        if (!this.isInsideDiagramRect(point)) {
            return;
        }

        this.insertItem(item, point);
    }

    private isInsideDiagramRect(point: Point) {
        const { left, top, width, height } = this.diagram.div
            .getBoundingClientRect();
        const { x, y } = point;
        return left < x
            && x < left + width
            && top < y
            && y < top + height;
    }

    private insertItem(item: PaletteItem, point: Point) {
        const task = this.taskCatalogService.getTaskByType(item.type);
        task.imagePath = this.getImagePath(task);

        const id = uuidv4();
        const location = mapDocumentToDiagramPoint(this.diagram, point);
        const taskGroup = this.taskGroupLocationService
            .findGroupAtLocation(location);

        this.diagram.commit(() => {
            this.diagram.model.addNodeData({
                ...task,
                id,
                category: Category.Node,
                loc: go.Point.stringify(location),
            });
        });

        this.taskCatalogPropertyService.getPropertyForOperatorType(item.type)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res: TaskCatalogItemResponse) => {
                this.diagram.commit(() => {
                    const prevSkipUndoManager = this.diagram.skipsUndoManager;
                    this.diagram.skipsUndoManager = true;

                    const part = this.diagram.findPartForKey(id);
                    const { data: { properties } } = part;
                    properties.module = res.module;
                    properties.parameters = res.parameters;

                    this.diagram.model
                        .setDataProperty(part.data, 'properties', properties);

                    this.diagram.select(
                        this.diagram.findPartForKey(id)
                    );

                    this.diagram.skipsUndoManager = prevSkipUndoManager;

                    if (taskGroup) {
                        taskGroup.addMembers(new go.List([part]));
                    }
                    const data = {
                        filename: miscellaneousConst.DAG.FILENAME,
                        contents: this.modelSaveService.getWorkflowDefinition()
                    }
                    this._udpTaskConfigService.setConfigToService(data, "jsonEditor");
                });
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private getImagePath(task: TaskCatalogItem) {
        if (task.type.includes('UDP')) {
            return '../../assets/icons/udp-icon.png';
        } else if (
            task.properties.subcategory === 'Amazon'
            || task.properties.subcategory === 'amazon'
        ) {
            return '../../assets/icons/aws-icon.png';
        } else if (task.properties.category === 'airflow') {
            return '../../assets/icons/airflow-logo.png';
        } else if (task.type.includes('GCS')) {
            return '../../assets/icons/gcs-icon.png';
        }

        return task.imagePath;
    }
}
