import { ReplaySubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit, Query, ViewChild } from '@angular/core';
import { SharedDataService } from '../../shared/modules/shared-data/shared-data.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'app-select-metadata',
  templateUrl: './select-metadata.component.html',
  styleUrls: ['./select-metadata.component.scss']
})
export class SelectMetadataComponent implements OnInit {

  /**
   * @private
   * @type: {Query}
   * @viewchild
   */
  @ViewChild('metadata', { static: false })
  private metaData: Query;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {data<boolean>}
   * @param: {dialogRef<MatDialogRef<ViewDataComponent>>}
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    private dialogRef: MatDialogRef<SelectMetadataComponent>,
    private sharedDataService: SharedDataService) {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {}

  /**
   * @public
   * @param: {v<boolean>}
   * @return: void
   * @description: a helper method that
   * closes the modal.
   */
  public close(v: boolean): void {
    if(!v) {
      this.sharedDataService.entity.blendSelectedAttributes = null;
    } else {
      this.sharedDataService.blendAttributesSelectcted$.next(this.sharedDataService.entity);
    }
    const value: any = v
      ? (this.metaData as any).entities
      : v;

    this.dialogRef.close(value);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
