import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';

// Components
import { ViewDataComponent } from './view-data/view-data.component';

/**
 * @author: Naga
 */
@Injectable()
export class SharedDataService {

  querySchema: Subject<any> = new Subject();
  blendAttributesSelectcted$: Subject<any> = new Subject();
  entity: any = {
    LEFT_DATA_ASSET: [],
    RIGHT_DATA_ASSET: []
  };
  selectedEntity: any = {
    LEFT_DATA_ASSET: [],
    RIGHT_DATA_ASSET: []
  };
  activeCard: string;

  fileSchema: any  = new BehaviorSubject<any>(null);
  public fileSchema$ = this.fileSchema.asObservable();

  /**
   * @constructor
   * @param: {dialog<MatDialog>}
   */
  constructor(private dialog: MatDialog) {}

  /**
   * @public
   * @returns: MatDialogRef<any>
   * @description: a helper method that
   * opens up data source view pop up.
   */
  public viewDataSource(): MatDialogRef<ViewDataComponent> {
    return this.openDialog(ViewDataComponent);
  }

  /**
   * @public
   * @param: {component<any>}
   * @param: {opts<any>}
   * @returns: MatDialogRef<any>
   * @description: a helper method that
   * opens up a modal.
   */
  public openDialog(component: any,
    opts: any = {}): MatDialogRef<typeof component> {

    const obj: any = {
      width: '1200px',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      panelClass: 'xft-dialog',
      data: null
    };

    return this.dialog.open(
      component, Object.assign({}, obj, opts)
    );
  }
}
