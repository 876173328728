import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ConfigModel } from '../models/config.model';

@Component({
  selector: 'xfusiontech-entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss']
})
export class EntitySearchComponent implements OnInit {
  expanded = false;
  expandedSubject = false;
  @Input() entities: any = [];
  @Input() config: ConfigModel;
  @Input() priviousConfigData: any;
  subjectConfig: any;
  private entitiesBackUp: any;

  public sidebarExpanded = true;
  selectedIds: any = [];

  @Output() public whenSelected: EventEmitter<any> = new EventEmitter<any>();

  @Output() public whenDeleted: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }


  ngOnInit() {
    if (this.entities) {
      this.entitiesBackUp = this.entities;
    }
    this.config = this.entities.dataSource.mappingConfig;
    if (this.entities && this.entities.subjectData) {
      this.subjectConfig = this.entities.subjectData.subMappingConfig;
    }
  }
  expandMenu() {
    this.expanded = !this.expanded;
  }
  public onSearch(value: string): void {
    this.entities = value.length ? this.entitiesBackUp.filter((entity: any) =>
    entity.entityName.includes(value)) : [...this.entitiesBackUp];
  }

  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  onSelectEntity(item: any, type) {
    this.selectedIds.push(item.entityID);
    const entity = {item, configType: type}
    this.whenSelected.emit(entity);
  }

  onDeleteEntity(id: string, type) {
    const i = this.selectedIds.indexOf(id);
    this.selectedIds.splice(i, 1);
    this.whenDeleted.emit({id, type});
  }

}
