/**
 * @author: Naga
 * @interface
 */
interface Delimeter {
  id: number,
  key: string,
  value: string
}

/**
 * @exports
 * @constant
 */
export const DELIMETERS: Delimeter[] = [
  {
    id: 1,
    key: 'Comma(,)',
    value: ',',
  },
  {
    id: 2,
    key: 'Tab(\t)',
    value: '\t'
  },
  {
    id: 3,
    key: 'Space(\s)',
    value: '\s'
  },
  {
    id: 4,
    key: 'Colon(:)',
    value: ':'
  },
  {
    id: 5,
    key: 'Semi Colon(;)',
    value: ';'
  },
  {
    id: 6,
    key: 'Dot(.)',
    value: '.'
  },
  {
    id: 7,
    key: 'Pipe(|)',
    value: '|'
  },
  {
    id: 8,
    key: 'Brace({})',
    value: '{}'
  },
  {
    id: 9,
    key: 'Slash(/)',
    value: '/'
  },
  {
    id: 10,
    key: `Single Quote(')`,
    value: `'`
  },
  {
    id: 11,
    key: `Double Quote(")`,
    value: `"`
  }

  // Uncomment it only after API integration
  // for XML & JSON file types
  // ,
  // {
  //   id: 11,
  //   key: 'Other',
  //   value: 'other'
  // }
];

/**
 * @exports
 * @constant
 */
export const ENC_TYPES: string[] = [
  'UTF-8'
];

/**
 * @exports
 * @constant
 * @function
 * @param: {id<string | number>} 
 */
export const _get: Function = (id: string | number): Delimeter => {
  return DELIMETERS.find(del => del.id === +id);
};
