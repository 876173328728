import { Component, Input } from '@angular/core';
import { MenuType } from '../menu.types';

@Component({
    selector: 'udp-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {

    @Input()
    path: string;

    @Input()
    label: string;

    @Input()
    type: MenuType = 'primary';

}
