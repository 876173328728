import { Injectable } from '@angular/core';
import { DiagramService } from './base.service';
import { Diagram } from '../Diagram';
import { UnsavedChangesService } from '../../services/unsaved-changes.service';

@Injectable()
export class ModelChangesService extends DiagramService {
    constructor(
        private unsavedChangesService: UnsavedChangesService
    ) {
        super();
    }

    bindDiagram(diagram: Diagram) {
        super.bindDiagram(diagram);

        this.attachDiagramEvents();
        this.setUnmodified();
    }

    unbindDiagram() {
        this.setUnmodified();
        super.unbindDiagram();
    }

    setUnmodified() {
        if (!this.diagram) {
            return;
        }

        this.diagram.isModified = false;
        this.updateUnsavedChanges();
    }

    private attachDiagramEvents() {
        this.diagram.addDiagramListener('Modified', () => {
            this.updateUnsavedChanges();
        });
    }

    private updateUnsavedChanges() {
        this.unsavedChangesService.stateChanged$
            .next(this.diagram.isModified);
    }
}
