import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UPDMilOperatorService } from '../udp-mil-operator.service';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';

@Component({
  selector: 'lib-bucket-explorer',
  templateUrl: './bucket-explorer.component.html',
  styleUrls: ['./bucket-explorer.component.scss']
})
export class BucketExplorerComponent implements OnInit, OnDestroy {

  public expanded = true;
  public gridData: GridDataResult;
  public pageSize = 5;
  public state: State = {
    skip: 0,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  s3_id = 'AKIA4EQ2F3RJL2BSVRTZ';
  s3_sk = 'JqC8MqXZ9J5iWmmSuA/B2tNwIoo5JxySLjDKPsUG';
  public items: any;
  bucketList: any;
  bucketListData: any [];
  rootBucketName: string;
  public itemsBreadCrumbs: BreadCrumbItem[];
  private bucketContent: any;
  bucData: any[];
  menuHeader: any = [];
  private supportedFormats = [
    'xlsx',
    'csv',
    'parquet',
    'xls',
    'arrow',
    'json'
  ];
  selected: any;
  selectedBucket: string;
  private currBreadCrumPath: string;
  insideFolder: boolean;
  rootData: boolean;
  key: any;
  appitemsTravel: any;
  private folderName: string;
  private subscriptions: Subscription[] = [];
  private fileContent: any;
  public showFileSelection: boolean;

  constructor(
    private dialogRef: MatDialogRef<BucketExplorerComponent>,
    private udpMilOperatorService: UPDMilOperatorService,
    private errorHandlingService: ErrorHandlingService,
    @Inject(MAT_DIALOG_DATA) public data: {saveNewFile: boolean}
  ) { }

  ngOnInit(): void {
    this.getBucketList();
    this.showFileSelection = this.data.saveNewFile;
  }

  public close(value: boolean): void {
    this.dialogRef.close(value);
    if (value) {
      this.udpMilOperatorService.targetFilePath.next({
        bukcetName: this.rootBucketName,
        fileLocation: this.currBreadCrumPath,
        bucketData: this.bucData,
        fileContent: this.fileContent
      });
    }
  }

  /**
   * Retrive bucket list from API
   */
  private getBucketList() {
    const formData: FormData = new FormData();
    formData.append('s3_id', this.s3_id);
    formData.append('s3_sk', this.s3_sk);
    this.subscriptions[this.subscriptions.length] = this.udpMilOperatorService.getBucketExplorer(formData, 's3_list_buckets')
    .subscribe(async data => {
      this.bucketList = data;
      this.bucketListData = this.bucketList.Buckets;
      this.items = this.bucketListData;
      this.rootBucketName = this.bucketListData[0].Name;
      Object.assign(this.bucketListData[0], {selected: true});
      this.itemsBreadCrumbs = [
        {
          text: this.bucketListData[0].Name,
          title: this.bucketListData[0].Name,
          icon: 'home',
        }
      ];
      this.getBucketContent(this.rootBucketName);
    }, error => {
        this.errorHandlingService.errorSuccessSnack('116', false);
      });
  }

  /**
   * Retrive bucket content from API
   */
  private getBucketContent(selectedBucket) {
    const formData: FormData = new FormData();
    formData.append('s3_id', this.s3_id);
    formData.append('s3_sk', this.s3_sk);
    formData.append('bucket_name', selectedBucket);
    this.subscriptions[this.subscriptions.length] = this.udpMilOperatorService.getBucketExplorer(formData, 's3_list_objects')
    .subscribe(async (data: any) => {
      this.bucketContent = data.Contents || [];
      this.processFileSize();
      this.processBucketFolder();
    }, error => {
      this.errorHandlingService.errorSuccessSnack('130', false);
    });
  }

  /**
   *
   */
  private processFileSize() {
    this.bucketContent.map((item: any) => {
      const decimals = 2;
      if (item.Size === 0) {
        return item.Size = '0 Bytes';
      }
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(item.Size) / Math.log(k));
      item.Size = parseFloat((item.Size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    });
  }

  /**
   * Process bucket content
   * @param bucData
   */
  private processBucketFolder() {
    this.bucData = [];
    const currentFolder = this.menuHeader.map((item: any) => item.label).join('');
    const folders = [];
    const files: any = [];
    const temp: any = [];
    this.bucketContent.forEach((item: any) => {
      if (item.Key.startsWith(currentFolder)) {
        const key = item.Key.replace(currentFolder, '');
        if (key !== '') {
          if (key.includes('/')) {
            const folder = key.split('/')[0];
            if (!temp.includes(folder)) {
              temp.push(folder);
              folders.push({...item, Key: folder + '/', isFolder: true, format: 'Folder', showAction: true});
            }
          } else {
            files.push({...item, Key: key, isFolder: false, showAction: true, format: key.split('.')[1]});
          }
        }
      }
    });
    // show action button for supported formats
    files.forEach((currFormat) => {
      if (currFormat.format) {
        this.supportedFormats.includes(currFormat.format.toLowerCase()) ? currFormat.showAction = true : currFormat.showAction = false;
      }
  });
    this.bucData = [...folders, ...files];
    this.gridData = {
      data: this.bucData.slice(this.state.skip, this.state.skip + this.pageSize),
      total: this.bucData.length,
    };
  }

  /**
   *
   * @param ev
   */
  public onSelect(ev: DrawerSelectEvent): void {
    this.rootBucketName = ev.item.Name;
    this.selected = ev.item;
    this.selectedBucket  = this.rootBucketName;
    this.getBucketContent(this.selectedBucket);
    this.breadCrumbMain();
  }

  /**
   * root breadcrumb
   */
  public breadCrumbMain() {
    this.menuHeader = [];
    this.processBucketFolder();
    this.currBreadCrumPath = '';
    this.getBucketContent(this.rootBucketName);
  }

  /**
   * sub breadcrumb
   * @param menu
   * @param index
   */
  public (menu, index) {
    this.menuHeader.splice(index + 1, this.menuHeader.length - 1);
    if (menu[index] && menu[index].items && menu[index].items.length) {
      this.appitemsTravel = menu[index].items;
    }
    const currPath = this.appitemsTravel;
    this.currBreadCrumPath = currPath;
    this.processBucketFolder();
  }

  /**
   *
   * @param key
   */
  public openFolder(key) {
    this.insideFolder = true;
    this.rootData = false;
    this.itemsBreadCrumbs.push({text: key, title: key});
    this.key = key;
    this.menuHeader.push({ label: key, icon: 'keyboard_arrow_right', items: key });
    this.processBucketFolder();
    this.appitemsTravel = this.bucData;
    this.currBreadCrumPath = '';
    this.menuHeader.map((t1) => {
      this.currBreadCrumPath =  this.currBreadCrumPath.concat(t1.label);
    });
    this.folderName = this.currBreadCrumPath ? this.currBreadCrumPath.slice(0, -1) : ''; // for Upload file form to display in file category
  }

  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.gridData = {
      data: this.bucData.slice(this.state.skip, this.state.skip + this.pageSize),
      total: this.bucData.length,
    };
  }

  public selectFile(e, ele) {
    this.fileContent = ele;
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }

}
