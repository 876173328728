import { QueryVariable } from '@xfusiontech/data-visualizer';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash/fp';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'igv-query-editor-variables-form',
    templateUrl: './variables-form.component.html',
    styleUrls: ['./variables-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariablesFormComponent implements OnChanges, OnDestroy {
    @HostBinding('class') classes = 'igv-variables-form';

    @Input()
    variables: QueryVariable[] = [];

    form: FormGroup;

    private onDestroy$ = new Subject();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.variables) {
            this.transformVariablesToFormGroup(changes.variables.previousValue);
        }
    }

    private transformVariablesToFormGroup(previousValue: QueryVariable[] = []) {
        if (this.form == null) {
            this.form = new FormGroup({});
        }

        (this.variables || []).forEach(variable => {
            const { queryVariableParameters: p } = variable;
            const variableValue = variable.queryVariableValue;
            const defaultValue = p && p.default ? p.default : null;
            const formControl = new FormControl(
                variableValue != null ? variableValue : defaultValue
            );

            const prevValue = previousValue.find(
                x => x.queryVariableName === variable.queryVariableName
            );
            if (!_.isEqual(prevValue)(variable)) {
                this.form.removeControl(variable.queryVariableName);
                this.form.addControl(variable.queryVariableName, formControl);
            }

            formControl.valueChanges
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(value => (variable.queryVariableValue = value));
        });
    }

    variableTrackBy = (x, variable: QueryVariable) =>
        variable.queryVariableName;

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
