import * as go from 'gojs';

import { theme } from '../../../../consts/theme';
import { Port } from '../../../../types/workflowDefinition';

const $ = go.GraphObject.make;

export type PortDotProps = {
  portId: Port;
  fromSpot: go.Spot;
  toSpot: go.Spot;
};

export const portDot = ({ portId, fromSpot, toSpot }: PortDotProps) => $(
  go.Shape,
  'Circle',
  {
    fromLinkable: true,
    toLinkable: true,
    alignmentFocus: go.Spot.Center,
    alignment: go.Spot.Center,
    width: 14,
    height: 14,
    fill: theme.colors.link,
    stroke: 'white',
    strokeWidth: 2,
    opacity: 0,
    fromSpot,
    toSpot,
    portId
  }
);
