import * as _ from 'lodash/fp';
import { TaskCatalogItemParameter } from '../../types/taskCatalog';

export const removeNullValuesFromParameters = (
    parameters: TaskCatalogItemParameter[]
) => _.map(
    (parameter: TaskCatalogItemParameter) => {
        if (_.isNull(parameter.value)) {
            return _.omit('value', parameter);
        }

        return parameter;
    }
)(parameters);
