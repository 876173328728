import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'igv-connection-info',
    templateUrl: './connection-info.component.html',
    styleUrls: ['./connection-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionInfoComponent {
    @Input()
    databaseName: string;
}
