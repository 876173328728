// tslint:disable max-line-length

export const DEFAULT_NODE_ICON_PATH = '/assets/udp-icon.png';

export const CPU_ICON = {
    viewport: '0 0 12 12',
    path: 'M 4.4423945,0 A 0.28915663,0.25735274 0 0 0 4.1532379,0.25753012 V 1.1566265 H 2.0240964 c -0.4755767,0 -0.8674699,0.3918932 -0.8674699,0.8674699 V 4.1526732 H 0.25753012 A 0.25735274,0.28915663 0 0 0 0,4.4418298 0.25735274,0.28915663 0 0 0 0.25753012,4.7309864 H 1.1566265 V 6.7488706 H 0.24962349 A 0.24940177,0.28915663 0 0 0 0,7.0380272 0.24940177,0.28915663 0 0 0 0.24962349,7.3271838 H 1.1566265 v 2.5041415 c 0,0.4755767 0.3918932,0.8674697 0.8674699,0.8674697 h 2.1291415 v 1.012048 A 0.28915663,0.28915663 0 0 0 4.4423945,12 0.28915663,0.28915663 0 0 0 4.7315511,11.710843 v -1.012048 h 2.5368978 v 1.012048 A 0.28915663,0.28915663 0 0 0 7.5576055,12 0.28915663,0.28915663 0 0 0 7.8467621,11.710843 v -1.012048 h 1.9845632 c 0.4755767,0 0.8674697,-0.391893 0.8674697,-0.8674697 V 7.3271838 h 1.012048 A 0.28915663,0.28915663 0 0 0 12,7.0380272 0.28915663,0.28915663 0 0 0 11.710843,6.7488706 H 10.698795 V 4.7309864 h 1.012048 A 0.28915663,0.28915663 0 0 0 12,4.4418298 0.28915663,0.28915663 0 0 0 11.710843,4.1526732 H 10.698795 V 2.0240964 c 0,-0.4755767 -0.391893,-0.8674699 -0.8674697,-0.8674699 H 7.8467621 V 0.26543675 A 0.28915663,0.26530371 0 0 0 7.5576055,0 0.28915663,0.26530371 0 0 0 7.2684489,0.26543675 V 1.1566265 H 4.7315511 V 0.25753012 A 0.28915663,0.25735274 0 0 0 4.4423945,0 Z M 2.0240964,1.7349398 h 7.8072289 c 0.1651943,0 0.2891567,0.1239622 0.2891567,0.2891566 v 7.8072289 c 0,0.1651943 -0.1239624,0.2891567 -0.2891567,0.2891567 H 2.0240964 c -0.1651944,0 -0.2891566,-0.1239624 -0.2891566,-0.2891567 V 2.0240964 c 0,-0.1651944 0.1239622,-0.2891566 0.2891566,-0.2891566 z m 2.70689,2.7063253 A 0.28918554,0.28918554 0 0 0 4.4418298,4.7304217 V 7.2690136 A 0.28918554,0.28918554 0 0 0 4.7309864,7.5581702 H 7.2690136 A 0.28918554,0.28918554 0 0 0 7.5581702,7.2690136 V 4.7304217 A 0.28918554,0.28918554 0 0 0 7.2690136,4.4412651 Z M 5.020143,5.0195783 H 6.979857 V 6.979857 H 5.020143 Z'
};

export const CHEVRON_UP = {
    viewport: '0 0 30 17',
    path: 'm 15.007846,5.1248617 11.3409,11.2505953 a 2.1345629,2.1156367 0 0 0 3.028102,0 2.1614517,2.1422872 0 0 0 0,-3.009452 L 16.526033,0.61888336 A 2.1386996,2.1197369 0 0 0 13.570325,0.55738228 L 0.63057164,13.357805 a 2.1433878,2.1243834 0 1 0 3.02603246,3.009452 z'
};

export const UDP_ICON = {
    viewport: '0 0 30 17',
    path: 'M121 1169 c-48 -73 -78 -145 -102 -239 -18 -69 -21 -104 -17 -209 4 -108 9 -139 35 -214 43 -123 85 -189 183 -287 96 -96 164 -140 283 -183 71 -26 99 -31 214 -35 100 -3 150 0 206 12 243 54 449 238 539 479 25 67 32 102 36 191 l5 109 -43 -43 c-23 -23 -72 -56 -108 -73 -61 -29 -76 -32 -162 -32 -83 0 -103 4 -154 28 -32 15 -60 26 -62 25 -1 -2 25 -37 58 -78 72 -89 151 -209 202 -305 l37 -70 -91 95 c-84 87 -93 93 -103 76 -27 -45 -105 -120 -147 -141 -169 -85 -382 -19 -463 145 l-25 49 -107 -115 c-59 -63 -110 -113 -112 -110 -17 16 195 322 299 431 35 38 36 41 16 42 -49 4 -153 67 -219 133 -91 91 -149 220 -149 330 0 46 -14 43 -49 -11z'
};

// tslint:enable
