import { InputType } from '../components/shared/form-field/form-field.types';

export enum ParameterType {
    String = 'str',
    Dictionary = 'dict',
    Boolean = 'bool',
    Integer = 'int',
    DeltaTime = 'datetime.timedelta',
    Datetime = 'datetime.datetime',
    Callable = 'callable',
    List = 'list',
    Union = 'union',
    Complex = 'complex',
}

export type FormField = {
    id: string;
    label: string;
    type: InputType;
    paramType: string;
    description?: string;
    default?: any;
    value?: any;
    required?: boolean;
};

export interface AvailableType {
    label: string;
    type: string;
}

export interface UnionInputPayload {
    value: any;
    selectedParameterType: ParameterType;
    availableParameterTypes: AvailableType[];
}

export interface UnionOutputPayload {
    value: any;
    selectedType: ParameterType;
}

export interface ComplexInputPayload {
    value: string;
    imports: string;
}

export interface ComplexOutputPayload {
    value: string;
    imports: string;
}


export type InputValue =
    | string
    | number
    | boolean
    | UnionInputPayload
    | ComplexInputPayload;
