import { Component, Input } from '@angular/core';

@Component({
    selector: 'udp-layout-icon',
    templateUrl: './layout-icon.component.html',
    styleUrls: ['./layout-icon.component.scss']
})
export class LayoutIconComponent {

    @Input()
    path: string;

    @Input()
    selected: boolean;

}
