import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { IconVariant } from '../../types/icons';

@Component({
  selector: 'udp-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input()
  variant: IconVariant = 'material';

  @Input()
  name: string;

  @Input()
  tooltipMessage: string;

  @Input()
  tooltipPosition: TooltipPosition = 'after';

  get isMaterial() {
    return this.variant === 'material';
  }
}
