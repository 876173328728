import * as go from 'gojs';
import { UndoState } from './UndoState';
import { UndoAction } from './UndoAction';
import HistoryMember from './HistoryMember';

type PropertyAccessor = string | ((data: any, newval: any) => any);

// tslint:disable
export class UndoStorage {

    protected state = new UndoState();
    protected temporaryStorage: Array<go.ChangedEvent>;
    
    protected externalTemporaryStorage: Array<{ key: number, action: UndoAction }>;
    protected pendingTransparentTransaction: Array<go.ChangedEvent>;
    protected pendingExternalTransparentChanges: Array<{ key: number, action: UndoAction }>;

    protected isTransactionTransparent: boolean;
    protected isInTransaction: boolean;
    isUndoingRedoing = false;
    protected optimalObjectMap: Map<Object, Map<PropertyAccessor, go.ChangedEvent>>;

    protected diagram: go.Diagram;
    protected readonly externalActionIdentifier = 'UndoManagerExternalAction';
    readonly transparentTransactionName = 'TransparentTransaction';

    protected partialChangesRegistrationEnabledInternal: boolean;

    get transactionToUndo() {
        if (this.state.historyIndex === -1) {
            return null;
        }

        return this.state.history[this.state.historyIndex];
    }

    get transactionToRedo() {
        if (this.state.historyIndex === this.state.history.length - 1 || this.state.history.length === 0) {
            return null;
        }

        return this.state.history[this.state.historyIndex + 1];
    }

    get partialChangesRegistrationEnabled() {
        return this.partialChangesRegistrationEnabledInternal;
    }

    set partialChangesRegistrationEnabled(registerPartialChangesEnabled: boolean) {
        this.partialChangesRegistrationEnabledInternal = registerPartialChangesEnabled;
    }

    bindDiagram(diagram: go.Diagram) {
        (window as any).undoManager = this;
        this.diagram = diagram;
        this.diagram.undoManager.isEnabled = false;
        diagram.addModelChangedListener(this.registerGoJsChange);
    }

    unbindDiagram() {
        this.diagram.removeModelChangedListener(this.registerGoJsChange);
    }

    registerAction(action: UndoAction) {
        this.state.maxExternalId += 1;
        this.externalTemporaryStorage.push({ key: this.state.maxExternalId, action });
        this.diagram.model.raiseChangedEvent(go.ChangedEvent.Property, this.externalActionIdentifier, null, null, this.state.maxExternalId);
    }

    async undo() {
        if (this.state.history.length === 0 || this.state.historyIndex === -1 || this.isUndoingRedoing) {
            return false;
        }
        const nodesToNotify = new Map<number, boolean>();
        this.isUndoingRedoing = true;
        const arr = this.state.history[this.state.historyIndex].changes;
        for (let i = arr.length - 1; i >= 0; --i) {
            const change = arr[i];

            if (change.propertyName === this.externalActionIdentifier) {
                await this.undoRedoExternalChange(change, true);
                continue;
            }

            if (!change.canUndo()) {
                continue;
            }

            const changeName = (change.change as any).name;
            switch (changeName) {
                case 'Property':
                    this.undoRedoPropertyChange(change, nodesToNotify, true);
                    break;
                case 'Insert':
                    this.undoRedoInsertChange(change, nodesToNotify, true);
                    break;
                case 'Remove':
                    this.undoRedoRemoveChange(change, nodesToNotify, true);
                    break;
                default:
                    break;
            }
        }

        this.notifyLinkEnds(nodesToNotify);
        this.state.historyIndex > -1 && this.state.historyIndex--;
        requestAnimationFrame(() => { this.isUndoingRedoing = false; });
        return true;
    }

    async redo() {
        if (this.state.history.length === 0 || this.state.historyIndex === this.state.history.length - 1 || this.isUndoingRedoing) {
            return false;
        }
        const nodesToNotify = new Map<number, boolean>();
        this.isUndoingRedoing = true;
        const arr = this.state.history[this.state.historyIndex + 1].changes;
        for (const change of arr) {
            if (change.propertyName === this.externalActionIdentifier) {
                await this.undoRedoExternalChange(change, false);
                continue;
            }

            if (!change.canRedo()) {
                continue;
            }

            const changeName = (change.change as any).name;
            switch (changeName) {
                case 'Property':
                    this.undoRedoPropertyChange(change, nodesToNotify, false);
                    break;
                case 'Insert':
                    this.undoRedoInsertChange(change, nodesToNotify, false);
                    break;
                case 'Remove':
                    this.undoRedoRemoveChange(change, nodesToNotify, false);
                    break;
                default:
                    break;
            }
        }

        this.notifyLinkEnds(nodesToNotify);
        this.state.historyIndex < this.state.history.length - 1 && this.state.historyIndex++;
        requestAnimationFrame(() => { this.isUndoingRedoing = false; });
        this.tryAppendTransparentTransaction(false);
        return true;
    }

    startTransaction(name: string) {
        this.diagram.startTransaction(name);
    }

    commitTransaction(name: string) {
        this.diagram.commitTransaction(name);
    }

    reset() {
        this.state = new UndoState();
        this.temporaryStorage = [];
        this.externalTemporaryStorage = [];
        this.pendingTransparentTransaction = [];
        this.pendingExternalTransparentChanges = [];
        this.optimalObjectMap.clear();
    }

    protected getAvoidableChanges() {
        return ['CommittingTransaction'];
    }

    protected generateActionId() {
        return '';
    }

    protected getOptimizableChangeNames() {
        return new Map<PropertyAccessor, boolean>();
    }

    protected registerGoJsChange = (e: go.ChangedEvent) => {
        const avoidableChanges = this.getAvoidableChanges();
        if (this.isUndoingRedoing || this.diagram.skipsUndoManager) {
            return;
        }
        if (e.propertyName === 'StartedTransaction') {
            this.startRegisteringTransaction(e);
        } else if (e.propertyName === 'CommittedTransaction') {
            if (this.temporaryStorage.length > 0) {
                this.isInTransaction = false;
                this.eraseFuture();
                this.pushChangesToHistory();
            }
        } else if (e.propertyName === 'RolledBackTransaction') {
            this.isInTransaction = false;
            this.isTransactionTransparent = false;
        } else if (avoidableChanges.indexOf(e.propertyName.toString()) === -1 && this.isInTransaction) {
            this.saveChangeOptimally(e.copy());
        }
    }

    protected startRegisteringTransaction(e: go.ChangedEvent) {
        this.isInTransaction = true;
        this.temporaryStorage = [];
        this.externalTemporaryStorage = [];
        this.pendingTransparentTransaction = [];
        this.pendingExternalTransparentChanges = [];
        this.optimalObjectMap = new Map<Object, Map<PropertyAccessor, go.ChangedEvent>>();
        this.isTransactionTransparent = e.oldValue === this.transparentTransactionName;
    }

    protected saveChangeOptimally(e: go.ChangedEvent) {
        const optimizableNames = this.getOptimizableChangeNames();
        const changeName = e.propertyName;
        if (changeName === this.externalActionIdentifier || !optimizableNames.has(changeName)) {
            this.temporaryStorage.push(e);
            return;
        }
        if (!this.optimalObjectMap.has(e.object)) {
            this.optimalObjectMap.set(e.object, new Map<PropertyAccessor, go.ChangedEvent>());
        }

        const objectChangeMap = this.optimalObjectMap.get(e.object);
        if (!objectChangeMap.has(e.propertyName)) {
            objectChangeMap.set(e.propertyName, e);
            this.temporaryStorage.push(e);
        }

        const propertyChange = objectChangeMap.get(e.propertyName);
        propertyChange.newValue = e.newValue;
        propertyChange.newParam = e.newParam;
    }

    protected pushChangesToHistory() {
        if (this.isTransactionTransparent) {
            this.processTransparentTransaction(this.temporaryStorage, this.externalTemporaryStorage);
            return;
        }

        this.registerPartialChanges(this.temporaryStorage);
        this.state.history.push(new HistoryMember(this.generateActionId(), this.temporaryStorage));
        this.state.historyIndex = this.state.history.length - 1;
        this.externalTemporaryStorage.forEach((el: { key: number, action: UndoAction }) => {
            this.state.externalActions.set(el.key, el.action);
        });

        this.tryAppendTransparentTransaction(false);
    }

    protected eraseFuture() {
        if (this.isTransactionTransparent) {
            return;
        }
        for (let i = this.state.historyIndex + 1; i < this.state.history.length; ++i) {
            const arr = this.state.history[i].changes;
            arr.forEach((e: go.ChangedEvent) => {
                if (e.propertyName === this.externalActionIdentifier) {
                    this.state.externalActions.delete(e.newValue);
                }
            });
        }

        this.state.history.splice(this.state.historyIndex + 1, this.state.history.length - (this.state.historyIndex + 1));
    }

    protected registerPartialChanges(modelChanges: Array<go.ChangedEvent>) {
        return;
    }

    private async undoRedoExternalChange(change: go.ChangedEvent, isUndo: boolean) {
        const externalChange = this.state.externalActions.get(change.newValue);
        externalChange && (isUndo ? await externalChange.undo() : await externalChange.redo());
    }

    private undoRedoPropertyChange(change: go.ChangedEvent, nodesToNotify: Map<number, boolean>, isUndo: boolean) {
        const oldValue = change.oldValue === undefined ? null : change.oldValue;
        const newValue = change.newValue;

        change.model.setDataProperty(change.object, change.propertyName.toString(), isUndo ? oldValue : newValue);
        this.discoverNotifiableNodes(nodesToNotify, change);
    }

    private undoRedoInsertChange(change: go.ChangedEvent, nodesToNotify: Map<number, boolean>, isUndo: boolean) {
        const model = change.model as go.GraphLinksModel;

        if (change.propertyName === 'nodeDataArray') {
            isUndo ? model.removeNodeData(change.newValue) : model.addNodeData(change.newValue);
        } else if (change.propertyName === 'linkDataArray') {
            this.makeLinkEndsNotifiable(nodesToNotify, change.newValue);
            isUndo ? model.removeLinkData(change.newValue) : model.addLinkData(change.newValue);
        } else if (change.object && change.object instanceof Array) {
            if (isUndo) {
                const index = change.object.indexOf(change.newValue);
                change.model.removeArrayItem(change.object, index);
            } else {
                change.model.addArrayItem(change.object, change.newValue);
            }
        }
    }

    private undoRedoRemoveChange(change: go.ChangedEvent, nodesToNotify: Map<number, boolean>, isUndo: boolean) {
        const model = change.model as go.GraphLinksModel;

        if (change.propertyName === 'nodeDataArray') {
            isUndo ? model.addNodeData(change.oldValue) : model.removeNodeData(change.oldValue);
        } else if (change.propertyName === 'linkDataArray') {
            this.makeLinkEndsNotifiable(nodesToNotify, change.oldValue);
            isUndo ? model.addLinkData(change.oldValue) : model.removeLinkData(change.oldValue);
        } else if (change.object && change.object instanceof Array) {
            if (isUndo) {
                change.model.addArrayItem(change.object, change.oldValue);
            } else {
                const index = change.object.indexOf(change.oldValue);
                change.model.removeArrayItem(change.object, index);
            }
        }
    }

    private processTransparentTransaction(transparentTransaction: Array<go.ChangedEvent>, transparentExternalChanges: Array<{ key: number, action: UndoAction }>) {
        this.pendingTransparentTransaction = this.pendingTransparentTransaction.concat(transparentTransaction.slice());
        this.pendingExternalTransparentChanges = this.pendingExternalTransparentChanges.concat(transparentExternalChanges.slice());
        this.tryAppendTransparentTransaction(true);
    }

    private tryAppendTransparentTransaction(toEnd: boolean) {
        if (this.state.historyIndex === -1 || this.pendingTransparentTransaction.length === 0) {
            return;
        }

        if (toEnd) {
            this.transactionToUndo.changes = this.transactionToUndo.changes.concat(this.pendingTransparentTransaction.slice());
        } else {
            const changes = this.pendingTransparentTransaction.concat(this.transactionToUndo.changes);
            this.transactionToUndo.changes = changes.slice();
        }

        this.pendingExternalTransparentChanges.forEach((el: { key: number, action: UndoAction }) => {
            this.state.externalActions.set(el.key, el.action);
        });
        this.pendingTransparentTransaction = [];
        this.pendingExternalTransparentChanges = [];
    }

    private discoverNotifiableNodes(map: Map<number, boolean>, change: go.ChangedEvent) {
        if (change.modelChange === 'linkToKey' || change.modelChange === 'linkFromKey') {
            change.oldValue !== undefined && change.oldValue !== null && map.set(change.oldValue, true);
            change.newValue !== undefined && change.newValue !== null && map.set(change.newValue, true);
        }
        if (change.modelChange === 'linkFromPortId') {
            change.object != null && change.object.from != null && map.set(change.object.from, true);
        }
        if (change.modelChange === 'linkToPortId') {
            change.object != null && change.object.to != null && map.set(change.object.to, true);
        }
    }

    private makeLinkEndsNotifiable(map: Map<number, boolean>, linkData: any) {
        if (!linkData) {
            return;
        }
        linkData.from !== undefined && linkData.from !== null && map.set(linkData.from, true);
        linkData.to !== undefined && linkData.to !== null && map.set(linkData.to, true);
    }

    private notifyLinkEnds(map: Map<number, boolean>) {
        map.forEach((value: boolean, key: number) => {
            const node = this.diagram.findNodeForKey(key);
            node && node.updateTargetBindings();
        });
    }
}
// tslint:enable
