import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { GraphModule } from '@xfusiontech/data-visualizer';
import {
    ButtonModule,
    FormFieldModule,
    IconButtonModule,
    IconModule,
} from '@xfusiontech/shared';
import { VisualizationDialogComponent } from './visualization-dialog.component';
import { VisualizationDialogRef } from './visualization-dialog.ref';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        IconModule,
        IconButtonModule,
        GraphModule,
    ],
    declarations: [VisualizationDialogComponent],
    entryComponents: [VisualizationDialogComponent],
    exports: [VisualizationDialogComponent],
    providers: [VisualizationDialogRef],
})
export class VisualizationDialogModule {}
