import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    Input,
} from '@angular/core';

@Component({
    selector: 'igv-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
    form: FormGroup;

    @Input()
    errors: string;

    @Output()
    login = new EventEmitter<{ email: string; password: string }>();

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: [null, Validators.required],
            password: [null, Validators.required],
        });
    }

    onSubmitClick() {
        this.login.emit(this.form.getRawValue());
    }
}
