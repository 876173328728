import { trigger, transition, style, animate } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [
        style({
            opacity: 0,
            overflow: 'hidden'
        }),
        animate('200ms ease-out', style({
            opacity: '*'
        })),
    ]),
    transition(':leave', [
        style({
            opacity: '*',
            overflow: 'hidden'
        }),
        animate('200ms ease-out', style({
            opacity: 0
        }))
    ])
]);
