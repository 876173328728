// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Components
import { SpinnerComponent } from './spinner/spinner.component';

// Services
import { SpinnerService } from './spinner/spinner.service';

/**
 * @author: Naga
 * @constant
 */
const SHARED: any[] = [
  SpinnerComponent
];

/**
 * @module
 */
@NgModule({
  declarations: [
    ...SHARED
  ],
  imports: [
    CommonModule
    , RouterModule
  ],
  exports: [
    ...SHARED
  ],
  providers: [
    SpinnerService
  ]
})
export class LayoutsModule { }
