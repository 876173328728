import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNativeDateModule } from '@angular/material/core';

import { DiagramModule } from '../diagram/diagram.module';
import { PaletteModule } from '../palette/palette.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { PropertiesEditorModule } from '../properties-editor/properties-editor.module';
import { DrawerExpansionButtonModule } from '../shared/drawer-expansion-button/drawer-expansion-button.module';
import { CodeEditorPanelModule } from '../code-editor-panel/code-editor-panel.module';
import { DiagramControlsModule } from '../diagram-controls/diagram-controls.module';

import { EditorComponent } from './editor.component';

const imports = [
    CommonModule,
    DiagramModule,
    PaletteModule,
    ToolbarModule,
    PropertiesEditorModule,
    MatSidenavModule,
    DrawerExpansionButtonModule,
    MatNativeDateModule,
    DiagramControlsModule,
    CodeEditorPanelModule
];

@NgModule({
    declarations: [
        EditorComponent,
    ],
    imports,
    exports: [
        EditorComponent,
    ]
})
export class EditorModule { }
