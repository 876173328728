export const TASK_GROUP_CATEGORY_PROPERTY = 'taskGroup';

export const TASK_GROUP_MIN_WIDTH = 300;
export const TASK_GROUP_MIN_HEIGHT = 65;
export const TASK_GROUP_PADDING = 25;

export const TASK_GROUP_BASE_DATA = {
  properties: {
    category: TASK_GROUP_CATEGORY_PROPERTY,
    // TODO: Add description if needed
    // description: '',
    name: 'TaskGroup',
    parameters: [
      {
        default: null,
        description: 'a unique, meaningful id for the taskgroup',
        id: 'group_id',
        inheritedFrom: null,
        required: true,
        type: 'str',
        typeCategory: 'elem',
        typeSubCategories: null,
        userValueType: undefined,
        value: null,
      },
      {
        default: null,
        id: 'default_args',
        inheritedFrom: null,
        required: false,
        type: 'Dict',
        typeCategory: 'dict',
        typeSubCategories: [],
        userValueType: undefined,
        value: undefined,
      }
    ],
    subcategory: null
  }
};
