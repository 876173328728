import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { HorizontalBarsIndicatorComponent } from './indicator/horizontal-bars-indicator.component';
import { DiffIndicatorComponent } from './diff-indicator/diff-indicator.component';
import { HorizontalBarComponent } from './bar/horizontal-bar.component';
import { getIconRegistry } from '../../utils/iconRegistry';
import { IconModule } from '../icon/icon.module';

const featherIcons = [
    'arrow-up',
    'arrow-down'
];

const imports = [
    CommonModule,
    IconModule
];

const components = [
    HorizontalBarsIndicatorComponent
];

const innerComponents = [
    DiffIndicatorComponent,
    HorizontalBarComponent
];

@NgModule({
    imports,
    declarations: [
        ...components,
        ...innerComponents
    ],
    exports: components
})
export class HorizontalBarsIndicatorModule {

    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
    ) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace(
            'feather',
            iconsPath
        )(featherIcons);
    }

}
