// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDataModule } from '../shared/modules/shared-data/shared-data.module';
import { MatCheckboxModule, MatSlideToggleModule, MatTableModule } from '@angular/material';

// Library Imports
import { ButtonModule, FormFieldModule, IconButtonModule, IconModule, TabsModule } from './../../../../shared';

// Components
import { ViewDatasetComponent } from './view-dataset.component';
import { DataSourceComponent } from './data-source/data-source.component';
import { ViewDataComponent } from '../shared/modules/shared-data/view-data/view-data.component';

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [
    DataSourceComponent
    , ViewDatasetComponent
  ],
  imports: [
    CommonModule
    , IconModule
    , TabsModule
    , FormsModule
    , ButtonModule
    , MatTableModule
    , FormFieldModule
    , IconButtonModule
    , SharedDataModule
    , MatCheckboxModule
    , ReactiveFormsModule
    , MatSlideToggleModule
  ],
  entryComponents: [ViewDataComponent],
  exports: [ViewDatasetComponent]
})
export class ViewDatasetModule { }
