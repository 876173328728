/**
 * @author: Naga
 * @constant
 */
export const JOIN_TYPES: any[] = [
  {
    id: 1,
    key: 'Left',
    value: 'left'
  },
  {
    id: 2,
    key: 'Right',
    value: 'right'
  },
  {
    id: 3,
    key: 'Full Outer',
    value: 'outer'
  },
  {
    id: 4,
    key: 'Inner',
    value: 'inner'
  }
];

export const DATA_ASSET_TYP = [
  {
    "id": 1,
    "code": "VIZ",
    "name": "Visualization"
  },
  {
    "id": 2,
    "code": "DSRC",
    "name": "Data Source"
  },
  {
    "id": 3,
    "code": "QRY",
    "name": "Query"
  },
  {
    "id": 4,
    "code": "DS",
    "name": "Dataset"
  },
  {
    "id": 5,
    "code": "DP",
    "name": "Data Pipeline"
  },
  {
    "id": 6,
    "code": "DF",
    "name": "Data File"
  },
  {
    "id": 7,
    "code": "NB",
    "name": "Notebook"
  }
];

export const DATASET1 = {
  "basketName": "udp-tf-state1",
  "createDate": "2022-07-01T09:47:13.061Z",
  "dataAssetCategoryID": "e8bf6a44-1897-43df-b825-48a7dfe87461",
  "dataSourceID": "e134f953-9acb-4e73-af4b-672e97ce6f06",
  "fileCreateUserID": "demouser",
  "fileID": "e134f953-9acb-4e73-af4b-672e97ce6f06",
  "fileLocation": "year-past.csv",
  "fileMetadata": "None",
  "fileName": "Test record 1",
  "fileOwnerUserID": "None",
  "filePhysicalName": "year-past.csv",
  "fileSchema": {
      "entityName": "Test record 1",
      "entityPhysicalName": "Test record 1",
      "entityDescription": "This is where the description lives",
      "entityType": "Test record 1",
      "entityClassType": "",
      "entityLinkandLoadPolicy": "",
      "entityObjectUniqueIDFormat": "",
      "attribute": [
          {
              "name": "index",
              "type": "integer",
              "attributeName": "index",
              "attributePhysicalName": "",
              "attributeDescription": "",
              "attributeDataType": "integer",
              "isIdentifyingAttribute": "",
              "isRequired": "",
              "isForeignKey ": "",
              "isNullable ": "",
              "dataFormat": "",
              "isSensitiveData": ""
          },
          {
              "name": "Year",
              "type": "integer",
              "attributeName": "Year",
              "attributePhysicalName": "",
              "attributeDescription": "",
              "attributeDataType": "integer",
              "isIdentifyingAttribute": "",
              "isRequired": "",
              "isForeignKey ": "",
              "isNullable ": "",
              "dataFormat": "",
              "isSensitiveData": ""
          }
      ]
  },
  "fileSize": "509",
  "fileType": "csv",
  "importedFileFlag": "True",
  "isActive": "True",
  "parentFileID": "test",
  "updateDate": "2022-07-01T09:47:13.061Z",
  "updateUserID": "demouser",
  "dataSourceName": "Test record 1",
  "dataSourceType": "DF"
}

export const DATASET2 = {
  "basketName": "udp-tf-state1",
  "createDate": "2022-07-01T09:47:13.061Z",
  "dataAssetCategoryID": "e8bf6a44-1897-43df-b825-48a7dfe87461",
  "dataSourceID": "n134f953-9acb-4e73-af4b-672e97ce6f07",
  "fileCreateUserID": "demouser",
  "fileID": "n134f953-9acb-4e73-af4b-672e97ce6f07",
  "fileLocation": "industry.csv",
  "fileMetadata": "None",
  "fileName": "Test record 2",
  "fileOwnerUserID": "None",
  "filePhysicalName": "industry.csv",
  "fileSchema": {
      "entityName": "Test record 2",
      "entityPhysicalName": "Test record 2",
      "entityDescription": "This is where the description lives",
      "entityType": "Test record 2",
      "entityClassType": "",
      "entityLinkandLoadPolicy": "",
      "entityObjectUniqueIDFormat": "",
      "attribute": [
          {
              "name": "industry",
              "type": "integer",
              "attributeName": "index",
              "attributePhysicalName": "",
              "attributeDescription": "",
              "attributeDataType": "integer",
              "isIdentifyingAttribute": "",
              "isRequired": "",
              "isForeignKey ": "",
              "isNullable ": "",
              "dataFormat": "",
              "isSensitiveData": ""
          }
      ]
  },
  "fileSize": "509",
  "fileType": "csv",
  "importedFileFlag": "True",
  "isActive": "True",
  "parentFileID": "test",
  "updateDate": "2022-07-01T09:47:13.061Z",
  "updateUserID": "demouser",
  "dataSourceName": "Test record 2",
  "dataSourceType": "DF"
}