import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
    dataAssetType,
    Query,
    Visualization,
} from '@xfusiontech/data-visualizer';
import { Option } from '@xfusiontech/shared';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from './../../models/user.model';

@Component({
    selector: 'igv-share-in-dashboard-dialog',
    templateUrl: './share-in-dashboard-dialog.component.html',
    styleUrls: ['./share-in-dashboard-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareInDashboardDialogComponent implements OnInit, OnDestroy {
    readonly sharedTo_PUBLIC = 'public';
    readonly sharedTo_USERS = 'users';

    form: FormGroup;
    options$: Observable<Option[]>;
    onDestroy$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public params: {
            type: dataAssetType;
            entity: Visualization | Query;
            userQuery: Observable<User[]>;
        },
        private dialogRef: MatDialogRef<ShareInDashboardDialogComponent>,
        private fb: FormBuilder
    ) {}

    get assetName() {
        return this.params.type === 'VISUALIZATION'
            ? (this.params.entity as Visualization).visualizationName
            : (this.params.entity as Query).queryName;
    }

    get isSharedToUsers() {
        return this.form
            ? this.form.get('sharedTo').value === this.sharedTo_USERS
            : false;
    }

    ngOnInit() {
        this.initializeFormGroup();
        this.initializeUserOptions();
    }

    onSubmitClick() {
        const formValue = this.form.getRawValue();
        this.dialogRef.close({
            sharedTo: formValue.sharedTo,
            users:
                formValue.sharedTo === this.sharedTo_PUBLIC
                    ? []
                    : (formValue.users || []).map(x => x.value),
        });
    }

    private initializeFormGroup() {
        this.form = this.fb.group({
            sharedTo: [this.sharedTo_PUBLIC, Validators.required],
            users: [],
        });

        this.form
            .get('sharedTo')
            .valueChanges.pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                if (value === this.sharedTo_PUBLIC) {
                    this.form.get('users').clearValidators();
                }

                if (value === this.sharedTo_USERS) {
                    this.form.get('users').setValidators(Validators.required);
                }

                this.form.updateValueAndValidity();
            });
    }

    private initializeUserOptions() {
        this.options$ = this.params.userQuery.pipe(
            map(users =>
                users.map(user => ({
                    value: user.userID,
                    name: [user.firstName, user.middleName, user.lastName]
                        .filter(x => !!x)
                        .join(' '),
                }))
            )
        );
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
