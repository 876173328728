import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, UserIdentity, UserService } from '@xfusiontech/shared';
import { Observable } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { User } from './../../components/header/user.model';
import { LOGIN_PATH } from './../../consts/paths';
import { LOGIN_API } from './../../consts/urls';

@Injectable({
    providedIn: 'root',
})
export class HomePageService {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private httpClient: HttpClient
    ) {}

    queryUserInfo() {
        const accessToken = this.authService.getAccessToken();
        const userID = this.authService.getUserID();

        if (!accessToken || !userID) {
            this.logout();
        }

        this.httpClient
            .post(`${LOGIN_API}/udp-core/user/auth`, null, {
                headers: {
                    userID,
                    accessToken,
                },
            })
            .pipe(take(1))
            .subscribe((user: UserIdentity) => {
                if (user && user.userInfo) {
                    this.userService.setUser(user);
                } else {
                    this.logout();
                }
            });
    }

    getUserInfo(): Observable<User> {
        return this.userService.user.pipe(
            filter(user => user != null && user.userInfo != null),
            map(({ userInfo }) => ({
                name: `${userInfo.firstName} ${userInfo.lastName}`,
                position: userInfo.designation,
                avatar: '',
            }))
        );
    }

    logout() {
        this.authService.logout();
        this.router.navigate([LOGIN_PATH]);
    }
}
