import * as _ from 'lodash/fp';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { SelectRowTypeDialogService } from '../select-row-type-dialog.service';
import { RowLayoutOption } from '../select-row-type-dialog.types';

@Component({
    selector: 'udp-select-row-type-dialog',
    templateUrl: './select-row-type-dialog.component.html',
    styleUrls: ['./select-row-type-dialog.component.scss']
})
export class SelectRowTypeDialogComponent {

    options: RowLayoutOption[];
    selection: boolean[];

    constructor(
        dialogService: SelectRowTypeDialogService,
        private dialogRef: MatDialogRef<SelectRowTypeDialogComponent>
    ) {
        this.options = dialogService.getOptions();
        this.selection = dialogService.getInitialSelection();
    }

    selectOption(idx: number) {
        this.selection = this.selection.map((__, index) => index === idx);
    }

    onSaveClick() {
        const selectedIdx = _.findIndex(_.identity, this.selection);
        this.dialogRef.close(this.options[selectedIdx].value);
    }

}
