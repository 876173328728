import * as _ from 'lodash/fp';

import { GridLayoutModel } from '../../grid-layout/grid-layout.types';
import { WidgetModel } from '../../widget/widget.types';

export const removeCell = (
    currentLayout: GridLayoutModel<WidgetModel>,
    rowIndex: number,
    cellIndex: number
): GridLayoutModel<WidgetModel> => {
    const next = _.merge({}, currentLayout);
    const selectedRow = next.rows[rowIndex];
    selectedRow.cells[cellIndex].data = null;

    return next;
};
