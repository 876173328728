import { DataType } from './global-search.types';

export const ADVANCED_SEARCH_DATA: DataType[] = [
    {
        entityClassType: 'Address',
        entityName: 'Address',
        entityLabel: 'Address',
        identifyingAttributes: [
            {
                entityClassType: 'StructuredAddress',
                entityName: 'PatientAddress',
                entityLabel: 'Patient Address',
                attributes: [
                    {
                        attributeName: 'StreetName',
                        attributeLabel: 'Street Code',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    },
                    {
                        attributeName: 'ZipCode',
                        attributeLabel: 'Zip Code',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    },
                    {
                        attributeName: 'City',
                        attributeLabel: 'City',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        listValues: 'cityCodeSet',
                        required: false
                    },
                    {
                        attributeName: 'County',
                        attributeLabel: 'County',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        listValues: 'countCodeSet',
                        required: false
                    },
                    {
                        attributeName: 'Latitude',
                        attributeLabel: 'Latitude',
                        attributeDataType: 'double',
                        displayType: 'double',
                        required: false
                    },
                    {
                        attributeName: 'Longitude',
                        attributeLabel: 'Longitude',
                        attributeDataType: 'double',
                        displayType: 'double',
                        required: false
                    }
                ]
            }
        ]
    },
    {
        entityClassType: 'Facility',
        entityName: 'Facility',
        entityLabel: 'Facility',
        identifyingAttributes: [
            {
                entityClassType: 'Facility',
                entityName: 'Facility',
                entityLabel: 'Facility',
                attributes: [
                    {
                        attributeName: 'FacilityName',
                        attributeLabel: 'Facility Name',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'FacilityType',
                        attributeLabel: 'Facility Type',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'FacilityStatus',
                        attributeLabel: 'FacilityStatus',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    }
                ]
            },
            {
                entityClassType: 'StructuredAddress',
                entityName: 'PatientAddress',
                entityLabel: 'Patient Address',
                attributes: [
                    {
                        attributeName: 'ZipCode',
                        attributeLabel: 'Zip Code',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    },
                    {
                        attributeName: 'StreetName',
                        attributeLabel: 'Street Name',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    }
                ]
            }
        ]
    },
    {
        entityClassType: 'Organization',
        entityName: 'Organization',
        entityLabel: 'Organization',
        identifyingAttributes: [
            {
                entityClassType: 'Organization',
                entityName: 'Organization',
                entityLabel: 'Organization',
                attributes: [
                    {
                        attributeName: 'OrganizationName',
                        attributeLabel: 'Organization Name',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'OrganizationCode',
                        attributeLabel: 'Organization Code',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'OrganizationNAICSCode',
                        attributeLabel: 'Organization NAICS Code',
                        displayType: 'text',
                        attributeDataType: 'String'
                    }
                ]
            },
            {
                entityClassType: 'StructuredAddress',
                entityName: 'OrganizationAddress',
                entityLabel: 'Organization Address',
                attributes: [
                    {
                        attributeName: 'ZipCode',
                        attributeLabel: 'Zip Code',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    },
                    {
                        attributeName: 'City',
                        attributeLabel: 'City',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        listValues: 'cityCodeSet',
                        required: false
                    },
                    {
                        attributeName: 'County',
                        attributeLabel: 'County',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        listValues: 'countCodeSet',
                        required: false
                    },
                    {
                        attributeName: 'StreetName',
                        attributeLabel: 'Street Name',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    }
                ]
            }
        ]
    },
    {
        entityClassType: 'Person',
        entityName: 'Patient',
        entityLabel: 'Emergency Patient',
        identifyingAttributes: [
            {
                entityClassType: 'Person',
                entityName: 'Patient',
                entityLabel: 'Emergency Patient',
                attributes: [
                    {
                        attributeName: 'DateOfBirth',
                        attributeLabel: 'DateOfBirth',
                        attributeDataType: 'Date',
                        displayType: 'date',
                        attributeFormat: 'DD/MM/YYYY',
                        required: false
                    },
                    {
                        attributeName: 'DateOfDeath',
                        attributeLabel: 'DateOfDeath',
                        attributeDataType: 'Date',
                        displayType: 'date',
                        attributeFormat: 'DD/MM/YYYY',
                        required: false
                    },
                    {
                        attributeName: 'SexatBirthCode',
                        attributeLabel: 'SexatBirthCode',
                        displayType: 'dropdown',
                        listValues: 'genderCodeSet',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'deacesedFlag',
                        attributeLabel: 'deacesedFlag',
                        displayType: 'dropdown',
                        listValues: 'deacesedCodeSet',
                        attributeDataType: 'String',
                        required: false
                    }
                ]
            },
            {
                entityClassType: 'PersonName',
                entityName: 'PatientName',
                entityLabel: 'Emergency Patient Name',
                attributes: [
                    {
                        attributeName: 'FirstName',
                        attributeLabel: 'FirstName',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false,
                        comparator: {
                            comparatorSymbol: '=',
                            comparatorLabel: '=',
                            minFuzziness: 40,
                            maxFuzziness: 100,
                            defaultFuzziness: 50
                        }
                    },
                    {
                        attributeName: 'LastName',
                        attributeLabel: 'LastName',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false,
                        comparator: {
                            comparatorSymbol: '=',
                            comparatorLabel: '=',
                            minFuzziness: 40,
                            maxFuzziness: 100,
                            defaultFuzziness: 50
                        }
                    },
                    {
                        attributeName: 'MiddleName',
                        attributeLabel: 'MiddleName',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false,
                        comparator: {
                            comparatorSymbol: '=',
                            comparatorLabel: '=',
                            minFuzziness: 40,
                            maxFuzziness: 100,
                            defaultFuzziness: 50
                        }
                    }
                ]
            },
            {
                entityClassType: 'Identification',
                entityName: 'Identification',
                entityLabel: 'Patient Identification',
                attributes: [
                    {
                        attributeName: 'IdentificationNumber',
                        attributeLabel: 'SSN',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false,
                        comparator: {
                            comparatorSymbol: '=',
                            comparatorLabel: '=',
                            minFuzziness: 40,
                            maxFuzziness: 100,
                            defaultFuzziness: 50
                        }
                    }
                ]
            },
            {
                entityClassType: 'Ethnicity',
                entityName: 'PatientEthnicity',
                entityLabel: 'Patient Ethnicity',
                attributes: [
                    {
                        attributeName: 'EthnicityCode',
                        attributeLabel: 'Ethnicity Code',
                        displayType: 'dropdown',
                        values: 'ethnicityCodeSet',
                        attributeDataType: 'String',
                        required: false
                    }
                ]
            },
            {
                entityClassType: 'Race',
                entityName: 'PatientRace',
                entityLabel: 'Patient Race',
                attributes: [
                    {
                        attributeName: 'RaceCode',
                        attributeLabel: 'Race Code',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        values: 'raceCodeSet',
                        required: false
                    }
                ]
            },
            {
                entityClassType: 'StructuredAddress',
                entityName: 'PatientAddress',
                entityLabel: 'Patient Address',
                attributes: [
                    {
                        attributeName: 'ZipCode',
                        attributeLabel: 'Zip Code',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    },
                    {
                        attributeName: 'City',
                        attributeLabel: 'City',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        listValues: 'cityCodeSet',
                        required: false
                    },
                    {
                        attributeName: 'County',
                        attributeLabel: 'County',
                        attributeDataType: 'String',
                        displayType: 'dropdown',
                        listValues: 'countCodeSet',
                        required: false
                    },
                    {
                        attributeName: 'StreetName',
                        attributeLabel: 'Street Name',
                        attributeDataType: 'String',
                        displayType: 'text',
                        required: false
                    }
                ]
            }
        ]
    },
    {
        entityClassType: 'Product',
        entityName: 'Vaccine',
        entityLabel: 'Vaccine',
        identifyingAttributes: [
            {
                entityClassType: 'Product',
                entityName: 'Vaccine',
                entityLabel: 'Vaccine',
                attributes: [
                    {
                        attributeName: 'productCompositionLevelCode',
                        attributeLabel: 'CompositionLevel Code',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'productCompositionLevelCodeName',
                        attributeLabel: 'CompositionLevel Code Name',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'productid',
                        attributeLabel: 'Product ID',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'productname',
                        attributeLabel: 'productname',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false,
                        comparator: {
                            comparatorSymbol: '=',
                            comparatorLabel: '=',
                            minFuzziness: 40,
                            maxFuzziness: 100,
                            defaultFuzziness: 50
                        }
                    }
                ]
            },
            {
                entityClassType: 'Organization',
                entityName: 'Organization',
                entityLabel: 'Organization',
                attributes: [
                    {
                        attributeName: 'OrganizationName',
                        attributeLabel: 'Manufacturer Name',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    },
                    {
                        attributeName: 'OrganizationCode',
                        attributeLabel: 'Manufacturer Code',
                        displayType: 'text',
                        attributeDataType: 'String',
                        required: false
                    }
                ]
            }
        ]
    }
];
