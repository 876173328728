import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[udpMouseHold]'
})
export class MouseHoldDirective implements OnDestroy {

    @Output('udpMouseHold')
    mouseHold: EventEmitter<void> = new EventEmitter();

    private onDestroy$: Subject<void> = new Subject();
    private onMouseUp$: Subject<void> = new Subject();

    @HostListener('mousedown')
    onMouseDown() {
        interval(50)
            .pipe(
                takeUntil(this.onDestroy$),
                takeUntil(this.onMouseUp$)
            )
            .subscribe(() => this.mouseHold.emit());
    }

    @HostListener('mouseup')
    onMouseUp() {
        this.onMouseUp$.next();
    }

    ngOnDestroy() {
        this.onMouseUp$.next();
        this.onMouseUp$.complete();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
