import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataAssetCategory, Visualization } from '@xfusiontech/data-visualizer';
import { UserService } from '@xfusiontech/shared';
import { BehaviorSubject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { MAIN_API } from './../../consts/urls';

@Injectable({
    providedIn: 'root',
})
export class DashboardPageService {
    constructor(
        private httpClient: HttpClient,
        private userService: UserService
    ) {}

    private dataAssetCategories$ = new BehaviorSubject<DataAssetCategory[]>([]);

    get dataAssetCategories() {
        return this.dataAssetCategories$.asObservable();
    }

    queryDataAssetsCategories(): void {
        const userID = this.userService.userIDSnapshot;
        const URL = `${MAIN_API}/visualizations/users/${userID}/dataAssets/?assetType=VISUALIZATION`;
        const query = this.httpClient.get<DataAssetCategory[]>(URL);

        query
            .pipe(
                shareReplay(),
                take(1)
            )
            .subscribe(categories =>
                this.dataAssetCategories$.next(categories)
            );
    }

    createOrUpdateCategory(category: DataAssetCategory): void {
        const userID = this.userService.userIDSnapshot;
        this.httpClient
            .post(
                `${MAIN_API}/visualizations/users/${userID}/dataAssets`,
                category
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });
    }

    deleteCategory(category: DataAssetCategory): void {
        const userID = this.userService.userIDSnapshot;
        this.httpClient
            .delete(
                `${MAIN_API}/visualizations/users/${userID}/dataAssets/${category.dataAssetCategoryID}`
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });
    }

    sendVisualizationByEmail(email: string, visualization: Visualization) {
        // todo - integrate with real api call
        this.queryDataAssetsCategories();
    }

    shareVisualizationInDashboard(
        sharedTo: string,
        users: string[],
        visualization: Visualization
    ) {
        // todo - integrate with real api call
        this.queryDataAssetsCategories();
    }

    editVisualization(visualization: Visualization, categoryId: number) {
        const userID = this.userService.userIDSnapshot;
        this.httpClient
            .put(
                `${MAIN_API}/visualizations/users/${userID}/dataAssetCategories/${categoryId}`,
                {
                    visualizationID: visualization.visualizationID,
                    visualizationDescription:
                        visualization.visualizationDescription,
                    visualizationName: visualization.visualizationName,
                    userID: userID,
                }
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });

        this.queryDataAssetsCategories();
    }

    deleteVisualization(visualization: Visualization) {
        const userID = this.userService.userIDSnapshot;
        this.httpClient
            .delete(
                `${MAIN_API}/visualizations/users/${userID}/dataAssetCategories/${visualization.visualizationID}?assetType=VISUALIZATION`
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });
    }
}
