// Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDataModule } from '../shared/modules/shared-data/shared-data.module';
import { MatCheckboxModule, MatSlideToggleModule, MatTableModule, MatCardModule, MatIconModule, MatSelectModule,MatButtonModule, MatButtonToggleModule,MatMenuModule, MatTooltipModule } from '@angular/material';

// Library Imports
import { ButtonModule, FormFieldModule, IconButtonModule, IconModule, SlideToggleModule, TabsModule } from './../../../../shared';
import { KendoModule } from 'src/app/third-party-libs/kendo.module';
import { ViewReferenceDatasetComponent } from './view-reference-dataset.component';
import { AngularMaterialModule } from 'src/app/third-party-libs/material.module';
import { DataImportModule } from '@xfusiontech/data-import';
import { CodeMaptableModule } from '../codemaptable/codemaptable.module';

// Components
const EXPORTS: any[] = [
  ViewReferenceDatasetComponent
  ];

  /**
   *
   */
@NgModule({
  declarations: [
    ...EXPORTS
  ],
  imports: [
    CommonModule
    , KendoModule
    , MatCardModule
    , MatIconModule
    , MatSelectModule
    , MatButtonModule
    , MatButtonToggleModule
    , MatMenuModule
    , IconModule
    , TabsModule
    , FormsModule
    , ButtonModule
    , MatTableModule
    , FormFieldModule
    , IconButtonModule
    , SharedDataModule
    , MatCheckboxModule
    , ReactiveFormsModule
    , MatSlideToggleModule
    , SlideToggleModule
    , MatTooltipModule
    , AngularMaterialModule
    , DataImportModule
    , CodeMaptableModule
  ],
  entryComponents: [ViewReferenceDatasetComponent],
  exports: [...EXPORTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ViewReferenceDatasetModule { }
