/**
 * @author: Naga
 * @constant
 */
export const DISP_COLUMNS_CFG: any = {
  selected: {
    field: 'selected',
    controlName: 'selected',
    editable: true,
    fieldType: 'CHECKBOX'
  },
  attributeName: {
    name: 'Attribute Name',
    field: 'attributeName'
  },
  attributePhysicalName: {
    name: 'Attribute Physical Name',
    field: 'attributePhysicalName'
  },
  attributeDescription: {
    name: 'Description',
    field: 'attributeDescription',
    editable: true,
    fieldType: 'POPUP'
  },
  attributeDataType: {
    name: 'Data Type',
    field: 'attributeDataType',
    controlName: 'attributeDataType',
    editable: true,
    fieldType: 'SELECT'
  },
  isIdentifyingAttribute: {
    name: 'Id Attribute',
    field: 'isIdentifyingAttribute',
    controlName: 'isIdentifyingAttribute',
    editable: true,
    fieldType: 'RADIO'
  },
  isRequired: {
    name: 'Required',
    field: 'isRequired',
    controlName: 'isRequired',
    editable: true,
    fieldType: 'RADIO'
  },
  isForeignKey: {
    name: 'Foreign Key',
    field: 'isForeignKey',
    controlName: 'isForeignKey',
    editable: true,
    fieldType: 'RADIO'
  },
  isNullable: {
    name: 'Nullable',
    field: 'isNullable',
    controlName: 'isNullable',
    editable: true,
    fieldType: 'RADIO'
  },
  dataFormat: {
    name: 'Data Format',
    field: 'dataFormat',
    controlName: 'dataFormat',
    editable: true,
    fieldType: 'INPUT'
  },
  isSensitiveData: {
    name: 'Sensitive Data',
    field: 'isSensitiveData',
    controlName: 'isSensitiveData',
    editable: true,
    fieldType: 'RADIO'
  }
};

/**
 * @constant
 */
export const DATA_TYPES: string[] = [
  'STRING',
  'INTEGER',
  'DECIMAL',
  'BOOLEAN',
  'DATE',
  'TIME',
  'NULL',
  'DATETIME',
  'LONG',
  'CHAR'
];
