import { Diagram } from '../Diagram';
import { Subject } from 'rxjs';

export abstract class DiagramService {

    protected diagram: Diagram;
    protected onDiagramUnbind$: Subject<void> = new Subject();

    bindDiagram(diagram: Diagram) {
        this.diagram = diagram;
    }

    unbindDiagram() {
        this.onDiagramUnbind$.next();
        this.diagram = null;
    }

}
