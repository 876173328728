import * as _ from 'lodash/fp';
import { ParameterType } from '../../../../../types/form';

const parseValue = _.identity;

const parseList = _.flowRight(
    _.join(',\n'),
    _.map(parseValue)
);

const parseDictionary = _.flowRight(
    _.join(',\n'),
    _.map(
        _.flowRight(
            parseValue,
            _.join(': '),
        )
    ),
    _.entries
);

export const parsePropertyValue = ({ type, value, default: defaultValue }) => {
    if (_.isUndefined(value)) {
        return defaultValue;
    }

    switch (type) {
        case ParameterType.List:
            return parseList(value);
        case ParameterType.Dictionary:
            return parseDictionary(value);
        default:
            return parseValue(value);
    }
};
