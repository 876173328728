import { Component, Input } from '@angular/core';
import { fadeInOut } from './widget-type-element.animations';

@Component({
    selector: 'udp-widget-type-element',
    templateUrl: './widget-type-element.component.html',
    styleUrls: ['./widget-type-element.component.scss'],
    animations: [
        fadeInOut
    ]
})
export class WidgetTypeElementComponent {

    @Input()
    label: string;

    @Input()
    description: string;

    @Input()
    selected?: boolean;

    @Input()
    iconPath?: string;

    showDescription: boolean;

}
