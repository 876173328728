import { GridLayoutModel } from '../../grid-layout/grid-layout.types';
import { WidgetModel } from '../../widget/widget.types';

export const removeRowAtIndex = (
    currentLayout: GridLayoutModel<WidgetModel>,
    rowIndex: number,
) => ({
    ...currentLayout,
    rows: currentLayout.rows
        .filter((__, idx) => idx !== rowIndex)
});
