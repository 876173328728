import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DataType, IconMap, AdvancedSearchFormModel, NavigationItemModel } from '../global-search.types';
import { mapAttributesToFields } from './utils/mapAttributesToFields';
import { mapAttributesToGroups } from './utils/mapAttributesToGroups';
import { ADVANCED_SEARCH_DATA } from '../global-search.consts';

@Injectable()
export class AdvancedSearchService {

    private type: BehaviorSubject<string> = new BehaviorSubject<string>('Address');

    constructor(private formBuilder: FormBuilder) {}

    getFormDataForSelectedEntity(): Observable<AdvancedSearchFormModel> {
        return this.type.pipe(
            switchMap((type) => this.getDataForType(type)),
            map(({ identifyingAttributes }) => {
                return {
                    fields: mapAttributesToFields(identifyingAttributes),
                    group: mapAttributesToGroups(
                        this.formBuilder,
                        identifyingAttributes
                    )
                } as AdvancedSearchFormModel;
            })
        );
    }

    getEntityTypes(): Observable<NavigationItemModel[]> {
        return this.type.pipe(
            map(() => ADVANCED_SEARCH_DATA),
            map((items) => {
                return _.map(({entityLabel, entityClassType}) => {
                    return {
                        label: entityLabel,
                        value: entityClassType,
                        icon: IconMap[entityClassType.toLowerCase()],
                        selected: entityClassType === this.type.value
                    } as NavigationItemModel;
                }, items);
            })
        );
    }

    setType(type: string) {
        this.type.next(type);
    }

    private getDataForType(type: string): Observable<DataType> {
        return _.flowRight(
            (val) => of(val),
            _.first,
            _.filter((typeObj: any) =>
                typeObj.entityClassType === type
            )
        )(ADVANCED_SEARCH_DATA);
    }

}
