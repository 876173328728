import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SelectDropDownModule } from 'ngx-select-dropdown';

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    NgxPermissionsModule,
    SelectDropDownModule
  ],
  exports: [
    CommonModule,
    ModalModule,
    BsDropdownModule,
    BsDatepickerModule,
    AccordionModule,
    CollapseModule,
    NgxPermissionsModule,
    SelectDropDownModule
  ],
})
export class NgxBootstrapModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NgxBootstrapModule,
      providers: [
        BsModalService
      ],
    };
  }
}
