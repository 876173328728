import * as go from 'gojs';
import * as _ from 'lodash/fp';

let isMouseCaptured = false;
let capturedRelativeCursorY: number = null;
let previousCursorY: number = null;

const getRelativeCursorPosition = (
    cursorY: number,
    { actualBounds: { top: handleTop } }: go.GraphObject
) => handleTop - cursorY;

const shouldMoveHandle = (
    cursorY: number,
    handle: go.GraphObject,
    delta: number,
    contentHeight: number
) => {
    const relativeCursorY = getRelativeCursorPosition(cursorY, handle);

    const isCursorBelowWhenScrollingDown =
        delta > 0 && relativeCursorY <= capturedRelativeCursorY;
    const isCursorAboveWhenScrollingUp =
        delta < 0 && relativeCursorY >= capturedRelativeCursorY;
    const isCorrectHeight = contentHeight && !_.isNaN(contentHeight);

    return isCorrectHeight
        && (isCursorBelowWhenScrollingDown || isCursorAboveWhenScrollingUp);
};

const getContentInfo = (
    handle: go.GraphObject,
    contentPanelName: string
) => {
    const contentPanel = handle.part.findObject(contentPanelName);
    return {
        contentPanel,
        contentHeight: contentPanel.actualBounds.height
    };
};

const calculateHandleOffset = (
    {
        actualBounds: { height: handleHeight },
        alignment: { offsetY: handleOffsetY }
    }: go.GraphObject,
    trackHeight: number,
    delta: number
) => {
    const minOffset = 0;
    const maxOffset = trackHeight - handleHeight;
    const nextOffset = handleOffsetY + delta;
    return _.clamp(minOffset, maxOffset)(nextOffset);
};

const updateHandleOffset = _.curry((
    handle: go.GraphObject,
    nextOffsetY: number
) => {
    handle.alignment = new go.Spot(0, 0, 0, nextOffsetY);
    return nextOffsetY;
});

const updateContentPanelOffset = _.curry((
    contentPanel: go.GraphObject,
    contentHeight: number,
    trackHeight: number,
    nextHandleOffsetY: number,
) => {
    const nextOffset = (-nextHandleOffsetY) * (contentHeight / trackHeight);
    contentPanel.alignment = new go.Spot(0, 0, 0, nextOffset);
});

const moveHandle = (trackHeight: number, contentPanelName: string) => (
    { documentPoint: { y: cursorY } }: go.InputEvent,
    handle: go.GraphObject
) => {
    if (!isMouseCaptured) {
        return;
    }

    const delta = cursorY - previousCursorY;
    previousCursorY = cursorY;

    const {
        contentPanel,
        contentHeight
    } = getContentInfo(handle, contentPanelName);

    if (!shouldMoveHandle(cursorY, handle, delta, contentHeight)) {
        return;
    }

    _.flowRight(
        updateContentPanelOffset(contentPanel, contentHeight, trackHeight),
        updateHandleOffset(handle)
    )(calculateHandleOffset(handle, trackHeight, delta));
};

const captureMouse = (
    { documentPoint: { y: cursorY } }: go.InputEvent,
    obj: go.GraphObject
) => {
    isMouseCaptured = true;
    previousCursorY = cursorY;
    capturedRelativeCursorY = getRelativeCursorPosition(cursorY, obj);
};

const releaseMouse = () => {
    isMouseCaptured = false;
    capturedRelativeCursorY = null;
    previousCursorY = null;
};

export const ScrollMouseTool = {
    captureMouse,
    releaseMouse,
    moveHandle
};
