import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {
    ConfirmDialogComponent,
    ConfirmDialogParams,
} from './confirm-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {
    constructor(private dialog: MatDialog) {}

    show(data: ConfirmDialogParams): MatDialogRef<ConfirmDialogComponent, any> {
        return this.dialog.open(ConfirmDialogComponent, { data });
    }
}
