import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { Configurations } from "./../configuration";
import { IntegrationWorkflowService } from 'src/app/workflow-editor/integration-workflow.service';
import { SubjectIdentificationConfigService } from './../subject-identification-config.service';
import * as _ from "lodash";
import { BroadcastService} from './../broadcast.service'

@Component({
  selector: "app-identifying-attributes",
  templateUrl: "./identifying-attributes.component.html",
  styleUrls: ["./identifying-attributes.component.scss"],
})
export class IdentifyingAttributesComponent implements OnInit {
  @Input() parentData: any;
  @Input() targetEntityData: any;
  @Input() internalData: any;
  @Input() selectedEntity: any;
  @Input() identifyConfigurationData: any;

  identifyAttributeForm: FormGroup;
  dropDownConfig = Configurations.ngxSelectdropDownConfig;
  popuptitle = "";
  popupbutton = "";

  entityNameDropdownOptions: Array<any> = [];
  entityAttributeDropdownOptions: Array<any> = [];

  config = {
    displayKey: "value", // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: "auto", // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 100, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true, // clears search criteria when an option is selected if set to true, default is false
  };
  constructor(
    private _fb: FormBuilder,
    private _cd: ChangeDetectorRef,
    private _workFlowService: IntegrationWorkflowService,
    private _identificationConfigService: SubjectIdentificationConfigService,
    private _bs: BroadcastService
  ) { }

  ngOnInit() {
    this.getEntityNameOptions();
    this.identifyAttributeForm = this._fb.group({
      attributeDetails: this._fb.array([this.addIdentifyParameters()]),
    });

    this.clearFormArray(
      <FormArray>this.identifyAttributeForm.get("attributeDetails")
    );
    this.identifyAttributeForm.reset();
    this.getIdentifiableData();
  }

  setchild() {
    const control = <FormArray>(
      this.identifyAttributeForm.controls["attributeDetails"]
    );
    while (control.length > 1) {
      control.removeAt(0);
    }
  }

  saveSearchConfig(workFlowData) {
    if (this.identifyAttributeForm.valid) {
      const formValues = this.identifyAttributeForm.value;
      this._identificationConfigService.mapEntitiesToJSON(
        this.targetEntityData,
        formValues,
        this.selectedEntity
      );
    }

    this._bs.storedIdentificationConfigDict$.subscribe((data) => {
      if (data) {
        this.identifyConfigurationData[0]["WorkflowComponent"] = JSON.stringify(
          data
        );
        let saveObj = {
          Source: 2,
          SourceDataTypeCode: this.parentData.sourceDataTypeCode,
          Status: this.parentData.status,
          TargetDataTypeCode: this.parentData.targetDataTypeCode,
          WorkflowID: this.parentData.workflowID,
          WorkflowComponentTypeCode: "identify",
          IntegrationWorkflowComponentName: "Decision Data",
          IntegrationWorkflowComponentStatus: "Active",
          WorkflowComponent: JSON.stringify(data),
        };
        this._workFlowService
          .updateIntegrationWorkflowInnerModule(saveObj)
          .subscribe((result) => {
            console.info("response", result.data);
          });
      }
    });
  }

  getIdentifiableData() {
    let obj = JSON.parse(
      this.identifyConfigurationData[0].IntegrationWorkflowComponentVersion
    );
    if (_.isPlainObject(obj) && !_.isEmpty(obj)) {
      obj["identifiableEntities"].forEach((entity) => {
        if (
          entity.subjectIdentifyingAttributes.targetEntityAttributes[0]
            .targetEntityName != ""
        ) {
          entity.subjectIdentifyingAttributes.targetEntityAttributes.forEach(
            (attr) => {
              this.setValueForForm(entity, attr);
            }
          );
        }
      });
    }
  }

  setValueForForm(entity, attr) {
    let entityNameObj = {
      item: {
        entityClass: entity.entityClassType,
        entityName: entity.entityName,
        entityType: entity.entityType,
        value:
          entity.entityName +
          " : " +
          entity.entityType +
          "[" +
          entity.entityClassType +
          "]",
      },
      value: entity.entityName,
    };

    let paramterEntityArr = [
      {
        targetAttributeName: {
          type: attr.targetAttributeDataType,
          value: attr.targetAttributeName,
        },
      },
    ];

    (<FormArray>this.identifyAttributeForm.get("attributeDetails")).push(
      this.initIdentifyParameters(entityNameObj, paramterEntityArr)
    );
  }

  // getPopupTitle() {
  //   this.popuptitle = "Add";
  //   this.popupbutton = "Save";
  //   this.addNewAttributeRow();
  // }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  getEntityNameOptions() {
    this.entityNameDropdownOptions = [];
    this.targetEntityData.forEach((item) => {
      this.entityNameDropdownOptions.push({
        item: item,
        value: item.entityType,
      });
    });
  }

  entityNameSelectionChanged(event) {
    this.entityAttributeDropdownOptions = [];

    for (let i = 0; i < this.internalData.length; i++) {
      for (let j = 0; j < this.internalData[i].EntityGroup.Entity.length; j++) {
        if (
          event.value.item.entityType ==
          this.internalData[i].EntityGroup.Entity[j].EntityName
        ) {
          this.internalData[i].EntityGroup.Entity[j].EntityAttributes.forEach(
            (item) => {
              this.entityAttributeDropdownOptions.push({
                type: item.Attribute[0].DataType,
                value: item.EntityAttributeName,
              });
            }
          );
        }
      }
    }
  }

  addIdentifyParameters() {
    return this._fb.group({
      entityName: ["", Validators.required],
      parameterEntity: this._fb.array([this.addParameterEntity()]),
    });
  }
  addParameterEntity() {
    return this._fb.group({
      // parameter: ["", Validators.required],
      targetAttributeName: ["", Validators.required],
    });
  }

  initIdentifyParameters(entity, parameter) {
    return this._fb.group({
      entityName: entity,
      parameterEntity: this._fb.array([this.initParameterEntity(parameter)]),
    });
  }

  initParameterEntity(parameter) {
    return this._fb.group({
      // parameter: ["", Validators.required],
      targetAttributeName: parameter[0].targetAttributeName,
    });
  }

  addNewAttributeRow() {
    (<FormArray>this.identifyAttributeForm.get("attributeDetails")).push(
      this.addIdentifyParameters()
    );
  }

  addParameterEntityOnClick(i) {
    (<FormArray>(
      this.identifyAttributeForm
        .get("attributeDetails")
      ["controls"][i].get("parameterEntity")
    )).push(this.addParameterEntity());
  }

  deleteQueryRow(i) {
    if (i) {
      const control = <FormArray>(
        this.identifyAttributeForm.controls["attributeDetails"]
      );
      control.removeAt(i);
    }
  }
  deleteParameterEntityRow(control, index) {
    control.removeAt(index);
  }
  saveConfiguration() { }
}
