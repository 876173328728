import { FormGroup } from '@angular/forms';
import { FilterItem } from './sidebar-with-filter.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FILTERS, getFiltersForm, getMapper } from './sidebar-with-filter.constants';
import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-sidebar-with-filter',
  templateUrl: './sidebar-with-filter.component.html',
  styleUrls: ['./sidebar-with-filter.component.scss']
})
export class SidebarWithFilterComponent implements OnInit {
  @Input()
  public isDatasetOperator = false;

  /**
   * @public
   * @type: {any[]}
   * @input
   */
  @Input()
  public data: any[] = [];

  @Input() showSidebar: any;

  /**
   * @public
   * @type: {any}
   */
  public cachedFilters: any;

  /**
   * @public
   * @type: {string}
   */
  public searchItem: string;

  /**
   * @public
   * @type: {FilterItem[]}
   * @input
   */
  @Input()
  public filters: FilterItem[];

  /**
   * @public
   * @type: {FormGroup}
   */
  public filterForm: FormGroup;

  /**
   * @public
   * @type: {boolean}
   */
  public sidebarExpanded = true;

  /**
   * @public
   * @type: {boolean}
   */
  public hasFilterApplied = false;

  /**
   * @public
   * @type: {EventEmitter<any>}
   * @output
   */
  @Output()
  public cat: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onTypeSelect: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @public
   * @type: {EventEmitter<any>}
   * @output
   */
  @Output()
  public subcat: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @public
   * @type: {EventEmitter<any>}
   * @output
   */
  @Output()
  public filterUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onExpandCat: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public newAsset: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public newCategory: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @constructor
   */
  constructor(private _dataCatalogService: DataCatalogService) {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.filters = this.filters || FILTERS;
    this.filterForm = getFiltersForm(
      this.filters
    );
    this.showSidebar ? this.sidebarExpanded = this.showSidebar : '';
    this._dataCatalogService.sidebarExpanded.subscribe(data => {
      this.sidebarExpanded = data;
    });
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * sets the search input.
   */
  public onSearch(value: string): void {
    this.searchItem = value;
  }
  onTypeClick(code: string) {
    if (code === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
      this.onTypeSelect.emit(code);
    }
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * clears the filters.
   */
  public clearFilters(): void {
    this.filterForm.reset();
    this.applyFilters(false);
  }

  /**
   * @public
   * @param: {filter<boolean>}
   * @return: void
   * @description: a helper method that
   * applies the filters.
   */
  public applyFilters(filter?: boolean): void {
    this.hasFilterApplied = filter;

    const filters: string[] = [];
    const vals: any = this.filterForm.value;
    const keys: string[] = Object.keys(vals);
    const mapper: any = getMapper(this.filters);

    for (const k of keys) {
      if (vals[k]) {
        filters.push(mapper[k]);
      }
    }

    this.cachedFilters = vals;
    this.filterUpdate.emit(filters);
  }

  toggleExpansion({asset, item}) {
    if (asset.dataSetDataSource.length === 0) {
      this.onExpandCat.emit({asset, dataAssetCode: item.dataAssetCode});
    } else if (item.dataAssetCode === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
      this.cat && this.cat.emit({ category: item, subCategory: asset });
    }
  }

  onCatClick({category, subCategory }) {
    if (this.isDatasetOperator) { return; }
    this.cat && this.cat.emit({ category, subCategory });
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * toggles the sidebar.
   */
  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * listens to menu open & close events
   * and sets the filter values if the
   * were not applied before closing the
   * menu.
   */
  public setCachedFilters(): void {
    if (!!this.cachedFilters) {
      this.filterForm.patchValue(
        this.cachedFilters
      );
    }
  }
  onAssetClick({category, subCategory}) {
    if (this.subcat) {
      this.subcat.emit({ category, subCategory});
    }
  }
}
