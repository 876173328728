import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { QueryVariable } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-query-text-editor',
    templateUrl: './query-text-editor.component.html',
    styleUrls: ['./query-text-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryTextEditorComponent implements ControlValueAccessor {
    @Input()
    disabled: boolean;

    @Input()
    queryVariables: QueryVariable[];

    @ViewChild('editableDiv', { static: false })
    editableDiv: ElementRef;

    value: string;

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    onChange: (value: any) => void = () => {};
    onTouched: () => void = () => {};

    onKeyUp() {
        const value = this.editableDiv.nativeElement.innerText;
        this.onTouched();
        this.onChange(value);
    }

    onBlur() {
        const value = this.editableDiv.nativeElement.innerText;
        this.value = value;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
