import * as go from 'gojs';

type Animatable<T, V> = [T, string, V, V];

export enum Easing {
    LINEAR,
    IN_QUAD,
    OUT_QUAD,
    IN_OUT_QUAD,
    IN_EXPO,
    OUT_EXPO
}

const easingMapping = {
    [Easing.LINEAR]: go.Animation.EaseLinear,
    [Easing.IN_QUAD]: go.Animation.EaseInQuad,
    [Easing.OUT_QUAD]: go.Animation.EaseOutQuad,
    [Easing.IN_OUT_QUAD]: go.Animation.EaseInOutQuad,
    [Easing.IN_EXPO]: go.Animation.EaseInExpo,
    [Easing.OUT_EXPO]: go.Animation.EaseOutExpo
};

export const animate = <T extends go.GraphObject | go.Diagram, V>(
    animatable: Animatable<T, V>[],
    duration: number,
    easing: Easing = Easing.IN_OUT_QUAD
) => {
    const animation = new go.Animation();
    animation.duration = duration;
    animation.easing = easingMapping[easing];
    animatable.forEach(([object, property, start, stop]) =>
        animation.add(object, property, start, stop));

    return new Promise((resolve) => {
        animation.finished = resolve;
        animation.start();
    });
};
