import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit {

  @Input() userId: string;
  @Input() customerId: string;
  constructor() { }

  ngOnInit() {
  }

}
