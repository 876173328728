import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { OverviewService } from '../../../../gojs/services/overview.service';

@Component({
    selector: 'xft-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements AfterViewInit {
    @ViewChild('overviewRef', { read: ElementRef, static: false })
    overviewRef: ElementRef;

    constructor(public overviewService: OverviewService) {}

    ngAfterViewInit() {
        this.overviewService.attachOverview(
            this.overviewRef.nativeElement
        );
    }
}
