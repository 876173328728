import { Component, Input } from '@angular/core';

@Component({
    selector: 'udp-logs-list-item',
    templateUrl: './logs-list-item.component.html',
    styleUrls: ['./logs-list-item.component.scss']
})
export class LogsListItemComponent {

    @Input()
    label: string;

    @Input()
    secondaryLabel: string;

    @Input()
    time: string;

    @Input()
    iconName: string;

    @Input()
    iconVariant: string;

}
