import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * @author: Naga
 */
@Injectable()
export class SpinnerService {

  /**
   * @protected
   * @type: {Subject<boolean>}
   */
  protected display$: Subject<boolean> = new Subject<boolean>();

  /**
   * @constructor
   */
  constructor() {}

  /**
   * @public
   * @returns: void
   * @description: a helper method to
   * display the spinner.
   */
  public show(): void {
    this.display$.next(true);
  }

  /**
   * @public
   * @returns: void
   * @description: a helper method to
   * hide the spinner.
   */
  public hide(): void {
    this.display$.next(false);
  }

  /**
   * @public
   * @returns: void
   * @description: a helper method to
   * publish the state of the spinner.
   */
  public load(): Subject<boolean> {
    return this.display$;
  }
}
