import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'udp-horizontal-bar',
    templateUrl: './horizontal-bar.component.html',
    styleUrls: ['./horizontal-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalBarComponent {

    @Input()
    label: string;

    @Input()
    value: number;

    @Input()
    unit?: string;

    @Input()
    diffValue?: number;

    @Input()
    diffSecondary?: string;

    @Input()
    color?: string;

    @Input()
    maxValue: number;

    get barWidth(): string {
        return `${100 * this.value / this.maxValue}%`;
    }

}
