import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataFileType } from './data-import.enum';
import { UploadConfig } from './data-import.interface';
import { DataImportService } from './data-import.service';
import { environment } from 'src/environments/environment';
import { DELIMETERS, ENC_TYPES, _get } from './data-import.constants';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';
import { DataCatalogService } from 'src/app/service/data-catalog.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'xft-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent implements OnDestroy {

  @Input() customerID: string;
  @Input() userID: string;
  /**
   * @public
   * @type: {any}
   */
  public response: any;

  /**
   * @public
   * @type: {File[]}
   */
  public files: File[] = [];

  /**
   * @public
   * @type: {number}
   */
  public activeTabId: number;

  /**
   * @public
   * @type: {any}
   */
  public entities: any[] = [];

  /**
   * @public
   * @type: {string}
   * @input
   */
   @Input()
  public dataSourceID: string;

    /**
   * @public
   * @type: {string}
   * @input
   */
     @Input()
     public assetType: string;

     /**
      * @public
      * @type: {string}
      * @input
      */
      @Input()
      public dataSource: string;

  /**
   * @public
   * @type: {any[]}
   */
  public delimeters: any[] = [];

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public heading: boolean = true;

  /**
   * @public
   * @type: {UploadConfig}
   * @input
   */
  @Input()
  public uploadConf: UploadConfig;

  /**
   * @public
   * @type: {any}
   */
  public customerImportFileId: any;

  /**
   * @public
   * @type: {File[]}
   */
  public removedFiles: File[] = [];

  /**
   * @public
   * @type: {FormGroup}
   */
  public fileImportForm: FormGroup;

  /**
   * @public
   * @type: {boolean}
   */
  public multiple: boolean = false;

  /**
   * @public
   * @type: {boolean}
   */
  public allFolders: boolean = false;

  /**
   * @public
   * @type: {string}
   * @input
   */
  @Input()
  public dataSourceClassType: string;

  @Input()
  public isExplorerTab: boolean = false;

  /**
   * @public
   * @type: {boolean}
   */
  public disableImport: boolean = false;

  private subscriptions: Subscription[] = [];

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public displayActions: boolean = true;

  /**
   * @public
   * @type: {string[]}
   */
  public encTypes: string[] = ENC_TYPES;

  /**
   * @public
   * @type: {EventEmitter<boolean>}
   */
  @Output()
  public error: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @public
   * @type: {EventEmitter<boolean>}
   */
   @Output()
  public success: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  isCsv: boolean = false;
  fileDetails: any;

  /**
   * @constructor
   * @param: {fb<FormBuilder>}
   * @param: {dataImportService<DataImportService>}
   */
  constructor(
    private fb: FormBuilder,
    private dataImportService: DataImportService,
    private _errorHandlingService: ErrorHandlingService,
    private _dataCatalogService: DataCatalogService) {
    this.delimeters = DELIMETERS;
    this.initForm();
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * submits the upload form.
   */
  public onFormUpload(): void {
    if (this.fileImportForm.valid) {
      this.disableImport = true;
      this.import();
    }
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * imports the metadata.
   */
  private import(): void {
    const formData: FormData = this.getFormData();
    if(this.dataSource == 'RDS'){
      const formRds: FormData = new FormData();
      formRds.append('file',this.fileDetails,this.fileDetails.name);
      this.subscriptions[this.subscriptions.length] = this._dataCatalogService.uploadFile(this.assetType,this.dataSourceID,this.customerID,this.userID,formRds).subscribe(data => {
        if(data['code'] == 0){
          this._dataCatalogService.closeMapTab.next(data['data']);
          this._errorHandlingService.errorSuccessSnack(118,true);
        } else {
          this.onReset();
          this._errorHandlingService.errorSuccessSnackMessage(data['message'],false);
        }
      })

    } else {
    const url: string = `${environment.fileImportUrl}`;

    this.dataImportService
    .importMetadata(url, formData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (!!res && res.fileMetadataID) {
        this.success.emit(true);
        this.response = res;
        this.customerImportFileId = res.fileMetadataID;
        this.processData();
      } else {
        this.handleErrors();
      }
    }, err => {
      this.handleErrors();
    });
  }
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * handles import metadata API errors.
   */
  private handleErrors(): void {
    this.disableImport = false;
    this.error.emit(true);
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * constructs the form data for import
   * metadata API call.
   */
  private getFormData(): FormData {
    const formData: FormData = new FormData();
    const form: any = this.fileImportForm.getRawValue();

    const {
      fileFormat,
      delimeterType,
      firstRecordHasFieldName,
      numberOfHeaderLineToSkip
    }: any = form;

    const metadata: any = {
      delimeterType: ',',
      firstRecordHasFieldName,
      fileFormat: fileFormat.fileType,
      dataSourceID: this.dataSourceID,
      dataSourceType: this.dataSourceClassType,
      numberOfHeaderLineToSkip: +numberOfHeaderLineToSkip
    };

    formData.append('file', this.files[0]);
    formData.append('fileMetadata', JSON.stringify(
      metadata
    ));

    return formData;
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * initializes the file import form.
   */
  private initForm(): void {
    this.fileImportForm = this.fb.group({
      otherDelType: new FormControl(''),
      firstRecordHasFieldName: new FormControl(true),
      numberOfHeaderLineToSkip: new FormControl('0'),
      fileFormat: new FormControl('', [Validators.required]),
      delimeterType: new FormControl({ value: '', disabled: true }),
      fileEncoding: new FormControl('UTF-8', [Validators.required])
    });
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * processes the uploaded data.
   */
  public processData(): void {
    if (!!this.response) {
      const fd: any = this.response.fileDetails;

      if (Array.isArray(fd)) {
        this.entities = fd;
      } else {
        this.entities = [fd];
      }

      // setting up the default view
      this.setActiveTabId(0);
    }
  }

  /**
   * @public
   * @param: {idx<number>}
   * @return: void
   * @description: a helper method that
   * sets the active tab id.
   */
  public setActiveTabId(idx: number): void {
    this.activeTabId = idx;
  }

  /**
   * @public
   * @param: {force<boolean>}
   * @return: void
   * @description: a helper method that
   * resets the scope variables for file/
   * folder upload.
   */
  public onReset(force?: boolean): void {
    this.files = [];
    this.removedFiles = [];
    this.isCsv = false;

    if (!force) {
      this.fileImportForm.get('fileFormat')
      .reset('');
    }

    if(this.isExplorerTab) {
      this.fileImportForm.get('file_name').reset();
      this.fileImportForm.get('description').reset();
      this.disableImport = false;
    }
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * sets the form fields based on file
   * format selection.
   */
  public onFileFormatSelect(): void {
    const formats: string[] = [
      DataFileType.CSV,
      DataFileType.EXCEL
    ];

    setTimeout(() => {
      const value: any = this.fileImportForm
      .get('fileFormat').value;

      const del: AbstractControl = this.fileImportForm
      .get('delimeterType');

      const format: string = value.fileType;

      // for CSV & Excel file, pre select the options
      if (!!value && formats.includes(format)) {

        // pre-populate options for CVS file format
        if (format === DataFileType.CSV) {
          const comma: any = _get(1);

          del.setValue(comma.value);
        } else {
          del.setValue('');
        }

        del.disable();
      } else {
        del.setValue('');
        del.enable();
      }
    });
  }

  /**
   * @public
   * @param: {files<FileList>}
   * @return: void
   * @description: a helper method that
   * listens to file drop event & prepares
   * a list of files for file upload.
   */
  public onFileDropped(files: FileList): void {
    this.createFilesList(Array.from(files));
    if(this.dataSource == 'RDS'){
      let value = files[0].name;
      this.fileDetails = files[0];
      const fileType = value.split('.');
      if(fileType && fileType[1] == 'csv'){
        this.isCsv = true;
      } else {
        this.isCsv = false;
        this._errorHandlingService.errorSuccessSnack("149",false);
      }
    }
  }

  /**
   * @public
   * @param: {files<FileList>}
   * @param: {e<MouseEvent>}
   * @return: void
   * @description: a helper method that
   * gets triggered on file browse & prepares
   * a list of files for file upload.
   */
  public async onFileBrowse(
    files: FileList, e: MouseEvent): Promise<any> {

    const cfg: any = this.uploadConf;
    const uType: any = cfg.uploadType;
    let arr: any[] = Array.from(files);
    const values: any = this.fileImportForm.value;
    let fileType: any = [];

    const id: string = values.fileFormat.id;
    // reset the input field
    (e.target as any).value = '';

    // // in case of folder selection
    // // filter out the allowed file types
    if (id === uType.Folder) {
      arr = this.getFilteredFiles(arr, id, cfg);
    }

    if(this.dataSource == 'RDS'){
      let value = arr[0].name;
      this.fileDetails = arr[0];
      fileType = value.split('.');
      if(fileType && fileType[1] == 'csv'){
        this.isCsv = true;
      } else {
        this.isCsv = false;
        this._errorHandlingService.errorSuccessSnack("149",false);
      }
    }

    this.createFilesList(arr);
  }

  /**
   * @private
   * @param: {list<any[]>}
   * @param: {id<string>}
   * @param: {cfg<any>}
   * @param: {skip<boolean>}
   * @return: any[]
   * @description: a helper method that
   * filters out the list based on the
   * supported file extensions.
   */
  private getFilteredFiles(
    list: any[], id: string, cfg: any, skip?: boolean): any[] {
    return list.filter((file) => {
      // get allowed file extenions for folder type
      const _id: string = skip ? '2' : id;
      const extns: any[] = (cfg.allowedExtns[_id] || '').split(',');

      // get the file extension
      const extn: string =
        file.name && file.name.replace(
          /^.*?(\.[a-zA-Z0-9]+)$/, '$1'
        );

      // add file to the truncated list
      if (!extns.includes(extn)) {
        this.truncatedFiles([file]);
      }
      return extns.includes(extn);
    });
  }

  /**
   * @public
   * @param: {files<FileList | any[]>}
   * @return: void
   * @description: a helper method that
   * listens to file removed event & prepares
   * a list of files that were removed.
   */
  public truncatedFiles(files: FileList | any[]): void {
    const ut: any = this.uploadConf.uploadType;
    const values: any = this.fileImportForm.value;

    const id: string = values.fileFormat.id;

    if (id === ut.Folder) {
      this.removedFiles = [
        ...this.removedFiles,
        ...Array.from(files)
      ];
    }
  }

  /**
   * @public
   * @param: {idx<number>}
   * @return: void
   * @description: a helper method that
   * deletes a file from the file list.
   */
  public deleteFile(idx: number): void {
    this.files.splice(idx, 1);
  }

  /**
   * @private
   * @param: {files<any[]>}
   * @return: void
   * @description: a helper method that
   * converts files list to an array list.
   */
  private createFilesList(files: any[]): void {
    if (files.length > 0) {
      for (const file of files) {
        this.addFile(file);
      }
    }
  }

  /**
   * @private
   * @param: {file<File>}
   * @return: void
   * @description: a helper method that
   * a file to the list.
   */
  private addFile(file: File): void {
    const item: any = this.files.find(
      (o) => o.name === file.name
    );

    if (!item) {
      this.files.push(file);
    }
  }

  /**
   * @public
   * @param: {bytes<number>}
   * @param: {decimals<number>}
   * @return: string
   * @description: a helper method that
   * formats the file size and returns
   * a lucid formatted file size.
   */
  public formatBytes(size: number, decimals: number): any {
    if (size === 0) {
      return '0 Bytes';
    }

    const k: number = 1024;
    const sizes: string[] = [
      'Bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB'
    ];
    const dm: number = decimals <= 0 ? 0 : decimals || 2;
    const i: number = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
