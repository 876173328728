import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Output,
    QueryList,
    EventEmitter,
    HostBinding,
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
    selector: 'udp-tab-group',
    templateUrl: './tab-group.component.html',
    styleUrls: ['./tab-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TabsComponent {
    @HostBinding('class') classes = 'udp-tab-group';

    @ContentChildren(TabComponent, { descendants: true }) tabs: QueryList<
        TabComponent
    >;

    @Output()
    selectionChange = new EventEmitter<string | null>();

    onTabChange(selected: TabComponent) {
        this.tabs.forEach(item => {
            item.selected = item.label === selected.label;
        });

        this.selectionChange.emit(selected.label);
    }
}
