import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'xft-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent {
    @Input()
    expanded = false;

    @Input()
    title = '';

    @Output()
    expandedChange: EventEmitter<boolean> = new EventEmitter();

    onExpandedChange() {
        this.expanded = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }
}
