import * as go from 'gojs';

export default class HistoryMember {

    constructor(
        public id: string,
        public changes: Array<go.ChangedEvent>
    ) { }

}
