import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataVisualizerModule } from '@xfusiontech/data-visualizer';
import {
    ADMINISTRATION_PATH,
    ALERTS_PATH,
    CATEGORY_ID_PARAM,
    DASHBOARD_PATH,
    DATA_IMPORT_PATH,
    LOGIN_PATH,
    QUERY_EDITOR_PATH,
    QUERY_ID_PARAM,
    RECOMMENDATIONS_PATH,
    VISUALIZATION_PATH,
} from './consts/paths';
import { VISUALIZATION_VIEW_SETTINGS } from './consts/visualization-view-settings';
import { AuthorizationGuard } from './guards/authorization.guard';
import { AdministrationPageComponent } from './pages/administration-page/administration-page.component';
import { AlertsPageComponent } from './pages/alerts-page/alerts-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { DataImportPageComponent } from './pages/data-import-page/data-import-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { QueryEditorPageComponent } from './pages/query-editor-page/query-editor-page.component';
import { RecommendationsPageComponent } from './pages/recommendations-page/recommendations-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: `${DASHBOARD_PATH}/`,
            },
            {
                path: DASHBOARD_PATH,
                component: DashboardPageComponent,
                redirectTo: `${DASHBOARD_PATH}/`,
                pathMatch: 'full',
            },
            {
                path: `${DASHBOARD_PATH}/:${CATEGORY_ID_PARAM}`,
                component: DashboardPageComponent,
            },
            {
                path: `${QUERY_EDITOR_PATH}`,
                redirectTo: `${QUERY_EDITOR_PATH}/`,
                component: QueryEditorPageComponent,
            },
            {
                path: `${QUERY_EDITOR_PATH}/:${QUERY_ID_PARAM}`,
                component: QueryEditorPageComponent,
            },
            {
                path: VISUALIZATION_PATH,
                loadChildren: () => DataVisualizerModule,
                data: { settings: VISUALIZATION_VIEW_SETTINGS },
            },
            {
                path: DATA_IMPORT_PATH,
                component: DataImportPageComponent,
            },
            {
                path: ADMINISTRATION_PATH,
                component: AdministrationPageComponent,
            },
            {
                path: RECOMMENDATIONS_PATH,
                component: RecommendationsPageComponent,
            },
            {
                path: ALERTS_PATH,
                component: AlertsPageComponent,
            },
        ],
    },
    {
        path: LOGIN_PATH,
        component: LoginPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InteractiveGraphViewRoutingModule {}
