import * as go from 'gojs';

import { generateGeometryFromSvgPath } from '../../../utils/generateGeometryFromSvgPath';
import { togglePropertiesTable } from '../../../utils/togglePropertiesTable';
import { EXPAND_BUTTON_HEIGHT, NODE_CONTENT_WIDTH, NODE_PADDING } from '../../../consts/shared';
import { CHEVRON_UP } from '../../../../consts/icons';
import { theme } from '../../../../consts/theme';

const $ = go.GraphObject.make;

const background = () => $(
    go.Shape,
    'RoundedRectangle',
    {
        fill: '#F5F5F5',
        stroke: null,
        desiredSize: new go.Size(NODE_CONTENT_WIDTH, EXPAND_BUTTON_HEIGHT),
        parameter1: 12
    }
);

const text = () => $(
    go.TextBlock,
    {
        text: 'Parameters',
        font: `${theme.fontWeight.light} 14px ${theme.fontFamily}`,
        stroke: theme.colors.text,
        alignmentFocus: go.Spot.Left,
        alignment: new go.Spot(0, 0.5, NODE_PADDING, 0)
    }
);

const expandIcon = () => $(
    go.Shape,
    {
        alignmentFocus: go.Spot.Right,
        alignment: new go.Spot(1, 0.5, -NODE_PADDING, 0),
        geometry: generateGeometryFromSvgPath(CHEVRON_UP.path),
        desiredSize: new go.Size(10, 5),
        angle: 180
    },
    new go.Binding('angle', 'expanded', (expanded) => expanded ? 0 : 180)
);

export const expandButton = () => $(
    go.Panel,
    go.Panel.Spot,
    {
        margin: new go.Margin(NODE_PADDING, 0, 0, 0),
        cursor: 'pointer',
        click: (__, obj: go.GraphObject) =>
            togglePropertiesTable(obj)
    },
    background(),
    text(),
    expandIcon()
);
