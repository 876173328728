import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SpinnerService } from '../layouts/spinner/spinner.service';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

/**
 * @author: Naga
 */
@Injectable()
export class InterceptorService implements HttpInterceptor {
  /**
   * @private
   * @type: {number}
   */
  private activeRequests: number = 0;

  /**
   * @constructor
   * @param {spinner<SpinnerService>}
   */
  constructor(private spinner: SpinnerService) { }

  /**
   * @public
   * @param: {req<HttpRequest<any>>}
   * @param: {next<HttpHandler>}
   *
   * @returns: {Observable<HttpEvent<any>>}
   * @description: N/A
   */
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.show();
    const token = localStorage.getItem(miscellaneousConst.ACCESS_TOKEN);
    if (token) {
        req = req.clone({
          headers: req.headers.set('Authorization', token)
        });
    }
    req = req.clone({
      headers: req.headers.set('Accept', 'application/json')
    });
    return next.handle(req).pipe(
      finalize(this.hide.bind(this)),
      catchError((err: HttpErrorResponse)  => {
        if (err.status == 401 || err.status == 403) {
          // TODO - Need to add a popup and also a mechanism to close data pipeline completely
          alert('Your session has expired. Please close the data pipeline and login to UDP app again!');
        }
        return throwError(err);
      })
    );
  }


  /**
   * @private
   * @returns: void
   * @description: hides the spinner
   * service request are completed.
   */
  private hide(): void {
    this.activeRequests--;

    if (this.activeRequests <= 0) {
      this.spinner.hide();
    }
  }

  /**
   * @private
   * @returns: void
   * @description: triggers the spinner
   * display as service requests are received.
   */
  private show(): void {
    this.activeRequests++;

    if (this.activeRequests === 1) {
      this.spinner.show();
    }
  }
}
