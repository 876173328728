import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ButtonComponent } from './button.component';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        MatButtonModule,
        CommonModule,
        MatProgressSpinnerModule,
    ],
    declarations: [
        ButtonComponent,
    ],
    exports: [
        ButtonComponent,
    ]
})
export class ButtonModule {}
