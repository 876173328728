import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SharedDataService } from '../shared/modules/shared-data/shared-data.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-create-dataset',
  templateUrl: './create-dataset.component.html',
  styleUrls: ['./create-dataset.component.scss']
})
export class CreateDatasetComponent implements OnInit, OnDestroy {

  /**
   * @public
   * @type: {string}
   */
  public selection = '';

  /**
   * @public
   * @type: {boolean}
   */
  @Input()
  public backBtn = true;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
   private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {router<Router>}
   * @param: {sharedDataService<SharedService>}
   */
  constructor(
    private router: Router,
    private sharedDataService: SharedDataService) {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.selection = '1';
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * checks for DB connection.
   */
  public testDbConnection(): void {}

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * that opens up data source view based
   * on selected data source type.
   */
  public viewDataSource(): void {
    const ref: MatDialogRef<any> = this.sharedDataService.viewDataSource();

    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => {});
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * checks for DB connection.
   */
  public saveDataset(): void {}

  /**
   * @public
   * @param: {item<any>}
   * @param: {reqType<string>}
   * @return: void
   * @description: a helper method that
   * triggers action based on a request
   * type.
   */
   public onAction(item: any, reqType: string): void {
    const link = '/dashboard/dmg/data-catalog/datasets';

    switch (reqType) {
      case 'NEW': {
        this.router.navigate([`${link}/create`]);
        break;
      }
      case 'BLEND': {
        this.router.navigate([`${link}/blend`]);
        break;
      }
    }
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
