import { NgModule } from '@angular/core';
import { StringArrayToSelectOptionsPipe } from './string-array-to-select-options.pipe';

const pipes = [StringArrayToSelectOptionsPipe];

@NgModule({
    declarations: pipes,
    exports: pipes,
})
export class StringArrayToSelectOptionsModule {}
