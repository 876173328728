import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDrawerContent } from '@angular/material';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import _ from 'lodash';

import { maximizeDrawer } from 'src/app/workflow-editor/components/editor/editor.animation';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';

import { BucketExplorerComponent } from './bucket-explorer/bucket-explorer.component';
import { ConfirmationDialogComponent } from 'libs/schema-explorer/src/lib/confirmation-dialog/confirmation-dialog.component';

import { UPDMilOperatorService } from './udp-mil-operator.service';
import { DataImportService } from 'libs/data-import';
import { CodeEditorPanelService } from '@workflow-editor/components/code-editor-panel/code-editor-panel.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { SchemaExplorerService } from 'libs/schema-explorer/src/lib/schema-explorer/schema-explorer.service';

import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'lib-upd-mil-operator',
  templateUrl: './upd-mil-operator.component.html',
  styleUrls: ['./upd-mil-operator.component.scss'],
  animations: [maximizeDrawer]
})
export class UpdMilOperatorComponent implements OnInit, OnDestroy {

  @ViewChild('content', { static: true })
  private content: MatDrawerContent;

  public datasets: any[] = [];
  private datasetsBackUp: any[] = [];

  private subjectEntitiesConfigs: any[] = [];

  // dataAssetTypeMap = { DF: 'File Asset', DP: 'Data Pipeline', QRY: 'Query', VIZ: 'Visualization', DS: 'Datasets', NB: 'Notebook' };
  private dataAssetTypeMap = { DF: 'File Asset', QRY: 'Query', DS: 'Datasets' };

  selectedDataset: any;
  subjectTargetData: any;
  subjectSourceCategory: any;
  showSubjectFields: any;
  showSubjectDelimeter = false;
  targetDataForm: FormGroup;
  shippedDataForm: FormGroup;

  showShippedStep = false;
  showDeliveredStep = false;
  showMDMConfig = false;
  mdmEntitiesAvailable = true;

  mdmEntityOptions: string[] = [];
  mdmConfigData: any;
  linkAndLoadPolicies: any;

  private mdmAttributesBasedOnEntityName: any;
  subjectMappingConfig: any;
  showSubjectMappingFlow = false;
  subjectMappingEntities = [];
  sourceData = [];
  subjectData = [];
  dataSetOperaterConfig: any;

  // MAIN Object which gets updates for Transformation Operator
  udpMILOperatorConfiguration: any;

  // Very important data coming from parent components
  @Input() currentTaskID: string;
  @Input() previousTaskIDs: any; // If Transformation Operator is connected to an operator
  @Input() currentFileConfig: any;
  @Input() currentOperatorCode: any;
  @Input() customerId: string;
  @Input() userId: string;

  private priviousConfigData: any;

  dataSourceCategory: string;
  public index = 0;
  private fileName = '';

  public newSelectedEntities: any = [];

  classTypes: any = [
    'PRIMARY SUBJECT',
    'PRIMARY SUBJECT ROLE',
    'RELATED SUBJECT',
    'SUBJECT ATTRIBUTE',
    'TRANSACTION',
    'EVENT',
    'N/A',
  ];

  public colocatedSubjectEntityclassTypes: any = [
    'PRIMARY SUBJECT ROLE',
    'SUBJECT ATTRIBUTE',
    'TRANSACTION',
    'EVENT',
    'N/A',
  ];
  actions: any = ['Copy', 'Translate', 'Custom'];
  dataMappingForm: FormGroup;

  selectedMapIndex = -1;
  selEntityIndex = -1;
  selMapping: any = {};

  public showNewFields = false;
  public showDelimeter = false;
  mdmConfig: any;
  targetFileFormatOptions: any = ['CSV', 'Delimited', 'Parquet', 'Arrow', 'HDF5', 'JSON'];
  private targetFileFormatInputOption = 'Delimited';
  targetDataSchemaModel: any;

  public entities = null;
  public showMapping = false;
  public mappingConfig: any;
  public selectedEntity: any;
  public showEntity = false;
  public saveNewFile: boolean;
  public updateFile: boolean;
  public hideLinkLoadPolicy = false;
  public hideDataMapping = false;
  private targetFileLocation: any;
  public fileNameExist = false;
  private saveToFile = '';
  private uploadToAnExistingFile = '';
  private updateMode = '';
  public skipDuplicateRecords = false;
  public showEntitySearch = false;
  private identifyDuplicateRecordsByFields: any;
  public entity: any;
  private subscriptions: Subscription[] = [];
  public s3FileName: any;
  private s3FileFormat: any;
  private s3FileLocation: any;
  public showTargetEntityClassType: boolean;
  public entityDataMappingUploadMode: any;
  public entityClassTypeList: any;

  constructor(
    private datasetOperatorService: UPDMilOperatorService,
    private fb: FormBuilder,
    private codeEditorPanelService: CodeEditorPanelService,
    private dataImportService: DataImportService,
    private cdr: ChangeDetectorRef,
    public modalService: BsModalService,
    private _udpTaskConfigService: UdpTaskConfigService,
    public bucketExplorerDialog: MatDialog,
    private schemaExplorerService: SchemaExplorerService
  ) {

  }

  ngOnInit() {
    this.fileName = `${this.currentTaskID}.config.${this.currentOperatorCode.toLowerCase()}.json`;

    this.getPreviousTaskData();

    if (this.currentFileConfig && this.currentFileConfig.contents) {
      this.priviousConfigData = this.currentFileConfig.contents;
      this.udpMILOperatorConfiguration = this.priviousConfigData;
    }
    this.initForm();
    this.getDataAssestCategories();
    this.subscriptions[this.subscriptions.length] = this._udpTaskConfigService.saveUdpData.subscribe(res => {
      if (this.selectedDataset) {
        this.setIndexValue(1);
      }
    });

    this.getTargetFileLocation();
    this.getSelectedFields();
  }

  /**
   *
   */
  private getTargetFileLocation() {
    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService.targetFilePath$.subscribe((data: any) => {
      if (data) {
        data.fileLocation = data.fileLocation ? data.fileLocation : '';
        const bucketData = data.bucketData;
        this.targetDataForm.patchValue({
          targetBucketName: data.bukcetName
        });
        this.targetDataForm.patchValue({
          targetFilePath: data.fileLocation
        });
        bucketData.forEach((item) => {
          if ('targetFileName' in this.targetDataForm.value) {
            if (item.Key === this.targetDataForm.get('targetFileName').value) {
              this.fileNameExist = true;
            }
          }
        });
        this.s3FileLocation = data.fileLocation ? data.fileLocation : '';
        if (data.fileContent) {
          this.s3FileName = data.fileContent.Key;
          this.s3FileFormat = data.fileContent.format;
        }
        this.cdr.detectChanges();
      }
    });
  }

  getSelectedFields() {
    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService.selectedFields$.subscribe((data: any) => {
      if (data) {
        const identifyDuplicateRecordsByFields = data;
        this.identifyDuplicateRecordsByFields = identifyDuplicateRecordsByFields.map((item) => {
          return item.attributeName;
        });
      }
    });
  }

  private getPreviousTaskData() {
    // Previous task is Dataset operator OR Data transformation operator
    if (this.previousTaskIDs[0].includes(miscellaneousConst.operators.DS.FILENAME) ||
    this.previousTaskIDs[0].includes(miscellaneousConst.operators.DT.FILENAME)) {
      this.dataSetOperaterConfig = this._udpTaskConfigService.getConfig(this.previousTaskIDs[0]).contents.datasetSchema;
    } else if (this.previousTaskIDs[0].includes(miscellaneousConst.operators.DB.FILENAME)) { // Previous task is Datablend operator
      const config = this._udpTaskConfigService.getConfig(this.previousTaskIDs[0]).contents;
      if (config.metadata && config.metadata.dataBlendBy) {
        if (config.metadata.dataBlendBy === 'ROW') {
          this.dataSetOperaterConfig = config.metadata.dataSetSchema;
        } else {
          this.dataSetOperaterConfig = this.removeDataSetSchemaFormatForDataBlend(_.cloneDeep(config.metadata.dataSetSchema));
        }
      }
    }
  }

  private removeDataSetSchemaFormatForDataBlend(dataset) {
    const keys = Object.keys(dataset);
    let finalDataSet = {};
    keys.forEach(key => {
      dataset[key].entityPhysicalName = dataset[key].entityName = (dataset[key].entityName).slice(2);
      dataset[key].attribute.forEach(item => {
        item.attributeName = item.attributeName.slice(2);
      });
      finalDataSet = finalDataSet ? { ...finalDataSet, ...{ [dataset[key].entityName]: dataset[key] } }
        : { [dataset[key].entityName]: dataset[key] };
    });

    return this.flattenDatablendConfigForBlendByColumn(finalDataSet);
  }

  private flattenDatablendConfigForBlendByColumn(dataset) {
    const keys = Object.keys(dataset);

    const attrArray = [];
    const entities = [];

    for (const key of keys) {
      for (const attr of dataset[key].attribute) {
        attrArray.push(attr);
      }
      entities.push(key);
    }

    const entityName = entities.join(' + ');

    const unique = [...new Map(attrArray.map((m) => [m.attributeName, m])).values()];

    return {
      entityName,
      entityPhysicalName: entityName,
      entityDescription: 'This is where the description lives',
      entityType: entityName,
      entityClassType: '',
      entityLinkandLoadPolicy: '',
      entityObjectUniqueIDFormat: '',
      attribute: unique
    };
  }

  private onFinalSaveForTSOperator(finalData: any) {
    this.udpMILOperatorConfiguration = finalData;
    this.codeEditorPanelService.dataTransformationOperatorConfig = {
      fileName: `config/${this.fileName}`,
      contents: this.udpMILOperatorConfiguration,
      TS: Math.floor(new Date().getTime() / 1000)
    };
    this.cdr.detectChanges();
  }

  checkSelectedMapping(data, key) {
    data.dataMappingConfig[key].forEach((item) => {
      if (key === 'targetToSourceDataMapping') {
        this.sourceData.filter((source) => {
          if (item.entityDataMapping.targetEntityName === source.entityName) {
            source.selectedMapped = item.dataMapping.length;
          }
        });
      } else {
        this.subjectData.filter((subject) => {
          if (item.entityDataMapping.targetEntityName === subject.entityName) {
            subject.selectedMapped = item.dataMapping.length;
          }
        });
      }
    });
  }

  onMaximizeDone() {
    if (this.content) {
      this.content._container.updateContentMargins();
      // this.redrawService.redraw();
    }
  }
  getMappedAttrCnt(attrs: any) {
    return attrs.filter((attr: any) => attr.sourceDataAttributes && attr.sourceDataAttributes.length > 0).length;
  }

  private initForm(): void {
    this.targetDataForm = this.fb.group({
      useTargetDataSourceSchema: ['targetDataSourceModel'],
      checkSubjectTargetData: new FormControl(false),
      sameTargetDatabase: new FormControl('')
    });
    this.dataMappingForm = this.fb.group({
      entities: new FormArray([]),
    });
    this.shippedDataForm = this.fb.group({
      identifyMasterEntity: new FormControl(false),
      selectedMDMEntity: new FormControl(''),
      selectedMDMSubjectAttribute: new FormControl(''),
      selectedMDMTargetEntityName: new FormControl(''),
      selectedMDMTargetEntityAttributeName: new FormControl(''),
      mdmSubject: new FormArray([])
    });
    if (this.priviousConfigData && this.priviousConfigData.subjectIdentificationConfig) {
      this.shippedDataForm.get('identifyMasterEntity').setValue(true);
      this.onIdentifyMasterEntity(true);
    }


  }

  public onTargetFileFormatChange(targetOption: any) {
    if (this.targetFileFormatInputOption === targetOption.value) {
      // Input required field
      const delimeter = new FormControl('');
      delimeter.setValidators(Validators.required);
      this.targetDataForm.addControl('delimeterCharacter', delimeter);
      this.showDelimeter = true;
    } else {
      this.targetDataForm.removeControl('delimeterCharacter');
      this.showDelimeter = false;
    }
  }

  public onSubjectTargetFileFormatChange(targetOption: any) {
    if (this.targetFileFormatInputOption === targetOption.value) {
      // Input required field
      const delimeter = new FormControl('');
      delimeter.setValidators(Validators.required);
      this.targetDataForm.addControl('subjectDelimeterCharacter', delimeter);
      this.showSubjectDelimeter = true;
    } else {
      this.targetDataForm.removeControl('subjectDelimeterCharacter');
      this.showSubjectDelimeter = false;
    }
  }

  getDataAssestCategories() {
    const dataAssetTypes = Object.keys(this.dataAssetTypeMap);
    // const temp = 'DSRC,' + dataAssetTypes.join(',');

    // private DSRCDMDataTypes contains DSRC and DM both which we will use later when DM will be available
    // For now, we will only pass DSRC ( Data Sources to API )
    // const assetTypes = this.DSRCDMDataTypes.join(',');
    const assetTypes = `DSRC, DF`;
    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService.getDataAssestCategories(assetTypes, this.customerId,
      this.userId).subscribe((res: any) => {
        if (res && res.dataAssetCategoryList && Object.keys(res.dataAssetCategoryList).length !== 0) {
          this.datasets = dataAssetTypes.map((type, i) => {
            return {
              id: i + 2,
              dataAssetType: this.dataAssetTypeMap[type],
              dataAssetCode: type,
              // icon: 'assets/Complete-Icon-Set/' + this.dataAssetTypeIconMap[type],
              dataAssets: res.dataAssetCategoryList[type] ?
                res.dataAssetCategoryList[type].map((cat: any) => {
                  return {
                    dataSetDataSource: [],
                    dataSetID: cat.dataAssetCategory.dataAssetCategoryID,
                    dataSetName: cat.dataAssetCategory.dataAssetCategoryName,
                    datasetSchema: {}
                  };
                }) : []
            };
          });
          // To display Data sources and File Assets only
          this.datasets = this.datasets.filter((data) => {
            return data.id !== 3 && data.id !== 4;
          });
        }
        if (res.customerDataSourcesMap) {
          const t: any = {
            id: 1,
            dataAssetType: 'Data Sources',
            dataAssetCode: DATASOURCE.INTERNAL_DATA_SOURCE_CODE,
            icon: 'assets/Complete-Icon-Set/DMG-Internal-Data-Source.png',
            dataAssets: []
          };
          const dsMap = { EXTD: 'External Data', INTD: 'Internal Data', SHRD: 'Shared Data', DFR: 'Data Frame' };
          ['EXTD', 'INTD', 'SHRD', 'DFR'].forEach((type, i) => {
            if (res.customerDataSourcesMap[type]) {
              const datasources: any = [];
              res.customerDataSourcesMap[type].reverse().forEach((cat: any) => {
                if (cat.customerDataSourceID && cat.customerDataSourceName) {
                  datasources.push({
                    dataSourceID: cat.customerDataSourceID,
                    dataSourceName: cat.customerDataSourceName,
                    dataSourceType: DATASOURCE.INTERNAL_DATA_SOURCE_CODE,
                    dataSetDesc: '',
                    dataCatType: type,
                    dataSourceCategoryID: cat.refDataSourceCategoryID,
                    productName: cat.refDataSourceProductName,
                    productLogoURL: cat.refDataSourceProductLogoURL
                  });
                }
              });
              t.dataAssets.push({
                dataSetDataSource: datasources,
                dataSetName: dsMap[type],
                dataAssetType: type,
                datasetSchema: {}
              });
            }
          });
          this.datasets.unshift(t);
        }
        this.loadDataMapping();
        this.cdr.detectChanges();
        this.datasetsBackUp = [...this.datasets];
      });
  }

  loadDataMapping() {
    let prvData: any;
    prvData = this.priviousConfigData ? this.priviousConfigData : '';
    if (prvData.targetData) {
      this.udpMILOperatorConfiguration = prvData;
      const id = prvData.targetData.targetDataSourceID;
      let fData: any;
      // tslint:disable-next-line: prefer-const
      let e: CdkDragDrop<string[]>;
      this.datasets.forEach(async data => {
        data.dataAssets.forEach(async item => {
          if (prvData.targetData.targetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
            item.dataSetDataSource.forEach(x => {
              if (id === x.dataSourceID) {
                fData = x;
                this.onDestDropped(e, 1, fData, '');
                return;
              }
            });
            this.setDSRCData();
          } else if (prvData.targetData.targetDataSourceType ===  DATASOURCE.FILE_ASSET_CODE)  {
            this.subscriptions[this.subscriptions.length] =
            await this.datasetOperatorService.getAssetLists(item.dataSetID, 'file').subscribe((res: any) => {
              if (res && res.length > 0) {
                  res.map((ds) => {
                    item.dataSetDataSource.push({
                      ...ds,
                      dataSourceID: ds.fileID,
                      dataSourceName: ds.fileName,
                      dataSourceType: DATASOURCE.FILE_ASSET_CODE,
                      dataSourceCategoryID: 8
                    });
                  });
                  item.dataSetDataSource.forEach(x => {
                    if (id === x.dataSourceID) {
                      return fData = x;
                    }
                  });
                  this.onDestDropped(e, 1, fData, '');
                  this.setDFData();
              }
            });
          }
        });
      });
      if (this.udpMILOperatorConfiguration.targetData.targetDataSourceCategory === 'CS') {
        this.onDestDropped(e, 1, fData, '');
        this.setCSData();
      }
    }
    if (prvData.subjectEntityDatasource) {
      this.targetDataForm.get('checkSubjectTargetData').patchValue(prvData.performSubjectEntityResolution);
      this.targetDataForm.get('sameTargetDatabase').patchValue(prvData.colocatedSubjectEntityDatabase);
      const id = prvData.subjectEntityDatasource.subjectDataSourceID;
      let fData: any;
      this.datasets.forEach(data => {
        data.dataAssets.forEach(item => {
          item.dataSetDataSource.forEach(x => {
            if (id === x.dataSourceID) {
              fData = x;
            }
          });
        });
      });
      let e: CdkDragDrop<string[]>;
      this.onDestDropped(e, 1, fData, 'subject');
    }
  }
  onExpandCat({ asset, dataAssetCode }) {
    const typeMap: any = { DF: 'file', DS: 'dataset', QRY: 'query' };
    // this.dataAssetCategoryName = asset.dataSetName;
    const data = {
      assetCategory: [
        {
          dataAssetCategoryID: asset.dataSetID,
          dataAssetType: dataAssetCode
        }
      ]
    };

    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService.getAssetLists(asset.dataSetID, typeMap[dataAssetCode])
    .subscribe((res: any) => {
      if (res && res.length > 0) {
        if (dataAssetCode === DATASOURCE.QUERY_CODE) {
          asset.dataSetDataSource = res.map((ds) => {
            return {
              ...ds,
              dataSourceID: ds.queryID,
              dataSourceName: ds.queryName,
              dataSourceType: dataAssetCode,
              dataSetDesc: ds.queryDescription
            };
          });
        } else if (dataAssetCode === DATASOURCE.FILE_ASSET_CODE) {
          asset.dataSetDataSource = res.map((ds) => {
            return {
              ...ds,
              dataSourceID: ds.fileID,
              dataSourceName: ds.fileName,
              dataSourceType: dataAssetCode
            };
          });
        } else if (dataAssetCode === 'DS') {
          asset.dataSetDataSource = res.map((ds) => {
            return {
              ...ds,
              dataSourceID: ds.datasetID,
              dataSourceName: ds.datasetName,
              dataSourceType: dataAssetCode,
              dataSetDesc: ds.datasetDescription
            };
          });
        }
        this.cdr.detectChanges();
      }
    });
  }

  public onFilterUpdate(list: any[]): void {
    if (Array.isArray(list)) {
      const data: any = [];
      if (list.length > 0) {
        for (const item of this.datasetsBackUp) {
          if (list.includes(item.dataAssetType)) {
            data.push(item);
          }
        }
        this.datasets = data;
      } else {
        this.datasets = [...this.datasetsBackUp];
      }
    }
  }

  public removeFormControlForTargetFile() {
    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      ConfirmationDialogComponent, this.bucketExplorerDialog,
      {
        width: '600px',
        height: '250px',
        data: {
           title: 'Delete Target Data Source',
           message: `Deleting the data source will automatically delete all configurations associated to
           this data source. Do you want to proceed with deleting the data source?`,
           buttonCancel: 'No',
           buttonOK: 'Yes'
        }
      }
    );
    ref.afterClosed()
      .subscribe((data) => {
        if (data) {
          this.selectedDataset = null;
          this.priviousConfigData = null;
          this.targetDataForm.removeControl('targetSaveUploadFile');
          this.targetDataForm.removeControl('targetAppendOverrideFile');
          this.targetDataForm.removeControl('targetIdentifyDuplicateRecordsByFields');
          this.targetDataForm.removeControl('targetFileName');
          this.targetDataForm.removeControl('targetBucketName');
          this.targetDataForm.removeControl('targetFilePath');
          this.targetDataForm.removeControl('targetFileFormat');
          this.targetDataForm.removeControl('delimeterCharacter');
          this.showNewFields = false;
          this.udpMILOperatorConfiguration = {};
          this.onFinalSaveForTSOperator('');
          this.newSelectedEntities = [];
          this.removeFormControlForSubject();
          this.targetDataForm.get('checkSubjectTargetData').patchValue('');
          this.targetDataForm.get('sameTargetDatabase').patchValue('');
          this.hideDataMapping = false;
          this.hideLinkLoadPolicy = false;
          this.updateFile = false;
        }
      });
  }

  private setDSRCData() {
    if (this.udpMILOperatorConfiguration.performSubjectEntityResolution) {
      this.targetDataForm.get('checkSubjectTargetData').patchValue(true);
    } else {
      this.targetDataForm.get('checkSubjectTargetData').patchValue(false);
    }
    if (this.udpMILOperatorConfiguration.colocatedSubjectEntityDatabase === true) {
      this.targetDataForm.get('sameTargetDatabase').patchValue(true);
    } else if (this.udpMILOperatorConfiguration.colocatedSubjectEntityDatabase === false) {
      this.targetDataForm.get('sameTargetDatabase').patchValue(false);
    }

    if (this.udpMILOperatorConfiguration.dataMappingConfig) {
      this.udpMILOperatorConfiguration.dataMappingConfig.targetToSourceDataMapping.map((item) => {
        this.datasetOperatorService.entityDataMappingUploadMode.next({
          uploadMode: item.entityDataMapping.uploadMode,
          upsertConfig: {
            upsertType: item.entityDataMapping.upsertConfig.upsertType,
            duplicateColumns: item.entityDataMapping.upsertConfig.duplicateColumns,
            updateColumns: item.entityDataMapping.upsertConfig.updateColumns
          }
        });
        if (item.entityDataMapping.uploadMode === 'UPSERT') {
            this.entityDataMappingUploadMode = 'UPSERT';
        }
      });
    }
  }

  setCSData() {
    if (this.udpMILOperatorConfiguration.targetData.saveToFile === 'NEW') {
      this.saveNewFile = true;
      this.updateFile = false;
      this.resetExistingFile();
    } else if (this.udpMILOperatorConfiguration.targetData.saveToFile === 'EXISTING') {
      this.updateFile = true;
      this.saveNewFile = false;
      this.resetSaveFileForm();
    }

    this.targetDataForm.patchValue({
      targetSaveUploadFile: this.udpMILOperatorConfiguration.targetData.saveToFile
    });
    this.targetDataForm.patchValue({
      targetFileName: this.udpMILOperatorConfiguration.targetData.targetFileName
    });
    this.targetDataForm.patchValue({
      targetBucketName: this.udpMILOperatorConfiguration.targetData.targetBucketName
    });
    this.targetDataForm.patchValue({
      targetFilePath: this.udpMILOperatorConfiguration.targetData.targetFilePath
    });
    this.targetDataForm.patchValue({
      targetFileFormat: this.udpMILOperatorConfiguration.targetData.targetFileFormat
    });
    this.targetDataForm.patchValue({
      targetAppendOverrideFile: this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.updateMode
    });
  }

  /**
   *
   */
  private setDFData() {
    this.targetDataForm.patchValue({
      targetAppendOverrideFile: this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.updateMode
    });

    if (this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.updateMode === 'APPEND') {
      this.skipDuplicateRecords = true;
    }

    if (this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.identifyDuplicateRecordsByFields.length === 0) {
      this.targetDataForm.patchValue({
        targetIdentifyDuplicateRecordsByFields: 'ALL'
      });
      this.showEntitySearch = false;
    } else if (this.udpMILOperatorConfiguration.targetData.uploadToAnExistingFile.identifyDuplicateRecordsByFields.length) {
      this.targetDataForm.patchValue({
        targetIdentifyDuplicateRecordsByFields: 'SELECT'
      });
      this.getFileSchema();
    }
  }

  deleteSubjectPopup() {
    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      ConfirmationDialogComponent, this.bucketExplorerDialog,
      {
        width: '600px',
        height: '250px',
        data: {
           title: 'Delete Subject Data Source',
           message: `Deleting the data source will automatically delete all configurations associated to
           this data source. Do you want to proceed with deleting the data source?`,
           buttonCancel: 'No',
           buttonOK: 'Yes'
        }
      }
    );
    ref.afterClosed()
      .subscribe((data) => {
        if (data) {
          this.subjectTargetData = null;
          this.removeFormControlForSubject();
        }
      });
  }

  public removeFormControlForSubject() {
    this.targetDataForm.removeControl('subjectTargetSaveUploadFile');
    this.targetDataForm.removeControl('subjectTargetAppendOverrideFile');
    this.targetDataForm.removeControl('subjectTargetIdentifyDuplicateRecordsByFields');
    this.targetDataForm.removeControl('subjectTargetFileName');
    this.targetDataForm.removeControl('subjectTargetBucketName');
    this.targetDataForm.removeControl('subjectTargetFilePath');
    this.targetDataForm.removeControl('subjectTargetFileFormat');
    this.targetDataForm.removeControl('subjectDelimeterCharacter');
    delete this.udpMILOperatorConfiguration.subjectEntityDatasource;
    if (this.udpMILOperatorConfiguration.dataMappingConfig &&
      this.udpMILOperatorConfiguration.dataMappingConfig.subjectToSourceDataMapping) {
        delete  this.udpMILOperatorConfiguration.dataMappingConfig.subjectToSourceDataMapping;
    }
    this.sourceData = [];
    this.subjectData = [];
    this.udpMILOperatorConfiguration = null;
    this.priviousConfigData = null;
    this.subjectData = [];
    this.showSubjectDelimeter = false;
    this.showSubjectFields = false;
    this.newSelectedEntities = [];
    this.cdr.detectChanges();
  }

  private processFormControlForTargetFile() {
    // radio button field
    const targetSaveUploadFile = new FormControl('');
    this.targetDataForm.addControl('targetSaveUploadFile', targetSaveUploadFile);

    // radio button field
    const targetAppendOverrideFile = new FormControl('');
    this.targetDataForm.addControl('targetAppendOverrideFile', targetAppendOverrideFile);

    // radio button field
    const targetIdentifyDuplicateRecordsByFields = new FormControl('');
    this.targetDataForm.addControl('targetIdentifyDuplicateRecordsByFields', targetIdentifyDuplicateRecordsByFields);

    // Input required field
    const targetFileName = new FormControl('');
    targetFileName.setValidators(Validators.required);
    this.targetDataForm.addControl('targetFileName', targetFileName);

    // Input required field
    const targetBucketName = new FormControl('');
    targetBucketName.setValidators(Validators.required);
    this.targetDataForm.addControl('targetBucketName', targetBucketName);

    // Input optional field
    const targetFilePath = new FormControl('');
    this.targetDataForm.addControl('targetFilePath', targetFilePath);

    // Dropdown required field
    const targetFileFormat = new FormControl('');
    targetFileName.setValidators(Validators.required);
    this.targetDataForm.addControl('targetFileFormat', targetFileFormat);

    this.showNewFields = true;
  }

  private processFormControlForSubjectFile() {
    // radio button field
    const targetSaveUploadFile = new FormControl('');
    this.targetDataForm.addControl('subjectTargetSaveUploadFile', targetSaveUploadFile);

    // radio button field
    const targetAppendOverrideFile = new FormControl('');
    this.targetDataForm.addControl('subjectTargetAppendOverrideFile', targetAppendOverrideFile);

    // radio button field
    const targetIdentifyDuplicateRecordsByFields = new FormControl('');
    this.targetDataForm.addControl('subjectTargetIdentifyDuplicateRecordsByFields', targetIdentifyDuplicateRecordsByFields);

    // Input required field
    const targetFileName = new FormControl('');
    targetFileName.setValidators(Validators.required);
    this.targetDataForm.addControl('subjectTargetFileName', targetFileName);

    // Input required field
    const targetBucketName = new FormControl('');
    targetBucketName.setValidators(Validators.required);
    this.targetDataForm.addControl('subjectTargetBucketName', targetBucketName);

    // Input optional field
    const targetFilePath = new FormControl('');
    this.targetDataForm.addControl('subjectTargetFilePath', targetFilePath);

    // Dropdown required field
    const targetFileFormat = new FormControl('');
    targetFileName.setValidators(Validators.required);
    this.targetDataForm.addControl('subjectTargetFileFormat', targetFileFormat);

    this.showSubjectFields = true;
  }

  public onDestDropped(e: CdkDragDrop<string[]>, id: number, data: any, targetType: any): void {
    if (targetType !== 'subject') {
      // this.removeFormControlForTargetFile();
      // if (['3', '8'].includes(e.item.data.dataSourceCategoryID)) return;
      if (data ? data.dataSourceType : e.item.data.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
        this.selectedDataset = data ? data : e.item.data;
        this.dataSourceCategory = this.datasetOperatorService.getDataSourceType(this.selectedDataset.dataSourceCategoryID);
        if (this.dataSourceCategory === 'CS') {
          this.selectedDataset = data ? data : e.item.data;
          this.hideDataMapping = true;
          this.hideLinkLoadPolicy = true;
          this.processFormControlForTargetFile();
          return;
        } else if (data ? data.dataSourceType : e.item.data.dataSourceType === DATASOURCE.FILE_ASSET_CODE) {
          if (e? e.item.data.dataSourceType === DATASOURCE.FILE_ASSET_CODE : data.dataSourceType === DATASOURCE.FILE_ASSET_CODE) {
            this.dataSourceCategory = DATASOURCE.FILE_ASSET_CODE;
            this.selectedDataset = data ? data : e.item.data;
            this.hideDataMapping = true;
            this.hideLinkLoadPolicy = true;
            this.processFormControlForTargetFile();
            this.updateFile = true;
            this.cdr.detectChanges();
          }
        } else {
          this.hideDataMapping = false;
          this.hideLinkLoadPolicy = false;
          this.updateFile = false;
        }
        this.targetDataForm.patchValue({ useTargetDataSourceSchema: 'targetDataSourceModel' });
      } else if (data ? data.dataSourceType : e.item.data.dataSourceType === DATASOURCE.FILE_ASSET_CODE) {
        this.dataSourceCategory = DATASOURCE.FILE_ASSET_CODE;
        this.selectedDataset = data ? data : e.item.data;
        this.hideDataMapping = true;
        this.hideLinkLoadPolicy = true;
        this.processFormControlForTargetFile();
        this.updateFile = true;
      }
    } else {
      if (data ? (data.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) : e.item.data.dataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE) {
        this.subjectTargetData = data ? data : e.item.data;
        this.subjectSourceCategory = this.datasetOperatorService.getDataSourceType(this.subjectTargetData.dataSourceCategoryID);
        if (this.subjectSourceCategory === 'CS') {
          this.selectedDataset = data ? data : e.item.data;
          this.hideDataMapping = true;
          this.hideLinkLoadPolicy = true;
          this.processFormControlForSubjectFile();
        }
        this.hideDataMapping = false;
        this.hideLinkLoadPolicy = false;
        this.updateFile = false;
      } else if (data ? data.dataSourceType : e.item.data.dataSourceType === DATASOURCE.FILE_ASSET_CODE) {
        this.dataSourceCategory = DATASOURCE.FILE_ASSET_CODE;
        this.selectedDataset = data ? data : e.item.data;
        this.hideDataMapping = true;
        this.hideLinkLoadPolicy = true;
        this.processFormControlForSubjectFile();
        this.updateFile = true;
      }
    }
  }
  onClassTypeChanges(data, entity: any, key) {
    if (data) {
      entity.entityClassType = data;
      if (data !== 'SUBJECT ATTRIBUTE') {
        if (!entity.subjectIdentification) {
          entity.subjectIdentification = '';
        } else {
          entity.subjectIdentification = entity.subjectIdentification;
        }
      }
      this.cdr.detectChanges();
    } else {
      const prvData = this.priviousConfigData ? this.priviousConfigData : '';
      if (prvData.dataMappingConfig && prvData.dataMappingConfig[key]) {
        prvData.dataMappingConfig[key].forEach(item => {
          if (item.entityDataMapping.entityClassType) {
            entity.entityClassType = item.entityDataMapping.entityClassType;
            entity.subjectIdentification = item.entityDataMapping.subjectIdentification ?
              item.entityDataMapping.subjectIdentification : '';
            this.cdr.detectChanges();
          }
        });
      }
    }
  }
  setSelMap(mapping: any, entityIndex: number, mapIndex: number) {
    this.selectedMapIndex = mapIndex;
    this.selEntityIndex = entityIndex;
    this.selMapping = { ...mapping };
  }

  private setFinalTargetDataForCS(data) {

    if (this.targetDataForm.get('targetSaveUploadFile').value) {
      data.saveToFile = this.targetDataForm.get('targetSaveUploadFile').value;
      if (data.saveToFile === 'EXISTING') {
        data.uploadToAnExistingFile = {
          updateMode: this.updateMode || this.targetDataForm.get('targetAppendOverrideFile').value,
          skipDuplicateRecords: false,
          identifyDuplicateRecordsByFields: []
        };
        data.targetFileName = this.s3FileName ? this.s3FileName : '';
        data.targetFilePath = this.s3FileLocation ? this.s3FileLocation : '';
        data.targetFileFormat = this.s3FileFormat ? this.s3FileFormat : '';
      } else {
        data.uploadToAnExistingFile = {};
      }
    }

    if (this.targetDataForm.get('targetFileName').value) {
      data.targetFileName = this.targetDataForm.get('targetFileName').value;
    }
    if (this.targetDataForm.get('targetBucketName').value) {
      data.targetBucketName = this.targetDataForm.get('targetBucketName').value;
    }
    if (this.targetDataForm.get('targetFilePath').value) {
      data.targetFilePath = this.targetDataForm.get('targetFilePath').value;
    }
    if (this.targetDataForm.get('targetFileFormat').value) {
      data.targetFileFormat = this.targetDataForm.get('targetFileFormat').value;
      if (data.targetFileFormat === 'Delimeter') {
        if (this.targetDataForm.get('delimeterCharacter').value) {
          data.delimeterCharacter = this.targetDataForm.get('delimeterCharacter').value;
        }
      }
    }
  }

  onEntityConfigSave(entitiesConfig: any) {
    // Update object again if already present in the array
    this.udpMILOperatorConfiguration = _.cloneDeep(entitiesConfig);
    this.onFinalSaveForTSOperator(entitiesConfig);
  }

  onSubjectConfigSave(entitiesConfig: any) {
    const subjectMapping = _.groupBy(entitiesConfig, 'targetEntityName'); // this is name of subject identification
    if (this.subjectEntitiesConfigs.length === 0) {
      this.subjectEntitiesConfigs.push(subjectMapping);
    } else {
      const index = _.findIndex(this.subjectEntitiesConfigs, (e) => {
        return Object.keys(e)[0] === entitiesConfig[0].targetEntityName;
      });
      if (index !== -1) {
        this.subjectEntitiesConfigs[index] = subjectMapping;
      } else {
        this.subjectEntitiesConfigs.push(subjectMapping);
      }
    }
    const formVal = this.shippedDataForm;
    const filterEntity = [];
    this.subjectEntitiesConfigs.forEach(data => {
      for (const key of Object.keys(data)) {
        for (const entity of data[key]) {
          filterEntity.push(entity);
        }
      }
    });
    const finalData = [];
    for (const sub of formVal.value.mdmSubject) {
      const entityData = [];
      filterEntity.forEach(data => {
        sub.subjectToTargetattributeMap.forEach(item => {
          if (item.targetEntityName === data.targetEntityName && item.targetAttributeName === data.targetAttributeName) {
            entityData.push({
              subjectAttributeName: item.targetAttributeName,
              subjectAttributeDataType: item.targetAttributeDataType,
              subjectEntityName: item.targetEntityName,
              targetEntityName: data.dataMappingEntity,
              targetAttributeName: data.dataMappingEntityAttributes,
              targetAttributeDatatType: data.dataMappingEntityAttributesType
            });
          }
        });
      });
      const finalSubjectEntity = this.subjectDataCheck(entityData);
      const links = this.linkAndLoadPolicies;
      finalData.push({
        mdmSubject: {
          ...sub, subjectToTargetattributeMap: finalSubjectEntity,
          subjectIdentificationPolicy: {
            subjectFoundPolicy: links.uniqueSubjectFoundActions.length > 1 ?
              links.uniqueSubjectFoundActions.join('_') : links.uniqueSubjectFoundActions[0],
            subjectNotFoundPolicy: links.noSubjectFoundActions.length === 0 ? 'NOTCRT' : this.filterLinks(links.noSubjectFoundActions),
            subjectPotDupPolicy: links.duplicateSubjectFound.length === 0 ? 'NOTCRT' : this.filterLinks(links.duplicateSubjectFound)
          }
        }
      });
    }
    this.sortBySubjectAttribute(finalData);
  }

  sortBySubjectAttribute(data) {
    let result: any;
    const order = { FirstName: 1, MiddleName: 2, LastName: 3, DateOfBirth: 4, SSN: 5, Gender: 6 };
    data.forEach(item => {
      result = _.orderBy(item.mdmSubject.subjectToTargetattributeMap, o => order[o.subjectAttributeName]);
      item.mdmSubject.subjectToTargetattributeMap = result;
    });
    this.onFinalSaveForTSOperator({ ...this.udpMILOperatorConfiguration, subjectIdentificationConfig: data });
  }

  subjectDataCheck(entityData) {
    const filterEntityData = [];
    if (this.priviousConfigData && this.priviousConfigData.subjectIdentificationConfig) {
      this.priviousConfigData.subjectIdentificationConfig.forEach(subjectConfig => {
        subjectConfig.mdmSubject.subjectToTargetattributeMap.forEach(attributes => {
          filterEntityData.push(attributes);
        });
      });
    }
    return this.mergeUniquely(filterEntityData, entityData);
  }

  mergeUniquely(arr1 = [], arr2 = []) {
    const newArr = arr1.concat(arr2);
    const map = {};
    const res = [];
    newArr.reverse().forEach(el => {
      if (!map[el.subjectAttributeName] && !map[el.subjectEntityName]) {
        res.push(el);
        map[el.subjectAttributeName] = 1;
      }
    });
    return res;
  }

  filterLinks(val) {
    if (val.length > 1) {
      return val.join('_');
    } else if (val.includes('LOG')) {
      return 'NOTCRT_' + val[0];
    } else {
      return val[0];
    }
  }

  onEntitySelection(entity: any) {
    const entityData = entity.item;
    if (this.newSelectedEntities.length === 0) {
      this.showEntity = false;
    }
    const entityId = [];
    this.newSelectedEntities.filter(i => entityId.push(i.entityID));
    this.selectedEntity = entityData;
    const key = entity.configType === 'subject' ? 'subjectToSourceDataMapping' : 'targetToSourceDataMapping';
    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig  && this.priviousConfigData.dataMappingConfig[key]) {
      const index = _.findIndex(this.priviousConfigData.dataMappingConfig[key], (e: any) => {
        return e.entityDataMapping.targetEntityName === entityData.entityName;
      });
      if (index !== -1) {
        this.selectedEntity.entityClassType = this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.entityClassType;
        this.selectedEntity.subjectIdentification =
          this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.subjectIdentification;
        this.selectedEntity.uploadMode = this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.uploadMode;
        this.selectedEntity.upsertConfig = {
          upsertType : this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.upsertType,
          duplicateColumns: this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.duplicateColumns,
          updateColumns: this.priviousConfigData.dataMappingConfig[key][index].entityDataMapping.upsertConfig.updateColumns
        };
      } else {
        this.selectedEntity.uploadMode = 'APPEND';
        this.selectedEntity.upsertConfig = {
          upsertType: '',
          duplicateColumns: [],
          updateColumns: []
        };
        this.datasetOperatorService.entityDataMappingUploadMode.next({});
      }
    } else {
      this.selectedEntity.entityClassType = '';
      this.selectedEntity.subjectIdentification = '';
      this.selectedEntity.uploadMode = 'APPEND';
      this.selectedEntity.upsertConfig = {
        upsertType: '',
        duplicateColumns: [],
        updateColumns: []
      };
      this.datasetOperatorService.entityDataMappingUploadMode.next({});
    }
    if (this.newSelectedEntities.length > 0) {
      if (!entityId.includes(entityData.entityID)) {
        this.newSelectedEntities.push(entityData);
      }
    } else {
      this.newSelectedEntities.push(entityData);

    }
    if (entity.configType === 'subject') {
      this.subjectData.push(entityData);
      this.subjectData = _.uniqBy(this.subjectData, 'entityID');
    } else {
      this.sourceData.push(entityData);
      this.sourceData = _.uniqBy(this.sourceData, 'entityID');
    }
    setTimeout(() => {
      this.showEntity = true;
      this.cdr.detectChanges();
    }, 500);

  }

  onEntityDeletion(entity: any) {
    const index = _.findIndex(this.newSelectedEntities, (e: any) => {
      return e.entityID === entity.id;
    });
    if (entity.type === 'subjectToSourceDataMapping') {
      const subIndex = _.findIndex(this.subjectData, (e: any) => {
        return e.entityID === entity.id;
      });
      subIndex !== -1 ? this.subjectData.splice(subIndex, 1) : '';
    } else {
      const sourceIndex = _.findIndex(this.sourceData, (e: any) => {
        return e.entityID === entity.id;
      });
      sourceIndex !== -1 ? this.sourceData.splice(sourceIndex, 1) : '';
    }
    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig[entity.type]) {
      const mapIndex = _.findIndex(this.priviousConfigData.dataMappingConfig[entity.type], (e: any) => {
        return e.entityDataMapping.targetEntityName === this.newSelectedEntities[index].entityName;
      });
      if (mapIndex !== -1) {
        this.priviousConfigData.dataMappingConfig[entity.type].splice(mapIndex, 1);
        this.onFinalSaveForTSOperator(this.priviousConfigData);
      }
      this.newSelectedEntities.splice(index, 1);
    }
    this.cdr.detectChanges();
  }

  deleteEntity(index, entityName, key) {
    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      ConfirmationDialogComponent, this.bucketExplorerDialog,
      {
        width: '600px',
        height: '250px',
        data: {
           title: 'Delete Entity',
           message: `Deleting the target entity will automatically delete all mappings/configurations associated to
           this entity. Do you want to proceed with deleting the entity?`,
           buttonCancel: 'No',
           buttonOK: 'Yes'
        }
      }
    );
    ref.afterClosed()
      .subscribe((data) => {
        if (data) {
          const entityIndex =  _.findIndex(this.newSelectedEntities, (e: any) => {
            return e.entityName === entityName;
          });
          if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig[key]) {
            const mapIndex = _.findIndex(this.priviousConfigData.dataMappingConfig[key], (e: any) => {
              return e.entityDataMapping.targetEntityName === entityName;
            });
            if (mapIndex !== -1) {
              this.priviousConfigData.dataMappingConfig[key].splice(mapIndex, 1);
              this.onFinalSaveForTSOperator(this.priviousConfigData);
            }
            this.removeEntity(this.newSelectedEntities[entityIndex]);
          }
          if ( key === 'targetToSourceDataMapping') {
            this.sourceData.splice(index, 1);
          } else {
            this.subjectData.splice(index, 1);
          }
          const id = this.newSelectedEntities[entityIndex].entityID;
          this.dataImportService.entityDelete.next(id);
        }
      });
  }

  removeEntity(entity) {
    if (this.priviousConfigData && this.priviousConfigData.subjectIdentificationConfig) {
      this.priviousConfigData.subjectIdentificationConfig.forEach(data => {
        data.mdmSubject.subjectToTargetattributeMap.forEach(item => {
          if (item.targetEntityName === entity.entityName) {
            item.targetEntityName = '';
            item.targetAttributeName = '';
            item.targetAttributeDatatType = '';
          }
        });
      });
      this.udpMILOperatorConfiguration = this.priviousConfigData;
      this.onFinalSaveForTSOperator(this.priviousConfigData);
    }
  }

  private getEntityGroups(data) {
    if (this.selectedDataset.dataSourceID) {
      this.subscriptions[this.subscriptions.length] = this.datasetOperatorService.getEntityGroupList(this.selectedDataset.dataSourceID,
        this.selectedDataset.dataCatType).subscribe((entityGroupList: any) => {
          if (entityGroupList && entityGroupList.length > 0) {
            const mappingConfig = {
              baseApiUrl: environment.baseApiUrl,
              mode: 'view',
              context: 'data-mapping',
              currentEntityGroupId: this.datasetOperatorService.getCurrentEntityGroupId(),
              type: 'dataSource'
            };
            this.mappingConfig = mappingConfig;
            const dataSource = {
              dataSourceName: this.selectedDataset.dataSourceName,
              productLogoURL: this.selectedDataset.productLogoURL,
              productName: this.selectedDataset.productName,
              entityGroupList,
              mappingConfig};
            this.entities = {dataSource};
            if (!this.subjectTargetData) {
              this.showMapping = true;
            } else {
              this.getSubject();
            }
          }
          // this.targetDataSchemaModel = res && res.length ? res[0] : null;
          // data.targetDataSchemaModel = this.targetDataSchemaModel;
          this.cdr.detectChanges();
        });
    }
  }

  getSubject() {
    this.datasetOperatorService.getEntityGroupList(this.subjectTargetData.dataSourceID,
      this.subscriptions[this.subscriptions.length] = this.subjectTargetData.dataCatType).subscribe((entityGroupList: any) => {
        if (entityGroupList && entityGroupList.length > 0) {
          const subMappingConfig = {
            baseApiUrl: environment.baseApiUrl,
            mode: 'view',
            context: 'data-mapping',
            currentEntityGroupId: this.datasetOperatorService.getCurrentEntityGroupId(),
            type: 'subject'
          };
          const dataSource = {
            dataSourceName: this.subjectTargetData.dataSourceName,
            productLogoURL: this.subjectTargetData.productLogoURL,
            productName: this.subjectTargetData.productName,
            entityGroupList,
            subMappingConfig
          };
          this.entities = {...this.entities, subjectData: dataSource};
        }
        this.showMapping = true;
        this.cdr.detectChanges();
      });
  }

  loadUdp(value) {
    this.udpMILOperatorConfiguration = value;
  }

  private setFinalSubjectDataForCS(data) {
    if (this.targetDataForm.get('subjectTargetFileName').value) {
      data.subjectFileName = this.targetDataForm.get('subjectTargetFileName').value;
    }
    if (this.targetDataForm.get('subjectTargetBucketName').value) {
      data.subjectBucketName = this.targetDataForm.get('subjectTargetBucketName').value;
    }
    if (this.targetDataForm.get('subjectTargetFilePath').value) {
      data.subjectFilePath = this.targetDataForm.get('subjectTargetFilePath').value;
    }
    if (this.targetDataForm.get('subjectTargetFileFormat').value) {
      data.subjectFileFormat = this.targetDataForm.get('subjectTargetFileFormat').value;
    }
    if (this.targetDataForm.get('subjectDelimeterCharacter').value) {
      data.subjectDelimeterCharacter = this.targetDataForm.get('subjectDelimeterCharacter').value;
    }
  }
  processSubjectFinalData() {
    const data = {
      subjectDataSourceID: this.subjectTargetData.dataSourceID,
      subjectDataSourceCategory: this.subjectSourceCategory,
      subjectDataSourceType: this.subjectTargetData.dataSourceType,
      useTargetDataSourceSchema : this.targetDataForm.value.useTargetDataSourceSchema === 'targetDataSourceModel',
      dataModelAssetID: ''
    };
    if (this.subjectSourceCategory === 'CS') {
      this.setFinalSubjectDataForCS(data);
    } else {
      this.setSubjectFormControl(data);
    }
    return data;
  }

  setIndexValue(val) {
    this.index = val;
    if (this.index === 1) { // Execute on 'Data Mapping' tab selection
      this.showShippedStep = false;
      this.showDeliveredStep = false;
      let subjectData = null;
      const performSubjectEntityResolution = this.targetDataForm.get('checkSubjectTargetData').value;
      const colocatedSubjectEntityDatabase = this.targetDataForm.get('sameTargetDatabase').value;
      this.showTargetEntityClassType = performSubjectEntityResolution ? true : false;
      this.entityClassTypeList = colocatedSubjectEntityDatabase ? this.classTypes : this.colocatedSubjectEntityclassTypes;
      if (this.subjectTargetData) {
        subjectData = this.processSubjectFinalData();
      }
      const data: any = {
        targetDataSourceID: this.selectedDataset.dataSourceID,
        targetDataSourceCategory: this.dataSourceCategory,
        targetDataSourceType: this.selectedDataset.dataSourceType,
        useTargetDataSourceSchema: this.targetDataForm.value.useTargetDataSourceSchema === 'targetDataSourceModel'
      };
      if (data.useTargetDataSourceSchema) {
        this.getEntityGroups(data);
      } else {
        data.dataModelAssetID = '';
        data.targetDataSchemaModel = '';
      }

      if (this.dataSourceCategory === 'CS') {
        this.setFinalTargetDataForCS(data);
      } else {
        this.setFormControl(data);
      }
      const prvData = this.priviousConfigData ? this.priviousConfigData : '';
      this.processFormControlForTargetFile();
      this.processFormControlForSubjectFile();
      // if (prvData && prvData.targetData.targetDataSourceID === data.targetDataSourceID) {
      //   this.onFinalSaveForTSOperator({ targetData: data });
      // } else {
      //   this.onFinalSaveForTSOperator({ targetData: data });
      // }
      if (subjectData) {
        this.onFinalSaveForTSOperator({ ...this.udpMILOperatorConfiguration, performSubjectEntityResolution, colocatedSubjectEntityDatabase,
          targetData: data, subjectEntityDatasource: subjectData });
      } else {
        this.onFinalSaveForTSOperator({ ...this.udpMILOperatorConfiguration, performSubjectEntityResolution, colocatedSubjectEntityDatabase,
          targetData: data });
      }
    } else if (this.index === 2) { // Execute on 'Shipped' tab selection
      this.showMapping = false;
      this.showShippedStep = true;
      this.showDeliveredStep = false;
    } else if (this.index === 3) { // Execute on 'Delivered' tab selection
      this.showMapping = false;
      this.showShippedStep = false;
      this.showDeliveredStep = true;
    } else {
      this.showMapping = false;
      this.showShippedStep = false;
      this.showDeliveredStep = false;
    }
  }

  onMdmEntityOptionsChange(entityOption: string) {
    this.linkAndLoadPolicies = undefined;
    this.showSubjectMappingFlow = false;
    this.subjectMappingEntities = [];
    this.subjectMappingConfig = undefined;

    this.shippedDataForm.get('selectedMDMEntity').patchValue(entityOption);
    const index = _.findIndex(this.mdmSubject.value, (e: any) => {
      return e.mdmSubjectEntityName === entityOption;
    });
    this.mdmConfigData = this.mdmSubject.value[index];

    const mdmIndex = _.findIndex(this.mdmConfig.identifiableEntities, (e: any) => {
      return e.entityName === entityOption;
    });

    if (mdmIndex !== -1) {
      // group by based on targetEntityName
      this.mdmAttributesBasedOnEntityName = _.groupBy(
        this.mdmConfig.identifiableEntities[mdmIndex].subjectIdentifyingAttributes.targetEntityAttributes,
        'targetEntityName');

      // Right now coming from Const file, need to replace with API call for MDM config
      this.linkAndLoadPolicies = this.mdmConfig.identifiableEntities[mdmIndex].linkAndLoadPolicies;

      _.each(this.mdmAttributesBasedOnEntityName, (val, key) => {
        const entity = {
          entityName: key,
          attributes: val
        };
        this.subjectMappingEntities.push(entity);
      });


      if (this.subjectMappingEntities.length > 0) {
        this.subjectMappingConfig = {
          baseApiUrl: environment.baseApiUrl,
          mode: 'view',
          context: 'subject-mapping',
          currentEntityGroupId: this.datasetOperatorService.getCurrentEntityGroupId()
        };
        this.showSubjectMappingFlow = true;
      }
      this.cdr.detectChanges();
    }
  }

  async onIdentifyMasterEntity(isTrue: boolean) {
    if (!this.mdmConfig) {
      this.mdmConfig = await this.datasetOperatorService.getMdmData().then();
    }
    this.showMDMConfig = isTrue;
    if (this.showMDMConfig) {
      for (const entity of this.mdmConfig.identifiableEntities) {
        this.createMDMFormGroupForShippedStep(entity);
      }
      if (this.mdmSubject.value.length > 0) {
        this.mdmEntitiesAvailable = true;
        for (const mdmEntity of this.mdmSubject.value) {
          this.mdmEntityOptions.push(mdmEntity.mdmSubjectEntityName);
        }
        if (this.priviousConfigData && this.priviousConfigData.subjectIdentificationConfig) {
          this.priviousConfigData.subjectIdentificationConfig.forEach(item => {

            if (this.mdmEntityOptions.includes(item.mdmSubject.mdmSubjectEntityName)) {
              this.onMdmEntityOptionsChange(item.mdmSubject.mdmSubjectEntityName);
            }
          });
        }
      } else {
        this.mdmEntitiesAvailable = false;
      }
    } else {
      this.shippedDataForm.setControl('mdmSubject', this.fb.array([]));
      this.mdmEntityOptions = [];
      this.shippedDataForm.get('selectedMDMEntity').patchValue('');
    }
    this.cdr.detectChanges();

  }

  private createMDMFormGroupForShippedStep(entity) {
    const mdmSubject = this.mdmSubject;
    mdmSubject.push(this.createInitialMDMGroup(entity));
    this.createsubjectIdentifyingAttributes(entity.entityName,
      entity.subjectIdentifyingAttributes.targetEntityAttributes);
  }

  private createInitialMDMGroup(entity): FormGroup {
    return new FormGroup({
      mdmSubjectEntityName: new FormControl(entity.entityName),
      mdmSubjectEntityType: new FormControl(entity.entityType),
      mdmSubjectEntityID: new FormControl(''), // entityID is missing from the mdm-config.const.ts for now
      masterIndexName: new FormControl('Master ' + entity.entityName + ' Index'), // hard coded for now
      masterIndexColumnName: new FormControl('PatientUniqueID'), // hard coded for now
      masterIndexAbbreviations: new FormControl('PUI'), // hard coded for now
      masterIndexDataType: new FormControl('Number'), // // hard coded for now
      subjectToTargetattributeMap: new FormArray([])
    });
  }

  private get mdmSubject(): FormArray {
    return this.shippedDataForm.get('mdmSubject') as FormArray;
  }

  private createsubjectIdentifyingAttributes(entityName, targetEntityAttributes: any[]) {

    const index = _.findIndex(this.mdmSubject.value, (e: any) => {
      return e.mdmSubjectEntityName === entityName;
    });
    const subjectToTargetattributeMap = this.mdmSubject.controls[index].get('subjectToTargetattributeMap') as FormArray;
    for (const attribute of targetEntityAttributes) {
      subjectToTargetattributeMap.push(this.createsubjectIdentifyingFormGroup(attribute));
    }
  }

  private createsubjectIdentifyingFormGroup(attribute) {
    return new FormGroup({
      targetEntityName: new FormControl(attribute.targetEntityName),
      targetAttributeName: new FormControl(attribute.targetAttributeName),
      targetAttributeDataType: new FormControl(attribute.targetAttributeDataType)
    });
  }

  private setFormControl(data) {
    data.saveToFile = 'EXISTING';
    // radio button field
    const targetSaveUploadFile = new FormControl('');
    this.targetDataForm.addControl('targetSaveUploadFile', targetSaveUploadFile);
    data.targetSaveUploadFile = this.targetDataForm.get('targetSaveUploadFile').value;

    // radio button field
    const targetAppendOverrideFile = new FormControl('');
    this.targetDataForm.addControl('targetAppendOverrideFile', targetAppendOverrideFile);

    // radio button field
    const targetIdentifyDuplicateRecordsByFields = new FormControl('');
    this.targetDataForm.addControl('targetIdentifyDuplicateRecordsByFields', targetIdentifyDuplicateRecordsByFields);

    data.uploadToAnExistingFile = {
      updateMode: this.updateMode,
      skipDuplicateRecords: this.skipDuplicateRecords,
      identifyDuplicateRecordsByFields: this.identifyDuplicateRecordsByFields
    };

    // Input required field
    const targetFileName = new FormControl('');
    this.targetDataForm.addControl('targetFileName', targetFileName);
    data.targetFileName = this.targetDataForm.get('targetFileName').value;

    // Input required field
    const targetBucketName = new FormControl('');
    this.targetDataForm.addControl('targetBucketName', targetBucketName);
    data.targetBucketName = this.targetDataForm.get('targetBucketName').value;

    // Input optional field
    const targetFilePath = new FormControl('');
    this.targetDataForm.addControl('targetFilePath', targetFilePath);
    data.targetFilePath = this.targetDataForm.get('targetFilePath').value;

    // Dropdown required field
    const targetFileFormat = new FormControl('');
    this.targetDataForm.addControl('targetFileFormat', targetFileFormat);
    data.targetFileFormat = this.targetDataForm.get('targetFileFormat').value;

    const delimeter = new FormControl('');
    this.targetDataForm.addControl('delimeterCharacter', delimeter);
    data.delimeterCharacter = this.targetDataForm.get('delimeterCharacter').value;

  }

  private setSubjectFormControl(data) {

    // radio button field
    const targetSaveUploadFile = new FormControl('');
    this.targetDataForm.addControl('subjectTargetSaveUploadFile', targetSaveUploadFile);
    data.subjectSaveUploadFile = this.targetDataForm.get('subjectTargetSaveUploadFile').value;

    // radio button field
    const targetAppendOverrideFile = new FormControl('');
    this.targetDataForm.addControl('subjectTargetAppendOverrideFile', targetAppendOverrideFile);
    data.subjectAppendOverrideFile = this.targetDataForm.get('subjectTargetAppendOverrideFile').value;

    // radio button field
    const targetIdentifyDuplicateRecordsByFields = new FormControl('');
    this.targetDataForm.addControl('subjectTargetIdentifyDuplicateRecordsByFields', targetIdentifyDuplicateRecordsByFields);
    data.subjectIdentifyDuplicateRecordsByFields = this.targetDataForm.get('subjectTargetIdentifyDuplicateRecordsByFields').value;

    // Input required field
    const targetFileName = new FormControl('');
    this.targetDataForm.addControl('subjectTargetFileName', targetFileName);
    data.subjectFileName = this.targetDataForm.get('subjectTargetFileName').value;

    // Input required field
    const targetBucketName = new FormControl('');
    this.targetDataForm.addControl('subjectTargetBucketName', targetBucketName);
    data.subjectFileName = this.targetDataForm.get('subjectTargetBucketName').value;

    // Input optional field
    const targetFilePath = new FormControl('');
    this.targetDataForm.addControl('subjectTargetFilePath', targetFilePath);
    data.subjectFilePath = this.targetDataForm.get('subjectTargetFilePath').value;

    // Dropdown required field
    const targetFileFormat = new FormControl('');
    this.targetDataForm.addControl('subjectTargetFileFormat', targetFileFormat);
    data.subjectFileFormat = this.targetDataForm.get('subjectTargetFileFormat').value;

    const delimeter = new FormControl('');
    this.targetDataForm.addControl('subjectDelimeterCharacter', delimeter);
    data.subjectDelimeterCharacter = this.targetDataForm.get('subjectDelimeterCharacter').value;

  }

  saveUploadFile(e) {
    if (e.value === 'NEW') {
      this.saveNewFile = true;
      this.updateFile = false;
      this.saveToFile = e.value;
      this.resetExistingFile();
      this.resetSaveFileForm();
    } else if (e.value === 'EXISTING') {
      this.updateFile = true;
      this.saveNewFile = false;
      this.saveToFile = e.value;
      this.resetSaveFileForm();
    }
  }

  resetExistingFile() {
    this.targetDataForm.get('targetAppendOverrideFile').reset();
  }

  resetSaveFileForm() {
    this.targetDataForm.get('targetFileName').reset();
    this.targetDataForm.get('targetBucketName').reset();
    this.targetDataForm.get('targetFilePath').reset();
    this.targetDataForm.get('targetFileFormat').reset();
    if (this.targetDataForm.get('targetFileFormat').value) {
      this.targetDataForm.get('delimeterCharacter').reset();
    }
  }

  /**
   *
   */
  public openBucketExplorer() {
    const dialogRef = this.bucketExplorerDialog.open(BucketExplorerComponent,  {
      data: {
        saveNewFile: this.saveNewFile
      }
    });
  }

  /**
   *
   * @param e
   */
  public appendOverrideFile(e) {
    this.uploadToAnExistingFile = 'EXISTING';
    if (e.value === 'APPEND') {
      this.updateMode = 'APPEND';
      if (this.dataSourceCategory === DATASOURCE.FILE_ASSET_CODE) {
        this.skipDuplicateRecords = true;
      }
    } else if (e.value === 'OVERRIDE') {
      this.updateMode = 'OVERRIDE';
      this.identifyDuplicateRecordsByFields = '';
      this.skipDuplicateRecords = false;
    }
  }

  /**
   *
   * @param e
   */
  public duplicateRecords(e) {
    this.updateMode = 'APPEND';
    if (e.value === 'ALL') {
      this.identifyDuplicateRecordsByFields = [];
      this.showEntitySearch = false;
    } else if (e.value === 'SELECT') {
      this.getFileSchema();
    }
  }

  /**
   *
   */
  private getFileSchema() {
    this.subscriptions[this.subscriptions.length] = this.datasetOperatorService.getDataAsset(this.selectedDataset.dataSourceID, 'file')
    .subscribe((data: any) => {
      if (data) {
        const rep = data.FileAsset.fileSchema.replaceAll('None', '""');
        const fileSchema = (JSON.parse(rep.replace(/'/g, '"')));
        fileSchema.attributes = fileSchema.attribute;
        delete fileSchema.attribute;
        this.entity = fileSchema;
        const mappingConfig = {
          baseApiUrl: environment.baseApiUrl,
          mode: 'view',
          context: 'data-mapping',
          fileAsset: true,
          currentEntityGroupId: this.selectedDataset.dataSourceID,
          type: DATASOURCE.FILE_ASSET_CODE
        };
        this.mappingConfig = mappingConfig;
        this.showEntitySearch = true;
        this.cdr.detectChanges();
      }
    });
  }

  public uploadMode(data, entity: any, key) {
    if (data) {
      if (!entity.uploadMode) {
        entity.uploadMode = 'APPEND';
      } else {
        entity.uploadMode = data.value;
      }
      if (data.value === 'UPSERT') {
        entity.upsertConfig = {
          upsertType: 'SCD1',
          duplicateColumns: [],
          updateColumns: []
        };
        this.datasetOperatorService.entityDataMappingUploadMode.next({
          entity,
          uploadMode: data.value,
          upsertConfig: {
            upsertType: 'SCD1',
            duplicateColumns: [],
            updateColumns: []
          }
        });
      } else {
        this.datasetOperatorService.entityDataMappingUploadMode.next({
          entity,
          uploadMode: data.value,
          upsertConfig: {
            upsertType: '',
            duplicateColumns: [],
            updateColumns: []
          }
        });
      }
      this.cdr.detectChanges();
    } else {
      const prvData = this.priviousConfigData ? this.priviousConfigData : '';
      if (prvData.dataMappingConfig && prvData.dataMappingConfig[key]) {
        prvData.dataMappingConfig[key].forEach(item => {
          if (item.entityDataMapping.uploadMode) {
            entity.uploadMode = item.entityDataMapping.uploadMode;
            this.cdr.detectChanges();
          }
        });
      }
    }
  }

  public upsertConfig(data, entity: any, key) {
    if (data) {
      if (!entity.upsertConfig.upsertType) {
        entity.upsertConfig.upsertType = 'SCD1';
      } else {
        entity.upsertConfig.upsertType = data.value;
      }
      this.datasetOperatorService.entityDataMappingUploadMode.next({
        entity,
        uploadMode: 'UPSERT', upsertConfig: {
        upsertType: data.value
      }});
      this.cdr.detectChanges();
    } else {
      const prvData = this.priviousConfigData ? this.priviousConfigData : '';
      if (prvData.dataMappingConfig && prvData.dataMappingConfig[key]) {
        prvData.dataMappingConfig[key].forEach(item => {
          if (item.entityDataMapping.upsertConfig.upsertType) {
            entity.upsertConfig.upsertType = item.entityDataMapping.upsertConfig.upsertType;
            this.cdr.detectChanges();
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }

}

