import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash/fp';

const featherIcons = [
    'refresh-cw',
    'corner-up-left',
    'corner-up-right',
    'trash-2',
    'share-2',
    'file',
    'folder',
    'cpu',
    'wifi',
    'database',
    'shield',
    'search',
    'info',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'chevron-down',
    'home',
    'calendar',
    'plus',
    'minus',
    'maximize',
    'minimize',
    'x',
    'alert-circle',
    'alert-triangle',
    'alert-octagon',
    'help-circle'
];

const customIcons = ['python-file', 'json-file', 'udp-icon', 'data-mapping'];

const iconsPath = '../assets/icons';

export const registerIcons = (
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
) => {
    const registerIconsInNamespace = (namespace: string, url: string) =>
        _.forEach((icon: string) => {
            const path = domSanitizer.bypassSecurityTrustResourceUrl(
                `${url}/${icon}.svg`
            );

            matIconRegistry.addSvgIconInNamespace(namespace, icon, path);
        });

    registerIconsInNamespace('feather', iconsPath)(featherIcons);

    registerIconsInNamespace('custom', iconsPath)(customIcons);
};
