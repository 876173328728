import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule, FormFieldModule, IconModule } from '@xfusiontech/shared';
import { ShareByEmailDialogComponent } from './share-by-email-dialog.component';
import { ShareByEmailDialogService } from './share-by-email-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        ButtonModule,
        FormFieldModule,
        IconModule,
    ],
    declarations: [ShareByEmailDialogComponent],
    entryComponents: [ShareByEmailDialogComponent],
    exports: [ShareByEmailDialogComponent],
    providers: [ShareByEmailDialogService],
})
export class ShareByEmailDialogModule {}
