import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs/operators';

import { PaletteGroup, PaletteItem } from '../../types/palette';
import { TaskCatalogService } from '../../services/task-catalog.service';
import { TaskCatalogItem } from '../../types/taskCatalog';
import { camelCaseToLabel } from '../../utils/camelCaseToLabel';
import { snakeCaseToLabel } from '../../utils/snakeCaseToLabel';
import { filterTaskCatalogItems } from '../../utils/itemsFiltering';

@Injectable()
export class PaletteService {
    private search: BehaviorSubject<string> = new BehaviorSubject('');
    blendDataSet: any  = new BehaviorSubject<any>(null);
    public blendDataSet$ = this.blendDataSet.asObservable();

    constructor(
        private taskCatalogService: TaskCatalogService
    ) {}

    setSearchValue(search: string) {
        this.search.next(search);
    }

    getPaletteGroups(): Observable<PaletteGroup[]> {
        return this.taskCatalogService.getTaskCatalog()
            .pipe(
                // tslint:disable-next-line: deprecation
                combineLatest(this.search),
                map(([items, search]) =>
                    _.flowRight(
                        this.groupPaletteItems,
                        this.mapToPaletteItems,
                        filterTaskCatalogItems(search),
                    )(
                        items
                    )
                )
            );
    }

    private mapToPaletteItems = (
        taskCatalogItems: TaskCatalogItem[]
    ): PaletteItem[] => _.map((item: TaskCatalogItem) => {
        const {
            type,
            properties: { category, subcategory, description, module },
            imagePath
        } = item;
        const label = camelCaseToLabel(type);

        return {
            type,
            label,
            category,
            subcategory,
            description,
            imagePath,
            module
        };
    })(taskCatalogItems)

    private subgroupGroups = (
        group: PaletteItem[]
    ) => _.flowRight(
        _.map(([subGroup, subItems]: [string, PaletteItem[]]) => ({
            name: subGroup === 'null'
                ? null
                : snakeCaseToLabel(subGroup),
            items: subItems
        })),
        _.toPairs,
        _.groupBy('subcategory')
    )(group)

    private moveNullSubcategoriesToCategories = (groups: PaletteGroup[]) => {
        groups.forEach((group: PaletteGroup) => {
            const nullSubcategory = group.subgroups
                .find((x) => x.name === null);
            if (nullSubcategory) {
                group.items = nullSubcategory.items;
                group.subgroups = group.subgroups
                    .filter((x) => x.name !== null);
            }
        });

        return groups;
    }

    private groupPaletteItems = (items: PaletteItem[]): PaletteGroup[] => {
        const groups = _.groupBy('category', items);
        return _.flowRight(
            this.moveNullSubcategoriesToCategories,
            _.map((group: string) => ({
                name: snakeCaseToLabel(group),
                subgroups: this.subgroupGroups(groups[group]),
                items: []
            })),
            _.keys
        )(groups);
    }
}
