import { DataAssetCategory } from '@xfusiontech/data-visualizer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'igv-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public params: Partial<DataAssetCategory>,
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initializeFormGroup();
  }

  private initializeFormGroup() {
    this.form = this.fb.group({
      dataAssetCategoryName: [null, Validators.required],
      dataAssetCategoryDescription: [null],
      dataAssetType: [null],
      dataAssetCategoryID: [null]
    });

    this.form.patchValue(this.params);
  }

  onSubmitClick() {
    this.dialogRef.close(this.form.getRawValue());
  }
}
