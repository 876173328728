import gql from 'graphql-tag';

export const GET_INTERNAL_DATA_HOMEPAGE = gql`
query getInternalDatabasesAll{
    internalDatabasesAll
    {
     InternalDatabaseID
     InternalDBDisplayName
      DatabaseName
      DatabasePhysicalName
      DatabaseProductName
      DataSensitivity
      DatabaseProductVersion
      DatabaseDataFormatType
      FunctionalDomains
      TxApplication{
        CRUDFunctions
        Tags
        TxApplicationDesc
        TxApplicationName
        TxApplicationType
      }
      EntityGroup{
      EntityGroupName
      EntityGroupType
      EntityGroupID
      Entity{
        EntityID
        EntityDataSensitivity
        EntityDesc
        EntityName
        EntityPhysicalName
        EntityClassType
        EntityAttributes {
          EntityAttributeID
          EntityAttributeName
          Sensitivity
          PhysicalName
          Attribute{
            AttributeID
            AttributeName
            AttributeDesc
            AttributePhysicalName
            DataType
            Nullable
            RequiredOrOptional
          }
        }
      }
    }

   }
  }`;


export const GET_ALL_INTERNAL_DATA_AND_ORG = gql`
  query Organization {
    organization {
      OrganizationID
      OrganizationCode
      OrganizationDesc
      OrganizationName
      InternalDataOrganization {
        InternalDataOrgID
        InternalDatabases {
          InternalDatabaseID
          DatabaseName
          DatabasePhysicalName
          DataSensitivity
          FunctionalDomains
          Tags {
            id
          }
        }
      }
    }
  }
`;

export const GET_INTERNAL_DATA_SOURCE = gql`
  query internalDatabases($uid: ID!){
    internalDatabases(InternalDatabasesID:$uid)
    {
      InternalDatabaseID
      DatabaseName
      DatabasePhysicalName
      DatabaseProductName
      DataSensitivity
      DatabaseProductVersion
      DatabaseDataFormatType
      FunctionalDomains
      id
      Tags {
        id
        TagName
      }

      TxApplicationID
      TxApplication{
        CRUDFunctions
        Tags
        TxApplicationDesc
        TxApplicationName
        TxApplicationType
      }
      EntityGroup {
        EntityGroupID
      }
    }
  }`;

export const UPDATE_INTERNAL_DATA_OWNER = gql`
  mutation updateInternalDataowner($InternalDataOwnerID:ID!,
    $DepartmentName: String!,
    $EmailId: String!,
    $FirstName: String!,
    $LastName: String!,
    $MiddleName: String!,
    $OwnershipType: String!,
    $PhoneNumber: String!,
    $Title: String!)
  {
    updateInternalDataOwner(InternalDataOwnerID: $InternalDataOwnerID,
      DepartmentName:$DepartmentName,
      EmailId:$EmailId,
      FirstName: $FirstName,
      LastName: $LastName,
      MiddleName: $MiddleName,
      OwnershipType: $OwnershipType,
      PhoneNumber: $PhoneNumber,
      Title: $Title){
      DepartmentName
      EmailId
    }
  }`;

export const UPDATE_INTERNAL_DATABASE = gql`
  mutation updateInternalDatabase($InternalDatabaseID:ID!,
    $DatabaseName: String!,
    $DatabasePhysicalName: String!,
    $DatabaseProductName: String!,
    $DatabaseProductVersion: String!,
    $DatabaseDataFormatType: String!,
    $DataSensitivity: String!,
    $FunctionalDomains: String!)
  {
    updateInternalDatabases(InternalDatabaseID: $InternalDatabaseID,
      DatabaseName:$DatabaseName,
      DatabasePhysicalName:$DatabasePhysicalName,
      DatabaseProductName: $DatabaseProductName,
      DatabaseProductVersion: $DatabaseProductVersion,
      DatabaseDataFormatType: $DatabaseDataFormatType,
      DataSensitivity: $DataSensitivity,
      FunctionalDomains: $FunctionalDomains){
      DatabaseName
      DatabaseProductVersion
    }
  }`;

export const UPDATE_INTERNAL_TRANSACTION = gql`
  mutation updateTXAppplication($TxApplicationID:ID!,
    $CRUDFunctions: String!,
    $Tags: String!,
    $TxApplicationDesc: String!,
    $TxApplicationName: String!,
    $TxApplicationType: String!)
  {
    updateTxApplication(TxApplicationID: $TxApplicationID,
      CRUDFunctions:$CRUDFunctions,
      Tags:$Tags,
      TxApplicationDesc: $TxApplicationDesc,
      TxApplicationName: $TxApplicationName,
      TxApplicationType: $TxApplicationType){
      CRUDFunctions
      TxApplicationDesc
    }
  }`;

export const ADD_TAGG = gql`
mutation addTagNew($complianceTagFlag:String!,$complianceType:String!,$tagName:String!)
{
  addTags(complianceTagFlag:$complianceTagFlag,complianceType:$complianceType,tagName:$tagName)
  {
    TagName
  }
}`;

export const FETCH_ALL_TAGS = gql`
query allTags($AllTagID:String!)
{
  allTags(AllTagID:$AllTagID)
  {
    Tags{
      TagName
      ComplianceType
      ComplianceTagFlag
      NodeID
    }
    NodeID
  }
}`;

export const CREATE_ORGANIZATION = gql`
mutation newOrganization($OrganizationCode:String!,$OrganizationDesc:String!,$OrganizationName:String!,$CreateAddress:CreateAddress!,$CreateOrganizationDetail:CreateOrganizationDetail!)
{
  newOrganization(OrganizationCode:$OrganizationCode,OrganizationDesc:$OrganizationDesc,OrganizationName:$OrganizationName,CreateAddress:$CreateAddress,input:$CreateOrganizationDetail)
{
OrganizationCode
OrganizationID
}
}`;

export const CREATE_INTERNAL_DATA = gql`
    mutation newOrganizationInternal($OrganizationCode:String!,$OrganizationDesc:String!,$OrganizationName:String!,
      $CreateAddress:CreateAddress!,$DatabaseName: String!,$DatabasePhysicalName: String!,
      $DatabaseProductName: String!,$DatabaseProductVersion: String!,$DatabaseDataFormatType:String!,
      $DataSensitivity: String!,$FunctionalDomains: String!,$CreateTagsInput:CreateTagsInput,
      $CreateTxApplicationInput:CreateTxApplicationInput!)

    {  newOrganizationInternal(OrganizationCode:$OrganizationCode,OrganizationDesc:$OrganizationDesc,OrganizationName:$OrganizationName,
      CreateAddress:$CreateAddress,DatabaseName: $DatabaseName,DatabasePhysicalName: $DatabasePhysicalName,
      DatabaseProductName: $DatabaseProductName,DatabaseProductVersion: $DatabaseProductVersion,DatabaseDataFormatType:$DatabaseDataFormatType,
      DataSensitivity: $DataSensitivity,FunctionalDomains: $FunctionalDomains,CreateTagsInput:[$CreateTagsInput],
      CreateTxApplicationInput:$CreateTxApplicationInput)
    {
      OrganizationCode
      OrganizationID

    }
  }`;
