import {
    ChangeDetectionStrategy,
    Component,
    Directive,
    HostBinding,
} from '@angular/core';

@Directive({
    selector: '[igvLayoutSidebar]',
})
export class LayoutSidebarDirective {}

@Directive({
    selector: '[igvLayoutContent]',
})
export class LayoutContentDirective {}

@Component({
    selector: 'igv-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
    @HostBinding('class') classes = 'igv-layout';
}
