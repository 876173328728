import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import _ from 'lodash';

import { DATA_TABS, GET_QUERY } from './query-controller.consts';
import { DATASOURCE } from 'src/app/shared/const/datasource.const';

import { DataFrameModel } from '@xfusiontech/data-studio';

import { CreateDataAssetComponent } from '../../create-data-asset';

import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { UtilService } from 'src/app/service/util-service';
import { SharedDataService } from '../../shared/modules/shared-data/shared-data.service';
import { UDPDatasetOperatorService } from '../../udp-dataset-operator/udp-data-operator.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';
import { UPDMilOperatorService } from '../../upd-mil-operator/udp-mil-operator.service';
import { SchemaExplorerService } from 'libs/schema-explorer/src/lib/schema-explorer/schema-explorer.service';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';

import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';

@Component({
  selector: 'lib-query-controller',
  templateUrl: './query-controller.component.html',
  styleUrls: ['./query-controller.component.scss']
})
export class QueryControllerComponent implements OnInit, OnDestroy {
  @Input()
  selectedDataset: any;

  @Input()
  hideSidebar = false;

  @Input()
  isQueryTab = false;

  @Input()
  dataAssetID: string;

  @Input()
  queryDataSourceType: any;

  @Input()
  querySchema: any;

  @Input()
  showQueryController = true;

  @Input() customerId: string;

  generateSchemaErrorMessage = '';
  datasets: any;
  public config: any = {};
  sideGridData: any;
  code = '';
  editorRef: any;
  editor: any;
  sidebarExpanded = true;
  previewDataTabs = DATA_TABS;
  queryForm: FormGroup;
  expanded: any;
  showQueryDetails = false;
  showSuccessQuery = false;
  showQueryError = false;
  showSidebar = true;
  dataSourceId: string;
  dataSourceType: string;
  queryList: any = [];
  dataAssetTypeMap = { QRY: 'Query' };
  catList: any;
  categoryLists: any[];
  dataAsstType = DATASOURCE.QUERY_CODE;
  editorOptions = {
    theme: 'vs-light',
    language: 'sql',
    fontSize: '14px',
    automaticLayout: true,
    wordWrap: 'on',
    dragAndDrop: true
  };
  queryScript: any;
  queryData: any;
  queryDataSourceID: any;
  @Input() userId: string;
  // customerID: string;
  showPreview = false;
  dfModel: DataFrameModel;
  queryDetails: any;
  selectedQuery: any;
  selectedCategory: any;
  defaultVal = '';
  private subscriptions: Subscription[] = [];
  userSessionKey: string;
  mappingConfig: any;
  entities: any;
  dataAssetTypeIconMap = {
    DF: 'DMG-File Imort.svg',
    DP: 'DP-Data Pipeline.svg',
    QRY: 'DMG-Query.svg',
    VIZ: 'DMG-Visualization.svg',
    DS: 'DMG-Dataset.svg',
    NB: 'DP-Data Pipeline Composer.svg'
  };

  private currentGeneratedSchema: any;

  @ViewChild('myModal', { static: true, read: ElementRef }) myModal;
  previewDetails: any;

  constructor(
    private fb: FormBuilder,
    private _dataCatalogService: DataCatalogService,
    private _utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedDataService,
    private datasetOperatorService: UDPDatasetOperatorService,
    private udpTaskConfigService: UdpTaskConfigService,
    private udpMilOperatorService: UPDMilOperatorService,
    private dialog: MatDialog,
    private errorHandlingService: ErrorHandlingService,
    private schemaExplorerService: SchemaExplorerService
  ) {
    this.subsribeToOperatorSave();
  }

  ngOnInit() {
    this.dataSourceId = this.selectedDataset.dataSourceID;
    if (
      this.selectedDataset &&
      this.selectedDataset.datasetDataSourceType == DATASOURCE.INTERNAL_DATA_SOURCE_CODE
    ) {
      this.dataSourceId =
        this.selectedDataset.datasetDataSource.database.customerDataSourceID;
      if (this.selectedDataset && this.selectedDataset.queryID) {
        this.getDataSetAsset(this.selectedDataset.queryID);
      }
    }
    this.getQueryAssetForQry();
    this.getDataAssetForDF();

    this.userId = this.udpTaskConfigService.getUserId();
    this.customerId = this.udpTaskConfigService.getCustomerId();
    this.userSessionKey = this._utilService.getUserSessionKey();
    this.showQueryController = true;
    // Query Form
    this.queryForm = this.fb.group({
      queryContent: ['', Validators.required]
    });
    this.sharedService.querySchema.subscribe((data) => {
      const editordata = this.queryForm.get('queryContent').value;
      if (data) {
        this.queryForm.get('queryContent').patchValue(editordata + ' ' + data);
        // this.defaultVal = data;
      }
      this.cdr.detectChanges();
    });
    this.getDataAssestCategories();

    this.subscriptions[this.subscriptions.length] =
      this._dataCatalogService.queryDataAssetInfo$.subscribe(
        (queryData: any) => {
          if (queryData) {
            this.queryData = queryData;
            this.queryDataSourceID = queryData.data.subCategory.dataSourceID;
            this.queryDataSourceType =
              queryData.data.subCategory.dataSourceType;
            this.dataSourceId = this.queryDataSourceID;
            this.showQueryController = true;
            if (queryData.data.subCategory.dataSourceType === DATASOURCE.DATASET_CODE) {
              this.querySchema = queryData.data.subCategory.datasetSchema;
            } else if (queryData.data.subCategory.dataSourceType === DATASOURCE.FILE_ASSET_CODE) {
              this.querySchema = queryData.data.subCategory.fileSchema;
              this.dataAssetID = queryData.data.subCategory.fileID;
            }
            this.reset();
          }
        }
      );

    this.subscriptions[this.subscriptions.length] =
      this._dataCatalogService.queryClose$.subscribe((data: any) => {
        if (data) {
          if (data.save) {
            this.getQueryDetails(data.save);
          } else if (data.cancel) {
            document.getElementById('closeModalButton').click();
          }
        }
      });

    this.config = {
      catKey: 'dataSetName',
      catIdKey: 'dataSetID',
      subCatIdKey: 'dataSetDataSourceID',
      subCatKey: 'dataSetDataSourceName',
      listKey: 'dataSetDataSource'
    };

    this.sideGridData = GET_QUERY;

    // Retrive list of queries to be displayed in sidepanel
    this.getQueryList();

    this.queryForm.valueChanges.subscribe((data: any) => {
      this.cdr.detectChanges();
    });
  }

  closeDataFrameComponent() {
    this.showPreview = false;
  }

  private subsribeToOperatorSave() {
    this.subscriptions[this.subscriptions.length] =
      this.udpTaskConfigService.currentOperatorSave$.subscribe((mode) => {
        if (mode && mode !== '' && mode === 'DATASET') {
          const queryScript = this.queryForm.get('queryContent').value;
          if (
            queryScript &&
            queryScript !== '' &&
            this.currentGeneratedSchema
          ) {
            this.lastQueryData(queryScript);
            this.udpTaskConfigService.updateEntitySchema.next({
              currentGeneratedSchema: this.currentGeneratedSchema,
              queryID: this.selectedQuery.queryID
                ? this.selectedQuery.queryID
                : ''
            });
          } else {
            this.udpTaskConfigService.updateEntitySchema.next(false);
          }
        }
      });
  }

  private getQueryAssetForQry() {
    if (
      (this.selectedDataset &&
        this.selectedDataset.datasetDataSourceType == DATASOURCE.QUERY_CODE) ||
      this.selectedDataset.dataSourceType === DATASOURCE.QUERY_CODE
    ) {
      if (
        this.selectedDataset &&
        this.selectedDataset.datasetDataSource &&
        this.selectedDataset.datasetDataSource.database &&
        this.selectedDataset.datasetDataSource.database.customerDataSourceID
      )
        this.dataSourceId =
          this.selectedDataset.datasetDataSource.database.customerDataSourceID;
      if (this.selectedDataset && this.dataSourceId) {
        this.getDataSetAsset(this.dataSourceId);
      }
    }
  }

  private getDataAssetForDF() {
    if (
      this.selectedDataset &&
      this.selectedDataset.dataSourceType === DATASOURCE.FILE_ASSET_CODE &&
      this.selectedDataset.queryID
    ) {
      this.getDataSetAsset(this.selectedDataset.queryID);
    }
  }

  getDataAssestCategories() {
    const dataAssetTypes = Object.keys(this.dataAssetTypeMap);
    const temp = 'DSRC,' + dataAssetTypes.join(',');
    this.datasetOperatorService
      .getDataAssestCategories(temp, this.customerId, this.userId)
      .subscribe((res: any) => {
        if (res.dataAssetCategoryList) {
          this.datasets = dataAssetTypes.map((type, i) => {
            return {
              id: i + 2,
              dataAssetType: this.dataAssetTypeMap[type],
              dataAssetCode: type,
              icon:
                'assets/Complete-Icon-Set/' + this.dataAssetTypeIconMap[type],
              dataAssets: res.dataAssetCategoryList[type]
                ? res.dataAssetCategoryList[type].map((cat: any) => {
                    return {
                      dataSetDataSource: [],
                      dataSetID: cat.dataAssetCategory.dataAssetCategoryID,
                      dataSetName: cat.dataAssetCategory.dataAssetCategoryName,
                      datasetSchema: {}
                    };
                  })
                : []
            };
          });
        }
        this.cdr.detectChanges();
      });
  }
  /**
   *
   * @param dataAssetId
   */
  getQueryDetails(assetID) {
    this.subscriptions[this.subscriptions.length] = this._dataCatalogService
      .getDataAsset(assetID, 'query')
      .subscribe((resp: any) => {
        this.queryDetails = resp;
        this.showQueryDetails = true;
      });
  }

  /**
   *
   */
  closeQueryDetails() {
    this.showQueryDetails = false;
  }

  /**
   * queryFormControl
   */
  get queryFormControl() {
    return this.queryForm.controls;
  }

  /**
   *
   */
  getQueryList() {
    this._dataCatalogService.sidebarExpanded.next(false);
    this.showSidebar = false;
    this.cdr.detectChanges();
    this.subscriptions[this.subscriptions.length] = this._dataCatalogService
      .getQueryAsset(DATASOURCE.QUERY_CODE, this.dataSourceId)
      .subscribe((data: any) => {
        const dataAssetTypes = Object.keys(this.dataAssetTypeMap);
        if (data) {
          this.queryList[0] = {
            id: 0,
            dataAssetType: DATASOURCE.QUERY,
            dataAssetCode: DATASOURCE.QUERY_CODE,
            dataAssets: data.map((cat: any) => {
              return {
                dataSetDataSource: cat.dataAssets.map((subCat) => {
                  return {
                    dataSourceID: subCat.id,
                    dataSourceName: subCat.name
                  };
                }),
                dataSetID: cat.catId,
                dataSetName: cat.catName,
                datasetSchema: {}
              };
            })
          };
          this.showSidebar = true;
        }
        // this._dataCatalogService.sidebarExpanded.next(true);
        this.cdr.detectChanges();
      });
  }

  /**
   *
   * @param e
   */
  onCategoryClick(e) {
    console.log('category click');
  }

  /**
   *
   * @param editor
   */
  editorInit(editor) {
    // Here you can access editor instance
    this.editor = editor;
  }

  /**
   *
   */
  toggleCard() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  lastQueryData(data) {
    this._dataCatalogService.lastQueryScript.next(data);
  }

  /**
   *
   */
  saveQuery() {
    // this.myModal.nativeElement.className = 'modal fade show';
    this.queryScript = this.queryForm.get('queryContent').value;

    const saveQueryData = {
      queryEditor: true,
      isQueryTab: this.isQueryTab,
      queryScript: this.queryScript,
      queryDataSourceID: this.dataSourceId
        ? this.dataSourceId
        : this.queryDataSourceID,
      queryDataSourceType:
        this.selectedDataset &&
        this.selectedDataset.datasetDataSourceType == DATASOURCE.INTERNAL_DATA_SOURCE_CODE
          ? this.selectedDataset.datasetDataSourceType
          : this.selectedDataset.dataSourceType,
      querySchema: this.querySchema
    };

    this._dataCatalogService.saveQueryInfo.next(saveQueryData);

    const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
      CreateDataAssetComponent,
      this.dialog,
      {
        width: '650px',
        data: {
          saveQueryData
        }
      }
    );

    ref.afterClosed().subscribe((data) => {
      this.getQueryList();
      if (data && data !== undefined && data !== null) {
        if(!this.selectedQuery){
          this.selectedQuery = {};
          this.selectedQuery.queryID = data;
        }
        this.regenerateSchema(data);
      }
    });
  }

  updateQuery() {
    const data = {
      customerFile: null,
      dataSet: null,
      query: null
    };
    let type = '';
    data.query = {
      isQueryTemplate: this.selectedQuery.isQueryTemplate,
      queryDescription: this.selectedQuery.queryDescription,
      queryID: this.selectedQuery.queryID,
      queryLanguage: this.selectedQuery.queryLanguage,
      queryName: this.selectedQuery.queryName,
      sharedFromUserID: this.selectedQuery.sharedFromUserID,
      sharedToUserID: this.selectedQuery.sharedToUserID,
      dataAssetCategoryID: this.selectedQuery.dataAssetCategoryID,
      querySchema: this.selectedQuery.querySchema,
      lastExecutedOn: this.selectedQuery.lastExecutedOn,
      lastExecutedbyUserID: this.selectedQuery.lastExecutedbyUserID,
      queryCreateUserID: this.selectedQuery.queryCreateUserID,
      queryDataSourceID: this.selectedQuery.queryDataSourceID,
      queryDataSourceType: this.selectedQuery.queryDataSourceType,
      queryScript: this.queryForm.get('queryContent').value,
      queryDashboardConfig: []
    };
    this.lastQueryData(data.query.queryScript);
    type = 'query';
    this._dataCatalogService.updateDataAsset(data, type).subscribe(
      (response: any) => {
        if (response.code === -1) {
          this.errorHandlingService.errorSuccessSnack('151', false);
        } else {
          this.regenerateSchema(data.query.queryID);
        }
      },
      (error) => {
        this.errorHandlingService.errorSuccessSnack('151', false);
      }
    );
  }

  private regenerateSchema(queryId: string) {
    this.currentGeneratedSchema = undefined;
    const type = 'query';
    const payload = {
      query: {
        queryID: queryId,
        queryDataSourceType: null
      }
    };
    this.subscriptions[this.subscriptions.length] = this._dataCatalogService
      .generateSchema(type, false, this.userId, this.customerId, payload)
      .subscribe((generatedSchema: any) => {
        if (generatedSchema && generatedSchema.code === -1) {
          this.generateSchemaErrorMessage =
            'Schema generation failed for this query. Please select another query or try again later.';
          this.cdr.detectChanges();
          return;
        }
        if (
          generatedSchema &&
          generatedSchema.data &&
          generatedSchema.data.schema
        ) {
          this.generateSchemaErrorMessage = '';
          this.currentGeneratedSchema = _.cloneDeep(
            generatedSchema.data.schema
          );
          this.cdr.detectChanges();
        }
      });
  }

  public onDestDropped(e: CdkDragDrop<string[]>, id: number): void {
    this.selectedQuery = e.item.data;
  }

  onDatasetSelect(event) {
    if (event.subCategory) {
      this.udpMilOperatorService
        .getDataAsset(event.subCategory.dataSourceID, 'query')
        .subscribe((data) => {
          this.selectedQuery = data;
          this.selectedCategory = event.category;
          this.queryForm
            .get('queryContent')
            .patchValue(this.selectedQuery.queryScript);
          this.regenerateSchema(event.subCategory.dataSourceID);
          this.cdr.detectChanges();
        });
    }
  }

  private getDataSetAsset(assetId) {
    this.udpMilOperatorService
      .getDataAsset(assetId, 'query')
      .subscribe((data) => {
        this.selectedQuery = data;
        this.queryForm
          .get('queryContent')
          .patchValue(this.selectedQuery.queryScript);
        if (this.selectedQuery && this.selectedQuery.queryID) {
          this.regenerateSchema(this.selectedQuery.queryID);
        }
        this.cdr.detectChanges();
      });
  }

  closeSelectedQuery() {
    this.selectedQuery = null;
    this.selectedCategory = null;
    this.queryForm.get('queryContent').patchValue('');
  }

  /**
   *
   */
  onExpandedChange() {
    this.expanded = !this.expanded;
    // this.expandedChange.emit(this.expanded);
  }

  /**
   *
   */
  closeQuery() {
    this.showQueryController = false;
    this.subscriptions[this.subscriptions.length] =
      this._dataCatalogService.closeQueryController.next(true);
    const payload = {
      userID: this.userId,
      customerID: this.customerId,
      actionType: 'UQS',
      actionCode: 'CLEAR_QRY_RESULT',
      workflowDefination: null,
      dataAssetID: this.dataAssetID,
      dataAssetType: this.queryDataSourceType,
      sessionKey: this.userSessionKey,
      taskContentRequest: null,
      queryScript: null,
      dataBlendRule: null
    };
    // API call
    this.subscriptions[this.subscriptions.length] = this._dataCatalogService
      .userSession(payload)
      .subscribe((clearResp: any) => {});
  }

  /**
   *
   */
  runQuery() {
    this.isQueryTab ? (this.dataAssetID = this.dataSourceId) : this.dataAssetID;
    this.cdr.detectChanges();
    const startTime = performance.now();
    let endTime = 0;
    // API call
    this.subscriptions[this.subscriptions.length] = this._dataCatalogService
      .runQuery(
        this.userId,
        this.customerId,
        this.selectedDataset.datasetDataSourceType
          ? this.selectedDataset.datasetDataSource.database.customerDataSourceID
          : this.dataSourceId,
        this.selectedDataset.datasetDataSourceType === DATASOURCE.INTERNAL_DATA_SOURCE_CODE
          ? DATASOURCE.INTERNAL_DATA_SOURCE_CODE
          : this.selectedDataset.dataSourceType,
        this.queryForm.get('queryContent').value
      )
      .subscribe(
        (runResponse) => {
          if (runResponse.data) {
            this.generateSchemaErrorMessage = '';
            this.showQueryDetails = true;
            this.dfModel = runResponse.data;
            this.showPreview = true;
            endTime = performance.now();
            const totalTime = endTime - startTime; // time took to run in milliseconds
            this.previewDetails = runResponse.data;            
            this.cdr.detectChanges();
          }
          if (runResponse.code === -1 || runResponse.code === 2) {
            this.errorHandlingService.errorSuccessSnackMessage(
              runResponse.message,
              false
            );
            this.generateSchemaErrorMessage = runResponse.message;
          }
        },
        () => {
          this.generateSchemaErrorMessage =
            'Oops! Something went wrong with selected query. Please try again later';
        }
      );

    if (this.queryForm.value.queryContent === 'success') {
      this.showQueryError = false;
      this.showSuccessQuery = true;
    } else if (this.queryForm.value.queryContent === 'error') {
      this.showQueryError = true;
      this.showSuccessQuery = false;
    }
  }

  /**
   *
   */
  reset() {
    this.queryForm.reset();
    this.showQueryError = false;
    this.showSuccessQuery = false;
    this.showPreview = false;
    this.showQueryDetails = false;
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * toggles the sidebar.
   */
  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
}
