import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarMessageComponent } from '../components/shared/snackbar/component/snackbar-message.component';

export type MessageType = 'error' | 'warning' | 'info' | 'success';

@Injectable()
export class MessagesService {
  constructor(private snackBar: MatSnackBar) {}

  openMessage(
    message: string,
    messageType: MessageType = 'info',
    options?: Partial<MatSnackBarConfig>
  ) {
    const { instance } = this.snackBar.openFromComponent(
      SnackbarMessageComponent,
      {
        duration: 3000,
        ...options,
        data: {
          iconType: messageType === 'success'
            ? 'material' : 'feather'
        },
        panelClass: `xft-snackbar-${messageType}`
      }
    );

    instance.message = message;
    instance.type = messageType;
  }
}
