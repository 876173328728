import { takeUntil } from 'rxjs/operators';
import { Component, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AvailableType, UnionInputPayload, UnionOutputPayload } from '../../../../types/form';

import { mapAvailableTypeToInputType } from '../../../utils/union/mapAvailableTypeToInputType';
import { Subject } from 'rxjs';

@Component({
    selector: 'xft-form-union',
    templateUrl: './union.component.html',
    styleUrls: ['./union.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UnionComponent implements OnDestroy {

    @Input()
    set control(control: FormControl) {
        this._control = control;
        this.createUnionFormControl(control.value);
    }
    get control(): FormControl {
        return this._control;
    }
    private _control: FormControl;

    private OnDestroy$ = new Subject();

    @Input()
    tooltipMessage: string;

    @Input()
    label = '';

    unionFormControl: FormGroup;
    availableTypes: AvailableType[];

    get selectedInputType() {
        const type = this.unionFormControl.get('type').value;
        return mapAvailableTypeToInputType(type);
    }

    get selectedType() {
        return this.unionFormControl.get('type').value;
    }

    get currentControl() {
        return this.unionFormControl.get('value');
    }

    constructor(
        private formBuilder: FormBuilder
    ) {}

    private subscribeResetValueOnTypeChange = () => {
        this.unionFormControl.get('type').valueChanges
            .pipe(takeUntil(this.OnDestroy$))
            .subscribe(() => {
                this.unionFormControl.get('value').setValue(null);
            });
    }

    private createUnionFormControl(payload: UnionInputPayload) {
        if (!payload) {
            return;
        }

        this.availableTypes = payload.availableParameterTypes;
        this.unionFormControl = this.formBuilder.group({
            type: [payload.selectedParameterType],
            value: [payload.value]
        });

        this.subscribeResetValueOnTypeChange();

        this.unionFormControl.valueChanges
            .pipe(takeUntil(this.OnDestroy$))
            .subscribe((val) => {
                const nextValue: UnionOutputPayload = {
                    selectedType: val.type,
                    value: val.value
                };

                this.control.markAsDirty();
                this.control.setValue(nextValue);
            });
    }

    ngOnDestroy() {
        this.OnDestroy$.next();
        this.OnDestroy$.complete();
    }
}
