import * as go from 'gojs';
import { isRegularPort } from '../../../../utils/portTypes/isRegularPort';

const isPolicyApplicable = isRegularPort;

// Nodes in can connect only connect to nodes
// in the same group
export const regularPortPolicy = (
  fromNode: go.Node,
  fromPort: go.GraphObject,
  toNode: go.Node,
  toPort: go.GraphObject
) => {
  if (!isPolicyApplicable(fromPort)) {
    console.log('defaultPortPolicy not applicable');
    return undefined;
  }

  const fromNodeGroup = fromNode.containingGroup;
  const fromNodeGroupKey = fromNodeGroup
    ? fromNodeGroup.key
    : undefined;

  const toNodeGroup = toNode.containingGroup;
  const toNodeGroupKey = toNodeGroup
    ? toNodeGroup.key
    : undefined;

  return fromNodeGroupKey === toNodeGroupKey;
};
