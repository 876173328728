import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { ValueObject } from '../../form-field/form-field.types';
import { mapFieldsToSearchQuery } from './utils/mapFieldsToSearchQuery';

@Component({
    selector: 'udp-advanced-search',
    templateUrl: './advanced.component.html',
    styleUrls: ['./advanced.component.scss']
})
export class AdvancedSearchComponent {

    constructor(
        private dialogRef: MatDialogRef<AdvancedSearchComponent>
    ) { }

    onFormSubmit(formValue: ValueObject) {
        this.dialogRef.close(
            mapFieldsToSearchQuery(formValue)
        );
    }

}
