import { MapChartOptions } from './map-chart.types';

export const DEFAULT_MAP_CHART_OPTIONS: MapChartOptions = {
    title: {
        text: null,
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: 'bottom',
            align: 'right'
        }
    },
    colorAxis: {
        min: 0,
        minColor: '#F7FAFC',
        maxColor: '#23405C'
    }
};
