import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class SchemaExplorerService {

    private dataMappingEntityArray = [];

    public currentSelectedEntityId = '';

    constructor(private http: HttpClient) {
    }

    /**
     * @description Get the data for a particular entity
     */
    public getEntity(baseApiUrl: string, entityId: string, currentEntityGroupId: string): Observable<any> {
        return this.http.get(`${baseApiUrl}metadata/entityGroup/${currentEntityGroupId}/entities/${entityId}`);
    }

    /**
     * @description Maintain a list of all entities which are available for data mapping
     */
    public updateDataMappingEntitiesList(entity, isDelete: boolean) {
        if (isDelete) {
            const index = _.findIndex(this.dataMappingEntityArray, (e: any) => {
              return e.entityID === entity;
            });
            if (index !== -1) {
              this.dataMappingEntityArray.splice(index, 1);
            }
        } else {
            this.dataMappingEntityArray.push(entity);
        }
    }

    public openDialog(component: any, dialog: any,
        opts: any = {}): MatDialogRef<typeof component> {

        const obj: any = {
          width: '1200px',
          autoFocus: true,
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'xft-dialog',
          data: null
        };

        return dialog.open(
          component, Object.assign({}, obj, opts)
        );
      }

}
