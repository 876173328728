// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@xfusiontech/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatExpansionModule, MatFormFieldModule, MatDialogModule, MatTableModule, MatPaginatorModule, MatInputModule } from '@angular/material';

// Components
import { UploadOperatorsComponent } from './upload-operators.component';
import { UploadResponseComponent } from './upload-response/upload-response.component';

// Directives
import { DragDropUploadDirective } from './shared/directives/drag-drop-upload/drag-drop-upload.directive';

/**
 * @constant
 */
const EXPORTS: any[] = [
  UploadResponseComponent
  , UploadOperatorsComponent
];

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [
    ...EXPORTS
    , DragDropUploadDirective
  ],
  imports: [
    FormsModule
    , ButtonModule
    , CommonModule
    , MatInputModule
    , MatTableModule
    , MatButtonModule
    , MatDialogModule
    , MatPaginatorModule
    , MatExpansionModule
    , MatFormFieldModule
    , MatFormFieldModule
    , ReactiveFormsModule
  ],
  exports: [...EXPORTS]
})
export class UploadOperatorsModule { }
