import * as go from 'gojs';

import { Category } from '../types';
import { locationBinding } from './bindings/locationBinding';
import { shadow } from './parts/shared/shadow';
import { expandButton } from './parts/taskGroup/expandButton';
import { taskGroupLabel } from './parts/taskGroup/taskGroupLabel';
import { background } from './parts/taskGroup/background';
import { handleAddNodesToGroup } from '../handlers/handleAddNodesToGroup';
import { MAIN_SHAPE_NAME } from '../consts/shared';
import { Port } from '../../types/workflowDefinition';
import { port } from './parts/ports/port';
import { updatePortsVisibility } from '../utils/updateDiagramPortsVisibility/updatePortsVisibility';
import { groupInternalPort } from './parts/taskGroup/groupInternalPort';
import { resizableBinding } from './parts/taskGroup/sizeBinding';

const $ = go.GraphObject.make;

const groupTemplate = () => $(
  go.Group,
  go.Panel.Spot,
  {
    locationSpot: go.Spot.TopLeft,
    layerName: 'Background',
    mouseEnter: (__, obj: go.GraphObject) => updatePortsVisibility(obj, 1),
    mouseLeave: (__, obj: go.GraphObject) => updatePortsVisibility(obj, 0),
    handlesDragDropForMembers: true,
    mouseDrop: handleAddNodesToGroup,
    isSubGraphExpanded: true,
    resizeObjectName: MAIN_SHAPE_NAME,
    selectionObjectName: MAIN_SHAPE_NAME,
    resizable: true,
    ...shadow(),
  },
  background(),
  taskGroupLabel(),
  expandButton(),
  groupInternalPort(Port.GroupInternalLeft),
  groupInternalPort(Port.GroupInternalRight),
  port(Port.Left),
  port(Port.Right),
  locationBinding(),
  resizableBinding(),
  new go.Binding('isSubGraphExpanded', 'collapsed', (collapsed) => !collapsed)
);

export const createTaskGroupTemplate = () => ({
  category: Category.TaskGroup,
  template: groupTemplate(),
});
