import { Component, Input } from '@angular/core';

import { WidgetWrapperComponent } from '../widget-wrapper.component';
import { BarChartWrapperData } from './bar-chart-wrapper.types';

@Component({
    selector: 'udp-bar-chart-wrapper',
    templateUrl: './bar-chart-wrapper.component.html'
})
export class BarChartWrapperComponent
    extends WidgetWrapperComponent<BarChartWrapperData> {

    @Input()
    data: BarChartWrapperData;

}
