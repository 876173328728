import * as go from 'gojs';
import * as _ from 'lodash/fp';

import { ScrollArgs, ScrollFunction } from './types';

let hoveredContainer: go.Panel = null;
export const setHoveredContainer = (obj: go.Panel) =>
    hoveredContainer = obj;

const SCROLL_PADDING = 4;
const SCROLL_SPEED = 20;

const getScrollMultiplier = (direction: ScrollArgs[1]) => direction === 'down'
    ? SCROLL_SPEED
    : -SCROLL_SPEED;

const getScrollingPanel = () => hoveredContainer.elt(
    hoveredContainer.elements.count - 1
);

export const scrollContainer = (
    scrollFunction: ScrollFunction,
    args: ScrollArgs,
    shouldCallNativeScroll: boolean = true
) => {
    const [, direction, distance] = args;

    if (hoveredContainer && distance) {
        const scrollingPanel = getScrollingPanel();
        const { alignment, actualBounds: { height } } = scrollingPanel;
        const multiplier = getScrollMultiplier(direction);

        const offsetY = _.clamp(
            hoveredContainer.actualBounds.height - height - SCROLL_PADDING,
            0,
            alignment.offsetY + multiplier,
        );

        scrollingPanel.alignment = new go.Spot(
            0,
            0,
            0,
            offsetY,
        );
    } else if (shouldCallNativeScroll) {
        scrollFunction(...args);
    }
};
