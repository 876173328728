import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

/**
 * @constant
 */
const { getFilesFromDataTransferItems }: any = require('datatransfer-files-promise');

/**
 * @enum
 */
enum DropType {
  File = '1',
  Folder = '2'
}

/**
 * @enum
 */
enum DataTransferItemKind {
  File = 'file',
  String = 'string'
}

/**
 * @author: Naga
 */
@Directive({
  selector: '[xftDragDropUpload]'
})
export class DragDropUploadDirective {

  /**
   * @public
   * @type: {string}
   * @input
   */
  @Input()
  public dropType = '';

  /**
   * @public
   * @type: {string}
   * @input
   */
   @Input()
   public dragType = '';

  /**
   * @public
   * @type: {string}
   * @input
   * @description: comma separated
   * file extensions.
   */
  @Input()
  public fileExtns = '';

  /**
   * @public
   * @type: {boolean}
   * @hostbinding
   */
  @HostBinding('class.fileover')
  public fileOver = false;

  /**
   * @private
   * @type: {DataTransfer}
   */
  private dataTransfer: DataTransfer;

  /**
   * @private
   * @type: {any[]}
   */
  private truncatedFiles: any[] = [];

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public disableDragAndDrop = false;

  /**
   * @public
   * @type: {EventEmitter<any>}
   * @output
   */
  @Output()
  public fileDropped: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @public
   * @type: {EventEmitter<any>}
   * @output
   */
   @Output()
   public fileRemoved: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @public
   * @param: {e<any>}
   * @return: void
   * @description: a helper method that
   * listents to drag over event.
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(e: any): void {
    this.setFileOver(e, true);
  }

  /**
   * @public
   * @param: {e<any>}
   * @return: void
   * @description: a helper method that
   * listents to drag leave event.
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(e: any): void {
    this.setFileOver(e, false);
  }

  /**
   * @public
   * @param: {e<any>}
   * @return: void
   * @description: a helper method that
   * listens to the drop event.
   */
  @HostListener('drop', ['$event'])
  public onDrop(e: any): void {
    this.dataTransfer = this.cloneDataTransfer(e.dataTransfer);
    this.setFileOver(e, false);

    if (!this.disableDragAndDrop) {
      this.truncatedFiles = [];
      this.handleDrop(this.dataTransfer, e);
    }
  }

  /**
   * @private
   * @param: {dt<DataTransfer>}
   * @param: {e<any>}
   * @return: Promise<any>
   * @description: a helper method that
   * handles the drop event.
   */
  private async handleDrop(dt: DataTransfer, e: any): Promise<any> {
    if (dt.files.length > 0) {
      let files: File[] = await getFilesFromDataTransferItems(
        e.dataTransfer.items
      );

      const hasFolders: boolean = await this.hasFolders(dt);
      if (this.dropType) {

        // folder drop request
        if (hasFolders) {
          // do not allow folder drop event on
          // file type selection
          if (this.dropType !== DropType.Folder) {
            console.warn(`Directory drag & drop not allowed.`);
            return;
          }

        // file drop request
        } else {
          // do not allow files drop event on
          // folder type selection
          if (this.dropType === DropType.Folder) {
            console.warn(`Files drag & drop not allowed.`);
            return;
          }
        }
      }

      // handle files with specific extensions
      if (!!this.fileExtns) {
        files = this.getFilteredFiles(files);
      }

      this.fileRemoved.emit(this.truncatedFiles);
      this.fileDropped.emit(files);
    }
  }

  /**
   * @private
   * @param: {list<any[]>}
   * @return: any[]
   * @description: a helper method that
   * filters out the list based on the
   * supported file extensions.
   */
   private getFilteredFiles(list: any[]): any[] {
    return list.filter(file => {
      const extn: string = file.name
      && file.name.replace(/^.*?(\.[a-zA-Z0-9]+)$/, '$1');

      const arr: string[] = this.fileExtns.split(',');
      // add truncated files to the
      // truncated files bucket
      if (!arr.includes(extn)) {
        this.truncatedFiles.push(file);
      }
      return arr.includes(extn);
    });
  }

  /**
   * @public
   * @param: {e<any>}
   * @param: {value<boolean>}
   * @return: void
   * @description: a helper method that
   * sets the file over variable and
   * prevents the default event.
   */
  private setFileOver(e: any, value: boolean): void {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = value;
  }

  /**
 * @private
 * @param: {dt<any>}
 * @return: Promise<any>
 * @description: a helper method that
 * checks if there are any directories being
 * dragged into when it's not allowed.
 */
  private async hasFolders(dt: any): Promise<any> {
    return new Promise(async resolve => {
      let count: number = 0;
      const files: FileList = dt.files;

      // loop through all the files &
      // check if it contains any folders
      for (const file of Array.from(files)) {
        const isFolder: any = await this.isFolder(file);

        // increase the folder count
        // when found
        if (isFolder) {
          count++;
        }
      }
      resolve(count > 0);
    });
  }

  /**
   * @private
   * @param: {file<File>}
   * @return: Promise<any>
   * @description: a helper method that
   * checks for a directory.
   */
  private isFolder(file: File): Promise<any> {
    return new Promise(resolve => {
      const reader: FileReader = new FileReader();

      // this means it's a file
      reader.onload = (e: any): void => {
        resolve(false);
      };

      // this means it's a directory
      reader.onerror = (e: any): void => {
        resolve(true);
      };

      reader.readAsArrayBuffer(file.slice(0, 1));
    });
  }

  /**
   * @private
   * @param: {dt<DataTransfer>}
   * @return: DataTransfer
   * @description: a helper method that
   * clones the data transfer object to
   * avoid the context lost when using async await.
   */
  private cloneDataTransfer(dt: DataTransfer): DataTransfer {
    const originalItems: any = dt.items;
    const cloned: any = new DataTransfer();

    cloned.dropEffect = dt.dropEffect;
    cloned.effectAllowed = dt.effectAllowed;

    let i: number = 0;
    let originalItem: any = originalItems[i];

    while (originalItem) {
      switch (originalItem.kind) {
        case DataTransferItemKind.File:
          const file: any = originalItem.getAsFile();

          if (file) {
            cloned.items.add(file);
          }
          break;
        case DataTransferItemKind.String:
          cloned.setData(
            originalItem.type, dt.getData(originalItem.type)
          );
          break;
        default:
          console.error(
            'Unrecognized DataTransferItem.kind: ', originalItem.kind
          );
          break;
      }
      i++;
      originalItem = originalItems[i];
    }
    return cloned;
  }
}
