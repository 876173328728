import { Component, Input } from '@angular/core';
import { WidgetWrapperComponent } from '../widget-wrapper.component';
import { InformationBlockDataField } from './information-block.types';

@Component({
    selector: 'immu-track-information-block',
    templateUrl: './information-block.component.html',
    styleUrls: ['./information-block.component.scss']
})
export class InformationBlockComponent extends WidgetWrapperComponent<InformationBlockDataField>{
    // @Input() title: string;
    // @Input() fields: InformationBlockDataField[];

    @Input()
    data: InformationBlockDataField;  
}
