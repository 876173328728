import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UdpDatasetOperatorComponent } from './udp-dataset-operator.component';
import { LayoutModule } from '../../../../interactive-graph-view';
import { MatFormFieldModule, MatIconModule, MatRadioModule, MatSelectModule, MatTooltipModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SidebarWithFilterModule } from '../../../../sidebar';
import { KendoModule } from 'src/app/third-party-libs/kendo.module';

// import {  } from '../../../../shared/src/components';
import { FormFieldModule, IconButtonModule, StringArrayToSelectOptionsModule } from '@xfusiontech/shared';
import { SharedModule } from '../shared/shared.module';
import { QueryModule } from '../query';

@NgModule({
  declarations: [UdpDatasetOperatorComponent],
  imports: [
    CommonModule,
    SidebarWithFilterModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    LayoutModule,
    DragDropModule,
    KendoModule,
    MatRadioModule,
    MatIconModule,
    StringArrayToSelectOptionsModule,
    FormFieldModule,
    SharedModule,
    QueryModule,
    MatTooltipModule
  ],
  exports: [UdpDatasetOperatorComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class UdpDatasetOperatorModule { }
