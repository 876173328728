import { Component, Input } from '@angular/core';
import { LogsListItemGroup } from '../logs-list.types';

@Component({
    selector: 'udp-logs-list',
    templateUrl: './logs-list.component.html',
    styleUrls: ['./logs-list.component.scss'],
})
export class LogsListComponent {

    @Input()
    itemGroups: LogsListItemGroup[];

}
