import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { Workflow } from '../types/workflow';
import { WORKFLOW_URL, PYTHON_CODE_URL, GATEWAY_API, GITHUB_API, OPERATORS_UPLOAD_API, GITHUB_MULTIFILES_API } from '../consts/api';
import { WorkflowDefinition } from '../types/workflowDefinition';
import { CreateSaveBodyService } from './create-save-body.service';
import { environment } from 'src/environments/environment';
import { ModelSaveService } from '../gojs/services/model-save.service';

@Injectable()
export class ApiService {
    public currentValue: string;
    constructor(
        private httpClient: HttpClient,
        private createSaveBodyService: CreateSaveBodyService,
        private modelSaveService: ModelSaveService,
    ) { }

    /**
     *
     * @param data
     * @returns
     */
    getBranchData(data: any): Observable<any> {
        return this.httpClient.post(`${environment.dataPipelinePythonAPIURL}branch_version`, data);
    }

    /**
     *
     * @param files
     * @returns
     */
    getMultiFilesContent(files) {
        const params = this.createSaveBodyService.parameters;
        const { token, nodeName, login } = params;
        // tslint:disable-next-line: max-line-length
        return this.httpClient.post(`${GITHUB_MULTIFILES_API}multifiles?token=${token}&repository=${nodeName}&branch=main&login=${login}`, files);
    }

    /**
     *
     * @param files
     */
    saveMultiFilesContent(payload) {
        const { token, nodeName } = this.createSaveBodyService.parameters;
        // tslint:disable-next-line: max-line-length
        return this.httpClient.post(`${GITHUB_MULTIFILES_API}multifilesupdate?token=${token}&repository=${nodeName}&branch=main&isVersionSame=${this.createSaveBodyService.isVersionSame}`, payload);
    }

    fetchOperators() {
        return this.httpClient.get(GATEWAY_API, {
            params: {
                customer_id: environment.customer_id
            }
        });
    }

    fetchParametersOfSelectedNode(type: string) {
        return this.httpClient.get(
            `${GATEWAY_API}/${type}`);
    }

    uploadOperators(payload: any): Observable<any> {
        return this.httpClient.post(`${OPERATORS_UPLOAD_API}`, payload);
    }

    fetchWorkflowFile(params) {
        const { token, nodeId, workflowDefFileName, dagFileSha } = params;
        return this.httpClient.get(`${GITHUB_API}?token=${token}&repository=${nodeId}&filename=${workflowDefFileName}&sha=${dagFileSha}`);
    }

    fetchPythonCode(params) {
        const { token,
            nodeId,
            dagPathName,
            dagFileName,
            pythonFileSha
        } = params;

        // tslint:disable-next-line: max-line-length
        return this.httpClient.get(`${GITHUB_API}?token=${token}&repository=${nodeId}&filename=${dagPathName}/${dagFileName}&sha=${pythonFileSha}`, { responseType: 'text' });
    }

    upsertWorkflow(data: Workflow, insert: boolean = false, nodeId: string) {
        const fetchAction = insert
            ? this.httpClient.put.bind(this.httpClient)
            : this.httpClient.put.bind(this.httpClient);

        return fetchAction(
            `${WORKFLOW_URL}/${nodeId}`,
            JSON.stringify(data),
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                }),
                responseType: 'text'
            }
        ).pipe(
            catchError((error) => {
                return throwError(error.message);
            })
        );
    }

    async upsertWorkflowToGithub(data: WorkflowDefinition) {
        const json = JSON.stringify(data);
        const body = this.createSaveBodyService.createSaveBody(json, 'json');

        return this.httpClient.post(GITHUB_API, body);
    }

    getPythonCode(workflowDefinition: string) {
        return this.httpClient.post(
            `${PYTHON_CODE_URL}`,
            workflowDefinition,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                }),
                responseType: 'text'
            }
        );
    }

    getPythonCodeSave(workflowDefinition: string) {
        return  this.httpClient.post(
            `${PYTHON_CODE_URL}`,
            workflowDefinition,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                }),
                responseType: 'text'
            }
        ).toPromise();
    }

    savePythonCode(code: string) {

        const body = this.createSaveBodyService.createSaveBody(code, 'python');

        return this.httpClient.post(GITHUB_API, body);
    }

    // new changes
    async newFetchPythonCode(wdata: any) {

        const workflowDefinition = JSON.stringify(
            this.modelSaveService.getWorkflowDefinition(),
            null,
            4
        );

        const completecall = await this.getPythonCode(workflowDefinition)
            .pipe(
                take(1)
            )
            .subscribe(
                async (data) => {
                    this.currentValue = data;
                    const completeCall1 = await this.saveDAGFile();
                },
                () => {
                }
            );
    }

    async saveDAGFile() {
        const completecall = await this.savePythonCode(this.currentValue)
            .subscribe(
                () => {
                },
                () => {

                }
            );
    }

    /**
     *
    */
    saveBlendDataRule(datasetID, payload) {
        return this.httpClient.put(`${environment.dataAssetURL}schemaMetadata?datasetID=${datasetID}`, payload);
    }

    /**
     *
     * @param datasetID
     * @returns
    */
    getBlendRule(datasetID) {
        return this.httpClient.get(`${environment.dataAssetURL}schemaMetadata?datasetID=${datasetID}`);
    }

}

