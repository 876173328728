import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoIconComponent } from './info-icon.component';
import { MatIconModule, MatTooltipModule } from '@angular/material';

const components = [
    InfoIconComponent
];

const imports = [
    CommonModule,
    MatIconModule,
    MatTooltipModule
];

@NgModule({
    declarations: components,
    exports: components,
    imports: imports
})
export class InfoIconModule { }
