import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { IdentifyingAttributesType } from '../../global-search.types';

export const mapAttributesToGroups = (
    formBuilder: FormBuilder,
    identifyingAttributes: IdentifyingAttributesType[]
): FormGroup => {
    const formGroup = formBuilder.group({});

    identifyingAttributes.forEach((identAttr) => {
        identAttr.attributes.forEach(({attributeName}) => {
            formGroup.setControl(
                `${identAttr.entityClassType}/${attributeName}`,
                new FormControl('')
            );
        });
    });

    formGroup.setControl('allRecords', formBuilder.control(false));
    formGroup.setControl('potentialDuplicates', formBuilder.control(false));

    return formGroup;
};
