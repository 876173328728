import gql from 'graphql-tag';

export const GET_ALL_ORG = gql`query Organization
{
  organization
  {
      OrganizationID
      OrganizationCode
      OrganizationDesc
      OrganizationName
      id
      Address{
        FullAddress
        StructuredAddress{
        StateCode
        AddressTag
        City
        County
        FullAddress
        Latitude
        Longitude
        StateCode
        StateName
        StreetName
        StreetNumber
        UnitNumber
        ZipCode
        CreateDate
        DataTrustLevel
        LastUpdateDataSourceName
        LastUpdateDate
        Status
        id
      }
     }
      ExternalDataOrganization{
        ExternalData{
          ExternalDataName
          ExternalDataID
        }
      }
      SharedDataOrganization{
        SharedDataset{
          SharedDatasetName
        }
      }

      InternalDataOrganization{
        InternalDatabases{
          DatabaseProductName
        }
      }
        DataManagementPerson{
          FirstName
          LastName
          id
          MiddleName
          ContactEmail
          ContactHomePhoneNumber
          ContactMobilePhoneNumber
          OrganizationID
          Title
          DataManagementPersonPicture
          DataManagementPersonRoleDataLink {
            id
            RefDataManagementRole{
              DataManagementRoleCode
              DataManagementRoleName
              DataManagementRoleDesc
            }
            ExternalData{
              ExternalDataID
              ExternalDataName
            }

            InternalDatabases{
              InternalDatabaseID
              InternalDataOrgID
              DatabasePhysicalName
            }

            SharedDataset{
              SharedDatasetName
            }
          }
      }
  }
}`;

export const GET_ORG_DET_BY_EXT_ID = gql`query OrganizationExternalData($ExternalDataOrgID:String!){
organizationExternalData(ExternalDataOrgID:$ExternalDataOrgID){
  OrganizationID
  OrganizationCode
  OrganizationDesc
  OrganizationName
  Address{
    FullAddress
    StructuredAddress{
      City
      ZipCode
      StreetNumber
      StreetName
      UnitNumber
      StateName
    }
  }
  DataManagementPerson{
    FirstName
    MiddleName
    LastName
    id
    Title
    ContactEmail
    ContactHomePhoneNumber
    ContactMobilePhoneNumber
    DataManagementPersonPicture
    DataManagementPersonIsActive
    DataManagementPersonRoleDataLink{
      id
      RefDataManagementRole{
        DataManagementRoleCode
        DataManagementRoleName
        DataManagementRoleDesc
        DataManagementRoleIsActive
      }
    }

  }
 }
}`;

export const GET_ORG_DET_BY_INT_ID = gql`query organizationInternalData($InternalDataOrgID:String!){
  organizationInternalData(InternalDataOrgID:$InternalDataOrgID){
    OrganizationID
    OrganizationCode
    OrganizationDesc
    OrganizationName
    Address{
      FullAddress
      StructuredAddress{
        City
        ZipCode
        StreetNumber
        StreetName
        UnitNumber
        StateName
      }
    }
    DataManagementPerson{
      FirstName
      MiddleName
      LastName
      UserId
      id
      Title
      ContactEmail
      ContactHomePhoneNumber
      ContactMobilePhoneNumber
      DataManagementPersonPicture
      DataManagementPersonIsActive
      DataManagementPersonRoleDataLink{
        id
        RefDataManagementRole{
          DataManagementRoleCode
          DataManagementRoleName
          DataManagementRoleDesc
          DataManagementRoleIsActive
          RefAllDataManagementRoleID
        }
        ExternalData{
          ExternalDataID
          ExternalDataName
        }
        InternalDatabases{
          InternalDatabaseID
          InternalDataOrgID
          DatabasePhysicalName
        }
        SharedDataset{
          SharedDatasetName
        }
      }
    }
   }
  }`;

export const GET_ALL_STATES = gql`query Organization
{
  organization
  {
      Address{
        StructuredAddress{
        StateCode
        StateName
      }
     }
  }
}`;


export const ADD_ORG =
  gql`mutation newOrganization($OrganizationCode:String!,$OrganizationDesc:String,$OrganizationName:String,$CreateAddress:CreateAddress)
  {  newOrganization(OrganizationCode:$OrganizationCode,OrganizationDesc:$OrganizationDesc,OrganizationName:$OrganizationName,CreateAddress:$CreateAddress)
  {
  OrganizationCode
  OrganizationID
  }
  }`;

export const UPDATE_ORG = gql`
  mutation updateOrganization($OrganizationID:String!,$OrganizationCode:String!,$OrganizationDesc:String,$OrganizationName:String,$CreateAddress:CreateAddress,$CreateOrganizationDetail:CreateOrganizationDetail)
  {  updateOrganization(OrganizationID:$OrganizationID,OrganizationCode:$OrganizationCode,OrganizationDesc:$OrganizationDesc,OrganizationName:$OrganizationName,CreateAddress:$CreateAddress,input:$CreateOrganizationDetail)
  {
    OrganizationCode
  }}
`;

export const DELETE_ORG = gql`
mutation deleteOrganization($OrganizationID:String!)
{  deleteOrganization(OrganizationID:$OrganizationID)
}
`;

export const GET_ALL_ORG_CODES = gql`query Organization
{
  organization
  {
      OrganizationID
      OrganizationCode
      OrganizationName
  }
}`;



