import * as _ from 'lodash/fp';
import { filter, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';

import { AdvancedSearchComponent } from '../advanced-search/advanced.component';
import { SearchService } from '../global-search.service';

@Component({
    selector: 'udp-global-search',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchComponent {

    @Input()
    searchResultsPagePath: string[];

    form: FormGroup;

    constructor(
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private router: Router,
        @Optional() private searchService: SearchService
    ) {
        this.form = this.formBuilder.group({
            search: this.formBuilder.control('')
        });

        if (!searchService) {
            console.warn(
                'No searchService injector defined. The Searchbox '
                + 'is operation but the navigation may not work properly'
            );
        }
    }

    onAdvancedClick(event: MouseEvent) {
        event.stopPropagation();

        const diaglogRef = this.dialog.open(AdvancedSearchComponent);
        diaglogRef.afterClosed()
            .pipe(
                take(1),
                filter(_.identity)
            )
            .subscribe((searchQuery) => {
                if (this.searchService) {
                    this.searchService.setSearchQuery(searchQuery);
                }
                this.goToSearchResults();
            });
    }

    onSubmit() {
        this.goToSearchResults();
    }

    private goToSearchResults() {
        this.router.navigate(this.searchResultsPagePath);
    }

}
