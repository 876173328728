import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as go from 'gojs';

import { Diagram } from '../../gojs/Diagram';
import { DiagramFactoryService } from '../../gojs/diagram-factory.service';

@Component({
    selector: 'xft-diagram',
    templateUrl: './diagram.component.html',
    styleUrls: ['./diagram.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramComponent implements AfterViewInit, OnDestroy {

    @ViewChild('diagramWrapper', { static: false })
    diagramWrapper: ElementRef<HTMLDivElement>;


    private diagram: Diagram;

    constructor(
        private diagramFactoryService: DiagramFactoryService
    ) { }

    ngAfterViewInit() {
        if (!this.diagramWrapper) {
            return;
        }
        //go.Diagram.licenseKey = '2bf840e7b56658c511d35a25403e7efb0ea02d3bcf824ef7595316f6ed5f601123cce17b55d299d0d5f01ef41a7493d189956b2c93480c6be232dbdf47b680f0b03275b2165b17d9a15071909af82df3f52b24f3c7e727aada7b8df1eefb93954ef8f58118cc0ee979791421557fac4aa8fbc678f8';

        this.setupDiagram();
    }

    ngOnDestroy() {
        this.diagram.unbindServices();
    }

    private setupDiagram() {
      go.Diagram.licenseKey = '2bf840e7b56658c511d35a25403e7efb0ea02d3bcf824ef7595316f6ed5f601123cce17b55d299d0d5f01ef41a7493d189956b2c93480c6be232dbdf47b680f0b03275b2165b17d9a15071909af82df3f52b24f3c7e727aada7b8df1eefb93954ef8f58118cc0ee979791421557fac4aa8fbc678f8';

        this.diagram = this.diagramFactoryService.createDiagram(
            this.diagramWrapper.nativeElement
        );
    }

}
