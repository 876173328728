// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSlideToggleModule, MatSortModule, MatTableModule, MatTooltipModule } from '@angular/material';

// Library Imports
import { ButtonModule, FormFieldModule, IconButtonModule, SlideToggleModule } from './../../../../../../shared';

// Services
import { SharedDataService } from './shared-data.service';

// Components
import { ViewDataComponent } from './view-data/view-data.component';
import { MetaDataComponent } from './meta-data/meta-data.component';
import { ViewEditSchemaComponent } from './view-edit-schema/view-edit-schema.component';

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [
    ViewDataComponent
    , MetaDataComponent
    , ViewEditSchemaComponent
  ],
  imports: [
    CommonModule
    , ButtonModule
    , MatSortModule
    , MatTableModule
    , MatInputModule
    , MatDialogModule
    , FormFieldModule
    , IconButtonModule
    , MatTooltipModule
    , SlideToggleModule
    , MatCheckboxModule
    , MatFormFieldModule
    , MatPaginatorModule
    , ReactiveFormsModule
    , MatSlideToggleModule
  ],
  providers: [
    SharedDataService
  ],
  exports: [
    ViewDataComponent
    , MetaDataComponent
    , ViewEditSchemaComponent
  ]
})
export class SharedDataModule { }
