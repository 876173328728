import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'navigationFilter'
})
export class NavigationFilterPipe implements PipeTransform {
  transform(data: any[], value: string): any[] {
    return value
      ? (data || []).filter((category: any) => {
          const val: string = (value || '').toLowerCase();
          const dataStr: string = JSON.stringify(category || '').toLowerCase();

          return dataStr.indexOf(val) > -1;
        })
      : data;
  }
}
