import * as _ from 'lodash/fp';
import { RowLayoutOption } from './select-row-type-dialog.types';

export const LAYOUT_OPTIONS: RowLayoutOption[] = [
    {
        iconPath: 'M 0,0 H 104 V 60 H 0 Z',
        value: [12]
    },
    {
        iconPath: 'M 0,0 V 60 H 48 V 0 Z M 56,0 V 60 H 104 V 0 Z',
        value: [6, 6]
    },
    {
        iconPath: 'M 0,0 V 60 H 29 V 0 Z m 37,0 v 60 h 67 V 0 Z',
        value: [4, 8]
    },
    {
        iconPath: 'M 0,0 V 60 H 67 V 0 Z m 75,0 v 60 h 29 V 0 Z',
        value: [8, 4]
    },
    {
        iconPath: 'M 0,0 V 60 H 29 V 0 Z M 37,0 V 60 H 67 V 0 Z m 38,0 v 60 h 29 V 0 Z',
        value: [4, 4, 4]
    },
    {
        iconPath: 'M 0,0 V 60 H 20 V 0 Z m 28,0 v 60 h 76 V 0 Z',
        value: [3, 9]
    },
    {
        iconPath: 'M 0,0 V 60 H 76 V 0 Z m 84,0 v 60 h 20 V 0 Z',
        value: [9, 3]
    },
    {
        iconPath: 'M 0,0 V 60 H 20 V 0 Z M 28,0 V 60 H 48 V 0 Z M 56,0 V 60 H 76 V 0 Z m 28,0 v 60 h 20 V 0 Z',
        value: [3, 3, 3, 3]
    },
    {
        iconPath: 'M 0,0 V 60 H 20 V 0 Z M 28,0 V 60 H 76 V 0 Z m 56,0 v 60 h 20 V 0 Z',
        value: [3, 6, 3]
    },
    {
        iconPath: 'M 0,0 V 60 H 20 V 0 Z M 28,0 V 60 H 48 V 0 Z m 28,0 v 60 h 48 V 0 Z',
        value: [3, 3, 6]
    },
    {
        iconPath: 'M 0,0 V 60 H 48 V 0 Z M 56,0 V 60 H 76 V 0 Z m 28,0 v 60 h 20 V 0 Z',
        value: [6, 3, 3]
    },
    {
        iconPath: 'M 0,0 V 60 H 11.464744 V 0 Z M 18.180766,0 V 60 H 29.64551 V 0 Z m 18.17917,0 V 60 H 48.64359 V 0 Z M 55.358015,0 V 60 H 66.822784 V 0 Z M 73.537193,0 V 60 H 85.820847 V 0 Z M 92.535256,0 V 60 H 104 V 0 Z',
        value: [2, 2, 2, 2, 2, 2]
    }
];

export const INITIAL_OPTIONS_SELECTION: boolean[] = [
    true,
    ..._.flowRight(
        _.map(() => false),
        _.range(1)
    )(LAYOUT_OPTIONS.length)
];
