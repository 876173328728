import * as go from 'gojs';
import { theme } from '../../../../consts/theme';

const $ = go.GraphObject.make;

const colorSelector = (valid: boolean) => valid
    ? theme.colors.link
    : theme.colors.selectedInvalidNodeAccent;

export const validationStrokeBinding = () =>
    new go.Binding('stroke', 'valid', colorSelector);
