import {
    Component,
    Input,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Query } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-query-editor-queries-navigation',
    templateUrl: './navigation-queries.component.html',
    styleUrls: ['../navigation/navigation.component.scss'],
})
export class QueryNavigationQueriesComponent {
    @Input() queries: Query[];

    @Output()
    shareQueryInDashboard = new EventEmitter<Query>();

    @Output()
    shareQueryByEmail = new EventEmitter<Query>();

    @Output()
    deleteQuery = new EventEmitter<Query>();

    menuTopLeftPosition = { x: '0', y: '0' };
    menuData: Query;

    @ViewChild(MatMenuTrigger, { static: true })
    matMenuTrigger: MatMenuTrigger;

    queryTrackBy = (_, query: Query) => query.queryID;

    onRightClick(event: MouseEvent, data: Query) {
        event.preventDefault();
        this.menuTopLeftPosition.x = event.clientX + 'px';
        this.menuTopLeftPosition.y = event.clientY + 'px';
        this.menuData = data;
        this.matMenuTrigger.openMenu();
    }
}
