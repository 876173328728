import { DASHBOARD_PATH } from './../../consts/paths';
import { LOGIN_API } from './../../consts/urls';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@xfusiontech/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoginPageService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private router: Router
    ) {}

    private errors$ = new BehaviorSubject<string>(null);

    getErrors(): Observable<string> {
        return this.errors$.asObservable();
    }

    login(userID: string, password: string): void {
        this.httpClient
            .post(`${LOGIN_API}/udp-core/user/login`, null, {
                headers: {
                    userID,
                    password,
                },
            })
            .pipe(take(1))
            .subscribe({
                error: err => {
                    const error =
                        err.status === 401
                            ? 'Invalid username or password!'
                            : 'An error occurred';
                    this.errors$.next(error);
                },
                next: (response: any) => {
                    if (response.userToken) {
                        this.authService.login(userID, response.userToken);
                        this.errors$.next(null);
                        this.router.navigate([DASHBOARD_PATH]);
                    } else {
                        this.errors$.next('An error occurred');
                    }
                },
            });
    }
}
