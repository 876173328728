/**
 * @author: Naga
 * @constant
 */
export const DISP_COLUMNS_CFG: any = {
  attributeSequenceNumber: {
    name: 'Seq No.',
    field: 'attributeSequenceNumber'
  },
  attributeName: {
    name: 'Attribute Name',
    field: 'attributeName'
  },
  attributeAliasName: {
    name: 'Alias',
    field: 'attributeAliasName',
    controlName: 'attributeAliasName',
    editable: true,
    fieldType: 'INPUT'
  },
  attributeDescription: {
    name: 'Description',
    field: 'attributeDescription',
    editable: true,
    fieldType: 'POPUP'
  },
  dataType: {
    name: 'Data Type',
    field: 'dataType',
    controlName: 'dataType',
    editable: true,
    fieldType: 'SELECT'
  },
  attributeLength: {
    name: 'Length',
    field: 'attributeLength',
    controlName: 'attributeLength',
    editable: true,
    fieldType: 'INPUT'
  },
  attributeFormat: {
    name: 'Format',
    field: 'attributeFormat',
    controlName: 'attributeFormat',
    editable: true,
    fieldType: 'INPUT'
  },
  glossaryTerm: {
    name: 'Glossary Term',
    field: 'glossaryTerm',
    editable: true,
    fieldType: 'TERM'
  },
  isRequiredAttribute: {
    name: 'Required',
    field: 'requiredAttribute',
    controlName: 'requiredAttribute',
    editable: true,
    fieldType: 'RADIO'
  },
  isIdentifyingAttribute: {
    name: 'Id Attribute',
    field: 'identifyingAttribute',
    controlName: 'identifyingAttribute',
    editable: true,
    fieldType: 'RADIO'
  },
  isSensitiveData: {
    name: 'Sensitive Data',
    field: 'sensitiveData',
    controlName: 'sensitiveData',
    editable: true,
    fieldType: 'RADIO'
  },
  attributeComment: {
    name: 'Comment',
    field: 'attributeComment',
    controlName: 'attributeComment',
    editable: true,
    fieldType: 'POPUP'
  }
};

/**
 * @constant
 */
export const DATA_TYPES: string[] = [
  'STRING',
  'INTEGER',
  'DECIMAL',
  'BOOLEAN',
  'DATE',
  'TIME',
  'NULL',
  'DATETIME',
  'LONG',
  'CHAR'
];
