import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'igv-query-variables-dialog-grid-parameters-cell',
    templateUrl: './grid-parameters-cell.component.html',
    styleUrls: ['./grid-parameters-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryVariablesDialogGridParametersCellComponent {
    @Input()
    parameters: { [key: string]: any };

    get parameterKeys(): string[] {
        return this.parameters ? Object.keys(this.parameters) : [];
    }
}
