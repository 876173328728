import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatFormFieldModule } from '@angular/material/form-field';

import { PropertiesEditorComponent } from './editor/properties-editor.component';
import { PropertiesEditorService } from './properties-editor.service';
import { FormFieldModule } from '../shared/form-field/form-field.module';
import { IconButtonModule } from '../shared/icon-button/icon-button.module';
import { PropertiesEditorNodeViewComponent } from './node-view/properties-editor-node-view.component';

const imports = [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormFieldModule,
    IconButtonModule,
    PerfectScrollbarModule,
    MatTooltipModule
];

const providers = [
    PropertiesEditorService
];

const components = [
    PropertiesEditorComponent
];

const innerComponents = [
    PropertiesEditorNodeViewComponent
];

@NgModule({
    imports,
    providers,
    declarations: [
        ...components,
        ...innerComponents
    ],
    exports: components
})
export class PropertiesEditorModule { }
