import { IconVariant } from '../../../types/shared';
import { Component, Input, OnInit } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'xft-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
    @Input()
    name: string;

    @Input()
    tooltipMessage: string;

    @Input()
    variant: IconVariant = 'material';

    @Input()
    tooltipPosition: TooltipPosition = 'after';

    isMaterial = false;

    ngOnInit(): void {
        this.isMaterial = this.variant === 'material';
    }
}
