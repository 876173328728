import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {DashboardPageService} from '@xfusiontech/mdm';

@Component({
    selector: 'udp-select-cell-type-dialog',
    templateUrl: './select-cell-type-dialog.component.html',
    styleUrls: ['./select-cell-type-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectCellTypeDialogComponent {

    step = 0;
    selectedCategory: string;
    title: string;
    source: string;
    public type: string;
    public data : string;

    constructor(
        private dialogRef: MatDialogRef<SelectCellTypeDialogComponent>) {
    }

    // openDialog() {
    //     DashboardPageService.  showInsertCellContentDialog().subscribe(data => {
    //     });
    //   }

    onWidgetCategorySelected(category: string) {
        this.selectedCategory = category;
    }

    onNextButtonClick() {
        this.step += 1;
    }

    onBackButtonClick() {
        this.step -= 1;
    }

    onContentModelChange(next: any) {
        const { title, source, type } = next;
        this.title = title;
        this.source = source;
        this.type = type;
    }

    onSaveClick() {
        this.dialogRef.close({
            selectedCategory: this.selectedCategory,
            title: this.title,
            source: this.source,
            type: this.type
        });


    }

}
