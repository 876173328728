import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';

import { MapChartComponent } from './chart/map-chart.component';

const imports = [
    CommonModule,
    HighchartsChartModule
];

const components = [
    MapChartComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class MapChartModule { }
