import { WidgetLibrary } from './select-cell-type-dialog.types';

export const WIDGET_LIBRARY: WidgetLibrary = [
    {
        name: 'Charts',
        type: 'homeDashboard',
        elements: [
            {
                category: 'bar-chart',
                label: 'Entity identification, Potential duplicates and child Client creation trend',
                description: 'Simple bar chart',
                iconPath: 'bar-chart-1.jpg'
            },
            {
                category: 'dupChildRecordsMerged',
                label: 'Number of Mislinks and Unmerges',
                description: 'Simple line chart',
                iconPath: 'bar-chart-2.jpg'
            },
            {
                category: 'childPlacementAdoption',
                label: 'Child Adoption Trend',
                description: 'Simple pie chart',
                iconPath: 'bar-chart-3.jpg'
            },
            {
                category: 'heatmap',
                label: 'Heatmap of facilities by county',
                description: 'Simple pie chart',
                iconPath: 'heat-map.jpg'
            },
            {
                category: 'heatmapChildFacility',
                label: 'Heatmap of number of children in facility by county',
                description: 'Simple pie chart',
                iconPath: 'heat-map.jpg'
            }
            ,
            {
                category: 'child-identification-request',
                label: 'Percentage of Child Identification Request Created New Child Record',
                description: 'Simple pie chart',
                iconPath: 'bar-chart-4.jpg'
            }
            ,
            {
                category: 'new-child-records',
                label: 'New Child Records Created',
                description: 'Simple pie chart',
                iconPath: 'bar-chart-4.jpg'
            }
        ]
    },
    {
        name: 'Indicators',
        type: 'homeDashboard',
        elements: [
            {
                category: 'information-block',
                label: 'Number of Child Clients',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-1.jpg'
            },
            {
                category: 'licenses-info-card',
                label: 'Number of Licenses',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-2.jpg'
            },
            {
                category: 'facilities-info-card',
                label: 'Number of Facilities',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-3.jpg'
            },
            {
                category: 'potential-dup-child-client-info-card',
                label: 'Number of Potential duplicate child clients',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-4.jpg'
            },
            {
                category: 'potential-dup-licenses-info-card',
                label: 'Number of Potential duplicate Licenses',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-5.jpg'
            },
            {
                category: 'potential-dup-facilities-info-card',
                label: 'Number of Potential duplicate Facilities',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-6.jpg'
            }
            // {
            //     category: 'horizontal-bars',
            //     label: 'Numeric bars',
            //     description: 'Numerical indicators for value series',
            //     iconPath: ''
            // },
            // {
            //     category: 'logs-list',
            //     label: 'Activity stream',
            //     description: 'Display any activity stream as a list',
            //     iconPath: ''
            // }
        ]
    }
];

export const Person360_WIDGET_LIBRARY: WidgetLibrary = [
    {
        name: 'Indicators',
        type: 'person360Dashboard',
        elements: [
            {
                category: 'family-relationships',
                label: 'Family Relationships',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            },
            {
                category: 'case',
                label: 'Case',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            },
            {
                category: 'placement',
                label: 'Placements',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            },
            {
                category: 'school',
                label: 'School',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            },
            {
                category: 'eligibility',
                label: 'Eligibility',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            },
            {
                category: 'document',
                label: 'Document',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            },
            {
                category: 'similar-people',
                label: 'Similar People',
                description: 'Text fields describing entity fields',
                iconPath: 'info-card-img.png'
            }
            // {
            //     category: 'horizontal-bars',
            //     label: 'Numeric bars',
            //     description: 'Numerical indicators for value series',
            //     iconPath: ''
            // },
            // {
            //     category: 'logs-list',
            //     label: 'Activity stream',
            //     description: 'Display any activity stream as a list',
            //     iconPath: ''
            // }
        ]
    }
];

export const BUSINESS_GLOSSARY_WIDGET_LIBRARY: WidgetLibrary = [
    {
        name: 'Indicators',
        type: 'glossaryDashboard',
        elements: [
            {
                category: 'functional-area-info-card',
                label: 'Number of Functional Areas',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-functional-areas.png'
            },
            {
                category: 'terms-info-card',
                label: 'Number of Terms',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-terms.png'
            },
            {
                category: 'references-info-card',
                label: 'Number of References',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-references.png'
            },
            {
                category: 'sensitive-data-elements-info-card',
                label: 'Number of Sensitive Data Elements',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-data-elements.png'
            },
            {
                category: 'data-standards-info-card',
                label: 'Number of Data Standards',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-standards.png'
            },
            {
                category: 'legal/compliance-policies-info-card',
                label: 'Number of Legal/Compliance Policies',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-policies.png'
            },
            {
                category: 'data-owners-info-card',
                label: 'Number of Data Owners',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-owners.png'
            },
            {
                category: 'data-stewards-info-card',
                label: 'Number of Data Stewards',
                description: 'Text fields describing glossary fields',
                iconPath: 'glossary-card-stewards.png'
            }
        ]
    }
];