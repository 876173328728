export const DASHBOARD_PATH = 'dashboard';
export const LOGIN_PATH = 'login';
export const VISUALIZATION_PATH = 'visualization';
export const ALERTS_PATH = 'alerts';
export const ADMINISTRATION_PATH = 'administration';
export const DATA_IMPORT_PATH = 'data-import';
export const RECOMMENDATIONS_PATH = 'recommendations';
export const QUERY_EDITOR_PATH = 'query-editor';
export const CATEGORY_ID_PARAM = 'categoryId';
export const QUERY_ID_PARAM = 'queryID';
