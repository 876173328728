import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';

import { removeNullValuesFromParameters } from '../utils/removeNullValuesFromParameters';
import { createGraphObjectMap } from '../utils/createGraphObjectMap';
import { WorkflowDefinition } from '../../types/workflowDefinition';
import { ValueObject } from '../../types/shared';
import { DiagramService } from './base.service';

@Injectable()
export class ModelSaveService extends DiagramService {

    getWorkflowDefinition(): WorkflowDefinition {
        const model = JSON.parse(this.diagram.model.toJson());

        return {
            ...this.getDiagramProperties(model),
            ...this.getNodesData(model),
            ...this.getLinksData(model)
        };
    }

    getLinksInfo() {
        const model = JSON.parse(this.diagram.model.toJson());
        return this.getNodesData(model);
    }

    private getDiagramProperties = ({ modelData }: ValueObject) => ({
        ...modelData,
        dag: {
            ...modelData.dag,
            parameters: removeNullValuesFromParameters(
                modelData.dag.parameters
            )
        }
    })

    private getNodesData = ({ nodeDataArray }: ValueObject) => {
        const filteredNodes = _.map((nodeData: any) => ({
            ...nodeData,
            properties: {
                ...nodeData.properties,
                parameters: removeNullValuesFromParameters(
                    nodeData.properties.parameters
                )
            }
        }))(nodeDataArray);

        return {
            nodes: createGraphObjectMap(filteredNodes)
        };
    }

    private getLinksData = ({ linkDataArray }: ValueObject) => ({
        links: createGraphObjectMap(linkDataArray)
    })

}
