import * as go from 'gojs';
import { Injectable } from '@angular/core';

import { DiagramService } from './base.service';
import { Category } from '../types';

@Injectable()
export class TaskGroupLocationService extends DiagramService {

    findGroupAtLocation(location: go.Point): go.Group {
      const part = this.diagram.findPartAt(location);
      if (!part || part.category !== Category.TaskGroup) {
        return;
      }

      return part as go.Group;
    }

}
