import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xfusiontech-entity-context-menu',
  templateUrl: './entity-context-menu.component.html',
  styleUrls: ['./entity-context-menu.component.scss']
})
export class EntityContextMenuComponent implements OnInit {
  @Input() menuData: any;
  @Input() matMenuTriggerFor: string;
  constructor() { }

  ngOnInit() {
  }

}
