import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'udp-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: [
        '../field/form-field.component.scss',
        './search-box.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent {

    @Input()
    showAdvancedButton = true;

    @Input()
    showQueryButton = false;

    @Input()
    control: FormControl;

    @Output()
    advanceClick: EventEmitter<MouseEvent> = new EventEmitter();

    @Output()
    queryClick: EventEmitter<MouseEvent> = new EventEmitter();

    @Output()
    searchIconClick: EventEmitter<MouseEvent> = new EventEmitter();

}
