import * as _ from 'lodash';
import { DATASETS_MENU } from './view-dataset.constants';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-view-dataset',
  templateUrl: './view-dataset.component.html',
  styleUrls: ['./view-dataset.component.scss']
})
export class ViewDatasetComponent implements OnInit {

  /**
   * @public
   * @type: {any}
   * @input
   */
  @Input()
  public selected: any;

  /**
   * @public
   * @type: {any}
   */
   public selectedItem: any;

  /**
   * @public
   * @type: {FormGroup}
   */
  public datasetForm: FormGroup;

  /**
   * @public
   * @type: {any[]}
   */
  public datasetMenu: any[] = [];

  /**
   * @public
   * @type: {any[]}
   */
  public dataCategoryList: any[] = [];

  /**
   * @public
   * @type: {boolean}
   */
  public displayFullScreen: boolean = false;

  /**
   * @public
   * @type: {EventEmitter<boolean>}
   */
  @Output()
  public back: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @constructor
   * @param: {fb<FormBuilder>}
   */
  constructor(private fb: FormBuilder) {
    this.datasetMenu = DATASETS_MENU;
    this.selectedItem = _.find(DATASETS_MENU, {
      isActive: true
    });
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    const cat: any = this.selected.category;
    const sub: any = this.selected.subCategory;

    this.datasetForm = this.fb.group({
      datasetCat: new FormControl(
        cat.dataSetID, [
          Validators.required
        ]
      ),
      datasetDesc: new FormControl(
        sub.dataSetDesc, [
          Validators.required
        ]
      ),
      datasetName: new FormControl(
        sub.dataSourceName, [
          Validators.required
        ]
      )
    });
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * that patches the form values based
   * on user's selection.
   */
  private patchValues(): void {
    const cat: any = this.selected.category;
    const sub: any = this.selected.subCategory;

    this.datasetForm.patchValue({
      datasetName: sub.dataSourceName,
      datasetCat: cat.dataSetID,
      datasetDesc: sub.dataSetDesc
    });
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * that sets the active tab.
   */
  public onNavItemSelect(item: any): void {
    this.selectedItem = item;
  }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * resets the dataset view.
   */
  public cancel(): void {
   this.selected = null;
   this.datasetMenu = [];
   this.selectedItem = null;
   this.datasetForm.reset();
   this.dataCategoryList = [];

   this.back.emit(true);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnChanges(): void {
    if (!!this.selected) {
      this.dataCategoryList = [];
      const cat: any = this.selected.category;

      this.dataCategoryList.push({
        value: cat.dataSetID,
        name: cat.dataSetName
      });

      // patch values only if the
      // form is available
      if (!!this.datasetForm) {
        this.patchValues();
      }
    }
  }
}
