import { ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';

import { EntityTextIndicatorWrapperComponent } from './entity-text-indicator/entity-text-indicator-wrapper.component';
import { HorizontalBarsIndicatorWrapperComponent } from './horizontal-bars-indicator/horizontal-bars-indicator-wrapper.component';
import { BarChartWrapperComponent } from './bar-chart/bar-chart-wrapper.component';
import { LogsListWrapperComponent } from './logs-list/logs-list-wrapper.component';
import {StateHeatmapComponent} from './state-heatmap/state-heatmap.component';
import { WidgetWrapperComponent } from './widget-wrapper.component';
import { WidgetCategory, WidgetData } from '../widget.types';
import {InformationBlockComponent} from './information-block/information-block.component';
import {BarGraphImgWrapperComponent} from './bar-graph-img/bar-graph-img-wrapper.component';

const categoryComponentMap: Record<
    WidgetCategory,
    Type<WidgetWrapperComponent<any>>
> = {
    'entity-text': EntityTextIndicatorWrapperComponent,
    'horizontal-bars': HorizontalBarsIndicatorWrapperComponent,
    'bar-chart': BarChartWrapperComponent,
    'dupChildRecordsMerged': BarChartWrapperComponent,
    'childPlacementAdoption': BarChartWrapperComponent,
    'logs-list': LogsListWrapperComponent,
    'heatmap' : StateHeatmapComponent,
    'heatmapChildFacility' : StateHeatmapComponent,
    'information-block' : InformationBlockComponent,
    'licenses-info-card' : InformationBlockComponent,
    'facilities-info-card' : InformationBlockComponent,
    'potential-dup-child-client-info-card' : InformationBlockComponent,
    'potential-dup-licenses-info-card' : InformationBlockComponent,
    'potential-dup-facilities-info-card' : InformationBlockComponent,
    'new-child-records' :BarGraphImgWrapperComponent,
    'child-identification-request' :BarGraphImgWrapperComponent,
    'family-relationships': EntityTextIndicatorWrapperComponent,
    'case': EntityTextIndicatorWrapperComponent,
    'placement': EntityTextIndicatorWrapperComponent,
    'school': EntityTextIndicatorWrapperComponent,
    'eligibility': EntityTextIndicatorWrapperComponent,
    'document': EntityTextIndicatorWrapperComponent,
    'similar-people': EntityTextIndicatorWrapperComponent,
    'functional-area-info-card' : InformationBlockComponent,
    'terms-info-card' : InformationBlockComponent,
    'references-info-card' : InformationBlockComponent,
    'sensitive-data-elements-info-card' : InformationBlockComponent,
    'data-standards-info-card' : InformationBlockComponent,
    'legal/compliance-policies-info-card' : InformationBlockComponent,
    'data-owners-info-card' : InformationBlockComponent,
    'data-stewards-info-card' : InformationBlockComponent,
};

@Injectable()
export class WidgetWrapperService {

    constructor(
        private factoryResolver: ComponentFactoryResolver
    ) {}

    getComponentFactory(
        category: string
    ): ComponentFactory<WidgetWrapperComponent<WidgetData>> {
        const componentType = categoryComponentMap[category];
        if (!componentType) {
            throw new Error(
                `No widget wrapper found for category "${category}"`
            );
        }

        const factory = this.factoryResolver.resolveComponentFactory
            <WidgetWrapperComponent<WidgetData>>(componentType);
        return factory;
    }

}
