import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class TaskCatalogPropertyService {
    constructor(
        private apiService: ApiService,
    ) { }

    getPropertyForOperatorType(type: string): Observable<object> {
        return this.apiService.fetchParametersOfSelectedNode(type);
    }
}
