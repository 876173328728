import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'udp-search-text',
    templateUrl: './search-text.component.html'
})
export class SearchTextComponent implements OnChanges {

    @Input() text: string;
    @Input() searchTerm: string;
    @Input() caseSensitive: boolean;

    searchTermFound: boolean;
    beforePart: string;
    searchTermPart: string;
    afterPart: string;

    ngOnChanges() {
        if (!this.searchTerm || !this.text) {
            this.searchTermFound = false;
            return;
        }

        const text = this.caseSensitive ? this.text : this.text.toString().toLowerCase();
        const searchTerm = this.caseSensitive ? this.searchTerm : this.searchTerm.toString().toLowerCase();

        const index = text.toString().indexOf(searchTerm);
        if (index < 0) {
            this.searchTermFound = false;
            return;
        }

        this.searchTermFound = true;
        this.beforePart = this.text.toString().substr(0, index);
        this.searchTermPart = this.text.toString().substr(index, searchTerm.length);
        this.afterPart = this.text.toString().substr(index + searchTerm.length);
    }
}
