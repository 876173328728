import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DiagramModel, GraphConfiguration } from '@xfusiontech/data-visualizer';

export interface VisualizationDialogParams {
    model: DiagramModel;
    configuration: GraphConfiguration;
}

@Component({
    selector: 'igv-visualization-dialog',
    templateUrl: './visualization-dialog.component.html',
    styleUrls: ['./visualization-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VisualizationDialogComponent {
    model: DiagramModel;
    configuration: GraphConfiguration;

    constructor(
        @Inject(MAT_DIALOG_DATA) params: VisualizationDialogParams | null,
        public dialogRef: MatDialogRef<VisualizationDialogComponent>
    ) {
        if (params) {
            this.model = params.model;
            this.configuration = params.configuration;
        }
    }
}
