import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EditorComponent } from './components/editor/editor.component';
import { WORKFLOW_EDITOR_PATH, WORKFLOW_EDITOR_ID_PATHS } from './consts/routing';
import { CanDeactivateOnChangesGuard } from './can-deactivate-on-changes.guard';

const routes: Routes = [
    {
        path: WORKFLOW_EDITOR_PATH,
        pathMatch: 'full',
        component: EditorComponent,
        canDeactivate: [CanDeactivateOnChangesGuard]
    },
    {
        path: WORKFLOW_EDITOR_ID_PATHS,
        component: EditorComponent,
        canDeactivate: [CanDeactivateOnChangesGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    providers: [
        CanDeactivateOnChangesGuard,
    ],
    exports: [
        RouterModule
    ]
})
export class WorkflowEditorRoutingModule { }
