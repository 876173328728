/**
 * @author: Naga
 * @constant
 * @exports
 */
export const DATASETS_MENU: any[] = [
  {
    menuName: 'Data Source',
    isActive: true,
    breadCrumbName: 'Data Source',
    id: '1'
  },
  {
    menuName: 'Dataset Schema',
    breadCrumbName: 'Dataset Schema',
    isActive: false,
    id: '2'
  }
];
