import { LoginPageService } from './login-page.service';
import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'igv-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit {
    errors$: Observable<string>;

    constructor(private service: LoginPageService) {}

    ngOnInit() {
        this.errors$ = this.service.getErrors();
    }

    onLogin({ email, password }: { email: string; password: string }) {
        this.service.login(email, password);
    }
}
