import * as _ from 'lodash/fp';
import * as go from 'gojs';
import { Observable, Subject, merge } from 'rxjs';
import {
  debounceTime,
  map,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

import { DEFAULT_NODE_ICON_PATH } from '../../consts/icons';

import { mapFormFieldToFormControl } from './utils/mapFormFieldToFormControl';
import { mapParameterToFormField } from './utils/mapParameterToFormField';
import { FormField, ParameterType } from '../../types/form';
import { camelCaseToLabel } from '../../utils/camelCaseToLabel';
import { isNodeEditorValidCategory } from './utils/isNodeEditorValidCategory';

import { updateModelData } from './utils/updateModelData';
import { updateNodeData } from './utils/updateNodeData';

import { SelectionService } from '@workflow-editor/gojs/services/selection.service';

import { NodeViewModel, PropertiesEditorModel } from './properties-editor.types';
import { ModelService } from '@workflow-editor/gojs/services/model.service';
import { UndoService } from '@workflow-editor/gojs/services/undo.service';
import { NodeValidationService } from '@workflow-editor/services/node-validation.service';
import { WorkflowValidationService } from '@workflow-editor/services/workflow-validation.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';

@Injectable()
export class PropertiesEditorService {

  private model = new Subject<PropertiesEditorModel>();
  private nodeView = new Subject<NodeViewModel>();

  constructor(
    private formBuilder: FormBuilder,
    private modelService: ModelService,
    private selectionService: SelectionService,
    private udpTaskConfigService: UdpTaskConfigService,
    private nodeValidationService: NodeValidationService,
    private workflowValidationService: WorkflowValidationService,
    undoRedoService: UndoService
  ) {
    merge(
      selectionService.selection,
      undoRedoService.undoRedoEvent$
        .pipe(
          withLatestFrom(selectionService.selection),
          map(([, selection]) => selection)
        )
    ).subscribe(
      (selection) => this.onSelectedElementChanged(selection)
    );
    setTimeout(() => this.selectionService.refreshSelection());
  }

  getModel(): Observable<PropertiesEditorModel> {
    return this.model;
  }

  getNodeViewModel(): Observable<NodeViewModel> {
    return this.nodeView;
  }

  private onSelectedElementChanged(selection: go.Set<go.Part>) {
    const first = selection.first();
    if (!first) {
      this.updateDiagramFormModel();
      return;
    }

    if (
      selection.count === 1
      && isNodeEditorValidCategory(first.category)
    ) {
      this.updateNodeFormModel(first);
      return;
    }

    this.model.next({
      form: null,
      formFields: null
    });
  }

  private updateDiagramFormModel() {
    const { dag } = this.modelService.getModelData();
    this.updateFormModel([
      {
        id: 'name',
        label: 'Workflow definition name',
        type: 'text',
        default: '',
        value: dag.name,
        paramType: ParameterType.String
      },
      ..._.map(mapParameterToFormField)(dag.parameters)
    ]);

    this.nodeView.next(null);
  }

  private updateNodeFormModel(selection: go.Part) {
    const {
      type,
      imagePath,
      properties: { name, parameters, description }
    } = selection.data;

    this.updateFormModel([
      {
        id: 'name',
        label: 'Name',
        type: 'text',
        default: '',
        paramType: ParameterType.String,
        value: name
      },
      ..._.map(mapParameterToFormField)(parameters)
    ]);

    this.nodeView.next({
      type: camelCaseToLabel(type),
      description,
      iconPath: imagePath || DEFAULT_NODE_ICON_PATH
    });
  }

  private updateFormModel(formFields: FormField[]) {

    // Assign task id as node id
    this.assignDefaultTaskId(formFields);

    const form = this.formBuilder.group({});
    _.forEach((field: FormField) => form.setControl(
      field.id,
      mapFormFieldToFormControl(field)
    ))(formFields);
    this.subscribeFormValueChanges(form);

    form.updateValueAndValidity();
    this.model.next({
      formFields,
      form
    });
  }

  /**
   * The task id in the UDP operator configuration will always be node id from the operator
   * and it will be non editable
   */
  private assignDefaultTaskId(formFields) {

    for (const formField of formFields) {
      if (formField.id === 'task_id') {
        const nodeId = this.selectionService.getCurrentSelection().first().key;
        const dagWorkFlowJSON = this.udpTaskConfigService.getLatestJSONConfig();
        if (!dagWorkFlowJSON) {
          formField.value = '';
          return;
        }
        let nodes = dagWorkFlowJSON.nodes;
        let currentNode;
        if (nodes && nodes[nodeId]) {
          currentNode = nodes[nodeId];
        } else {
          nodes = this.modelService.getNodes();
          const modelCurrentNode = nodes.filter(node => node.id === nodeId);
          if (modelCurrentNode && modelCurrentNode.length > 0) {
            currentNode = modelCurrentNode[0];
          }
        }
        if (currentNode) {
          const taskId = this.fetchTaskIdFromExistingData(currentNode);

          if (taskId && taskId !== '') {
            formField.value = taskId;
            break;
          } else {
            const name = currentNode && currentNode.properties && currentNode.properties.name ? currentNode.properties.name : nodeId;
            const time = Math.floor(new Date().getTime() / 1000);
            const id = (`t_${name}_${time}`).replace('-', '_');
            formField.value = id;
            break;
          }
        }

      }
      if (formField.id === 'name') {
        if (formField.value) {
          formField.value = (formField.value).replace('-', '_');
        }
      }
    }
  }

  private fetchTaskIdFromExistingData(currentNode) {
    const params = currentNode.properties ? currentNode.properties.parameters : null;
    if (params !== null && params !== undefined && params.length > 0) {
      const taskId = params.find(param => {
        return param.id === 'task_id';
      }).value;
      if (taskId !== undefined && taskId !== null && taskId !== '') {
        return taskId;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  private subscribeFormValueChanges(form: FormGroup) {
    form.valueChanges
      .pipe(
        debounceTime(250),
        takeUntil(this.selectionService.selection),
      )
      .subscribe(() => {
        const nextValue = form.value;
        const selected = this.selectionService
          .getCurrentSelection()
          .first();

        if (selected) {
          const nextNodeData = updateNodeData(
            selected.data,
            nextValue
          );
          this.modelService.updateNodeData(
            selected.key,
            nextNodeData
          );
          const nodes = this.modelService.getNodes();
          this.nodeValidationService.checkNodesValidity(
            [nextNodeData], nodes
          );
        } else {
          const nextModelData = updateModelData(
            this.modelService.getModelData(),
            nextValue
          );
          this.modelService.updateModelData(nextModelData);
          this.workflowValidationService
            .checkWorkflowValidation(nextModelData);
        }
      });
  }

}
