import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
    dataAssetType,
    Query,
    Visualization,
} from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-share-by-email-dialog',
    templateUrl: './share-by-email-dialog.component.html',
    styleUrls: ['./share-by-email-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareByEmailDialogComponent implements OnInit {
    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public params: { type: dataAssetType; entity: Visualization | Query },
        private dialogRef: MatDialogRef<ShareByEmailDialogComponent>,
        private fb: FormBuilder
    ) {}

    get assetName() {
        return this.params.type === 'VISUALIZATION'
            ? (this.params.entity as Visualization).visualizationName
            : (this.params.entity as Query).queryName;
    }

    get attachment() {
        return this.params.type === 'VISUALIZATION'
            ? (this.params.entity as Visualization).visualizationThumbnailImage
            : null;
    }

    ngOnInit() {
        this.initializeFormGroup();
    }

    private initializeFormGroup() {
        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    onSubmitClick() {
        this.dialogRef.close(this.form.get('email').value);
    }
}
