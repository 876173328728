import {
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import * as _ from 'lodash/fp';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user/user.service';
import { HasPermissionService } from './has-permission.service';
/**
 * Usage:
 * <div *udpHasPermission="['can_write', 'can_read']"> ... </div>   <- AND OPERATOR is default
 * <div *udpHasPermission="['can_write', 'can_read']; op 'OR'"> ... </div>
 */

@Directive({
    selector: '[udpHasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
    private currentUserPermissions: string[];
    private permissions: string[] = [];
    private logicalOp = 'AND';
    private isHidden = true;

    private onDestroy$ = new Subject();

    @Input()
    set udpHasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    @Input()
    set udpHasPermissionOp(value) {
        this.logicalOp = value;
        this.updateView();
    }

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userService: UserService,
        private hasPermissionService: HasPermissionService
    ) {}

    ngOnInit() {
        this.userService.userPermissions
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(permissions => {
                this.currentUserPermissions = permissions;
                this.updateView();
            });
    }

    private updateView() {
        const hasPermissions = this.hasPermissionService.checkPermission(
            this.currentUserPermissions,
            this.permissions,
            this.logicalOp
        );
        if (this.permissions == null || hasPermissions) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
