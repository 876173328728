import { Component, Input, OnInit } from '@angular/core';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'xft-data-tools',
  templateUrl: './data-tools.component.html',
  styleUrls: ['./data-tools.component.scss']
})
export class DataToolsComponent implements OnInit {

  /**
   * @public
   * @type: {'CREATE' | 'BLEND'}
   * @input
   */
  @Input() public mode: any;
  @Input() public currentTaskID: any;
  @Input() public currentOperatorCode: any;
  @Input() public nodes: any;
  @Input() public workflowDef: any;
  @Input() public currentFileConfig: any;
  @Input() public operatorsConfigs: any;
  @Input() public previousTaskIDs: any;

  userId: string;
  customerId: string;

  showDataTools = false;

  /**
   * @constructor
   */
  constructor(private udpTaskConfigService: UdpTaskConfigService) { }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    // this.mode = 'QUERY';  // temporary added for testing
    if (this.udpTaskConfigService.getCustomerId() !== '' && this.udpTaskConfigService.getUserId() !== '') {
      this.userId = this.udpTaskConfigService.getUserId();
      this.customerId = this.udpTaskConfigService.getCustomerId();
      this.showDataTools = true;
    }
  }
}
