import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule, MatMenuModule } from '@angular/material';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
    ButtonModule,
    ExpansionPanelModule,
    FormFieldModule,
    getIconRegistry,
    HasPermissionModule,
    IconButtonModule,
    IconModule,
} from '@xfusiontech/shared';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DashboardNavigationVisualizationComponent } from './components/navigation-visualization/navigation-visualizations.component';
import { NavigationFilterPipe } from './components/navigation/navigation-filter.pipe';
import { DashboardNavigationComponent } from './components/navigation/navigation.component';
import { VisualizationContextMenuComponent } from './components/visualization-context-menu/visualization-context-menu.component';
import { VisualizationTileComponent } from './components/visualization-tile/visualization-tile.component';
import { DashboardComponent } from './dashboard.component';

const customIcons = [
    'query_search',
    'query_editor',
    'half_circle',
    'shared_by_me',
    'shared_with_me',
    'query',
    'new_visualization',
];

@NgModule({
    declarations: [
        DashboardComponent,
        VisualizationTileComponent,
        DashboardNavigationComponent,
        NavigationFilterPipe,
        DashboardNavigationVisualizationComponent,
        VisualizationContextMenuComponent,
    ],
    imports: [
        CommonModule,
        IconModule,
        ButtonModule,
        IconButtonModule,
        MatMenuModule,
        RouterModule,
        FormFieldModule,
        PerfectScrollbarModule,
        ExpansionPanelModule,
        MatIconModule,
        DragDropModule,
        MatDividerModule,
        HasPermissionModule,
    ],
    exports: [DashboardComponent, DashboardNavigationComponent],
})
export class DashboardModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace('custom', iconsPath)(customIcons);
    }
}
