import * as go from 'gojs';

import { Port } from '../../../../types/workflowDefinition';
import { portDot } from './portDot';

const $ = go.GraphObject.make;

export const port = (portId: Port) => {
  const alignment = portId === Port.Left
    ? go.Spot.Left
    : go.Spot.Right;

  return $(
    go.Panel,
    go.Panel.Spot,
    {
      alignmentFocus: go.Spot.Center,
      alignment,
    },
    portDot({
      portId,
      fromSpot: alignment,
      toSpot: alignment
    })
  );
}
