import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { DataAssetCategory, Visualization } from '@xfusiontech/data-visualizer';
@Component({
    selector: 'igv-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
    @Input()
    dataAssetCategories: DataAssetCategory[];

    @Input()
    selectedCategory: number;

    @Output()
    visualizationSelectionChange = new EventEmitter<Visualization>();

    searchItem: string;
    sidebarExpanded = true;

    categoriesTrackBy = (_, category: DataAssetCategory) =>
        category.dataAssetCategoryID;
    visualizationTrackBy = (_, visualization: Visualization) =>
        visualization.visualizationID;

    onValueChanges(value: string) {
        this.searchItem = value;
    }

    toggleSidebar() {
        this.sidebarExpanded = !this.sidebarExpanded;
    }
}
