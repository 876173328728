import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { LinkLoadConfiguration } from "./link-n-load-data-integration-model";
import linkandloaddata from "./link_and_load_data.json";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { IntegrationWorkflowService } from 'src/app/workflow-editor/integration-workflow.service';
import * as _ from "lodash";
// import { ErrorHandlingService } from 'src/app/service/global-services/error-handling.service';

@Component({
  selector: "app-link-n-load-data-integration",
  templateUrl: "./link-n-load-data-integration.component.html",
  styleUrls: ["./link-n-load-data-integration.component.scss"],
})
export class LinkNLoadDataIntegrationComponent implements OnInit {
  linkAndLoadForm: FormGroup;
  linkAndLoaddataModel: LinkLoadConfiguration;
  entityNameDropdownOptions: any[] = [];
  entityClassDropdownOptions: any[] = [];
  entityClassAttributes: any[] = [];
  entityNameData = [];
  entityClassData = [];
  textdata = "Select";
  popuptitle = "";
  popupbutton = "";
  partentDataForSubmit: any;

  @Input() parentData: any;
  @Input() linkAdnLoadData: any;
  @Input() dropDownData: any[];
  @Input() entityMapping: any;

  public linkAndLoadDataObj: any;
  selectedEntityName: any;
  viewData = [];
  editArray = [];
  isEditPopUp = false;

  config = {
    displayKey: "description", //if objects array passed which key to be displayed defaults to description
    // search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "", // text to be displayed when no item is selected defaults to Select,
    // customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 500, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    //noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    // searchPlaceholder:'Search' // label thats displayed in search input,
    // searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    //clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
  };

  constructor(
    private _fb: FormBuilder,
    private appoloClient: Apollo,
    private _workFlowService: IntegrationWorkflowService,
    // private _errorHandlingService: ErrorHandlingService
  ) {
    this.linkAndLoadDataObj = linkandloaddata["SubjectRelation"];
    this.linkAndLoaddataModel = new LinkLoadConfiguration();
  }
  @ViewChild("CloseWorkFlow3", { static: false }) CloseWorkFlow3;

  ngOnInit() {
    this.initialiseForm();
  }

  initialiseForm() {
    this.entityClassDropdownOptions = this.entityClassData;
    this.linkAndLoadForm = this._fb.group({
      entityName: ["", Validators.required],
      entityClass: [""],
    });
    this.resetAttributes();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.viewData = [];
    if (this.linkAdnLoadData && this.linkAdnLoadData.length > 0) {
      this.linkAdnLoadData.map((e1) => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "linkLoad") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              JSON.parse(e1.IntegrationWorkflowComponentVersion).map((e2) => {
                if (e2 && e2 !== null) {
                  let data = [];
                  data = e2;
                  data["id"] = e1.WorkflowComponentTypes.id;
                  data["parentid"] = e1.IntegrationWorkflowID;
                  this.viewData.push(data);
                }
              });
            }
          }
        }
      });
      this.getDropDownData();
    }
  }
  getDropDownData() {
    this.entityMapping.forEach((item) => {
      this.entityNameData.push({
        id: item.targetEntity.displayMappingName,
        description: item.targetEntity.displayMappingName,
      });

      this.entityClassData.push({
        id: item.targetEntity.targetEntityClass.value,
        description: item.targetEntity.targetEntityClass.value,
      });
    });

    this.entityNameData.push({
      id: "Select",
      description: "Select",
    });

    this.entityNameDropdownOptions = this.entityNameData;
    this.entityNameDropdownOptions = _.uniq(this.entityNameDropdownOptions);
    this.entityClassDropdownOptions = this.entityClassData;
    this.entityClassDropdownOptions = _.uniq(this.entityClassDropdownOptions);
  }

  entityNameChanged(event) {
    if (event.value) {
      this.entityMapping.forEach((item) => {
        if (item.targetEntity.displayMappingName == event.value.description) {
          this.linkAndLoadForm.patchValue({
            entityClass: item.targetEntity.targetEntityClass.value,
          });
          this.linkAndLoadForm.updateValueAndValidity();
          this.entityClassChanged(item.targetEntity.targetEntityClass.value);
        }
      });
    }
  }

  entityNameFilterData(event) {
    this.entityNameDropdownOptions = this.entityNameData.filter((i) =>
      i.description.toLowerCase().includes(event.toLowerCase())
    );
  }

  entityClassChanged(event) {
    this.linkAndLoadDataObj.map((e) => {
      if (e.IdentificationSubject === event) {
        this.entityClassAttributes = e.LinkAndLoadAction;
        this.entityClassAttributes.map((e1) => {
          e1.options.map((e2) => {
            this.linkAndLoadForm.addControl(
              e1.title + "^" + e2,
              new FormControl("")
            );
          });
        });
      }
    });
  }

  entityClassFilterData(event) {
    // this.entityClassDropdownOptions = this.entityClassData.filter(i =>
    //   i.description.toLowerCase().includes(event.toLowerCase())
    // );
  }

  //Submit the link and load form
  onSubmitLinkAndLoad() {
    let identificationsArray = [];
    let samekey = "";
    let finalevalue = "";
    for (let key in this.linkAndLoadForm.value) {
      if (key !== "entityName" && key !== "entityClass") {
        var splitted = key.split("^");

        if (this.linkAndLoadForm.value[key] === true) {
          if (samekey === _.get(linkandloaddata["ActionMap"], splitted[0])) {
            finalevalue =
              finalevalue +
              "," +
              _.get(linkandloaddata["OptionMap"], splitted[1]);
          } else {
            samekey = _.get(linkandloaddata["ActionMap"], splitted[0]);
            if (finalevalue === "") {
              finalevalue =
                finalevalue +
                _.get(linkandloaddata["ActionMap"], splitted[0]) +
                "^" +
                _.get(linkandloaddata["OptionMap"], splitted[1]);
            } else {
              finalevalue =
                finalevalue +
                "#" +
                _.get(linkandloaddata["ActionMap"], splitted[0]) +
                "^" +
                _.get(linkandloaddata["OptionMap"], splitted[1]);
            }
          }
        }
      }
    }
    var selectedData = finalevalue.split("#");
    selectedData.map((e) => {
      var selectedData1 = e.split("^");
      let IdentificationActionsArray = [];

      var unsortedSelectedData = selectedData1[1].split(",");
      var sortedSelectedData = _.sortBy(unsortedSelectedData, function (item) {
        return linkandloaddata["OrderMap"].indexOf(item);
      });

      sortedSelectedData.map((e) => IdentificationActionsArray.push(e));
      identificationsArray.push({
        IdentificationName: selectedData1[0],
        IdentificationActions: IdentificationActionsArray,
      });
    });

    let formdata: any;
    //converyting Submitted form data into API format
    formdata = {
      integration_pid: this.partentDataForSubmit.SourceDataTypeCode,
      entityName: this.linkAndLoadForm.value.entityName.description,
      entityClass: this.linkAndLoadForm.value.entityClass,
      Identifications: identificationsArray,
    };

    if (this.isEditPopUp === true) {
      this.viewData.filter((e, index) => {
        if (JSON.stringify(e) === JSON.stringify(this.editArray)) {
          this.viewData.splice(index, 1);
        }
      });
    }

    this.viewData.forEach((data) => {
      if (
        data.entityName == this.linkAndLoadForm.value.entityName.description
      ) {
        this.viewData = _.reject(this.viewData, (n) => {
          return (
            n.entityName == this.linkAndLoadForm.value.entityName.description
          );
        });
      }
    });

    this.viewData.push(formdata);

    if (this.partentDataForSubmit !== "") {
      let saveObj = {
        Source: 2,
        SourceDataTypeCode: this.partentDataForSubmit.sourceDataTypeCode,
        Status: this.partentDataForSubmit.status,
        TargetDataTypeCode: this.partentDataForSubmit.targetDataTypeCode,
        WorkflowID: this.partentDataForSubmit.workflowID,
        WorkflowComponentTypeCode: "linkLoad",
        IntegrationWorkflowComponentName: "Link and Load",
        IntegrationWorkflowComponentStatus: "Active",
        WorkflowComponent: JSON.stringify(this.viewData),
      };
      this._workFlowService
        .updateIntegrationWorkflowInnerModule(saveObj)
        .subscribe((result) => {
          this.CloseWorkFlow3.nativeElement.click();
          this.entityClassAttributes = [];
          this.linkAndLoadForm.reset();
          if(this.popuptitle === "Add") {
            //this._errorHandlingService.errorSuccessSnack("13",true);
          } else if(this.popuptitle === "Edit") {
           // this._errorHandlingService.errorSuccessSnack("15",true);
          }
        },
        (error) => {                              //Error callback
          console.error('error caught in component')
          //this.errorSuccessSnack('Error occured while creating data pipeline. Please try again.', false);
          if(this.popuptitle === "Add") {
            //this._errorHandlingService.errorSuccessSnack("12",false);
          } else if(this.popuptitle === "Edit") {
            //this._errorHandlingService.errorSuccessSnack("14",false);
          }
          //throw error;   //You can also throw the error to a global error handler
        });
    }

    this.isEditPopUp = false;
  }

  resetAttributes() {
    this.entityClassAttributes = [];
  }

  paretAddDetails(data) {
    this.partentDataForSubmit = "";
    this.partentDataForSubmit = data;
    this.popuptitle = "Add";
    this.popupbutton = "Save";
    this.entityNameData = [];
    this.initialiseForm();
    this.getDropDownData();
    this.entityNameDropdownOptions.forEach((item) => {
      if (item.description == "Select") {
        this.selectedEntityName = item;
      }
    });
  }

  paretEditDetails(data) {
    this.partentDataForSubmit = "";
    this.partentDataForSubmit = data;
    this.popuptitle = "Edit";
    this.popupbutton = "Save";
    this.entityNameData = [];
    this.getDropDownData();
  }

  extractChildren(obj) {
    let keysArray = Object.keys(obj);
    keysArray = keysArray.filter((e) => e !== "integration");
    return keysArray;
  }

  editLinkAndLoad(editdata) {
    this.partentDataForSubmit = "";
    this.partentDataForSubmit = this.parentData;
    this.popuptitle = "Edit";
    this.popupbutton = "Update";
    this.editArray = [];
    this.entityClassAttributes = [];

    if (this.linkAdnLoadData && this.linkAdnLoadData.length > 0) {
      this.linkAdnLoadData.map((e1) => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "linkLoad") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              JSON.parse(e1.IntegrationWorkflowComponentVersion).map(
                (e2, index) => {
                  if (e2 && e2 !== null) {
                    if (index === editdata) {
                      let data = [];
                      data = e2;
                      data["id"] = e1.WorkflowComponentTypes.id;
                      data["parentid"] = e1.IntegrationWorkflowID;
                      this.editArray = data;
                    }
                  }
                }
              );
            }
          }
        }
      });
    }

    let setData = {};
    setData = {
      entityName: {
        id: editdata,
        description: this.editArray["entityName"],
      },
      entityClass: this.editArray["entityClass"],
    };

    this.entityClassChanged(this.editArray["entityClass"]);
    this.entityClassAttributes.map((e1) => {
      let avail = this.editArray["Identifications"].find(
        (x) =>
          x.IdentificationName === _.get(linkandloaddata["ActionMap"], e1.title)
      );
      if (avail) {
        e1.options.map((e2) => {
          var target = avail.IdentificationActions.find(function (temp) {
            temp = _.findKey(linkandloaddata["OptionMap"], function (o) {
              return o === temp;
            });
            return temp == e2;
          });
          if (target) {
            setData[e1.title + "^" + e2] = true;
          } else {
            setData[e1.title + "^" + e2] = false;
          }
        });
      } else {
        e1.options.map((e2) => {
          setData[e1.title + "^" + e2] = false;
        });
      }
    });

    let entityClass = this.linkAndLoadForm.setValue(setData);

    this.isEditPopUp = true;
  }

  deleteLinkAndLoad(deleteid) {
    this.editArray = [];
    if (this.linkAdnLoadData && this.linkAdnLoadData.length > 0) {
      this.linkAdnLoadData.map((e1) => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "linkLoad") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              JSON.parse(e1.IntegrationWorkflowComponentVersion).map(
                (e2, index) => {
                  if (e2 && e2 !== null) {
                    if (index === deleteid) {
                      let data = [];
                      data = e2;
                      data["id"] = e1.WorkflowComponentTypes.id;
                      data["parentid"] = e1.IntegrationWorkflowID;
                      this.editArray = data;
                    }
                  }
                }
              );
            }
          }
        }
      });
    }

    this.viewData.filter((e, index) => {
      if (JSON.stringify(e) === JSON.stringify(this.editArray)) {
        this.viewData.splice(index, 1);
      }
    });

    let saveObj = {
      Source: 2,
      SourceDataTypeCode: this.parentData.sourceDataTypeCode,
      Status: this.parentData.status,
      TargetDataTypeCode: this.parentData.targetDataTypeCode,
      WorkflowID: this.parentData.workflowID,
      WorkflowComponentTypeCode: "linkLoad",
      IntegrationWorkflowComponentName: "Link and Load",
      IntegrationWorkflowComponentStatus: "Active",
      WorkflowComponent: JSON.stringify(this.viewData),
    };
    this._workFlowService
      .updateIntegrationWorkflowInnerModule(saveObj)
      .subscribe((result) => {
       // this._errorHandlingService.errorSuccessSnack("17",true);
      },
      (error) => {                              //Error callback
        console.error('error caught in component')
        //this.errorSuccessSnack('Error occured while creating data pipeline. Please try again.', false);
         // this._errorHandlingService.errorSuccessSnack("16",false);

        //throw error;   //You can also throw the error to a global error handler
      });
  }
}
