import * as _ from 'lodash/fp';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { OpenFileSettings } from './open-file.types';

@Injectable()
export class OpenFileService {

    private static INPUT_ID = 'XAQUA_UI_FILE_READER';
    private static DEFAULT_SETTINGS = {
        multiple: true,
        fileTypes: ['application/json']
    };

    openFile(settings?: OpenFileSettings): Observable<File[]> {
        const result = new Subject<File[]>();

        const input = this.createInput(settings);
        const readfile = (event) => {
            const files = event.target.files as File[];
            if (!files) {
                result.next([]);
            }

            result.next(files);
        };
        input.onchange = readfile;
        input.click();

        return result.pipe(
            take(1),
            tap(() => this.removeInput(input))
        );
    }

    readAsText(...files: File[]): Observable<string[]> {
        if (!files.length) {
            return of([]);
        }

        const result = _.map((file: File) => {
            const content = new Subject<string>();

            const reader = new FileReader();
            reader.onload = (e: any) => {
                content.next(e.target.result);
                content.complete();
            };
            reader.readAsText(file);

            return content;
        })(files);

        return forkJoin(result);
    }

    private createInput(userSettings: OpenFileSettings) {
        const currentInput = document.getElementById(
            OpenFileService.INPUT_ID
        ) as HTMLInputElement;
        if (currentInput) {
            return this.applySettings(currentInput, userSettings);
        }

        const input = document.createElement('input');
        input.id = OpenFileService.INPUT_ID;
        input.type = 'file';
        input.style.display = 'none';

        document.body.appendChild(input);

        return this.applySettings(input, userSettings);
    }

    private applySettings(input: HTMLInputElement, userSettings: OpenFileSettings) {
        const settings = _.defaults(
            OpenFileService.DEFAULT_SETTINGS,
            userSettings
        );
        input.multiple = settings.multiple;
        input.accept = settings.fileTypes.join(',');

        return input;
    }

    private removeInput(input: HTMLElement) {
        const parent = input.parentElement;
        if (!parent) {
            return;
        }

        parent.removeChild(input);
    }
}
