import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { Observable } from 'apollo-link';
import * as InternalDataQueries from 'src/app/queries/data-management-governance/internal-data-query';
import * as OrganizationQueries from 'src/app/queries/data-management-governance/organization-query';
import * as PeopleRoleQueries from 'src/app/queries/people-and-roles/people-and-roles-query';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InternalDataService {


  constructor(private apollo: Apollo, httpLink: HttpLink,
              private http: HttpClient) {
  }

  public fetchAllInternalData() {
    return this.apollo.use('endpoint9096').query({
      query: InternalDataQueries.GET_INTERNAL_DATA_HOMEPAGE
    });
  }

  public fetchAllInternalDataAndOrgs() {
    return this.apollo.use('endpoint9096').query({
      query: InternalDataQueries.GET_ALL_INTERNAL_DATA_AND_ORG
    });
  }

  public fetchInternalDataSourceInfo(internalsourceId) {
    return this.apollo.use('endpoint9096').query({
      query: InternalDataQueries.GET_INTERNAL_DATA_SOURCE,
      variables: {
        uid: internalsourceId
      }
    });
  }

  updateOrgInternal(addEditOrganizationForm, orgId, createAddress, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: OrganizationQueries.UPDATE_ORG,
      variables: {
        OrganizationID: orgId,
        OrganizationCode: addEditOrganizationForm.organizationCode,
        OrganizationDesc: addEditOrganizationForm.organizationDes,
        OrganizationName: addEditOrganizationForm.organizationName,
        CreateAddress: createAddress
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
        variables: {
          InternalDataOrgID: intId
        }
      }]
    });
  }

  addPersonForInternal(orgId, dataManagementPersonForm, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.ADD_PERSON,
      variables: {
        OrganizationID: orgId,
        ContactEmail: dataManagementPersonForm.email,
        ContactHomePhoneNumber: dataManagementPersonForm.homeNum,
        ContactMobilePhoneNumber: dataManagementPersonForm.mobileNum,
        DataManagementPersonIsActive: dataManagementPersonForm.personStatus,
        DataManagementPersonPicture: dataManagementPersonForm.picture,
        FirstName: dataManagementPersonForm.firstName,
        LastName: dataManagementPersonForm.lastName,
        MiddleName: dataManagementPersonForm.middleName,
        Title: dataManagementPersonForm.title
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_PERSON_BY_ORGANIZATIONID,
          variables: { OrganizationID: orgId }
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
          variables: { InternalDataOrgID: intId }
        }
      ],
    });
  }

  public updatePeopleForInternal(selectedItem, organizationId, dataManagementPeopleForm, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.EDIT_PEOPLE,
      variables: {
        id: selectedItem.id,
        OrganizationID: organizationId,
        ContactEmail: dataManagementPeopleForm.email,
        Title: dataManagementPeopleForm.title,
        FirstName: dataManagementPeopleForm.firstName,
        MiddleName: dataManagementPeopleForm.middleName,
        LastName: dataManagementPeopleForm.lastName,
        ContactHomePhoneNumber: _.isEmpty(dataManagementPeopleForm.homeNum) ? '' : dataManagementPeopleForm.homeNum,
        ContactMobilePhoneNumber: dataManagementPeopleForm.mobileNum,
        DataManagementPersonIsActive: _.isEmpty(dataManagementPeopleForm.personStatus) ? '' : dataManagementPeopleForm.personStatus,
        DataManagementPersonPicture: _.isEmpty(dataManagementPeopleForm.picture) ? '' : dataManagementPeopleForm.picture
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
        variables: { InternalDataOrgID: intId }
      }],
    });
  }

  public onDeletePersonForInternal(userId, orgId, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.DELETE_PERSON_QUERY,
      variables: {
        id: userId
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_PERSON_BY_ORGANIZATIONID,
          variables: { OrganizationID: orgId }
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
          variables: { InternalDataOrgID: intId }
        }
      ]
    });
  }

  // GraphQL Mutation Query to update Internal Data Owner Details in External Data Source.
  public updateInternalDataOwnerInfo(dataOwnersForm, InternalDataOwnerID) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: InternalDataQueries.UPDATE_INTERNAL_DATA_OWNER,
      variables: {
        InternalDataOwnerID,
        DepartmentName: dataOwnersForm.departmentName,
        EmailId: dataOwnersForm.emailId,
        FirstName: dataOwnersForm.firstName,
        LastName: dataOwnersForm.lastName,
        MiddleName: dataOwnersForm.middleName,
        OwnershipType: dataOwnersForm.ownershipType,
        PhoneNumber: dataOwnersForm.phoneNumber,
        Title: dataOwnersForm.title
      }
    });
  }

  // GraphQL Mutation Query to update Internal Database Details in External Data Source.
  public updateInternalDatabaseInfo(databaseForm, InternalDatabaseID) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: InternalDataQueries.UPDATE_INTERNAL_DATABASE,
      variables: {
        InternalDatabaseID,
        DatabaseName: databaseForm.databaseName,
        DatabasePhysicalName: databaseForm.databasePhysicalName,
        DatabaseProductName: databaseForm.databaseProductName,
        DatabaseProductVersion: databaseForm.databaseProductVersion,
        DatabaseDataFormatType: databaseForm.databaseDataFormatType,
        DataSensitivity: databaseForm.dataSensitivity,
        FunctionalDomains: databaseForm.functionalDomains
      }
    });
  }

  // GraphQL Mutation Query to update Internal Database Details in External Data Source.
  public updateInternalTransactionInfo(transactionForm, TxApplicationID) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: InternalDataQueries.UPDATE_INTERNAL_TRANSACTION,
      variables: {
        TxApplicationID,
        CRUDFunctions: transactionForm.crudFunction,
        Tags: transactionForm.tags,
        TxApplicationDesc: transactionForm.txApplicationDesc,
        TxApplicationName: transactionForm.txApplicationName,
        TxApplicationType: transactionForm.txApplicationType
      }
    });
  }

  public addTag(ComplianceTagFlag, ComplianceType, TagName) {
    console.log('inside mutation');
    console.log(ComplianceTagFlag);
    console.log(ComplianceType);
    console.log(TagName);
    return this.apollo.mutate({
      mutation: InternalDataQueries.ADD_TAGG,
      variables: {
        complianceTagFlag: ComplianceTagFlag,
        complianceType: ComplianceType,
        tagName: TagName
      }
    });
  }

  public getAllTags() {
    return this.apollo.query({
      query: InternalDataQueries.FETCH_ALL_TAGS,
      variables: {
        AllTagID: '123'
      }
    });
  }

  public createInternalData(payload) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: InternalDataQueries.CREATE_INTERNAL_DATA,
      variables: {
        OrganizationCode: payload.OrganizationCode,
        OrganizationDesc: payload.OrganizationDesc,
        OrganizationName: payload.OrganizationName,
        CreateAddress: {
          addressTag: ''
        },
        DatabaseName: payload.DatabaseName,
        DatabaseProductName: payload.DatabaseProductName,
        DatabasePhysicalName: payload.DatabasePhysicalName,
        DatabaseProductVersion: payload.DatabaseProductVersion,
        DatabaseDataFormatType: payload.DatabaseDataFormatType,
        DataSensitivity: payload.DataSensitivity,
        FunctionalDomains: payload.FunctionalDomains,
        CreateTagsInput: {
          complianceTagFlag: '',
          tagName: 'MySQL',
          complianceType: ''
        },
        CreateTxApplicationInput: {
          crudFunctions: payload.CreateTxApplicationInput.crudFunctions,
          tags: payload.CreateTxApplicationInput.tags,
          txApplicationDesc: payload.CreateTxApplicationInput.txApplicationDesc,
          txApplicationName: payload.CreateTxApplicationInput.txApplicationName,
          txApplicationType: payload.CreateTxApplicationInput.txApplicationType,
        }
      }
    });
  }

  addRoleForInternal(orgId, dataManagementRoleForm, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.ADD_ROLE,
      variables: {
        DataManagementRoleDesc: dataManagementRoleForm.roleDesc,
        DataManagementRoleName: dataManagementRoleForm.roleName,
        DataManagementRoleCode: dataManagementRoleForm.roleCode,
        DataManagementRoleIsActive: dataManagementRoleForm.roleActive,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_PERSON_BY_ORGANIZATIONID,
          variables: { OrganizationID: orgId }
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
          variables: { InternalDataOrgID: intId }
        }
      ]
    });
  }

  public updateRoleForInternal(roleItem, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.UPDATE_ROLE,
      variables: {
        DataManagementRoleDesc: roleItem.roleDesc,
        DataManagementRoleName: roleItem.roleName,
        DataManagementRoleCode: roleItem.roleCode,
        DataManagementRoleIsActive: roleItem.roleActive
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
        variables: { InternalDataOrgID: intId }
      }],
    });
  }

  public onDeleteRoleForInternal(dataManagementRoleCode, intId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.DELETE_ROLE_QUERY,
      variables: {
        DataManagementRoleCode: dataManagementRoleCode
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_ROLES
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_INT_ID,
          variables: { InternalDataOrgID: intId }
        },
      ]
    });
  }
}
