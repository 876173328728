import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'udp-row-buttons',
    templateUrl: './row-buttons.component.html'
})
export class RowButtonsComponent {

    @Output()
    removeClick: EventEmitter<void> = new EventEmitter();

}
