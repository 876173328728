import { WorkflowDefinitionNode } from '../../../types/workflowDefinition';
import { updateParameters } from './updateParameters';
import { ValueObject } from '../../../types/shared';

export const updateNodeData = (
    prevNodeData: WorkflowDefinitionNode,
    values: ValueObject
) => ({
    ...prevNodeData,
    properties: {
        ...prevNodeData.properties,
        name: values.name,
        parameters: updateParameters(
            prevNodeData.properties.parameters,
            values
        )
    }
});
