import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnInit,
  Input,
  OnDestroy
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import _ from 'lodash';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { GITHUB_API } from 'src/app/workflow-editor/consts/api';
import { CommandHandler } from '../../../gojs/tools/CommandHandler';

import { UploadOperatorsComponent } from '@xfusiontech/upload-operators';
import { SelectVersionDialogComponent } from '@workflow-editor/components/shared/select-version-dialog/select-version-dialog.component';

import { UndoService } from '../../../gojs/services/undo.service';
import { MessagesService } from '../../../services/messages.service';
import { SelectionService } from '../../../gojs/services/selection.service';
import { ModelSaveService } from '../../../gojs/services/model-save.service';
import { NodeValidationService } from '../../../services/node-validation.service';
import { CodeEditorPanelService } from '../../code-editor-panel/code-editor-panel.service';
import { WorkflowValidationService } from 'src/app/workflow-editor/services/workflow-validation.service';
import { TaskGroupInsertService } from '../../../gojs/services/task-group-insert.service';
import { ApiService } from 'src/app/workflow-editor/services/api.service';
import { UdpTaskConfigService } from 'src/app/workflow-editor/services/udp-task-config.service';
import { ToolbarService } from '../toolbar.service';
import { CreateSaveBodyService } from '@workflow-editor/services/create-save-body.service';

import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';
@Component({
  selector: 'xft-toolbar-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonsComponent implements OnInit, OnDestroy {
  /**
   * @public
   * @type: {any}
   * @input
   */
  @Input()
  public params: any;

  isSaveButtonLoading: boolean;
  isRemoveDisabled$: Observable<boolean>;
  pythonButtonDisabled$: Observable<boolean>;
  saveButtonDisabled$: Observable<boolean>;

  private subscriptions: Subscription[] = [];

  /**
   * @private
   * @type: {MatDialogRef<UploadOperatorsComponent>}
   */
  private dialogRef: MatDialogRef<UploadOperatorsComponent>;

  constructor(
    private dialog: MatDialog,
    private codeEditorPanelService: CodeEditorPanelService,
    private messagesService: MessagesService,
    private nodeValidationService: NodeValidationService,
    private modelSaveService: ModelSaveService,
    public commandHandler: CommandHandler,
    public selectionService: SelectionService,
    public undoService: UndoService,
    private changeDetector: ChangeDetectorRef,
    private workflowValidationService: WorkflowValidationService,
    private taskGroupService: TaskGroupInsertService,
    private _apiService: ApiService,
    private _udpTaskConfigService: UdpTaskConfigService,
    private toolBarService: ToolbarService,
    private createSaveBodyService: CreateSaveBodyService
  ) {
    this.isRemoveDisabled$ = this.selectionService.selection.pipe(
      map((selection) => !selection || !selection.count),
      startWith(true)
    );
  }

  ngOnInit(): void {
    this.pythonButtonDisabled$ =
      this.nodeValidationService.pythonButtonDisabledObservable;

    this.saveButtonDisabled$ =
      this.workflowValidationService.saveButtonDisabledObservable;
  }

  async onClickSave(): Promise<any> {
    if (
      this._udpTaskConfigService.saveConfig.items.operatorConfig.length === 0 &&
      !this._udpTaskConfigService.saveConfig.items.jsonEditor
    ) {
      this.messagesService.openMessage(
        miscellaneousConst.common.noOperators,
        'error'
      );
      return;
    }

    const ref: MatDialogRef<any> = this.toolBarService.openDialog(
      SelectVersionDialogComponent,
      this.dialog,
      {
        width: '600px'
      }
    );
    this.subscriptions[this.subscriptions.length] = ref.afterClosed().subscribe(async (response) => {
      if (!response.isClose) {
        return;
      }
      const data = response.value;
      if (data.version === 'sameVersion') {
        this.createSaveBodyService.isVersionSame = true;
      } else {
        this.createSaveBodyService.isVersionSame = false;
      }
      if (this.isSaveButtonLoading) {
        return;
      }
      this.isSaveButtonLoading = true;
      const finalPythonCode =
        await this._udpTaskConfigService.getFinalPythonCode();
        this.subscriptions[this.subscriptions.length] = this._apiService.savePythonCode(finalPythonCode).subscribe(
        (data) => {
          if (data) {
            this.callMultiFilesAPIForFinalSave();
          } else {
            console.log('Python Save API failed...!!!');
            this.messagesService.openMessage(
              'There is an error while saving python configuration...!!!',
              'error'
            );
          }
        },
        (error) => {
          console.log('Python Save API failed...!!!', error);
          alert('There is an error while saving python configuration...!!!');
          this.messagesService.openMessage(
            'There is an error while saving python configuration...!!!',
            'error'
          );
        }
      );
    });
  }

  async callMultiFilesAPIForFinalSave() {
    const payload = {
      items: this.processFinalConfigDataBeforeGitHubSave()
    };
    this.subscriptions[this.subscriptions.length] = (await this._apiService.saveMultiFilesContent(payload)).subscribe(
      () => {
        this.isSaveButtonLoading = false;
        this.changeDetector.markForCheck();
        this.messagesService.openMessage('Configuration saved to Github.');
      },
      (error) => {
        this.messagesService.openMessage(
          'There is an error while saving operator configuration...!!!',
          'error'
        );
      }
    );
  }

  private processFinalConfigDataBeforeGitHubSave(): any[] {
    const configData: any[] =
      this._udpTaskConfigService.prepareFinalPayloadForSave();
    this._udpTaskConfigService.udpTaskConfig = configData;
    if (configData.length > 0) {
      const dagWorkFlow = configData.filter(
        (config) => config.filename === miscellaneousConst.DAG.FILENAME
      );
      if (dagWorkFlow && dagWorkFlow.length > 0) {
        return Object.keys(dagWorkFlow[0].contents.nodes).length === 0
          ? []
          : this.addTCVStateErrorsToFinalSavePayload(configData);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  private addTCVStateErrorsToFinalSavePayload(configData: any[]) {
    if (
      this._udpTaskConfigService.TCVStateConfigs &&
      this._udpTaskConfigService.TCVStateConfigs.length > 0
    ) {
      for (const tcvConfig of this._udpTaskConfigService.TCVStateConfigs) {
        configData.push(tcvConfig);
      }
    }
    return configData;
  }

  onClickJsonEditor(): void {
    this.codeEditorPanelService.openJsonEditor();
  }

  onClickPythonEditor(): void {
    this.codeEditorPanelService.openPythonEditor();
  }

  onClickRedo(): void {
    this.commandHandler.redo();
  }

  onClickUndo(): void {
    this.commandHandler.undo();
  }

  onClickRemove(): void {
    const curentNode = this.selectionService.getCurrentSelection().first().data;
    const index = _.findIndex(curentNode.properties.parameters, (e: any) => {
      return e.id === 'task_id';
    });
    if (index !== -1) {
      const taskId = curentNode.properties.parameters[index].value;
      this._udpTaskConfigService.removeTaskConfig(taskId);
      this._udpTaskConfigService.removeTaskFromSaveConfig(taskId);
      this._udpTaskConfigService.removeTaskFromTimeStampConfig(taskId);
    }
    this.selectionService.removeCurrentSelection();

    const { nodes } = this.modelSaveService.getWorkflowDefinition();
    const nodesLeft = new Set(Object.keys(nodes));

    this.nodeValidationService.updateValidationParameters(nodesLeft);

    const data = {
      filename: miscellaneousConst.DAG.FILENAME,
      contents: this.modelSaveService.getWorkflowDefinition()
    };
    this._udpTaskConfigService.setConfigToService(data, 'jsonEditor');
  }

  onGroupTasksClick(): void {
    this.taskGroupService.groupSelectedTasks();
  }

  /**
   * @public
   * @returns: void
   * @description: a helper method
   * that opens up upload operator
   * modal.
   */
  public openUploadModal(): void {
    const p: any = this.params;

    this.dialogRef = this.dialog.open(UploadOperatorsComponent, {
      width: '575px',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      panelClass: 'xft-dialog',
      data: {
        isDag: true,
        domain: GITHUB_API,
        env: {
          token: p.token,
          branch: p.branchName,
          repository: p.gitHubUrl,
          current_version: p.version,
          dagPathName: p.dagPathName
        },
        headerText: 'Import Operator Support File'
      }
    });
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    if (!!this.dialogRef) {
      this.dialogRef.close();
    }
    unsubscribeCollection(this.subscriptions);
  }
}
