import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { WidgetTypeElementComponent } from './widget-type-element/widget-type-element.component';
import { SelectCellTypeDialogComponent } from './dialog/select-cell-type-dialog.component';
import { SelectContentComponent } from './select-content/select-content.component';
import { SelectCellTypeDialogService } from './select-cell-type-dialog.service';
import { SelectTypeComponent } from './select-type/select-type.component';
import { FormFieldModule } from '../../../form-field/form-field.module';
import { getIconRegistry } from '../../../../utils/iconRegistry';
import { ButtonModule } from '../../../button/button.module';
import { IconModule } from '../../../icon/icon.module';

const featherIcons = [
    'check'
];

const imports = [
    CommonModule,
    ButtonModule,
    IconModule,
    FormFieldModule,
    ReactiveFormsModule,
    PerfectScrollbarModule
];

const components = [
    SelectTypeComponent,
    WidgetTypeElementComponent,
    SelectContentComponent
];

const entryComponents = [
    SelectCellTypeDialogComponent
];

const providers = [
    SelectCellTypeDialogService
];

@NgModule({
    imports,
    declarations: [
        ...components,
        ...entryComponents
    ],
    entryComponents,
    providers
})
export class SelectCellTypeDialogModule {

    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
    ) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace(
            'feather',
            iconsPath
        )(featherIcons);
    }

}
