const primaryEntityNames = [
    'Address',
    'Facility',
    'Organization',
    'Person',
    'Product',
];

export const isPrimaryEntity = (
    key: string
) => primaryEntityNames.includes(key);
