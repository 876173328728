// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, IconButtonModule, SlideToggleModule } from './../../../shared';
import { MatButtonModule, MatCheckboxModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSlideToggleModule, MatSortModule, MatTableModule, MatTooltipModule } from '@angular/material';

// Components
import { DataImportComponent } from './data-import.component';
import { ViewDataComponent } from './view-data/view-data.component';
import { ViewEntityComponent } from './view-entity/view-entity.component';
import { ViewEditMetadataComponent } from './view-edit-metadata/view-edit-metadata.component';

// Directives
import { DragDropUploadDirective } from './shared/directives/drag-drop-upload/drag-drop-upload.directive';

/**
 * @constant
 */
const EXPORTS: any[] = [
  DataImportComponent
  , ViewDataComponent
  , ViewEntityComponent
  , ViewEditMetadataComponent
];

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [
    ...EXPORTS
    , DragDropUploadDirective
  ],
  imports: [
    FormsModule
    , ButtonModule
    , CommonModule
    , MatSortModule
    , MatTableModule
    , MatInputModule
    , MatButtonModule
    , MatTooltipModule
    , IconButtonModule
    , SlideToggleModule
    , MatCheckboxModule
    , MatExpansionModule
    , MatPaginatorModule
    , MatFormFieldModule
    , ReactiveFormsModule
    , MatSlideToggleModule
  ],
  exports: [...EXPORTS]
})
export class DataImportModule { }
