import { ParameterType } from '../../../types/form';
import { isParameterType } from '../../properties-editor/utils/isParameterType';

const parseAvailableType = (type: string) => type.toLowerCase();

export const normalizeAvailableType = (type: string) => {
    if (type == null) {
        return type;
    }

    const parsedType = parseAvailableType(type);
    return isParameterType(parsedType as ParameterType) ? parsedType : type;
};
