import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import _ from 'lodash';
import moment from "moment";
import { getCustomPythonCodeForDataMapping } from 'src/app/shared/utils/common-utilities';

@Component({
  selector: 'xfusiontech-configure-mappings',
  templateUrl: './configure-mappings.component.html',
  styleUrls: ['./configure-mappings.component.scss']
})
export class ConfigureMappingsComponent implements OnInit {

  selMapping: any = {
  };

  editorValue = '';
  configureVal: any;
  monacoEditorLanguages = ['python'];
  mappingValue = new FormControl('', Validators.required);
  updateValue = new FormControl('', Validators.required);
  insertValue = new FormControl('', Validators.required);
  actions: any = ['Copy', 'Translate', 'Custom'];
  booleanValue = ['True', 'False', 0, 1]
  showFields = false;
  selectedRefID: any;
  isUpsertType: any;
  elementType = '';
  constructor(private dialogRef: MatDialogRef<ConfigureMappingsComponent>,
              private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.selMapping = this.data.currentMapping ? _.cloneDeep(this.data.currentMapping) : '';
    this.checkType();
    this.checkAvailableActions();
    this.setupValidation();
    if (this.selMapping && !this.selMapping.transformation.actionType) {
      this.selMapping.transformation.actionType = '';
    }
    this.configureVal = this.selMapping ? this.selMapping.transformation.actionType : '';
    this.showFields = true;
    this.cdr.detectChanges();
  }

  private checkAvailableActions() {
    // if (this.selMapping.sourceData.length > 0) {
      if (
        !this.data.element.sourceAttribute &&
        ((this.data.uploadMode === 'APPEND' ||
          this.data.uploadMode === 'OVERRIDE') ||
          (this.data.uploadMode === 'UPSERT' &&
            (this.data.upsertConfig.upsertType === 'SCD0' ||
            this.data.upsertConfig.upsertType === 'SCD1' ||
            this.data.upsertConfig.upsertType === 'SCD2' ||
              this.data.upsertConfig.upsertType === 'SCD3')))
      ) {
        this.actions = ['Value', 'Custom'];
        if( this.selMapping.transformation && !this.selMapping.transformation.actionType) {
          this.onActionChange('Value');
        }
        this.checkUpsertType();
      } else {
        this.actions = ['Copy', 'Translate', 'Custom'];
      }
      if (this.selMapping && this.selMapping.sourceData && this.selMapping.transformation
        && this.selMapping.transformation.actionType !== 'Value') {
        if (this.selMapping.transformation) {
          this.selMapping.transformation.dataMappingType =
          this.selMapping.sourceData.length > 1 ? '1:N' : '1:1';
        } else {
          this.selMapping.transformation = {
            dataMappingType: this.selMapping.sourceData.length > 1 ? '1:N' : '1:1'
          };
        }
      }
  }

  checkType() {
    const type = this.data.currentMapping.elementValue.dataType;
    if(['INTEGER','LONG','SHORT','BIGINT'].includes(type)) {
      this.elementType = 'int';
    } else if (['BOOLEAN','BIT'].includes(type)) {
      this.elementType = 'bool';
    } else if (['STRING','VARCHAR','CHAR'].includes(type)) {
      this.elementType = 'str';
    } else if (['TIME','DATETIME','TIMESTAMP'].includes(type)) {
      this.elementType = 'ts';
    } else if (['DATE'].includes(type)) {
      this.elementType = 'date';
    }
  }

  checkUpsertType() {
    if (this.selMapping.transformation.actionType === 'Value') {
      if (
        this.data.uploadMode === 'UPSERT' &&
        (this.data.upsertConfig.upsertType === 'SCD2' || this.data.upsertConfig.upsertType === 'SCD3')
      ) {
        this.isUpsertType = true;
      } else {
        this.isUpsertType = false;
      }
    }
    if (this.selMapping.sourceValue) {
      this.mappingValue.patchValue(this.selMapping.sourceValue);
    }
    const attr = this.data.element.attributeName;
    if (this.data.upsertConfig && this.data.upsertConfig.updateColumns.length > 0) {
      this.data.upsertConfig.updateColumns.forEach(col => {
        const key = Object.keys(col)[0];
        if (key === this.data.element.attributeName) {
          this.insertValue.patchValue(col[key].insertValue);
          this.updateValue.patchValue(col[key].updateValue);
        }
      });
    }
  }

  onBool(event, type) {
    if(type === 'insert') {
      this.insertValue.patchValue(event);
    } else {
      this.updateValue.patchValue(event);
    }
  }

  setupValidation() {
    if (this.elementType === 'int') {
      this.mappingValue.setValidators([
        Validators.required,
        Validators.pattern('^(0|[1-9][0-9]*)$')
      ]);
      this.updateValue.setValidators([
        Validators.required,
        Validators.pattern('^(0|[1-9][0-9]*)$')
      ]);
      this.insertValue.setValidators([
        Validators.required,
        Validators.pattern('^(0|[1-9][0-9]*)$')
      ]);
    }
  }
  onMapping(event) {
    this.mappingValue.patchValue(event);
  }
  onCheckCodeMap(e) {
    if (e) {
      this.selectedRefID = e;
      this.onActionChange('Translate');
    }
  }

  onActionChange(data: any) {
    this.selMapping.transformation.actionType = data;
    if (this.selMapping.transformation.actionType === 'Custom') {
      this.clearFormValue();
      this.selMapping.updateColumn = '';
      this.selMapping.sourceValue = '';
      this.selectedRefID = '';
      this.selMapping.transformation.dataTranslationAssetID = '';
      this.selMapping.transformation.dataTranslationAssetType = '';
      if (this.selMapping.transformation.customCalculationFunction) {
        this.editorValue = this.selMapping.transformation.customCalculationFunction;
      } else {
        this.assignCustomPythonCode();
      }
    } else if (this.selMapping.transformation.actionType === 'Copy') {
      this.selectedRefID = '';
      this.selMapping.transformation.customCalculationFunction = '';
      this.selMapping.transformation.dataTranslationAssetID = '';
      this.selMapping.transformation.dataTranslationAssetType = '';
    } else if (this.selMapping.transformation.actionType === 'Translate') {
      this.selMapping.transformation.customCalculationFunction = '';
      this.selMapping.transformation.dataTranslationAssetID = this.selectedRefID ? this.selectedRefID.id : this.selMapping.transformation.dataTranslationAssetID;
      this.selMapping.transformation.dataTranslationAssetType = this.selectedRefID ? this.selectedRefID.type : this.selMapping.transformation.dataTranslationAssetType;
      // Add translatio ID here for dataTranslationAssetID
    } else if (this.selMapping.transformation.actionType === 'Value') {
      this.selectedRefID = '';
      this.selMapping.transformation.customCalculationFunction = '';
      this.selMapping.transformation.dataTranslationAssetID = '';
      this.selMapping.transformation.dataTranslationAssetType = '';
    }
    this.checkUpsertType();
    this.cdr.detectChanges();
  }

  clearFormValue() {
    this.mappingValue.patchValue('');
    this.insertValue.patchValue('');
    this.updateValue.patchValue('');
  }

  private assignCustomPythonCode() {
    if (this.selMapping.sourceData) {
      this.editorValue = getCustomPythonCodeForDataMapping(this.selMapping.targetAttributeName, this.selMapping.sourceData);
    }
  }

  setupColumnData() {
    let updateVal;
    let insertVal;
    if(this.elementType === 'date') {
      updateVal = moment(this.updateValue.value).format("YYYY-MM-DD");
      insertVal = moment(this.insertValue.value).format("YYYY-MM-DD");
    } else {
      updateVal = this.updateValue.value
      insertVal = this.insertValue.value
    }
    const updateData = {
      columnDataType: this.elementType,
      columnDataFormat: '',
      updateValue: updateVal,
      insertValue: insertVal
    }
    this.selMapping.updateColumn = updateData;
  }

  public close(flag: boolean): void {
    if (flag) {
      if (
        !this.isUpsertType &&
        this.selMapping.transformation.actionType === 'Value' &&
        this.mappingValue.status === 'INVALID'
      ) {
        this.mappingValue.markAsTouched();
        return;
      } else if (
        this.isUpsertType &&
        this.selMapping.transformation.actionType === 'Value' &&
        (this.insertValue.status === 'INVALID' ||
          this.updateValue.status === 'INVALID')
      ) {
        this.insertValue.markAsTouched();
        this.updateValue.markAsTouched();
        return;
      }
      if(this.selMapping.transformation.actionType === 'Value') {
        if(this.isUpsertType) {
          this.setupColumnData();
          this.selMapping.sourceValue = '';
        } else {
          this.selMapping.updateColumn = '';
          this.selMapping.sourceValue = this.elementType === 'date' ?
            moment(this.mappingValue.value).format("YYYY-MM-DD") :
            this.mappingValue.value;
        }
      }
      if (this.selMapping.transformation && this.selMapping.transformation.actionType
        && this.selMapping.transformation.actionType === 'Custom' && this.editorValue !== '') {
        this.selMapping.transformation.customCalculationFunction = this.editorValue;
      }

      this.dialogRef.close(this.selMapping);
    } else {
      this.dialogRef.close();
    }
  }

}
