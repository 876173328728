import { Injectable } from '@angular/core';
import * as go from 'gojs';
import * as _ from 'lodash/fp';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { Diagram } from '../Diagram';
import { handleLinkDrawn } from '../handlers/handleLinkDrawn';
import { handleSelectionDeleted } from '../handlers/handleSelectionDeleted';
import { EventHandler } from '../types';

import { DiagramService } from './base.service';
import { ModelSaveService } from './model-save.service';
import { UdpTaskConfigService } from '../../services/udp-task-config.service';

@Injectable()
export class EventsService extends DiagramService {

    constructor(
        private modelSaveService: ModelSaveService,
        private _udpTaskConfigService: UdpTaskConfigService
    ) {
        super();
    }
    private handlers: EventHandler<go.DiagramEventName>[] = [
        {
            event: 'LinkDrawn',
            callback: this.linkDrawnCallback.bind(this)
        },
        {
            event: 'SelectionDeleted',
            callback: handleSelectionDeleted
        }
    ];

    linkDrawnCallback(event) {
        handleLinkDrawn(event);
        const data = {
            filename: miscellaneousConst.DAG.FILENAME,
            contents: this.modelSaveService.getWorkflowDefinition()
        }
        this._udpTaskConfigService.setConfigToService(data, "jsonEditor");
    }

    bindDiagram(diagram: Diagram) {
        super.bindDiagram(diagram);
        _.forEach((handler: EventHandler<go.DiagramEventName>) => this.diagram
            .addDiagramListener(
                handler.event,
                handler.callback
            )
        )(this.handlers);
    }

    unbindDiagram() {
        this.diagram.removeDiagramListener('LinkDrawn', handleLinkDrawn);
        _.forEach((handler: EventHandler<go.DiagramEventName>) => this.diagram
            .removeDiagramListener(
                handler.event,
                handler.callback
            )
        )(this.handlers);
    }

}
