import { Component, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { CodeEditorPanelService } from '../code-editor-panel.service';
import { fadeInOut, slideInOut } from './code-editor-panel.animation';
import { CodeEditorLanguage, NotValidOperator } from '../types';
import { CreateSaveBodyService } from 'src/app/workflow-editor/services/create-save-body.service';
import { ApiService } from 'src/app/workflow-editor/services/api.service';
import { ModelSaveService } from 'src/app/workflow-editor/gojs/services/model-save.service';
import { UdpTaskConfigService } from 'src/app/workflow-editor/services/udp-task-config.service';

import { SelectionService } from '@workflow-editor/gojs/services/selection.service';
import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';
import { DataStudioEventsService } from '@xfusiontech/data-studio';

@Component({
  selector: 'xft-code-editor-panel',
  templateUrl: './code-editor-panel.component.html',
  styleUrls: ['./code-editor-panel.component.scss'],
  animations: [fadeInOut, slideInOut]
})
export class CodeEditorPanelComponent implements OnDestroy, OnInit {
  open: boolean;
  title: string;
  mode: string;
  value = 'editor';
  language: CodeEditorLanguage;
  isLoading: boolean;
  currentTaskID: string;
  currentOperatorCode: string;
  currentOperatorId: string;
  params: any;
  branchFiles: any;
  branchFileSha: any;
  branchFile: any = {};

  notValidNodes: any = [];

  public currentNotValidOperator: NotValidOperator;

  private onDestroy$ = new Subject<void>();
  fileName: string;
  workflowDef: any;
  nodes: any;
  currentFileName: any;
  currentFileConfig: any;
  operatorsConfigs: any = {};
  currentOperatorType: string;
  currentOperator: any;
  currentOperatorID: any;
  previousOperatorNodes: any = [];
  previousTaskIDs: any = [];
  isEmptyNodes = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private codeEditorPanelService: CodeEditorPanelService,
    private createSaveBodyService: CreateSaveBodyService,
    private modelSaveService: ModelSaveService,
    private selectionService: SelectionService,
    private apiService: ApiService,
    private _udpTaskConfigService: UdpTaskConfigService,
    private changeDetector: ChangeDetectorRef,
    private dataStudioEventsService: DataStudioEventsService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions[this.subscriptions.length] = this.codeEditorPanelService
      .getPanelMode()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ open, title, language, value, mode, data }) => {
        this.open = open;

        if (this.open) {
          this.title = title;
          this.language = language;
          this.value = value;
          this.mode = mode;
          this.currentTaskID = data ? data.form.value.task_id : '';
          this.currentOperatorId = data ? data.form.value.id : '';
          // this.getSha();
          this.getConfig(this.branchFileSha);
          this.changeDetector.markForCheck();
        }
      });
    this.subscriptions[this.subscriptions.length] =
      this.codeEditorPanelService.isCodeLoading
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((isCodeLoading) => {
          this.isLoading = isCodeLoading;
          this.changeDetector.markForCheck();
        });
    this.subscribeToSubjectOperatorSave();
  }

  ngOnInit(): void {
    this.getWorkflowDiagramSaved();
  }

  /**
   *
   */
  getSha() {
    this.params = this.createSaveBodyService.parameters;
    this.fileName = `${
      this.currentTaskID
    }.config.${this.currentOperatorCode.toLowerCase()}.json`;
    const { login, token, nodeName } = this.params;
    const data = {
      token,
      login,
      repositories_name: [nodeName]
    };
    this.subscriptions[this.subscriptions.length] = this.apiService
      .getBranchData(data)
      .subscribe((resp: any) => {
        this.branchFiles = resp.repositories[0].branches[0].versions[0].files;
        this.branchFile = this.branchFiles.find((item) => {
          if (item.filename == this.fileName) {
            return item;
          }
        });
        this.branchFileSha = this.branchFile.sha;
        localStorage.clear();
        localStorage.setItem(
          'branchFileSha',
          JSON.stringify(this.branchFileSha)
        );
      });
    this.branchFileSha = JSON.parse(localStorage.getItem('branchFileSha'));
  }

  /**
   *
   * @param currentValue
   */
  onValueChange(currentValue: string) {
    this.codeEditorPanelService.setCurrentValue(currentValue);
  }

  /**
   *
   * @param type
   * @returns
   */
  getOperatorCodeBasedOnMode(mode: string) {
    let code = '';
    switch (mode) {
      case 'DATASET':
        code = 'DS';
        break;
      case 'BLEND':
        code = 'DB';
        break;
      case 'MIL':
        code = 'MIL';
        break;
      case 'DT':
        code = 'DT';
        break;
    }
    return code;
  }

  /**
   *
   * @param type
   * @returns
   */
  getOperatorCode(type: string) {
    let code = '';
    switch (type) {
      case 'UDPDatasetOperator':
        code = 'DS';
        break;
      case 'UDPDataBlendOperator':
        code = 'DB';
        break;
      case 'UDPTransformationOperator':
        code = 'DT';
        break;
      case 'UDP_MILOperator':
        code = 'MIL';
        break;
      case 'UDPMILOperator':
        code = 'MIL';
        break;
    }
    return code;
  }

  /**
   *
   */
  getConfig(dagFileSha) {
    this.previousOperatorNodes = [];
    this.previousTaskIDs = [];
    this.notValidNodes = [];
    this.workflowDef = this.modelSaveService.getWorkflowDefinition();
    const nodes: any = this.codeEditorPanelService.getLinksData();
    const links: any = this.codeEditorPanelService.getLinkNodes();
    const fileNames = [];
    const validNodes: any = [];

    Object.values(nodes).forEach((node: any) => {
      const task_id = node.properties.parameters.find(
        (p) => p.id === 'task_id'
      );
      if (task_id) {
        if (node.id !== this.currentOperatorId && task_id.value) {
          validNodes.push(node);
          fileNames.push(
            `${task_id.value}.config.${this.getOperatorCode(
              node.type
            ).toLowerCase()}.json`
          );
        } else {
          this.notValidNodes.push(node.type);
        }
      }
    });

    // current file name to get the config from service
    this.currentFileName = fileNames.find((filename) => {
      if (filename.includes(this.currentTaskID)) {
        return filename;
      }
    });
    // retrieve current operators config from _udpTaskConfigService, if exists
    this.operatorsConfigs =
      this._udpTaskConfigService.getOperatorConfigFromCache(
        `config/${this.currentFileName}`
      );

    // current file config
    // if(this.operatorsConfigs && this._udpTaskConfigService.getConfig(`config/${this.currentFileName}`)) {
    //   this.currentFileConfig = "";
    // } else {
    //   this.currentFileConfig = this.operatorsConfigs || this._udpTaskConfigService.getConfig(`config/${this.currentFileName}`);
    // }

    this.currentFileConfig =
      this.operatorsConfigs ||
      this._udpTaskConfigService.getConfig(`config/${this.currentFileName}`);

    this.currentOperatorCode = this.getOperatorCodeBasedOnMode(this.mode);

    // set current operator code

    this.currentOperatorType =
      this.codeEditorPanelService.assignCorrectOperatorCodeType(
        this.currentOperatorCode
      );

    // get previous nodes
    this.getPreviousLinkNodesData(nodes, links);

    // Check if current operator selected is valid or not

    this.currentNotValidOperator =
      this.codeEditorPanelService.checkForSelectedOperatorValidation(
        this.previousTaskIDs,
        this.currentOperatorCode,
        this.currentOperatorType
      );

    this.nodes = nodes;
  }

  private getPreviousLinkNodesData(nodes, links) {
    if (!this.selectionService.getCurrentSelection().first()) {
      return;
    }

    const nodeId = this.selectionService.getCurrentSelection().first().key;
    this.currentOperator = nodes.find((node) => {
      if (node.type === this.currentOperatorType && node.id === nodeId) {
        return node;
      }
    });
    this.currentOperatorID =
      this.currentOperator && this.currentOperator.id
        ? this.currentOperator.id
        : '';
    for (const link of links) {
      if (link.to === this.currentOperatorID) {
        this.previousOperatorNodes.push(link);
      }
    }

    const prvTaskId = [];
    if (this.previousOperatorNodes && this.previousOperatorNodes.length > 0) {
      nodes.map((node) => {
        for (const prvOpNode of this.previousOperatorNodes) {
          if (prvOpNode.from === node.id) {
            node.properties.parameters.find((param) => {
              if (param.id === 'task_id') {
                return prvTaskId.push(
                  `config/${param.value}.config.${this.getOperatorCode(
                    node.type
                  ).toLowerCase()}.json`
                );
              }
            });
          }
        }
      });
      this.previousTaskIDs = [...new Set(prvTaskId)];
    }
  }

  onCancelClick() {
    this.codeEditorPanelService.discardChanges();
  }

  onSaveClick() {
    if (this.currentOperatorCode === 'DT') {
      this.dataStudioEventsService.saveWorkflowDiagramForDP();
      return;
    }

    this._udpTaskConfigService.saveUdpData.next();
    if (this.mode === 'DATASET') {
      this._udpTaskConfigService.currentOperatorSave.next(this.mode);
    } else {
      this.codeEditorPanelService.saveChanges();
    }
  }

  private getWorkflowDiagramSaved() {
    this.subscriptions[this.subscriptions.length] =
      this.dataStudioEventsService.workflowDiagramSaved$.subscribe(
        (event: any) => {
          if (
            event &&
            event.dataTransformationRecipe &&
            event.dataTransformationRecipe.nodes &&
            event.dataTransformationRecipe.nodes.length
          ) {
            if (event.dataTransformationRecipe.nodes.length === 1) {
              if (event.dataTransformationRecipe.nodes[0].id === '99999') {
                this.isEmptyNodes = true;
                return;
              }
            }
            if (
              event.taskContractValidation &&
              event.taskContractValidation.length
            ) {
              return;
            }
            this.isEmptyNodes = false;
            this.saveUDPTransformationOperatorConfig(event);
          } else {
            this.isEmptyNodes = true;
            return;
          }
        }
      );
  }

  private saveUDPTransformationOperatorConfig(event) {
    const transformationOperatorData =
      this.codeEditorPanelService.setUdpTransformerConfig(event);
    this.codeEditorPanelService.UDPTransformationOperatorConfig = {
      fileName: `config/${this.currentFileName}`,
      contents: transformationOperatorData,
      TS: Math.floor(new Date().getTime() / 1000)
    };
    this.codeEditorPanelService.saveChanges();
    this.cdr.detectChanges();
  }

  private subscribeToSubjectOperatorSave() {
    this.subscriptions[this.subscriptions.length] =
      this._udpTaskConfigService.currentOperatorSave$.subscribe((mode) => {
        if (mode !== undefined && mode !== null && mode === 'SAVE_DATASET') {
          this.codeEditorPanelService.saveChanges();
        }
      });
  }

  onGenerateDagClick() {
    this.codeEditorPanelService.fetchPythonCode();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    unsubscribeCollection(this.subscriptions);
  }
}
