import { Injectable } from '@angular/core';
import _ from 'lodash/fp';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ValidationService } from '../gojs/services/validation/validation.service';
import { WorkflowDefinitionNode } from '../types/workflowDefinition';

@Injectable()
export class NodeValidationService {
  private allNodes = new Set<string>();
  private validNodes = new Set<string>();

  constructor(private diagramValidationService: ValidationService) {}

  get pythonButtonDisabledObservable(): Observable<boolean> {
    return combineLatest([
      this.diagramValidationService.nodesValid,
      this.diagramValidationService.linksValid
    ]).pipe(map((values) => !_.all(_.identity, values)));
  }

  checkNodesValidity(nodes: WorkflowDefinitionNode[], allNodes: any) {
    nodes.forEach((node) => this.allNodes.add(node.id));

    nodes.forEach((node) => {
      const invalid = this.diagramValidationService.validateNodeProperties(
        node.properties
      );
      const sameTypeNodes = Array.from(allNodes).filter(
        (n: any) => node.type == n.type && node.id !== n.id
      );
      const taskId = node.properties.parameters.find((p) => p.id === 'task_id');
      const inValidTaskId = sameTypeNodes.some(
        (n: any) =>
          n.properties.parameters.find((p) => p.id === 'task_id').value ===
          taskId.value
      );
      if (inValidTaskId) {
        if (taskId && taskId.error) taskId.error == 'Task Id should be unique';
      } else {
        if (taskId && taskId.error) taskId.error == null;
      }
      !invalid && !inValidTaskId
        ? this.validNodes.add(node.id)
        : this.validNodes.delete(node.id);
    });

    this.diagramValidationService.setNodesValid(
      this.validNodes.size === this.allNodes.size
    );
  }

  updateValidationParameters(nodesLeft: Set<string>) {
    this.validNodes = new Set(
      [...this.validNodes].filter((node) => nodesLeft.has(node))
    );

    this.allNodes = new Set(nodesLeft);

    this.diagramValidationService.setNodesValid(
      this.validNodes.size === this.allNodes.size
    );
  }
}
