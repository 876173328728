import * as _ from 'lodash/fp';
import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';

import { IconVariant } from '../../../../types/shared';
import { InputType, Option } from '../form-field.types';
import { showHideClearButton } from '../form-field.animation';

const NON_TEXT_FIELDS: InputType[] = [
    'checkbox',
    'list',
    'dictionary',
    'union',
    'complex',
];

@Component({
    selector: 'xft-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        showHideClearButton
    ]
})
export class FormFieldComponent {

    @Input()
    paramType = '';

    @Input()
    type: InputType = 'text';

    @Input()
    options: Option[] = [];

    @Input()
    placeholder = '';

    @Input()
    label: string;

    @Input()
    suffixIcon: string;

    @Input()
    suffixIconVariant: IconVariant = 'material';

    @Input()
    control: FormControl = new FormControl();

    @Input()
    required = false;

    @Input()
    tooltipMessage: string;

    @Input()
    clearEnabled: boolean;

    @Input()
    controlName: any;

    @Output()
    optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

    displayAutocompleteValue = _.get('name');

    isSimpleTextField = (type: InputType) => !_.includes(type, NON_TEXT_FIELDS);

    trackByOptions = (idx: number, { name }: Option) => name;

    constructor(public elementRef: ElementRef) {}

    onClearClicked() {
        this.control.setValue(null);
    }

    onChangeInput(event, control) {
        if (control === 'name') {
            const pattern = /[A-Za-z0-9\_]+/;
            const inputChar = String.fromCharCode(event.charCode);
            if (!pattern.test(inputChar)) {
                event.preventDefault();
            }
        }
    }
}
