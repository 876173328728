import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UnsavedChangesService {

    stateChanged$ = new BehaviorSubject<boolean>(false);
}
