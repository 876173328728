import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SlideToggleComponent } from './slide-toggle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule
];

const components = [
    SlideToggleComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class SlideToggleModule { }
