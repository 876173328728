import { NgModule } from '@angular/core';
import { UserAvatarComponent } from './user-avatar.component';

const components = [
    UserAvatarComponent
];

@NgModule({
    declarations: components,
    exports: components
})
export class UserAvatarModule { }
