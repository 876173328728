export const DATASOURCE = {
    INTERNAL_DATA_SOURCE_CODE: 'DSRC',
    QUERY_CODE: 'QRY',
    QUERY: 'Query',
    FILE_ASSET_CODE: 'DF',
    DATASET_CODE: 'DS',
    RDS_CODE: 'RDS'
}

export const DATASOURCE_TYPE_MAP = {
    DF: 'File Asset',
    QRY: 'Query',
    DS: 'Datasets'
}