import { Component, ViewEncapsulation } from '@angular/core';
import { ZoomService } from '../../../gojs/services/zoom.service';

import * as _ from 'lodash/fp';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const zoomToPercents = _.flowRight(
    _.join(''),
    _.concat(_.__, '%'),
    _.round,
    _.multiply(100),
);

@Component({
    selector: 'xft-zoom',
    templateUrl: './zoom.component.html',
    styleUrls: ['./zoom.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ZoomComponent {
    isZoomOpen = false;

    percentZoomValue$: Observable<number>;

    constructor(
        public zoomService: ZoomService,
    ) {
        this.percentZoomValue$ = this.zoomService.zoomValue$.pipe(
            map(zoomToPercents)
        );
    }

    toggleZoomOpen() {
        this.isZoomOpen = !this.isZoomOpen;
    }
}
