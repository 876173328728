import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { Observable } from 'apollo-link';
import * as ExternalDataQueries from 'src/app/queries/data-management-governance/external-data-query';
import * as OrganizationQueries from 'src/app/queries/data-management-governance/organization-query';
import * as PeopleRoleQueries from 'src/app/queries/people-and-roles/people-and-roles-query';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ExternalDataService {

  constructor(private apollo: Apollo, httpLink: HttpLink) { }

  // GraphQL Query to fetch All Data in External Data Source
  public fetchAllExternalData() {
    return this.apollo.query({
      query: ExternalDataQueries.GET_EXTERNAL_DATA_HOMEPAGE
    });
  }

  // GraphQL Query to fetch All Data in External Data Source
  public fetchAllExternalDataAndOrgs() {
    return this.apollo.use('endpoint9096').query({
      query: ExternalDataQueries.GET_ALL_EXTERNAL_DATA_AND_ORG
    });
  }

  // GraphQL QUery to fetch details of External Data Source
  public fetchExternalDataInfo(ExternalDataId) {
    return this.apollo.use('endpoint9096').query({
      query: ExternalDataQueries.GET_EXTERNAL_DATA,
      variables: {
        uid: ExternalDataId
      }
    });
  }

  public fetchSourceDataElements(ExternalDataName) {
    return this.apollo.use('endpoint9096').query({
      query: ExternalDataQueries.GET_EXTERNAL_DATA_ELEMENTS,
      variables: {
        ExternalDataName
      }
    });
  }

  // GraphQL Query to fetch SOurceData Details in External Data Source
  public fetchSourceDataInfo(ExternalDataId) {
    const queryParam = 'externalData(ExternalDataID: "' + ExternalDataId + '")';
    return this.apollo.use('endpoint9096').query({
      query: gql`query {
        ${queryParam}
        {
          AverageDataSize
          BatchFileType
          DataInputChannel
          DataSensitivity
          DataTrustLevel
          InputFrequency
          ExternalDataDesc
          ExternalDataName
          ExternalDataTypeCode
          id
          Status
          Tags {
            id
            TagName
            ComplianceType
            ComplianceTagFlag
          }
        }
      }`
    });
  }

  updateOrgExternal(addEditOrganizationForm, orgId, createAddress, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: OrganizationQueries.UPDATE_ORG,
      variables: {
        OrganizationID: orgId,
        OrganizationCode: addEditOrganizationForm.organizationCode,
        OrganizationDesc: addEditOrganizationForm.organizationDes,
        OrganizationName: addEditOrganizationForm.organizationName,
        CreateAddress: createAddress
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
        variables: {
          ExternalDataOrgID: extId
        }
      }]
    });
  }

  addPersonForExternal(orgId, dataManagementPersonForm, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.ADD_PERSON,
      variables: {
        OrganizationID: orgId,
        ContactEmail: dataManagementPersonForm.email,
        ContactHomePhoneNumber: dataManagementPersonForm.homeNum,
        ContactMobilePhoneNumber: dataManagementPersonForm.mobileNum,
        DataManagementPersonIsActive: dataManagementPersonForm.personStatus,
        DataManagementPersonPicture: dataManagementPersonForm.picture,
        FirstName: dataManagementPersonForm.firstName,
        LastName: dataManagementPersonForm.lastName,
        MiddleName: dataManagementPersonForm.middleName,
        Title: dataManagementPersonForm.title
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_PERSON_BY_ORGANIZATIONID,
          variables: { OrganizationID: orgId }
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
          variables: { ExternalDataOrgID: extId }
        }
      ],
    });
  }

  public updatePeopleForExternal(selectedItem, organizationId, dataManagementPeopleForm, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.EDIT_PEOPLE,
      variables: {
        id: selectedItem.id,
        OrganizationID: organizationId,
        ContactEmail: dataManagementPeopleForm.email,
        Title: dataManagementPeopleForm.title,
        FirstName: dataManagementPeopleForm.firstName,
        MiddleName: dataManagementPeopleForm.middleName,
        LastName: dataManagementPeopleForm.lastName,
        ContactHomePhoneNumber: _.isEmpty(dataManagementPeopleForm.homeNum) ? '' : dataManagementPeopleForm.homeNum,
        ContactMobilePhoneNumber: dataManagementPeopleForm.mobileNum,
        DataManagementPersonIsActive: _.isEmpty(dataManagementPeopleForm.personStatus) ? '' : dataManagementPeopleForm.personStatus,
        DataManagementPersonPicture: _.isEmpty(dataManagementPeopleForm.picture) ? '' : dataManagementPeopleForm.picture
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
        variables: { ExternalDataOrgID: extId }
      }],
    });
  }

  public onDeletePersonForExternal(userId, orgId, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.DELETE_PERSON_QUERY,
      variables: {
        id: userId
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_PERSON_BY_ORGANIZATIONID,
          variables: { OrganizationID: orgId }
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
          variables: { ExternalDataOrgID: extId }
        }
      ]
    });
  }

  addRoleForExternal(dataManagementRoleForm, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.ADD_ROLE,
      variables: {
        DataManagementRoleDesc: dataManagementRoleForm.roleDesc,
        DataManagementRoleName: dataManagementRoleForm.roleName,
        DataManagementRoleCode: dataManagementRoleForm.roleCode,
        DataManagementRoleIsActive: dataManagementRoleForm.roleStatus,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_ROLES
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
          variables: { ExternalDataOrgID: extId }
        }
      ]
    });
  }

  // GraphQL Mutation Query to update SOurceData Details in External Data Source.
  public updateSourceDataInfo(sourceDataFormInfo, ExternalDataId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: ExternalDataQueries.UPDATE_SOURCE_DATA,
      variables: {
        ExternalDataID: ExternalDataId,
        AverageDataSize: sourceDataFormInfo.averageData,
        BatchFileType: sourceDataFormInfo.batchFile,
        DataInputChannel: sourceDataFormInfo.dataInput,
        DataSensitivity: sourceDataFormInfo.dataSensit,
        DataTrustLevel: sourceDataFormInfo.dataTrust,
        ExternalDataDesc: sourceDataFormInfo.sourceDataOrganization,
        ExternalDataName: sourceDataFormInfo.sourceDataName,
        ExternalDataTypeCode: sourceDataFormInfo.sourceDataType,
        InputFrequency: sourceDataFormInfo.inputFrequencyData,
        Status: sourceDataFormInfo.statusCode,
        Tags: sourceDataFormInfo.Tags,
      }
    });
  }

  // GraphQL Query for updating SourceData Agreement Info
  public updateSourceDataAgreementInfo(agreementDataForm, dataSharingAgreementID) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: ExternalDataQueries.UPDATE_SOURCE_DATA_AGREEMENT,
      variables: {
        DataSharingAgreementID: dataSharingAgreementID,
        ContractDocumentUrl: agreementDataForm.ContDocumentURL,
        ContractEndDate: agreementDataForm.ContEndDate,
        ContractStartDate: agreementDataForm.ContStartDate,
        DataConsumerOrganisationName: agreementDataForm.ConsumerOrganizationName,
        DataProviderOrganisationName: agreementDataForm.ProviderOrganizationName,
        Notes: agreementDataForm.NotesView,
        Restrictions: agreementDataForm.RestrictionView,
        Status: agreementDataForm.StatusView,
        Tags: agreementDataForm.TagsView
      }
    });
  }

  // GraphQL Query for fetching SOurceData Agreement Information
  public fetchdataSharingAgreementInfo(dataSharingAgreementID) {
    const queryParam = 'dataSharingAgreement(DataSharingAgreementID: "' + dataSharingAgreementID + '")';
    return this.apollo.use('endpoint9096').query({
      query: gql`query {
        ${queryParam}
          {
            ContractDocumentUrl
            ContractEndDate
            ContractStartDate
            DataConsumerOrganisationName
            DataProviderOrganisationName
            Notes
            Restrictions
            Status
            Tags
          }
      }`
    });
  }


  public updateRoleForExternal(roleItem, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.UPDATE_ROLE,
      variables: {
        DataManagementRoleDesc: roleItem.roleDesc,
        DataManagementRoleName: roleItem.roleName,
        DataManagementRoleCode: roleItem.roleCode,
        DataManagementRoleIsActive: roleItem.roleActive
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
        variables: { ExternalDataOrgID: extId }
      }],
    });
  }

  public onDeleteRoleForExternal(dataManagementRoleCode, extId) {
    return this.apollo.use('endpoint9096').mutate({
      mutation: PeopleRoleQueries.DELETE_ROLE_QUERY,
      variables: {
        DataManagementRoleCode: dataManagementRoleCode
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PeopleRoleQueries.GET_ALL_ROLES
        },
        {
          query: OrganizationQueries.GET_ORG_DET_BY_EXT_ID,
          variables: { ExternalDataOrgID: extId }
        },
      ]
    });
  }
}
