import { Component, Input } from '@angular/core';

import { WidgetWrapperComponent } from '../widget-wrapper.component';
import { HorizontalBarsIndicatorWrapperData } from './horizontal-bars-indicator-wrapper.types';

@Component({
    selector: 'udp-horizontal-bars-indicator-wrapper',
    templateUrl: './horizontal-bars-indicator-wrapper.component.html'
})
export class HorizontalBarsIndicatorWrapperComponent
    extends WidgetWrapperComponent<HorizontalBarsIndicatorWrapperData> {

    @Input()
    data: HorizontalBarsIndicatorWrapperData;

}
