import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'udp-field-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LabelComponent {
    @Input()
    label: string;

    @Input()
    tooltipMessage: string;
}
