import {
    animate,
    query,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

export const maximizeDrawer = trigger('maximizeDrawer', [
    state('maximized', style({
        position: 'fixed',
        width: '100%',
        borderRadius: 0
    })),
    transition('default => maximized', [
        query('.xft-properties-editor__form ', [
            animate('50ms', style({ opacity: 0 }))
        ]),
        animate('200ms ease-out', style({
            width: '100%',
            borderRadius: 0,
            zIndex: '9999'
        })),
        query('.xft-properties-editor__form ', [
            animate('200ms', style({ opacity: 1 }))
        ])
    ]),
    transition('maximized => default', [
        style({
            zIndex: '9999'
        }),
        query('.xft-properties-editor__form, .xft-properties-editor__node-view', [
            animate('50ms', style({ opacity: 0 }))
        ]),
        animate('300ms ease-in'),
        query('.xft-properties-editor__form, .xft-properties-editor__node-view ', [
            animate('200ms', style({ opacity: 1 }))
        ])
    ])
]);
