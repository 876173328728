import * as go from 'gojs';

import {
    NODE_COLLAPSED_HEIGHT, NODE_PADDING,
    PROPERTIES_TABLE_NAME
} from '../../../../consts/shared';

export const getExpandedNodeHeight = (node: go.Part) => {
    const table = node.findObject(PROPERTIES_TABLE_NAME);
    node.ensureBounds();

    const tableHeight = table.actualBounds.height;
    return tableHeight
        + NODE_COLLAPSED_HEIGHT
        + NODE_PADDING;
};
