import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermissionModule } from '@xfusiontech/shared';
import { InteractiveGraphViewRoutingModule } from './interactive-graph-view.routing';
import { AdministrationPageModule } from './pages/administration-page/administration-page.module';
import { AlertsPageModule } from './pages/alerts-page/alerts-page.module';
import { DashboardPageModule } from './pages/dashboard-page/dashboard-page.module';
import { DataImportPageModule } from './pages/data-import-page/data-import-page.module';
import { HomePageModule } from './pages/home-page/home-page.module';
import { LoginPageModule } from './pages/login-page/login-page.module';
import { QueryEditorPageModule } from './pages/query-editor-page/query-editor-page.module';
import { RecommendationsPageModule } from './pages/recommendations-page/recommendations-page.module';

const pages = [
    DashboardPageModule,
    HomePageModule,
    LoginPageModule,
    AdministrationPageModule,
    DataImportPageModule,
    RecommendationsPageModule,
    AlertsPageModule,
    QueryEditorPageModule,
];

const imports = [
    CommonModule,
    InteractiveGraphViewRoutingModule,
    HasPermissionModule,
];

@NgModule({
    imports: [...imports, ...pages, CommonModule],
    declarations: [],
})
export class InteractiveGraphViewModule {}
