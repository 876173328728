import * as _ from 'lodash/fp';
import * as go from 'gojs';

import { ValueObject } from '../../types/shared';

const EXCLUDED_PROPERTIES = [
  'isGroup',
  '__gohashid'
];

export const updateDataObject = (
  diagram: go.Diagram,
  prevData: ValueObject,
  nextData: ValueObject
) => _.flowRight(
  _.map((key: string) =>
    diagram.model.setDataProperty(prevData, key, nextData[key])
  ),
  _.filter((key: string) => !EXCLUDED_PROPERTIES.includes(key)),
  _.keys
)(nextData);
