import { CommonModule } from '@angular/common';
import { RichTooltipComponent } from './rich-tooltip.component';
import { RichTooltipDirective } from './rich-tooltip.directive';
import { NgModule } from '@angular/core';

const elements = [RichTooltipDirective, RichTooltipComponent];

@NgModule({
    imports: [CommonModule],
    declarations: elements,
    exports: elements,
    entryComponents: [RichTooltipComponent],
})
export class RichTooltipModule {}
