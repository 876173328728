import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { HasPermissionModule, IconButtonModule } from '@xfusiontech/shared';
import { MenuComponent } from './menu.component';

@NgModule({
    declarations: [MenuComponent],
    imports: [
        CommonModule,
        MatIconModule,
        IconButtonModule,
        RouterModule,
        HasPermissionModule,
    ],
    exports: [MenuComponent],
})
export class MenuModule {}
