import * as go from 'gojs';

import { theme } from '../../../../consts/theme';
import { camelCaseToLabel } from '../../../../utils/camelCaseToLabel';
import { TASK_GROUP_PADDING } from '../../../consts/task-groups';

const $ = go.GraphObject.make;

export const taskGroupLabel = () => $(
  go.TextBlock,
  {
    alignmentFocus: go.Spot.TopLeft,
    alignment: new go.Spot(0, 0, TASK_GROUP_PADDING, TASK_GROUP_PADDING),
    font: `${theme.fontWeight.light} 14px ${theme.fontFamily}`,
    stroke: theme.colors.text,
    maxLines: 1,
    maxSize: new go.Size(250, Infinity),
    overflow: go.TextBlock.OverflowEllipsis,
  },
  new go.Binding('text', '', (data) => {
    const { properties: { name }, type } = data;
    return name || camelCaseToLabel(type);
  })
);
