import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material';
import { PERMISSIONS } from '@xfusiontech/shared';

@Component({
    selector: 'igv-visualization-context-menu',
    templateUrl: './visualization-context-menu.component.html',
    styleUrls: ['./visualization-context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'visualizationContextMenu',
})
export class VisualizationContextMenuComponent {
    PERMISSIONS = PERMISSIONS;

    @Input()
    customSubCtxMenuTpl: any;

    @ViewChild('menu', { static: true }) menu: MatMenu;

    @Output()
    shareVisualizationInDashboard = new EventEmitter();

    @Output()
    shareVisualizationByEmail = new EventEmitter();

    @Output()
    editVisualization = new EventEmitter();

    @Output()
    deleteVisualization = new EventEmitter();
}
