import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from '@progress/kendo-angular-charts';

import { BarChartComponent } from './bar-chart.component';

const imports = [
    CommonModule,
    ChartModule
];

const components = [
    BarChartComponent
];

@NgModule({
    imports,
    declarations: components,
    exports: components
})
export class BarChartModule { }
