import { ParameterType } from '../../../types/form';
import { InputType } from '../../shared/form-field/form-field.types';

const parameterTypeToInputTypeMap: Record<ParameterType, InputType> = {
    [ParameterType.String]: 'text',
    [ParameterType.Dictionary]: 'dictionary',
    [ParameterType.Boolean]: 'checkbox',
    [ParameterType.Integer]: 'number',
    [ParameterType.DeltaTime]: 'number',
    [ParameterType.Datetime]: 'date',
    [ParameterType.Callable]: 'text',
    [ParameterType.List]: 'list',
    [ParameterType.Union]: 'union',
    [ParameterType.Complex]: 'complex',
};

export const mapParameterTypeToInputType = (parameterType: ParameterType) => {
    if (!parameterType) {
        return null;
    }
    const inputType = parameterTypeToInputTypeMap[parameterType];
    return inputType || 'complex';
};
