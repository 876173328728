import { Injectable } from '@angular/core';
import { UrlParameters } from '../types/url';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

@Injectable()
export class CreateSaveBodyService {
    public parameters: UrlParameters;
    public isVersionSame = false;

    private jsonBody(data: string, formData: FormData, fileName: string = miscellaneousConst.DAG.FILENAME) {
        const blob = new Blob([data], { type: 'text/json' });
        const file = new File([blob], miscellaneousConst.DAG.DAGWORKFLOW, { type: 'json' });
        formData.append('filename', fileName);
        formData.append('filecontent', file);

        return formData;
    }

    private pythonBody(data: string, formData: FormData) {
        if (this.parameters.dagFileName !== 'undefined') {
            const file = new File([data], this.parameters.nodeId, { type: 'text/py' });
            formData.append('filename', this.parameters.dagPathName + '/' + this.parameters.nodeId + '.py');
            formData.append('filecontent', file);

            return formData;
        } else {
            const file = new File([data], this.parameters.nodeId, { type: 'text/py' });
            formData.append('filename', this.parameters.dagPathName + '/' + this.parameters.nodeId + '.py');
            formData.append('filecontent', file);

            return formData;
        }
    }

    private createFormData(): FormData {
        const formData = new FormData();
        const { token, nodeId, branchName, version } = this.parameters;

        formData.append('token', token);
        formData.append('repository', nodeId);
        formData.append('branch', branchName);
        formData.append('current_version', version);

        return formData;
    }

    setUrlParameters(routeParameters) {
        this.parameters = routeParameters;
    }

    createSaveBody(data: string, type: string, fileName?: string) {
        const formData = this.createFormData();

        if (type === 'json') {
            return this.jsonBody(data, formData, fileName);
        }

        if (type === 'python') {
            return this.pythonBody(data, formData);
        }
    }
}
