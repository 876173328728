import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClassDtl } from './ref-dataset-modal';
import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';


@Component({
  selector: 'lib-view-reference-dataset',
  templateUrl: './view-reference-dataset.component.html',
  styleUrls: ['./view-reference-dataset.component.scss']
})
export class ViewReferenceDatasetComponent implements OnInit {

  @Input() customerId: string;
  @Input() userId: string;
  @Input() selMapping: any;

  selectedDataset: any;
  selectedVersion: any;
  isVersionSelected = false;
  isVersionCodeMap = false;
  versionCodeDataMap: any;
  private codesetClassData: any;
  private codesetSubClassData: any;
  detailList: Array<ClassDtl> = [];
  classLength: any;
  selectedSubClass: any;
  selectedClass: any;
  private subscriptions: Subscription[] = [];
  closeDetails = false;
  isDisplayHome = true;
  isSelectedDataset = false;
  savedRefData: any;
  displaySavedData = false;
  @Output() onCheckCode = new EventEmitter();

  constructor(private _dataCatalogService: DataCatalogService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.isDisplayHome = true;
    if (this.selMapping && this.selMapping.dataTranslationAssetID && this.selMapping.dataTranslationAssetType) {
      // tslint:disable-next-line: max-line-length
      this.subscriptions[this.subscriptions.length] = this._dataCatalogService.getDatasetCodeValueById(this.customerId, this.selMapping.dataTranslationAssetType, this.selMapping.dataTranslationAssetID, true).subscribe(data => {
        if (data) {
          this.savedRefData = data;
          this.displaySavedData = true;
          this.isDisplayHome = false;
        } else {
          this.displaySavedData = false;
          this.isDisplayHome = true;
        }
      });
    }


    this._dataCatalogService.selectedCategory$.subscribe(cat => {
      if (cat) {
        this.selectedDataset = cat.subCategory;
        this.isSelectedDataset = true;
        this.isVersionSelected = false;
        this.isDisplayHome = true;
        this.displaySavedData = false;
        this.selectedVersion = {};
      } else {
        this.isSelectedDataset = false;
        this.isVersionSelected = false;
        this.selectedVersion = {};
        this.displaySavedData = false;
      }
    });

    this._dataCatalogService.selectedDataAsset$.subscribe(cat => {
      if (cat) {
        this.displaySavedData = false;
        this.isDisplayHome = true;
        const categoryDataset = cat.category.dataAssets;
        this.selectedVersion = cat.subCategory;
        this.selectedDataset = categoryDataset.find(ds => ds.refDataSetID === this.selectedVersion.refDataSetID);
        this.isSelectedDataset = true;
        this.isVersionSelected = true;
        this.closeDetails = true;
        this.isVersionCodeMap = false;
        this.versionCodeDataMap = [];
        this.cdr.detectChanges();
        if (this.selectedVersion.refCodeValMap) {
          setTimeout(() => {
            this.isVersionCodeMap = true;
            this.versionCodeDataMap = this.selectedVersion.refCodeValMap;
          }, 500);
        } else {
          this.isSelectedDataset = false;
          this.isVersionCodeMap = false;
          this.versionCodeDataMap = [];
          this.displaySavedData = false;
        }
        this.displayVersionClass(this.selectedVersion.refDataSetVersionID);
      }
    });
  }

  async displayVersionClass(versionID) {
    this.detailList = new Array<ClassDtl>();
    this.codesetClassData = [];
    const completeCall = await this._dataCatalogService.getRefCodesetClass(versionID, this.customerId).subscribe((data: any) => {
      if (data.length > 0) {
        this.codesetClassData = data;
        this.classLength = this.codesetClassData.length;
        for (const item of this.codesetClassData) {
          let newClass: ClassDtl = new ClassDtl();
          newClass = {...item, subClasses: []};
          this.detailList.push(newClass);
        }
      }
    });
  }

  checkUndefinedOrNull(param) {
    return (typeof param === undefined || param == null) ? [] : param;
  }

  onToggleExpansionClassClick(val, j) {
    this.selectedClass = val.refCodesetClassID;
    const refVal = this.checkUndefinedOrNull(val.refCodeValMap);
    if (refVal.length > 0) {
      this.detailList[j].isViewCodeRef = true;
    } else {
      this.detailList[j].isViewCodeRef = false;
    }
    this.getSubClasses(val);
  }

  onToggle(subClass, j, i) {
    this.selectedSubClass = subClass.refCodesetSubClassID;
    const refVal = this.checkUndefinedOrNull(subClass.refCodeValMap);
    if (refVal.length > 0) {
      this.detailList[j].subClasses[i].isViewSubCodeRef = true;
    } else {
      this.detailList[j].subClasses[i].isViewSubCodeRef = false;
    }
  }

  async getSubClasses(classVal) {
    const classId = classVal.refCodesetClassID;
    if (classVal.subClasses.length == 0) {
      this.codesetSubClassData = [];
      const completeCall = await this._dataCatalogService.getRefSubCodesetClass(classId, this.customerId).subscribe((data: any) => {
        if (data.length > 0) {
          this.codesetSubClassData = data;
          this.detailList.forEach(item => {
            if (item.refCodesetClassID === classId) {
              item.subClassLength = this.codesetSubClassData.length;
              this.codesetSubClassData.forEach(sItem => {
                item.subClasses.push(sItem);
              });
            }
          });
        }
      });
    }
  }

  onCheck(e) {
    if (e) {
      this.onCheckCode.emit(e);
    }
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
}
