import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
    ButtonModule,
    getIconRegistry,
    IconButtonModule,
    IconModule,
} from '@xfusiontech/shared';
import { ConfirmDialogModule } from './all/components/confirm-dialog/confirm-dialog.module';
import { LayoutModule } from './all/components/layout/layout.module';
import { QueryEditorModule } from './all/components/query-editor/query-editor.module';
import { QueryVariablesDialogModule } from './all/components/query-variables-dialog/query-variables-dialog.module';
import { SidebarModule } from './all/components/sidebar/sidebar.module';
import { CategoryDialogModule } from '././all/components/category-dialog/category-dialog.module';
import { ShareByEmailDialogModule } from '././all/components/share-by-email-dialog/share-by-email-dialog.module';
import { ShareInDashboardDialogModule } from '././all/components/share-in-dashboard-dialog/share-in-dashboard-dialog.module';
import { QueryEditorPageComponent } from './query-editor-page.component';
import { QueryEditorService } from './query-editor-page.service';

const customIcons = ['add_query'];

const modules = [
    ButtonModule,
    IconModule,
    IconButtonModule,
    MatMenuModule,
    SidebarModule,
    LayoutModule,
    QueryEditorModule,
    CategoryDialogModule,
    ShareByEmailDialogModule,
    ShareInDashboardDialogModule,
    ConfirmDialogModule,
    QueryVariablesDialogModule,
    RouterModule
];

@NgModule({
    declarations: [QueryEditorPageComponent],
    providers: [QueryEditorService],
    imports: [CommonModule, ...modules],
    exports: [QueryEditorPageComponent],
})
export class QueryEditorPageModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace('custom', iconsPath)(customIcons);
    }
}
