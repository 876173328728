import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EditVisualizationDialogModule } from '@xfusiontech/data-visualizer';
import {
    ButtonModule,
    IconButtonModule,
    HasPermissionModule,
} from '@xfusiontech/shared';
import { ConfirmDialogModule } from '../../components/confirm-dialog/confirm-dialog.module';
import { LayoutModule } from '../../components/layout/layout.module';
import { VisualizationDialogModule } from '../../components/visualization-dialog/visualization-dialog.module';
import { CategoryDialogModule } from './../../components/category-dialog/category-dialog.module';
import { DashboardModule } from './../../components/dashboard/dashboard.module';
import { ShareByEmailDialogModule } from './../../components/share-by-email-dialog/share-by-email-dialog.module';
import { ShareInDashboardDialogModule } from './../../components/share-in-dashboard-dialog/share-in-dashboard-dialog.module';
import { SidebarModule } from './../../components/sidebar/sidebar.module';
import { DashboardPageComponent } from './dashboard-page.component';

const components = [
    SidebarModule,
    DashboardModule,
    CategoryDialogModule,
    ConfirmDialogModule,
    ShareByEmailDialogModule,
    ShareInDashboardDialogModule,
    EditVisualizationDialogModule,
    LayoutModule,
    IconButtonModule,
    RouterModule,
    ButtonModule,
    VisualizationDialogModule,
    HasPermissionModule,
];

@NgModule({
    declarations: [DashboardPageComponent],
    imports: [CommonModule, ...components],
    exports: [DashboardPageComponent],
})
export class DashboardPageModule {}
