import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
declare var escape: any;

@Injectable()
export class UtilService {
    private sessionid: string;
    private userid:string;
    private customerid:string;
    private userSessionKey: string;
    sessionCust: string;
    sessionUser: string;
    private currentUserPermissions: Array<string> = [];
    inactiveApis: any = [];

    userCustomerInfo: any  = new BehaviorSubject<any>(null);
    public userCustomerInfo$ = this.userCustomerInfo.asObservable();

    setSessionId(sessionid): void {
        this.sessionid = sessionid;
        sessionStorage.setItem("sessionid", this.sessionid);
    }
    getSessionId(): string {
        return this.sessionid ? this.sessionid : sessionStorage.getItem("sessionid");
    }

    setUserAndCustomerID(userid,customerid)
    {
        this.userid = userid,
        this.customerid =customerid;
        sessionStorage.setItem("userid", this.userid);
        sessionStorage.setItem("customerid", this.customerid);
        const data = {
          userId: this.userid,
          customerId: this.customerid
        };

        this.userCustomerInfo.next(data);
	}

  setUserSessionKey(userSessionKey) {
    this.userSessionKey = userSessionKey
    sessionStorage.setItem("userSessionKey", this.userSessionKey);
  }

  getUserSessionKey() {
    return this.userSessionKey ? this.userSessionKey : sessionStorage.getItem("userSessionKey");
  }

	getUserId() {
    this.sessionUser = sessionStorage.getItem("userid")
    console.log('u',this.sessionUser,this.userid);
		return this.sessionUser ? this.sessionUser : this.userid;
	}

  getUserInfo() {
		return JSON.parse(sessionStorage.getItem("userinfo"));
	}

  getCustomerId() {
    this.sessionCust = sessionStorage.getItem("customerid")
    console.log('c',this.sessionCust,this.customerid);
		return this.sessionCust ? this.sessionCust : this.customerid;
	}

    setPermissionStrings(value: any[]){
      this.currentUserPermissions = [];
      this.currentUserPermissions = JSON.parse(JSON.stringify(value));

      let permissionDetails = JSON.stringify(value);
      if(permissionDetails.includes("UDP.DMG")) {
        this.currentUserPermissions.push('UDP.DMG');
        if(permissionDetails.includes("UDP.DMG.ORG")) {
          this.currentUserPermissions.push('UDP.DMG.ORG');
        }
        if(permissionDetails.includes("UDP.DMG.PRL")) {
          this.currentUserPermissions.push('UDP.DMG.PRL');
        }
        if(permissionDetails.includes("UDP.DMG.PRL")) {
          this.currentUserPermissions.push('UDP.DMG.PRL');
        }
        if(permissionDetails.includes("UDP.DMG.BSC")) {
          this.currentUserPermissions.push('UDP.DMG.BSC');
        }
        if(permissionDetails.includes("UDP.DMG.BSG")) {
          this.currentUserPermissions.push('UDP.DMG.BSG');
        }
        if(permissionDetails.includes("UDP.DMG.SHR")) {
          this.currentUserPermissions.push('UDP.DMG.SHR');
        }
        if(permissionDetails.includes("UDP.DMG.ANL")) {
          this.currentUserPermissions.push('UDP.DMG.ANL');
        }


      }
      if(permissionDetails.includes("UDP.DP")) {
        this.currentUserPermissions.push('UDP.DP');
        if(permissionDetails.includes("UDP.DP.WRKSP")) {
          this.currentUserPermissions.push('UDP.DP.WRKSP');
        }
        if(permissionDetails.includes("UDP.DP.DPW")) {
          this.currentUserPermissions.push('UDP.DP.DPW');
        }
      }
      if(permissionDetails.includes("UDP.PSO")) {
        this.currentUserPermissions.push('UDP.PSO');
      }
      if(permissionDetails.includes("UDP.DINS")) {
        this.currentUserPermissions.push('UDP.DINS');
        if(permissionDetails.includes("UDP.DINS.AN_DAS")) {
          this.currentUserPermissions.push('UDP.DINS.AN_DAS');
        }
        if(permissionDetails.includes("UDP.DINS.VIS_REP")) {
          this.currentUserPermissions.push('UDP.DINS.VIS_REP');
        }
        if(permissionDetails.includes("UDP.DINS.DOC_SRH")) {
          this.currentUserPermissions.push('UDP.DINS.DOC_SRH');
        }
      }
      if(permissionDetails.includes("UDP.ADM")) {
        this.currentUserPermissions.push('UDP.ADM');
        if(permissionDetails.includes("UDP.ADM.CUS_MGM")) {
          this.currentUserPermissions.push('UDP.ADM.CUS_MGM');
        }
        if(permissionDetails.includes("UDP.ADM.USR_MGM")) {
          this.currentUserPermissions.push('UDP.ADM.USR_MGM');
        }
      }
      if(permissionDetails.includes("UDP.DMG.MSTR_DATA")) {
        this.currentUserPermissions.push('UDP.DMG.MSTR_DATA');
      }

      let permissionDetailsUpdated = JSON.stringify(this.currentUserPermissions)

      console.log('permissionDetailsUpdated',permissionDetailsUpdated);
      localStorage.setItem('userPermission', permissionDetailsUpdated);
    }

    isUserLoggedIn():boolean {


        //TODO UNTIL TOKEN ISSUE FIXED RETURN TRUE
        return true;
	/*	let token = this.getSessionId();
		let loggedIn: boolean = false;
		//return !this.isTokenExpired(token)
		try {
			if (this.decodeToken(token)) {
				loggedIn = true;
			} else {
				loggedIn = false;
			}
		} catch (err) {
			loggedIn = false;
		}
		return loggedIn;*/

	}

    public decodeToken(token: string) {

        //TODO set values after decoding token Need to be refactored after authentication service is ready
        this.setUserAndCustomerID("demouser","DEMOUSER");

        /*
		var parts = token.split('.');

		if (parts.length !== 3) {
			throw new Error('JWT must have 3 parts');
		}

		var decoded = this.urlBase64Decode(parts[1]);
		if (!decoded) {
			throw new Error('Cannot decode the token');
		}

		return JSON.parse(decoded);*/
	}
    public urlBase64Decode(str: string) {

		var output = str.replace(/-/g, '+').replace(/_/g, '/');
		switch (output.length % 4) {
			case 0: { break; }
			case 2: { output += '=='; break; }
			case 3: { output += '='; break; }
			default: {
				throw 'Illegal base64url string!';
			}
		}

		return decodeURIComponent(escape(window.atob(output)));
	}
}
