import { IconVariant } from '../../../types/shared';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'xft-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
    @Input()
    name: string;

    @Input()
    disabled: boolean;

    @Input()
    variant: IconVariant;

    /**
     * @public
     * @type: {string}
     */
    @Input()
    public message: string = '';

    /**
     * @public
     * @type: {string}
     */
     @Input()
     public position: string = 'after';

    /**
     * @public
     * @type: {boolean}
     */
     public isMaterial = false;

    @Output()
    buttonClick: EventEmitter<any>  = new EventEmitter<any>();


    /**
     * @public
     * @return: void
     * @description: Life Cycle Hook
     */
    public ngOnInit(): void {
        this.isMaterial = this.variant === 'material';
    }
}
