import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-workflow-dropdown',
  templateUrl: './workflow-dropdown.component.html',
  styleUrls: ['./workflow-dropdown.component.scss']
})
export class WorkflowDropdownComponent implements OnInit, OnChanges {
  constructor(private cdr: ChangeDetectorRef) {}

  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() options: any;
  @Input() model: any;
  private workflow$ = new BehaviorSubject<undefined>(undefined);
  ngOnInit() {
    this.modelCheck();
  }
  modelCheck() {
    if (this.model) {
      this.handleSelect('', this.model);
    }
  }
  set selectedWorkflow(workflow) {
    this.workflow$.next(workflow);
  }
  get selectedWorkflow() {
    return this.workflow$.getValue();
  }
  selectWorkflow(workflow) {
    this.workflow$.next(workflow);
    this.valueChange.emit(this.workflow$.getValue());
  }

  handleSelect(event, workflow?) {
    if (event) {
      event.stopPropagation();
    }
    this.selectWorkflow(workflow);
  }

  handleConfirm() {
    this.valueChange.emit(this.workflow$.getValue());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.modelCheck();
    this.cdr.detectChanges();
  }
}
