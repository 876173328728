import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    DataAssetCategory,
    DiagramModel,
    GraphConfiguration,
    GRAPH_CONFIGURATION,
    Query,
} from '@xfusiontech/data-visualizer';
import { UserService } from '@xfusiontech/shared';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, map, shareReplay, take } from 'rxjs/operators';
import { QUERY_EDITOR_PATH } from './all/consts/paths';
import { MAIN_API } from './all/consts/urls';
import { QueryVariableType } from './all/models/query-variable.model';
import { queryVariables } from './mock/MOCK_QueryMetadata';
import {
    APIQueryDataAssetCategory,
    mapQueryAPIToDataAssetCategory,
    mapQueryToAPIModel,
    mapQueryToRunAPIModel,
} from './query-api-mapper';

@Injectable({
    providedIn: 'root',
})
export class QueryEditorService {
    protected dataAssetCategories = new BehaviorSubject<DataAssetCategory[]>(
        []
    );
    public dataAssetCategories$ = this.dataAssetCategories.asObservable();

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private router: Router
    ) {
        this.queryDataAssetsCategories();
    }

    queryDataAssetsCategories(): void {
        const userID = this.userService.userIDSnapshot;
        const URL = `${MAIN_API}/visualizations/users/${userID}/dataAssets/?assetType=QUERY`;
        const query = this.httpClient.get<APIQueryDataAssetCategory[]>(URL);

        query
            .pipe(
                shareReplay(),
                take(1),
                map(categories => mapQueryAPIToDataAssetCategory(categories))
            )
            .subscribe(categories => this.dataAssetCategories.next(categories));
    }

    createOrUpdateQuery(query: Query, categoryId: string): void {
        const userID = this.userService.userIDSnapshot;
        const dataSourceID = this.userService.currentDataSourceIdSnapshot;

        this.httpClient
            .post(
                `${MAIN_API}/visualizations/users/${userID}/dataAssetCategories/${categoryId}`,
                mapQueryToAPIModel(query)
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: (response: any) => {
                    this.queryDataAssetsCategories();
                    if (response.query && response.query.queryID) {
                        this.router.navigate([
                            `/${QUERY_EDITOR_PATH}`,
                            response.query.queryID,
                        ]);
                    }
                },
            });
    }

    createOrUpdateCategory(category: DataAssetCategory): void {
        const userID = this.userService.userIDSnapshot;
        this.httpClient
            .post(
                `${MAIN_API}/visualizations/users/${userID}/dataAssets`,
                category
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });
    }

    getQueryRunResult(
        query: Query
    ): Observable<{
        model: DiagramModel;
        configuration: GraphConfiguration;
    } | null> {
        const url = `${MAIN_API}/visualizations/${
            query.isQueryTemplate ? 'queryTemplate' : 'runQuery'
        }`;

        return this.httpClient.post(url, mapQueryToRunAPIModel(query)).pipe(
            take(1),
            map(result =>
                'nodeResultDetails' in result
                    ? {
                          model: {
                              nodes: (
                                  (result as any).nodeResultDetails.nodes || []
                              ).map(n => ({
                                  ...n,
                                  numberOfChildren:
                                      n.nodeDigest && n.nodeDigest.numberOfEdges
                                          ? +n.nodeDigest.numberOfEdges
                                          : 0,
                              })),
                              links:
                                  (result as any).nodeResultDetails.links || [],
                          },
                          configuration: GRAPH_CONFIGURATION,
                      }
                    : null
            ),
            catchError(() => of(null))
        );
    }

    deleteQuery(query: Query): void {
        this.httpClient
            .delete(`${MAIN_API}/visualizations/deleteQuery/${query.queryID}`)
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });
    }

    sendQueryByEmail(email: string, query: Query) {
        // todo - integrate with real api call
        this.queryDataAssetsCategories();
    }

    shareQueryInDashboard(sharedTo: string, users: string[], query: Query) {
        // todo - integrate with real api call
        this.queryDataAssetsCategories();
    }

    deleteCategory(category: DataAssetCategory): void {
        const userID = this.userService.userIDSnapshot;
        this.httpClient
            .delete(
                `${MAIN_API}/visualizations/users/${userID}/dataAssets/${category.dataAssetCategoryID}`
            )
            .pipe(take(1))
            .subscribe({
                error: err => console.error(err),
                next: () => this.queryDataAssetsCategories(),
            });
    }

    getQueryVariablesTypes(): Observable<QueryVariableType[]> {
        return of(queryVariables).pipe(
            shareReplay(),
            delay(200)
        );
    }
}
