import * as go from 'gojs';
import { MAIN_SHAPE_NAME } from '../../../consts/shared';
import { TASK_GROUP_MIN_HEIGHT, TASK_GROUP_MIN_WIDTH } from '../../../consts/task-groups';
import { sizeBinding } from './sizeBinding';

const $ = go.GraphObject.make;

export const background = () => $(
  go.Shape,
  {
    name: MAIN_SHAPE_NAME,
    alignmentFocus: go.Spot.TopLeft,
    alignment: go.Spot.TopLeft,
    figure: 'RoundedRectangle',
    stroke: '#cccccc',
    strokeWidth: 1,
    fill: 'white',
    stretch: go.GraphObject.Fill,
    minSize: new go.Size(TASK_GROUP_MIN_WIDTH, TASK_GROUP_MIN_HEIGHT),
    shadowVisible: true,
  },
  sizeBinding()
);
