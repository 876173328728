import * as go from 'gojs';

import { theme } from '../../../../consts/theme';
import { Port } from '../../../../types/workflowDefinition';
import { generateGeometryFromSvgPath } from '../../../utils/generateGeometryFromSvgPath';

const $ = go.GraphObject.make;

// tslint:disable-next-line: max-line-length
const BORDER_GEOMETRY = 'M 0,0 C 7.3062669,0 13.229167,5.9228998 13.229167,13.229167 13.229167,20.535434 7.3062669,26.458333 0,26.458333';

// tslint:disable-next-line: max-line-length
const FILL_GEOMETRY = 'M 13.229167,13.229167 C 13.229167,20.535434 7.3062669,26.458333 0,26.458333 V 0 c 7.3062669,0 13.229167,5.9228998 13.229167,13.229167 z';

const WIDTH = 14;
const HEIGHT = 30;

const portFill = (angle: number) => $(
  go.Shape,
  {
    geometry: generateGeometryFromSvgPath(FILL_GEOMETRY),
    alignmentFocus: go.Spot.Left,
    alignment: go.Spot.Left,
    width: WIDTH,
    height: HEIGHT,
    fill: 'transparent',
    stroke: null,
    angle
  }
);

const portBorder = (angle: number) => $(
  go.Shape,
  {
    geometryString: BORDER_GEOMETRY,
    alignmentFocus: go.Spot.Left,
    alignment: go.Spot.Left,
    strokeDashArray: [8, 2],
    width: WIDTH,
    height: HEIGHT,
    stroke: theme.colors.link,
    strokeWidth: 2,
    angle
  }
);

export const groupInternalPort = (portId: Port) => {
  const linkSpot = portId === Port.GroupInternalLeft
    ? go.Spot.Right
    : go.Spot.Left;
  const alignment = portId === Port.GroupInternalLeft
    ? go.Spot.Left
    : go.Spot.Right;

  return $(
    go.Panel,
    go.Panel.Spot,
    {
      fromLinkable: true,
      toLinkable: true,
      fromSpot: linkSpot,
      toSpot: linkSpot,
      portId,
      alignmentFocus: alignment,
      alignment,
      opacity: 0
    },
    portFill(portId === Port.GroupInternalLeft ? 0 : 180),
    portBorder(portId === Port.GroupInternalLeft ? 0 : 180),
  );
};
