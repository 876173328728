import * as _ from 'lodash/fp';
import { FormControl, Validators } from '@angular/forms';

import { FormField } from '../../../types/form';

const getFieldValue = (formField: FormField) => {
    const value = formField.value;
    const defaultValue = formField.default;

    return _.isNil(value)
        ? defaultValue
        : value;
};

const getValidators = (formField: FormField) => formField.required
    ? [Validators.required]
    : [];

export const mapFormFieldToFormControl = (
    formField: FormField
): FormControl => {
    const fieldValue = getFieldValue(formField);
    const validators = getValidators(formField);

    return new FormControl(fieldValue, validators);
};
