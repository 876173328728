import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnerPageTabsComponent } from './inner-page-tabs/inner-page-tabs.component';
import { InnerPageTabComponent } from './inner-page-tab/inner-page-tab.component';

const components = [
  InnerPageTabsComponent
];

const internalComponents = [
  InnerPageTabComponent
];

const imports = [
  CommonModule
];

@NgModule({
  declarations: [...components, ...internalComponents],
  exports: components,
  imports: imports
})
export class InnerPageTabsModule { }
