import * as _ from 'lodash/fp';
import { GridLayoutModel } from '../../grid-layout/grid-layout.types';
import { WidgetModel } from '../../widget/widget.types';

export const setCellData = (
    currentLayout: GridLayoutModel<WidgetModel>,
    rowIndex: number,
    cellIndex: number,
    cellData: WidgetModel,
    type:string
) => {
    const next = _.merge({}, currentLayout);
    const selectedRow = next.rows[rowIndex];
    const selectedCell = selectedRow.cells[cellIndex];
    selectedRow.cells[cellIndex] = {
        ...selectedCell,
        data: cellData,
        type: type
    };

    return next;
};
