import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog.service';
import { MatIconModule } from '@angular/material';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatIconModule],
    declarations: [ConfirmDialogComponent],
    entryComponents: [ConfirmDialogComponent],
    exports: [ConfirmDialogComponent],
    providers: [ConfirmDialogService],
})
export class ConfirmDialogModule {}
