/**
 * @author: Naga
 * @constant
 */
 export const DATASETS: any[] = [
    {
      dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2rytr5',
      dataSetName: 'Sample Dataset 1',
      dataSetDataSource: [
        {
          dataSourceID: '1ac85184-06eb-11e5',
          dataSourceName: 'Sample Source 11',
          dataSourceType: 'Test',
          dataSourceConnectionID: '06eb2-23dwe-44sd',
          dataSetDesc: 'Sample Source 11'
        },
        {
          dataSourceID: '1ac851787-06eb-12e5',
          dataSourceName: 'Sample Source 12',
          dataSourceType: 'Test',
          dataSourceConnectionID: '06eb2-23dwe-66sd',
          dataSetDesc: 'Sample Source 12'
        }
      ],
      datasetSchema: {
        entity: [
          {
            entityName: 'Test Entity 1',
            attributes: [
              {
                attributeName: 'Test1 Attribute 1',
                attribututeDataType: 'string',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              },
              {
                attributeName: 'Test1 Attribute 2',
                attribututeDataType: 'boolean',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              },
              {
                attributeName: 'Test1 Attribute 3',
                attribututeDataType: 'boolean',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              },
              {
                attributeName: 'Test1 Attribute 4',
                attribututeDataType: 'boolean',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              }
            ]
          }
        ]
      }
    },
    {
      dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2d6c5',
      dataSetName: 'Sample Dataset 2',
      dataSetDataSource: [
        {
          dataSourceID: '1ac85184-06eb-21e5',
          dataSourceName: 'Sample Source 21',
          dataSourceType: 'Test',
          dataSourceConnectionID: '06eb2-23dwe-44sd',
          dataSetDesc: 'Sample Source 21'
        },
        {
          dataSourceID: '1ac851787-06eb-22e5',
          dataSourceName: 'Sample Source 22',
          dataSourceType: 'Test',
          dataSourceConnectionID: '06eb2-23dwe-66sd',
          dataSetDesc: 'Sample Source 22'
        },
        {
          dataSourceID: '1ac851787-06eb-23e5',
          dataSourceName: 'Sample Source 23',
          dataSourceType: 'Test',
          dataSourceConnectionID: '06eb2-23dwe-66sd',
          dataSetDesc: 'Sample Source 23'
        },
        {
          dataSourceID: '1ac851787-06eb-24e5',
          dataSourceName: 'Sample Source 24',
          dataSourceType: 'Test',
          dataSourceConnectionID: '06eb2-23dwe-66sd',
          dataSetDesc: 'Sample Source 24'
        }
      ],
      datasetSchema: {
        entity: [
          {
            entityName: 'Test Entity 1',
            attributes: [
              {
                attributeName: 'Test2 Attribute 1',
                attribututeDataType: 'string',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              },
              {
                attributeName: 'Test2 Attribute 2',
                attribututeDataType: 'boolean',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              },
              {
                attributeName: 'Test2 Attribute 3',
                attribututeDataType: 'boolean',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              },
              {
                attributeName: 'Test2 Attribute 4',
                attribututeDataType: 'boolean',
                isIdentifyingAttribute: 'N',
                isSensitiveData: 'N'
              }
            ]
          }
        ]
      }
    }
  ];