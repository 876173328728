/**
 * @author: Naga
 * @constant
 * @enum
 */
export enum DataFileType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
  XML = 'XML',
  JSON = 'JSON'
}
