import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedDataService } from '../../shared/modules/shared-data/shared-data.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.scss']
})
export class DataSourceComponent implements OnInit, OnDestroy {

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
   private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {sharedDataService<SharedDataService>}
   */
  constructor(private sharedDataService: SharedDataService) {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void { }

  /**
   * @public
   * @return: void
   * @description: a helper method that
   * that opens up data source view based 
   * on selected data source type.
   */
  public viewDataSource(): void {
    const ref: MatDialogRef<any> = this.sharedDataService
    .viewDataSource();

    ref.afterClosed()
    .pipe(takeUntil(this.destroy$))
    .subscribe(v => {});
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
