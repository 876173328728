import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Subscription } from 'rxjs';

import { DataImportService } from 'libs/data-import';
import { SchemaExplorerService } from '../../schema-explorer.service';

import { getTypeMapping } from 'src/app/shared/utils/common-utilities';

@Component({
  selector: 'xfusiontech-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit, OnDestroy {

  @Input() config: any;
  @Input() public item: any;

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  @Output() public whenSelectEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() public whenDeleteEntity: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger, {static: true})
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  menuTopLeftPosition = { x: '0', y: '0' };
  contextMenuType: any;
  menuData: any;
  columnMenuData: any;
  configType: any;

  @Input() priviousConfigData: any;
  selectedIds: any = [];

  @Input('selected')
  set selIds(ids: any) {
    this.selectedIds = ids;
  }

  typeMap: any = getTypeMapping();

  private sub: Subscription;

  constructor(private cdr: ChangeDetectorRef,
              private dataImportService: DataImportService,
              private schemaExplorerService: SchemaExplorerService) {
    this.subscribeEntityDelete();
  }

  ngOnInit() {
    if (this.item && this.item.attributes && this.item.attributes.length > 0) {
      // this.sortEntityAttributes();
    }
    this.configType = this.config.type === 'subject' ? 'subjectToSourceDataMapping' : 'targetToSourceDataMapping';
    if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig
      && this.priviousConfigData.dataMappingConfig[this.configType]) {
      this.priviousConfigData.dataMappingConfig[this.configType].forEach(data => {
        if (data.entityDataMapping.targetEntityName === this.item.entityName) {
          this.callOnCardExpand(this.item);
        }
      });
    }
  }

  /**
   * @description when user expands a particular entity then fetch data from backend for a single entity
   */
  public callOnCardExpand(item) {
    if (!item.attributes && this.config.currentEntityGroupId !== '') {
      this.sub = this.schemaExplorerService.getEntity(this.config.baseApiUrl,
        item.entityID, this.config.currentEntityGroupId).subscribe(entity => {
          if (entity && entity.attributes && entity.attributes.length > 0) {
            item.attributes = entity.attributes;
            item.expanded = !item.expanded ? true : false;
            this.schemaExplorerService.currentSelectedEntityId = item.entityID;
            // this.sortEntityAttributes();
            this.cdr.detectChanges();
            if (this.priviousConfigData && this.priviousConfigData.dataMappingConfig
              && this.priviousConfigData.dataMappingConfig[this.configType]) {
              this.priviousConfigData.dataMappingConfig[this.configType].forEach(data => {
                this.item.attributes.sort((a, b) => a.attributeSequenceNumber - b.attributeSequenceNumber);
                if (data.entityDataMapping.targetEntityName == item.entityName) {
                  this.addEntityForMapping(item);
                }
              });
            }
            if (this.config.context !== 'data-mapping') {
              this.whenSelectEntity.emit(item);
            }

          }
        });
    } else {
      item.expanded = !item.expanded ? true : false;

      if (this.config.context !== 'data-mapping' &&
        this.schemaExplorerService.currentSelectedEntityId !== item.entityID) {
        this.schemaExplorerService.currentSelectedEntityId = item.entityID;
        this.whenSelectEntity.emit(item);
      }

    }
  }

  public addEntityForMapping(entity) {
    this.schemaExplorerService.updateDataMappingEntitiesList(entity, false);
    this.whenSelectEntity.emit(entity);
  }

  public deleteEntityFromMapping(entityId) {
    this.schemaExplorerService.updateDataMappingEntitiesList(entityId, true);
    this.whenDeleteEntity.emit(entityId);
  }

  /**
   * @description It should only be allowed when user is in data mapping config mode
   * It should only be allowed if entity column data is fetched through 'entities/{entityId} API'
   */
  onRightClick(event: MouseEvent, entity: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    if ((entity && entity.attributes && entity.attributes.length > 0) || (entity && entity.attributeName)) {
      this.menuData = { entity, showAdd: !this.selectedIds.includes(entity.entityID) };
    } else {
      this.menuData = { entity, showWarning: true };
    }
    this.contextMenu.openMenu();
  }

  onColumnRightClick(event: MouseEvent, attribute: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.columnMenuData = attribute;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  copyColumn(val) {
    const data = {
      parentEntityName: this.item.entityName,
      parentEntityAttributeName: val.attributeName
    };
    this.dataImportService.setColumnData(data);
  }

  close() {
    this.columnMenuData = '';
  }

  /**
   * @description This function will make sure that entity has primary key, foriegn key loaded in the last
   */
  private sortEntityAttributes() {
    this.item.attributes.sort((a, b) => {
      return (Number(a.foreignKey) - Number(b.foreignKey)) ||
        (Number(a.primaryKey) - Number(b.primaryKey));
    });
  }

  private subscribeEntityDelete() {
    this.sub = this.dataImportService.entityDelete$.subscribe( id => {
      if (id !== null && id !== undefined) {
        this.deleteEntityFromMapping(id);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
