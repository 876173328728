import * as _ from 'lodash/fp';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HorizontalBarsSeries } from '../horizontal-bars-indicator.types';
import { DEFAULT_COLORS } from '../horizontal-bars-indicator.consts';

@Component({
    selector: 'udp-horizontal-bars-indicator',
    templateUrl: './horizontal-bars-indicator.component.html',
    styleUrls: ['./horizontal-bars-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalBarsIndicatorComponent {

    @Input()
    series: HorizontalBarsSeries[];

    @Input()
    usePercent = true;

    get maxValue(): number {
        return this.usePercent
            ? 100
            : _.flowRight(
                _.max,
                _.map((series: HorizontalBarsSeries) => series.value)
            )(this.series);
    }

    getColorAtIndex(idx: number) {
        const color = this.series[idx].color;
        if (color) {
            return color;
        }

        const defaultColorIndex = idx % DEFAULT_COLORS.length;
        return DEFAULT_COLORS[defaultColorIndex];
    }

}
