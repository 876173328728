import { Injectable } from '@angular/core';
import identificationConfig from './identification-config.json';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SubjectIdentificationConfigService {

  identificationConfigDict = identificationConfig;
  identificationConfigArray: Array<any> = identificationConfig.identifiableEntities;
  attributeParams: Array<any> = [];

  constructor() { }

  mapEntitiesToJSON(entityParams, identifyAttributeParams, selectedEntity) {
    this.identificationConfigArray = [];
    this.attributeParams = identifyAttributeParams;
    entityParams.forEach((entityItem, index) => {
      this.identificationConfigArray.push({
        entityName: entityItem.entityName,
        entityType: entityItem.entityType,
        entityClassType: entityItem.entityClass,
        subjectIdentifyingAttributes: {
          targetEntityAttributes: [
            {
              "targetEntityName": "",
              "targetEntityType": "",
              "targetAttributeName": "",
              "targetAttributeDataType": ""
            }
          ]
        },
        subjectSearchConfiguration: {
          searchQueries: [
            {
              queryName: "",
              searchQueryParameters: [
                {
                  "targetEntityName": "",
                  "targetEntityType": "",
                  "targetAttributeName": "",
                  "targetAttributeDataType": ""
                }
              ]
            }
          ]
        },
        subjectScoringRuleSetName: "",
        subjectIdentificationRuleSetName: ""
      })
    });

    //this.identificationConfigArray.shift();

    this.identificationConfigArray.forEach((entityItem, parentIndex) => {
      if (selectedEntity.entityName == entityItem.entityName) {
        identifyAttributeParams['attributeDetails'].forEach((attributeItem) => {
          attributeItem.parameterEntity.forEach(param => {
            this.identificationConfigArray[parentIndex].
              subjectIdentifyingAttributes.targetEntityAttributes.push({
                targetEntityName: attributeItem.entityName.item.entityType,
                targetEntityType: attributeItem.entityName.item.entityType,
                targetAttributeName: param.targetAttributeName.value,
                targetAttributeDataType: param.targetAttributeName.type
              });
          })
        })
        this.identificationConfigArray[parentIndex].
          subjectIdentifyingAttributes.targetEntityAttributes.shift();
      }
    })


    this.identificationConfigArray.forEach(item => {
      item.subjectIdentifyingAttributes.targetEntityAttributes = _.uniq(item.subjectIdentifyingAttributes.targetEntityAttributes);
    })
  }

  getIdentificationAttributes() {
    return this.attributeParams;
  }


  getIdentificationConfig() {
    return this.identificationConfigDict;
  }

  mapSearchParamToJSON(searchParams, selectedEntity) {
    this.identificationConfigArray.forEach((entityItem, parentIndex) => {
      if (selectedEntity.entityName == entityItem.entityName) {
        searchParams['queryDetails'].forEach((searchItem) => {
          this.identificationConfigArray[parentIndex].
            subjectSearchConfiguration.searchQueries.push({
              queryName: searchItem.queryName.queryName,
              searchQueryParameters: []
            });
        })
        if (this.identificationConfigArray[parentIndex].subjectSearchConfiguration.searchQueries[0].queryName == ""
          && this.identificationConfigArray[parentIndex].subjectSearchConfiguration.searchQueries.length > 1) {
          this.identificationConfigArray[parentIndex].subjectSearchConfiguration.searchQueries.shift();
        }
      }
    });

    this.searchQueryParametersMapping(searchParams, selectedEntity);
  }

  searchQueryParametersMapping(searchParams, selectedEntity) {
    this.identificationConfigArray.forEach((entityItem, parentIndex) => {
      if (selectedEntity.entityName == entityItem.entityName) {
        searchParams['queryDetails'].forEach((searchItem) => {
          this.identificationConfigArray[parentIndex].subjectSearchConfiguration.searchQueries.forEach((queryItem, index) => {
            if (searchItem.queryName.queryName == queryItem.queryName) {
              searchItem.parameterEntity.forEach(param => {
                this.identificationConfigArray[parentIndex].
                  subjectSearchConfiguration.searchQueries[index].searchQueryParameters.push({
                    targetEntityName: param.targetEntityName,
                    targetEntityType: param.targetEntityName,
                    targetAttributeName: param.targetEntityAttribute.value,
                    targetAttributeDataType: param.targetEntityAttribute.type
                  });
              });
            }
          });
        });
      }
    })


    this.identificationConfigArray.forEach(item => {
      item.subjectSearchConfiguration.searchQueries = _.uniqBy(item.subjectSearchConfiguration.searchQueries, 'queryName');
      item.subjectSearchConfiguration.searchQueries.forEach(query => {
        query.searchQueryParameters = _.uniq(query.searchQueryParameters);
      })
    })
    this.identificationConfigDict.identifiableEntities = this.identificationConfigArray;
  }

}