import { Component, Input } from '@angular/core';
import { EntityTextIndicatorFieldModel } from './entity-text-indicator.types';

@Component({
    selector: 'udp-entity-text-indicator',
    templateUrl: './entity-text-indicator.component.html',
    styleUrls: ['./entity-text-indicator.component.scss']
})
export class EntityTextIndicatorComponent {

    @Input()
    labelTitle?: string;

    @Input()
    valueTitle?: string;

    @Input()
    fields: EntityTextIndicatorFieldModel[] = [];

}
