import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@xfusiontech/shared';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { User } from '../models/user.model';
import { LOGIN_API } from './../consts/urls';

@Injectable({
    providedIn: 'root',
})
export class ShareService {
    constructor(
        private httpClient: HttpClient,
        private userService: UserService
    ) {}

    getShareToUsers(): Observable<User[]> {
        const dataSourceId = this.userService.currentDataSourceIdSnapshot;
        return this.httpClient
            .get<User[]>(
                `${LOGIN_API}/udp-core/users?dataSourceId=${dataSourceId}`
            )
            .pipe(
                shareReplay(),
                take(1)
            );
    }
}
