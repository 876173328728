import * as _ from 'lodash/fp';

import { TaskCatalogItemParameter } from '../../../types/taskCatalog';
import { ValueObject } from '../../../types/shared';
import { InputType } from '../../shared/form-field/form-field.types';
import { mapParameterTypeToInputType } from './mapParameterTypeToInputType';
import { ParameterType } from '../../../types/form';
import { mapUnionToParameterValue } from '../../utils/union/mapUnionToParameterValue';
import { mapUnionToParameterType } from '../../utils/union/mapUnionToParameterType';
import { getFullParameterType } from './getFullParameterType';
import { mapComplexToParameterValue } from '../../utils/complex/mapComplexToParameterValue';
import { mapComplexToCustomFields } from '../../utils/complex/mapComplexToCustomFields';
import { mapUnionToCustomField } from '../../utils/union/mapUnionToCustomField';

const mapToParameterValue = (inputValue: any, type: InputType) => {
    switch (type) {
        case 'number': {
            return String(inputValue);
        }
        case 'checkbox': {
            return inputValue ? 'True' : 'False';
        }
        case 'union': {
            return mapUnionToParameterValue(inputValue);
        }
        case 'complex': {
            return mapComplexToParameterValue(inputValue);
        }
        default: {
            return inputValue;
        }
    }
};

const mapToParameterUserValueType = (
    inputValue: any,
    type: ParameterType,
    userValueType: ParameterType
) => {
    switch (type) {
        case ParameterType.Union: {
            return mapUnionToParameterType(inputValue);
        }
        default: {
            return userValueType;
        }
    }
};

const mapCustomFields = (
    inputValue: any,
    type: ParameterType,
    param: TaskCatalogItemParameter
) => {
    switch (type) {
        case (ParameterType.Union) : {
            return mapUnionToCustomField(param);
        }
        case (ParameterType.Complex): {
            return mapComplexToCustomFields(inputValue, param);
        }
        default: {
            return param;
        }
    }
};

export const updateParameters = (
    parameters: TaskCatalogItemParameter[],
    values: ValueObject
): TaskCatalogItemParameter[] => _.map(
    (param: TaskCatalogItemParameter) => {
        const parameterType = getFullParameterType(
            param.type as ParameterType,
            param.typeCategory as ParameterType
        );
        const value = values[param.id];

        const result = {
            ...param,
            value: mapToParameterValue(
                value,
                mapParameterTypeToInputType(parameterType)
            ),
            userValueType: mapToParameterUserValueType(
                value,
                parameterType,
                param.userValueType as ParameterType
            )
        };

        return mapCustomFields(value, parameterType, result);
    }
)(parameters);

