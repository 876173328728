import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Optional,
    Output,
    Self,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectionStrategy,
} from '@angular/core';
import * as _ from 'lodash/fp';
import { FormBuilder, FormControl } from '@angular/forms';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
    MatAutocompleteSelectedEvent,
    MatFormFieldControl,
} from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DisplayWithFunction, SearchItemModel, SearchItemTemplateContext, SearchModel, SearchSize } from '../fuzzy-search.types';

@Component({
    selector: 'udp-fuzzy-search',
    templateUrl: './fuzzy-search.component.html',
    styleUrls: ['./fuzzy-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuzzySearchComponent<TItemModel = SearchItemModel>
    implements
        OnDestroy,
        MatFormFieldControl<TItemModel[]>,
        ControlValueAccessor {
    @Input()
    size: SearchSize = 'default';

    @Input()
    placeholder = 'Search';

    @Input()
    required: boolean;

    @Input()
    set model(model: Observable<SearchModel<TItemModel>>) {
        this._model = model;
        this.onModelAssign();
    }
    get model(): Observable<SearchModel<TItemModel>> {
        return this._model;
    }
    private _model: Observable<SearchModel<TItemModel>>;

    @Input()
    itemTemplate: TemplateRef<SearchItemTemplateContext<TItemModel>>;

    @Input()
    displayWith = _.get('label');

    @Input()
    clearOnSelect: boolean;

    @Input()
    maxValues = NaN;

    @Output()
    valueChange = new EventEmitter<string>();

    @Output()
    selectionChange = new EventEmitter<TItemModel>();

    @ViewChild('fuzzySearch', { static: false })
    elementRef: ElementRef;

    items: TItemModel[];
    loading: boolean;

    stateChanges: Observable<void>;
    id: string;
    focused: boolean;
    empty: boolean;
    shouldLabelFloat: boolean;
    disabled: boolean;
    errorState: boolean;
    controlType = 'udp-fuzzy-search';
    autofilled?: boolean;

    onChange: (value: any) => void = () => {};
    onTouched: () => void = () => {};

    private onDestroy$ = new Subject<void>();
    private onModelAssign$ = new Subject<void>();

    private _value: TItemModel[] = [];
    public get value(): TItemModel[] {
        return this._value;
    }
    public set value(value: TItemModel[]) {
        this._value = value;

        this.disabled =
            !isNaN(this.maxValues) && value.length >= this.maxValues;

        this.changeDetector.markForCheck();
    }

    private _inputValue: string;
    public get inputValue(): string {
        return this._inputValue;
    }
    public set inputValue(value: string) {
        if (typeof value !== 'string') {
            return;
        }
        this.valueChange.emit(value);
        this._inputValue = value;
        this.changeDetector.markForCheck();
    }

    get visibleItems() {
        return this.inputValue ? this.items : [];
    }

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private changeDetector: ChangeDetectorRef
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent) {
        this.selectionChange.emit(event.option.value);
        if (!this.clearOnSelect) {
            this.value = [...this.value, event.option.value];
        }
        this.onTouched();
        this.onChange(this.value);
        this.inputValue = '';
    }

    onItemRemove(item: TItemModel) {
        this.value = this.value.filter(i => i !== item);
        this.inputValue = '';
        this.onTouched();
        this.onChange(this.value);
    }

    writeValue(value: TItemModel[]): void {
        this.value = value || [];
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    setDescribedByIds(ids: string[]): void {
        const controlElement = this.elementRef.nativeElement;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    }

    private onModelAssign() {
        this.onModelAssign$.next();
        this.model
            .pipe(
                takeUntil(this.onModelAssign$),
                takeUntil(this.onDestroy$)
            )
            .subscribe(({ loading, items }) => {
                this.loading = loading;
                this.items = items;
                this.changeDetector.markForCheck();
            });
    }

    ngOnDestroy() {
        this.onModelAssign$.next();
        this.onModelAssign$.complete();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
