import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-data-catalog-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @Input() menuData: any;
  @Input() matMenuTriggerFor: string;
  constructor() { }

  ngOnInit() {
  }

}
