import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, Optional } from '@angular/core';

import { GridLayoutModel } from '../../grid-layout/grid-layout.types';
import { WidgetDashboardService } from '../widget-dashboard.service';
import { WidgetModel } from '../../widget/widget.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectCellTypeDialogService } from '../dialogs/select-cell-type-dialog/select-cell-type-dialog.service';

@Component({
    selector: 'udp-widget-dashboard',
    templateUrl: './widget-dashboard.component.html',
    styleUrls: ['./widget-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDashboardComponent implements OnDestroy {

    layout: GridLayoutModel<WidgetModel>;

    private onDestroy$: Subject<void> = new Subject();

    @Input()
    public type: string;

    public dashboardType: string;


    constructor(
        @Optional() private service: WidgetDashboardService,
        private changeDetector: ChangeDetectorRef,
        public dialogService: SelectCellTypeDialogService,
    ) {
      console.log('type',this.type);
        this.service.setType(this.type);
        this.dashboardType = this.type;
        if (!service) {
            throw new Error(
                'WidgetDashboardComponent requires WidgetDashboardService '
                + 'provider to be declared.'
            );
        }

        service.getLayout()
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((layout) => {
                this.layout = layout;
                this.changeDetector.markForCheck();
            });
    }

    onNewCellButtonClick(rowIndex: number, cellIndex: number) {
        this.service.setType(this.type);
        this.service.onNewCellButtonClick(rowIndex, cellIndex, this.type);
    }

    onRemoveCellClick(rowIndex: number, cellIndex: number) {
        this.service.onRemoveCellClick(rowIndex, cellIndex);
    }

    onNewRowButtonClick() {
        this.service.onNewRowButtonClick();
    }

    onRemoveRowClick(rowIndex: number) {
        this.service.onRemoveRowClick(rowIndex);
    }

    trackByIdx(index: number) {
        return index;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
