// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedDataModule } from '../shared/modules/shared-data/shared-data.module';

// Library Imports
import { IconButtonModule } from './../../../../shared';
import { DataImportModule } from './../../../../data-import';
import { DashboardModule, LayoutModule, SidebarModule } from './../../../../interactive-graph-view';


// Components
import { ImportDatasetComponent } from './import-dataset.component';

/**
 * @module
 */
@NgModule({
  declarations: [
    ImportDatasetComponent
  ],
  imports: [
    CommonModule
    , RouterModule
    , LayoutModule
    , SidebarModule
    , DashboardModule
    , IconButtonModule
    , SharedDataModule
    , DataImportModule
  ],
  exports: [
    ImportDatasetComponent
  ]
})
export class ImportDatasetModule { }
