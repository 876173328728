import { Component, Input, OnInit } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { BroadcastService} from './../broadcast.service';
import * as _ from 'lodash';

@Component({
  selector: "app-association-mapping",
  templateUrl: "./association-mapping.component.html",
  styleUrls: ["./association-mapping.component.scss"]
})
export class AssociationMappingComponent implements OnInit {
  public dataintegrationDataObject: any;
  public dataintegrationDataObj: any;
  getNameOfIntegrationObj: any;

  @Input() changed: boolean = false;

  constructor(private apollo: Apollo, private broadcaster: BroadcastService) { }

  ngOnInit() {
    this.broadcaster.dataIntegrationConfigJson$.subscribe(data => {
      if (data) {
        this.dataintegrationDataObject = data;
        this.dataintegrationDataObj = this.getAssociationMappings(this.dataintegrationDataObject);
        
      }
    });

    this.broadcaster.emptyAssociationMapping$.subscribe(() => {
      this.dataintegrationDataObject = {};
      this.dataintegrationDataObj = this.getAssociationMappings(this.dataintegrationDataObject);
      
    });
  }

  ngOnChanges() {
    this.ngOnInit()
  }

  removeDuplicateValues(arr) {
    let result = arr.reduce((accumulator, current) => {
      if (checkIfAlreadyExist(current)) {
        return accumulator;
      } else {
        return [...accumulator, current];
      }

      function checkIfAlreadyExist(currentVal) {
        return accumulator.some((item) => {
          return (item.associationSourceEntityName === currentVal.associationSourceEntityName &&
            item.associationSourceEntityType === currentVal.associationSourceEntityType &&
            item.associationTargetEntityName === currentVal.associationTargetEntityName &&
            item.associationTargetEntityType === currentVal.associationTargetEntityType &&
            item.entityAssociationName === currentVal.entityAssociationName);
        });
      }
    }, []);

    
    return result;
  }

  getAssociationMappings(dataset) {

    this.getNameOfIntegrationObj = _.isEmpty(dataset) ? "" : dataset.sourceData.sourceDataTypeCode;
    let html = "";
    if (!_.isEmpty(dataset)) {
      let filteredResult = this.removeDuplicateValues(dataset.associationDataMappings);
      filteredResult.forEach(
        data => {
          html = html + "<tr>";
          html =
            html +
            '<td><span class="title"> Entity Name: </span><span> ' +
            data.associationSourceEntityName +
            "</span><br>" +
            '<span class="title" > Entity Type: </span><span>' +
            data.associationSourceEntityType +
            "</span></td>";

          html = html + "<td> " + data.entityAssociationName + "</td>";
          html =
            html +
            '<td><span class="title"> Entity Name: </span><span> ' +
            data.associationTargetEntityName +
            "</span><br>" +
            '<span class="title" > Entity Type: </span><span>' +
            data.associationTargetEntityType +
            "</span></td>";

          html = html + "</tr>";
        });
      return html;
    }
  }

}
