import * as go from 'gojs';
import * as _ from 'lodash/fp';

export enum AlignmentType {
    Top = 'Top',
    Bottom = 'Bottom',
    Left = 'Left',
    Right = 'Right',
    Center = 'Center'
}

type Side = 'top' | 'left' | 'bottom' | 'right';
type SideAlignment = Exclude<AlignmentType, 'Center'>;

const alignmentToDimensionMap: Record<SideAlignment, Side> = {
    [AlignmentType.Top]: 'top',
    [AlignmentType.Bottom]: 'bottom',
    [AlignmentType.Left]: 'left',
    [AlignmentType.Right]: 'right',
};

const getSelectionRect = (objects: go.Set<go.Part>) => {
    let selectionRect = objects.first().actualBounds.copy();

    objects.each((obj) =>
        selectionRect = selectionRect.unionRect(obj.actualBounds));

    return selectionRect;
};

const moveBy = (obj: go.Part, dx: number, dy: number) => obj.moveTo(
    obj.actualBounds.x + dx,
    obj.actualBounds.y + dy,
);

export const alignToSide = (objects: go.Set<go.Part>, type: SideAlignment) => {
    const side = alignmentToDimensionMap[type];
    const extremum = getSelectionRect(objects)[side];
    const isMovingVertical = _.includes(type)([
        AlignmentType.Top,
        AlignmentType.Bottom
    ]);

    objects.each((obj) => {
        const offset = extremum - obj.actualBounds[side];
        moveBy(
            obj,
            isMovingVertical ? 0 : offset,
            isMovingVertical ? offset : 0,
        );
    });
};

export const alignToCenter = (objects: go.Set<go.Part>) => {
    const {
        centerX: newCenterX,
        centerY: newCenterY
    } = getSelectionRect(objects);

    objects.each((obj) => {
        const { centerX, centerY } = obj.actualBounds;
        moveBy(
            obj,
            newCenterX - centerX,
            newCenterY - centerY
        );
    });
};

export const align = (objects: go.Set<go.Part>, type: AlignmentType) => {
    if (objects.count === 0) {
        return;
    }

    const { diagram } = objects.first();
    diagram.commit(() => {
        if (type === AlignmentType.Center) {
            alignToCenter(objects);
        } else {
            alignToSide(objects, type);
        }
    });
};
