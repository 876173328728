import { FormControl, FormGroup } from '@angular/forms';
import { FilterItem } from './sidebar-with-filter.interface';

/**
 * @author: Naga
 * @constant
 */
export const FILTERS: FilterItem[] = [
  // {
  //   id: 1,
  //   label: 'Data Sources',
  //   control: 'dataSources'
  // },
  {
    id: 2,
    label: 'Datasets',
    control: 'datasets'
  },
  {
    id: 3,
    label: 'Query',
    control: 'query'
  },
  {
    id: 4,
    label: 'Data File',
    control: 'dataFile'
  },
  // {
  //   id: 5,
  //   label: 'Notebook',
  //   control: 'notebook'
  // },
  // {
  //   id: 6,
  //   label: 'Visualization',
  //   control: 'visualization'
  // }
];

/**
 * @method
 * @constant
 * @param: {filters<FilterItem[]>}
 * @returns: any
 */
export const getMapper: Function = (filters: FilterItem[]): any => {
  const o: any = {};

  for (const filter of filters) {
    o[filter.control] = filter.label;
  }

  return o;
};

/**
 * @method
 * @constant
 * @param: {filters<FilterItem[]>}
 * @returns: FormGroup
 */
export const getFiltersForm: Function = (filters: FilterItem[]): FormGroup => {
  const o: any = {};

  for (const filter of filters) {
    if (!!filter.control) {
      o[filter.control] = new FormControl('');
    }
  }

  return new FormGroup(o);
};
