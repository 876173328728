import * as go from 'gojs';

import { ScrollableContainerOptions, ScrollableContent } from './types';
import { heightBinding, opacityBinding } from './bindings';
import { setHoveredContainer } from './scrollContainer';
import { theme } from '../../../../../consts/theme';
import { scrollbar } from './scrollbar';

const $ = go.GraphObject.make;

export const SCROLLABLE_CONTAINER_NAME = 'SCROLLABLE_CONTAINER';

export const scrollableContainer = (
    content: ScrollableContent,
    options: ScrollableContainerOptions
) => {
    const {
        width,
        height,
        scrollingPanelName,
        offsetX,
        offsetY,
        scrollbarWidth
    } = options;

    return $(
        go.Panel,
        go.Panel.Auto,
        {
            name: SCROLLABLE_CONTAINER_NAME,
            alignmentFocus: go.Spot.TopLeft,
            alignment: new go.Spot(0, 0, offsetX, offsetY),
            mouseEnter: (__, obj: go.Panel) => setHoveredContainer(obj),
            mouseLeave: () => setHoveredContainer(null),
            padding: new go.Margin(2, 0, 2, 0),
        },
        $(
            go.Shape,
            'RoundedRectangle',
            {
                width,
                maxSize: new go.Size(width, height),
                fill: 'transparent',
                alignment: go.Spot.TopCenter,
                stroke: theme.colors.scrollbarContainerStroke,
                strokeWidth: theme.sizes.scrollbarContainerStroke,
                parameter1: 4,
                spot1: go.Spot.TopLeft,
                spot2: go.Spot.BottomRight,
            },
            heightBinding(height, scrollingPanelName),
        ),
        opacityBinding(scrollingPanelName),
        scrollbar({
            scrollContainerHeight: height,
            scrollbarWidth,
            scrollingPanelName,
        }),
        content(),
    );
};
