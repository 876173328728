import { Injectable } from '@angular/core';
import { DiagramService } from './base.service';
import { BehaviorSubject } from 'rxjs';
import { DIAGRAM_SCALE_INITIAL, DIAGRAM_SCALE_MAX, DIAGRAM_SCALE_MIN } from '../consts/shared';

@Injectable()
export class ZoomService extends DiagramService {
    BUTTON_ZOOM_STEP = 0.1;
    SLIDER_ZOOM_STEP = 0.01;

    zoomValue$ = new BehaviorSubject<number>(DIAGRAM_SCALE_INITIAL);
    maxZoom: number = DIAGRAM_SCALE_MAX;
    minZoom: number = DIAGRAM_SCALE_MIN;

    bindDiagram(diagram) {
        super.bindDiagram(diagram);
        this.zoomValue$.next(this.diagram.scale);

        this.diagram.addDiagramListener(
            'ViewportBoundsChanged',
            () => this.zoomValue$.next(
                this.diagram.scale
            )
        );
    }

    setZoom(value: number) {
        this.diagram.scale = value;
    }

    increaseZoom() {
        this.diagram.scale += this.BUTTON_ZOOM_STEP;
    }

    decreaseZoom() {
        this.diagram.scale -= this.BUTTON_ZOOM_STEP;
    }

    zoomToFit() {
        this.diagram.zoomToFit();
    }
}
