import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'udp-inner-page-tabs',
    templateUrl: './inner-page-tabs.component.html',
    styleUrls: ['./inner-page-tabs.component.scss']
})
export class InnerPageTabsComponent implements OnInit {
    @Input() tabs: string[];
    @Output() tabChange: EventEmitter<string> = new EventEmitter();

    selectedTab: string;

    ngOnInit(): void {
        if (this.tabs && this.tabs.length) {
            this.selectedTab = this.tabs[0];
        }
    }

    onTabChange(label: string) {
        this.selectedTab = label;
        this.tabChange.emit(label);
    }
}
