import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../../components/header/header.module';
import { MenuModule } from '../../components/menu/menu.module';
import { ConnectionInfoModule } from './../../components/connection-info/connection-info.module';
import { TopBarModule } from './../../components/top-bar/top-bar.module';
import { HomePageComponent } from './home-page.component';

const components = [HeaderModule, MenuModule, TopBarModule, ConnectionInfoModule];

@NgModule({
    declarations: [HomePageComponent],
    imports: [CommonModule, RouterModule, ...components],
    exports: [HomePageComponent],
})
export class HomePageModule {}
