import { NgModule } from '@angular/core';

import { DiagramFactoryService } from './diagram-factory.service';
import { AlignmentService } from './services/alignment.service';
import { DiagramPaletteService } from './services/diagram-palette.service';
import { EventsService } from './services/events.service';
import { FocusService } from './services/focus.service';
import { ModelSaveService } from './services/model-save.service';
import { ModelService } from './services/model.service';
import { NodeSearchingService } from './services/node-searching.service';
import { OverviewService } from './services/overview.service';
import { RedrawService } from './services/redraw.service';
import { SelectionService } from './services/selection.service';
import { UndoService } from './services/undo.service';
import { ZoomService } from './services/zoom.service';
import { CommandHandler } from './tools/CommandHandler';
import { ModelChangesService } from './services/model-changes.service';
import { TaskGroupInsertService } from './services/task-group-insert.service';
import { LinkingTool } from './tools/LinkingTool';
import { TaskGroupLocationService } from './services/task-group-location.service';
import { ValidationService } from './services/validation/validation.service';
import { LinkValidationService } from './services/validation/link-validation.service';

const diagramServices = [
    DiagramFactoryService,
    AlignmentService,
    DiagramPaletteService,
    EventsService,
    FocusService,
    ModelSaveService,
    ModelService,
    NodeSearchingService,
    OverviewService,
    RedrawService,
    SelectionService,
    UndoService,
    ZoomService,
    ModelChangesService,
    TaskGroupInsertService,
    TaskGroupLocationService,
    ValidationService,
    LinkValidationService
];

const tools = [
    CommandHandler,
    LinkingTool
];

@NgModule({
    providers: [
        ...diagramServices,
        ...tools
    ]
})
export class GoJSModule { }
