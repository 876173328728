import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { DISP_COLUMNS_CONFIG } from './codemaptable.consts';
import * as _ from 'lodash';
import { DataCatalogService } from 'src/app/service/data-catalog.service';
import { Subscription } from 'rxjs';
import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';
import { UdpGrid } from 'libs/schema-explorer/src/lib/entity-attributes/udp-grid';

@Component({
  selector: 'lib-codemaptable',
  templateUrl: './codemaptable.component.html',
  styleUrls: ['./codemaptable.component.scss',
              '../../../../../libs/data-import/src/lib/shared/styles/udp-table.scss']
})
export class CodemaptableComponent  extends UdpGrid implements OnInit {

  @Input() customerId: string;
  @Input() userId: string;
  @Input() selMapping: any;
  config: any;
  public codeValueForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public sorter: (...args) => any[];
  isChecked = false;
  radioValue: any;

  @Input() public type: any;
  @Input() public id: any;
  @Input() public entity: any;
  @Output() closeCodeMap =  new EventEmitter();
  @Output() onCheck =  new EventEmitter();
  @ViewChild(MatTable, null) table: MatTable<any>;

  constructor(private fb: FormBuilder, private dataCatalogService: DataCatalogService) {
    super();
    this.config = DISP_COLUMNS_CONFIG;
    this.displayedColumns = Object.keys(DISP_COLUMNS_CONFIG);
  }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.codeValueForm = this.fb.group({
      searchValue: new FormControl(''),
      attributes: this.fb.array([])
    });

    this.radioValue = {id: this.id, type: this.type};
    if (this.selMapping && this.id === this.selMapping.dataTranslationAssetID) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }

     // initialize the form attributes
    this.initFormArray();

     // set table data source
    this.dataSource = new MatTableDataSource<any>(
       this.attributes.controls
     );


    setTimeout(() => {
      super.ngOnInit();
      this.sorter = this.dataSource.sortData;
      this.dataSource.sortData = this.sortData.bind(this);
    });
  }

  public get attributes(): FormArray {
    return this.codeValueForm.get('attributes') as FormArray;
  }

  private initFormArray(attributes?: any[], list?: any[]): any[] {
    const entity: any = this.entity;
    const formArr: FormArray = this.attributes;
    attributes = this.entity;

    // get list of all the attributes
    // convert them into a group
    if (attributes) {
      for (const attr of attributes) {
      const group: any = {};
      const keys: string[] = _.keys(attr);

      // convert each field into a form control
      _.each(keys, (key) => {
        // in case if the data type is missing
        // preset it to string data type
        if (key === 'dataType' && !attr[key]) {
          attr[key] = 'STRING';
        }

        group[key] = new FormControl(attr[key]);
      });

      // in case if the attribute alias
      // name wasn't returned from the
      // API do add one.
      this.setAttributes(group);

      if (!list) {
        formArr.push(new FormGroup(group));
      } else {
        list.push(new FormGroup(group));
      }

    }
  }

    return list || [];
  }

     private sortData(
      data: FormGroup[], sort: MatSort): any[] {
      const list: any[] = [];

      // normalize the data
      // for sorting to happen
      for (const item of data) {
        list.push(item.value);
      }

      // get the sorted data
      const sorted: any[] = this.sorter.call(
        this, list, sort
      );

      return this.initFormArray(sorted, []);
    }

  private setAttributes(group: any): void {
    group.code = this.setControl(
      'code', group
    );

    group.val = this.setControl(
      'val', group
    );

    group.svarr = this.setControl(
      'svarr', group
    );
  }

  private setControl(attr: any, group: any): any {
    if (!group.hasOwnProperty(attr)) {
      return new FormControl('');
    }
    return group[attr];
  }

changeRadio() {
  this.onCheck.emit(this.radioValue);
}

ngOnDestroy(): void {
  unsubscribeCollection(this.subscriptions);
}
}
