import { Component, OnInit, OnDestroy } from '@angular/core';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-query-dataset',
  templateUrl: './query-dataset.component.html',
  styleUrls: ['./query-dataset.component.scss']
})
export class QueryDatasetComponent implements OnInit, OnDestroy {

  /**
   * @constructor
   */
  constructor() { }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void { }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void { }
}
