import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelectRowTypeDialogComponent } from './dialog/select-row-type-dialog.component';
import { SelectRowTypeDialogService } from './select-row-type-dialog.service';
import { LayoutIconComponent } from './layout-icon/layout-icon.component';
import { ButtonModule } from '../../../button/button.module';

const imports = [
    CommonModule,
    ButtonModule
];

const entryComponents = [
    SelectRowTypeDialogComponent
];

const providers = [
    SelectRowTypeDialogService
];

const components = [
    LayoutIconComponent,
    ...entryComponents
];

@NgModule({
    imports,
    declarations: components,
    providers,
    entryComponents
})
export class SelectRowTypeDialogModule {}
