import * as go from 'gojs';

import { theme } from '../../../../consts/theme';

const $ = go.GraphObject.make;

const TEXT_BLOCK_NAME = 'TEXT_BLOCK';

export const tooltip = (binding: go.Binding) => $(
    go.Adornment,
    go.Panel.Auto,
    $(
        go.Shape,
        'RoundedRectangle',
        {
            fill: theme.colors.tooltip,
            stroke: null,
            parameter1: 3
        }
    ),
    $(
        go.TextBlock,
        {
            name: TEXT_BLOCK_NAME,
            stroke: theme.colors.tooltipText,
            margin: 6,
            font: `${theme.fontSize.tooltip} ${theme.fontFamily}`
        },
        [binding]
    ),
    new go.Binding('visible', 'text', (text) => !!text.length)
        .ofObject(TEXT_BLOCK_NAME)
);
