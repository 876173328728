import { FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AdvancedSearchService } from '../advanced.service';
import { FormItemModel } from '../../global-search.types';
import { ValueObject } from '../../../form-field/form-field.types';

@Component({
    selector: 'udp-advanced-search-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedSearchFormComponent implements OnDestroy {

    form: FormGroup;
    fields: FormItemModel[];

    @Output()
    formSubmit: EventEmitter<ValueObject> = new EventEmitter();

    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private service: AdvancedSearchService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.service.getFormDataForSelectedEntity()
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((searchForm) => {
                this.form = searchForm.group;
                this.fields = searchForm.fields;
                this.changeDetector.markForCheck();
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onSubmit() {
        this.formSubmit.emit(
            this.form.getRawValue()
        );
    }
}
