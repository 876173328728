import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryDialogService } from './category-dialog.service';
import { CategoryDialogComponent } from './category-dialog.component';
import { ButtonModule, FormFieldModule, IconModule } from '@xfusiontech/shared';

@NgModule({
  imports: [
    IconModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    FormFieldModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  declarations: [CategoryDialogComponent],
  entryComponents: [CategoryDialogComponent],
  exports: [CategoryDialogComponent],
  providers: [CategoryDialogService]
})
export class CategoryDialogModule {}
