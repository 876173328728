import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'xft-drawer-expansion-button',
    templateUrl: './drawer-expansion-button.component.html',
    styleUrls: ['./drawer-expansion-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerExpansionButtonComponent {

    @Input()
    position: 'left' | 'right' = 'left';

    @Input()
    open: boolean;

    get icon(): string {
        if (this.open) {
            return this.position === 'left'
                ? 'chevron_left'
                : 'chevron_right';
        } else {
            return this.position === 'left'
                ? 'chevron_right'
                : 'chevron_left';
        }
    }

}
