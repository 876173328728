import {
    DataAssetCategory,
    QueryVariable,
    Query,
} from '@xfusiontech/data-visualizer';

export interface APIQueryDataAssetCategory {
    dataAssetCategoryDescription: string;
    dataAssetCategoryID: string;
    dataAssetCategoryName: string;
    dataAssetType: 'QUERY' | 'VISUALIZATION';
    isActive: boolean;
    queries: {
        documentId: string;
        isActive: boolean;
        userID: string;
        sharedFromUserID: string;
        sharedToUserID: string;
        dataSourceID: string;
        queryID: string;
        isQueryTemplate: boolean;
        queryDescription: string;
        queryLanguage: string;
        queryName: string;
        queryDocument: string;
    }[];
}

export interface APIPostQuery {
    visualization: any;
    query: {
        queryID: string;
        queryDescription?: string;
        queryLanguage?: string;
        queryName?: string;
        isQueryTemplate?: boolean;
        sharedFromUserID?: string;
        sharedToUserID?: string;
        isActive?: boolean;
        queryDocument?: {
            query: string;
            queryVariables: APIQueryVariables[];
        };
    };
}

export interface APIQueryVariables {
    queryVariableName: string;
    queryVariableDataType: string;
    queryVariableValue: any;
    queryVariableParameter: {
        parameterName: string;
        parameterValues: { [key: string]: any };
    }[];
}

export interface APIRunQuery {
    dataSourceID: string;
    dialect: string;
    limit?: number;
    timeout?: number;
    isQueryTemplate: boolean;
    queryVariables: APIQueryVariables[];
    query: string;
    ignoreSelectiveExpansion?: boolean;
    parentNodeIds: string[];
    exclusionNodeCategories: string[];
    exclusionEdgeTypes: string[];
    returnNodeDigest: boolean;
    maxNumberOfNodesToReturn: number;
    limitType: string;
}

// the API tends to change so I implicitly defined the mappings so that in case of change only one place fix is required
export const mapQueryAPIToDataAssetCategory = (
    categories: APIQueryDataAssetCategory[]
): DataAssetCategory[] => {
    return categories.map(category => ({
        dataAssetCategoryDescription: category.dataAssetCategoryDescription,
        dataAssetCategoryID: category.dataAssetCategoryID,
        dataAssetCategoryName: category.dataAssetCategoryName,
        dataAssetType: category.dataAssetType,
        isActive: category.isActive,
        queries: category.queries.map(query => ({
            dataSourceID: query.dataSourceID,
            queryID: query.queryID,
            isQueryTemplate: query.isQueryTemplate,
            queryDescription: query.queryDescription,
            documentId: query.documentId,
            queryLanguage: query.queryLanguage,
            queryName: query.queryName,
            sharedFromUserID: query.sharedFromUserID,
            sharedToUserID: query.sharedToUserID,
            isActive: query.isActive,
            userId: query.userID,
            query: getQueryFromQueryDocument(query.queryDocument),
            variables: getQueryVariablesFromQueryDocument(query.queryDocument),
        })),
    }));
};

export const mapQueryToAPIModel = (query: Query): APIPostQuery => {
    return {
        visualization: null,
        query: {
            queryDescription: query.queryDescription,
            queryLanguage: query.queryLanguage,
            queryName: query.queryName,
            queryID: query.queryID,
            isQueryTemplate: query.isQueryTemplate,
            sharedFromUserID: query.sharedFromUserID,
            sharedToUserID: query.sharedToUserID,
            isActive: query.isActive,
            queryDocument: {
                query: query.query,
                queryVariables: mapQueryVariables(query.variables),
            },
        },
    };
};

export const mapQueryToRunAPIModel = (query: Query): APIRunQuery => {
    return {
        dataSourceID: query.dataSourceID,
        dialect: 'cypher',
        limit: null,
        timeout: null,
        isQueryTemplate: query.isQueryTemplate,
        queryVariables: query.isQueryTemplate
            ? mapQueryVariables(query.variables)
            : null,
        query: query.query,
        ignoreSelectiveExpansion: false,
        parentNodeIds: [],
        exclusionNodeCategories: [],
        exclusionEdgeTypes: [],
        returnNodeDigest: true,
        maxNumberOfNodesToReturn: null,
        limitType: null,
    };
};

const getQueryFromQueryDocument = (document: string): string => {
    const parsed = JSON.parse(document);
    return parsed && 'query' in parsed ? parsed.query : '';
};

const getQueryVariablesFromQueryDocument = (
    document: string
): QueryVariable[] => {
    const parsed = JSON.parse(document);
    return parsed &&
        'queryVariables' in parsed &&
        Array.isArray(parsed.queryVariables) &&
        (parsed.queryVariables as Array<any>).every(x => isQueryVariable(x))
        ? parsed.queryVariables.map(v => ({
              queryVariableName: v.queryVariableName,
              queryVariableDataType: v.queryVariableDataType,
              queryVariableValue: v.queryVariableValue,
              queryVariableParameters:
                  v.queryVariableParameter &&
                  Array.isArray(v.queryVariableParameter)
                      ? v.queryVariableParameter.reduce((acc, val) => {
                            acc[val.parameterName] = val.parameterValues;
                            return acc;
                        }, {})
                      : {},
          }))
        : [];
};

const mapQueryVariables = (variables: QueryVariable[]): APIQueryVariables[] =>
    variables.map(v => ({
        queryVariableName: v.queryVariableName,
        queryVariableDataType: v.queryVariableDataType,
        queryVariableValue: v.queryVariableValue,
        queryVariableParameter: Object.keys(v.queryVariableParameters).map(
            key => ({
                parameterName: key,
                parameterValues: v.queryVariableParameters[key],
            })
        ),
    }));

const isQueryVariable = (o: any): o is QueryVariable => {
    return o && 'queryVariableName' in o && 'queryVariableDataType' in o;
};
