import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * @author: Naga
 */
@Component({
  selector: 'view-entity',
  templateUrl: './view-entity.component.html',
  styleUrls: ['./view-entity.component.scss']
})
export class ViewEntityComponent implements OnInit {

  /**
   * @public
   * @type: {any[]}
   */
  @Input()
  public entity: any;

  /**
   * @public
   * @type: {boolean}
   */
  @Input()
  public actions: boolean = true;

  /**
   * @public
   * @type: {string}
   */
  public actionType: string = '';

  /**
   * @public
   * @type: {string}
   */
  @Input()
  public customerImportFileId: string;

  /**
   * @public
   * @type: {boolean}
   */
   @Input()
   public onlyMetadataDisplay: boolean;

  /**
   * @public
   * @type: {EventEmitter<string>}
   * @output
   */
  @Output()
  public switch: EventEmitter<string> = new EventEmitter<string>();

  /**
   * @constructor
   */
  constructor() {

    // set the default action type
    if(this.onlyMetadataDisplay) {
      this.setActionType('2');
    } else {
      this.setActionType('1');
    }
  }

  /**
   * @public
   * @param: {action<string>}
   * @return: void
   * @description: a helper method that
   * that sets the action type.
   */
  public setActionType(action: string): void {
    this.actionType = action;

    if (!this.actions) {
      this.switch.emit(action);
    }
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
  }
}
