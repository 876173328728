import { Component, Input, ViewEncapsulation } from '@angular/core';

import { IconVariant } from '../../types/icons';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'udp-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IconButtonComponent {
    @Input()
    label: string;

    @Input()
    variant: IconVariant = 'material';

    @Input()
    name: string;

    @Input()
    tooltipMessage: string;

    @Input()
    tooltipPosition: TooltipPosition = 'after';

}
