import * as go from 'gojs';

import { ScrollMouseTool } from './scrollMouseTool';

import {
    heightBinding,
    scrollbarThumbAlignmentBinding,
    scrollbarThumbHeightBinding,
    scrollbarVisibilityBinding
} from './bindings';

const $ = go.GraphObject.make;

type ScrollbarOptions = {
    scrollContainerHeight: number;
    scrollingPanelName: string;
    scrollbarWidth: number;
};

export const scrollbar = ({
    scrollContainerHeight,
    scrollingPanelName,
    scrollbarWidth,
}: ScrollbarOptions) => $(
    go.Panel,
    go.Panel.Auto,
    {
        alignmentFocus: go.Spot.TopCenter,
        alignment: go.Spot.TopRight,
    },
    $(
        go.Shape,
        'RoundedRectangle',
        {
            fill: '#dedede',
            stroke: null,
            strokeWidth: 0,
            width: scrollbarWidth,
            alignment: go.Spot.TopCenter,
            spot1: go.Spot.TopLeft,
            spot2: go.Spot.BottomRight,
        },
        heightBinding(scrollContainerHeight, scrollingPanelName),
    ),
    $(
        go.Shape,
        'RoundedRectangle',
        {
            fill: '#afafaf',
            stroke: null,
            strokeWidth: 0,
            desiredSize: new go.Size(scrollbarWidth, NaN),
            alignment: go.Spot.TopCenter,
            spot1: go.Spot.TopLeft,
            spot2: go.Spot.BottomRight,
            isActionable: true,
            actionDown: ScrollMouseTool.captureMouse,
            actionUp: ScrollMouseTool.releaseMouse,
            actionMove: ScrollMouseTool.moveHandle(
                scrollContainerHeight,
                scrollingPanelName
            )
        },
        scrollbarThumbHeightBinding(
            scrollContainerHeight,
            scrollingPanelName
        ),
        scrollbarThumbAlignmentBinding(
            scrollContainerHeight,
            scrollingPanelName
        ),
    ),
    scrollbarVisibilityBinding(
        scrollContainerHeight,
        scrollingPanelName
    ),
);
