import { ReplaySubject } from 'rxjs';
import { META_DATA } from './meta-data';
import { Component, Input, OnInit } from '@angular/core';
import { SharedDataService } from '../shared-data.service';

/**
 * @author: Naga
 */
@Component({
  selector: 'lib-meta-data',
  templateUrl: './meta-data.component.html',
  styleUrls: ['./meta-data.component.scss'] 
})
export class MetaDataComponent implements OnInit {

  /**
   * @public
   * @type: {any[]}
   * @input
   */
  @Input()
  public entities: any[] = [];

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public action: boolean = false;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public disable: boolean = false;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public enableSelect: boolean = false;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public displayOnlyEntity: boolean = false;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   */
  constructor(
    private _sharedDataService: SharedDataService
  ) {}

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    // this.entities = [...META_DATA];
    this._sharedDataService.fileSchema$.subscribe((data: any) => {
      if (data.card === 'LEFT_DATA_ASSET') {
        this.entities.push(data.fileSchema);
      } else if (data.card === 'RIGHT_DATA_ASSET') {
        this.entities.push(data.fileSchema);
      } else {
        this.entities = data.fileSchema;
      }
    });
  }

  /**
   * @public
   * @param: {actionType<string>}
   * @return: void
   * @description: a helper method that
   * triggers the requested action.
   */
  public onAction(actionType: string): void {
    switch(actionType) {
      case 'SELECT': {
        this.selectAll();
        break;
      }
      case 'DESELECT': {
        this.deselectAll();
        break;
      }
    }
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * selects all the attributes.
   */
  private selectAll(): void {
    this.setSelection(true);
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * deselects all the attributes.
   */
  private deselectAll(): void {
    this.setSelection(false);
  }

  /**
   * @private
   * @param: {value<boolean>}
   * @return: void
   * @description: a helper method that
   * sets the attribute selection state.
   */
  private setSelection(value: boolean): void {
    const entities: any[] = JSON.parse(
      JSON.stringify(this.entities)
    );
    this.entities = [];

    for (const entity of entities) {
      for (const attr of entity.entityAttributes) {
        attr.selected = value;
      }
    }

    this.entities = entities;
  }

  /**
   * @private
   * @param: {value<boolean>}
   * @return: void
   * @description: a helper method that
   * sets row expansion state.
   */
  private setExpanded(value: boolean): void {
    for (const entity of this.entities) {
      entity.expanded = value;
    }
  }

  /**
   * @public
   * @param: {obj<any>}
   * @param: {key<string>}
   * @return: void
   * @description: a helper method that
   * toggles the attributes selection.
   */
  public onAttrChange(obj: any, key: string): void {
    obj[key] = !obj[key];
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.setExpanded(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
