// tslint:disable:max-line-length
export const createEmptyWorkflow = () => {
   return {
      NodeID: null,
      WorkflowID: '',
      WorkflowName: '',
      WorkflowVersion: '',
      WorkflowDescription: '',
      WorkflowDefination: {
         filename: '',
         dag: {
            description: '',
            name: '',
            parameters: [
               {
                  description: 'The id of the DAG',
                  id: 'dag_id',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  value: 'id',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: '',
                  description: 'The description for the DAG to e.g. be shown on the webserver',
                  id: 'description',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: '0 * * * *',
                  description: 'Defines how often that DAG runs, this timedelta object gets added to your latest task instance\'s execution_date to figure out the next schedule',
                  id: 'schedule_interval',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  value: '0 * * * *',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: 'datetime(2020,11,1)',
                  description: 'The timestamp from which the scheduler will attempt to backfill',
                  id: 'start_date',
                  inheritedFrom: null,
                  required: true,
                  type: 'datetime.datetime',
                  typeCategory: 'elem',
                  userValueType: 'datetime.datetime',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'A date beyond which your DAG won\'t run, leave to None for open ended scheduling',
                  id: 'end_date',
                  inheritedFrom: null,
                  required: false,
                  type: 'datetime.datetime',
                  typeCategory: 'elem',
                  userValueType: 'datetime.datetime',
                  typeSubCategories: null,
                  value: null
               },
               {
                  default: null,
                  description: 'This list of folders (non relative) defines where jinja will look for your templates. Order matters. Note that jinja/airflow includes the path of your DAG file by default',
                  id: 'template_searchpath',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: '',
                  description: 'Template undefined type.',
                  id: 'template_undefined',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  value: '',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'a dictionary of macros that will be exposed in your jinja templates. For example, passing ``dict(foo=\'bar\')`` to this argument allows you to ``{{ foo }}`` in all jinja templates related to this DAG. Note that you can pass any type of object here.',
                  id: 'user_defined_macros',
                  inheritedFrom: null,
                  required: false,
                  type: 'dict',
                  typeCategory: 'dict',
                  userValueType: 'dict',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'a dictionary of filters that will be exposed in your jinja templates. For example, passing ``dict(hello=lambda name: \'Hello %s\' % name)`` to this argument allows you to ``{{ \'world\' | hello }}`` in all jinja templates related to this DAG.',
                  id: 'user_defined_filters',
                  inheritedFrom: null,
                  required: false,
                  type: 'dict',
                  typeCategory: 'dict',
                  userValueType: 'dict',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'A dictionary of default parameters to be used as constructor keyword parameters when initialising operators. Note that operators have the same hook, and precede those defined here, meaning that if your dict contains `\'depends_on_past\': True` here and `\'depends_on_past\': False` in the operator\'s call `default_args`, the actual value will be `False`.',
                  id: 'default_args',
                  inheritedFrom: null,
                  required: false,
                  type: 'dict',
                  typeCategory: 'dict',
                  userValueType: 'dict',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'a dictionary of DAG level parameters that are made accessible in templates, namespaced under `params`. These params can be overridden at the task level.',
                  id: 'params',
                  inheritedFrom: null,
                  required: false,
                  type: 'dict',
                  typeCategory: 'dict',
                  userValueType: 'dict',
                  typeSubCategories: null
               },
               {
                  default: '16',
                  description: 'the number of task instances allowed to run concurrently',
                  id: 'concurrency',
                  inheritedFrom: null,
                  required: false,
                  type: 'int',
                  value: '16',
                  typeCategory: 'elem',
                  userValueType: 'int',
                  typeSubCategories: null
               },
               {
                  default: '16',
                  description: 'maximum number of active DAG runs, beyond this number of DAG runs in a running state, the scheduler won\'t create new active DAG runs',
                  id: 'max_active_runs',
                  inheritedFrom: null,
                  required: false,
                  type: 'int',
                  value: '16',
                  typeCategory: 'elem',
                  userValueType: 'int',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'specify how long a DagRun should be up before timing out / failing, so that new DagRuns can be created. The timeout is only enforced for scheduled DagRuns, and only once the # of active DagRuns == max_active_runs.',
                  id: 'dagrun_timeout',
                  inheritedFrom: null,
                  required: false,
                  type: 'datetime.timedelta',
                  typeCategory: 'elem',
                  userValueType: 'datetime.timedelta',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'specify a function to call when reporting SLA timeouts.',
                  id: 'sla_miss_callback',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'Specify DAG default view (tree, graph, duration, gantt, landing_times)',
                  id: 'default_view',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: 'LR',
                  description: 'Specify DAG orientation in graph view (LR, TB, RL, BT)',
                  id: 'orientation',
                  inheritedFrom: null,
                  required: false,
                  type: 'str',
                  value: 'LR',
                  typeCategory: 'elem',
                  userValueType: 'str',
                  typeSubCategories: null
               },
               {
                  default: 'True',
                  description: 'Perform scheduler catchup (or only run latest)? Defaults to True',
                  id: 'catchup',
                  inheritedFrom: null,
                  required: false,
                  type: 'bool',
                  value: 'True',
                  typeCategory: 'elem',
                  userValueType: 'bool',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'A function to be called when a DagRun of this dag fails. A context dictionary is passed as a single parameter to this function.',
                  id: 'on_failure_callback',
                  inheritedFrom: null,
                  required: false,
                  type: 'callable',
                  typeCategory: 'elem',
                  userValueType: 'callable',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'Much like the ``on_failure_callback`` except that it is executed when the dag succeeds.',
                  id: 'on_success_callback',
                  inheritedFrom: null,
                  required: false,
                  type: 'callable',
                  typeCategory: 'elem',
                  userValueType: 'callable',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: `Specify optional DAG-level permissions, e.g., "{
                            "role1":{
                               "can_dag_read"
                            },
                            "role2":{
                               "can_dag_read",
                               "can_dag_edit"
                            }
                         }"`,
                  id: 'access_control',
                  inheritedFrom: null,
                  required: false,
                  type: 'dict',
                  typeCategory: 'dict',
                  userValueType: 'dict',
                  typeSubCategories: null
               },
               {
                  default: null,
                  description: 'Specifies if the dag is paused when created for the first time. If the dag exists already, this flag will be ignored. If this optional parameter is not specified, the global config setting will be used.',
                  id: 'is_paused_upon_creation',
                  inheritedFrom: null,
                  required: false,
                  type: 'bool',
                  typeCategory: 'elem',
                  userValueType: 'bool',
                  typeSubCategories: null
               }
            ]
         },
         nodes: {},
         links: {}
      }
   };
};
// tslint:enable
