import * as _ from 'lodash/fp';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NodeViewModel } from '../properties-editor.types';

@Component({
    selector: 'xft-properties-editor-node-view',
    templateUrl: './properties-editor-node-view.component.html',
    styleUrls: ['./properties-editor-node-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesEditorNodeViewComponent {

    @Input()
    model: NodeViewModel;

    get truncatedDescription() {
        return _.truncate({
            length: 200
        })(this.model.description);
    }

}
