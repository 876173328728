import { Injectable } from '@angular/core';

import { INITIAL_OPTIONS_SELECTION, LAYOUT_OPTIONS } from './select-row-type-dialog.consts';
import { RowLayoutOption } from './select-row-type-dialog.types';

@Injectable()
export class SelectRowTypeDialogService {

    getOptions(): RowLayoutOption[] {
        return LAYOUT_OPTIONS;
    }

    getInitialSelection(): boolean[] {
        return INITIAL_OPTIONS_SELECTION;
    }

}
