export class LinkLoadConfiguration {
    /*Scoring Configuration Add Popup data*/
    public LNL_EntityNameSet = [
        { description: "Person", id: "Person", parentID: "CMS" },
        { description: "Address", id: "Address", parentID: "CMS" },
        { description: "ChildClient", id: "ChildClient", parentID: "CMS" },
        { description: "Race", id: "Race", parentID: "CMS" },
        { description: "Gender", id: "Gender", parentID: "CMS" },
        { description: "Ethnicity", id: "Ethnicity", parentID: "CMS" },
        { description: "Identity", id: "Identity", parentID: "CMS" },
        { description: "PhysicalDetails", id: "PhysicalDetails", parentID: "CMS" },
        { description: "PersonName", id: "PersonName", parentID: "CMS" },
        { description: "Case", id: "Case", parentID: "CMS" },
        { description: "Allegation", id: "Allegation", parentID: "CMS" },
        { description: "Allegation Disposition", id: "Allegation Disposition", parentID: "CMS" },
        { description: "Adoption HomeStudy", id: "Adoption HomeStudy", parentID: "CMS" },
        { description: "Adoptive Placement", id: "Adoptive Placement", parentID: "CMS" },
        { description: "Adoption Agency", id: "Adoption Agency", parentID: "CMS" },
        { description: "FacilityAddress", id: "FacilityAddress", parentID: "LIS-FAS" },
        { description: "Facility", id: "Facility", parentID: "LIS-FAS" },
        { description: "Visits", id: "Visits", parentID: "LIS-FAS" },
        { description: "Licensee", id: "Licensee", parentID: "LIS-FAS" }
    ];
    public LNL_EntityClassTypeSet = [
        { description: "PRIMARY_SUBJECT", id: "PRIMARY_SUBJECT", parentID: "Person" },
        { description: "RELATED SUBJECT", id: "RELATED SUBJECT", parentID: "Address" },
        { description: "PRIMARY SUBJECT ROLE", id: "PRIMARY SUBJECT ROLE", parentID: "ChildClient" },
        { description: "SUBJECT ATTRIBUTE", id: "SUBJECT ATTRIBUTE", parentID: "Race" },
        { description: "SUBJECT ATTRIBUTE", id: "SUBJECT ATTRIBUTE", parentID: "Gender" },
        { description: "SUBJECT ATTRIBUTE", id: "SUBJECT ATTRIBUTE", parentID: "Ethnicity" },
        { description: "SUBJECT ATTRIBUTE", id: "SUBJECT ATTRIBUTE", parentID: "Identity" },
        { description: "SUBJECT ATTRIBUTE", id: "SUBJECT ATTRIBUTE", parentID: "PhysicalDetails" },
        { description: "SUBJECT ATTRIBUTE", id: "SUBJECT ATTRIBUTE", parentID: "PersonName" },
        { description: "EVENT/TRANSACTION", id: "EVENT/TRANSACTION", parentID: "Case" },
        { description: "EVENT/TRANSACTION", id: "EVENT/TRANSACTION", parentID: "Allegation" },
        { description: "EVENT/TRANSACTION", id: "EVENT/TRANSACTION", parentID: "Allegation Disposition" },
        { description: "EVENT/TRANSACTION", id: "EVENT/TRANSACTION", parentID: "Adoption HomeStudy" },
        { description: "EVENT/TRANSACTION", id: "EVENT/TRANSACTION", parentID: "Adoptive Placement" },
        { description: "RELATED SUBJECT", id: "RELATED SUBJECT", parentID: "Adoption Agency" },
        { description: "RELATED SUBJECT", id: "RELATED SUBJECT", parentID: "FacilityAddress" },
        { description: "RELATED SUBJECT", id: "RELATED SUBJECT", parentID: "Facility" },
        { description: "EVENT/TRANSACTION", id: "EVENT/TRANSACTION", parentID: "Visits" },
        { description: "PRIMARY_SUBJECT", id: "PRIMARY_SUBJECT", parentID: "Licensee" }
    ];
}
