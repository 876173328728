// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDataModule } from './../shared/modules/shared-data/shared-data.module';
import { MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatRadioModule, MatSlideToggleModule, MatTableModule, MatTooltipModule } from '@angular/material';

// Library Imports
import { DashboardModule, LayoutModule, SidebarModule } from './../../../../interactive-graph-view';
import { ButtonModule, FormFieldModule, IconButtonModule, IconModule, SnackbarModule, TabsModule } from './../../../../shared';
// Components
import { BlendDatasetsComponent } from './blend-datasets.component';
import { SelectMetadataComponent } from './select-metadata/select-metadata.component';
import { ConfigErrorsComponent } from './config-errors/config-errors.component';

import { SharedModule } from 'libs/data-catalog/src/lib/shared/shared.module';
/**
 * @author: Naga
 * @constant
 */
const EXPORTS: any[] = [
  BlendDatasetsComponent
];

/**
 * @module
 */
@NgModule({
  declarations: [
    ...EXPORTS
    , SelectMetadataComponent,
    ConfigErrorsComponent
  ],
  imports: [
    CommonModule
    , IconModule
    , TabsModule
    , FormsModule
    , ButtonModule
    , LayoutModule
    , RouterModule
    , SidebarModule
    , MatCardModule
    , MatIconModule
    , MatRadioModule
    , MatTableModule
    , SnackbarModule
    , DragDropModule
    , MatDialogModule
    , DashboardModule
    , FormFieldModule
    , MatButtonModule
    , IconButtonModule
    , MatTooltipModule
    , SharedDataModule
    , MatCheckboxModule
    , ReactiveFormsModule
    , MatSlideToggleModule
    , MatButtonToggleModule
    , SharedModule
  ],
  exports: [...EXPORTS],
  entryComponents: [SelectMetadataComponent, ConfigErrorsComponent]
})
export class BlendDatasetsModule { }
