import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { shareReplay, take, map, mergeMap } from 'rxjs/operators';

import { DataAssetCategory } from '@xfusiontech/data-visualizer';

import { UtilService } from './util-service';
import { APIQueryDataAssetCategory, mapQueryAPIToDataAssetCategory } from 'libs/query-editor/src/lib/query-api-mapper';

import { environment } from 'src/environments/environment';

/**
 * @constant
 */
const baseUrl = environment.dataAssetCategory;
const dataAssetPermissionUrl = environment.dataAssetPermissionURL;
const visualizations = environment.visualizations;
@Injectable({
    providedIn: 'root'
})
export class DataCatalogService {
    private userId: string;
    private customerId: string;
    selectedCategory: any = new BehaviorSubject<any>(null);
    selectedDataAsset: any = new BehaviorSubject<any>(null);
    newDataAssetInfo: any = new BehaviorSubject<any>(null);
    uploadSaveAsset: any = new BehaviorSubject<any>(null);
    saveQueryInfo: any = new BehaviorSubject<any>(null);
    newDataAssetClosed: any = new BehaviorSubject<any>(null);
    uploadS3FileInfo: any = new BehaviorSubject<any>(null);
    uploadS3FileSelectedCategory: any = new BehaviorSubject<any>(null);
    uploadS3FileDataAssetName: any = new BehaviorSubject<any>(null);
    newCategoryInfo: any = new BehaviorSubject<any>(null);
    sidebarToggle: any = new BehaviorSubject<any>(null);
    selectedDSCategory: any = new BehaviorSubject<any>(null);
    blendDataSet: any = new BehaviorSubject<any>(null);
    dataStudio: any = new BehaviorSubject<any>(null);
    fileUpload: any = new BehaviorSubject<any>(null);
    backToExplorer: any = new BehaviorSubject<any>(null);
    savedCategory: any = new BehaviorSubject<any>(null);
    queryDataAssetInfo: any = new BehaviorSubject<any>(null);
    queryClose: any = new BehaviorSubject<any>(null);
    closeQueryController: any = new BehaviorSubject<any>(null);
    reloadCategories: any = new BehaviorSubject<any>(false);
    addClass: any = new BehaviorSubject<any>(null);
    addSubClass: any = new BehaviorSubject<any>(null);
    addCodeset: any = new BehaviorSubject<any>(null);
    closeMapTab: any = new BehaviorSubject<any>(null);
    addNewCodeset: any = new BehaviorSubject<any>(null);
    versionCodemapData: any = new BehaviorSubject<any>(null);
    addRefData: any = new BehaviorSubject<any>(false);
    selectedCodeMap: any = new BehaviorSubject<any>(null);
    lastQueryScript: any = new BehaviorSubject<any>(null);



    protected dataAssetCategories = new BehaviorSubject<DataAssetCategory[]>(
        []
    );
    public dataAssetCategories$ = this.dataAssetCategories.asObservable();
    public selectedCategory$ = this.selectedCategory.asObservable();
    public selectedDataAsset$ = this.selectedDataAsset.asObservable();
    public selectedDSCategory$ = this.selectedDSCategory.asObservable();
    public sidebarToggle$ = this.sidebarToggle.asObservable();
    public newDataAssetInfo$ = this.newDataAssetInfo.asObservable();
    public uploadSaveAsset$ = this.uploadSaveAsset.asObservable();
    public saveQueryInfo$ = this.saveQueryInfo.asObservable();
    public newDataAssetClosed$ = this.newDataAssetClosed.asObservable();
    public uploadS3FileInfo$ = this.uploadS3FileInfo.asObservable();
    public uploadS3FileSelectedCategory$ = this.uploadS3FileSelectedCategory.asObservable();
    public uploadS3FileDataAssetName$ = this.uploadS3FileDataAssetName.asObservable();
    public blendDataSet$ = this.blendDataSet.asObservable();
    public dataStudio$ = this.dataStudio.asObservable();
    public fileUpload$ = this.fileUpload.asObservable();
    public backToExplorer$ = this.backToExplorer.asObservable();
    public savedCategory$ = this.savedCategory.asObservable();
    public queryDataAssetInfo$ = this.queryDataAssetInfo.asObservable();
    public queryClose$ = this.queryClose.asObservable();
    public closeQueryController$ = this.closeQueryController.asObservable();
    public newCategoryInfo$ = this.newCategoryInfo.asObservable();
    public reloadCategories$ = this.reloadCategories.asObservable();
    public addClass$ = this.addClass.asObservable();
    public addSubClass$ = this.addSubClass.asObservable();
    public addCodeset$ = this.addCodeset.asObservable();
    public closeMapTab$ = this.closeMapTab.asObservable();
    public addNewCodeset$ = this.addNewCodeset.asObservable();
    public versionCodemapData$ = this.versionCodemapData.asObservable();
    public selectedCodeMap$ = this.selectedCodeMap.asObservable();
    public lastQueryScript$ = this.lastQueryScript.asObservable();
    public addRefData$ = this.addRefData.asObservable();
    public updateNotifier: Subject<any> = new Subject();
    public sidebarExpanded: Subject<any> = new Subject();

    querySessionKey: any;

    constructor(
        private util: UtilService,
        private http: HttpClient
    ) { }

    /**
     *
     * @param assetType
     * @param dataSourceID
     * @returns
     */
    getQueryAsset(assetType, dataSourceID) {
        return this.http.get(`${environment.queryAsset}assetType/${assetType}/dataSource/${dataSourceID}`);
    }

    /**
     *
     * @param datasetID
     * @returns
     */
    getBlendRule(datasetID) {
        return this.http.get(`${environment.dataAssetURL}schemaMetadata?datasetID=${datasetID}`);
    }

    /**
     *
     * @param datasetID
     * @param payload
     * @returns
     */
    saveBlendDataRule(datasetID, payload) {
        return this.http.put(`${environment.dataAssetURL}schemaMetadata?datasetID=${datasetID}`, payload);
    }

    /**
     *
     * @param ownerUserID
     * @param createUserID
     * @param data
     * @returns
     */
    postDAC(ownerUserID, createUserID, data) {
        return this.http.post(`${environment.dataAssetCategory}users/${ownerUserID}/${createUserID}/dataAssetCategory`, data);
    }

    createNewProject(data: any) {
        return this.http.post(`${environment.workspaceUIURL}new-project`, data);
    }

    updateProject(data: any) {
        return this.http.put(`${environment.workspaceUIURL}edit-project`, data);
    }

    /**
     *
     * @param dataAssetTypes
     * @returns
     */
    getDataAssestCategories(dataAssetTypes, customerID, userID) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${environment.visualizations}customer/${customerID}/user/${userID}/assets/${dataAssetTypes}/data-asset-categories`);
    }

    /**
     *
     * @param data
     * @param type
     * @returns
     */
    addDataAsset(data, type, generateAssetSchema,userId,customerId) {
        return this.http.post(`${environment.dataAssetURL}saveAsset?type=${type}&generateAssetSchema=${generateAssetSchema}&userID=${userId}&customerID=${customerId}`, data);
    }
    /**
     *
     * @param dataAssetId
     * @param type
     * @returns
     */
    getDataAssetById(dataAssetId, type) {
        return this.http.get(`${environment.dataAssetURL}asset?type=${type}&assetID=${dataAssetId}`);
    }

    /**
     *
     * @param dataSetId
     * @param dataAssetType
     * @param data
     * @returns
     */
    getDataAssetDetails(dataSetId, dataAssetType) {
        return this.http.get(`${environment.dataAssetURL}assetdetails?type=${dataAssetType}&dataAssetID=${dataSetId}`);
    }

    /**
     *
     * @param dataAssetCategoryID
     * @param type
     * @returns
     */
    getDataAssetsByType(dataAssetCategoryID, type) {
        return this.http.get(`${environment.dataAssetURL}allassets?dataAssetCategoryID=${dataAssetCategoryID}&type=${type}`);
    }

    /**
     *
     * @param data
     * @returns
     */
    getDataAssetList(data) {
        return this.http.put(`${environment.dataAssetCategory}getDataAssets/lists`, data);
    }

    /**
     *
     * @param data
     * @returns
     */
    getAssetLists(dataAssetCategoryID: string, type: string) {
        return this.http.get(`${environment.dataAssetURL}allassets?dataAssetCategoryID=${dataAssetCategoryID}&type=${type}`);
    }

    /**
     *
     * @returns
     */
    getDacById(dacId) {
        return this.http.get(`${baseUrl}${dacId}`);
    }

    /**
     *
     * @returns
     */
    getUserPermissions(dacId, customerid, userId) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${dataAssetPermissionUrl}customer/${customerid}/user/${userId}/data-asset-category/${dacId}/user-permissions`);
    }

    /**
     *
     * @param updatedData
     * @returns
     */
    updateDac(ownerID, updateUserID, updatedData) {
        return this.http.put(`${baseUrl}users/${ownerID}/${updateUserID}/dataAssets`, updatedData);
    }

    /**
     *
     */
    queryDataAssetsCategories(): void {
        const URL = `${visualizations}users/${this.userId}/dataAssets/?assetType=QUERY`;
        const query = this.http.get<APIQueryDataAssetCategory[]>(URL);

        query
            .pipe(
                shareReplay(),
                take(1),
                map(categories => mapQueryAPIToDataAssetCategory(categories))
            )
            .subscribe(categories => this.dataAssetCategories.next(categories));
    }
    getTags(dacId: any) {
        // return this.http.get(`${baseUrl}${dacId}`);
        return of(['Patient', 'COVID-19']);
    }
    getGlosseryTerms(dacId: any, type: string) {
        return this.http.get(`${environment.tagMetadataURL}businessglossary/${type}/${dacId}`);
        // return of(['Claims', 'Immunization']);
    }
    updateDataAsset(data: any, type: string) {
        return this.http.put(`${environment.dataAssetURL}updateAsset?type=${type}`, data);
    }

    generateSchema(type: any, withSave: boolean, userID, customerID, data: any) {
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.dataAssetURL}generateSchema?type=${type}&withSave=${withSave}&userID=${userID}&customerID=${customerID}`, data);
    }

    getDataAsset(assetID: string, type: string) {
        return this.http.get(`${environment.dataAssetURL}asset?type=${type}&assetID=${assetID}`);
    }

    // Reference Datasets APIs
    getReferenceDatasets(customerID) {
        return this.http.get(`${environment.refBaseURL}customer/${customerID}/refds`);
    }

    getRefDatasetVersions(datasetID, customerID) {
        return this.http.get(`${environment.refBaseURL}customer/${customerID}/refds/${datasetID}/version`);
    }

    getRefCodesetClass(versionID, customerID) {
        return this.http.get(`${environment.refBaseURL}customer/${customerID}/refds/version/${versionID}/class`);
    }

    getRefSubCodesetClass(classID, customerID) {
        return this.http.get(`${environment.refBaseURL}customer/${customerID}/refds/version/class/${classID}/subclass`);
    }

    updateRefCodeValueMap(codeType, id, customerid, userid, payload) {
        // tslint:disable-next-line: max-line-length
        return this.http.put(`${environment.refBaseURL}customer/${codeType}/${id}/CodeValueMap?customerID=${customerid}&userID=${userid}`, payload);
    }

    createRefDataSet(codeType, customerid, userid, payload) {
        return this.http.post(`${environment.refBaseURL}customer/${codeType}/create?customerID=${customerid}&userID=${userid}`, payload);
    }

    updateRefDataSet(codeType, id, customerid, userid, payload) {
        return this.http.put(`${environment.refBaseURL}customer/${codeType}/${id}?customerID=${customerid}&userID=${userid}`, payload);
    }

    uploadFile(codeType, id, customerid, userid, payload) {
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.refBaseURL}customer/${codeType}/${id}/file?customerID=${customerid}&userID=${userid}`, payload);
    }

    getDatasetCodeValueById(customerID, type, datasetID, isParent) {
      return this.http.get(`${environment.refBaseURL}customer/${customerID}/${type}/${datasetID}/CodeValueMap?parentInfo=${isParent}`);
  }

    /**
     *
     * @param payload
     * @returns
     */
    userSession(payload) {
        return this.http.post(`${environment.userDataSession}`, payload);
    }

    public initRunQuerySession(userID: string, customerID, dataAssetIDParam, dataAssetTypeParam): Observable<any> {
        const initSessionPayload = {
            userID,
            customerID,
            actionType: 'SES',
            actionCode: 'SES_INIT',
            workflowDefination: null,
            dataAssetID: null,
            dataAssetType: null,
            actionParameters: null,
            sessionKey: null,
            taskContentRequest: null,
            queryScript: null,
            dataBlendRule: null
        };

        return this.http.post(`${environment.userDataSession}`, initSessionPayload).pipe(
            map((initSessionData) => {
                const initSessionKey = initSessionData !== null && initSessionData !== undefined && initSessionData;
                this.querySessionKey = initSessionKey;
                return {
                    userID,
                    customerID,
                    actionType: 'UQS',
                    actionCode: 'INIT_QRY_SES',
                    workflowDefination: null,
                    dataAssetID: dataAssetIDParam,
                    dataAssetType: dataAssetTypeParam,
                    sessionKey: initSessionKey,
                    taskContentRequest: null,
                    queryScript: null,
                    dataBlendRule: null
                };
            }),
            mergeMap(initQuerySessionPayload => this.http.post(`${environment.userDataSession}`, initQuerySessionPayload))
            , take(1)
        );
    }

    public runQuery(userID: string, customerID, dataAssetIDParam, dataAssetTypeParam, queryScriptParam): Observable<any> {
        return this.initRunQuerySession(userID, customerID, dataAssetIDParam, dataAssetTypeParam).pipe(
            map((initQuerySession) => {
                return {
                    userID,
                    customerID,
                    actionType: 'UQS',
                    actionCode: 'RUN_QRY',
                    workflowDefination: null,
                    dataAssetID: dataAssetIDParam,
                    dataAssetType: dataAssetTypeParam,
                    sessionKey: this.querySessionKey,
                    taskContentRequest: null,
                    queryScript: queryScriptParam,
                    dataBlendRule: null
                };
            }),
            mergeMap(runQueryPayload => this.http.post(`${environment.userDataSession}`, runQueryPayload))
            , take(1)
        );
    }

}

