import * as _ from 'lodash/fp';

import { GridLayoutModel } from '../../grid-layout/grid-layout.types';
import { WidgetModel } from '../../widget/widget.types';

export const insertEmptyRowWithSizes = (
    currentLayout: GridLayoutModel<WidgetModel>,
    sizes: number[]
): GridLayoutModel<WidgetModel> => {
    const nextRow = {
        cells: _.map((size) => ({
            size,
            data: null
        }), sizes)
    };

    return {
        ...currentLayout,
        rows: [
            ...currentLayout.rows,
            nextRow
        ] as any[]
    };
};
