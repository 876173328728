import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QueryVariable } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-query-text-editor-variable',
    templateUrl: './query-text-editor-variable.component.html',
    styleUrls: ['./query-text-editor-variable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryTextEditorVariableComponent {
    @Input()
    variable: string;

    @Input()
    variableDefinition: QueryVariable;

    get variableParameterKeys() {
        return this.variableDefinition &&
            this.variableDefinition.queryVariableParameters
            ? Object.keys(
                  this.variableDefinition.queryVariableParameters
              ).filter(
                  x =>
                      this.variableDefinition.queryVariableParameters[x] != null
              )
            : [];
    }
}
