import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './snackbar.service';
import { IconModule } from '../../components/icon/icon.module';
import { SnackbarMessageComponent } from './component/snackbar-message.component';


const imports = [
    CommonModule,
    IconModule,
    MatSnackBarModule
];

const providers = [
    SnackbarService
];

const components = [
    SnackbarMessageComponent
];

@NgModule({
    imports,
    providers,
    declarations: components,
    entryComponents: components
})
export class SnackbarModule { }
