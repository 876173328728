import { Component } from '@angular/core';
import { GO_BACK_URL } from '../../../consts/api';

@Component({
    selector: 'xft-go-back-button',
    templateUrl: './go-back-button.component.html',
    styleUrls: ['./go-back-button.component.scss']
})
export class GoBackButtonComponent {
    goBackUrl = GO_BACK_URL;
}
