import { scrollContainer } from './scrollContainer';
import * as go from 'gojs';

export const setupNodesScrolling = (diagram: go.Diagram) => {
    const defaultScrollFunction = diagram.scroll.bind(diagram);
    let prevPointer;

    diagram.scroll = (...args) => scrollContainer(
        defaultScrollFunction,
        args,
    );

    // HACK: Fix Precision Touchpads scrolling on Edge
    window.addEventListener('pointermove', (e) => {
        if (e.pointerType !== 'touch') {
            return;
        }
        const distance = prevPointer - e.pageY;
        const direction = distance > 0 ? 'up' : 'down';
        scrollContainer(
            defaultScrollFunction,
            ['pixel', direction, Math.abs(distance)],
            false
        );
        prevPointer = e.pageY;
    });
};
