import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule, FormFieldModule, IconModule } from '@xfusiontech/shared';
import { CategoryDialogComponent } from './category-dialog.component';
import { CategoryDialogService } from './category-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        ButtonModule,
        FormFieldModule,
        IconModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [CategoryDialogComponent],
    entryComponents: [CategoryDialogComponent],
    exports: [CategoryDialogComponent],
    providers: [CategoryDialogService],
})
export class CategoryDialogModule {}
