import * as _ from 'lodash/fp';
import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';

import { MapChartOptions } from '../map-chart.types';
import { DEFAULT_MAP_CHART_OPTIONS } from '../map-chart.consts';

@Component({
    selector: 'udp-map-chart',
    templateUrl: './map-chart.component.html',
    styleUrls: ['./map-chart.component.scss']
})
export class MapChartComponent {

    Highcharts = Highcharts;
    chartConstructor = 'mapChart';

    @Input()
    set options(options: MapChartOptions) {
        this._options = _.defaults(DEFAULT_MAP_CHART_OPTIONS)(options);
    }
    get options(): MapChartOptions {
        return this._options;
    }
    private _options: MapChartOptions;

    @Input()
    height = '600px';

}
