import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataCatalogService {
  constructor(private http: HttpClient) {}

  getAllWorkflowsList(customerID, dataAssetID, dataAssetType, workflowStatus) {
    return this.http.get(
      `${environment.existingDataAssetsWorkflows}?customerID=${customerID}&dataAssetID=${dataAssetID}&dataAssetType=${dataAssetType}&workflowStatus=${workflowStatus}`
    );
  }
}
