import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  UDPTaskConfigurationsDictionary,
  UDPTaskConfiguration
} from '../types/config';
import { NotValidTimeStampOperator } from '@workflow-editor/components/code-editor-panel/types';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { CreateSaveBodyService } from './create-save-body.service';
import { ApiService } from './api.service';
import { MessagesService } from './messages.service';
import { ModelSaveService } from '@workflow-editor/gojs/services/model-save.service';

import { uniqueArrayBasedOnKey } from 'src/app/shared/utils/common-utilities';


@Injectable({
  providedIn: 'root'
})
export class UdpTaskConfigService {
  branchFiles: any;
  saveUdpData = new Subject();
  currentOperatorSave = new Subject();
  public currentOperatorSave$ = this.currentOperatorSave.asObservable();

  updateEntitySchema = new BehaviorSubject<any>(null);
  public updateEntitySchema$ = this.updateEntitySchema.asObservable();

  private customerId: string;
  private userId: string;
  private finalPythonCode;
  configDictionary: UDPTaskConfigurationsDictionary[] = [];
  taskConfiguration: UDPTaskConfiguration = {};
  types = [
    {
      key: 'jsonEditor',
      ext: miscellaneousConst.DAG.FILENAME
    },
    {
      key: 'pythonEditor',
      ext: 'generatedDAG/'
    },
    {
      key: 'operatorConfig',
      ext: 'config/'
    },
    {
      key: 'timeStampConfig',
      ext: 'config/'
    }
  ];
  saveConfig = {
    items: {
      jsonEditor: null,
      pythonEditor: null,
      operatorConfig: [],
      timeStampConfig: null
    }
  };

  TCVStateConfigs: any[] = [];

  private _taskConfig: any;
  constructor(
    private messagesService: MessagesService,
    private _createSaveBodyService: CreateSaveBodyService,
    private modelSaveService: ModelSaveService,
    private apiService: ApiService
  ) {}

  public setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public getCustomerId(): string {
    return this.customerId;
  }

  public getUserId(): string {
    return this.userId;
  }

  /**
   *
   */
  public set udpTaskConfig(taskConfig) {
    this._taskConfig = taskConfig;
  }

  public getLatestJSONConfig() {
    return (
      this.modelSaveService.getWorkflowDefinition() ||
      this.getConfigFromCacheByType('jsonEditor')
    );
  }

  /**
   *
   * @param fileName
   */
  getConfig(fileName) {
    if (this._taskConfig) {
      const configContent = this._taskConfig.find((item) => {
        if (item.filename == fileName) {
          return item;
        }
      });
      if (fileName.includes('config/')) {
        return configContent;
      } else {
        return configContent.contents;
      }
    } else {
      return;
    }
  }

  async getFinalPythonCode() {
    const workflowDefinition = JSON.stringify(
      this.modelSaveService.getWorkflowDefinition(),
      null,
      4
    );
    let data = JSON.parse(workflowDefinition);
    data.dag.parameters = this.convertDateData(data.dag.parameters);
    const keys = Object.keys(data.nodes);
    keys.forEach((key) => {
      data.nodes[key].properties.parameters = this.convertDateData(
        data.nodes[key].properties.parameters
      );
    });
    this.finalPythonCode = await this.apiService
      .getPythonCodeSave(JSON.stringify(data))
      .then();
    return this.finalPythonCode;
  }

  /**
   * @description Fix for python dag file where start date and end date is comming in utc format
   * @param formGroupdata
   * @returns
   */
  private convertDateData(formGroupdata) {
    for (const formControl of formGroupdata) {
      if (
        formControl.value &&
        (formControl.id === 'start_date' || formControl.id === 'end_date') &&
        typeof formControl.value === 'string' &&
        !formControl.value.includes('datetime')
      ) {
        formControl.value = {
          class: 'Date',
          value: formControl.value
        };
      }
    }
    return formGroupdata;
  }

  /**
   * To prepare data for the final final Save API call for the GitHub
   * @returns
   */
  prepareFinalPayloadForSave() {
    const items = [];
    this.types.forEach(async (type, idx) => {
      let config: any = null;
      if (type.key !== 'pythonEditor') {
        if (idx === 2) {
          // For Operator Config
          items.push(...this.getOperatorConfigs());
          return;
        }
        if (
          type.key === 'timeStampConfig' &&
          this.saveConfig.items.timeStampConfig &&
          this.saveConfig.items.timeStampConfig.contents
        ) {
          // For timestamp config
          items.push(...this.saveConfig.items.timeStampConfig);
        }
        if (type.key === 'jsonEditor') {
          const value =
            this.modelSaveService.getWorkflowDefinition() ||
            this.getConfigFromCacheByType('jsonEditor');
          if (value) {
            config = {};
            config.contents = {};
            config.contents = { ...value };
            config.filename = miscellaneousConst.DAG.FILENAME; // Keeping hard coded name only
          } else {
            config = {
              ...this._taskConfig.find((item) =>
                item.filename.includes(type.ext)
              )
            };
          }
          config.current_version =
            this._createSaveBodyService.parameters.version;
          delete config.version;
        } else {
          if (this._taskConfig && this._taskConfig.length > 0) {
            config = {
              ...this._taskConfig.find((item) =>
                item.filename.includes(type.ext)
              )
            };
            config.current_version =
              this._createSaveBodyService.parameters.version;
            delete config.version;
          }
        }
      }
      // if config is valid and if config has property called "filename" then only add as valid config
      if (config && config.filename) {
        items.push(config);
      }
    });
    this._taskConfig = _.filter(
      items,
      (item) => !_.isNull(item) && item !== undefined
    );
    return this._taskConfig.length > 0
      ? uniqueArrayBasedOnKey(this._taskConfig, 'filename')
      : this._taskConfig;
  }

  /**
   *
   * @returns
   */
  getOperatorConfigs() {
    if (this.saveConfig.items.operatorConfig) {
      const config = this.saveConfig.items.operatorConfig;
      const items = [...config];
      const fileNames = config.map((v) => v.filename);
      if (this._taskConfig && this._taskConfig.length > 0) {
        const taskOperatorConfigs = this._taskConfig.filter((item) =>
          item.filename.includes(this.types[2].ext)
        );
        const configs = taskOperatorConfigs
          .filter((v) => fileNames.indexOf(v.filename) === -1)
          .map((cfg) => {
            const tempCfg = { ...cfg };
            tempCfg.current_version =
              this._createSaveBodyService.parameters.version;
            delete tempCfg.version;
            return tempCfg;
          });
        items.push(...configs);
      }
      return items;
    }
  }

  /**
   * Setting Config on click of save in code editor panel
   * @param data
   */
  setConfigToService(config, type) {
    let data;
    const current_version = this._createSaveBodyService.parameters.version;
    if (type === 'pythonEditor') {
      data = config;
    } else {
      data = { ...config };
      data.current_version = current_version;
    }
    if (type === this.types[1].key) {
      const nodeId = this._createSaveBodyService.parameters.nodeId;
      const pythonObj = {
        filename: `generatedDAG/${nodeId}.py`,
        contents: data,
        current_version: current_version
      };
      this.saveConfig.items[type] = pythonObj;
      this.messagesService.openMessage('Configuration saved');
    } else if (type === this.types[2].key) {
      // For all operators ( DS, DB, MIL )
      this.processSaveForOperators(config, data, type);
    } else {
      this.saveConfig.items[type] = data;
    }
    this.prepareFinalPayloadForSave();
  }

  private processSaveForOperators(config, data, type) {
    const temp = this.saveConfig.items.operatorConfig.find(
      (c1) => c1.filename == config.fileName
    );
    if (temp) {
      temp.contents = config.contents;
      data.filename = data.fileName;
      delete data.fileName;
      if (data.TS) {
        this.addTimeStampOperatorConfig(data.TS, data.filename);
        delete data.TS;
      }
    } else {
      data.filename = data.fileName;
      delete data.fileName;
      if (data.TS) {
        this.addTimeStampOperatorConfig(data.TS, data.filename);
        delete data.TS;
      }
      this.saveConfig.items[type].push(data);
    }
    this.messagesService.openMessage('Configuration saved');
  }

  private addTimeStampOperatorConfig(timeStamp: number, fileName: string) {
    if (
      this.saveConfig.items.timeStampConfig &&
      this.saveConfig.items.timeStampConfig.contents
    ) {
      const keyName = fileName.split('/')[1].split('.')[0] + '_last_updated_TS';
      let isFound = false;

      for (const content of this.saveConfig.items.timeStampConfig.contents) {
        for (const key in content) {
          if (keyName === key) {
            isFound = true;
            content[key] = timeStamp;
          }
        }
      }

      if (!isFound) {
        const timeStampConfig = {};
        timeStampConfig[keyName] = timeStamp;
        this.saveConfig.items.timeStampConfig.contents.push(timeStampConfig);
      }
    } else {
      const timeStampConfig = {};
      const keyName = fileName.split('/')[1].split('.')[0] + '_last_updated_TS';
      timeStampConfig[keyName] = timeStamp;
      const finalTimeStampConfig = {
        filename: miscellaneousConst.operators.TIMESTAMP.FILENAME, // timestamp file
        contents: [timeStampConfig],
        current_version: this._createSaveBodyService.parameters.version
      };
      this.saveConfig.items.timeStampConfig = finalTimeStampConfig;
    }
  }

  private createTCVStateJSONFileForOperator(
    taskIdParam: string,
    errorLabel: string,
    errors: string[]
  ) {
    if (this.TCVStateConfigs && this.TCVStateConfigs.length > 0) {
      const config = this.TCVStateConfigs.filter(
        (tcvConfig) => tcvConfig.taskId === taskIdParam
      );
      if (config && config.length > 0) {
        config[0].contents.push({
          errorLabel,
          errors
        });
      } else {
        this.addTCVStateConfigFiles(taskIdParam, errorLabel, errors);
      }
    } else {
      this.addTCVStateConfigFiles(taskIdParam, errorLabel, errors);
    }
  }

  private addTCVStateConfigFiles(
    taskIdParam: string,
    errorLabel: string,
    errors: string[]
  ) {
    const tcvConfig = {
      filename: 'config/' + taskIdParam + '.TCVSTATE.json',
      contents: [
        {
          errorLabel,
          errors
        }
      ],
      taskId: taskIdParam,
      current_version: this._createSaveBodyService.parameters.version
    };
    this.TCVStateConfigs.push(tcvConfig);
  }

  /**
   * get data from udp task config service
   * @param type
   * @returns
   */
  getConfigFromCacheByType(type) {
    if (type === 'operatorConfig') {
      return this.saveConfig.items.operatorConfig;
    } else {
      return (
        this.saveConfig.items[type] && this.saveConfig.items[type].contents
      );
    }
  }

  /**
   *
   * @param fileName
   */
  getOperatorConfigFromCache(fileName) {
    if (this.saveConfig.items.operatorConfig) {
      const temp = this.saveConfig.items.operatorConfig.find((item) => {
        return item.filename === fileName;
      });
      return temp;
    } else {
      return;
    }
  }

  removeTaskConfig(taskId) {
    const index = _.findIndex(this._taskConfig, (e: any) => {
      const id =
        e.filename &&
        e.filename.includes('/') &&
        e.filename.split('/')[1].split('.')[0];
      return id !== undefined && id !== '' && id === taskId;
    });
    if (index !== -1) {
      this._taskConfig.splice(index, 1);
    }
  }

  removeTaskFromSaveConfig(taskId) {
    const index = _.findIndex(
      this.saveConfig.items.operatorConfig,
      (e: any) => {
        const id =
          e.filename &&
          e.filename.includes('/') &&
          e.filename.split('/')[1].split('.')[0];
        return id !== undefined && id !== '' && id === taskId;
      }
    );
    if (index !== -1) {
      this.saveConfig.items.operatorConfig.splice(index, 1);
    }
  }

  removeTaskFromTimeStampConfig(taskId: string) {
    if (
      this.saveConfig.items.timeStampConfig &&
      this.saveConfig.items.timeStampConfig.contents
    ) {
      const keyName = taskId + '_last_updated_TS';
      let index = -1;

      const timeStampContents = this.saveConfig.items.timeStampConfig.contents;

      for (let i = 0; i < timeStampContents.length; i++) {
        for (const key in timeStampContents[i]) {
          if (keyName === key) {
            index = i;
            break;
          }
        }
      }

      if (index !== -1) {
        this.saveConfig.items.timeStampConfig.contents.splice(index, 1);
      }
    }
  }

  public checkForTCVValidationForOperators(
    previousTaskIds: any[],
    currentTaskID: any
  ): any[] {
    if (previousTaskIds.length === 2) {
      return this.checkForTCVValidationForDataBlend(
        previousTaskIds,
        currentTaskID
      );
    }
    return null;
  }

  private checkForTCVValidationForDataBlend(
    previousTaskIds: any[],
    currentTaskID: any
  ): any[] {
    const p1Content = this.getConfig(previousTaskIds[0]);
    const p2Content = this.getConfig(previousTaskIds[1]);
    const cContent = this.getConfig(
      'config/' + currentTaskID + '.' + miscellaneousConst.operators.DB.FILENAME
    );

    const p1Config =
      p1Content && p1Content.contents && p1Content.contents.datasetSchema;
    const p2Config =
      p2Content && p2Content.contents && p2Content.contents.datasetSchema;

    let leftDatasetConfig;
    let rightDatasetConfig;

    if (
      cContent &&
      cContent.contents &&
      cContent.contents.metadata &&
      cContent.contents.metadata.dataSetSchema
    ) {
      for (const key in cContent.contents.metadata.dataSetSchema) {
        if (key.includes('l_')) {
          leftDatasetConfig = cContent.contents.metadata.dataSetSchema[key];
        } else {
          rightDatasetConfig = cContent.contents.metadata.dataSetSchema[key];
        }
      }
    }

    let leftDatasetErrors = [];
    let rightDatasetErrors = [];

    if (leftDatasetConfig && rightDatasetConfig) {
      leftDatasetErrors = this.checkForDatasetConfigError(
        leftDatasetConfig,
        p1Config,
        true,
        currentTaskID
      );
      rightDatasetErrors = this.checkForDatasetConfigError(
        rightDatasetConfig,
        p2Config,
        false,
        currentTaskID
      );
    }

    if (leftDatasetErrors.length > 0) {
      this.createTCVStateJSONFileForOperator(
        currentTaskID,
        miscellaneousConst.operators.DB.errors.leftDatasetSchemaChanged,
        leftDatasetErrors
      );
    }

    if (rightDatasetErrors.length > 0) {
      this.createTCVStateJSONFileForOperator(
        currentTaskID,
        miscellaneousConst.operators.DB.errors.rightDatasetSchemaChanged,
        rightDatasetErrors
      );
    }

    if (this.TCVStateConfigs && this.TCVStateConfigs.length > 0) {
      const config = this.TCVStateConfigs.filter(
        (tcvConfig) => tcvConfig.taskId === currentTaskID
      );
      if (config && config.length > 0) {
        return config;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  private checkForDatasetConfigError(
    dataBlendLeftRightConfig,
    leftRightDatasetConfig,
    isLeft: boolean,
    currentTaskID: any
  ) {
    let dataBlendColumns = [];
    let sourceColumns = [];

    for (const dbColumn of _.cloneDeep(dataBlendLeftRightConfig.attribute)) {
      dataBlendColumns.push(dbColumn.attributeName);
    }

    for (const column of _.cloneDeep(leftRightDatasetConfig.attribute)) {
      sourceColumns.push(column.attributeName);
    }

    dataBlendColumns = _.uniq(dataBlendColumns);
    sourceColumns = _.uniq(sourceColumns);

    // Main function that will call out missing columns list
    const diff_leftColumns = _.differenceBy(sourceColumns, dataBlendColumns, 0);

    const finalColumns = [];

    if (diff_leftColumns.length > 0) {
      const datasetTypeLabel = isLeft ? 'Left Dataset' : 'Right Dataset';
      for (const column of diff_leftColumns) {
        finalColumns.push(
          'Datablend ' + datasetTypeLabel + ' has missing column: ' + column
        );
      }
    }

    return finalColumns;
  }

  public checkTimeStampValidationForOperators(
    previousTaskIds: any[],
    currentTaskID: any
  ): NotValidTimeStampOperator {
    if (
      this.saveConfig.items.timeStampConfig &&
      this.saveConfig.items.timeStampConfig.contents &&
      this.saveConfig.items.timeStampConfig.contents.length > 0
    ) {
      if (previousTaskIds.length === 2) {
        return this.checkTimeStampValidationForDataBlend(
          previousTaskIds,
          currentTaskID
        );
      }
    } else {
      return null;
    }
  }

  private checkTimeStampValidationForDataBlend(
    previousTaskIds: any[],
    currentTaskID: any
  ): NotValidTimeStampOperator {
    const p1 =
      previousTaskIds[0].split('/')[1].split('.')[0] + '_last_updated_TS';
    const p2 =
      previousTaskIds[1].split('/')[1].split('.')[0] + '_last_updated_TS';
    const ct = currentTaskID + '_last_updated_TS';

    const p1TS = this.findTimeStampValueBasedOnKey(p1);
    const p2TS = this.findTimeStampValueBasedOnKey(p2);
    const ctTS = this.findTimeStampValueBasedOnKey(ct);

    if (ctTS < p1TS || ctTS < p2TS) {
      // That means source got changed after current task config is done
      const notValidTSOperator = new NotValidTimeStampOperator();
      notValidTSOperator.previousTaskIds = previousTaskIds;
      notValidTSOperator.currentTaskId = currentTaskID;
      notValidTSOperator.warningMessage =
        miscellaneousConst.operators.DB.errors.previousTasksModifiedAfterCurrentTask;

      return notValidTSOperator;
    }

    return null;
  }

  private findTimeStampValueBasedOnKey(keyName) {
    let value = '';
    for (const content of this.saveConfig.items.timeStampConfig.contents) {
      for (const key in content) {
        if (keyName === key) {
          value = content[key];
          break;
        }
      }
    }
    return value;
  }
}
