import * as go from 'gojs';

import { MAIN_SHAPE_NAME, PROPERTIES_TABLE_NAME, NODE_COLLAPSED_HEIGHT } from '../consts/shared';
import { animate } from './animate';
import { getExpandedNodeHeight } from '../templates/parts/node/propertiesTable/utils';

const ANIMATION_DURATION = 200;

const showTable = async (
    background: go.GraphObject,
    table: go.GraphObject
) => {
    table.opacity = 0;
    const expandedHeight = getExpandedNodeHeight(table.part);

    await animate(
        [[background, 'height', NODE_COLLAPSED_HEIGHT, expandedHeight]],
        ANIMATION_DURATION
    );
    await animate(
        [[table, 'opacity', 0, 1]],
        0.4 * ANIMATION_DURATION
    );
};

const hideTable = async (
    background: go.GraphObject,
    table: go.GraphObject
) => {
    const expandedHeight = getExpandedNodeHeight(table.part);

    await animate(
        [[table, 'opacity', 1, 0]],
        0.2 * ANIMATION_DURATION
    );
    await animate(
        [[background, 'height', expandedHeight, NODE_COLLAPSED_HEIGHT]],
        ANIMATION_DURATION
    );
    table.visible = false;
};

export const togglePropertiesTable = ({
    diagram, part
}: go.GraphObject ) => {
    if (diagram.animationManager.isAnimating) {
        return;
    }

    diagram.skipsUndoManager = true;
    diagram.commit(async () => {
        const { data } = part;
        const nextExpanded = !data.expanded;

        diagram.model.setDataProperty(data, 'expanded', nextExpanded);

        const background = part.findObject(MAIN_SHAPE_NAME);
        const table = part.findObject(PROPERTIES_TABLE_NAME);

        table.visible = true;
        if (nextExpanded) {
            await showTable(background, table);
        } else {
            await hideTable(background, table);
        }
        diagram.skipsUndoManager = false;
    });
};
