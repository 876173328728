import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import {
  DAGFILENAME,
  WORKFLOWDEFFILENAME,
  WORKFLOW_ID_PARAM_NAME,
  WORKFLOW_NAME_PARAM_NAME,
  CUSTOMERID,
  USERID,
  ACCESS_TOKEN
} from '../../consts/routing';

import { MatDrawerContent } from '@angular/material';
import { MatIconRegistry } from '@angular/material/icon';

import { maximizeDrawer } from './editor.animation';

import { registerIcons } from '../../utils/registerIcons';

import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';

import { WorkflowService } from '@workflow-editor/services/workflow.service';
import { RedrawService } from '@workflow-editor/gojs/services/redraw.service';
import { TaskCatalogService } from '@workflow-editor/services/task-catalog.service';
import { UnsavedChangesService } from '@workflow-editor/services/unsaved-changes.service';
import { CreateSaveBodyService } from '@workflow-editor/services/create-save-body.service';
import { ApiService } from '@workflow-editor/services/api.service';
import { UdpTaskConfigService } from '@workflow-editor/services/udp-task-config.service';

import { unsubscribeCollection } from 'src/app/shared/utils/common-utilities';

@Component({
  selector: 'xft-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [maximizeDrawer]
})
export class EditorComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  /**
   * @public
   * @type: {any}
   */
  public params: any = {};

  /**
   * @public
   * @type: {any}
   */
  public drawerOpen: any = {
    start: true,
    end: false
  };

  /**
   * @public
   * @type: {MatDrawerContent}
   * @viewchild
   */
  @ViewChild('content', { static: true })
  private content: MatDrawerContent;

  /**
   * @public
   * @type: {boolean}
   */
  public propertiesEditorMaximized = false;

  private onDestroy$ = new Subject<void>();
  branchFiles: any;
  configJSON: any;
  nodeId: any;

  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private taskCatalogService: TaskCatalogService,
    route: ActivatedRoute,
    private workflowService: WorkflowService,
    private redrawService: RedrawService,
    private unsavedChangesService: UnsavedChangesService,
    private createSaveBodyService: CreateSaveBodyService,
    private _apiService: ApiService,
    private _udpTaskConfigService: UdpTaskConfigService
  ) {
    // tslint:disable-next-line: max-line-length
    // go.Diagram.licenseKey = '2bf840e7b56658c511d35a25403e7efb0ea02d3bcf824ef7595316f6ed5f601123cce17b55d299d0d5f01ef41a7493d189956b2c93480c6be232dbdf47b680f0b03275b2165b17d9a15071909af82df3f52b24f3c7e727aada7b8df1eefb93954ef8f58118cc0ee979791421557fac4aa8fbc678f8';
    route.params.subscribe((params) => {
      if (params.access_token)
        localStorage.setItem(
          miscellaneousConst.ACCESS_TOKEN,
          params.access_token
        );
      this.loadUDPOperators();
      this.setUserIdCustomerIdSessionId(route);
      this.loadWorkFlowEditor(route);
    });

    registerIcons(matIconRegistry, domSanitizer);
  }

  private loadWorkFlowEditor(route: ActivatedRoute) {
    this.nodeId = route.snapshot.params[WORKFLOW_ID_PARAM_NAME];
    const workflowName = route.snapshot.params[WORKFLOW_NAME_PARAM_NAME];
    this.workflowService.workflowId = this.nodeId;
    const routeParams = route.snapshot.params;
    this.createSaveBodyService.setUrlParameters(routeParams);
    // store params for ease of access
    this.params = routeParams;

    this.setUserIdCustomerIdSessionId(route);
    const { login, token, nodeName, version } = this.params;
    const data = {
      token,
      login,
      repositories_name: [nodeName]
    };

    this.subscriptions[this.subscriptions.length] = this._apiService
      .getBranchData(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((resp: any) => {
        if (resp.repositories[0].branches[0].versions.length === 0) {
          this.createSaveBodyService.isVersionSame = false; // When first time there is no data then isVersionSame should be false
          return;
        } else {
          this.branchFiles = resp.repositories[0].branches[0].versions[0].files;
          this.subscriptions[this.subscriptions.length] = this.workflowService
            .getFilesContent(this.branchFiles)
            .subscribe((configJSON: any) => {
              this._udpTaskConfigService.udpTaskConfig = configJSON;
              if (this.nodeId) {
                const jsonObject = configJSON.filter(
                  (config) =>
                    config.filename === miscellaneousConst.DAG.FILENAME
                );
                const timeStampObject = configJSON.filter(
                  (config) =>
                    config.filename ===
                    miscellaneousConst.operators.TIMESTAMP.FILENAME
                );
                if (
                  route.snapshot.params[WORKFLOWDEFFILENAME] !== 'undefined' ||
                  (jsonObject && jsonObject.length > 0)
                ) {
                  this.workflowService.fetchWorkflow();
                  if (timeStampObject && timeStampObject.length > 0) {
                    this.workflowService.updateTimeStampConfigJson(this.params);
                  }
                }
                if (route.snapshot.params[DAGFILENAME] !== 'undefined') {
                  this.workflowService.fetchWorkflowPython(routeParams);
                }
                this.workflowService.setWorkFlowName(workflowName);
                // Add value for patch value in workflow JSON, textbox.
                const defaultSetkeys = [{ key: 'dag_id', value: this.nodeId }];
                this.workflowService.setDagParametersValue(defaultSetkeys);
              }
            });
        }
      });
  }

  private setUserIdCustomerIdSessionId(route: ActivatedRoute) {
    if (route.snapshot.params[CUSTOMERID] && route.snapshot.params[USERID]) {
      this._udpTaskConfigService.setCustomerId(
        route.snapshot.params[CUSTOMERID]
      );
      this._udpTaskConfigService.setUserId(route.snapshot.params[USERID]);
    }
    if (route.snapshot.params[ACCESS_TOKEN]) {
      localStorage.setItem(
        miscellaneousConst.ACCESS_TOKEN,
        route.snapshot.params[ACCESS_TOKEN]
      );
    }
  }

  private loadUDPOperators() {
    // This loads left data panel with UDP operators
    this.taskCatalogService.fetchTaskCatalog();
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(e) {
    const hasUnsavedChanges = this.unsavedChangesService.stateChanged$.value;

    if (hasUnsavedChanges) {
      const confirmationMessage = 'Unsaved changes. Do you want to close tab?';

      e.preventDefault();
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }

  setDrawerOpen(position: string, next: boolean) {
    this.drawerOpen[position] = next;
    this.redrawService.redraw();
  }

  onMaximizeDone() {
    this.content._container.updateContentMargins();
    this.redrawService.redraw();
  }

  onPropertiesEditorMaximized() {
    this.propertiesEditorMaximized = !this.propertiesEditorMaximized;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    unsubscribeCollection(this.subscriptions);
  }
}
