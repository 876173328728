import { NgModule } from '@angular/core';
import { DiagramControlsComponent } from './diagram-controls.component';
import { AlignmentComponent } from './alignment/alignment.component';
import { ZoomComponent } from './zoom/zoom.component';
import { IconModule } from '../shared/icon/icon.module';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { OverviewComponent } from './zoom/overview/overview.component';

@NgModule({
    declarations: [
        AlignmentComponent,
        ZoomComponent,
        DiagramControlsComponent,
        OverviewComponent
    ],
    imports: [
        IconModule,
        CommonModule,
        MatSliderModule,
        FormsModule
    ],
    exports: [
        DiagramControlsComponent
    ]
})
export class DiagramControlsModule {}
