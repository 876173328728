import { Component, Input } from '@angular/core';
import { MapChartOptions } from '@xfusiontech/shared';
import californiaMap from '@highcharts/map-collection/countries/us/us-ca-all.geo.json';

import { SAMPLE_MAP_DATA } from './SAMPLE_DATA';
import { WidgetWrapperComponent } from '../widget-wrapper.component';
import { StateHeatmapWrapperData } from './state-heatmap.types';

@Component({
    selector: 'immu-track-state-heatmap',
    templateUrl: './state-heatmap.component.html'
})
export class StateHeatmapComponent  extends WidgetWrapperComponent<StateHeatmapWrapperData>{

    @Input()
    height = '500px';

    options: MapChartOptions = {
        chart: {
          map: californiaMap as any
        },
        series: [{
            data: SAMPLE_MAP_DATA,
            name: 'Random data',
            dataLabels: {
                enabled: true,
                format: '{point.name}'
            }
        } as any]
    };

}
