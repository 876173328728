import * as go from 'gojs';
import * as _ from 'lodash/fp';

import {
    NODE_COLLAPSED_HEIGHT,
    NODE_CONTENT_WIDTH,
    NODE_PADDING,
    PROPERTIES_TABLE_HEIGHT,
    PROPERTIES_TABLE_NAME,
    PROPERTIES_TABLE_WIDTH,
    SCROLLBAR_WIDTH
} from '../../../../consts/shared';
import { snakeCaseToLabel } from '../../../../../utils/snakeCaseToLabel';
import { theme } from '../../../../../consts/theme';
import { scrollableContainer } from '../scrollableContainer/scrollableContainer';
import { parsePropertyValue } from './parsePropertyValue';
import { tooltip } from '../../shared/tooltip';
import { redrawNode } from 'src/app/workflow-editor/gojs/utils/redrawNode';

const $ = go.GraphObject.make;

const COLUMN_WIDTH = 0.5 * (NODE_CONTENT_WIDTH - 8);

const propertyKeyBinding = () =>
    new go.Binding('text', 'id', (id) => snakeCaseToLabel(id));
const propertyValueBinding = () =>
    new go.Binding('text', '', parsePropertyValue);

const propertyRow = () => $(
    go.Panel,
    go.Panel.TableRow,
    $(
        go.TextBlock,
        {
            column: 0,
            font: `12px ${theme.fontFamily}`,
            stroke: theme.colors.textLight,
            margin: new go.Margin(2, 0, 2, 4),
            alignment: go.Spot.TopLeft,
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 1,
            width: COLUMN_WIDTH,
            toolTip: tooltip(propertyKeyBinding())
        },
        propertyKeyBinding()
    ),
    $(
        go.TextBlock,
        {
            column: 1,
            font: `${theme.fontWeight.light} 12px ${theme.fontFamily}`,
            stroke: theme.colors.textLight,
            margin: new go.Margin(2, 0, 2, 4),
            overflow: go.TextBlock.OverflowEllipsis,
            wrap: go.TextBlock.None,
            width: COLUMN_WIDTH,
            toolTip: tooltip(propertyValueBinding())
        },
        propertyValueBinding()
    )
);

export const propertiesTable = () => $(
    go.Panel,
    go.Panel.Table,
    {
        name: PROPERTIES_TABLE_NAME,
        defaultAlignment: go.Spot.Left,
        alignmentFocus: go.Spot.TopLeft,
        alignment: go.Spot.TopLeft,
        itemTemplate: propertyRow(),
        visible: false,
        opacity: 0,
        maxSize: new go.Size(PROPERTIES_TABLE_WIDTH, NaN),
        padding: new go.Margin(2, 0, 2, 0),
    },
    new go.Binding('opacity', 'expanded', (expanded) => expanded ? 1 : 0),
    new go.Binding('visible', 'expanded'),
    new go.Binding(
        'itemArray',
        'properties',
        (properties, obj: go.GraphObject) => {
            requestAnimationFrame(() => {
                redrawNode(obj.part);
            });
            return _.get('parameters')(properties);
        }
    )
);

export const scrollablePropertiesTable = () =>
    scrollableContainer(
        propertiesTable,
        {
            width: NODE_CONTENT_WIDTH,
            height: PROPERTIES_TABLE_HEIGHT,
            scrollingPanelName: PROPERTIES_TABLE_NAME,
            offsetX: NODE_PADDING,
            offsetY: NODE_COLLAPSED_HEIGHT,
            scrollbarWidth: SCROLLBAR_WIDTH,
        }
    );
