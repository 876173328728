import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule, MatMenuModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GraphSearchboxModule } from '@xfusiontech/data-visualizer';
import {
    ButtonModule,
    ExpansionPanelModule,
    FormFieldModule,
    IconButtonModule,
    IconModule,
    RichTooltipModule,
    TabsModule,
    StringArrayToSelectOptionsModule,
} from '@xfusiontech/shared';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CategoriesToSelectOptionsPipe } from './categories-to-select-options.pipe';
import { QueryNavigationQueriesComponent } from './navigation-query/navigation-queries.component';
import { NavigationFilterPipe } from './navigation/navigation-filter.pipe';
import { QueryEditorNavigationComponent } from './navigation/navigation.component';
import { QueryEditorComponent } from './query-editor.component';
import { QueryTextEditorTooltipDirective } from './query-text-editor/query-text-editor-tooltip.directive';
import { QueryTextEditorVariableComponent } from './query-text-editor/query-text-editor-variable/query-text-editor-variable.component';
import { QueryTextEditorComponent } from './query-text-editor/query-text-editor.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { TemplateEngineService } from './template-engine.service';
import { VariablesFormComponent } from './variables-form/variables-form.component';
@NgModule({
    declarations: [
        QueryEditorComponent,
        VariablesFormComponent,
        CategoriesToSelectOptionsPipe,
        QueryEditorNavigationComponent,
        NavigationFilterPipe,
        QueryNavigationQueriesComponent,
        SearchBoxComponent,
        QueryTextEditorComponent,
        QueryTextEditorVariableComponent,
        QueryTextEditorTooltipDirective,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        FormFieldModule,
        TabsModule,
        IconModule,
        PerfectScrollbarModule,
        RouterModule,
        ExpansionPanelModule,
        IconButtonModule,
        MatMenuModule,
        MatDividerModule,
        GraphSearchboxModule,
        MatTooltipModule,
        RichTooltipModule,
        StringArrayToSelectOptionsModule,
    ],
    providers: [TemplateEngineService],
    exports: [QueryEditorComponent, QueryEditorNavigationComponent],
    entryComponents: [QueryTextEditorVariableComponent],
})
export class QueryEditorModule {}
