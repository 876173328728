import { NgModule } from '@angular/core';
import { OpenFileService } from './open-file.service';

const providers = [
    OpenFileService
];

@NgModule({
    providers
})
export class OpenFileModule { }
