import * as _ from 'lodash/fp';
import { ValueObject } from '../../types/shared';

const omittedProperties = [
    'category',
    'expanded',
    'invalid'
];

export const createGraphObjectMap = (
    graphObjectArray: ValueObject[]
): ValueObject => _.flowRight(
    _.keyBy('id'),
    _.map(
        _.omit(omittedProperties)
    )
)(graphObjectArray);
