import gql from 'graphql-tag';

export const GET_EXTERNAL_DATA_HOMEPAGE = gql`query ExternalDataAll
{
  externalDataAll
   {
    ExternalDataID
    ExternalDataBasesDisplay
    AverageDataSize
    BatchFileType
    DataInputChannel
    DataSensitivity
    DataTrustLevel
    InputFrequency
    ExternalDataDesc
    ExternalDataName
    ExternalDataTypeCode
    Status
    DataSharingAgreement{
      DataSharingAgreementID
      ContractDocumentUrl
      ContractEndDate
      ContractStartDate
      DataConsumerOrganisationName
      DataProviderOrganisationName
      Notes
      Restrictions
      Status
      Tags

    }
    ExternalDataOrganization{
        OrganizationAddress
        OrgPic
        OrganisationCode
        OrganisationDesc
        OrganisationName
        SourceSystemDesc
        SourceSystemName
        ExternalDataOwnerID
    }
    DataElement{
      DataElementName
      DataElementDescription
      DataSensitivity
      ExternalDataElement{
      DataElementDesc
      DataElementName
      DataElementPhysicalName
      DataSensitivity
      DataStandard
      DataType
      ListOfValues
      Nullable
      RequiredOrOptional
      Tags
    }

    }
  }
}`;

export const GET_ALL_EXTERNAL_DATA_AND_ORG = gql`
  query Organization {
    organization {
      OrganizationID
      OrganizationCode
      OrganizationDesc
      OrganizationName
      ExternalDataOrganization {
        ExternalDataOrgID
        ExternalData {
          ExternalDataID
          ExternalDataName
          ExternalDataDesc
          ExternalDataTypeCode
          Status
        }
      }
    }
  }
`;

export const GET_EXTERNAL_DATA = gql`query externalData($uid: ID!){
    externalData(ExternalDataID:$uid)
    {
        ExternalDataID
        ExternalDataDesc
        ExternalDataName
        ExternalDataTypeCode
        Status
        id
        DataSharingAgreement {
          DataSharingAgreementID
        }
        Tags{
          TagName
        }
    }
  }`;

export const GET_EXTERNAL_DATA_ELEMENTS = gql`query externalDataElements($ExternalDataName:String){
    externalDataElements(ExternalDataName:$ExternalDataName)
    {
      ExternalDataElementID
      DataElementName
      DataType
      DataElementDesc
      DataElementPhysicalName
      ExternalDataElementID
    }
  }`;

export const UPDATE_SOURCE_DATA = gql`mutation
  updateExtData($ExternalDataID:ID!,
    $AverageDataSize: String!,
    $BatchFileType: String!,
    $DataInputChannel: String!,
    $DataSensitivity: String!,
    $DataTrustLevel: String!,
    $ExternalDataDesc: String!,
    $ExternalDataName: String!,
    $ExternalDataTypeCode: String!,
    $InputFrequency: String!,
    $Status: String!,
    $Tags: String!)
  {
    updateExternalData(ExternalDataID: $ExternalDataID,
      AverageDataSize:$AverageDataSize,
      BatchFileType:$BatchFileType,
      DataInputChannel: $DataInputChannel,
      DataSensitivity: $DataSensitivity,
      DataTrustLevel: $DataTrustLevel,
      ExternalDataDesc: $ExternalDataDesc,
      ExternalDataName: $ExternalDataName,
      ExternalDataTypeCode: $ExternalDataTypeCode,
      InputFrequency: $InputFrequency,
      Status: $Status,
      Tags: $Tags){
      AverageDataSize
      BatchFileType
    }
  }`;

export const UPDATE_SOURCE_DATA_AGREEMENT = gql`mutation updateAgreement($DataSharingAgreementID:ID!,
    $ContractDocumentUrl: String!,
    $ContractEndDate: String!,
    $ContractStartDate: String!,
    $DataConsumerOrganisationName: String!,
    $DataProviderOrganisationName: String!,
    $Notes: String!,
    $Restrictions:String!,
    $Status:String!,
    $Tags:String!){
    updateDataSharingAgreement(DataSharingAgreementID:$DataSharingAgreementID,ContractDocumentUrl:$ContractDocumentUrl,ContractEndDate:$ContractEndDate,ContractStartDate:$ContractStartDate,DataConsumerOrganisationName:$DataConsumerOrganisationName,DataProviderOrganisationName:$DataProviderOrganisationName,Notes:$Notes,Restrictions:$Restrictions,Status:$Status,Tags:$Tags)
    {
      ContractDocumentUrl
    }
  }`;

export const GET_EXT_ADD_SOURCE_DATA_ORG =
  gql`mutation newOrganization($OrganizationCode:String!,$OrganizationDesc:String!,$OrganizationName:String!,$CreateAddress:CreateAddress!,$CreateOrganizationDetail:CreateOrganizationDetail!){
    newOrganization(OrganizationCode:$OrganizationCode,OrganizationDesc:$OrganizationDesc,OrganizationName:$OrganizationName,CreateAddress:$CreateAddress,input:$CreateOrganizationDetail)
    {
    OrganizationCode
    OrganizationID
    }
    }`;


export const CREATE_EXTERNAL_DATA = gql`
    mutation newOrganizationExternalData($OrganizationCode:String!,$OrganizationDesc:String!,$OrganizationName:String!,
      $CreateAddress:CreateAddress!,$AverageDataSize: String!,$BatchFileType: String!,$DataInputChannel: String!,$DataSensitivity: String!,
      $DataTrustLevel: String!,$ExternalDataDesc: String!,$ExternalDataName: String!,$ExternalDataTypeCode: String!,
      $InputFrequency: String!,$Status: String!,$Tags: String!,$CreateDataSharingAgreementInput:CreateDataSharingAgreementInput!,
      $CreateTagsInput:CreateTagsInput,
      $CreateExternalDataElement:CreateExternalDataElement)
    {
    newOrganizationExternalData(OrganizationCode:$OrganizationCode,OrganizationDesc:$OrganizationDesc,
      OrganizationName:$OrganizationName,CreateAddress:$CreateAddress,AverageDataSize: $AverageDataSize,BatchFileType: $BatchFileType,
      DataInputChannel: $DataInputChannel,DataSensitivity: $DataSensitivity,DataTrustLevel:$DataTrustLevel,
      ExternalDataDesc: $ExternalDataDesc,ExternalDataName: $ExternalDataName,ExternalDataTypeCode: $ExternalDataTypeCode,
      InputFrequency: $InputFrequency,Status: $Status,Tags: $Tags,
      CreateDataSharingAgreementInput:$CreateDataSharingAgreementInput,CreateTagsInput:[$CreateTagsInput],
      CreateExternalDataElement:[$CreateExternalDataElement])
    {
      OrganizationCode
    }
    }`;

