import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material';

import { CheckboxInputComponent } from './checkbox/checkbox-input.component';
import { FormFieldComponent } from './field/form-field.component';
import { IconModule } from '../icon/icon.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ListComponent } from './list/list.component';
import { MatIconModule } from '@angular/material/icon';
import { LabelComponent } from './label/label.component';
import { DictionaryComponent } from './dictionary/dictionary.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { AutocompleteOptionComponent } from './autocomplete-option/autocomplete-option.component';
import { UnionComponent } from './union/union.component';
import { ComplexComponent } from './complex/complex.component';

const imports = [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    IconModule,
    IconButtonModule
];

const components = [
    CheckboxInputComponent,
    ListComponent,
    LabelComponent,
    DictionaryComponent,
    AddButtonComponent,
    AutocompleteOptionComponent,
    UnionComponent,
    ComplexComponent
];

const exportedComponents = [
    FormFieldComponent
];

@NgModule({
    imports: [
        imports,
        MatAutocompleteModule,
        MatIconModule
    ],
    declarations: [
        ...components,
        ...exportedComponents,
    ],
    exports: [
        ...exportedComponents
    ]
})
export class FormFieldModule {}
