import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { LogsListComponent } from './list/logs-list.component';
import { LogsListItemComponent } from './item/logs-list-item.component';
import { IconModule } from '../icon/icon.module';

const imports = [
    CommonModule,
    IconModule,
    PerfectScrollbarModule
];

const components = [
    LogsListComponent
];

const innerComponents = [
    LogsListItemComponent
];

@NgModule({
    imports,
    declarations: [
        ...components,
        ...innerComponents
    ],
    exports: components
})
export class LogsListModule { }
