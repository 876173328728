import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-workflow-dropdown-item',
  templateUrl: './workflow-dropdown-item.component.html',
  styleUrls: ['./workflow-dropdown-item.component.scss']
})
export class WorkflowDropdownItemComponent implements OnInit {
  @Input()
  workflow: any;

  @Input()
  selected: boolean;
  constructor() { }

  ngOnInit() {
  }

}
