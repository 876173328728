import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import _ from 'lodash';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

import { ConfigModel } from '../../schema-explorer/models/config.model';
import { SchemaExplorerService } from '../../schema-explorer/schema-explorer.service';

@Component({
  selector: 'lib-source-attributes',
  templateUrl: './source-attributes.component.html',
  styleUrls: ['./source-attributes.component.scss']
})
export class SourceAttributesComponent implements OnInit {
  mappingConfig: ConfigModel;
  currentEntityGroupId = '';
  attributeData = [];
  sourceTitle: any;
  selectionData = [];
  public sourceData = [];
  currentElement: any;

  public showTable = false;

  public entity: any;

  constructor(
    private dialogRef: MatDialogRef<SourceAttributesComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private schemaExplorerService: SchemaExplorerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentEntityGroupId = this.data.currentEntityGroupId;
    this.attributeData = this.data.attributeData.attribute;
    this.selectionData = this.data.sourceMappingFormControlData;
    this.sourceTitle = this.data.attributeData.entityName;
    this.currentElement = this.data.element;
  }

  ngOnInit() {
    this.sourceData = _.cloneDeep(this.selectionData);
    this.showTable = false;
    this.mappingConfig = {
      baseApiUrl: '',
      mode: 'edit',
      context: 'source-mapping',
      currentEntityGroupId: this.currentEntityGroupId
    };

    this.entity = {
      attributes: this.attributeData
    };

    this.showTable = true;
  }

  onCheckBoxSelection(data) {
    if (this.sourceData.length === 0) {
      this.sourceData.push(data.value);
    } else {
      const sourceIndex = _.findIndex(this.sourceData, (e: any) => {
        return e.attributeName === data.value.attributeName;
      });
      if (sourceIndex === -1) {
        if (data.checked) {
          this.sourceData.push(data.value);
        }
      } else {
        if (data.checked) {
          this.sourceData[sourceIndex] = data.value;
        } else {
          this.sourceData.splice(sourceIndex, 1);
        }
      }
    }
  }

  public close(flag: boolean): void {
    if (flag) {
      const typematch = this.checkDataType();
      if (typematch) {
        this.selectionData = _.cloneDeep(this.sourceData);
        this.dialogRef.close(this.selectionData);
      } else {
        const ref: MatDialogRef<any> = this.schemaExplorerService.openDialog(
          ConfirmationDialogComponent,
          this.dialog,
          {
            width: '600px',
            height: '250px',
            data: {
              title: 'Data type mismatch warning',
              message: `The data type for the target attribute(s) source and the source attribute does not match. Do
                      you like to continue with the mapping?`,
              buttonCancel: 'No',
              buttonOK: 'Yes'
            }
          }
        );
        ref.afterClosed().subscribe((data) => {
          if (data) {
            this.selectionData = _.cloneDeep(this.sourceData);
            this.dialogRef.close(this.selectionData);
          }
        });
      }
    } else {
      this.dialogRef.close();
    }
  }

  checkDataType() {
    let typeMatch = false;
    let types = [];
    this.sourceData.forEach((item) => types.push(item.type));
    types = _.uniq(types);
    if (types.length > 1) {
      typeMatch = false;
    } else {
      types.includes('string') ? types.push('varchar', 'char') : '';
      if (types.includes(this.currentElement.dataType.toLowerCase())) {
        typeMatch = true;
      } else if (types.includes(this.currentElement.dataType.toLowerCase())) {
        typeMatch = true;
      } else {
        typeMatch = false;
      }
    }
    return typeMatch;
  }

  public onSearch(event) {}
}
