import * as _ from 'lodash';
import { AbstractControl } from '@angular/forms';
import { AfterViewInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

/**
 * @author: Naga
 */
export class UdpGrid implements AfterViewInit {

  /**
   * @public
   * @type: {any[]}
   * @input
   */
  @Input()
  public attributesList: any[] = [];

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public elevatedStyle: boolean = true;

  /**
   * @public
   * @type: {MatSort}
   * @viewchild
   */
  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  /**
   * @public
   * @type: {string[]}
   * @input
   */
  @Input()
  public displayedColumns: string[] = [];

  /**
   * @public
   * @type: {MatPaginator}
   * @viewchild
   */
  @ViewChild(MatPaginator, { static: false })
  public paginator: MatPaginator;

  /**
   * @public
   * @type: {MatTableDataSource<any[]>}
   */
  public dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);

  /**
   * @constructor
   */
  constructor() {}

  /**
   * @protected
   * @return: void
   * @description: a helper method that
   * initializes data source config.
   */
  protected initDataSourceCfg(): void {
    // preserve the original filter predicate
    const predicate: any = this.dataSource.filterPredicate;
    this.dataSource.filterPredicate = this.predicate(predicate);
    this.dataSource.sortingDataAccessor = this.sorter.bind(this);
  }

  /**
   * @public
   * @param: {cb<(...args) => any>}
   * @return: any
   * @description: a helper method that accesses
   * the filter predicate and applies custom filters.
   */
  public predicate(cb: (...args) => any): any {
    return (data: AbstractControl, filter: string): any => {
      return cb.call(this.dataSource, data.value, filter);
    };
  }

  /**
   * @public
   * @param: {data<AbstractControl>}
   * @param: {id<string>}
   * @return: any
   * @description: a helper method that accesses
   * the sorting data accessor and applies
   * custom accessor method.
   */
  public sorter(data: AbstractControl, id: string): any {
    const value: any = data.value[id];
    return _.isString(value) ? value.toLowerCase() : value;
  }

  /**
   * @private
   * @param: {value<string>}
   * @return: void
   * @description: a helper method that sets
   * the mat filter value.
   */
  public applyFilter(value: string): void {
    value = (value || '').toLowerCase().trim();
    this.dataSource.filter = value;
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
