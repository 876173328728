import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    ApolloModule,
    HttpLinkModule
  ]
})
export class GraphQLModule {
  // private readonly URI1: string = environment.graphQL9092URL;
  // private readonly URI2: string = environment.graphQL9095URL;
  private readonly URI3: string = environment.Person360GraphQLURL;

  constructor(
    apollo: Apollo,
    httpLink: HttpLink
  ) {
    const options1: any = { uri: this.URI3 };
    apollo.createDefault({
      link: httpLink.create(options1),
      cache: new InMemoryCache()
    });

    const options2: any = { uri: this.URI3 };
    apollo.createNamed('endpoint9095', {
      link: httpLink.create(options2),
      cache: new InMemoryCache()
    });

    const options3: any = { uri: this.URI3 };
    apollo.createNamed('endpoint9096', {
      link: httpLink.create(options3),
      cache: new InMemoryCache()
    });
  }
}
