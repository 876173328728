import * as go from 'gojs';

import { theme } from '../../../../consts/theme';
import { validationStrokeBinding } from './bindings';

const $ = go.GraphObject.make;

export const linkEnding = () => $(
    go.Shape,
    {
        strokeWidth: 2,
        stroke: theme.colors.link,
        strokeCap: 'round',
    },
    validationStrokeBinding(),
);
