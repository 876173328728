import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';
import { DataAssetCategory, Visualization } from '@xfusiontech/data-visualizer';
import { PERMISSIONS } from '@xfusiontech/shared';

@Component({
  selector: 'igv-dashboard-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class DashboardNavigationComponent implements OnChanges, OnInit {
  PERMISSIONS = PERMISSIONS;

  @Input()
  navConfig: any;

  @Input()
  isSchemaTab: boolean = false;

  @Input()
  customCtxMenuTpl: any;

  @Input()
  customSubCtxMenuTpl: any;

  @Input()
  ctxMenu: boolean = true;

  @Input()
  draggable: boolean = false;

  @Input()
  suppressNavigation: boolean = false;

  @Input()
  dataAssetCategories: any[];

  @Output()
  itemClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  categoryClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  visualizationSelectionChange = new EventEmitter<Visualization>();

  @Output()
  editCategory = new EventEmitter<DataAssetCategory | null>();

  @Output()
  deleteCategory = new EventEmitter<DataAssetCategory>();

  @Output()
  shareVisualizationInDashboard = new EventEmitter<Visualization>();

  @Output()
  shareVisualizationByEmail = new EventEmitter<Visualization>();

  @Output()
  editVisualization = new EventEmitter<Visualization>();

  @Output()
  deleteVisualization = new EventEmitter<Visualization>();

  menuTopLeftPosition = { x: '0', y: '0' };

  @ViewChild(MatMenuTrigger, { static: true, read: MatMenuTrigger })
  matMenuTrigger: MatMenuTrigger;

  searchItem: string;
  expansionMap: { [key: string]: boolean } = {};
  menuData: DataAssetCategory;
  dataAssetCategoriesBkp: any[];

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.dataAssetCategories && this.dataAssetCategories != null) {
      this.dataAssetCategories.map(
        (x) => (this.expansionMap[x.dataAssetCategoryID] = false)
      );
    }
  }

  ngOnInit(): void {
      this.dataAssetCategoriesBkp = this.dataAssetCategories;
  }

  categoriesTrackBy = (_, category: any) => {
    return (
      (this.navConfig && category[this.navConfig.catIdKey]) ||
      category.dataAssetCategoryID
    );
  };

  visualizationTrackBy = (_, visualization: Visualization) =>
    visualization.visualizationID;

  onValueChanges(value: string) {
    this.searchItem = value;
    if(this.isSchemaTab) {
      if(!this.searchItem) {
        this.dataAssetCategories = this.dataAssetCategoriesBkp;
      } else {
        this.dataAssetCategories = this.dataAssetCategoriesBkp.filter(x => x.entityGroupName.toUpperCase().indexOf(this.searchItem.toUpperCase()) != -1)
      }
    }
  }

  onRightClick(event: MouseEvent, data: DataAssetCategory) {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.menuData = data;
    this.matMenuTrigger.openMenu();
  }

  onItemClick(e: any, category: any): void {
    this.itemClick.emit({
      category,
      subCategory: e
    });
  }
}
