import { Component, EventEmitter, Output, Input } from '@angular/core';
import { CodeEditorLanguage } from '../types';

@Component({
    selector: 'xft-code-editor-header',
    templateUrl: './code-editor-header.component.html',
    styleUrls: ['./code-editor-header.component.scss']
})
export class CodeEditorHeaderComponent {
    // notConfigNodes: any = [];
    @Input()
    title: string;

    // @Input('nodes')
    // set setNodes(nodes: any) {
    //     this.notConfigNodes = nodes.map((node: any) => node.type);
    // }

    @Input()
    language: CodeEditorLanguage;

    /**
     * @public
     * @type: {boolean}
     * @input
     */
    @Input()
    public onlyCancel: boolean = false;

    @Output()
    generateDagClick = new EventEmitter<void>();

    @Output()
    cancelClick = new EventEmitter<void>();

    @Output()
    saveClick = new EventEmitter<void>();

}
