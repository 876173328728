import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { LoaderModule } from '../loader/loader.module';
import { FuzzySearchOptionComponent } from './option/fuzzy-search-option.component';
import { FuzzySearchComponent } from './search/fuzzy-search.component';

const imports = [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    MatChipsModule,
    MatIconModule,
];

const components = [FuzzySearchComponent];

const innerComponents = [FuzzySearchOptionComponent];

@NgModule({
    imports,
    declarations: [...components, ...innerComponents],
    exports: components,
})
export class FuzzySearchModule {}
