import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UdpGrid } from '../shared/classes/udp-grid';
import { DataImportService } from '../data-import.service';
import { environment } from 'src/environments/environment';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DATA_TYPES, DISP_COLUMNS_CFG } from './view-edit-metadata.constants';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { state, style, transition, trigger, animate } from '@angular/animations';

/**
 * @author: Naga
 */
@Component({
  selector: 'view-edit-imported-metadata',
  templateUrl: './view-edit-metadata.component.html',
  styleUrls: [
    './view-edit-metadata.component.scss',
    './../shared/styles/udp-table.scss'
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ViewEditMetadataComponent extends UdpGrid implements OnInit {

  /**
   * @public
   * @type: {any}
   */
  public config: any;

  /**
   * @public
   * @type: {FormGroup}
   */
  public metaDataForm: FormGroup;

  /**
   * @public
   * @type: {string[]}
   */
  public dataTypes: string[] = [];

  /**
   * @public
   * @type: {any}
   */
  public sorter: (...args) => any[];

  /**
   * @public
   * @type: {EventEmitter<boolean>}
   */
   @Output()
  public error: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @public
   * @type: {EventEmitter<boolean>}
   */
  @Output()
  public success: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {fb<FormBuilder>}
   * @param: {dataImportService<DataImportService>}
   */
  constructor(private fb: FormBuilder,
    private dataImportService: DataImportService) {
    super();

    this.dataTypes = DATA_TYPES;
    this.config = DISP_COLUMNS_CFG;
    this.displayedColumns = Object.keys(DISP_COLUMNS_CFG);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    this.init();
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the meta data form.
   */
  private init(): void {
    this.metaDataForm = this.fb.group({
      searchValue: new FormControl(''),
      attributes: this.fb.array([])
    });

    // initialize the form attributes
    this.initFormArray();

    // set table data source
    this.dataSource = new MatTableDataSource<any>(
      this.attributes.controls
    );

    // customize the sorting mechanism
    setTimeout(() => {
      super.ngOnInit();
      this.sorter = this.dataSource.sortData;
      this.dataSource.sortData = this.sortData.bind(this);
    });
  }

  /**
   * @private
   * @returns: FormArray
   * @description: a helper method that
   * returns the attributes.
   */
  private get attributes(): FormArray {
    return this.metaDataForm.get('attributes') as FormArray;
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * initializes the meta data list.
   */
  private initFormArray(attributes?: any[], list?: any[]): any[] {
    const entity: any = this.entity;
    const formArr: FormArray = this.attributes;

    if(entity.fileSchema) {
      attributes = entity.fileSchema.attributes;
    } else if (entity.entities) {
      attributes = entity.entities[0].attributes;
    } else {
      attributes = attributes
    }

    // get list of all the attributes
    // convert them into a group
    for (const attr of attributes) {
      const group: any = {};
      const keys: string[] = _.keys(attr);

      // convert each field into a form control
      _.each(keys, (key) => {
        // in case if the data type is missing
        // preset it to string data type
        if (key === 'dataType' && !attr[key]) {
          attr[key] = 'STRING';
        }

        group[key] = new FormControl(attr[key]);
      });

      // in case if the attribute alias
      // name wasn't returned from the
      // API do add one.
      this.setAttributes(group);

      if (!list) {
        formArr.push(new FormGroup(group));
      } else {
        list.push(new FormGroup(group));
      }

    }

    return list || [];
  }

  /**
   * @private
   * @param: {group<any>}
   * @returns: void
   * @description: a helper method that
   * sets the attributes.
   */
  private setAttributes(group: any): void {
    group.attributeLength = this.setControl(
      'attributeLength', group
    );

    group.attributeFormat = this.setControl(
      'attributeFormat', group
    );

    group.attributeComment = this.setControl(
      'attributeComment', group
    );

    group.requiredAttribute = this.setControl(
      'requiredAttribute', group
    );

    group.attributeAliasName = this.setControl(
      'attributeAliasName', group
    );

  }

  /**
   * @private
   * @param: {attr<any>}
   * @param: {group<any>}
   * @returns: any
   * @description: a helper method that
   * sets the control.
   */
  private setControl(attr: any, group: any): any {
    if (!group.hasOwnProperty(attr)) {
      return new FormControl('');
    }
    return group[attr];
  }

  /**
   * @private
   * @returns: any[]
   * @description: a helper method that
   * normalizes the data for sorting.
   */
  private sortData(
    data: FormGroup[], sort: MatSort): any[] {
    const list: any[] = [];

    // normalize the data
    // for sorting to happen
    for (const item of data) {
      list.push(item.value);
    }

    // get the sorted data
    const sorted: any[] = this.sorter.call(
      this, list, sort
    );

    return this.initFormArray(sorted, []);
  }

  /**
   * @private
   * @returns: void
   * @description: a helper method that
   * saves the meta data.
   */
  public saveMetaData(): void {
    this.dataImportService
    .updateMetadata(
      environment.updateMetadataUrl + this.customerImportFileId,
      this.metaDataForm.value.attributes
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (!!res) {
        this.success.emit(true);
      } else {
        this.handleErrors();
      }
    }, err => {
      this.handleErrors();
    });
  }

  /**
   * @private
   * @return: void
   * @description: a helper method that
   * handles import metadata API errors.
   */
   private handleErrors(): void {
    this.error.emit(true);
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
   public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
