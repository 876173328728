import * as go from 'gojs';
import { TASK_GROUP_MIN_HEIGHT, TASK_GROUP_MIN_WIDTH } from '../../../consts/task-groups';

const shouldUseCollapsedSize = (
  { size, collapsed }: go.ObjectData
) => !size || collapsed;

const getCollapsedSize = () =>
  new go.Size(TASK_GROUP_MIN_WIDTH, TASK_GROUP_MIN_HEIGHT);

const readModelValue = (data: go.ObjectData) => shouldUseCollapsedSize(data)
? getCollapsedSize()
: go.Size.parse(data.size);

const writeModelValue =
  (value: go.Size, data: go.ObjectData, model: go.GraphLinksModel) => {
    if (data.collapsed) {
      return;
    }

    model.setDataProperty(data, 'size', go.Size.stringify(value));
  };

export const sizeBinding = () =>
  new go.Binding('desiredSize', '', readModelValue)
    .makeTwoWay(writeModelValue);

export const resizableBinding = () =>
  new go.Binding('resizable', 'collapsed', (collapsed) => !collapsed);
