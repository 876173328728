import * as _ from 'lodash/fp';

import { TaskCatalogItemParameter } from '../../../types/taskCatalog';
import { snakeCaseToLabel } from '../../../utils/snakeCaseToLabel';
import { ComplexInputPayload, FormField, ParameterType, UnionInputPayload } from '../../../types/form';
import { mapParameterTypeToInputType } from './mapParameterTypeToInputType';
import { capitalizeFirstChar } from '../../../utils/capitalizeFirstChar';
import { getFullParameterType } from './getFullParameterType';
import { InputType } from '../../shared/form-field/form-field.types';
import { mapComplexToInputValue } from '../../utils/complex/mapComplexToInputValue';
import { mapUnionToInputValue } from '../../utils/union/mapUnionToInputValue';
import { mapDateToInputValue } from '../../utils/date/mapDateToInputValue';

export const mapToInputValue = (
    value: string,
    formInputType: InputType,
    parameter: TaskCatalogItemParameter
): string | number | boolean | UnionInputPayload | ComplexInputPayload => {
    switch (formInputType) {
        case 'number': {
            const numeric = parseFloat(value);
            return isNaN(numeric) ? undefined : numeric;
        }
        case 'checkbox': {
            return _.isNil(value)
                ? null
                : value.toString().toLowerCase() === 'true';
        }
        case 'date': {
            return mapDateToInputValue(value);
        }
        case 'union': {
            return mapUnionToInputValue(
                value,
                parameter,
                mapToInputValue
            );
        }
        case 'complex': {
            return mapComplexToInputValue(
                value,
                parameter
            );
        }
        default: {
            return value;
        }
    }
};


export const mapParameterToFormField = (
    parameter: TaskCatalogItemParameter
): FormField => {
    const {
        id,
        description,
        type,
        value,
        required,
        typeCategory
    } = parameter;
    const formInputType = mapParameterTypeToInputType(
        getFullParameterType(
            type as ParameterType,
            typeCategory as ParameterType
        )
    );

    return {
        id,
        label: snakeCaseToLabel(id),
        description: capitalizeFirstChar(description),
        type: formInputType,
        value: mapToInputValue(
            value,
            formInputType,
            parameter
        ),
        default: mapToInputValue(
            parameter.default,
            formInputType,
            parameter
        ),
        required,
        paramType: type
    };
};
