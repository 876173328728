import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {
    VisualizationDialogComponent,
    VisualizationDialogParams,
} from './visualization-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class VisualizationDialogRef {
    constructor(private dialog: MatDialog) {}

    show(
        data: VisualizationDialogParams | null
    ): MatDialogRef<VisualizationDialogComponent, any> {
        return this.dialog.open(VisualizationDialogComponent, {
            data,
            width: '1242px',
            minWidth: '80vh',
            height: '805px',
            maxHeight: '80vh',
        });
    }
}
