import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry, MatMenuModule } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import {
    ButtonModule,
    getIconRegistry,
    IconButtonModule,
    IconModule,
} from '@xfusiontech/shared';
import { ViewComponent } from './view/view.component';
import {
    LayoutComponent,
    LayoutContentDirective,
    LayoutSidebarDirective,
} from './layout.component';

const customIcons = [
    'query_search',
    'query_editor',
    'half_circle',
    'shared_by_me',
    'shared_with_me',
    'query',
    'new_visualization',
];

@NgModule({
    imports: [CommonModule],
    declarations: [
        ViewComponent,
        LayoutComponent,
        LayoutContentDirective,
        LayoutSidebarDirective,
    ],
    entryComponents: [ViewComponent, LayoutComponent],
    exports: [
        ViewComponent,
        LayoutComponent,
        LayoutContentDirective,
        LayoutSidebarDirective,
    ],
    providers: [],
})
export class LayoutModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace('custom', iconsPath)(customIcons);
    }
}
