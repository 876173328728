import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { ButtonModule, FormFieldModule, IconModule } from '@xfusiontech/shared';
import { ShareInDashboardDialogComponent } from './share-in-dashboard-dialog.component';
import { ShareInDashboardDialogService } from './share-in-dashboard-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        ButtonModule,
        FormFieldModule,
        IconModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
    ],
    declarations: [ShareInDashboardDialogComponent],
    exports: [ShareInDashboardDialogComponent],
    entryComponents: [ShareInDashboardDialogComponent],
    providers: [ShareInDashboardDialogService],
})
export class ShareInDashboardDialogModule {}
