import * as _ from 'lodash/fp';
import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
    selector: 'udp-form-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ListComponent  {

    @Input()
    set control(control: FormControl) {
        this._control = control;
        this.createEntriesFormControl(control.value);
    }
    get control(): FormControl {
        return this._control;
    }
    private _control: FormControl;

    @Input()
    tooltipMessage: string;

    @Input()
    label: string;

    entriesFormControl: FormArray;

    constructor(
        private formBuilder: FormBuilder
    ) {
        this.createEntriesFormControl([]);
    }

    onKeyDown({ key }: KeyboardEvent, last: boolean) {
        if (last && key === 'Enter') {
            this.addNewItem();
        }
    }

    addNewItem() {
        this.entriesFormControl.push(
            this.formBuilder.control('')
        );
    }

    removeItem(idx: number) {
        this.entriesFormControl.removeAt(idx);
    }

    private updateValue() {
        const nextValue = _.filter(
            (entry: string) => !!entry.length
        )(this.entriesFormControl.getRawValue());

        this.control.markAsDirty();
        this.control.setValue(nextValue);
    }

    private createEntriesFormControl(entries: string[]) {
        this.entriesFormControl = this.formBuilder.array(
            _.flowRight(
                _.map((entry: string) => this.formBuilder.control(entry))
            )(entries)
        );
        this.entriesFormControl.valueChanges
            .subscribe(() => {
                this.updateValue();
            });
    }

}
