import * as go from 'gojs';
import { Point } from '../../types/shared';

export const mapDocumentToDiagramPoint = (
    diagram: go.Diagram,
    point: Point
) => {
    const { left: diagramX, top: diagramY } = diagram.div
        .getBoundingClientRect();
    const { x, y } = point;

    return diagram.transformViewToDoc(new go.Point(
        x - diagramX,
        y - diagramY
    ));
};
