import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges
} from "@angular/core";
import { DecideConfiguration } from "./decide-configuration-model";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { IntegrationWorkflowService } from 'src/app/workflow-editor/integration-workflow.service';

@Component({
  selector: "app-decide-configuration",
  templateUrl: "./decide-configuration.component.html",
  styleUrls: ["./decide-configuration.component.scss"]
})
export class DecideConfigurationComponent implements OnInit {
  public decideConfigurationDataObj = [];
  decideConfigurationForm: FormGroup;
  dataModel2: DecideConfiguration;
  relatedSubjectDropdownOptions: any[] = [];
  relatedSubjectRawDropdownOptions: any[] = [];
  identificationRuleSetDropdownOptions: any[] = [];
  @Input() parentData: any;
  @Input() decisionConfigurationData = [];
  @Input() dropDownData: any[] = [];
  popuptitle = "";
  popupbutton = "";
  editArray = [];
  isEditPopUp = false;

  config = {
    displayKey: "description", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
    // customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: 5, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    // searchPlaceholder:'Search' // label thats displayed in search input,
    // searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
  };
  constructor(
    private _fb: FormBuilder,
    private _workFlowService: IntegrationWorkflowService
  ) {
    this.dataModel2 = new DecideConfiguration();
  }
  @ViewChild("CloseWorkFlow2", { static: false }) CloseWorkFlow2;

  ngOnInit() {
    // this.relatedSubjectDropdownOptions = this.dataModel2.decideSubjectRelatedSet;
    // this.identificationRuleSetDropdownOptions = this.dataModel2.identificationDecisionRuleSet;
    this.decideConfigurationForm = this._fb.group({
      decideSubjectRelatedType: ["", Validators.required],
      identificationRuleSetType: ["", Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.decideConfigurationDataObj = [];
    if (
      this.decisionConfigurationData &&
      this.decisionConfigurationData.length > 0
    ) {
      this.decisionConfigurationData.map(e1 => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "identify") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              let jsonParse = JSON.parse(
                e1.IntegrationWorkflowComponentVersion
              );

              if (jsonParse.length > 0) {
                jsonParse.map(e2 => {
                  let data = [];
                  if (e2 && e2 !== null) {
                    data = e2;
                    data["id"] = e1.WorkflowComponentTypes.id;
                    data["parentid"] = e1.IntegrationWorkflowID;
                    this.decideConfigurationDataObj.push(data);
                  }
                });
              } else {
                let data = [];
                data = jsonParse;
                data["id"] = e1.WorkflowComponentTypes.id;
                data["parentid"] = e1.IntegrationWorkflowID;
                this.decideConfigurationDataObj.push(data);
              }
            }
          }
        }
      });
      this.getSubjectDropDownData();
    }
  }

  extractChildren(obj) {
    let keysArray = Object.keys(obj);
    keysArray = keysArray.filter(e => e !== "integration");
    return keysArray;
  }

  get f2() {
    return this.decideConfigurationForm.controls;
  }

  getPopupTitle() {
    this.popuptitle = "Add";
    this.popupbutton = "Save";
  }

  onSubmitDecideConfiguration() {

    let formdata: any;
    //converyting Submitted form data into API format
    formdata = {
      integration_pid: this.parentData.sourceDataTypeCode,
      decideSubjectRelatedType: this.decideConfigurationForm.value
        .decideSubjectRelatedType,
      identificationRuleSetType: this.decideConfigurationForm.value
        .identificationRuleSetType
    };

    if (this.isEditPopUp === true) {
      this.decideConfigurationDataObj.filter((e, index) => {
        if (JSON.stringify(e) === JSON.stringify(this.editArray)) {
          this.decideConfigurationDataObj.splice(index, 1);
        }
      });
    }

    this.decideConfigurationDataObj.push(formdata);
    let saveObj = {
      Source: 2,
      SourceDataTypeCode: this.parentData.sourceDataTypeCode,
      Status: this.parentData.status,
      TargetDataTypeCode: this.parentData.targetDataTypeCode,
      WorkflowID: this.parentData.workflowID,
      WorkflowComponentTypeCode: "identify",
      IntegrationWorkflowComponentName: "Decision Data",
      IntegrationWorkflowComponentStatus: "Active",
      WorkflowComponent: JSON.stringify(this.decideConfigurationDataObj)
    };
    this._workFlowService
      .updateIntegrationWorkflowInnerModule(saveObj)
      .subscribe(result => {
        this.decideConfigurationForm.reset();
        this.CloseWorkFlow2.nativeElement.click();
      });

    this.isEditPopUp = false;
  }

  relatedSubjectSelectionChanged() {

    let selectedSubject = this.decideConfigurationForm.get(
      "decideSubjectRelatedType"
    ).value;
    if (
      this.dropDownData.filter(i => i.SubjectEntityName == selectedSubject)
        .length > 0
    ) {
      this.identificationRuleSetDropdownOptions = this.dropDownData
        .find(i => i.SubjectEntityName == selectedSubject)
      ["DecisionConfigurationMap"].map(
        i => i.DecisionConfigurationRulesetName
      );
      // .find(
      //   i => i.ScoringConfigurationRuleSetName == event.value
      // )
      // .RulesetParameters.map(i => i.RulesetParameterDataName);
    }
  }
  getIdentificationDecisionRuleset() { }
  relatedSubjectFilterData($event) {
    this.relatedSubjectDropdownOptions = this.dataModel2.decideSubjectRelatedSet.filter(
      i => i.description.toLowerCase().includes($event.toLowerCase())
    );
  }

  identificationSelectionChanged() {
  }

  identificationRuleSetFilterData($event) {
    this.identificationRuleSetDropdownOptions = this.dataModel2.identificationDecisionRuleSet.filter(
      i => i.description.toLowerCase().includes($event.toLowerCase())
    );
  }

  editDecideConfiguration(queryData) {
    this.popuptitle = "Edit";
    this.popupbutton = "Update";
    this.editArray = [];
    this.getSubjectDropDownData();
    if (
      this.decisionConfigurationData &&
      this.decisionConfigurationData.length > 0
    ) {
      this.decisionConfigurationData.map(e1 => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "identify") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              let jsonParse = JSON.parse(
                e1.IntegrationWorkflowComponentVersion
              );
              if (jsonParse.length > 0) {
                jsonParse.map((e2, index) => {
                  if (e2 && index === queryData) {
                    let data = [];
                    data = e2;
                    data["id"] = e1.WorkflowComponentTypes.id;
                    data["parentid"] = e1.IntegrationWorkflowID;
                    this.editArray = data;
                  }
                });
              } else {
                let data = [];
                data = jsonParse;
                data["id"] = e1.WorkflowComponentTypes.id;
                data["parentid"] = e1.IntegrationWorkflowID;
                this.editArray = data;
              }
            }
          }
        }
      });
    }

    this.decideConfigurationForm.setValue({
      decideSubjectRelatedType: this.editArray["decideSubjectRelatedType"],
      identificationRuleSetType: this.editArray["identificationRuleSetType"]
    });
    this.relatedSubjectSelectionChanged();
    this.isEditPopUp = true;
  }

  deleteDecideConfiguration(deleteid) {
    this.editArray = [];
    if (
      this.decisionConfigurationData &&
      this.decisionConfigurationData.length > 0
    ) {
      this.decisionConfigurationData.map(e1 => {
        if (e1 && this.parentData.workflowID === e1.IntegrationWorkflowID) {
          if (e1.WorkflowComponentTypeCode === "identify") {
            if (
              e1.IntegrationWorkflowComponentVersion &&
              e1.IntegrationWorkflowComponentVersion != ""
            ) {
              let jsonParse = JSON.parse(
                e1.IntegrationWorkflowComponentVersion
              );
              if (jsonParse.length > 0) {
                jsonParse.map((e2, index) => {
                  if (e2 && index == deleteid) {
                    let data = [];
                    data = e2;
                    data["id"] = e1.WorkflowComponentTypes.id;
                    data["parentid"] = e1.IntegrationWorkflowID;
                    this.editArray = data;
                  }
                });
              } else {
                let data = [];
                data = jsonParse;
                data["id"] = e1.WorkflowComponentTypes.id;
                data["parentid"] = e1.IntegrationWorkflowID;
                this.editArray = data;
              }

              // JSON.parse(e1.IntegrationWorkflowComponentVersion).map(
              //   (e2, index) => {
              //     if (e2 && e2 !== null) {
              //       if (index === deleteid) {
              //         let data = [];
              //         data = e2;
              //         data["id"] = e1.WorkflowComponentTypes.id;
              //         data["parentid"] = e1.IntegrationWorkflowID;
              //         this.editArray = data;
              //       }
              //     }
              //   }
              // );
            }
          }
        }
      });
    }

    this.decideConfigurationDataObj.filter((e, index) => {
      if (JSON.stringify(e) === JSON.stringify(this.editArray)) {
        this.decideConfigurationDataObj.splice(index, 1);
      }
    });

    let saveObj = {
      Source: 2,
      SourceDataTypeCode: this.parentData.sourceDataTypeCode,
      Status: this.parentData.status,
      TargetDataTypeCode: this.parentData.targetDataTypeCode,
      WorkflowID: this.parentData.workflowID,
      WorkflowComponentTypeCode: "identify",
      IntegrationWorkflowComponentName: "Decision Data",
      IntegrationWorkflowComponentStatus: "Active",
      WorkflowComponent: JSON.stringify(this.decideConfigurationDataObj)
    };
    this._workFlowService
      .updateIntegrationWorkflowInnerModule(saveObj)
      .subscribe(result => {
        console.info("response", result.data);
      });
  }
  getSubjectDropDownData() {
    if (this.dropDownData && this.dropDownData.length > 0) {
      this.relatedSubjectRawDropdownOptions = this.dropDownData.map(
        i => i.SubjectEntityName
      );
      this.relatedSubjectDropdownOptions = this.relatedSubjectRawDropdownOptions;
    }
  }
}
