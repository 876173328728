import * as go from 'gojs';

import { togglePropertiesTable } from '../utils/togglePropertiesTable';
import { topRoundedRectangle } from './parts/node/topRoundedRectangle';
import { updatePortsVisibility } from '../utils/updateDiagramPortsVisibility/updatePortsVisibility';
import { scrollablePropertiesTable } from './parts/node/propertiesTable/propertiesTable';
import { expandButton } from './parts/node/expandButton';
import {
  MAIN_SHAPE_NAME,
  NODE_COLLAPSED_HEIGHT,
  NODE_EXPANDED_HEIGHT,
  NODE_WIDTH
} from '../consts/shared';
import { Port } from '../../types/workflowDefinition';
import { labelPanel } from './parts/node/labelPanel';
import { port } from './parts/ports/port';
import { Category } from '../types';
import { getExpandedNodeHeight } from './parts/node/propertiesTable/utils';
import { locationBinding } from './bindings/locationBinding';
import { shadow } from './parts/shared/shadow';

const $ = go.GraphObject.make;

const getShapeHeight = (part: go.Part, panel: go.GraphObject) =>
  part.data.expanded ? getExpandedNodeHeight(part) : panel.height;

const mainShape = () =>
  $(
    go.Shape,
    'RoundedRectangle',
    {
      name: MAIN_SHAPE_NAME,
      desiredSize: new go.Size(NODE_WIDTH, 116),
      fill: 'white',
      opacity: 1,
      parameter1: 16,
      stroke: null,
      alignment: go.Spot.Center,
      alignmentFocus: go.Spot.Center,
      maxSize: new go.Size(NaN, NODE_EXPANDED_HEIGHT)
    },
    new go.Binding('height', '', getShapeHeight).ofObject()
  );

const contentLayout = (...children: go.GraphObject[]) =>
  $(
    go.Panel,
    go.Panel.Vertical,
    {
      alignment: go.Spot.Top,
      alignmentFocus: go.Spot.Top,
      height: NODE_COLLAPSED_HEIGHT
    },
    ...children
  );

const selectionLayout = () => {
  return {
    selectionAdornmentTemplate: $(
      go.Adornment,
      'Auto',
      $(go.Shape, 'RoundedRectangle', {
        fill: null,
        stroke: 'dodgerblue',
        strokeWidth: 2.5
      }),
      $(go.Placeholder)
    )
  };
};

const node = (...children: go.GraphObject[]) =>
  $(
    go.Node,
    go.Panel.Spot,
    {
      selectionAdorned: true,
      doubleClick: (__, obj: go.GraphObject) => togglePropertiesTable(obj),
      mouseEnter: (__, obj: go.GraphObject) => updatePortsVisibility(obj, 1),
      mouseLeave: (__, obj: go.GraphObject) => updatePortsVisibility(obj, 0),
      layerName: 'Foreground',
      ...shadow(),
    },
    locationBinding(),
    ...children,
    port(Port.Left),
    port(Port.Right),
    selectionLayout()
  );

export const createNodeTemplate = () => ({
  category: Category.Node,
  template: node(
    mainShape(),
    contentLayout(topRoundedRectangle(), labelPanel(), expandButton()),
    scrollablePropertiesTable()
  )
});
