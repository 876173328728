import * as go from 'gojs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { SelectionService } from './selection.service';
import { align, AlignmentType } from '../utils/align';
import { canBeAligned } from '../utils/canBeAligned';
import { DiagramService } from './base.service';

@Injectable()
export class AlignmentService extends DiagramService {

    static MIN_ALIGNED_ELEMENTS_COUNT = 2;

    constructor(
        private selectionService: SelectionService
    ) {
        super();
    }

    getCanAlignSelection() {
        return this.selectionService.selection
            .pipe(map((selection) => {
                const alignableElements = this.filterAlignableElements(
                    selection
                );

                return alignableElements.count
                    >= AlignmentService.MIN_ALIGNED_ELEMENTS_COUNT;
            }));
    }

    alignSelection(type: AlignmentType) {
        align(
            this.filterAlignableElements(
                this.selectionService.getCurrentSelection(),
            ),
            type
        );
    }

    private filterAlignableElements(elements: go.Set<go.Part>) {
        return elements.filter(
            (part) => canBeAligned(part)
        );
    }
}
