import * as go from 'gojs';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DiagramService } from './base.service';
import { Diagram } from '../Diagram';

@Injectable()
export class SelectionService extends DiagramService {

    get selection(): Observable<go.Set<go.Part>> {
        return this._selection;
    }
    private _selection: Subject<go.Set<go.Part>> = new Subject();

    bindDiagram(diagram: Diagram) {
        super.bindDiagram(diagram);

        this.diagram.addDiagramListener(
            'ChangedSelection',
            this.onSelectionChanged
        );
    }

    getCurrentSelection(): go.Set<go.Part> {
        return this.diagram.selection;
    }

    refreshSelection() {
        this.onSelectionChanged();
    }

    removeCurrentSelection() {
        this.diagram.commandHandler.deleteSelection();
    }

    unbindDiagram() {
        this.diagram.removeDiagramListener(
            'ChangedSelection',
            this.onSelectionChanged
        );
        super.unbindDiagram();
    }

    private onSelectionChanged = () => {
        this._selection.next(this.diagram.selection);
    }

    getDiagram() {
        return this.diagram;
    }

}
