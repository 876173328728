import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UdpDataTransformationOperatorComponent } from './udp-data-transformation-operator.component';
import { StringArrayToSelectOptionsModule } from '@xfusiontech/shared';
import { DataStudioModule } from '@xfusiontech/data-studio';
import { TransformationOperatorEventComponent } from './transformation-operator-event/transformation-operator-event.component';
import { TransformationOperatorEventHelperService } from './transformation-operator-event/transformation-operator-event-helper.service';

@NgModule({
  declarations: [
    UdpDataTransformationOperatorComponent,
    TransformationOperatorEventComponent
  ],
  imports: [
    CommonModule,
    StringArrayToSelectOptionsModule,
    DataStudioModule
  ],
  exports: [
    UdpDataTransformationOperatorComponent,
    TransformationOperatorEventComponent
  ],
  providers:[TransformationOperatorEventHelperService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UdpDataTransformationOperatorModule {}
