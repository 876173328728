// Modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material';
import { ImportDatasetModule } from '../import-dataset/import-dataset.module';
import { SharedDataModule } from '../shared/modules/shared-data/shared-data.module';

// Library Imports
import { DataImportModule } from './../../../../data-import';
import { QueryEditorPageModule } from './../../../../query-editor';
import { ButtonModule, FormFieldModule } from './../../../../shared';

// Components
import { CreateDatasetComponent } from './create-dataset.component';
import { ViewDataComponent } from '../shared/modules/shared-data/view-data/view-data.component';

/**
 * @author: Naga
 * @constant
 */
 const EXPORTS: any[] = [
  CreateDatasetComponent
];

/**
 * @module
 */
@NgModule({
  declarations: [
    ...EXPORTS
  ],
  imports: [
    CommonModule
    , FormsModule
    , ButtonModule
    , MatRadioModule
    , FormFieldModule
    , DataImportModule
    , SharedDataModule
    , ImportDatasetModule
    , QueryEditorPageModule
  ],
  exports: [...EXPORTS],
  entryComponents: [ViewDataComponent]
  
})
export class CreateDatasetModule {}
