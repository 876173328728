import * as go from 'gojs';
import { v4 as uuidv4 } from 'uuid';

import { DiagramService } from './services/base.service';
import { createTemplateMap } from './utils/createTemplateMap';
import { createNodeTemplate } from './templates/nodeTemplate';
import { createLinkTemplate } from './templates/linkTemplate';
import { setupNodesScrolling } from './templates/parts/node/scrollableContainer/setupNodesScrolling';
import { DIAGRAM_SCALE_INITIAL, DIAGRAM_SCALE_MAX, DIAGRAM_SCALE_MIN } from './consts/shared';
import { DiagramTools, DiagramValidationTool } from './types';
import { createTaskGroupTemplate } from './templates/taskGroupTemplate';
import { handleDiagramMouseDrop } from './handlers/handleDiagramMouseDrop';

export class Diagram extends go.Diagram {

  model: go.GraphLinksModel;
  validation: DiagramValidationTool;

  constructor(
    div: HTMLDivElement,
    private services: DiagramService[],
    tools: DiagramTools
  ) {
    super(div);
    // tslint:disable-next-line: max-line-length
    go.Diagram.licenseKey = '2bf840e7b56658c511d35a25403e7efb0ea02d3bcf824ef7595316f6ed5f601123cce17b55d299d0d5f01ef41a7493d189956b2c93480c6be232dbdf47b680f0b03275b2165b17d9a15071909af82df3f52b24f3c7e727aada7b8df1eefb93954ef8f58118cc0ee979791421557fac4aa8fbc678f8';

    this.setupProperties();
    this.setupTools(tools);
    this.setupModel();
    this.setupTemplates();
    this.bindServices();
    setupNodesScrolling(this);
  }

  unbindServices() {
    this.services.forEach(
      (service) => service.unbindDiagram()
    );
  }

  private setupProperties() {
    this.scrollMode = go.Diagram.InfiniteScroll;
    this.scale = DIAGRAM_SCALE_INITIAL;
    this.minScale = DIAGRAM_SCALE_MIN;
    this.maxScale = DIAGRAM_SCALE_MAX;
    this.mouseDrop = handleDiagramMouseDrop;
  }

  private setupTools(tools: DiagramTools) {
    const {
      commandHandler, linkingTool
    } = tools;
    this.commandHandler = commandHandler;
    this.toolManager.linkingTool = linkingTool;
    this.toolManager.hoverDelay = 400;
    this.toolManager.toolTipDuration = 10000;
    this.toolManager.linkingTool.portGravity = 20;
  }

  private setupModel() {
    const model = new go.GraphLinksModel([], []);
    model.makeUniqueLinkKeyFunction = () => uuidv4();
    model.makeUniqueKeyFunction = () => uuidv4();
    model.nodeKeyProperty = 'id';
    model.linkKeyProperty = 'id';
    model.linkFromPortIdProperty = 'fromPort';
    model.linkToPortIdProperty = 'toPort';

    this.model = model;
  }

  private setupTemplates() {
    this.nodeTemplateMap = createTemplateMap<go.Part>(
      createNodeTemplate()
    );

    this.groupTemplateMap = createTemplateMap<go.Group>(
      createTaskGroupTemplate()
    );

    this.linkTemplate = createLinkTemplate();
  }

  private bindServices() {
    this.services.forEach(
      (service) => service.bindDiagram(this)
    );
  }
}
