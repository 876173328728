import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { IconModule } from '../icon/icon.module';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuIconItemComponent } from './menu-icon-item/menu-icon-item.component';

const imports = [
    CommonModule,
    RouterModule,
    IconModule
];

const components = [
    MenuComponent
];

const innerComponents = [
    MenuItemComponent,
    MenuIconItemComponent
];

@NgModule({
    imports,
    declarations: [
        ...components,
        ...innerComponents
    ],
    exports: components
})
export class MenuModule {}
