import { event } from 'jquery';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {

  private dataIntegrationConfigJson = new BehaviorSubject<any>(null);
  private dataIntegrationConfigJsonWithoutAM = new Subject<any>();
  private clonedWorkflowMessageStore = new Subject<any>();
  private statusVersionMessageStore = new Subject<any>();
  private storeIdentificationConfigDict = new Subject<any>();
  private identificationConfigDictStoreSuccess = new Subject<any>();
  private storeSearchFlagValue = new Subject<any>();
  private storeMenuFlagValue = new Subject<any>();
  private storeDataPipeLineValue = new Subject<any>();
  private showWorkFlowData = new Subject<any>();
  private deleteOrgConfirm = new Subject<string>();
  private deleteRoleConfirm = new Subject<string>();
  private addOrgConfirm = new Subject<string>();
  private addRoleConfirm = new Subject<string>();
  private accessRouteValue: any;



  dataIntegrationConfigJson$ = this.dataIntegrationConfigJson.asObservable();
  emptyAssociationMapping$ = this.dataIntegrationConfigJsonWithoutAM.asObservable();
  clonedMessageStore$ = this.clonedWorkflowMessageStore.asObservable();
  statusVersionUpdate$ = this.statusVersionMessageStore.asObservable();
  storedIdentificationConfigDict$ = this.storeIdentificationConfigDict.asObservable();
  identificationConfigDictStoreSuccess$ = this.identificationConfigDictStoreSuccess.asObservable();
  storeSearchFlagValue$ = this.storeSearchFlagValue.asObservable();
  storeMenuFlagValue$ = this.storeMenuFlagValue.asObservable();
  storeDataPipeLineValue$ = this.storeDataPipeLineValue.asObservable();
  showWorkFlowData$ = this.showWorkFlowData.asObservable();
  deleteOrgConfirm$ = this.deleteOrgConfirm.asObservable();
  deleteRoleConfirm$ = this.deleteRoleConfirm.asObservable();
  addOrgConfirm$ = this.addOrgConfirm.asObservable();
  addRoleConfirm$ = this.addRoleConfirm.asObservable();



  storeDataIntegrationConfig(data: any) {
    this.dataIntegrationConfigJson.next(data);
  }

  emptyAssociationMapping() {
    this.dataIntegrationConfigJsonWithoutAM.next(Math.random());
  }

  newClonedWorkflow(data: String) {
    this.clonedWorkflowMessageStore.next(data);
  }

  statusVersionUpdate(data: String) {
    this.statusVersionMessageStore.next(data);
  }

  storeIdentificationCofigDict(data: any) {
    this.storeIdentificationConfigDict.next(data);
  }

  storeIdentificationCofigDictSuccess(data: any) {
    this.identificationConfigDictStoreSuccess.next(data);
  }

  storeSearchFlag(data: any) {
    this.storeSearchFlagValue.next(data);
  }

  storeMenuFlag(data: any) {
    this.storeMenuFlagValue.next(data);
  }

  storeDataPipeLine(data: any) {
    this.storeDataPipeLineValue.next(data);
  }

  setAccessRouteFunction(data: any) {
    this.accessRouteValue = data;
  }

  getAccessRouteFunction() {
    return this.accessRouteValue;
  }

  storeSideBarWrkFlwData(data: any) {
    this.showWorkFlowData.next(data);
  }

  setConfirmEvent(data: any, item) {
    if (data == 'delete') {
      if (item == 'organization') {
        this.deleteOrgConfirm.next(data);
      }
      else if (item == 'role') {
        this.deleteRoleConfirm.next(data);
      }
    }
    else if (data == 'add') {
      if (item == 'organization') {
        this.addOrgConfirm.next(data);
      }
      else if (item == 'role') {
        this.addRoleConfirm.next(data);
      }
    }
  }
}