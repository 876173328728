import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from './toolbar.service';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'xft-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {

  /**
   * @public
   * @type: {any}
   * @input
   */
  @Input()
  public data: any;

  @Input()
  public nodeId: any;

  /**
   * @public
   * @type: {string}
   */
  public projectName: string;

  /**
   * @private
   * @type: {ReplaySubject<boolean>}
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * @constructor
   * @param: {toolbarService<ToolbarService>}
   */
  constructor(public toolbarService: ToolbarService) {

    // get project name async
    this.toolbarService.getProjectName()
    .pipe(takeUntil(this.destroy$))
    .subscribe((name: string) => {
      this.projectName = name;
    });
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
