import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DataAssetCategory,
    EditVisualizationDialogService,
    Visualization,
} from '@xfusiontech/data-visualizer';
import { PERMISSIONS } from '@xfusiontech/shared';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfirmDialogService } from '../../components/confirm-dialog/confirm-dialog.service';
import { CategoryDialogService } from './../../components/category-dialog/category-dialog.service';
import { ShareByEmailDialogService } from './../../components/share-by-email-dialog/share-by-email-dialog.service';
import { ShareInDashboardDialogService } from './../../components/share-in-dashboard-dialog/share-in-dashboard-dialog.service';
import {
    CATEGORY_ID_PARAM,
    DASHBOARD_PATH,
    VISUALIZATION_PATH,
} from './../../consts/paths';
import { ShareService } from '../../services/share.service';
import { DashboardPageService } from './dashboard-page.service';

@Component({
    selector: 'igv-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPageComponent implements OnInit, OnDestroy {
    PERMISSIONS = PERMISSIONS;
    dataAssetCategories: DataAssetCategory[];

    activeDataAssetCategory$: Observable<DataAssetCategory>;
    selectedCategory: string;

    private onDestroy$ = new Subject();

    constructor(
        private service: DashboardPageService,
        private shareService: ShareService,
        private categoryDialogService: CategoryDialogService,
        private confirmDialogService: ConfirmDialogService,
        private shareByEmailDialogService: ShareByEmailDialogService,
        private shareInDashboardDialogService: ShareInDashboardDialogService,
        private editVisualizationDialogService: EditVisualizationDialogService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.route.params.subscribe(params => {
            this.selectedCategory = params['categoryId'];
        });
    }

    ngOnInit() {
        this.service.queryDataAssetsCategories();
        this.service.dataAssetCategories
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                dataAssetCategories =>
                    (this.dataAssetCategories = dataAssetCategories)
            );

        this.activeDataAssetCategory$ = combineLatest([
            this.service.dataAssetCategories,
            this.route.paramMap.pipe(
                map(paramMap => paramMap.get(CATEGORY_ID_PARAM))
            ),
        ]).pipe(
            map(([categories, activeCategoryId]) =>
                categories.find(c => c.dataAssetCategoryID === activeCategoryId)
            )
        );
    }

    onCategoryEditClick(category?: DataAssetCategory) {
        const dialogRef = this.categoryDialogService.show(
            category || {
                dataAssetType: 'VISUALIZATION',
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(result => this.service.createOrUpdateCategory(result));
    }

    onCategoryDelete(category: DataAssetCategory) {
        const dialogRef = this.confirmDialogService.show({
            title: 'Confirm',
            message:
                'Are you sure to delete the category? All associated assets will be deleted as well.',
            buttonCancel: 'Cancel',
            buttonOK: 'Delete',
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(() => {
                this.service.deleteCategory(category);
                this.router.navigate([DASHBOARD_PATH]);
            });
    }

    onVisualizationClick(visualization: Visualization) {
        this.router.navigate([
            VISUALIZATION_PATH,
            visualization.visualizationID,
        ]);
    }

    onEditVisualization(visualization: Visualization) {
        const dialogRef = this.editVisualizationDialogService.show({
            visualization,
            dataAssetCategories: this.dataAssetCategories,
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(
                (result: {
                    visualization: Visualization;
                    categoryId: number;
                }) => {
                    this.service.editVisualization(
                        result.visualization,
                        result.categoryId
                    );
                }
            );
    }

    onDeleteVisualization(visualization: Visualization) {
        const dialogRef = this.confirmDialogService.show({
            title: 'Confirm',
            message: 'Are you sure to delete the visualization?',
            buttonCancel: 'Cancel',
            buttonOK: 'Delete',
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(() => {
                this.service.deleteVisualization(visualization);
                this.router.navigate([DASHBOARD_PATH]);
            });
    }

    onShareVisualizationByEmail(visualization: Visualization) {
        const dialogRef = this.shareByEmailDialogService.show({
            type: 'VISUALIZATION',
            entity: visualization,
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(email => !!email),
                take(1)
            )
            .subscribe(email =>
                this.service.sendVisualizationByEmail(email, visualization)
            );
    }

    onShareVisualizationInDashboard(visualization: Visualization) {
        const dialogRef = this.shareInDashboardDialogService.show({
            type: 'VISUALIZATION',
            entity: visualization,
            userQuery: this.shareService.getShareToUsers(),
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(result => !!result),
                take(1)
            )
            .subscribe(
                ({ sharedTo, users }: { sharedTo: string; users: string[] }) =>
                    this.service.shareVisualizationInDashboard(
                        sharedTo,
                        users,
                        visualization
                    )
            );
    }

    onCreateNewVisualization() {
        this.router.navigate([VISUALIZATION_PATH]);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
