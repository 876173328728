// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../shared/icon/icon.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SnackbarModule } from '../shared/snackbar/snackbar.module';
import { FormFieldModule } from '../shared/form-field/form-field.module';
import { IconButtonModule } from '../shared/icon-button/icon-button.module';
import { ExpansionPanelModule } from '../shared/expansion-panel/expansion-panel.module';

// Components
import { ItemComponent } from './item/item.component';
import { PaletteComponent } from './palette.component';
import { SearchBarComponent } from './search-bar/search-bar.component';

// Services
import { PaletteService } from './palette.service';

/**
 * @module
 */
@NgModule({
  imports: [
    IconModule
    , CommonModule
    , DragDropModule
    , MatInputModule
    , SnackbarModule
    , MatButtonModule
    , FormFieldModule
    , IconButtonModule
    , MatTooltipModule
    , ExpansionPanelModule
    , PerfectScrollbarModule
  ],
  declarations: [
    ItemComponent
    , PaletteComponent
    , SearchBarComponent
  ],
  providers: [
    PaletteService
  ],
  exports: [
    PaletteComponent
  ]
})
export class PaletteModule {}
