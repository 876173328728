import { Injectable } from '@angular/core';
import * as go from 'gojs';

import { validateLink } from '../services/validation/links/validateLink';

@Injectable()
export class LinkingTool extends go.LinkingTool {

  get linkValidation() {
    return validateLink;
  }

}
