import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, ViewChild } from '@angular/core';

import { ValueObject } from '../../../types/shared';
import { CodeEditorLanguage } from '../types';

@Component({
    selector: 'xft-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeEditorComponent {

    @Input()
    set language(language: CodeEditorLanguage) {
        this.editorOptions.language = language;
    }

    @Input()
    value: string;

    editorOptions: ValueObject = {
        theme: 'vs-light'
    };

    @ViewChild('editor', { static: false })
    editor: ValueObject;

    @Output()
    valueChange = new EventEmitter<string>();

    onValueChange(event) {
        this.valueChange.emit(event);
    }

}
