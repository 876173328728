import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  DATA_MAPPING_TITLE,
  JSON_EDITOR_TITLE,
  PYTHON_EDITOR_TITLE_DAG_SCRIPT,
  PYTHON_EDITOR_UNTITLED
} from './consts';

import { CodeEditorPanelMode, NotValidOperator } from './types';

import { ModelSaveService } from '../../gojs/services/model-save.service';
import { ModelService } from '../../gojs/services/model.service';
import { MessagesService } from '../../services/messages.service';
import { ApiService } from '../../services/api.service';
import { WorkflowService } from '../../services/workflow.service';
import { UDPDatasetOperatorService } from 'libs/data-catalog/src/lib/udp-dataset-operator/udp-data-operator.service';
import { UdpTaskConfigService } from '../../services/udp-task-config.service';
import { miscellaneousConst } from 'src/app/shared/const/miscellaneous.const';
import _ from 'lodash';
import { TransformationOperatorEventHelperService } from 'libs/data-catalog/src/lib/udp-data-transformation-operator/transformation-operator-event/transformation-operator-event-helper.service';

@Injectable()
export class CodeEditorPanelService {
  params: any;
  branchFiles: any;
  branchFileSha: any;
  branchFile: any;
  get isCodeLoading(): Observable<boolean> {
    return this._isCodeLoading;
  }
  private _isCodeLoading: Subject<boolean> = new Subject();

  private currentValue: string;
  public datasetOperatorConfig: any;
  public datablendOperatorConfig: any;
  public dataTransformationOperatorConfig: any;
  public UDPTransformationOperatorConfig: any;

  constructor(
    private modelSaveService: ModelSaveService,
    private modelService: ModelService,
    private messagesService: MessagesService,
    private apiService: ApiService,
    private workflowService: WorkflowService,
    private udpDatasetOperatorService: UDPDatasetOperatorService,
    private udpTaskConfigService: UdpTaskConfigService,
    private transformationOperatorEventHelperService: TransformationOperatorEventHelperService
  ) {}

  private panelMode = new BehaviorSubject<CodeEditorPanelMode>({
    open: false
  });

  getPanelMode(): Observable<CodeEditorPanelMode> {
    return this.panelMode;
  }

  public assignCorrectOperatorCodeType(currentOperatorCode: string): string {
    switch (currentOperatorCode) {
      case 'DS':
        return 'UDPDatasetOperator';
      case 'DB':
        return 'UDPDataBlendOperator';
      case 'MIL':
        return 'UDP_MILOperator';
      case 'DT':
        return 'UDPTransformationOperator';
    }
  }

  public checkForSelectedOperatorValidation(
    previousTaskIDs: any[],
    currentOperatorCode: string,
    currentOperatorType: string
  ): NotValidOperator {
    if (currentOperatorCode === 'DS') {
      // check for Dataset Operator
      if (previousTaskIDs.length > 0) {
        return this.assignNotValidOperator(
          currentOperatorCode,
          currentOperatorType,
          miscellaneousConst.operators.DS.errors.noPreviousTasks
        );
      }
    } else if (currentOperatorCode === 'DB') {
      if (
        previousTaskIDs.length !== 2 ||
        !this.checkIfAllPreviousOperatorsAreDS(previousTaskIDs)
      ) {
        return this.assignNotValidOperator(
          currentOperatorCode,
          currentOperatorType,
          miscellaneousConst.operators.DB.errors.must2PreviousTasks
        );
      } else {
        // Check if previous task config is available or not
        const previousConfig1 = this.udpTaskConfigService.getConfig(
          previousTaskIDs[0]
        );
        const previousConfig2 = this.udpTaskConfigService.getConfig(
          previousTaskIDs[1]
        );
        if (!previousConfig1 || !previousConfig2) {
          return this.assignNotValidOperator(
            currentOperatorCode,
            currentOperatorType,
            miscellaneousConst.operators.DB.errors.previousConfigError
          );
        }

        // Check if previous task config has TCV State Errors

        const previousTaskID1 = previousTaskIDs[0].split('/')[1].split('.')[0];
        const previousTaskID2 = previousTaskIDs[1].split('/')[1].split('.')[0];

        if (
          this.udpTaskConfigService.TCVStateConfigs &&
          this.udpTaskConfigService.TCVStateConfigs.length > 0
        ) {
          const TCVConfig1 = this.udpTaskConfigService.TCVStateConfigs.filter(
            (tcvConfig) => tcvConfig.taskId === previousTaskID1
          );
          const TCVConfig2 = this.udpTaskConfigService.TCVStateConfigs.filter(
            (tcvConfig) => tcvConfig.taskId === previousTaskID2
          );

          if (
            (TCVConfig1 && TCVConfig1.length > 0) ||
            (TCVConfig2 && TCVConfig2.length > 0)
          ) {
            return this.assignNotValidOperator(
              currentOperatorCode,
              currentOperatorType,
              miscellaneousConst.operators.DB.errors.errorsInPreviousTask
            );
          }
        }
      }
    } else if (currentOperatorCode === 'MIL') {
      // Check for MIL Operator
      if (previousTaskIDs.length !== 1) {
        return this.assignNotValidOperator(
          currentOperatorCode,
          currentOperatorType,
          miscellaneousConst.operators.MIL.errors.must1PreviousTask
        );
      } else {
        // Check if previous task config is available or not
        const previousConfig = this.udpTaskConfigService.getConfig(
          previousTaskIDs[0]
        );
        if (!previousConfig) {
          return this.assignNotValidOperator(
            currentOperatorCode,
            currentOperatorType,
            miscellaneousConst.operators.MIL.errors.previousConfigError
          );
        }
      }
    } else if (currentOperatorCode === 'DT') {
      // Check for Data Transformation Operator
      if (previousTaskIDs.length !== 1) {
        return this.assignNotValidOperator(
          currentOperatorCode,
          currentOperatorType,
          miscellaneousConst.operators.DT.errors.must1PreviousTask
        );
      } else {
        // Check if previous task config is available or not
        const previousConfig = this.udpTaskConfigService.getConfig(
          previousTaskIDs[0]
        );
        if (!previousConfig) {
          return this.assignNotValidOperator(
            currentOperatorCode,
            currentOperatorType,
            miscellaneousConst.operators.DT.errors.previousConfigError
          );
        }
      }
    }

    return null;
  }

  private assignNotValidOperator(
    code: string,
    type: string,
    errorMessage: string
  ): NotValidOperator {
    const notValidOperator = new NotValidOperator();
    notValidOperator.operatorCode = code;
    notValidOperator.operatorType = type;
    notValidOperator.errorMessage = errorMessage;
    return notValidOperator;
  }

  /**
   * @description To check if all connected previoud operators are dataset operators or not
   */
  private checkIfAllPreviousOperatorsAreDS(previousTaskIDs: string[]): boolean {
    if (previousTaskIDs.length !== 2) {
      return false;
    } else {
      const operatorsArray: string[] = [];
      for (const taskId of previousTaskIDs) {
        const splitArr = taskId.split('.');
        operatorsArray.push(splitArr[splitArr.length - 2].toUpperCase());
      }

      // check if only 2 inputs should be there i.e. of types DS or DB but should not be MIL
      return (
        (operatorsArray.includes('DS') || operatorsArray.includes('DB')) &&
        !operatorsArray.includes('MIL')
      );
    }
  }

  openJsonEditor() {
    const value =
      this.modelSaveService.getWorkflowDefinition() ||
      this.udpTaskConfigService.getConfigFromCacheByType('jsonEditor');
    // const value =  this._udpTaskConfigService.getConfigFromCacheByType("jsonEditor") || this.modelSaveService.getWorkflowDefinition();
    this.currentValue = JSON.stringify(value, null, 4);

    this.panelMode.next({
      open: true,
      title: JSON_EDITOR_TITLE,
      value: this.currentValue,
      language: 'json'
    });
  }

  /**
   *
   * @descripton It will not get called becuase now we are not passing dagFileSHA that is coming on load of work flow editor itself
   * This method was getting called earlier in code-editor-panel component inside getConfig() method
   */
  getConfig(fileName: string, dagFileSha) {
    return this.udpDatasetOperatorService.getConfig(fileName, dagFileSha);
  }

  /**
   *
   */
  openDataMapping() {
    this.currentValue = 'dataMapping';

    this.panelMode.next({
      open: true,
      title: DATA_MAPPING_TITLE,
      value: this.currentValue,
      language: 'javascript'
    });
  }

  /**
   * @public
   * @param: {data<any>}
   * @param: {mode<string>}
   * @returns: void
   * @description: a helper method that
   * opens up the data catalog tools.
   */
  public openDataTools(data: any, mode: string): void {
    this.currentValue = 'tools';

    this.panelMode.next({
      data,
      mode,
      open: true,
      value: this.currentValue
    });
  }

  /**
   *
   * @returns
   */
  openPythonEditor() {
    const currentScript = this.workflowService.getCurrentWorkflowScript();
    // removed ! operater to fix default python view, Same would be there Generate Dag button
    // if (currentScript) {
    this.fetchPythonCode();
    return;
    // }

    this.setPythonContent(currentScript);
    this._isCodeLoading.next(false);
  }

  /**
   *
   */
  fetchPythonCode() {
    this._isCodeLoading.next(true);
    this.setPythonContent('');

    // const nodeId = this.workflowService.getCurrentNodeID();
    const workflowDefinition = JSON.stringify(
      this.modelSaveService.getWorkflowDefinition(),
      null,
      4
    );
    this.apiService
      .getPythonCode(workflowDefinition)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.setPythonContent(data);
          this._isCodeLoading.next(false);
        },
        () => {
          this.setPythonContent('# Unable to fetch code');
          this._isCodeLoading.next(false);
        }
      );
  }

  /**
   * @description Aakash - Need to refactor this code more so that instead of
   * Seperate Variables we can use MAP with Key and Value
   * Key would be operator Type and Value would be final JSON Config
   */
  saveChanges() {
    const panelData: any = this.panelMode.getValue();
    if (panelData.mode === 'DATASET') {
      // For DataSet Operator
      if (!this.datasetOperatorConfig) {
        return;
      }
      this.udpTaskConfigService.setConfigToService(
        this.datasetOperatorConfig,
        'operatorConfig'
      );
      this._isCodeLoading.next(false);
      this.panelMode.next({
        open: false
      });
    } else if (panelData.mode === 'BLEND') {
      // For Blend Operator
      if (!this.datablendOperatorConfig) {
        return;
      }
      this.udpTaskConfigService.setConfigToService(
        this.datablendOperatorConfig,
        'operatorConfig'
      );
      this._isCodeLoading.next(false);
      this.panelMode.next({
        open: false
      });
    } else if (panelData.mode === 'MIL') {
      // For Transformation Operator
      if (!this.dataTransformationOperatorConfig) {
        this.dataTransformationOperatorConfig();
      }
      this.udpTaskConfigService.setConfigToService(
        this.dataTransformationOperatorConfig,
        'operatorConfig'
      );
      this._isCodeLoading.next(false);
      this.panelMode.next({
        open: false
      });
    } else if (panelData.mode === 'DT') {
      // For UDP Transformation Operator
      if (!this.UDPTransformationOperatorConfig) {
        this.dataTransformationOperatorConfig();
      }
      this.udpTaskConfigService.setConfigToService(
        this.UDPTransformationOperatorConfig,
        'operatorConfig'
      );
      this._isCodeLoading.next(false);
      this.panelMode.next({
        open: false
      });
    } else {
      if (panelData.language === 'json') {
        const data = {
          filename: miscellaneousConst.DAG.FILENAME,
          contents: JSON.parse(this.currentValue)
        };
        this.udpTaskConfigService.setConfigToService(data, 'jsonEditor');
        this.panelMode.next({
          open: false
        });
        this.messagesService.openMessage('Configuration saved');
      }

      if (panelData.language === 'python') {
        this.udpTaskConfigService.setConfigToService(
          this.currentValue,
          'pythonEditor'
        );
        this._isCodeLoading.next(false);
        this.panelMode.next({
          open: false
        });
      }
    }
  }

  /**
   *
   */
  discardChanges() {
    this.currentValue = null;
    this.panelMode.next({
      open: false
    });
  }

  /**
   *
   * @param currentValue
   */
  setCurrentValue(currentValue: string) {
    this.currentValue = currentValue;
  }

  /**
   *
   * @param content
   */
  private setPythonContent(content: string) {
    // set python content to _udpTaskConfigService
    this.udpTaskConfigService.setConfigToService(content, 'pythonEditor');
    const { dag } = this.modelService.getModelData();

    this.currentValue = content;
    this.panelMode.next({
      open: true,
      title: `${PYTHON_EDITOR_TITLE_DAG_SCRIPT} -
            ${(dag.name != null && dag.name.trim()) || PYTHON_EDITOR_UNTITLED}`,
      value: this.currentValue,
      language: 'python'
    });
  }

  /**
   *
   * @returns
   */
  getLinksData() {
    return this.modelService.getNodes();
  }

  /**
   *
   * @returns
   */
  getLinkNodes() {
    return this.modelService.getLinks();
  }

  /**
   * @description
   * the following method is used for set udp transformer operator config
   */

  setUdpTransformerConfig(event) {
    let previousTaskSchemaForDTOperator = {
      ...this.transformationOperatorEventHelperService.getPreviousTaskSchemaForDTOperator()
    };

    let datasetSchema = previousTaskSchemaForDTOperator.attribute
      ? previousTaskSchemaForDTOperator.attribute
      : [];
    if (
      previousTaskSchemaForDTOperator &&
      previousTaskSchemaForDTOperator.attribute &&
      previousTaskSchemaForDTOperator.attribute.length
    ) {
      if (event.lastTaskColumns && event.lastTaskColumns.length) {
        datasetSchema = previousTaskSchemaForDTOperator.attribute.filter((x) =>
          event.lastTaskColumns.includes(x && x.name)
        );
      }
    }

    previousTaskSchemaForDTOperator.attribute = datasetSchema;

    const transformationOperatorData = {
      dataAssetID: event.dataPreparationWorkflow.dataAssetID,
      dataAssetType: event.dataPreparationWorkflow.dataAssetType,
      dataPreparationWorkflowID: event.dataPreparationWorkflow.id,
      dataPreparationWorkflowName:
        event.dataPreparationWorkflow.dataPreparationWorkflowName,
      dataTransformationRecipe: event.dataTransformationRecipe,
      description: event.dataPreparationWorkflow.description,
      datasetSchema: previousTaskSchemaForDTOperator
    };
    return transformationOperatorData;
  }
}
