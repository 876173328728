import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';

/**
 * @interface
 */
interface UploadError {
  file: string;
  error: string;
}

/**
 * @author: Naga
 */
@Component({
  selector: 'xft-upload-response',
  templateUrl: './upload-response.component.html',
  styleUrls: ['./upload-response.component.scss']
})
export class UploadResponseComponent implements OnInit, AfterViewInit {

  /**
   * @public
   * @type: {any}
   * @input
   */
  @Input()
  public errInfo: any;

  /**
   * @public
   * @type: {any}
   * @input
   */
  public errors: any = {};

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public dag: boolean = false;

  /**
   * @public
   * @type: {boolean}
   * @input
   */
  @Input()
  public success: boolean = false;

  /**
   * @public
   * @type: {MatPaginator}
   * @viewchild
   */
  @ViewChild(MatPaginator, { static: false })
  public paginator: MatPaginator;

  /**
   * @public
   * @type: {MatTableDataSource<UploadError>}
   * @viewchild
   */
  public dataSource: MatTableDataSource<UploadError>;

  /**
   * @public
   * @type: {string[]}
   */
  public displayedColumns: string[] = ['file', 'error'];

  /**
   * @constructor
   */
  constructor() { }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngOnInit(): void {
    const list: any[] = this.errInfo.errors_found
    || this.errInfo.errors_found_at_repo;

    if (Array.isArray(list)) {
      this.refineErrorsList(list);

      this.dataSource = new MatTableDataSource<UploadError>(
        this.errors.list
      );
    }
  }

  /**
   * @private
   * @param: {value<string>}
   * @return: void
   * @description: a helper method that sets
   * the mat filter value.
   */
  public applyFilter(value: string): void {
    value = value.toLowerCase().trim();
    this.dataSource.filter = value;
  }

  /**
   * @public
   * @param: {errors<any[]>}
   * @return: void
   * @description: a helper method that
   * refines the errors list.
   */
  public refineErrorsList(errors: any[]): void {
    this.errors = { list: [], empty: [] };

    for (const o of errors) {
      if (!!o.file && !!o.error) {
        this.errors.list.push(o);
      } else {
        this.errors.empty.push(o);
      }
    }
  }

  /**
   * @public
   * @return: void
   * @description: Life Cycle Hook
   */
  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}
