import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { DataAssetCategory, Query } from '@xfusiontech/data-visualizer';

@Component({
    selector: 'igv-query-editor-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class QueryEditorNavigationComponent implements OnChanges {
    @Input()
    dataAssetCategories: DataAssetCategory[];

    @Output()
    querySelectionChange = new EventEmitter<Query>();

    @Output()
    editCategory = new EventEmitter<DataAssetCategory | null>();

    @Output()
    deleteCategory = new EventEmitter<DataAssetCategory>();

    @Output()
    shareQueryInDashboard = new EventEmitter();

    @Output()
    shareQueryByEmail = new EventEmitter();

    @Output()
    deleteQuery = new EventEmitter();

    searchItem: string;
    expansionMap: { [key: string]: boolean } = {};

    menuTopLeftPosition = { x: '0', y: '0' };

    @ViewChild(MatMenuTrigger, { static: true })
    matMenuTrigger: MatMenuTrigger;
    menuData: DataAssetCategory;

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (
            simpleChanges.dataAssetCategories &&
            this.dataAssetCategories != null
        ) {
            this.dataAssetCategories.map(
                x => (this.expansionMap[x.dataAssetCategoryID] = false)
            );
        }
    }

    categoriesTrackBy = (_, category: DataAssetCategory) =>
        category.dataAssetCategoryID;

    onValueChanges(value: string) {
        this.searchItem = value;
    }

    onRightClick(event: MouseEvent, data: DataAssetCategory) {
        event.preventDefault();
        this.menuTopLeftPosition.x = event.clientX + 'px';
        this.menuTopLeftPosition.y = event.clientY + 'px';
        this.menuData = data;
        this.matMenuTrigger.openMenu();
    }
}
