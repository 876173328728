import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';
import { WORKFLOW_EDITOR_PATH } from './workflow-editor/consts/routing';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: WORKFLOW_EDITOR_PATH
  },
  {
    path: WORKFLOW_EDITOR_PATH,
    component: WorkflowEditorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
