import * as _ from 'lodash/fp';
import * as go from 'gojs';

import { TemplateCreator } from '../types';

export const createTemplateMap = <T>(
    ...templateCreators: TemplateCreator<T>[]
) => {
    const templateMap = new go.Map<string, T>();
    _.forEach(({ category, template }) => {
        templateMap.add(category, template);
    })(templateCreators);

    return templateMap;
};
