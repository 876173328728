import { Injectable } from '@angular/core';
import {
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
  MatSnackBar,
} from "@angular/material/snack-bar";

import errorMessages from "../json/error_messages.json";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  error_Messages: any= new Map();

  constructor(
    public _snackbar: MatSnackBar
  ) {

  }

  errorSuccessSnack(id, isSuccess) {
    let messageMap = new Map();
    for (var value in errorMessages) {
      messageMap.set(value,errorMessages[value])
      }
    let message = messageMap.get(id);
    this._snackbar.open(message, isSuccess ? 'Success!' : 'Error', {
      duration: 5000,
      panelClass: [isSuccess ? 'success-snackbar' : 'error-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

  errorSuccessSnackMessage(message, isSuccess) {
    this._snackbar.open(message, isSuccess ? 'Success!' : 'Error', {
      duration: 5000,
      panelClass: [isSuccess ? 'success-snackbar' : 'error-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }
}
