/**
 * @author: Naga
 * @constant
 */
export const DATASETS: any[] = [
  {
    id: 1,
    dataAssetType: 'Data Sources',
    dataAssets: [
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2rytr5',
        dataSetName: 'Sample Dataset 1',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-11e5',
            dataSourceName: 'Sample Source 11',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 11'
          },
          {
            dataSourceID: '1ac851787-06eb-12e5',
            dataSourceName: 'Sample Source 12',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 12'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test1 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      },
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2d6c5',
        dataSetName: 'Sample Dataset 2',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-21e5',
            dataSourceName: 'Sample Source 21',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 21'
          },
          {
            dataSourceID: '1ac851787-06eb-22e5',
            dataSourceName: 'Sample Source 22',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 22'
          },
          {
            dataSourceID: '1ac851787-06eb-23e5',
            dataSourceName: 'Sample Source 23',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 23'
          },
          {
            dataSourceID: '1ac851787-06eb-24e5',
            dataSourceName: 'Sample Source 24',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 24'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test2 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: 2,
    dataAssetType: 'Datasets',
    dataAssets: [
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2rytr5',
        dataSetName: 'Sample Dataset 3',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-11e5',
            dataSourceName: 'Sample Source 31',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 11'
          },
          {
            dataSourceID: '1ac851787-06eb-12e5',
            dataSourceName: 'Sample Source 32',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 12'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test1 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      },
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2d6c5',
        dataSetName: 'Sample Dataset 4',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-21e5',
            dataSourceName: 'Sample Source 41',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 21'
          },
          {
            dataSourceID: '1ac851787-06eb-22e5',
            dataSourceName: 'Sample Source 42',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 22'
          },
          {
            dataSourceID: '1ac851787-06eb-23e5',
            dataSourceName: 'Sample Source 43',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 23'
          },
          {
            dataSourceID: '1ac851787-06eb-24e5',
            dataSourceName: 'Sample Source 44',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 24'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test2 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: 3,
    dataAssetType: 'Query',
    dataAssets: [
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2rytr5',
        dataSetName: 'Sample Dataset 1',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-11e5',
            dataSourceName: 'Sample Source 11',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 11'
          },
          {
            dataSourceID: '1ac851787-06eb-12e5',
            dataSourceName: 'Sample Source 12',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 12'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test1 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      },
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2d6c5',
        dataSetName: 'Sample Dataset 2',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-21e5',
            dataSourceName: 'Sample Source 21',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 21'
          },
          {
            dataSourceID: '1ac851787-06eb-22e5',
            dataSourceName: 'Sample Source 22',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 22'
          },
          {
            dataSourceID: '1ac851787-06eb-23e5',
            dataSourceName: 'Sample Source 23',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 23'
          },
          {
            dataSourceID: '1ac851787-06eb-24e5',
            dataSourceName: 'Sample Source 24',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 24'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test2 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: 4,
    dataAssetType: 'Notebook',
    dataAssets: [
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2rytr5',
        dataSetName: 'Sample Dataset 1',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-11e5',
            dataSourceName: 'Sample Source 11',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 11'
          },
          {
            dataSourceID: '1ac851787-06eb-12e5',
            dataSourceName: 'Sample Source 12',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 12'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test1 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      },
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2d6c5',
        dataSetName: 'Sample Dataset 2',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-21e5',
            dataSourceName: 'Sample Source 21',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 21'
          },
          {
            dataSourceID: '1ac851787-06eb-22e5',
            dataSourceName: 'Sample Source 22',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 22'
          },
          {
            dataSourceID: '1ac851787-06eb-23e5',
            dataSourceName: 'Sample Source 23',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 23'
          },
          {
            dataSourceID: '1ac851787-06eb-24e5',
            dataSourceName: 'Sample Source 24',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 24'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test2 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      }
    ]
  },
  {
    id: 5,
    dataAssetType: 'Visualization',
    dataAssets: [
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2rytr5',
        dataSetName: 'Sample Dataset 1',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-11e5',
            dataSourceName: 'Sample Source 11',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 11'
          },
          {
            dataSourceID: '1ac851787-06eb-12e5',
            dataSourceName: 'Sample Source 12',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 12'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test1 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test1 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      },
      {
        dataSetID: '1ac85184-06eb-44e5-abcd-ca6058c2d6c5',
        dataSetName: 'Sample Dataset 2',
        dataSetDataSource: [
          {
            dataSourceID: '1ac85184-06eb-21e5',
            dataSourceName: 'Sample Source 21',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-44sd',
            dataSetDesc: 'Sample Source 21'
          },
          {
            dataSourceID: '1ac851787-06eb-22e5',
            dataSourceName: 'Sample Source 22',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 22'
          },
          {
            dataSourceID: '1ac851787-06eb-23e5',
            dataSourceName: 'Sample Source 23',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 23'
          },
          {
            dataSourceID: '1ac851787-06eb-24e5',
            dataSourceName: 'Sample Source 24',
            dataSourceType: 'Test',
            dataSourceConnectionID: '06eb2-23dwe-66sd',
            dataSetDesc: 'Sample Source 24'
          }
        ],
        datasetSchema: {
          entity: [
            {
              entityName: 'Test Entity 1',
              attributes: [
                {
                  attributeName: 'Test2 Attribute 1',
                  attribututeDataType: 'string',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 2',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 3',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                },
                {
                  attributeName: 'Test2 Attribute 4',
                  attribututeDataType: 'boolean',
                  isIdentifyingAttribute: 'N',
                  isSensitiveData: 'N'
                }
              ]
            }
          ]
        }
      }
    ]
  }
];
