// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatRadioModule, MatSlideToggleModule, MatSortModule, MatTableModule, MatTooltipModule } from '@angular/material';

// Components
import { CodemaptableComponent } from './codemaptable.component';
import { ButtonModule, IconButtonModule, SlideToggleModule } from '../../../../shared';
import { DataImportModule } from 'libs/data-import';

/**
 * @constant
 */
const EXPORTS: any[] = [
  CodemaptableComponent
];

/**
 * @author: Naga
 * @module
 */
@NgModule({
  declarations: [
    ...EXPORTS
  ],
  imports: [
    FormsModule
    , ButtonModule
    , CommonModule
    , MatSortModule
    , MatTableModule
    , MatInputModule
    , MatButtonModule
    , MatTooltipModule
    , IconButtonModule
    , SlideToggleModule
    , MatCheckboxModule
    , MatExpansionModule
    , MatPaginatorModule
    , MatFormFieldModule
    , ReactiveFormsModule
    , MatSlideToggleModule
    , MatIconModule
    , MatRadioModule
    , DataImportModule
  ],
  exports: [...EXPORTS]
})
export class CodeMaptableModule { }
