import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchTextComponent } from './search-text.component';

const components = [
    SearchTextComponent
];

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule
    ]
})
export class SearchTextModule { }
