import { Pipe, PipeTransform } from '@angular/core';
import { Option } from './../../components/form-field/form-field.types';

@Pipe({ name: 'stringArrayToSelectOptions' })
export class StringArrayToSelectOptionsPipe implements PipeTransform {
    transform(value: string[]): Option[] {
        return value
            ? value.map(variableType => ({
                  name: variableType,
                  value: variableType,
              }))
            : [];
    }
}
