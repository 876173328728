import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'udp-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DatetimeComponent,
        },
    ],
})
export class DatetimeComponent
    implements
        OnDestroy,
        OnInit,
        MatFormFieldControl<Date>,
        ControlValueAccessor {
    @Input()
    placeholder: string;

    @Input()
    required: boolean;

    @ViewChild('datetime', { static: true })
    elementRef: ElementRef;

    value: Date;
    get valueHours() {
        return this.value ? this.value.getHours() : null;
    }
    get valueMinutes() {
        return this.value ? this.value.getMinutes() : null;
    }

    stateChanges = new Subject<void>();
    id: string;
    focused: boolean;
    empty: boolean;
    shouldLabelFloat: boolean;
    errorState: boolean;
    autofilled?: boolean;
    disabled: boolean;
    controlType = 'udp-datetime';

    onChange: (value: Date) => void = () => {};
    onTouched: () => void = () => {};

    onDestroy$ = new Subject();
    date$ = new BehaviorSubject<Date>(null);
    hours$ = new BehaviorSubject<number>(null);
    minutes$ = new BehaviorSubject<number>(null);

    form: FormGroup;

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() {
        combineLatest([this.date$, this.hours$, this.minutes$])
            .pipe(
                takeUntil(this.onDestroy$),
                filter(([date, _, __]) => date != null)
            )
            .subscribe(([date, hours, minutes]) => {
                const value = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    hours ? hours : 0,
                    minutes ? minutes : 0,
                    0,
                    0
                );

                this.onChange(value);
            });
    }

    setDescribedByIds(ids: string[]): void {
        const controlElement = this.elementRef.nativeElement;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    }

    writeValue(value: Date): void {
        this.value = typeof value === 'object' ? value : new Date(value);
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onDateChange(event) {
        this.date$.next(event.value);
        this.onTouched();
    }

    onHourChange(event) {
        const {
            target: { value },
        } = event;
        this.hours$.next(value);
        this.onTouched();
    }

    onMinuteChange(event) {
        const {
            target: { value },
        } = event;
        this.minutes$.next(value);
        this.onTouched();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
