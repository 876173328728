import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PaletteItem } from '../../../types/palette';

@Component({
    selector: 'xft-palette-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ItemComponent {
    icon = 'cpu';

    @Input()
    item: PaletteItem;

    checkUDP(item: any) {
        if (item.label.includes('Udp') || item.label.includes('UDP')) {
            const newstr = item.label.replace('Udp', 'UDP');
            item.imagePath = '../../assets/icons/udp-icon.png';
            item.label = newstr;
            return newstr;
        } if ( item.category === 'providers' && item.subcategory === 'amazon') {
            item.imagePath = '../../assets/icons/aws-icon.png';
            if (item.label.includes('Aws')) {
                const newstr = item.label.replace('Aws', 'AWS');

                item.label = newstr;

            }
            return item.label;
        } else if ( item.category === 'airflow') {
            item.imagePath = '../../assets/icons/airflow-logo.png';
            return item.label;
        } else if (item.label.includes('GCS') || item.label.includes('Gcs')) {
            item.imagePath = '../../assets/icons/gcs-icon.png';
            return item.label;
        } else {
            return item.label;
        }

    }
}
